import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Box, CardActionArea, Divider, Fab, Typography, useTheme } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import {  AccessTimeFilled, ArrowForward, ChevronRight } from '@material-ui/icons';

import BottomDrawer from 'bos_common/src/components/BottomDrawer';
import EmbededMap from 'bos_common/src/components/EmbededMap';

import { AppContext } from '../../../context/AppContext';
import { getDistance, getMultiStoreKey, getStoreFrontHoursLabel } from './utils';
import { Merchant } from '../../../services/models';
import StoreLocationSelectorCard from './StoreLocationSelectorCard';
import MerchantInfoDrawer from '../../MerchantInfo/MerchantInfoDrawer';
import { getMerchantAddress, isEmptyOrNil } from '../../../utils';
import { getStoredJSON, LOCALSTORAGE_MARKETPLACE_APP_KEYS, storeJSON } from '../../../services/localStorage';
import { hoursForDate } from '../../../services/hoursUtils';

interface StoreFrontSelectorDrawerProps {
  merchant: Merchant,
  stores: Merchant[],
  open: boolean,
  setOpen: (b: boolean) => void
}

export type LocationCardMerchant = {
  id: string
  name: string,
  address: string,
  hours: string,
  hasHoursToday: boolean,
  distance?: string
};

const StoreFrontSelectorDrawer = (props: StoreFrontSelectorDrawerProps): JSX.Element | null => {
  const { merchant, stores, open, setOpen } = props;
  if (!open) return null;

  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const { clearCart } = useContext(AppContext);

  const multiStoreKey = getMultiStoreKey(merchant);

  const [currentPosition, setCurrentPosition] = useState<GeolocationPosition>();
  const [showInfoDrawer, setShowInfoDrawer] = useState<boolean>(false);
  const [selectedMerchant, setSelectedMerchant] = useState<Merchant>();

  const storeCardInfo: LocationCardMerchant[] = useMemo(() =>
    stores.map((store) => ({
      id: store.id,
      name: store.multiStore?.label ?? store.officialName,
      address: getMerchantAddress(store) || '',
      hours: getStoreFrontHoursLabel(store, store.hours),
      hasHoursToday: !isEmptyOrNil(hoursForDate(store, new Date())),
      distance: getDistance(store.structuredAddress, currentPosition)
    })), [stores, currentPosition]);

  const storeLocations = useMemo(() => stores.map((store) => ({
    ...store.structuredAddress,
    title: store.multiStore?.label ?? store.officialName
  }) || {}), [stores]);

  useEffect(() => {
    if (!open) return

    const onLocationSuccess = (currentPosition: GeolocationPosition) => {
      setCurrentPosition(currentPosition)
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onLocationSuccess);
    }
  }, [open])

  const getNewURL = (newMerchant: Merchant) => `/${newMerchant.username}`

  const handleStorefrontChange = (merchantId: string) => {
    const selectedStore = stores?.find((store) => store.id === merchantId);
    if (selectedStore) {
      setOpen(false);

      const openedAt = getStoredJSON(LOCALSTORAGE_MARKETPLACE_APP_KEYS.STORE_SELECTOR_LAST_OPENED_AT);
      storeJSON(LOCALSTORAGE_MARKETPLACE_APP_KEYS.STORE_SELECTOR_LAST_OPENED_AT, {
        ...openedAt,
        [multiStoreKey]: new Date().toISOString(),
      })

      if (merchant.id !== selectedStore.id) {
        clearCart();
        history.push({ pathname: getNewURL(selectedStore), state: { from: window.location.pathname } });
      }
    }
  }

  const handleClickStoreHours = (merchantId: string) => {
    const merchant = stores?.find((store) => store.id === merchantId);
    if (merchant !== undefined) {
      setSelectedMerchant(merchant);
      setShowInfoDrawer(true);
    }
  }

  return (
    <BottomDrawer
      open={open}
      setOpen={() => {return;}} // prevents closing behavior coming from components close events
      title={<Typography variant="h6" style={{ textAlign: 'center' }} mb="1">Select Pickup Location</Typography>}
      maxHeightPx={700}
    >
      <Box>
        <EmbededMap storeLocations={storeLocations} height={"200px"} />
        {storeCardInfo.map((store: LocationCardMerchant) => (
          <Box key={store.name} component="div" sx={{pl: 2, pr: 2, pt: 1}}>
            <Box sx={{display: 'flex', alignItems: 'center', mb: 1}} >
              <Box sx={{flexGrow: 1}}>
                <CardActionArea onClick={(e) => handleStorefrontChange(store.id)}>
                  <StoreLocationSelectorCard
                    store={store}
                  />
                </CardActionArea>
                <Fab
                  variant="extended"
                  size="small"
                  onClick={() => handleClickStoreHours(store.id)}
                  className="business-hours-cta"
                  sx={{boxShadow: 'none', marginTop: theme.spacing(0.5)}}
                >
                  <Typography component="div" fontSize={14} sx={{textTransform: 'none', display: 'flex', alignItems: 'center'}}>
                    <AccessTimeFilled fontSize={"small"} sx={{mr: 0.5}}/>
                      Business Hours
                    <ArrowForward fontSize={"small"} />
                  </Typography>
                </Fab>
              </Box>
              <ChevronRight sx={{cursor: 'pointer'}} onClick={(e) => handleStorefrontChange(store.id)} />
            </Box>
            <Divider />
          </Box>
        ))}
        {selectedMerchant && (
          <MerchantInfoDrawer
            merchant={selectedMerchant}
            open={showInfoDrawer}
            setOpen={setShowInfoDrawer}
          />
        )}
      </Box>
    </BottomDrawer>
  )
}

export default StoreFrontSelectorDrawer;