import React from 'react'
import { SvgIcon } from "@material-ui/core";

function HotpotIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M4.281 7.59h.82v.82h-.82v-.82zM10.898 7.59h.82v.82h-.82v-.82zM9.258 5.95h.82v.82h-.82v-.82z" />
      <path d="M11.79 4.21a5.365 5.365 0 00-7.58 0 5.365 5.365 0 000 7.58 5.365 5.365 0 007.58 0 5.365 5.365 0 000-7.58zm-.58.58a4.547 4.547 0 01.835 5.268c-1.797-.09-2.74-1.177-3.735-2.327-.544-.627-1.106-1.276-1.818-1.77a4.624 4.624 0 00-2.037-.796 4.544 4.544 0 016.754-.375zm-6.42 6.42a4.547 4.547 0 01-.836-5.268c1.798.089 2.74 1.177 3.736 2.326.544.628 1.106 1.277 1.818 1.77.622.431 1.293.694 2.037.797a4.544 4.544 0 01-6.754.375z" />
      <path d="M14.184 4.713c.725-.805.7-2.049-.074-2.823a2.053 2.053 0 00-2.822-.074A6.965 6.965 0 008 1c-1.87 0-3.628.728-4.95 2.05A6.954 6.954 0 001 8c0 1.165.283 2.287.816 3.287-.725.804-.7 2.049.074 2.823a2.053 2.053 0 002.823.074c1 .533 2.122.816 3.287.816 1.87 0 3.628-.728 4.95-2.05A6.954 6.954 0 0015 8a6.965 6.965 0 00-.816-3.287zM13.53 2.47c.404.404.468 1.022.19 1.493a7.095 7.095 0 00-1.683-1.684 1.233 1.233 0 011.493.191zM2.47 13.53a1.233 1.233 0 01-.19-1.493 7.086 7.086 0 001.683 1.684 1.233 1.233 0 01-1.493-.191zm5.53.65A6.187 6.187 0 011.82 8a6.16 6.16 0 011.812-4.368A6.161 6.161 0 018 1.82 6.187 6.187 0 0114.18 8 6.187 6.187 0 018 14.18z" />
    </SvgIcon>
  );
}

export default HotpotIcon 