import React, { useContext, useEffect, useState } from "react";
import qs from 'qs';
import { useHistory } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import { Case, Switch, Then } from "react-if";
import { propOr } from "ramda";

import { FullscreenPaper } from 'bos_common/src/components/Papers';
import SimpleLoader from "bos_common/src/components/SimpleLoader";
import { AppContext } from "../../context/AppContext";
import { MerchantQRCode, MerchantType, OrderType, QRCodeType } from "../../services/models";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../../services/Tracking/events";
import eventTrackingService from "../../services/Tracking";

import PageHeader from "../../components/common/PageHeader";
import OrderOptionsGroup from "../../components/Order/OrderOptionsGroup";
import MerchantHero from "../../components/MerchantInfo/MerchantHero";
import { isEmptyOrNil, isKioskDevice, isAddingItemToOpenCheck } from "../../utils";
import BackButton from "../../components/common/BackButton";
import NoneExistPage from "../NoneExistPage";
import { VisitedMerchantContext } from "../../context/VisitedMerchantContext/VisitedMerchantContext";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getUser } from "../../redux/slice/auth/authSelector";

import { fetchMerchant, fetchMerchantCoupon } from "../../redux/slice/merchant/action";
import { getCouponByCode, getCurrentMerchant, getMerchantLoadingState } from "../../redux/slice/merchant/merchantSelector";
import ReservationDrawer from "../../components/Reservation/ReservationDrawer";
import { LOCALSTORAGE_MARKETPLACE_APP_KEYS, storeTimedKey } from "../../services/localStorage";
import ProductMerchantMenuPage from "./components/ProductMerchantMenuPage";
import ServicesMerchantKioskPage from "./components/ServicesMerchantKioskPage";

interface IMerchantPageParams {
  merchantUserName: string,
  merchandiseId?: string,
  comboCouponId?: string,
}

const MerchantPage = ({ orderType }: { orderType?: OrderType }): React.ReactElement => {
  const history = useHistory()
  const user = useAppSelector(getUser);

  const { merchantUserName } = useParams<IMerchantPageParams>();
  const merchant = useAppSelector(getCurrentMerchant);
  const loading = useAppSelector(getMerchantLoadingState);

  const { setOrderType, orderType: cartOrderType, cartType } = useContext(AppContext);
  const { setVisitedMerchant } = useContext(VisitedMerchantContext);
  const [hasFetched, setHasFetched] = useState<boolean>(false);

  const location = useLocation<{ from: { pathname: string } }>();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const tableQueryParam = propOr('', 'tableid', params);
  const promotionCode = propOr('', 'pc', params);
  const coupon = useAppSelector(getCouponByCode)(promotionCode);
  const reduxDispatch = useAppDispatch();

  // this will remove affiliate id when we go to a merchants page if it does not exist
  const urlParams = new URLSearchParams(location.search);
  const referrerId = urlParams.get('referral') === 'true' ? urlParams.get('suid') : null;
  const affiliateCode = urlParams.get("affiliate");
  const kioskOverride = urlParams.get("kiosk");
  const isKioskModeEnabled = isKioskDevice() || kioskOverride;

  useEffect(() => {
    if (referrerId) {
      storeTimedKey(LOCALSTORAGE_MARKETPLACE_APP_KEYS.GIFT_REFERRAL_DATA, referrerId)
    }
  }, [referrerId])

  useEffect(() => {
    if (affiliateCode) {
      // set Affiliate Code in AppContext to associate with the Order Creation
      storeTimedKey(LOCALSTORAGE_MARKETPLACE_APP_KEYS.AFFILIATE_DATA, affiliateCode);
    }
  }, [affiliateCode])

  useEffect(() => {
    reduxDispatch(fetchMerchant({
      username: merchantUserName
    }));
    setHasFetched(true);
  }, [merchantUserName]);

  useEffect(() => {
    if (merchant) {
      setVisitedMerchant(merchant)

      eventTrackingService.captureEvent({
        category: EVENT_CATEGORIES.MERCHANT_MENU,
        action: EVENT_ACTIONS.VISITED_MENU,
        data: { merchant, user },
        label: merchant.officialName
      })

      if (!isEmptyOrNil(promotionCode)) {
        reduxDispatch(fetchMerchantCoupon({
          merchantId: merchant.id,
          couponCode: promotionCode
        }));
      }
    }

    if (orderType && orderType !== OrderType.DINEIN) {
      setOrderType(orderType);
    } else if (!isEmptyOrNil(tableQueryParam) && merchant) {
      const tableQrObject = merchant?.qrCodes?.find((i: MerchantQRCode) => `${i.id}` === `${tableQueryParam}`)
      const oType = tableQrObject?.type === QRCodeType.Table ? OrderType.DINEIN : OrderType.PICKUP
      setOrderType(oType, tableQrObject);
    }
  }, [tableQueryParam, promotionCode, merchant, orderType])

  if (!merchant && !loading && hasFetched) {
    return <NoneExistPage title={<>Merchant&nbsp;<b>{merchantUserName}</b>&nbsp;not found</>} />
  }

  const isServicesMerchant = merchant?.type === MerchantType.services

  /**
  * On click header back event handler.
  */
  const handleBack = (): void => {
    location.state?.from
      ? history.goBack()
      : history.push(`/`);
  }

  const getTitle = () => {
    if (isAddingItemToOpenCheck(cartType)) {
      return "Add Items to Order";
    }

    if(!loading) {
      return <OrderOptionsGroup merchant={merchant} />
    }

    return '';
  }

  return (
    <div className="container">
      {!isKioskModeEnabled && (
        <PageHeader
          title={getTitle()}
          leftChild={<BackButton onBack={handleBack} />}
        />)
      }
      <SimpleLoader loading={loading} />
      {merchant && (
        <FullscreenPaper>
          <Switch>
            <Case condition={isServicesMerchant && isKioskModeEnabled}>
              <ServicesMerchantKioskPage merchant={merchant} />
            </Case>
            <Case condition={cartOrderType === OrderType.PICKUP || cartOrderType === OrderType.DINEIN}>
              <ProductMerchantMenuPage merchant={merchant} coupon={coupon} />
            </Case>
            <Case condition={cartOrderType === OrderType.RESERVATION}>
              <Then>
                <MerchantHero merchant={merchant} height='60vh' />
                <ReservationDrawer merchant={merchant} />
              </Then>
            </Case>
          </Switch>
        </FullscreenPaper>
      )}
    </div >
  );
}

export default MerchantPage;
