import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { defaultTo, pathOr, propOr } from "ramda";
import useAxios from "axios-hooks";

import { UserContext } from "bos_common/src/context/UserContext";
import { Merchant } from "bos_common/src/types/MerchantType";
import { LOCALSTORAGE_APP_KEYS } from "bos_common/src/constants";
import { Order } from "bos_common/src/types/OrderTypes";
import { CheckInApiResponse } from "bos_common/src/types/PlatformRaffleType";

import { AppContext } from "../../context/AppContext";
import {
  enablePlatformRaffles,
  isEmptyOrNil,
  getAuthHeaders,
  isKioskDevice,
  extractQueryParamsFromLocation,
  deleteQueryParamsFromLocation,
} from "../../utils";

interface IMerchantPageParams {
  merchantUserName: string;
}

const getScannedRaffleInfo = (
  key: LOCALSTORAGE_APP_KEYS.RAFFLE | LOCALSTORAGE_APP_KEYS.RAFFLE_MERCHANT
) => {
  try {
    switch (key) {
      case LOCALSTORAGE_APP_KEYS.RAFFLE:
        return defaultTo(
          {},
          JSON.parse(localStorage.getItem(LOCALSTORAGE_APP_KEYS.RAFFLE) ?? "")
        );
      case LOCALSTORAGE_APP_KEYS.RAFFLE_MERCHANT:
        return defaultTo(
          {},
          JSON.parse(
            localStorage.getItem(LOCALSTORAGE_APP_KEYS.RAFFLE_MERCHANT) ?? ""
          )
        );
      default:
        console.log("unknown key");
    }
  } catch (error) {
    return {};
  }
};

export interface PlatformRaffleProps {
  isRaffleCheckedIn: boolean;
  checkInDetails?: CheckInApiResponse;
  scannedMerchant: Merchant;
  raffleQRScanned: string;
  order?: Order;
  loading: boolean;
}

interface usePlatformRaffleHookProps {
  order?: Order;
  cb?: (isOpen: boolean) => void;
}
const usePlatformRaffleHook = ({
  order,
  cb,
}: usePlatformRaffleHookProps): PlatformRaffleProps => {
  if (isKioskDevice()) {
    return {} as PlatformRaffleProps;
  }

  const { token, authenticating } = useContext(UserContext);
  const { featureFlags } = useContext(AppContext);
  const isRaffleEnabled = enablePlatformRaffles(featureFlags);

  const location = useLocation<{ from: { pathname: string } }>();
  const history = useHistory();
  const { merchantUserName } = useParams<IMerchantPageParams>();

  const params = extractQueryParamsFromLocation(location);

  const isCheckedInCallback = propOr("", "checkedIn", params);
  const raffleQRScanned = propOr("", "raffleQRScanned", params);

  const [scannedRaffle] = useState(
    getScannedRaffleInfo(LOCALSTORAGE_APP_KEYS.RAFFLE)
  );
  const [scannedMerchant, setScannedMerchant] = useState<Merchant>(
    getScannedRaffleInfo(LOCALSTORAGE_APP_KEYS.RAFFLE_MERCHANT)
  );

  const [isRaffleCheckedIn, setIsRaffleCheckedIn] = useState<boolean>(false);

  const [_, findMerchantForRaffle] = useAxios(
    {
      url: `/raffles/find-merchant`,
    },
    { manual: true }
  );

  const [{ data: checkInDetails, loading }, checkInUser] =
    useAxios<CheckInApiResponse>(
      {
        url: `/raffles/${scannedRaffle?.id}/check-in`,
      },
      { manual: true }
    );

  useEffect(() => {
    return () => setScannedMerchant({} as Merchant);
  }, []);

  useEffect(() => {
    if (
      isRaffleEnabled &&
      !isRaffleCheckedIn &&
      ((!isEmptyOrNil(merchantUserName) && !isEmptyOrNil(raffleQRScanned)) ||
        !isEmptyOrNil(order))
    ) {
      findMerchantForRaffle({
        url: `/raffles/find-merchant`,
        params: {
          merchantId: order?.merchantId,
          merchantUsername: merchantUserName,
          type: "platform",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            const raffle = pathOr([], ["data", "raffle"], res);
            const merchants = pathOr([], ["data", "raffle", "merchants"], res);
            const requiredMerchant = merchants.find(
              (m: Merchant) =>
                m.username === merchantUserName || m.id === order?.merchantId
            );

            localStorage.setItem(
              LOCALSTORAGE_APP_KEYS.RAFFLE,
              JSON.stringify(raffle)
            );
            localStorage.setItem(
              LOCALSTORAGE_APP_KEYS.RAFFLE_MERCHANT,
              JSON.stringify(requiredMerchant)
            );
            setScannedMerchant(requiredMerchant);
            cb && cb(true);
          }
        })
        .catch(() => {
          setScannedMerchant({} as Merchant);
          cb && cb(false);
        });
    }
  }, [
    order,
    raffleQRScanned,
    merchantUserName,
    isCheckedInCallback,
    isRaffleEnabled,
  ]);

  useEffect(() => {
    if (isCheckedInCallback && !isRaffleCheckedIn && token) {
      deleteQueryParamsFromLocation(location, ["checkedIn"], history);

      checkInUser({
        url: `/raffles/${scannedRaffle!.id}/check-in`,
        params: {
          type: "platform",
          merchantUsername: merchantUserName,
          orderId: order?.id,
          merchantId: order?.merchantId,
        },
        headers: getAuthHeaders(token),
      })
        .then((res) => {
          if (res.status === 200) {
            cb && cb(true);
            setIsRaffleCheckedIn(true);
          }
        })
        .catch(() => {
          setScannedMerchant({} as Merchant);
          cb && cb(false);
        });
    }
  }, [isCheckedInCallback, token]);

  if (!isRaffleEnabled || isEmptyOrNil(scannedMerchant) || authenticating)
    return {} as PlatformRaffleProps;

  return {
    isRaffleCheckedIn,
    checkInDetails,
    scannedMerchant,
    raffleQRScanned,
    order,
    loading,
  };
};

export default usePlatformRaffleHook;
