import React from 'react';
import {  isProduction, isDevelopment } from '../../utils';
import { CaptureEventParams, CaptureOrderCheckoutEvent } from './types';
import {  EVENT_CATEGORIES } from './events';
import { getOrderCheckoutEventAction } from './utils';
import { Merchant } from '../models';
import { getStoredTimedKey, LOCALSTORAGE_MARKETPLACE_APP_KEYS } from '../localStorage';

export const getEventLabel = (merchant: Merchant): string => {
  const affiliateCode = getStoredTimedKey(LOCALSTORAGE_MARKETPLACE_APP_KEYS.AFFILIATE_DATA);
  const referrerId = getStoredTimedKey(LOCALSTORAGE_MARKETPLACE_APP_KEYS.GIFT_REFERRAL_DATA);

  let label = `mid=${merchant.id}:mname=${merchant.officialName}`;
  if (affiliateCode) {
    label = `${label}:affiliate=${affiliateCode}`;
  }
  if (referrerId) {
    label = `${label}:referrer=${referrerId}`;
  }
  return label;
}

const sendEventToDataLayer = (type: string, category: string, action: string, label: string, data: any) => {
  // dont send localhost data
  if (isDevelopment()) return;

  const payload = {
    event: 'custom',
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    eventData: data    
  };

  if (!isProduction()) {
    console.log(`Sending ${type} event to GA data layer`);
    console.log(payload);
  }

  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push(payload);
};

export const captureEventForGA = (params: CaptureEventParams) => {
  const { category, data = {}, label = '', action } = params;
  sendEventToDataLayer('capture', category, action, label, data);
}

export const captureOrderCheckoutEventForGA = (params: CaptureOrderCheckoutEvent) => {
  const { data, label } = params;
  sendEventToDataLayer("checkout", EVENT_CATEGORIES.ORDER_CHECKOUT, getOrderCheckoutEventAction(params), label, data);
}