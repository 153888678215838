import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import thunk from "redux-thunk";
import createSagaMiddleware from "@redux-saga/core";
import rootSaga from "./rootSaga";
import authReducer from "./slice/auth";
import voteReducer from "./slice/vote";
import merchantReducer from "./slice/merchant";
import marketPlaceReducer from "./slice/marketPlace";
import reviewReducer from "./slice/review";
import orderReducer from "./slice/order";
import reservationReducer from './slice/reservation';
import merchantPromotionReducer from "./slice/merchantPromotion";
import merchandiseReducer from "./slice/merchandise";
import stripeReducer from "./slice/stripe";
import merchantStoryReducer from "./slice/merchantStory";
import { isProduction } from "../utils";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    auth: authReducer,
    vote: voteReducer,
    marketPlace: marketPlaceReducer,
    merchant: merchantReducer,
    review: reviewReducer,
    order: orderReducer,
    merchantPromotion: merchantPromotionReducer,
    merchandise: merchandiseReducer,
    stripe: stripeReducer,
    reservation: reservationReducer,
    merchantStory: merchantStoryReducer,
  },
  middleware: (getDefaultMiddleware) =>
    isProduction()
      ? getDefaultMiddleware().concat(thunk).concat(sagaMiddleware)
      : getDefaultMiddleware().concat(thunk).concat(sagaMiddleware).concat(logger)
})

// NOTE: Use Redux Thunk instead of Redux Saga for next and future features.
sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
