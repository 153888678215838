import React, { useState, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Alert, CircularProgress, createStyles, Fab, Grid, makeStyles, Stack,
  TextField, Theme, Typography
} from '@material-ui/core';
import {
  PermIdentity as PermIdentityIcon,
  CalendarToday as CalendarTodayIcon,
  AccessTime as AccessTimeIcon,
  Check as CheckIcon
} from "@material-ui/icons"
import { format } from 'date-fns';

import BottomDrawer from 'bos_common/src/components/BottomDrawer';
import { axios } from 'bos_common/src/services/reservationBackendAxios';
import { UserContext } from 'bos_common/src/context/UserContext';

import { Merchant } from '../../services/models';
import { PartySetting, OccasionEnum } from 'bos_common/src/types/ReservationTypes';
import { getTimeLabelFromOffset } from '../../utils';
import { TimerSelectChip } from '../Classifieds/styledComponents';
import { SignupEntrance } from '../User/SignupDrawer';
import { showSignUpDrawer } from '../../redux/slice/auth/authActions';
import { useAppDispatch } from '../../redux/hooks';


interface IConfirmReservationDrawerProps {
  merchant: Merchant,
  open: boolean;
  setOpen: (v: boolean) => void;
  partySetting: PartySetting;
  setPartySetting: (v: PartySetting) => void;
}

const useStyle = makeStyles((theme: Theme) => createStyles({
  drawerRoot: {
    '& .title': {
      textAlign: 'center',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    '& .confirmBtn': {
      position: 'relative',
    },
  },
  sampleStore: {
    '& .width': {
      width: 'auto'
    }
  },
  occasion: {
    '& .occasionSelectedBox': {
      padding: `${theme.spacing(2)} 0`,
      overflow: 'auto',
      '& .width': {
        width: 'auto',
        minWidth: 75,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      }
    }
  },
}))

const ConfirmReservationDrawer = ({
  merchant, open, setOpen, partySetting, setPartySetting
}: IConfirmReservationDrawerProps): JSX.Element | null => {
  // ConfirmReservationDrawer component props
  const { user } = useContext(UserContext);
  const history = useHistory();
  const classes = useStyle();
  const { address, officialName } = merchant;
  const { peopleNum, dinnerDate, dinnerTime } = partySetting;

  const [selectedOccasion, setSelectedOccasion] = useState<number>(partySetting.occasion ?? 0);
  const [specialRequest, setSpecialRequest] = useState<string>(partySetting.specialRequest ?? "");

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  
  const reduxDispatch = useAppDispatch();

  const occasionList = useMemo<Array<{ key: string, value: number }>>(() => {
    const arr: Array<{ key: string, value: number }> = [];
    for (const key in OccasionEnum) {
      if (!isNaN(Number(key))) {
        const element: string = OccasionEnum[key];
        arr.push({ key: element, value: Number(key) })
      }
    }
    return arr;
  }, [OccasionEnum])

  if (!open) {
    return null;
  }

  const confirmOrder = () => {
    setError('');
    setPartySetting({
      ...partySetting,
      occasion: selectedOccasion,
      specialRequest: specialRequest,
    });

    if (user) {
      const params = {
        ...partySetting,
        displayName: user.displayName,
        phone: user.phoneNumber,
        interCode: '',
        email: user.email,
        specialRequest: specialRequest,
        occasion: selectedOccasion,
        merchantId: merchant.id,
      };
      setLoading(true);
      axios.post('/customer/booking', { reservationInfo: params }).then(res => {
        if (res.status === 200) {
          // navigate to success page
          history.push(`/reservation/${res.data.id}`);
        } else {
          setError(res.data.message);
        }
      }).finally(() => {
        setPartySetting({
          ...partySetting,
          occasion: undefined,
          specialRequest: '',
        });
        setLoading(false);
      })
    } else {
      setSignupDrawerOpen(true);
    }
  }

  const setSignupDrawerOpen = (open: boolean) => {
    reduxDispatch(showSignUpDrawer({
      drawerShown: open,
      signupEntrance: SignupEntrance.RESERVATION,
    }));
  }

  const title = (
    <Typography
      variant="h6"
      className="title"
      component="div">
      {"Confirm reservation"}
    </Typography>)

  return (
    <BottomDrawer open={open} setOpen={setOpen} title={title} className={classes.drawerRoot}>
      <Stack spacing={4} sx={{ p: 3, flex: 1, overflow: 'auto', boxSizing: 'border-box', minHeight: '0' }}>
        <Stack className={classes.sampleStore}>
          <Typography variant="h6">{officialName}</Typography>
          <Typography variant="body2" noWrap>{address}</Typography>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={4}>
              <TimerSelectChip selected className={"width"}
                label={
                  <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    <PermIdentityIcon sx={{ mr: 0.75, fontSize: 16 }} />
                    {`Party for ${peopleNum}`}
                  </Stack>
                } />
            </Grid>
            <Grid item xs={4}>
              <TimerSelectChip selected className={"width"}
                label={
                  <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    <CalendarTodayIcon sx={{ mr: 0.75, fontSize: 14 }} />
                    {format(new Date(dinnerDate), 'EEE, MMM dd')}
                  </Stack>
                } />
            </Grid>
            <Grid item xs={4}>
              <TimerSelectChip selected className={"width"}
                label={
                  <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    <AccessTimeIcon sx={{ mr: 0.75, fontSize: 16 }} />
                    {getTimeLabelFromOffset(dinnerTime)}
                  </Stack>
                } />
            </Grid>
          </Grid>
        </Stack>
        <Stack>
          <Typography variant="subtitle1" gutterBottom>Special Request?</Typography>
          <TextField
            placeholder='Your reservation notes here.'
            multiline
            rows={5}
            variant="outlined"
            value={specialRequest}
            onChange={(e) => setSpecialRequest(e.target.value)}
            inputProps={{
              maxLength: 1000
            }}
          />
        </Stack>

        <Stack className={classes.occasion}>
          <Typography variant="subtitle1">What’s the occasion?</Typography>
          <div className="occasionSelectedBox">
            <Stack spacing={1} direction="row" className="timer-list">
              {
                occasionList.map((occasion) => (
                  <div key={`occasion_${occasion.value}`}>
                    <TimerSelectChip className="width"
                      selected={occasion.value === selectedOccasion}
                      label={occasion.key}
                      onClick={() => setSelectedOccasion(occasion.value === selectedOccasion ? 0 : occasion.value)}
                    />
                  </div>
                ))
              }
            </Stack>
          </div>
        </Stack>
        {error &&
          <Alert severity="error">
            {error}
          </Alert>
        }
        <Fab className="confirmBtn" disabled={loading} variant="extended" color="primary" onClick={() => { confirmOrder() }}>
          <CheckIcon fontSize="small" sx={{ mr: 1 }} />
          Confirm
          {loading && (
            <CircularProgress
              sx={{
                color: 'white',
                position: 'absolute',
                zIndex: 1,
              }}
            />
          )}
        </Fab>
      </Stack>
    </BottomDrawer>
  );
}

export default ConfirmReservationDrawer