import React, { useEffect, useContext, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Stack, Typography, Button } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import AliceCarousel from "react-alice-carousel";
import { pathOr } from "ramda";

import BottomDrawer from "bos_common/src/components/BottomDrawer";
import { isZeroAndStore } from 'bos_common/src/services/merchantService';

import { AppContext, CartItemType } from "../../context/AppContext";
import { getItemQuantity, getTotalItems } from "../../services/cartUtils";
import { zeroAndInsulatedBagIds } from "../MerchantMenu/StoreLocation/utils"
import CartItemCard from "./CartItemCard";
import { LOCALSTORAGE_MARKETPLACE_APP_KEYS } from "../../services/localStorage";
import { isMerchandiseAvailable } from "../../utils";
import eventTrackingService from "../../services/Tracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../../services/Tracking/events";


const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: '0 1rem 2rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& .alice-carousel': {
      '& .alice-carousel__dots': {
        margin: 0,
      },
    }
  }
}));

enum RecActionType {
  ADD = 'add',
  UNDO = 'undo',
}

type RecommendItemType = CartItemType & { recReason: string, action: RecActionType }

type ShoppingCartRecDrawerProps = {
  open: boolean;
  setOpen: (_: boolean) => void;
}

export const ShoppingCartRecDrawer = (props: ShoppingCartRecDrawerProps): JSX.Element | null => {
  const { open, setOpen } = props;
  const classes = useStyles();
  const { cart, addItemToCart, removeItemFromCart, merchantConfig } = useContext(AppContext);
  const [recItems, setRecItems] = useState<RecommendItemType[]>([]);
  const [title, setTitle] = useState<string>("Bags");

  useEffect(() => {
    const merchant = merchantConfig?.merchant;
    const items = [];

    if (merchant) {
      const bagItem = merchant.orderingConfig?.carryoutBag;
      const utensilsItem = merchant.orderingConfig?.utensils;

      const total = getTotalItems(cart);
      const bagCount = getItemQuantity(cart, bagItem?.id || "");
      const utensilsCount = getItemQuantity(cart, utensilsItem?.id || "");
      const isUtensilsAvailable = pathOr(false, ["orderingConfig", "utensilsAvailable"], merchant);


      if (bagItem && isMerchandiseAvailable(bagItem)) {
        const nonBagItemCount = total - bagCount - utensilsCount;
        const recBagCount = nonBagItemCount > 2  ? Math.ceil(nonBagItemCount / 4) : 0;

        if (isZeroAndStore(merchant) && recBagCount > 0) {
          items.push({
            ...bagItem,
            quantity: recBagCount > bagCount ? recBagCount - bagCount : bagCount,
            recReason: `To help carry the ${total} drinks and cakes`,
            action: RecActionType.ADD,
          })
          const cachedValue = window.sessionStorage.getItem(LOCALSTORAGE_MARKETPLACE_APP_KEYS.CART_RECOMMENDATION_DRAWER);
          if (!cachedValue || JSON.parse(cachedValue) !== false) {
            setOpen(true);
            setTitle('Recommended items')
          }
        } else {
          items.push({
            ...bagItem,
            quantity: 1,
            recReason: ``,
            action: RecActionType.ADD,
          })
        }
      }

      if (isZeroAndStore(merchant)) {
        const insulatedBag = merchantConfig?.merchandises.find(
          (item) => zeroAndInsulatedBagIds.includes(item.id)
        );
        if (insulatedBag && isMerchandiseAvailable(insulatedBag)) {
          items.push({
            ...insulatedBag,
            quantity: 1,
            recReason: ``,
            action: RecActionType.ADD,
          })
        }
      }

      if (utensilsItem && isUtensilsAvailable) {
        items.push({
          ...utensilsItem,
          quantity: 1,
          recReason: ``,
          action: RecActionType.ADD,
        })
      }

      setRecItems(items)
    }
  }, [merchantConfig?.merchant.id]);

  if (!merchantConfig || !open) {
    return null;
  }

  const updateCart = (recItems: RecommendItemType[], item: RecommendItemType, index: number) => {
    if (item.action === RecActionType.ADD) {
      if (item.quantity > 0) {
        removeItemFromCart({ merchandiseId: item.id, modifiersSignature: item.modifiersSignature });
        addItemToCart(item, merchantConfig.merchant);
      }
      setOpen(false);
    } else if (item.action === RecActionType.UNDO) {
      const cartCopy = [...cart];
      let toRemove = item.quantity;
      cartCopy.forEach((cartItem) => {
        if (cartItem.id === item.id && toRemove > 0) {
          toRemove--;
          removeItemFromCart({ merchandiseId: cartItem.id, modifiersSignature: cartItem.modifiersSignature });
        }
      })
      recItems[index].action = RecActionType.ADD;
      setRecItems([...recItems]);
    }

    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.SHOPPING_CART,
      action: item.action === RecActionType.ADD
        ? EVENT_ACTIONS.CLICK_ITEM_ADDED
        : EVENT_ACTIONS.CLICK_ITEM_REMOVED,
      data: {
        merchant: merchantConfig.merchant,
      },
      label: item.name,
    })
  }

  return (
    <BottomDrawer
      open={open}
      title={<Typography variant='h6' component='div' color='textPrimary'>{title}</Typography>}
      setOpen={setOpen}>
      <div className={classes.root}>
        <AliceCarousel
          items={recItems.map((item, index) => (
            <Stack key={index} spacing={1} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', p: 1 }}>
              <CartItemCard item={item} onChangeQuantity={(quantity: number, change: number) => {
                recItems[index].quantity = recItems[index].quantity + change;
                setRecItems([...recItems]);
              }} />
              <Button sx={{mt: 2}} color="primary" variant="contained" fullWidth onClick={() => updateCart(recItems, item, index)}>
                Add to cart
              </Button>
              {item.recReason && <Typography variant="subtitle2" display="flex" alignSelf={"center"} >
                <InfoOutlined /> &nbsp;&nbsp;{item.recReason}
              </Typography>}
            </Stack>
          ))}
          disableButtonsControls
          mouseTracking
          infinite={false}
          autoWidth
          responsive={{ 0: { items: 2 }, 800: { items: 4 } }}
        />
      </div>
    </BottomDrawer>
  );
}

export default ShoppingCartRecDrawer;
