import { Review } from "../../../bos_common/src/types/ReviewType";
import { RootState } from "../../store";

export const getReviewLoadingStatus = (state: RootState): boolean => state.review.loading;

export const getReviewUpdatingStatus = (state: RootState): boolean => Boolean(state.review.updating);

export const isEarnRewardsDrawerShown = (state: RootState): boolean => state.review.ui.earnRewardsShown;

export const getReviewsByOrder = (state: RootState) => (orderId: string): Review[] => {
  const { entities } = state.review;
  return entities.byOrder[orderId] 
    ? entities.byOrder[orderId].map((id: number) => entities.byId[id])
    : [];
}
