import React, { useContext, Dispatch, SetStateAction } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Badge, Fab, useMediaQuery, Theme } from "@material-ui/core";
import { ShoppingCart, Search as SearchIcon } from "@material-ui/icons";
import { Else, If, Then } from "react-if";

import { FabContainer } from "../bos_common/src/components/FabContainers";
import PromotionButton from "./Promotions/PromotionButton";
import { AppContext } from "../context/AppContext";
import { VisitedMerchantContext } from "../context/VisitedMerchantContext/VisitedMerchantContext";
import { getTotalItems } from "../services/cartUtils";
import eventTrackingService from "../services/Tracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../services/Tracking/events";
import { useAppSelector } from "../redux/hooks";
import { getUser } from "../redux/slice/auth/authSelector";
import { getOfflineMerchantMessage, isEmptyOrNil, isFollowMerchantEnabled, isKioskDevice } from "../utils";
import FollowMerchantCTA from "./FollowMerchant/FollowMerchantCTA";
import { Merchant, MerchantCoupon } from "../services/models";
import { getEventLabel } from "../services/Tracking/EventsTracking";

interface IMerchantFabParams {
  merchantUserName: string,
}

interface IMerchantFabProps {
  onShowCart: Dispatch<SetStateAction<boolean>>,
  onSearch?: () => void,
  coupon?: MerchantCoupon,
}

const MerchantFab = (props: IMerchantFabProps) => {
  const { onShowCart, onSearch, coupon } = props;
  const { cart, orderType } = useContext(AppContext);
  const { visitedMerchant: merchant, isMerchantOrderingAvailable, visitedMenu } = useContext(VisitedMerchantContext);
  const { merchantUserName } = useParams<IMerchantFabParams>();

  const user = useAppSelector(getUser);
  const history = useHistory();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const isKioskModeEnabled = isKioskDevice();
  const merchandises = visitedMenu?.merchandises;

  const openShoppingCart = () => {
    if (isKioskModeEnabled) {
      onShowCart(true)
    } else {
      history.push({ pathname: "/cart", state: { from: `/${merchantUserName}` } })
    }

    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.MERCHANT_MENU,
      action: EVENT_ACTIONS.CLICK_GOTO_CART,
      label: getEventLabel(merchant ?? {} as Merchant),
      data: { merchant, user }
    })
  }

  return (
    <FabContainer
      alignment='right'
      fabStyle={{ padding: '24px 16px 16px', zIndex: 1100 /*to overlap AppBar's default z-index (1100) */ }}
      fadeTop
    >
      <div className="fabContent" style={{ display: 'flex', alignItems: 'center' }}>
        <If condition={!isEmptyOrNil(onSearch)}>
          <Fab
            variant="extended"
            sx={{
              padding: (theme) => `${theme.spacing(1)} !important`,
              color: (theme) => theme.palette.primary.main,
              backgroundColor: 'white'
            }}
            onClick={() => onSearch && onSearch()} >
            <SearchIcon sx={{ padding: '0px !important' }} />
          </Fab>
        </If>

        <If condition={!isMobile}>
          {/* <FollowMerchantCTA merchant={merchant!} /> */}
          <PromotionButton merchant={merchant} merchandises={merchandises} coupon={coupon} />
        </If>

        <Fab
          disabled={!isMerchantOrderingAvailable}
          variant="extended"
          color="primary"
          onClick={openShoppingCart}
          className="shoppingCartButton"
        >
          <If condition={isMerchantOrderingAvailable}>
            <Then>
              <>
                <Badge
                  badgeContent={getTotalItems(cart)}
                  sx={{ fontWeight: "bold", color: "#fff", marginRight: "8px" }}>
                  <ShoppingCart />
                </Badge>
                {'Shopping Cart'}
              </>
            </Then>
            <Else>{getOfflineMerchantMessage(orderType, merchant)}</Else>
          </If>
        </Fab >
      </div>
    </FabContainer>
  )
}

export default MerchantFab;