import React from "react"
import { Button, createStyles, makeStyles, Stack, Theme, Typography } from "@material-ui/core";
import { rgba } from "polished";

import CustomDrawer from "../CustomDrawer";
import ChatBannerImg from "../../assets/images/chat-banner.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { getMobileOS } from "../../utils";

const useStyle = makeStyles((theme: Theme) => createStyles({
  root: {
    background: rgba(theme.palette.info.light, 0.05),
    borderRadius: theme.spacing(2.5),

  },
  drawer: {
    '& .drawerImage': {
      maxWidth: 'unset',
      height: 250,
      objectFit: 'cover',
      background: theme.palette.primary.main,
    },

    '& .MuiCardContent-root': {
      padding: 0,
    }
  }
}))

type DownloadAppDrawerProps = {
  isOpen: boolean;
  setIsOpen: (_: boolean) => void;
}

const DownloadAppDrawer = ({ isOpen, setIsOpen }: DownloadAppDrawerProps) => {
  const classes = useStyle();

  const osName = getMobileOS();

  const url = osName === 'iOS'
    ? "https://apps.apple.com/id/app/one-market/id1562275335"
    : "https://play.google.com/store/apps/details?id=app.onem.marketplace";

  return (
    <CustomDrawer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={'Chat with local customers like you, grab a coffee together, and even enjoy exclusive contents'}
      promotionImage={ChatBannerImg}
      className={classes.drawer}
    >
      <Stack margin="auto" gap={2} p={2}>
        <Stack direction="row" className={classes.root} p={2} alignItems="center" gap={2}>
          <Logo />

          <Stack textAlign="left" flexGrow={1}>
            <Typography variant="h6" component="div" >One Market App</Typography>
            <Typography variant="body2" component="div" >Get to know your local business and customers</Typography>
          </Stack>

          <Button target="_blank" href={url} variant="contained" sx={{ borderRadius: 50 }} color="primary">Get</Button>
        </Stack>

        <Button variant="outlined" fullWidth onClick={() => setIsOpen(false)}>Later</Button>
      </Stack>
    </CustomDrawer>
  )
}

export default DownloadAppDrawer;