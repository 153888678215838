import React, { useContext, useState } from "react";
import { rgba } from "polished";
import { useHistory, useLocation, NavLink, useRouteMatch } from "react-router-dom";
import {
  Box, createStyles, Divider, Drawer, List, ListItem, ListItemText, makeStyles,
  Theme, Typography, ListItemIcon, Toolbar, Icon, Button, Avatar
} from "@material-ui/core"
import { AccountBalanceWallet, Logout, ChevronRight, Login, Menu, Star, AccessTimeFilled, AccountCircle, Support} from "@material-ui/icons"
import { Else, If, Then } from "react-if";

import { logout } from "../../bos_common/src/services/auth0";
import { UserContext } from "../../bos_common/src/context/UserContext";
import { LOCALSTORAGE_APP_KEYS } from "../../bos_common/src/constants";
import VersionNumber from "../../bos_common/src/components/VersionNumber";
import { User } from "../../services/models";
import { isEmbeddedMode, isEmptyOrNil } from "../../utils";

import { ReactComponent as AppLogoIcon } from '../../assets/icons/logo.svg';
import SignupDrawer, { SignupEntrance } from "../User/SignupDrawer";
import OneMarketIcon from "../../assets/icons/OneMarketIcon";
import CustomizedChip from "./CustomizedChip";
import OrdersMenuIcon from "../../assets/icons/OrdersMenuIcon";
import eventTrackingService from "../../services/Tracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../../services/Tracking/events";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getSignUpDrawerUIProperties } from "../../redux/slice/auth/authSelector";
import { showSignUpDrawer } from "../../redux/slice/auth/authActions";
import GemMonoIcon from "../../assets/icons/GemMonoIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: 300,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),

      '& .MuiButton-root': {
        textTransform: 'capitalize',
        justifyContent: 'flex-start',

        '& .MuiButton-startIcon': {
          marginRight: theme.spacing(2)
        }
      },

      '& .toolbar': {
        display: "flex",
        padding: 0,
        marginBottom: theme.spacing(2),

        '& .MuiIconButton-root': {
          paddingRight: 0,
        },
      },
      '& .content': {
        flexGrow: 1,
        '& .MuiListItem-root': {
          color: theme.palette.info.light,
          borderRadius: '10px',
          marginBottom: theme.spacing(1),

          '& .MuiSvgIcon-root': {
            color: theme.palette.info.light,
            fontSize: 18,
          },

          '&.selected': {
            backgroundColor: rgba(theme.palette.primary.main, 0.25),
            color: theme.palette.primary.main,
            '& .MuiSvgIcon-root': { color: theme.palette.primary.main },
          }
        },
      }
    },
  }),
);

declare let window: any;

const MENU_ITEMS_LIST = [
  // {
  //   path: '/messages',
  //   label: 'Messages',
  //   key: 'messages',
  //   icon: <Message />
  // },
  // {
  //   path: '/wallet',
  //   label: 'Wallet',
  //   key: 'wallet',
  //   icon: <AccountBalanceWallet />
  // },
  {
    path: '/',
    label: 'Discover',
    key: 'home',
    icon: <Icon component={OneMarketIcon} />
  },
  // {
  //   path: '/lootbox',
  //   label: 'Lootbox',
  //   key: 'lootbox',
  //   icon: <LootBoxIcon color="secondary"/>
  // },
  {
    path: '/1mpoints',
    label: '1M Points',
    key: '1mpoints',
    icon: <GemMonoIcon sx={{ width: 16, height: 16}} />
  },
  {
    path: '/orders',
    label: 'Orders',
    key: 'orders',
    icon: <OrdersMenuIcon />
  },
  {
    path: '/subscription-settings',
    label: 'Subscriptions & Favorites',
    key: 'subscription-settings',
    icon: <Star />
  },
  {
    path: '/payment-method',
    label: 'Payment',
    key: 'payment',
    icon: <AccountBalanceWallet />
  },
  {
    path: '/visit-history',
    label: 'Visit History',
    key: 'visit_history',
    icon: <AccessTimeFilled color="primary" />
  },
  {
    path: '/settings',
    label: 'Profile & Preference',
    key: 'settings',
    icon: <AccountCircle />
  },
  // {
  //   path: '/content-feed',
  //   label: 'Content Feed',
  //   key: 'home',
  //   icon: <Feed />
  // },
]

export const excludeRedirect = [
  {
    path: /\/order\/\w+\/reviews/,
    to: (id: string): string => `order/${id}`,
    paramsName: 'orderId'
  }
]

const MenuIconRenderer = (props: {
  value: string,
  path: string,
  label: string,
  icon: React.ReactNode
}) => {
  const { value, path, label, icon } = props

  return (
    <ListItem button key={value} component={NavLink} to={path} exact activeClassName="selected" >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={label} />
      <ChevronRight />
    </ListItem>
  )
}

interface MenuDrawerProps {
  menuButtonProps?: {
    color?: "primary" | "secondary" | "inherit",
    variant?: 'text' | 'outlined' | 'contained'
  }
}

export const MenuDrawer = ({ menuButtonProps }: MenuDrawerProps): React.ReactElement => {
  const history = useHistory()
  const classes = useStyles()
  const location = useLocation()
  const routeMatch = useRouteMatch();
  const { user, setAuthenticating, setLoginState } = useContext(UserContext)

  const openDrawer = location.hash?.includes('openDrawer')
  const [drawerOpen, setDrawerOpen] = useState(openDrawer || false);
  const signUpDrawerUIProperties = useAppSelector(getSignUpDrawerUIProperties);
  const reduxDispatch = useAppDispatch();

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
    // remove the hash without refreshing the page
    if (!open && openDrawer) {
      window.location.hash = ''
    }
  }

  const userLogout = () => {
    setLoginState(undefined, undefined)
    sessionStorage.removeItem(LOCALSTORAGE_APP_KEYS.USER_PHONE_NUMBER);
    sessionStorage.removeItem(LOCALSTORAGE_APP_KEYS.USER_DISPLAY_NAME);

    if (isEmbeddedMode() && window?.webkit && window?.webkit.messageHandlers.logoutMessageHandler) {
      window?.webkit.messageHandlers.logoutMessageHandler.postMessage('');
    } else if (isEmbeddedMode() && window?.logoutMessageHandler) {
      window.logoutMessageHandler.postMessage('')
    } else {
      setAuthenticating(true)
      const match = excludeRedirect.find((item) => item.path.test(location.pathname));

      match
        ? logout(`${window.location.origin}/logout`, match?.to(routeMatch.params[match.paramsName]))
        : logout(`${window.location.origin}/logout`, location.pathname);
    }
  }

  const handleLoginClick = () => {
    reduxDispatch(showSignUpDrawer({
      drawerShown: true,
      signupEntrance: SignupEntrance.USER_LOGIN,
    }));
    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.HAMBURGER_MENU,
      action: EVENT_ACTIONS.CLICK_LOGIN,
      label: ''
    })
  }

  const setSignupDrawerOpen = (open: boolean) => {
    reduxDispatch(showSignUpDrawer({
      drawerShown: open,
      signupEntrance: signUpDrawerUIProperties?.signupEntrance,
    }));
  }

  const OneMarketSupportButton = (
    <ListItem
      button
      key={'one-market-support-button'}
      component={'a'}
      href="https://docs.google.com/forms/d/1HA3tBbmJXt63FYo9NLfE5oCRpKgXiwgXlY0UV7SmeHA/edit"
      target="_blank"
    >
      <ListItemIcon><Support /></ListItemIcon>
      <ListItemText primary={'Contact Support'} />
    </ListItem>
  )

  const RenderAnonymousList = () => {
    return (
      <Box
        className={classes.drawer}
        role="presentation">
        <Toolbar className="toolbar" sx={{ justifyContent: 'center' }}>
          <div>
            <img src={process.env.PUBLIC_URL + '/delivery-guy-handing-box.png'} width={185} />
            <Typography variant="h4" component="div" color="primary" sx={{ marginTop: 2 }}>
              <Icon component={AppLogoIcon} />&nbsp;One Market
            </Typography>
          </div>
        </Toolbar>
        <Divider />
        <List className='content'>
          <MenuIconRenderer
            value="discover"
            path="/"
            label="Discover"
            icon={<OneMarketIcon />}
          />
          <MenuIconRenderer
            value="visit-history"
            path="/visit-history"
            label="Visit History"
            icon={<AccessTimeFilled color="secondary" />}
          />
          {OneMarketSupportButton}
        </List>

        <Divider />
        {/* {displayMarketPlaceButton() && MarketPlaceButton} */}
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<Login />}
          fullWidth
          sx={{ marginBottom: 2, marginTop: 2 }}
          className="drawer-action-btn"
          onClick={handleLoginClick}
        >
          Login to enjoy more!
        </Button>
        <VersionNumber />
      </Box>
    )
  }

  const RenderUserList = (currentUser: User | undefined) => {
    if (!currentUser) {
      return null
    }

    const handlePointsClick = () => {
      history.push('/1mpoints');
    }

    return (
      <Box
        className={classes.drawer}
        role="presentation">
        <Toolbar className="toolbar">
          <Avatar src={user?.picture} alt="Profile-pic" sx={{ width: 56, height: 56, marginRight: 2 }} />
          <div>
            <Typography variant="h6" component="div">
              {currentUser.displayName || currentUser.phoneNumber}
            </Typography>
            <CustomizedChip
              label={user?.points ?? 0}
              icon={<GemMonoIcon sx={{ width: 14, height: 14}} />}
              onClick={handlePointsClick}
              color="secondary"
              variant="filled"
              size="small"
            />
          </div>
        </Toolbar>
        <Divider />
        <List className='content'>
          {
            MENU_ITEMS_LIST.map((item) => (
              <MenuIconRenderer
                key={item.key}
                value={item.key} // added value prop to remove console warning
                path={item.path}
                label={item.label}
                icon={item.icon}
              />
            ))
          }
          {OneMarketSupportButton}
        </List>

        <Divider />
        {/* {displayMarketPlaceButton() && MarketPlaceButton} */}

        <Button
          variant="outlined"
          color="primary"
          size="large"
          startIcon={<Logout />}
          fullWidth
          sx={{ marginBottom: 2, marginTop: 1 }}
          className="drawer-action-btn"
          onClick={userLogout}
        >
          Log Out
        </Button>
        <VersionNumber />
      </Box>
    )
  }

  return (
    <div>
      <Button
        variant="text"
        color="inherit"
        onClick={toggleDrawer(true)}
        aria-label="open drawer"
        sx={{ borderRadius: '50%', padding: '9px', minWidth: 'unset', boxShadow: 'unset' }}
        className="drawerButton"
        {...menuButtonProps}
      >
        <Menu fontSize="large" sx={{ padding: '0 !important' }} />
      </Button>
      <Drawer
        variant="temporary"
        ModalProps={{ keepMounted: true }}
        anchor={'right'}
        open={drawerOpen}
        onClose={toggleDrawer(false)}>
        <If condition={!isEmptyOrNil(user)}>
          <Then>
            {RenderUserList(user)}
          </Then>
          <Else>
            {RenderAnonymousList()}
          </Else>
        </If>
      </Drawer>

      {signUpDrawerUIProperties && <SignupDrawer
        open={signUpDrawerUIProperties.drawerShown}
        setOpen={setSignupDrawerOpen}
        entrance={signUpDrawerUIProperties.signupEntrance ?? SignupEntrance.USER_LOGIN}
      />}
    </div>
  )
}