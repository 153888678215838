import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Order } from "../../../bos_common/src/types/OrderTypes";
import { getOrder, putOrder } from "../../../services/ordersApiService";
import { getAuthHeaders } from "../../../utils";
import { RootState } from "../../store";
import { getToken } from "../auth/authSelector";

export const ordersFetching = createAction('order/setLoading');

export const ordersFetchSucceed = createAction<Order[]>('order/updateOrder');

export const ordersFetchFailed = createAction('order/stopLoading');

export const fetchOrderDetail = createAsyncThunk(
  'review/fetchOrderDetail',
  async (id: string, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const token = getToken(state);
    const response = await getOrder(id, { headers: getAuthHeaders(token) });
    return [response.data];
  }
);

export const updateOrderDetail = createAsyncThunk(
  'review/updateOrderDetail',
  async (order: Partial<Order>, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const token = getToken(state);
    const response = await putOrder(order, { headers: getAuthHeaders(token) });
    return [response.data];
  }
);
