import React from 'react'
import { Card, CardContent, createStyles, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formGroup: {
      '& .MuiFormLabel-root': {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      '& .MuiFormControlLabel-root': {
        marginLeft: theme.spacing(1),
        width: '100%',
      },
      '& .MuiFormHelperText-root': {
        marginLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
      '& .MuiTextField-root': {
        padding: theme.spacing(2),
        paddingTop: 0
      },
    }
  }),
);


interface FormGroupCardWrapper {
  elevation?: number,
  id?: string | number,
  children: React.ReactNode
}

const FormGroupCardWrapper = (props: FormGroupCardWrapper): React.ReactElement => {
  const classes = useStyles();
  const { id = '', elevation = 3 } = props;

  return (
    <CardContent key={id} className={classes.formGroup}>
      <Card elevation={elevation}>
        {props.children}
      </Card>
    </CardContent>
  )
}

export default FormGroupCardWrapper;