import { pathOr } from "ramda";

// src
import { isCouponValid, getItemsEligibleForCoupon } from "../bos_common/src/services/CouponUtils";
import { isPromotionValid } from "../bos_common/src/services/PromotionUtils";
import { MerchantOrderingConfig } from "../bos_common/src/types/MerchantOrderingConfigType";
import { MerchantPromotion, PromotionCriteriaType } from "../bos_common/src/types/MerchantPromotionType";
import config from "../config/config";

import { CartItemType, MerchantCartConfig, TipData } from "../context/AppContext"
import { isEmptyOrNil, toFixed2 } from "../utils";
import { CouponValueType, ItemType, MerchantCoupon } from "./models";

export const getTotalItems = (cart: CartItemType[]): number => {
  return cart.reduce((a: number, b: CartItemType) => a + b.quantity, 0)
}

export const getItemQuantity = (cart: CartItemType[], itemId: string | number, itemType?: ItemType): number => {
  return cart.reduce((a: number, b: CartItemType) => {
    const selectedItem = itemType === ItemType.CATEGORY ? b.categoryId === itemId : b.id === itemId
    if (selectedItem) return a + b.quantity;
    return a;
  }, 0)
}

// 0.075 is the california sales tax rate
// by default use 0.075 if merchant has not configured a tax rate yet
const getMerchantTaxRate = (config?: MerchantOrderingConfig) => Number(config?.taxRate || 0.075)

export const getItemsRedeemAmount = (list: CartItemType[]): number => {
  return toFixed2(list.reduce((a: number, b: CartItemType) => a + (b.quantityRedeemed || 0) * b.price, 0));
}

export const getCartDiscountedAmount = (cart: CartItemType[], coupon: MerchantCoupon | undefined): number => {
  if (!isCouponValid(coupon)) return 0;
  if (!isEmptyOrNil(coupon?.promotion) && !isPromotionValid(coupon?.promotion)) return 0;

  const filteredCart = getItemsEligibleForCoupon(cart, coupon, true) || [];
  const total = getTotalItems(filteredCart);
  const newSubtotal = getSubtotalPrice(filteredCart);

  const allCriteria = [
    ...(coupon?.criteriaList || []),
    {
      type: coupon?.criteriaType,
      value: coupon?.criteriaValue,
    },
  ];
  for (const criteria of allCriteria) {
    switch (criteria?.type) {
      case PromotionCriteriaType.MINIMUM_QUANTITY:
        if (total < Number(criteria?.value || 0)) {
          return 0;
        }
        break;
      case PromotionCriteriaType.MINIMUM_SPEND:
        if (newSubtotal < Number(criteria?.value)) {
          return 0;
        }
        break;
      case PromotionCriteriaType.DEVICE_TYPE:
        if (config.appConfig.deviceType !== criteria.value) {
          return 0;
        }
        break;
      case PromotionCriteriaType.NONE:
      default:
        break;
    }
  }

  switch (coupon?.valueType) {
    case CouponValueType.PERCENTAGE:
    case CouponValueType.BUY_ONE_GET_ONE_FREE:
    case CouponValueType.BUY_ONE_GET_ONE_ONLY_FREE:
    case CouponValueType.BUY_ONE_GET_ONE_PERCENTAGE:
    case CouponValueType.BUY_ONE_GET_ONE_ONLY_PERCENTAGE:
    case CouponValueType.BUY_X_GET_Y_FREE:
    case CouponValueType.GET_ONE_FREE:
    case CouponValueType.COMBO_ABSOLUTE:
    case CouponValueType.COMBO_PERCENTAGE:
      return cart.reduce((total, a) => total + Number(a.couponRedeemedAmount || 0), 0);
    case CouponValueType.ABSOLUTE:
    default:
      return Math.min(coupon?.value || 0, newSubtotal);
  }
}

export const getTotalTip = (cart: CartItemType[], tip: TipData): number => {
  const tipRate = tip.tipRate
  if (tipRate) {
    return toFixed2(cart.reduce((a: number, b: CartItemType) => a + b.quantity * b.price * tipRate, 0));
  } else if (tip.customTip) {
    return tip.customTip
  }
  return 0
}

export const getSubtotalPrice = (cart: CartItemType[]): number => {
  return toFixed2(cart.reduce((a: number, b: CartItemType) => a + b.quantity * Number(b.price), 0));
}

const _getSubtotalToPay = (subtotal: number, discountedAmount: number, redeemedAmount: number): number => {
  return toFixed2(Math.max(subtotal - redeemedAmount - discountedAmount, 0));
}

export const getSubtotalToPay = (cart: CartItemType[], coupon?: MerchantCoupon): number => {
  const subtotal = getSubtotalPrice(cart);
  const discountedAmount = getCartDiscountedAmount(cart, coupon);
  const redeemedAmount = getItemsRedeemAmount(cart);
  return _getSubtotalToPay(subtotal, discountedAmount, redeemedAmount);
}

const _getTotalTax = (subtotalToPay: number, orderingConfig: MerchantOrderingConfig | undefined): number => {
  const merchantTaxRate = getMerchantTaxRate(orderingConfig)
  return toFixed2(subtotalToPay * merchantTaxRate);
}

export const getTotalTax = (cart: CartItemType[], coupon: MerchantCoupon | undefined, orderingConfig: MerchantOrderingConfig | undefined): number => {
  const subtotalToPay = getSubtotalToPay(cart, coupon);
  return _getTotalTax(subtotalToPay, orderingConfig);
}

// Todo: this should be on backend to avoid client side tempering
export const getTotalPrice = (params: { cart: CartItemType[], tip: TipData, coupon: MerchantCoupon | undefined, orderingConfig?: MerchantOrderingConfig }): number => {
  const {
    cart = [],
    tip,
    coupon,
    orderingConfig,
  } = params;
  const subtotalToPay = getSubtotalToPay(cart, coupon);
  const tipAmount = getTotalTip(cart, tip);
  const taxAmount = _getTotalTax(subtotalToPay, orderingConfig);
  return toFixed2(subtotalToPay + tipAmount + taxAmount);
}

export const getPriceBreakdown = (
  cart: CartItemType[],
  tip: TipData,
  coupon: MerchantCoupon | undefined,
  orderingConfig: MerchantOrderingConfig | undefined,
  promotion?: MerchantPromotion,
): Array<[string, number]> => {
  const breakdowns = new Array<[string, number]>()
  const subtotal = getSubtotalPrice(cart)
  const discountedAmount = getCartDiscountedAmount(cart, coupon)
  const redeemAmount = getItemsRedeemAmount(cart)
  const subtotalToPay = _getSubtotalToPay(subtotal, discountedAmount, redeemAmount)
  breakdowns.push(["Subtotal", subtotal])
  if (discountedAmount > 0) {
    breakdowns.push([`"${coupon?.code || "coupon"}"`, -discountedAmount]);
  }
  if (redeemAmount > 0) {
    breakdowns.push(["1M Points redeemed", -redeemAmount]);
  }
  breakdowns.push(["Taxes", _getTotalTax(subtotalToPay, orderingConfig)])
  const tipVal = getTotalTip(cart, tip)
  if (tipVal > 0) {
    breakdowns.push(["Tip", tipVal])
  }
  return breakdowns
}

const defaultTipRatesConfig = [
  { tipRate: 0.18 },
  { tipRate: 0.2 },
  { tipRate: 0.15 },
]

export const getMerchantTipRatesConfig = (merchantConfig?: MerchantCartConfig): Array<TipData> => {
  const tipOptions = pathOr([], ['merchant', 'orderingConfig', 'tipOptions'], merchantConfig)
  if (isEmptyOrNil(tipOptions)) return defaultTipRatesConfig
  return tipOptions
}

export * from '../bos_common/src/services/orderPricingUtils'