import React from 'react'
import { Button } from '@material-ui/core'
import MerchantItemCard from "../common/MerchantItemCard"
import { isEmptyOrNil } from '../../utils'
import { UserPromotionEntry } from '../../services/models'

interface PromotionsEntriesProps {
  setOpen: (_: boolean) => void,
  userPromotionEntries: UserPromotionEntry[] | undefined,
}

const MerchantPromotionsEntries = ({ setOpen, userPromotionEntries }: PromotionsEntriesProps) => {
  if (isEmptyOrNil(userPromotionEntries))
    return null;

  return (
    <>
      {userPromotionEntries?.map((item, idx) =>
        <MerchantItemCard
          key={item.merchant.id}
          merchant={item.merchant}
          promotionText={item.promotion.oneliner}
          gutterBottom={idx !== userPromotionEntries.length - 1}
        />
      )}
      <Button color="primary" variant="contained" onClick={() => setOpen(false)} sx={{ marginTop: '16px' }}>
        Done
      </Button>
    </>
  )
}


export default MerchantPromotionsEntries