import FormLabel from '@material-ui/core/FormLabel';
import React from 'react';
import StarReview from '../common/StarReview';

interface RatingFieldProps {
  onChangeRating: (fieldName: string, value: number) => void;
  fieldName: string;
  value: number;
  label: string;
}

export const RatingField = (props: Readonly<RatingFieldProps>): JSX.Element => {
  const { fieldName, onChangeRating, label } = props;
  const value = props.value || 0;

  return (
    <FormLabel component="legend" style={{ border: "1px solid rgba(0, 0, 0, 0.1)", padding: '1rem', display: 'flex', }}>
      <span style={{ textTransform: 'capitalize' }}>{label}</span>
      <StarReview onChangeRating={(value: number) => onChangeRating(fieldName, value)} value={value} />
    </FormLabel>
  );
}

export default RatingField;
