import { ascend, find, map, pathOr, pipe, prop, propEq, sort } from "ramda";
import { AxiosResponse } from "axios";
import assert from "assert";

import axios from "../bos_common/src/services/backendAxios";
import { isCouponValid } from "../bos_common/src/services/CouponUtils";
import { AppContextStateType, CartItemType } from "../context/AppContext"
import { getAuthHeaders, getRequiredRedemptionPoints, isCartFromAnOpenCheckOrder, isEmptyOrNil } from "../utils"
import {
  getCartDiscountedAmount, getItemsRedeemAmount,
  getTotalPrice, getTotalTax, getTotalTip
} from "./cartUtils"
import { LineItem, MerchandiseCategory, Order, OrderStatus, OrderType, PreAuthInfo, PreAuthStatus, User } from "./models"
import config from "../config/config";
import { getStoredTimedKey, LOCALSTORAGE_MARKETPLACE_APP_KEYS } from "./localStorage";
import { PaymentMethod } from "@stripe/stripe-js";

type CreateOrderServiceParams = {
  appContext: AppContextStateType,
  paymentIntentId?: string,
  name?: string,
  user?: User,
  token?: string,
  orderStatus?: OrderStatus,
  phone?: string | null,
  preAuthPaymentMethod?: PaymentMethod
}

const createOrderService = (params: CreateOrderServiceParams): Promise<AxiosResponse<Order>> => {
  const {
    appContext,
    paymentIntentId,
    name,
    user,
    token,
    orderStatus = OrderStatus.PAID,
    phone,
    preAuthPaymentMethod
  } = params;

  const allowedOrderStatus = [OrderStatus.PAID, OrderStatus.OPEN_CHECK];
  assert(allowedOrderStatus.includes(orderStatus));

  const {
    cart, merchantConfig, orderType, tip, pickupTime, coupon,
    tableQrCodeObject, openCheckOrder, cartType, orderPickupType
  } = appContext;
  // NOTE: Temporary, to maintain compatilibity between Redux and Context until fully migrate to Redux.

  const affiliateCode = getStoredTimedKey(LOCALSTORAGE_MARKETPLACE_APP_KEYS.AFFILIATE_DATA);
  const referrerId = getStoredTimedKey(LOCALSTORAGE_MARKETPLACE_APP_KEYS.GIFT_REFERRAL_DATA);

  const isDineinOrderType = orderType === OrderType.DINEIN
  const isOpenCheckOrder = orderStatus === OrderStatus.OPEN_CHECK
  // if Active order is set to OPEN_CHECK mode and application is in Dine In Mode then update the Order
  const isUpdatingOpenCheck = isDineinOrderType && openCheckOrder?.id && openCheckOrder.status === OrderStatus.OPEN_CHECK

  const orderingConfig = merchantConfig?.merchant.orderingConfig;
  const currency = "USD";

  // sort line items based on Categories
  const sortedCartItems: CartItemType[] = pipe(
    map((item: CartItemType) => {
      const categoryItem: MerchandiseCategory | undefined = find(
        propEq('id', item.categoryId),
        pathOr([], ['mercCategories'], merchantConfig)
      );

      return { ...item, categoryOrder: categoryItem?.displayOrder ?? -1 };
    }),
    sort(ascend(prop('categoryOrder')))
  )(cart);

  const orderLineItems = sortedCartItems.map((item) => {
    return {
      id: item.id,
      name: item.name,
      price: item.price,
      quantity: item.quantity,
      note: item.note,
      categoryId: item.categoryId,
      customization: item.modifiers || {},
      customizationDisplayOrder: item.modifiersDisplayOrder,
      customizationSignature: item.modifiersSignature,
      refunded: false,
      remainInventory: undefined,
      quantityRedeemed: item.quantityRedeemed || 0,
      pointsRedeemed: (item.quantityRedeemed || 0) > 0 ? getRequiredRedemptionPoints(item, merchantConfig?.merchant) : 0,
      couponRedeemedAmount: item.couponRedeemedAmount,
      noCoupons: item.noCoupons,
    } as LineItem
  })

  const getPointsRedeemInfo = () => {
    return {
      pointsRedeemed: Math.round(orderLineItems.reduce((acc, item) => acc + (item?.pointsRedeemed ?? 0) * (item.quantityRedeemed || 0), 0)),
      amountRedeemed: getItemsRedeemAmount(cart),
    }
  }

  const getCouponRedemptionInfo = () => {
    if (!coupon || !isCouponValid(coupon)) {
      return null;
    }

    const amountRedeemed = getCartDiscountedAmount(cart, coupon);

    if (amountRedeemed <= 0) {
      return null;
    }

    return {
      couponId: coupon.id,
      couponCode: coupon.code,
      amountRedeemed,
    };
  }

  const getOrderAmountSummary = () => {
    return isOpenCheckOrder ? {
      amount: 0,
      tax: 0,
      tip: 0, // dont assume any tip until client closes open-check
    } : {
      amount: getTotalPrice({ cart, tip, coupon, orderingConfig }),
      tax: getTotalTax(cart, coupon, orderingConfig),
      tip: getTotalTip(cart, tip),
    }
  }

  const getPreAuthInfo = (preAuthPaymentMethod?: PaymentMethod) => {
    if (isOpenCheckOrder && preAuthPaymentMethod) {
      return {
        isPreAuthPayment: true,
        paymentMethod: preAuthPaymentMethod.id,
        preAuthInfo: {
          amount: getTotalPrice({ cart, tip, coupon, orderingConfig }),
          currency,
          connectedAccountCustomerId: '',
          connectedAccountPaymentMethodId: '',
          paymentIntentId: '',
          status: PreAuthStatus.TO_BE_AUTHED,
        } as PreAuthInfo,
      }
    }

    return {};
  }

  const getOrderData = (paymentId: string | null, name?: string, preAuthPaymentMethod?: PaymentMethod): Partial<Order> => {
    if (isUpdatingOpenCheck) {
      return {
        ...((openCheckOrder) && {
          id: openCheckOrder.id
        }),
        userDisplayName: name || user?.displayName || '',
        userId: user?.id,
        currency: currency,
        ...(!isEmptyOrNil(paymentId) && {
          paymentId
        }),
        lineItems: isCartFromAnOpenCheckOrder(cartType) ? [] : [...orderLineItems],
        status: orderStatus,
        tableId: tableQrCodeObject?.id,
        pointsRedemptionInfo: getPointsRedeemInfo(),
        couponRedemptionInfo: getCouponRedemptionInfo(),
        ...(getOrderAmountSummary()),
      } as Partial<Order>;
    }
    return {
      ...((openCheckOrder) && {
        id: openCheckOrder.id
      }),
      userId: user?.id,
      userDisplayName: name || user?.displayName || '',
      merchantId: merchantConfig?.merchant?.id || '',
      description: '',
      currency,
      paymentId,
      lineItems: isCartFromAnOpenCheckOrder(cartType) ? [] : orderLineItems,
      status: orderStatus,
      type: orderType,
      tableId: tableQrCodeObject?.id,
      readyTime: null,
      toPickupTime: pickupTime || new Date(),
      pointsRedemptionInfo: getPointsRedeemInfo(),
      couponRedemptionInfo: getCouponRedemptionInfo(),
      ...(merchantConfig?.merchant.orderingConfig?.enabledEatHere && !isDineinOrderType && {
        pickupType: orderPickupType,
      }),
      ...((!isEmptyOrNil(affiliateCode)) && {
        affiliateRedemptionInfo: {
          affiliateCode
        }
      }),
      sourceInfo: {
        device: config.appConfig.deviceType,
        app: config.appConfig.appType,
      },
      ...(getOrderAmountSummary()),
      ...(getPreAuthInfo(preAuthPaymentMethod)),
    } as Partial<Order>;
  }

  if (isUpdatingOpenCheck) {
    return axios({
      url: `/orders/openCheck/${openCheckOrder?.id}`,
      method: 'PUT',
      data: getOrderData(paymentIntentId ?? null, name),
      ...(token && {
        headers: getAuthHeaders(token)
      })
    })
  } else {
    return axios({
      url: '/orders',
      method: 'POST',
      data: getOrderData(paymentIntentId ?? null, name, preAuthPaymentMethod),
      params: {
        phoneNumber: phone,
        ...(!isEmptyOrNil(referrerId) && {
          referrerId
        })
      },
      ...(token && {
        headers: getAuthHeaders(token)
      })
    })
  }
}

export default createOrderService