import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { Alert, CardContent, createStyles, makeStyles } from "@material-ui/core";
import { If, Then, Else } from 'react-if';

import { ColoredPaper } from '../bos_common/src/components/Papers';
import SimpleLoader from "../bos_common/src/components/SimpleLoader";
import { AppContext } from "../context/AppContext";
import { Merchant, Order, OrderType } from "../services/models";
import { getTotalItems, getTotalPrice } from "../services/cartUtils";

import PageHeader from "../components/common/PageHeader";
import StripePaymentForm from "../components/Payment/StripePaymentForm";
import NoPaymentForm from "../components/Payment/NoPaymentForm";
import BackButton from "../components/common/BackButton";
import { isPaymentDisabled } from "../utils";
import { cleanUpStateAfterOder } from "../services/localStorage";
import { useAppSelector } from "../redux/hooks";
import { getStripeLoadingStatus, getStripeUpdatingStatus } from "../redux/slice/stripe/stripeSelector";
import eventTrackingService from "../services/Tracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../services/Tracking/events";
import { getEventLabel } from "../services/Tracking/EventsTracking";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .content': {
        paddingTop: 0,
      }
    },
  }),
);

type CheckoutPageProps = {
  isPreAuth?: boolean,
}

const CheckoutPage = (props: CheckoutPageProps): React.ReactElement => {
  const history = useHistory();
  const classes = useStyles();
  const { cart, clearCart, merchantConfig, tip, coupon, orderType, tableQrCodeObject } = useContext(AppContext);
  const loading = useAppSelector(getStripeLoadingStatus);
  const updating = useAppSelector(getStripeUpdatingStatus);
  const { isPreAuth = false } = props;

  const count = getTotalItems(cart)
  const amount = getTotalPrice({ cart, tip, coupon, orderingConfig: merchantConfig?.merchant.orderingConfig })
  const isStripePaymentAcceptable = amount >= 0.50
  const isDineInOrder = orderType === OrderType.DINEIN;
  const isPaymentDisabledForDineIn = isDineInOrder && isPaymentDisabled(merchantConfig?.merchant);

  useEffect(() => {
    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.ORDER_CHECKOUT,
      action: EVENT_ACTIONS.CLICK_ORDER_CHECKOUT_START,
      data: {
        merchant: merchantConfig?.merchant
      },
      label: getEventLabel(merchantConfig?.merchant ?? {} as Merchant),
    });
  }, []);

  const onSuccessPayment = (order: Order) => {
    // clear cart
    clearCart();
    cleanUpStateAfterOder();

    history.push({
      pathname: `/${(isPreAuth && isDineInOrder) ? 'oc-order' : 'order'}/${order.id}`,
      state: { new: true }
    });
  }

  const getTitle = () => {
    let stripeTitle = "Payment (test mode)"
    if (merchantConfig?.merchant.production === true) {
      stripeTitle = "Payment"
    }
    const title = isStripePaymentAcceptable ? stripeTitle : "Checkout"

    if (isDineInOrder) {
      return (
        <div style={{lineHeight: 1}}>
          <div>{title}</div>
          <span style={{fontSize: 16}}>Table: {tableQrCodeObject?.identifier}</span>
        </div>
      )
    }

    return title;
  }

  return (
    <div className="container">
      <PageHeader leftChild={<BackButton />} title={getTitle()} />
      <SimpleLoader loading={loading || updating} />
      <ColoredPaper variant="outlined" className={classes.root}>
        <CardContent className={"content"}>
          <If condition={count === 0}>
            <Then>
              <Alert severity="info">
                {"You don't have any item in your cart"}
              </Alert>
            </Then>
            <Else>
              <If condition={isStripePaymentAcceptable}>
                <Then>
                  <If condition={isPaymentDisabledForDineIn}>
                    <Then>
                      <Alert severity="warning">
                        {"Online payment is not supported at this store. Please checkout at the counter or ask for paper check."}
                      </Alert>
                    </Then>
                    <Else>
                      <StripePaymentForm isPreAuth={isPreAuth} onSuccessSubmit={onSuccessPayment} />
                    </Else>
                  </If>
                </Then>
                <Else>
                  <NoPaymentForm onSuccessSubmit={onSuccessPayment} />
                </Else>
              </If>
            </Else>
          </If>
        </CardContent>
      </ColoredPaper>
    </div>
  )

}

export default CheckoutPage