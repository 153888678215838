



import React from 'react'
import { SvgIcon } from "@material-ui/core";

function BreakfastIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M15.752 1.024a.469.469 0 00-.48.023 6.773 6.773 0 00-2.525 3.095A6.16 6.16 0 008 1.906a6.154 6.154 0 00-3.313.96V1.438a.469.469 0 00-.937 0V3.78h-.938V1.438a.469.469 0 00-.937 0V3.78H.937V1.438a.469.469 0 00-.937 0V4.25c0 .765.369 1.446.938 1.874v7.501c0 .775.63 1.406 1.406 1.406.775 0 1.406-.63 1.406-1.406v-1.233a6.155 6.155 0 009.438-1.145v2.378c0 .775.63 1.406 1.406 1.406.775 0 1.406-.63 1.406-1.406V1.438a.469.469 0 00-.248-.414zM2.812 13.625a.47.47 0 01-.937 0V6.547a2.346 2.346 0 00.938 0v7.078zm-.468-7.969a1.409 1.409 0 01-1.326-.937H3.67a1.409 1.409 0 01-1.326.937zM8 13.156a5.18 5.18 0 01-4.25-2.191V6.124a2.342 2.342 0 00.938-1.874v-.227A5.195 5.195 0 018 2.843c1.791 0 3.461.923 4.402 2.42a6.81 6.81 0 00-.152 1.43v3.182c0 .228.163.418.38.46A5.198 5.198 0 018 13.156zm7.063.469a.47.47 0 01-.938 0v-3.281h.938v3.281zm0-4.219h-1.876V6.693c0-1.638.683-3.19 1.876-4.292v7.005z" />
      <path d="M8 6.594c-.775 0-1.406.63-1.406 1.406 0 .775.63 1.406 1.406 1.406.775 0 1.406-.63 1.406-1.406 0-.775-.63-1.406-1.406-1.406zm0 1.875a.47.47 0 11.001-.939A.47.47 0 018 8.47z" />
      <path d="M11.392 6.98a.507.507 0 01-.146-.311l-.052-.56a1.445 1.445 0 00-1.302-1.303l-.56-.052a.507.507 0 01-.311-.146A1.434 1.434 0 008 4.185c-.386 0-.749.15-1.021.423a.507.507 0 01-.31.146l-.561.052a1.445 1.445 0 00-1.303 1.302l-.052.561a.507.507 0 01-.146.31c-.297.297-.454.65-.454 1.021 0 .37.157.724.454 1.02a.507.507 0 01.146.311l.052.56c.065.69.613 1.238 1.303 1.303l.56.052a.507.507 0 01.31.146c.273.273.636.423 1.021.423.386 0 .748-.15 1.02-.423a.507.507 0 01.311-.146l.56-.052a1.444 1.444 0 001.303-1.302l.052-.561a.507.507 0 01.146-.31 1.445 1.445 0 000-2.042zm-.662 1.378a1.447 1.447 0 00-.417.885l-.053.561a.506.506 0 01-.456.456l-.56.053c-.334.031-.649.18-.886.416a.503.503 0 01-.716 0 1.446 1.446 0 00-.885-.416l-.56-.053a.506.506 0 01-.457-.456l-.053-.56a1.446 1.446 0 00-.416-.886c-.241-.241-.241-.475 0-.716.237-.237.385-.551.416-.885l.053-.561a.506.506 0 01.456-.456l.56-.053c.335-.031.65-.18.886-.416a.503.503 0 01.716 0c.237.237.552.385.886.416l.56.053a.506.506 0 01.457.456l.052.56c.031.335.18.65.417.886a.507.507 0 010 .716z" />
    </SvgIcon>
  );
}

export default BreakfastIcon