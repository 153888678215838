import React, { useMemo } from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { propOr } from "ramda";
import AliceCarousel from "react-alice-carousel";
import { If, Then, Else } from "react-if";

import { WhitePaper, isEmptyOrNil } from "bos_common/src";
import { Merchandise } from "bos_common/src/types/MerchandiseType";

import { isKioskDevice } from "../../utils";
import 'react-alice-carousel/lib/alice-carousel.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      minHeight: '250px',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: 'sticky',
      top: theme.spacing(7),

      '& .item': {
        display: "flex",
        justifyContent: "center",

        '& .mercMedia': {
          objectFit: "contain",
          height: 240,
          width: '100%',

          [theme.breakpoints.up('sm')]: {
            height: 300,
          },
          [theme.breakpoints.up('sm')]: {
            height: 400,
          },
        },
      },
    },
  }),
);

const getMediaItems = (photos: string[], videos: string[]) => {
  const handleDragStart = (e: React.DragEvent<HTMLElement>) => e.preventDefault();
  const videosList = isKioskDevice()
    ? []
    : videos.map((video, index) => {
      return (
        <div className={'item'} key={`video-${index}`}>
          <video className={'mercMedia'} controls onDragStart={handleDragStart}>
            <source src={video} type="video/mp4" />
            <source src={video} type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        </div>
      )
    });

  const photosList = photos.map((photo, index) => {
    return (
      <div className={'item'} key={`photo-${index}`}>
        <img className={'mercMedia'} src={photo} onDragStart={handleDragStart} />
      </div>)
  });

  return [...photosList, ...videosList]
}

type MerchandiseMediaProps = {
  merchandise: Merchandise,
}

const MerchandiseMedia = ({ merchandise } : MerchandiseMediaProps) => {

  const classes = useStyles();
  const merchandiseMedia = useMemo(() => getMediaItems(propOr([], 'photos', merchandise), propOr([], 'videos', merchandise)), [merchandise]);

  return (
    <WhitePaper elevation={1} className={classes.root}>
      <If condition={isEmptyOrNil(merchandiseMedia)}>
        <Then>
          <Typography variant="subtitle1" component='div' className='photos-gallery-blankslate'> No photos to display</Typography>
        </Then>
        <Else>
          <AliceCarousel
            autoHeight
            disableButtonsControls
            items={merchandiseMedia}
            mouseTracking
          />
        </Else>
      </If>
    </WhitePaper>
  );
}

export default MerchandiseMedia;