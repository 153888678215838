import * as React from "react";
import { useContext, useMemo } from "react";
import { Typography, Divider, createStyles, makeStyles, Theme, useMediaQuery, Stack, Grid, Box } from "@material-ui/core"
import { Else, If, Switch, Then, Case } from 'react-if'
import classNames from "classnames";
import { rgba } from "polished";
import assert from "assert"

import RenderWaitTimeSlider from "../common/WaitTimeSlider";
import CustomizedChip from "../common/CustomizedChip";
import { getOfflineMerchantMessage, isMerchantOnline, isKioskDevice, isStoreOpen } from "../../utils";

import TableIcon from "../../assets/icons/TableIcon";
import { AppContext } from "../../context/AppContext";
import { Merchant, OrderType } from "../../services/models"
import { nextOpenHour } from "../../services/hoursUtils";

import StoreLocationSelector from "../MerchantMenu/StoreLocation/StoreLocationSelector";
import { isMultiStore } from "../MerchantMenu/StoreLocation/utils"
import MerchantHeader from "./MerchantHeader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .filledIconButton': {
        '&.MuiButtonBase-root': {
          backgroundColor: `${rgba(theme.palette.primary.main, 0.15)}`,
          transition: "all 200ms linear",

          '&:hover': {
            backgroundColor: `${rgba(theme.palette.primary.main, 0.3)}`,
          }
        }
      },

      '& .MuiCardHeader-action': {
        alignSelf: 'center',
      },

      '& .realTimeInfo': {
        padding: `0 ${theme.spacing(2)} ${theme.spacing(2)}`
      },

      '& .offline-status': {
        alignItems: 'center',
        padding: `8px 8px`,
        borderRadius: '10px',

        '& .store-timings': {
          backgroundColor: theme.palette.common.white,
          border: 0,
          color: rgba(54, 66, 69, 1),
          padding: '0 4px',
          marginRight: theme.spacing(1),
        },

        '&.ordering-unavailable': {
          backgroundColor: rgba(227, 77, 40, 0.15),

          '& .MuiTypography-root': {
            color: rgba(227, 77, 40, 1)
          }
        },

        '&.ordering-available': {
          backgroundColor: rgba(54, 66, 69, 0.15),

          '& .MuiTypography-root': {
            color: rgba(54, 66, 69, 1)
          }
        },
      },

      '& .storeTitle': {
        display: 'flex',
        alignItems: 'flex-end',
      }
    }
  }),
);

const RenderKioskOrderingStatusBanner = (props: { merchant: Merchant, orderType: OrderType }) => {
  const { merchant, orderType } = props
  const isMerchantOrderingAvailable = isMerchantOnline(merchant, orderType);
  assert(isKioskDevice());

  const offlineStatusClasses = classNames(['offline-status'], {
    'ordering-unavailable': !isMerchantOrderingAvailable,
    'ordering-available': isMerchantOrderingAvailable
  })

  if (!isMerchantOrderingAvailable) {
    return (
      <Stack direction='row' className={offlineStatusClasses}>
        <Typography variant="subtitle2" component='p'>
          {getOfflineMerchantMessage(orderType, merchant)}
        </Typography>
      </Stack>
    )
  }

  return (
    <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }} spacing={2}>
      <Grid item xs={12} sm={12} lg={12}>
        <RenderWaitTimeSlider merchant={merchant} typographyProps={{ variant: "subtitle2", color: "textSecondary" }} short />
      </Grid>
    </Grid>
  );
}

const RenderMerchantOrderingStatusBanner = (props: { merchant: Merchant, orderType: OrderType }) => {
  const { merchant, orderType } = props
  assert(!isKioskDevice());
  const isMerchantOrderingAvailable = isMerchantOnline(merchant, orderType);
  const isMerchantStoreOpen = isStoreOpen(merchant, new Date())

  const RenderOfflineMessage = () => {
    const variant = "subtitle2";
    const offlineStatusClasses = classNames(['offline-status'], {
      'ordering-unavailable': !isMerchantOrderingAvailable,
      'ordering-available': isMerchantOrderingAvailable
    })

    // only need label if store is closed and this is not on kiosk
    const storeOpenTimingLabel = useMemo(() => !isMerchantStoreOpen ? nextOpenHour(merchant) : null, [isMerchantStoreOpen, merchant]);

    if (isMerchantOrderingAvailable && isMerchantStoreOpen) return null;

    const storeTimingsLabel = useMemo(() => {
      return !merchant.online ? 'Opens later' : `Opens at ${storeOpenTimingLabel}`;
    }, [merchant.online, storeOpenTimingLabel]);

    return (
      <Stack direction='row' className={offlineStatusClasses}>
        <Switch>
          <Case condition={!isMerchantStoreOpen && storeOpenTimingLabel}>
            <CustomizedChip className='store-timings' label={storeTimingsLabel} />
            &nbsp;
            <Typography variant={variant} component='p'>
              <If condition={!isMerchantOrderingAvailable}>
                <Then>
                  Come back soon!
                </Then>
                <Else>
                  Order ahead available
                </Else>
              </If>
            </Typography>
          </Case>
          <Case condition={!isMerchantOrderingAvailable}>
            <Typography variant={variant} component='p'>
              {(merchant?.id === 'b_ajfglvb36')
                ? 'Stanford Mall Store temporarily closed due to relocation. Reopen this Fall.'
                : getOfflineMerchantMessage(orderType, merchant)
              }
            </Typography>
          </Case>
        </Switch>
      </Stack>
    )
  }

  const isStoreSelectorShown = isMultiStore(merchant) && orderType !== OrderType.DINEIN;

  return (
    <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }} spacing={2}>
      <If condition={isStoreSelectorShown}>
        <Grid item xs={12} sm={6} lg={4}>
          <StoreLocationSelector merchant={merchant} />
        </Grid>
      </If>
      <Grid item xs={12} sm={isStoreSelectorShown ? 6 : 12} lg={isStoreSelectorShown ? 8 : 12}>
        <If condition={isMerchantOrderingAvailable && isMerchantStoreOpen && merchant.claimed}>
          <Then>
            <RenderWaitTimeSlider merchant={merchant} typographyProps={{ variant: "subtitle2", color: "textSecondary" }} short />
          </Then>
          <Else>
            <RenderOfflineMessage />
          </Else>
        </If>
      </Grid>
    </Grid>
  )
}

type MerchantInfoHeaderProps = {
  merchant: Merchant;
};

const MerchantInfoHeader = ({ merchant }: MerchantInfoHeaderProps): React.ReactElement => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { orderType, tableQrCodeObject } = useContext(AppContext);

  return (
    <div className={classes.root}>
      <Box sx={{p: 2, pb: 1}}>
        <MerchantHeader merchant={merchant} />
      </Box>
      <div className="realTimeInfo">
        <If condition={tableQrCodeObject?.identifier}>
          <Then>
            <Typography component="div" variant={"subtitle2"} gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
              <TableIcon /> &nbsp;&nbsp;Table: {tableQrCodeObject?.identifier}
            </Typography>
          </Then>
        </If>

        <If condition={isKioskDevice()}>
          <Then>
            <RenderKioskOrderingStatusBanner merchant={merchant} orderType={orderType} />
          </Then>
          <Else>
            <RenderMerchantOrderingStatusBanner merchant={merchant} orderType={orderType} />
          </Else>
        </If>
      </div>
    </div>
  )
}

export default MerchantInfoHeader;
