import React, { useState, useEffect } from 'react';
import { Button, createStyles, Divider, makeStyles, Modal, Stack, TextField, Theme, styled, IconButton } from '@material-ui/core';
import { compareAsc, format, sub } from 'date-fns';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { LocalizationProvider, StaticDatePicker } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';

interface IDateSelectModalProps {
  data: string;
  onConfirm: (v: string) => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  datePicker: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(1),
    '& .btns': {
      justifyContent: 'flex-end',
      padding: `${theme.spacing(2)} 0 0`
    }
  },
  timerBox: {
    flex: 1,
    height: 48,
    background: theme.palette.background.paper,
    boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.07)',
    borderRadius: 6,
  }
}))

const CustomStaticDatePicker = styled(StaticDatePicker)(({ theme }) => ({
  '& .MuiPickersDay-root.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '36px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '36px',
    }
  },
  '& .MuiPickersDay-today:not(.Mui-selected)': {
    borderRadius: '36px',
  },
  '& .MuiPickersDay-dayWithMargin': {
    margin: 0
  }
}))

const DateSelectModal = ({
  data, onConfirm
}: IDateSelectModalProps) => {
  const classes = useStyles();
  const [value, setValue] = useState<Date | null>(new Date());
  const [selectValue, setSelectValue] = useState<Date | null>(value);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setSelectValue(new Date(data));
  }, [data])

  useEffect(() => {
    if (modalOpen) {
      setValue(selectValue);
    }
  }, [modalOpen])

  const save = () => {
    
    if (value && compareAsc(value, sub(new Date(), {days: 1})) >= 0) {
      onConfirm(format(value, 'yyyy-MM-dd'))
      setModalOpen(false);
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack direction={"row"} className={classes.timerBox} divider={<Divider orientation="vertical" flexItem />}>
        <Button color="inherit" sx={{ flex: 1 }} onClick={() => { setModalOpen(true) }}>
          {format(selectValue?.getTime() || 0, 'PP')}
        </Button>
        <IconButton onClick={() => { setModalOpen(true) }}>
          <ArrowDropDownIcon />
        </IconButton>
      </Stack>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div className={classes.datePicker}>
          <CustomStaticDatePicker
            displayStaticWrapperAs="mobile"
            openTo="date"
            value={value}
            minDate={sub(new Date(), { days: 0 })}
            onChange={(newValue: any) => {
              setValue(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <Stack direction="row" spacing={1} className="btns">
            <Button onClick={() => { setModalOpen(false) }}>Cancel</Button>
            <Button onClick={() => { save() }}>Ok</Button>
          </Stack>
        </div>
      </Modal>
    </LocalizationProvider>

  );
}

export default DateSelectModal