import React from 'react'
import { SvgIcon } from "@material-ui/core";

function BowlIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M12.146 9.332c.079-.179.145-.363.198-.553l.119-.425-1.32-.367a3.98 3.98 0 001.508-3.223l-.013-.44-2.316.065 2.427-3.005-.685-.554-2.901 3.592-.905.025L9.73.832 8.914.5 7.3 4.465l.02.009-4.37.122.012.44c.008.297.049.588.121.87l-.076.272a3.973 3.973 0 00.442 3.154v.44a3.972 3.972 0 002.643 3.739V15.5h4.406v-1.99a3.972 3.972 0 002.644-3.738v-.44h-.995zM11.75 5.23a3.096 3.096 0 01-1.752 2.438l-5.962-1.66a3.059 3.059 0 01-.15-.556l7.864-.222zM3.768 6.848l7.58 2.11a3.033 3.033 0 01-.196.373H4.557a3.095 3.095 0 01-.79-2.483zm5.848 7.77H6.972v-.905c.145.016.292.025.441.025h1.762c.15 0 .296-.009.441-.025v.906zm-.44-1.762H7.412a3.09 3.09 0 01-3.053-2.643h7.868a3.09 3.09 0 01-3.053 2.643z" />
    </SvgIcon>
  );
}

export default BowlIcon