import { pickAll, propOr } from 'ramda';
import RP from 'react-facebook-pixel';

import config from '../../config/config';
import { isEmptyOrNil, isProduction } from '../../utils';
import { CaptureEventParams, CaptureOrderCheckoutEvent } from './types';
import { getOrderCheckoutEventAction } from './utils';

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

const isPixelEnabled = () => !isEmptyOrNil(config.appConfig.pixelId)

export const initializeFbPixel = () => {
  if (!isPixelEnabled()) return

  console.log("FB Pixel tracking enabled")
  RP.init(config.appConfig.pixelId, undefined, options)
  RP.pageView()
}

export const captureEventForFP = (params: Omit<CaptureEventParams, 'category'>) => {
  if (!isPixelEnabled()) return

  if (!isProduction()) {
    console.log("--------------")
    console.log("FB Pixel Event Captured")
    console.log(params)
    console.log("--------------")
  }

  const { action, data = {} } = params
  const _data = {
    ...data,
    user: pickAll(['id', 'displayName'], propOr({}, 'user', data))
  }

  RP.trackCustom(action, _data)
}

export const captureOrderCheckoutEventForFP = (params: CaptureOrderCheckoutEvent) => {
  if (!isPixelEnabled()) return

  const action = getOrderCheckoutEventAction(params);
  captureEventForFP({ ...params, action })
}