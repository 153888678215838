import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  TypographyProps,
  useTheme,
} from '@material-ui/core';
import { AlarmOn, Schedule } from '@material-ui/icons';
import useAxios from 'axios-hooks';
import { If, Then, Else } from 'react-if';
import { rgba } from 'polished';

import { WaitTime } from '../../bos_common/src/types/WaitTimeType';
import { Merchant, Order } from '../../services/models';
import { isEmptyOrNil, isMerchantOnline, pluralString } from '../../utils';
import { useFunctionOnInterval } from '../../bos_common/src';
import { WAIT_TIME_ON_ORDER_STATUS_INTERVAL } from '../../config/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: ({ waitColor }: any) => ({
      '& .MuiTypography-root': {
        display: 'flex',
        alignItems: 'center'
      },
      background: rgba(waitColor, 0.1),
      borderRadius: theme.spacing(1.5),
      padding: theme.spacing(1.5, 1),
    }),
    boxRoot: ({ waitColor }: any) => ({
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',

      '& .MuiSvgIcon-root': {
        color: waitColor,
      },
      '& .time-wrapper': {
        flex: '1 1 100%',
        minWidth: theme.spacing(24),
        color: waitColor,
        lineHeight: 1.2,
      },
      '& .progress-bar-wrapper': {
        width: '100%',
        '& .progress-root': {
          display: 'block',
          width: '100%',
          //maxWidth: '360px',
          height: 8,
          position: 'relative',
          borderRadius: 5,
          overflow: 'visible',
          background: rgba(waitColor, 0.15),

          '& .progress-bar-indicator': {
            height: '100%',
            borderRadius: 5,
            mask: 'linear-gradient(#fff 0 0)',
            '&::before': {
              content: '""',
              borderRadius: 5,
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: waitColor,
            }
          }
        }
      }
    }),
  }),
);

type RenderWaitTimeProps = {
  merchant?: Merchant,
  order?: Order,
  withIcon?: boolean,
  short?: boolean,
  typographyProps?: TypographyProps<"div">,
}
const RenderWaitTimeSlider = ({ merchant, order, withIcon = true, short = false, typographyProps = {} }: RenderWaitTimeProps): React.ReactElement | null => {
  const [waitColor, setWaitColor] = useState<string>('#1AAA5D');
  const classes = useStyles({ waitColor });
  const theme = useTheme();

  const url = order
    ? `/orders/${order.id}/waitTime`
    : merchant
      ? `/merchants/${merchant.id}/waitTime`
      : undefined;

  const [{ data: waitTime, loading }, executeGet] = useAxios<WaitTime>(
    { url, method: 'get' },
    { manual: true }
  );

  const timerHandler = useFunctionOnInterval(executeGet, WAIT_TIME_ON_ORDER_STATUS_INTERVAL, true);
  const merchantOnline = useMemo(() => isMerchantOnline(merchant), [merchant]);

  useEffect(() => {
    if (waitTime && waitTime.readyTime) {
      if (timerHandler.current) {
        clearTimeout(timerHandler.current)
      }
    }

    if (waitTime) {
      if (waitTime.maxMinutes < 25) {
        setWaitColor('#1AAA5D')
      } else if (waitTime.maxMinutes < 40) {
        setWaitColor('#F8B62D')
      } else if (waitTime.maxMinutes <= 45) {
        setWaitColor('#F1840F')
      } else {
        setWaitColor('#EA5615')
      }
    }
  }, [waitTime])

  const getWaitTimeString = (minMinutes: number | undefined, maxMinutes: number | undefined) => {
    if (minMinutes == null || maxMinutes == null) {
      return 'n/a'
    } else {
      return (<span>{minMinutes}-{maxMinutes} mins</span>)
    }
  }

  if (!merchantOnline) {
    return null;
  }

  return (
    <div className={classes.root}>
      <If condition={loading && !waitTime}>
        <Then>
          <Typography variant="subtitle1" color="textPrimary" component="div" {...typographyProps}>
            {withIcon && <Schedule className='wait-time-icon' sx={{ mr: 0.5, color: waitColor }} />}
            Estimating&nbsp;...
          </Typography>
        </Then>
        <Else>
          <If condition={!!waitTime}>
            <Then>
              <Typography variant="subtitle1" color="textPrimary" component="div" {...typographyProps}>
                <If condition={!isEmptyOrNil(waitTime?.readyTime)}>
                  <Then>
                    {withIcon && <AlarmOn className='wait-time-icon' color='primary' sx={{ color: waitColor }} />}
                    {new Date(waitTime?.readyTime ?? "")}
                  </Then>
                  <Else>
                    <Box className={classes.boxRoot}>
                      {withIcon && <Schedule className='wait-time-icon' color="primary" sx={{ color: waitColor, marginRight: theme.spacing(0.5) }} />}
                      <div className="time-wrapper">
                        <Typography component="span" fontSize={14}>
                          Est. wait&nbsp;{getWaitTimeString(waitTime?.minMinutes, waitTime?.maxMinutes)}
                        </Typography>
                        <If condition={waitTime?.priorOrdersInQueue && !short}>
                          <Typography component={"span"} fontSize={14}>
                            {pluralString(waitTime?.priorOrdersInQueue ?? 0, 'order')} / {pluralString(waitTime?.priorItemsInQueue ?? 0, 'item')} in queue
                          </Typography>
                        </If>
                      </div>
                      <div className='progress-bar-wrapper'>
                        <div className='progress-root'>
                          <div className='progress-bar-indicator' style={{ width: `${Math.min(60, (waitTime?.maxMinutes ?? 0) / 60 * 100)}%` }}></div>
                        </div>
                      </div>
                    </Box>
                  </Else>
                </If>
              </Typography>
            </Then>
          </If>
        </Else>
      </If >
    </div>
  )
}

export default RenderWaitTimeSlider;