import React from 'react';
import { createStyles, Divider, Grid, makeStyles, Stack, Theme, Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { ChevronRight } from '@material-ui/icons';
import { pathOr } from 'ramda';

// src
import { Merchant, User } from '../services/models';
import { isEmptyOrNil } from '../utils';
import { useAppSelector } from '../redux/hooks';
import { DialogCard } from '../components/LearnMoreDialog';
import { getUser } from '../redux/slice/auth/authSelector';

import CashOnDeliveryIcon from '../assets/icons/CashOnDeliveryIcon';
import PicturesIcon from '../assets/icons/PicturesIcon';
import GemIcon from '../assets/icons/GemIcon';
import ChatIcon from '../assets/icons/ChatIcon';
import AffiliateUserBannerSrc from '../assets/images/banner/affiliate-user-banner.svg'
import LinkWithCopyButton from '../components/common/LinkWithCopyButton';
import { getMerchantAffiliateLink } from 'bos_common/src/services/urls';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: "auto",

      "& .pageContent": {
        padding: theme.spacing(3),

        "& .blockImg": {
          width: "100%",
          objectFit: "contain",
          height: 250,
          objectPosition: "left bottom",
        },

        "& .cardsWrapper": {
          maxWidth: 500,
          margin: "auto",
        }
      },
    },
    banner: {
      padding: theme.spacing(4, 2),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.paper,
      position: 'relative',
      minHeight: 300,
      zIndex: 0,
      
      [theme.breakpoints.up('sm')]: {
        display: "flex",
        alignItems: "center",
      },

      '& .goToInstaBtn': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1, 2),
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.spacing(3),
        color: '#000',
        fontSize: theme.spacing(2),
        fontWeight: 500,
        width: 150,
        textDecoration: 'none',
      },

      '& .bannerImg': {
        position: 'absolute',
        bottom: 0,
        right: 0,
        zIndex: -1
      }
    },
    contentBox: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
      marginBottom: theme.spacing(2),

      '& .content': {
        fontWeight: 500
      }
    },
    affiliateContainer: {
      borderRadius: theme.spacing(2),
      marginBottom: theme.spacing(3),
      position: 'relative',
      display: "flex",
      justifyContent: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "auto 100%",
      backgroundPosition: "right",

      '& .innerContent': {
          width: "100%",
          maxWidth: 500,

        '& .affiliateLinkSection': {
          backgroundColor: theme.palette.background.paper,
          borderRadius: theme.spacing(3),
          padding: theme.spacing(1),
          lineHeight: 1,
          width: 'max-content'
        }
      }
    }
  })
);

const ContentBox = ({ icon, content, }: { icon: React.ReactNode, content: string }) => {
  const classes = useStyles()

  return (
    <Stack spacing={2} direction="row" alignItems="center" className={classes.contentBox}>
      <div className="icon">{icon}</div>
      <div className='content'>{content}</div>
    </Stack>
  )
}

const RewardPrice = ({ previousValue, newValue }: { previousValue: string, newValue: string }) => {
  return (
    <>
      <Typography component={"span"} sx={{ color: 'grey.400', }}><del>{previousValue}</del></Typography>
      &nbsp;
      <Typography component={"span"} sx={{ color: 'secondary.main', }}>{newValue}</Typography>
    </>
  )
}

const UserAffiliateLinkSection = ({ merchant }: { merchant: Merchant }): JSX.Element | null => {
  const classes = useStyles()

  const user = useAppSelector(getUser)
  const affiliateLink = getMerchantAffiliateLink(merchant, user || {} as User)

  if (isEmptyOrNil(affiliateLink)) return null

  return (
    <div className={classes.affiliateContainer}>
      <div className='innerContent'>
        <LinkWithCopyButton url={affiliateLink} fullWidth shorten={true} />
      </div>
    </div>
  )
}

const Banner = () => {
  const classes = useStyles()
  return (
    <div className={classes.banner}>
      <div className='bannerContent'>
        <Typography variant='h4' fontWeight="bold" className='heading' mb={1}>Earn as You Inspire</Typography>
        <Typography variant='h6' mb={2}>Share your affiliate link and earn commission</Typography>
        <a href={'https://instagram.com'} target="blank" className='goToInstaBtn'>Go to Instagram <ChevronRight /></a>
      </div>
      <img src={AffiliateUserBannerSrc} alt="affiliate-user-banner" className="bannerImg" />
    </div>
  )
}

const AffiliateUserContent = ({ merchant }: { merchant: Merchant }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Banner />
      <div className='pageContent'>
        <UserAffiliateLinkSection merchant={merchant} />
        
        <Divider sx={{ my: 2 }} />

        <div className='cardsWrapper'>
          <ContentBox icon={<PicturesIcon />} content={`Share your experience at ${merchant.officialName} on Instagram`} />
          <ContentBox icon={<ChatIcon />} content="Include #marlo in your post" />
        </div>
        
        <Divider sx={{ my: 2 }} />

        <Typography variant='h5' gutterBottom>Earn on another level - as a <Typography variant='h5' component="span" color="secondary.main">Marlo Elect!</Typography></Typography>

        <Typography variant='body1' gutterBottom>Generate over <b>$250</b> in Poco sales:</Typography>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <DialogCard
              heading={<>Get <RewardPrice previousValue='10%' newValue='20%' /> in reward points </>}
              icon={<GemIcon />}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <DialogCard
              heading={<><RewardPrice previousValue='5%' newValue='10%' /> commission from affiliate link sales</>}
              icon={<CashOnDeliveryIcon />}
            />
          </Grid>
        </Grid>

        <Typography variant='body1' mt={3} color="secondary.light" gutterBottom>Generate over <Typography variant='body1' component="span" color="secondary.main">$1000</Typography> in Poco sales:</Typography>
        <Grid container spacing={2} mb={3}>
          <Grid item sm={6} xs={12}>
            <DialogCard
              heading={<>Get <RewardPrice previousValue='10%' newValue='30%' /> in reward points </>}
              icon={<GemIcon />}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <DialogCard
              heading={<><RewardPrice previousValue='5%' newValue='15%' /> commission from affiliate link sales</>}
              icon={<CashOnDeliveryIcon />}
            />
          </Grid>
        </Grid>

        <Typography variant='body1' mt={2} gutterBottom color="textSecondary" sx={{ 'a': { color: "inherit" } }}>
          Rules apply. <Link to="/help/terms-of-use" target="_blank">See Terms &amp; Conditions</Link> for details.
        </Typography>
      </div>
    </div>
  )
}


const AffiliateEducationPage = (): React.ReactElement | null => {
  const location = useLocation();
  const merchant: Merchant = pathOr({}, ['state', 'merchant'], location)

  return (
    <div className='container'>
      <AffiliateUserContent merchant={merchant} />
    </div>
  )
}

export default AffiliateEducationPage