import React from "react";
import { Card, Button, Alert, Box } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { If } from "react-if";

// statics
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { getMobileOS } from "../../utils";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      "& .MuiAlert-message": {
        width: '100%',
        display: 'flex',
      },

      "& .logo-container": {
        display: 'flex',
        width: '2rem',
      },
    },
  }),
);

export const DownloadAppCTA = (): JSX.Element => {
  const classes = useStyles();
  const osName = getMobileOS();
  const shouldShowCTA = ["Android", "iOS"].includes(osName)

  const url = osName === 'iOS'
    ? "https://apps.apple.com/id/app/one-market/id1562275335"
    : "https://play.google.com/store/apps/details?id=app.onem.marketplace";

  const handleClick = () => {
    location.assign(url);
  }

  return (
    <If condition={shouldShowCTA}>
      <Card>
        <Alert severity="error" icon={false} elevation={3} onClick={handleClick} className={classes.root}>
          <Box className="logo-container">
            <Logo />
          </Box>
          <Box style={{ marginLeft: '1rem' }}>
            Discover local restaurants near you available for pre-order for pickup.
            <br/>
            <Button target="_blank" href={url} variant="outlined" color="primary" size="small" sx={{mt: 1}}>
              Download the app
            </Button>
          </Box>
        </Alert>
      </Card>
    </If>
  );
}

export default DownloadAppCTA;
