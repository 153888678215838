import React, { useContext, useEffect, useState } from 'react';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { UserContext } from '../../bos_common/src/context/UserContext';
import { User } from '../../services/models';
import axios from '../../bos_common/src/services/backendAxios';
import useStyles from './styles'

interface FoodPreference {
  vegan: boolean,
  vegetarian: boolean,
  lactoseFree: boolean,
  glutenFree: boolean,
  kosher: boolean,
  islam: boolean,
  hindu: boolean,
}

const UserFoodPreferences = (): React.ReactElement => {
  const classes = useStyles()
  const { user, token, setLoginState, setLoading } = useContext(UserContext);

  const getFoodPrefData = (localUser: User | undefined) => {
    return {
      vegan: localUser?.foodPreference?.vegan || false,
      vegetarian: localUser?.foodPreference?.vegetarian || false,
      lactoseFree: localUser?.foodPreference?.lactoseFree || false,
      glutenFree: localUser?.foodPreference?.glutenFree || false,
      kosher: localUser?.foodPreference?.kosher || false,
      islam: localUser?.foodPreference?.islam || false,
      hindu: localUser?.foodPreference?.hindu || false,
    }
  }

  const [foodPrefsChecked, setFoodPrefsChecked] = useState<FoodPreference>(getFoodPrefData(user));

  useEffect(() => {
    setFoodPrefsChecked(getFoodPrefData(user))
  }, [user])

  const toggleLabels = {
    vegan: 'Vegan',
    vegetarian: 'Vegetarian',
    lactoseFree: 'Lactose Free',
    glutenFree: 'Gluten Free',
    kosher: 'Kosher',
    islam: 'Halal',
    hindu: 'Hindu',
  }

  const handleToggle = (prop: keyof FoodPreference) => (event: any) => {
    event.preventDefault()
    const currentValue = foodPrefsChecked[prop]
    const newChecked = { ...foodPrefsChecked }
    newChecked[prop] = !currentValue
    setFoodPrefsChecked(newChecked);

    setLoading(true)
    axios.put<User>(
      '/users',
      { foodPreference: newChecked, id: user?.id },
      { headers: { "Authorization": `JWT ${token}` } }
    ).then((response) => {
      setLoading(false)
      if (response.status === 200) {
        setLoginState(response.data, token)
      }
    }).catch((err) => {
      console.log(err)
      setLoading(false)
    })
  };

  const generateRow = (prop: keyof FoodPreference): React.ReactElement => {
    return (
      <ListItem button onClick={handleToggle(prop)} key={prop}>
        <FormControlLabel label={toggleLabels[prop]} control={<Checkbox color="primary"
            onChange={handleToggle(prop)}
            checked={foodPrefsChecked[prop]}
            inputProps={{ 'aria-labelledby': `${prop}_checkbox` }}
          />} />
      </ListItem>
    )
  }

  return (
    <div className={classes.root}>
      <Typography gutterBottom variant="subtitle1" component="div" className="sectionHeader" style={{ fontWeight: 600 }}>
        Food Preference
      </Typography>
      <List>
        <Divider light variant="middle" />
        {generateRow('vegan')}
        <Divider light variant="middle" />
        {generateRow('vegetarian')}
        <Divider light variant="middle" />
        {generateRow('lactoseFree')}
        <Divider light variant="middle" />
        {generateRow('glutenFree')}
        <Divider light variant="middle" />
        {generateRow('kosher')}
        <Divider light variant="middle" />
        {generateRow('islam')}
        <Divider light variant="middle" />
        {generateRow('hindu')}
      </List>
    </div>
  );
};

export default UserFoodPreferences;
