import React from 'react';
import { createStyles, Typography, TypographyProps } from '@material-ui/core';
import { Place } from '@material-ui/icons';

import { Merchant } from '../../services/models';
import { makeStyles } from '@material-ui/core';
import { Theme } from 'pretty-format/build/types';
import { isKioskDevice } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: '1.2 !important',
      marginTop: '2px',

      '& .place-icon': {
        marginRight: '5px',
        marginTop: '-2px',
      },
    },
  })
)


type MerchantAddressProps = {
  merchant?: Merchant,
  typographyProps?: TypographyProps<"div">,
  showIcon?: boolean,
}

const MerchantAddress = ({ merchant, typographyProps = {}, showIcon = true }: MerchantAddressProps): React.ReactElement | null => {
  const classes = useStyles()
  const isKioskModeEnabled = isKioskDevice()

  if (!merchant || isKioskModeEnabled) return null;

  return (
    <Typography
      variant="subtitle1"
      color="textPrimary"
      component="div"
      className={classes.root}
      {...typographyProps}>
      {showIcon && <Place color="primary" className="place-icon" />}{merchant.address}
    </Typography>
  )
}

export default MerchantAddress