import { AxiosRequestConfig, AxiosResponse } from "axios";
import { propOr } from "ramda";
import axios, { backendAPIURL } from "../bos_common/src/services/backendAxios";
import { Merchant } from "./models";

export interface MerchantQueryParams {
  id?: string;
  email?: string;
  ownerId?: string;
  username?: string;
}

export const getMerchant = (queryParams: MerchantQueryParams, request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const url = new URL(`${backendAPIURL}/merchants`);
  Object.keys(queryParams).forEach((key) => url.searchParams.append(key, queryParams[key]));

  return axios({
    url: url.toString(),
    method: 'GET',
    headers: request.headers,
  });
}

export interface MerchantSearchParams {
  searchQuery: string;
  zipcodes: string[];
  claimed?: boolean
}

export const getMerchantBySearchParams = (searchParams: MerchantSearchParams, request: AxiosRequestConfig): Promise<AxiosResponse> => {
  const url = new URL(`${backendAPIURL}/merchants/search`);

  return axios({
    url: url.toString(),
    method: 'GET',
    headers: request.headers,
    params: {
      limit: 1000,
      name: searchParams.searchQuery,
      claimed: searchParams.claimed,
      ...(searchParams.zipcodes && { zipcode: searchParams.zipcodes }),
    }
  })
}

export const getMerchantStores = async (merchantId: string): Promise<Merchant[]> => {
  const response = await axios.get(`${backendAPIURL}/merchants/${merchantId}/store-locations`)
  return response.data;
}

export default {
  getMerchantBySearchParams
}
