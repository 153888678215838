import React from 'react'
import { SvgIcon } from "@material-ui/core";

function CheckIcon(props: any) {
  return (
    <SvgIcon {...props} >
      <path d="M0 1.63636C0 1.20237 0.172402 0.786158 0.47928 0.47928C0.786158 0.172402 1.20237 0 1.63636 0H7.81818C8.25217 0 8.66839 0.172402 8.97527 0.47928C9.28214 0.786158 9.45455 1.20237 9.45455 1.63636V7.27273H12V9.63636C12 10.2632 11.751 10.8644 11.3077 11.3077C10.8644 11.751 10.2632 12 9.63636 12H1.63636C1.20237 12 0.786158 11.8276 0.47928 11.5207C0.172402 11.2138 0 10.7976 0 10.3636V1.63636ZM9.45455 10.9091H9.63636C9.97391 10.9091 10.2976 10.775 10.5363 10.5363C10.775 10.2976 10.9091 9.97391 10.9091 9.63636V8.36364H9.45455V10.9091ZM2.72727 2.90909C2.58261 2.90909 2.44387 2.96656 2.34158 3.06885C2.23929 3.17114 2.18182 3.30988 2.18182 3.45455C2.18182 3.59921 2.23929 3.73795 2.34158 3.84024C2.44387 3.94253 2.58261 4 2.72727 4H6.72727C6.87194 4 7.01067 3.94253 7.11297 3.84024C7.21526 3.73795 7.27273 3.59921 7.27273 3.45455C7.27273 3.30988 7.21526 3.17114 7.11297 3.06885C7.01067 2.96656 6.87194 2.90909 6.72727 2.90909H2.72727ZM2.72727 5.45455C2.58261 5.45455 2.44387 5.51201 2.34158 5.61431C2.23929 5.7166 2.18182 5.85534 2.18182 6C2.18182 6.14466 2.23929 6.2834 2.34158 6.38569C2.44387 6.48799 2.58261 6.54545 2.72727 6.54545H6.72727C6.87194 6.54545 7.01067 6.48799 7.11297 6.38569C7.21526 6.2834 7.27273 6.14466 7.27273 6C7.27273 5.85534 7.21526 5.7166 7.11297 5.61431C7.01067 5.51201 6.87194 5.45455 6.72727 5.45455H2.72727ZM2.72727 8C2.58261 8 2.44387 8.05747 2.34158 8.15976C2.23929 8.26205 2.18182 8.40079 2.18182 8.54545C2.18182 8.69012 2.23929 8.82886 2.34158 8.93115C2.44387 9.03344 2.58261 9.09091 2.72727 9.09091H4.54545C4.69012 9.09091 4.82886 9.03344 4.93115 8.93115C5.03344 8.82886 5.09091 8.69012 5.09091 8.54545C5.09091 8.40079 5.03344 8.26205 4.93115 8.15976C4.82886 8.05747 4.69012 8 4.54545 8H2.72727Z" fill="white" />
    </SvgIcon>
  );
}

export default CheckIcon