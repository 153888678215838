import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { rgba } from "polished";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    background: rgba(theme.palette.primary.main, 0.15),
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: "12px",
    borderRadius: "3px",
    padding: `${theme.spacing(0.2)} ${theme.spacing(0.6)}`,
  }
}))


export default function CountBox({ count }: { count: number }) {
  const classes = useStyles();
  return (<span className={classes.root}>{count}</span>);
}