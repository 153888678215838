import React from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

export default function CloseButton(props: {
  onClose: () => void,
  color?: string,
  disabled?: boolean
}): React.ReactElement {
  const { color = 'rgba(0, 0, 0, 0.6)', onClose, disabled = false } = props
  return (
    <IconButton onClick={onClose} disabled={disabled}>
      <Close fontSize="large" sx={{ color }} />
    </IconButton>
  )
}