import React from "react"
import { If, Then } from "react-if";
import { Card, createStyles, Grid, ListItem, ListItemText, makeStyles, Stack, Theme, Typography } from "@material-ui/core";

import { isEmptyOrNil } from "bos_common/src";

import MerchantAvatar from "./MerchantAvatar";
import { Merchant } from "../services/models";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),

      '& .cardTitle span': {
        fontWeight: "bold",
        fontSize: theme.spacing(2),
      },
    },
  })
);

type HorizontalContentCardProps = {
  handleCardClick: () => void;
  cardTitle: string;
  cardSubTitle: string;
  merchant: Merchant;
  extraContent?: React.ReactElement;
  cardFooter?: React.ReactElement;
}

const HorizontalContentCard = (props: HorizontalContentCardProps) => {
  const { cardTitle, cardSubTitle, handleCardClick, merchant, cardFooter, extraContent } = props;
  const classes = useStyles();

  return (
    <Card elevation={3} className={classes.root}>
      <ListItem button onClick={handleCardClick}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={9}>
            <div>
              <ListItemText
                className="cardTitle"
                primary={cardTitle}
                secondary={cardSubTitle}
              />
              {extraContent}
            </div>
          </Grid>
          <Grid item xs={3} textAlign="right">
            <MerchantAvatar merchant={merchant} />
          </Grid>
        </Grid>

      </ListItem>

      <If condition={!isEmptyOrNil(cardFooter)}>
        <Then>
          {cardFooter}
        </Then>
      </If>
    </Card>
  )
}

export default HorizontalContentCard;
