import React from 'react'
import { SvgIcon } from "@material-ui/core";

function CoffeeBeanIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M8.004 8.818c1.607-.002 2.866-1.622 2.866-3.69 0-2.065-1.257-3.684-2.862-3.689H8c-1.609 0-2.87 1.62-2.87 3.69 0 2.067 1.259 3.686 2.866 3.69h.008zm2.046-3.69c0 1.58-.838 2.675-1.775 2.846C8.153 7.628 8 7.112 8 6.769c0-.458.274-1.227.38-1.485.045-.11.44-1.094.44-1.795 0-.344-.095-.755-.197-1.098.968.421 1.427 1.651 1.427 2.738zm-4.1 0c0-1.578.838-2.673 1.776-2.844.121.346.274.862.274 1.205 0 .457-.274 1.227-.379 1.484-.045.11-.44 1.095-.44 1.796 0 .343.094.755.197 1.098-.761-.331-1.427-1.335-1.427-2.738zM14.29 8.632c-.002 0-.005-.004-.005-.004-1.138-1.138-3.175-.883-4.638.58-1.384 1.383-1.78 3.43-.586 4.632a.98.98 0 00.006.006c1.048 1.048 3.06.998 4.638-.58 1.33-1.33 1.82-3.392.584-4.634zm-4.064 1.155c.994-.993 2.383-1.367 3.267-.756-.16.331-.416.804-.658 1.046-.323.324-1.06.674-1.317.782-.11.045-1.086.462-1.581.958-.243.242-.467.6-.637.915-.302-.77-.07-1.949.926-2.945zm3.927.98c-.096.67-.461 1.353-1.028 1.92-1.122 1.122-2.488 1.293-3.267.755.16-.33.416-.803.658-1.046.324-.323 1.061-.673 1.318-.781.11-.046 1.085-.462 1.581-.958.243-.243.466-.6.637-.915.118.3.154.65.1 1.025zM6.354 9.208c-1.463-1.463-3.5-1.718-4.638-.58h-.001a.304.304 0 00-.005.005c-1.242 1.25-.732 3.315.586 4.633 1.336 1.336 3.401 1.816 4.638.58l.005-.006c1.24-1.247.738-3.31-.585-4.632zm-.58.58c.996.995 1.23 2.172.927 2.944-.17-.315-.394-.673-.637-.915-.496-.496-1.471-.913-1.58-.958-.258-.108-.996-.459-1.319-.782-.242-.242-.498-.715-.658-1.046.802-.554 2.173-.338 3.267.756zm-2.899 2.898c-.995-.995-1.23-2.173-.926-2.944.17.314.394.672.636.915.496.496 1.472.912 1.582.958.256.107.993.457 1.317.781.242.242.499.715.658 1.046-.791.547-2.16.351-3.267-.756z" />
    </SvgIcon>
  );
}

export default CoffeeBeanIcon 