import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// import { Reservation } from "../../../bos_common/src/types/ReservationTypes";
import { cancelReservation, getReservation } from "../../../services/reservationApiService";
import { getAuthHeaders } from "../../../utils";
import { RootState } from "../../store";
import { getToken } from "../auth/authSelector";

export const reservationFetching = createAction('order/setLoading');

export const ReservationFetchFailed = createAction('order/stopLoading');

export const fetchReservationDetail = createAsyncThunk(
  'review/fetchOrderDetail',
  async (id: string, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const token = getToken(state);
    const response = await getReservation(id, { headers: getAuthHeaders(token) });
    return [response.data];
  }
);

export const fetchReservationCancel = createAsyncThunk(
  'reservations/cancel',
  async (id: string, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const token = getToken(state);
    const response = await cancelReservation(id, { headers: getAuthHeaders(token)});
    return [response.data];
  }
)

// export const updateOrderDetail = createAsyncThunk(
//   'review/updateOrderDetail',
//   async (order: Partial<Order>, thunkAPI) => {
//     const state: RootState = thunkAPI.getState() as RootState;
//     const token = getToken(state);
//     const response = await putOrder(order, { headers: getAuthHeaders(token) });
//     return [response.data];
//   }
// );
