import React, { useMemo } from 'react';
import useAxios from 'axios-hooks';
import { If } from 'react-if';
import {
  Avatar,
  Box,
  CardContent,
  CardHeader,
  createStyles,
  Fab,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Map } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router';

import SimpleLoader from '../bos_common/src/components/SimpleLoader';
import { FullscreenPaper } from '../bos_common/src';
import { FabContainer } from '../bos_common/src/components/FabContainers';

// src
import { Merchant } from '../services/models';
import { getSortedMerchantsListBasedOnStatus, isEmptyOrNil } from '../utils';
import PageHeader from '../components/common/PageHeader';
import PromotionsDrawer from '../components/common/PromotionsDrawer';
import StoresListDetailed from '../components/Stores/StoresListDetailed';
import AppFooter from '../components/common/AppFooter';
import PlatformPromotionModal from '../components/PromotionModal';
import AppLogo from "../assets/icons/logo.svg"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '100vh',
      backgroundColor: '#f5faff !important',
      overflow: 'auto',
      marginTop: 4,

      '& .marketplace-header': {
        padding: theme.spacing(2),
        paddingBottom: 0,
      },
    }
  })
)

const PageTitleMarkup = (
  <>
    <CardHeader
      sx={{ padding: `8px 8px 8px 0` }}
      title={
        <Typography variant="h6" component="div" className="store-name">
          One Market
        </Typography>
      }
      avatar={<Avatar color="secondary" alt={"One Market"} src={AppLogo} variant="square" />}
      subheader={(
        <Typography variant="caption" component="div">
          Buy Local, Earn Rewards
        </Typography>
      )}
    />
  </>
)

const MerchantListHeader = ({ data, loading }: { data?: Merchant[], loading: boolean }) => (
  <div className='marketplace-header'>
    <div>
      <Typography variant="h6" component="div">Open near me</Typography>
    </div>

    <If condition={isEmptyOrNil(data) && !loading}>
      <CardContent><p>No merchant Exists Nearby</p></CardContent>
    </If>
  </div>
)

const HomePage = (): React.ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [{ data: fetchedMerchantList, loading }] = useAxios<Merchant[]>({
    url: '/users/nearby/merchants',
    method: "GET"
  })

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  // Sort Merchant: Show online merchants at start
  const merchants = useMemo(() => getSortedMerchantsListBasedOnStatus(fetchedMerchantList || []), [fetchedMerchantList]);

  const handleClickSearchMap = () => {
    history.push('/discovery', { from: location.pathname });
  }

  return (
    <div className={"container"}>
      <PageHeader
        theme="dark"
        title={PageTitleMarkup}
      />
      <FullscreenPaper>
        <div className={classes.root}>
          <SimpleLoader loading={loading} />
          <MerchantListHeader data={merchants} loading={loading} />
          <Box sx={{ px: 2, my: 1 }}>
            <StoresListDetailed merchants={merchants} />
          </Box>
        </div>

        <PlatformPromotionModal />

        <AppFooter />

        <FabContainer
          alignment='right'
          fabStyle={{ padding: "24px 16px 16px" }}
          fadeTop={isMobile}>
          <Fab
            color="primary"
            aria-label="Search"
            variant="extended"
            onClick={handleClickSearchMap}>
            <Map sx={{ mr: 1 }} />
            Search &amp; Map View
          </Fab>
        </FabContainer>
      </FullscreenPaper>

      <PromotionsDrawer />
    </div>
  )
}

export default HomePage;
