import { isEmptyOrNil } from "bos_common/src";
import { differenceInHours } from "date-fns";
import { path } from "ramda";

import { getFarmersMarketMerchants } from "bos_common/src/services/merchantService";
import { StructuredAddress } from "bos_common/src/types/AddressType";

import { hoursForDate, nextSpecialHours } from "../../../services/hoursUtils";
import { getStoredJSON, LOCALSTORAGE_MARKETPLACE_APP_KEYS } from "../../../services/localStorage";

import { Merchant, OperationHours, SpecialHours } from "../../../services/models";
import { getTimeLabelFromOffset } from "../../../utils";

export enum MULTI_STORE {
  ZEROAND = 'zeroand',
  OCEAN_EMPIRE = 'ocean_empire',
  CHEUNG_HING = 'cheung_hing',
}

const toMultistore = {
  "b_qbvour3xp" : MULTI_STORE.OCEAN_EMPIRE,
  "b_fZjeVhuW" : MULTI_STORE.OCEAN_EMPIRE,
  "b_OlOAwD8F" : MULTI_STORE.OCEAN_EMPIRE,
  "b_gkBUgip5" : MULTI_STORE.OCEAN_EMPIRE,
  "b_ppnhkv5n5":  MULTI_STORE.CHEUNG_HING,
  "b_q68v3di99":  MULTI_STORE.CHEUNG_HING,
  "b_vd8rxwbzd":  MULTI_STORE.CHEUNG_HING,
  "b_dzcae55wl":  MULTI_STORE.CHEUNG_HING,
  "b_z4klb5cln":  MULTI_STORE.CHEUNG_HING,
};

const DISCOVERY_BAY_AREA_ID = 'b_o0ojlap66';

export const toExternalReservation = {
  'b_ttjdpckvv': 'https://www.opentable.com/r/taurus-steakhouse-san-carlos?utm_source=1m&utm_campaign=1m_social&utm_medium=affiliate',
  'b_v112m5cl2': 'https://www.opentable.com/r/taurus-steakhouse-san-carlos?utm_source=1m&utm_campaign=1m_social&utm_medium=affiliate',    // for testing
  'b_7rqjors41': 'https://www.opentable.com/r/avenida-san-mateo?utm_source=1m&utm_campaign=1m_social&utm_medium=affiliate'
}

export interface SimpleMerchant {
  label: string,
  key: string,
  id: string,
  structuredAddress: StructuredAddress
}

// prdocution IDs
export const getMultiStoreKey = (m: Merchant) => (toMultistore[m.id] || m.parentMerchant?.id)
export const isOceanEmpire = (m: Merchant) => toMultistore[m.id] === MULTI_STORE.OCEAN_EMPIRE;
export const isDiscoveryBayArea = (m: Merchant) => m.id === DISCOVERY_BAY_AREA_ID;
export const isOrderAmountApproximate = (m: Merchant) => getFarmersMarketMerchants().has(m.id);
export const isMultiStore = (m: Merchant) => !!(toMultistore[m.id] || m.multiStore)

export const zeroAndInsulatedBagIds = ['m_8rsfhsbrv', 'm_jvfwz5upu', 'm_apc9o7vl5', 'm_e8UQ3uz4'];

export const multiStores: { [key: string]: {[key: string]: SimpleMerchant} } ={
  [MULTI_STORE.OCEAN_EMPIRE] : {
    "b_fZjeVhuW": {
      label: "Farmer's Market (Millbrae, CA; Sat)",
      key: 'ocean_empire_millbrae',
      id: 'b_fZjeVhuW',
      structuredAddress: {
        "lat": 37.6004273,
        "lng": -122.3909726,
        "city": "Millbrae",
        "line1": "238 Broadway",
        "state": "CA",
        "country": "US",
        "postal_code": "94030"
      }
    },
    "b_gkBUgip5": {
      label: "Storefront (South San Francisco, CA; Thur & Fri)",
      key: 'ocean_empire_south_sanfrancisco',
      id: 'b_gkBUgip5',
      structuredAddress: {
        "lat": 37.6479867,
        "lng": -122.4006227,
        "city": "South San Francisco",
        "line1": "274 Harbor Way",
        "state": "CA",
        "country": "US",
        "postal_code": "94080"
      }
    },
    "b_OlOAwD8F": {
      label: "Warehouse Buffet Restraurant (Foster City, CA; Thur)",
      key: 'ocean_empire_fc',
      id: 'b_OlOAwD8F',
      structuredAddress: {
        "lat": 37.5435737,
        "lng": -122.2704842,
        "city": "Foster City",
        "line1": "979 Edgewater Blvd",
        "state": "CA",
        "country": "US",
        "postal_code": "94404"
      }
    },
  },

  [MULTI_STORE.CHEUNG_HING] : {
    "b_ppnhkv5n5": {
      label: "Cheung Hing (Geary SF)",
      key: 'cheung_hing_geary_sf',
      id: 'b_ppnhkv5n5',
      structuredAddress: {
        "lat": 37.77971,
        "lng": -122.486504,
        "city": "SF",
        "line1": "6255 Geary Blvd",
        "state": "CA",
        "country": "US",
        "postal_code": "94121"
      }
    },
    "b_vd8rxwbzd": {
      label: "Cheung Hing (San Mateo)",
      key: 'cheunghing_sanmateo',
      id: 'b_vd8rxwbzd',
      structuredAddress: {
        "lat": 37.5300605,
        "lng": -122.2905091,
        "city": "San Mateo",
        "line1": "40 42nd Ave",
        "state": "CA",
        "country": "US",
        "postal_code": "94403"
      },
    },
    "b_dzcae55wl": {
      label: "Cheung Hing (Millbrae)",
      key: 'cheung_hing_millbrae',
      id: 'b_dzcae55wl',
      structuredAddress: {
        "lat": 37.6007288,
        "lng": -122.3905548,
        "city": "Millbrae",
        "line1": "245 El Camino Real",
        "state": "CA",
        "country": "US",
        "postal_code": "94030"
      },
    },
    "b_z4klb5cln" : {
      label: "Cheung Hing (SSF)",
      key: 'cheung_hing_ssf',
      id: 'b_z4klb5cln',
      structuredAddress: {
          "lat": 37.6550722,
          "lng": -122.4111522,
          "city": "South San Francisco",
          "line1": "333 Grand Ave",
          "state": "CA",
          "country": "US",
          "postal_code": "94080"
      },
    },
  }
}

export const getSelectedStoreLabel = (m: Merchant) => {
  if (m.multiStore) {
    return m.multiStore.label || m.officialName;
  }

  const multiStoreKey = toMultistore[m.id];
  return path([multiStoreKey, m.id, 'label'], multiStores) || "";
}

export const getMultiStoresList = (m: Merchant) => {
  const multiStoreKey = toMultistore[m.id];
  return multiStores[multiStoreKey] || {};
}

export const shouldOpenStoreSelectorDrawer = (m: Merchant) => {
  const multiStoreKey = getMultiStoreKey(m);
  if (!multiStoreKey) return false;

  const lastOpenedAt = getStoredJSON(LOCALSTORAGE_MARKETPLACE_APP_KEYS.STORE_SELECTOR_LAST_OPENED_AT);
  if (!lastOpenedAt) return true
  if (!lastOpenedAt[multiStoreKey]) return true;

  const now = new Date()
  const isLastVisit24HrsAgo = differenceInHours(now, new Date(lastOpenedAt[multiStoreKey])) > 24
  return isLastVisit24HrsAgo
}

// By Default Returen redial distance b/w two points in Miles
export const getDistanceBetweenTwoCoordinates = (lat1: number, lng1: number, lat2: number, lng2: number, unit?: "K" | "N"): number => {
  const radlat1 = Math.PI * lat1 / 180
  const radlat2 = Math.PI * lat2 / 180
  const theta = lng1 - lng2
  const radtheta = Math.PI * theta / 180
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist)
  dist = dist * 180 / Math.PI
  dist = dist * 60 * 1.1515
  if (unit == "K") { dist = dist * 1.609344 }
  if (unit == "N") { dist = dist * 0.8684 }
  return dist
}

export const getDistance = (address: StructuredAddress | undefined, currentPosition: GeolocationPosition | undefined): string => {
  if (isEmptyOrNil(currentPosition) || isEmptyOrNil(address)) return '';
  const currentPosLat = currentPosition?.coords.latitude ?? 0
  const currentPosLon = currentPosition?.coords.longitude ?? 0

  const { lat = 0, lng = 0 } = address!;

  const distance = getDistanceBetweenTwoCoordinates(currentPosLat, currentPosLon, lat, lng)
  const distanceLabel = new Intl.NumberFormat('en-US', { notation: "compact", }).format(distance);
  return `(${distanceLabel} mi.)`
}

export const getStoreFrontHoursLabel = (merchant: Merchant, hours: OperationHours): string => {
  const hoursToday = hoursForDate(merchant, new Date());
  let hasHoursToday = true;

  let start, end;
  let startFormatted, endFormatted;

  if (isEmptyOrNil(hoursToday)) {
    hasHoursToday = false;

    const nextSpecialHoursAvailable = nextSpecialHours(merchant);
    if (isEmptyOrNil(nextSpecialHoursAvailable))
      return 'No Hours specified';

    start = nextSpecialHoursAvailable!.fromMinOffset;
    end = nextSpecialHoursAvailable!.toMinOffset;

    startFormatted = `${nextSpecialHoursAvailable?.dayOfWeek.substring(0, 3)} ${getTimeLabelFromOffset(start)}`;
    endFormatted = getTimeLabelFromOffset(end);
  } else {
    const defaultHour = hoursToday as OperationHours;
    const specialHour = hoursToday as SpecialHours[];
    if (defaultHour && defaultHour.defaultFromMinOffset) {
      startFormatted = getTimeLabelFromOffset(defaultHour.defaultFromMinOffset);
      endFormatted = getTimeLabelFromOffset(defaultHour.defaultToMinOffset);
    } else if (specialHour && specialHour.length > 0) {
      startFormatted = getTimeLabelFromOffset(specialHour[0].fromMinOffset);
      endFormatted = getTimeLabelFromOffset(specialHour[0].toMinOffset);
    }
  }

  const timeString = `${startFormatted} - ${endFormatted}`;

  return hasHoursToday ?
    `${timeString} Today` :
    `Next Available: ${timeString}`;
}
