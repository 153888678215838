import { Merchant } from '../types/MerchantType';

export const isOpenCheckForPickup = (merchantId: string) : boolean => {
    return getFarmersMarketMerchants().has(merchantId);
};

export const getFarmersMarketMerchants = (): Map<string, string> => {
    const map = new Map<string, string>();
    map.set("b_qbvour3xp", "oceanempire");
    map.set("b_gkBUgip5", "ocean_empire_south_sanfrancisco");
    map.set("b_OlOAwD8F", "ocean_empire_fc");
    map.set("b_fZjeVhuW", "ocean_empire_millbrae");
    map.set("b_o0ojlap66", "discoverbayarea");
    map.set("b_akht533wa", "antoine’s_cookie_shop");
    return map;
};

export const isZeroAndStore = (m: Merchant) =>
  ['b_np2n2hyfk',
  'b_ajfglvb36',
  'b_ofo1mu720',
  'b_12dafyfla',
  'b_maiib8528',
  'b_o7ef9zk9l',
  'b_8dp1vih8u',
  'b_fj6nfe3pl',].includes(m.id);
