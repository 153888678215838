import React, { SetStateAction } from "react";
import {
  Merchandise,
  MerchandiseModifiersType,
  Merchant,
  Order,
  OrderType,
  MerchantCoupon,
  FeatureFlags,
  MerchandiseApiResponseType,
  MerchantQRCode,
  OrderPickupType
} from "../services/models";

export interface MerchantCartConfig extends MerchandiseApiResponseType {
  merchant: Merchant;
}

export enum CartType {
  REGULAR = 'regular',
  FROM_OPEN_CHECK = 'from_open_check',
  ADD_ITEMS_OPEN_CHECK = 'add_items_open_check',
}

export interface CartItemType extends Merchandise {
  quantity: number;
  note?: string;
  // a map of all applied modifiers
  modifiers?: MerchandiseModifiersType;
  // a signature of the modifiers
  modifiersSignature?: number;
  modifiersDisplayOrder?: Array<string>;
  quantityRedeemed?: number;
  couponRedeemedAmount?: number;
}

// those two fields should be mutual exclusive
export interface TipData {
  tipRate?: number
  customTip?: number
}

export const defaultTipData: TipData = {
  tipRate: undefined
}

export interface RemoveItemFromCartParams {
  merchandiseId: string
  modifiersSignature: number | undefined
  clearThisItemFromCart?: boolean
}

export type SwitchMerchantAlert = {
  previousMerchantName?: string,
  currentMerchantName?: string,
  isOpen: boolean
}

/**
 * The context carried out through the SPA, when adding attributes, remember to
 * 1/ update clearCart in Reducers
 * 2/ update storeAppContextState in localStorage
 */
export interface AppContextStateType {
  cart: CartItemType[],
  merchantConfig?: MerchantCartConfig,
  tip: TipData,
  setTip: (_: TipData) => void,
  orderType: OrderType,
  tableQrCodeObject?: MerchantQRCode,
  setOrderType: (_: OrderType, __?: MerchantQRCode) => void,
  pickupTime?: Date,
  setPickupTime: (_: Date | undefined) => void,
  addItemToCart: (_: CartItemType, __: Merchant | undefined) => void,
  removeItemFromCart: (_: RemoveItemFromCartParams) => void,
  redeemItems: (_: CartItemType[]) => void,
  cancelRedemption: () => void,
  coupon?: MerchantCoupon,
  setCoupon: (_: MerchantCoupon | undefined) => void,
  clearCart: () => void,
  activeOrder?: Order,
  setActiveOrder: (_: Order | undefined) => void,
  openCheckOrder?: Order,
  cartType?: CartType,
  setCartType: (_: CartType, __?: MerchantCartConfig, ___?: Order) => void,
  featureFlags?: FeatureFlags,
  reFetchMerchandisesForCart: () => boolean,
  setSwitchMerchantAlert: (_: SwitchMerchantAlert) => void,
  orderPickupType: OrderPickupType | null
  setOrderPickupType: (_: OrderPickupType) => void,
}

export const AppContext = React.createContext<AppContextStateType>({
  cart: [],
  tip: defaultTipData,
  setTip: (_: SetStateAction<TipData>) => ({}),
  orderType: OrderType.PICKUP,
  setOrderType: (_: SetStateAction<OrderType>, __?: SetStateAction<MerchantQRCode>) => ({}),
  setPickupTime: (_: SetStateAction<Date | undefined>) => ({}),
  addItemToCart: (_: CartItemType, __: Merchant | undefined) => ({}),
  removeItemFromCart: (_: RemoveItemFromCartParams) => ({}),
  redeemItems: (_: CartItemType[]) => ({}),
  cancelRedemption: () => ({}),
  setCoupon: (_: SetStateAction<MerchantCoupon | undefined>) => ({}),
  clearCart: () => ({}),
  setActiveOrder: (_: SetStateAction<Order | undefined>) => ({}),
  setCartType: (_: SetStateAction<CartType>, __?: SetStateAction<MerchantCartConfig>, ___?: SetStateAction<Order>) => ({}),
  reFetchMerchandisesForCart: () => false,
  setSwitchMerchantAlert: (_: SetStateAction<SwitchMerchantAlert>) => void ({}),
  orderPickupType: null,
  setOrderPickupType: (_: SetStateAction<OrderPickupType>) => ({}),
})