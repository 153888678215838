import { isEmptyOrNil } from "bos_common/src"
import { AppContextStateType, CartType } from "../context/AppContext"

export enum LOCALSTORAGE_MARKETPLACE_APP_KEYS {
  APP_CONTEXT_STATE = 'app-context-state',
  ORDER_TYPE = 'order-type',
  PROMOTION_DRAWER = 'promotion-drawer',
  PROMO_MERCHANT_DRAWER = 'promotion-merchant-drawer',
  PROMO_MERCHANT_DRAWER1 = 'promotion-merchant-drawer1',
  MARKETPLACE_DISCOVERY_SEARCH_STATE = 'marketplace-discovery-search-state',
  CARRYOUT_BAG_CHOSEN = 'carryout-bag-chosen',
  PREFERRED_STORE_ID = 'preferred-store-id',
  STORE_SELECTOR_LAST_OPENED_AT = 'store-selector-last-opened-at',
  CART_RECOMMENDATION_DRAWER = 'cart-rec-drawer',
  RESERVATION_PARAMS = 'reservation-params',
  GIFT_REFERRAL_DATA = 'gift-referral-data',
  AFFILIATE_DATA = 'affiliate-data',
}

interface AppContextStateStorageType {
  appState: Partial<AppContextStateType>,
  storedTimestamp: number,
}

export const storeAppContextState = (appState: AppContextStateType): void => {
  try {
    if (appState?.cartType !== CartType.REGULAR) return

    if (appState?.cart?.length > 0) {
      const appStateCopy = {
        cart: appState.cart,
        merchantConfig: appState.merchantConfig,
        tip: appState.tip,
        coupon: appState.coupon,
        orderType: appState.orderType,
        pickupTime: appState.pickupTime,
        tableQrCodeObject: appState.tableQrCodeObject,
      } as Partial<AppContextStateType>

      const storageData = {
        appState: appStateCopy,
        storedTimestamp: Date.now(),
      } as AppContextStateStorageType

      localStorage.setItem(
        LOCALSTORAGE_MARKETPLACE_APP_KEYS.APP_CONTEXT_STATE,
        JSON.stringify(storageData)
      )
    } else {
      removeAppContextStateStorage()
    }
  } catch (err) {
    console.log(err)
  }
}

export const getStoredAppContextState = (currState: Partial<AppContextStateType>): Partial<AppContextStateType> => {
  try {
    const storageDataStr = localStorage.getItem(LOCALSTORAGE_MARKETPLACE_APP_KEYS.APP_CONTEXT_STATE) || undefined
    if (storageDataStr) {
      const storageData = JSON.parse(storageDataStr) as AppContextStateStorageType | undefined
      if (storageData && (Date.now() - storageData.storedTimestamp < 24 * 60 * 60 * 1000)) {
        const appStateCopy = {
          cart: storageData.appState.cart,
          merchantConfig: storageData.appState.merchantConfig,
          tip: storageData.appState.tip,
          orderType: storageData.appState.orderType,
          tableQrCodeObject: storageData.appState.tableQrCodeObject,
          coupon: storageData.appState.coupon,
          pickupTime: storageData.appState.pickupTime ? new Date(storageData.appState.pickupTime) : undefined,
        } as Partial<AppContextStateType>

        if (appStateCopy?.cart?.length && appStateCopy?.cart?.length > 0 && appStateCopy?.merchantConfig) {
          return {...currState, ...appStateCopy };
        } else {
          removeAppContextStateStorage();
        }
      }
    }
  } catch (err) {
    console.log(err)
  }

  return currState
}

export const getStoredJSON = (key: LOCALSTORAGE_MARKETPLACE_APP_KEYS) => {
  let result = {};
  try {
    result = JSON.parse(localStorage.getItem(key) || '');
  } catch (_) {
    // ignore
  }
  return result;
}

export const storeJSON = (key: LOCALSTORAGE_MARKETPLACE_APP_KEYS, val: any) => {
  localStorage.setItem(key, JSON.stringify(val));
}

type StoredTimedDataType = {
  val: string;
  storedTimestamp: number;
}

export const storeTimedKey = (key: LOCALSTORAGE_MARKETPLACE_APP_KEYS, val: string) => {
  storeJSON(key, {
    val,
    storedTimestamp: Date.now(),
  });
}

export const getStoredTimedKey = (key: LOCALSTORAGE_MARKETPLACE_APP_KEYS): string | null => {
  const jsonData = getStoredJSON(key) as StoredTimedDataType;
  if (jsonData && !isEmptyOrNil(jsonData)) {
    const { val, storedTimestamp } = jsonData;
    if (Date.now() - storedTimestamp < 24 * 60 * 60 * 1000) {
      return val;
    }
  }
  return null;
}

export const cleanUpStateAfterOder = (): void => {
  sessionStorage.removeItem(LOCALSTORAGE_MARKETPLACE_APP_KEYS.CARRYOUT_BAG_CHOSEN);
  localStorage.removeItem(LOCALSTORAGE_MARKETPLACE_APP_KEYS.AFFILIATE_DATA);
  localStorage.removeItem(LOCALSTORAGE_MARKETPLACE_APP_KEYS.GIFT_REFERRAL_DATA);
}

export const removeAppContextStateStorage = (): void => {
  localStorage.removeItem(LOCALSTORAGE_MARKETPLACE_APP_KEYS.APP_CONTEXT_STATE)
  sessionStorage.removeItem(LOCALSTORAGE_MARKETPLACE_APP_KEYS.CART_RECOMMENDATION_DRAWER)
}