import React, { useEffect, useState } from "react";
import { Alert, createStyles, makeStyles, Link } from "@material-ui/core";
import { Share } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { pathOr } from "ramda";

// src
import { isEmptyOrNil } from "../../utils";
import { Merchant, Order, User, Merchandise } from "../../services/models";

import BitlyService from "bos_common/src/services/BitlyService";
import { getMerchantAffiliateLink } from "bos_common/src/services/urls";
import config from "../../config/config";
import LinkWithCopyButton from "../common/LinkWithCopyButton";

interface AffiliateUserProps {
  order?: Order;
  merchant?: Merchant;
  user?: User,
  merchandise?: Merchandise
}

const useStyles = makeStyles(() => createStyles({
  root: {
    position: 'relative',
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto 100%",
    backgroundPosition: "right",
  }
}))

export const getAffiliateLink = async ({ merchant, user, merchandise }: Readonly<AffiliateUserProps>) => {
  const bitlyAccessToken = config.appConfig.bitlyAccessCode;
  if (merchant && user && !isEmptyOrNil(bitlyAccessToken)) {
    const bitlyService = new BitlyService(bitlyAccessToken)
    const response = await bitlyService.shortenUrl(getMerchantAffiliateLink(merchant, user, merchandise))
    return pathOr('', ['data', 'link'], response)
  }
}

export const useAffiliateUserLink = (props: Readonly<AffiliateUserProps>): string => {
  const [link, setLink] = useState<string>("");
  const { user, merchant, merchandise } = props;

  useEffect(() => {
    const init = async () => {
      const url = await getAffiliateLink({ user, merchant, merchandise })
      setLink(url)
    }
    init()
  }, [])

  return link;
}

export default function UserAffiliateBanner(props: Readonly<AffiliateUserProps>): React.ReactElement | null {
  const { user, merchant } = props;
  const classes = useStyles();
  const history = useHistory()

  if (!user || !merchant || !user.affiliateCode) {
    return null;
  }

  const affiliateLink = getMerchantAffiliateLink(merchant, user || {} as User)

  if (isEmptyOrNil(affiliateLink)) {
    return null;
  }

  const handleClick = () => {
    history.push({
      pathname: '/help/affiliate',
      state: { merchant }
    })
  }

  return (
    <Alert
      severity="error"
      iconMapping={{ error: <Share fontSize={"large"} color="primary" /> }}
      elevation={3}
      sx={{ display: 'flex' }}
      className={classes.root}
    >
      Share and Earn, include an
      &nbsp;
      <Link onClick={handleClick} >
        Affiliate link
      </Link>
      &nbsp;
      <LinkWithCopyButton url={affiliateLink} shorten={true} />
    </Alert>
  )
}