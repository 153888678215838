import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { rgba } from "polished";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    storeFrontSelectorContainer: {
      "& >div": {
        border: `1px solid ${rgba(theme.palette.common.black, 0.15)}`,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(1.2),

        display: "grid",
        gridTemplateColumns: '20px 1fr 20px',
        alignItems: "center",

        [theme.breakpoints.up('sm')]: {
          marginTop: 0,
          marginBottom: 0
        },

        "& .labelContainer": {
          marginLeft: theme.spacing(0.5),
          marginRight: theme.spacing(0.5),

          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",

          "& .pickup-location": {
            fontWeight: "bold",
          }
        }
      }
    },
  })
)

export default useStyles;