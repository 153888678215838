import React, { useContext } from 'react';
import {
  Button,
  CardContent,
  Divider,
  Typography,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { AccountCircle } from '@material-ui/icons';
import useAxios from 'axios-hooks';
import { UserContext } from '../bos_common/src/context/UserContext';
import { ColoredPaper } from '../bos_common/src/components/Papers';
import { logout } from '../bos_common/src/services/auth0';

import PageHeader from '../components/common/PageHeader';
import UserProfileSettings from '../components/User/UserProfileSettings';
import UserFoodPreferences from '../components/User/UserFoodPreference';
import UserApplicationPreferences from '../components/User/UserApplicationPreferences';
import { headerCustomStyle } from '../styles/Common.style';
import ImageUploadAvatar from '../bos_common/src/components/ImageUploadAvatar';
import SimpleLoader from '../bos_common/src/components/SimpleLoader'
import CustomizedChip from '../components/common/CustomizedChip';
import { ReactComponent as OneMarketLogo } from "../assets/icons/onemarket-logo.svg";
import { User } from '../services/models';
import axios from '../bos_common/src/services/backendAxios';
import { getAuthHeaders, isAppEmbed, isWebViewEmbed, uploadThumbnail } from '../utils';
import { If } from 'react-if';
import { useAppDispatch } from '../redux/hooks';
import { deleteMyAccount } from '../redux/slice/auth/authActions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "20px 20px 0px 0px !important",
      zIndex: 1,

      '& .avatarContainer': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: theme.spacing(4),
      },
      '& .avatar': {
        width: theme.spacing(16),
        height: theme.spacing(16),
      },
      '& .sectionSpacer': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      '& .buttonArea': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        marginTop: theme.spacing(2),
      },
    },
    navWrapper: {
      ...headerCustomStyle(theme, theme.palette.primary.main),
    }
  })
);

const defaultImage = "/user-avatar.png";

declare let window: any;

const UserSettingsPage = (): React.ReactElement => {
  const title = 'Profile & Preference'
  const classes = useStyles()
  const location = useLocation()
  const { user, loading, token, setAuthenticating, setLoginState, setLoading, updateUserPicture } = useContext(UserContext);
  const userPicture = user?.picture ?? defaultImage;
  const reduxDispatch = useAppDispatch();

  const [, executeGetUploadUrl] = useAxios(
    { url: '/upload-url', method: 'POST' },
    { manual: true }
  );

  const isEmbedApp = isWebViewEmbed() || isAppEmbed()

  const handleLogout = () => {
    clearLogoutUser();
  };

  const handleClickDelete = () => {
    if (confirm("Are you sure to delete your 1M account? This cannot be undone!")) {
      reduxDispatch(deleteMyAccount())
        .then((res) => {
          console.log({ res });
          clearLogoutUser();
        });
    }
  }

  const onPhotoSelect = (files: File[])=>{
    setLoading(true);
    executeGetUploadUrl()
        .then(async (response) => {
          if (response.status === 200) {
            const f0 = files[0];
            const { url, uploadUrl } = response.data;

            const uploadFileResponse = await axios.request<{ url: string }>({
              method: 'put',
              url: uploadUrl,
              data: f0,
              headers: { 'content-type': f0.type },
            });

            await uploadThumbnail(url , f0);

            return uploadFileResponse;
       }
        else {
          throw new Error("Error Getting Upload Url")
        }
      })
      .then(async (response)=>{
        const res = await axios.put<User>(
           '/users',
           { picture: response.config.url , id: user?.id },
           { headers: getAuthHeaders(token) }
         );

        if(res?.data?.picture){
          updateUserPicture(res.data.picture);
        }
        setLoading(false);
      })
      .catch(_ => setLoading(false));
  }

  const clearLogoutUser = () => {
    setLoginState(undefined, undefined)
    if (isEmbedApp && window?.webkit && window?.webkit.messageHandlers.logoutMessageHandler) {
      window?.webkit.messageHandlers.logoutMessageHandler.postMessage('');
    } else if (isEmbedApp && window?.logoutMessageHandler) {
      window.logoutMessageHandler.postMessage('')
    } else {
      setAuthenticating(true)
      logout(window.location.origin + '/', location.pathname);
    }
  }

  return (
    <div className="container">
      <SimpleLoader loading={loading} />

      <div className={classes.navWrapper}>
        <PageHeader title={title} leftChild={<AccountCircle fontSize="large" style={{padding: 12}} />} />
      </div>

      <ColoredPaper className={classes.root}>
        {user && (
          <CardContent>
            <ImageUploadAvatar
              logoSrc={userPicture}
              onPhotoSelect={onPhotoSelect}
            />
            <div className="avatarContainer">
              <Typography gutterBottom variant="h6" component="div">
                {user?.displayName}
              </Typography>
              <Typography gutterBottom variant="h6" component="div">
                {user?.phoneNumber}
              </Typography>
              <If condition={user.affiliateCode != null}>
                <CustomizedChip
                  label={<><OneMarketLogo/>&nbsp;Marlo</>}
                  color="primary"
                  sx={{pl: '4px', pr: '8px', fontSize: "16px"}}
                  variant={"filled"} />
              </If>
            </div>

            <Divider className="sectionSpacer" />

            <UserProfileSettings />
            <Divider className="sectionSpacer" />

            <UserFoodPreferences />
            <Divider className="sectionSpacer" />

            <UserApplicationPreferences />
            <Divider className="sectionSpacer" />

            <div className="buttonArea">
              <Button variant="outlined" color='primary' onClick={handleLogout} >
                Logout
              </Button>
            </div>
            <div className="buttonArea">
              <Button variant="outlined" color='primary' onClick={handleClickDelete} >
                Delete My Account
              </Button>
            </div>
          </CardContent>
        )}
      </ColoredPaper>
    </div>
  );
};

export default UserSettingsPage;
