import React from "react";
import {
  Stack,
  createStyles,
  makeStyles,
  Skeleton,
  Theme,
} from "@material-ui/core";

import MerchandiseItemCardSkeleton from "./MerchandiseItemCardSkeleton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.background.paper,
      padding: theme.spacing(2),
      marginTop: theme.spacing(1),
    }
  })
);

export const MerchandiseListSkeleton = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Skeleton sx={{ width: '195px', mb: 2 }} />

      <Stack direction="row">
        <MerchandiseItemCardSkeleton />
        <MerchandiseItemCardSkeleton />
        <MerchandiseItemCardSkeleton />
      </Stack>
    </div>
  );
}

export default MerchandiseListSkeleton;
