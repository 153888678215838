import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { storesToVoteFetchFailed, storesToVoteFetching, storesToVoteFetchSucceed, voteCountFetchFailed, voteCountFetching, voteCountFetchSucceed, voteCreateFailed, voteCreateSucceed, voteCreating, votedStoresFetchFailed, votedStoresFetching, votedStoresFetchSucceed, voteRemainingFetchFailed, voteRemainingFetching, voteRemainingFetchSucceed } from './voteAction';
import { getRemainingVotes, getStoresToVote, getStoreVotesCount, getVotedStores, postVote, VoteRequestBody } from '../../../services/votesApiService';
import { Merchant } from "../../../bos_common/src/types/MerchantType";
import { getToken } from '../auth/authSelector';
import { getAuthHeaders } from '../../../utils';
import { AxiosResponse } from 'axios';
import { merchantsFetchSucceed } from '../merchant/action';
import { PayloadAction } from '@reduxjs/toolkit';
import { fetchVoteCount as fetchVoteCountAction } from './voteAction';
import UserMerchantVote from "../../../bos_common/src/types/UserMerchantVote";

interface VoteCount {
  merchantId: string;
  voteCount: number;
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchStoresToVote() {
   try {
      yield put(storesToVoteFetching());
      const token: string = yield select(getToken);

      const res: AxiosResponse<{data: Merchant[]}> = yield call(getStoresToVote, { headers: getAuthHeaders(token) });
      const merchants = res.data.data;
      const storesToVoteIds = res.data.data.map((item) => item.id);
      
      yield put(merchantsFetchSucceed(merchants));
      yield put(storesToVoteFetchSucceed(storesToVoteIds));
      yield put(fetchVoteCountAction(storesToVoteIds));
      yield 
   } catch (e: any) {
      console.error(e);
      yield put(storesToVoteFetchFailed());
   }
}

function* fetchVotedStores() {
  try {
    yield put(votedStoresFetching());
    const token: string = yield select(getToken);

    const res: AxiosResponse<{data: UserMerchantVote[]}> = yield call(getVotedStores, { headers: getAuthHeaders(token) });
    const merchants = res.data.data.map((item) => item.merchant);
    const votedStoreIds = merchants.map((item) => item.id);
    
    yield put(merchantsFetchSucceed(merchants));
    yield put(votedStoresFetchSucceed(votedStoreIds));
    yield put(fetchVoteCountAction(votedStoreIds));
  } catch (e: any) {
    console.error(e);
    yield put(votedStoresFetchFailed());
  }
}

function* fetchVoteCount(action: PayloadAction<string[]>) {
  try {
    yield put(voteCountFetching());
    const token: string = yield select(getToken);

    const res: AxiosResponse<{data: VoteCount[]}> = yield call(getStoreVotesCount, action.payload, { headers: getAuthHeaders(token) });
    const counts = res.data.data;
    const mappedIds = {}
    counts.forEach((item: VoteCount) => mappedIds[item.merchantId] = item.voteCount);
    
    yield put(voteCountFetchSucceed(mappedIds));
  } catch (e: any) {
    console.error(e);
    yield put(voteCountFetchFailed());
  }
}

function* createVote(action: PayloadAction<VoteRequestBody>) {
  try {
     yield put(voteCreating());
     const token: string = yield select(getToken);

     const res: AxiosResponse<{data: UserMerchantVote}> = yield call(postVote, action.payload, { headers: getAuthHeaders(token) });
     const createdVote = res.data.data;
     
     yield put(voteCreateSucceed(createdVote));
     yield 
  } catch (e: any) {
     console.error(e);
     yield put(voteCreateFailed());
  }
}

function* fetchVoteRemaining() {
  try {
     yield put(voteRemainingFetching());
     const token: string = yield select(getToken);

     const res: AxiosResponse<{voteRemaining: number}> = yield call(getRemainingVotes, { headers: getAuthHeaders(token) });
     const voteRemaining = res.data.voteRemaining;
     
     yield put(voteRemainingFetchSucceed(voteRemaining));
     yield 
  } catch (e: any) {
     console.error(e);
     yield put(voteRemainingFetchFailed());
  }
}

function* voteSaga(): Generator {
  yield all([
    takeLatest("VOTE_FETCH_REQUESTED", fetchStoresToVote),
    takeLatest("STORES_TO_VOTE_FETCH_REQUESTED", fetchStoresToVote),
    takeLatest("VOTED_STORES_FETCH_REQUESTED", fetchVotedStores),
    takeLatest("VOTE_COUNT_FETCH_REQUESTED", fetchVoteCount),
    takeLatest("VOTE_CREATE_REQUESTED", createVote),
    takeLatest("VOTE_REMAINING_FETCH_REQUESTED", fetchVoteRemaining),
  ]);
}

export default voteSaga;