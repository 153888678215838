
import React from 'react'
import { SvgIcon } from "@material-ui/core";

function SoupIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <g clipPath="url(#clip0_8881_45366)">
        <path d="M15.52 2.577c-.61-.61-1.57-.643-2.14-.073L9.866 6.02H.52A.521.521 0 000 6.542v2.433A4.701 4.701 0 003.129 13.4v.79c0 .862.701 1.564 1.564 1.564h4.172c.862 0 1.564-.702 1.564-1.564v-.79a4.7 4.7 0 003.129-4.425V6.752l2.035-2.035c.57-.57.537-1.53-.073-2.14zM9.386 14.19a.522.522 0 01-.521.521H4.693a.522.522 0 01-.521-.521v-.55c.17.018.345.028.521.028h4.172c.176 0 .35-.01.521-.029v.55zm3.129-5.215a3.654 3.654 0 01-3.65 3.65H4.693a3.654 3.654 0 01-3.65-3.65V7.063h11.472v1.912zm2.34-4.996l-2.04 2.041h-1.476l2.78-2.778c.16-.16.464-.128.664.072s.233.505.072.665z" />
        <path d="M6.844 2.199c-.323-.187-.324-.793 0-.98A.521.521 0 006.32.316c-1.016.588-1.016 2.197 0 2.785.323.187.324.793 0 .98a.521.521 0 00.523.903c1.016-.588 1.016-2.197 0-2.785z" />
        <path d="M4.758 2.199c-.323-.187-.323-.793 0-.98a.521.521 0 00-.522-.903c-1.017.588-1.017 2.197 0 2.785.322.187.323.793 0 .98a.521.521 0 00.522.903c1.016-.588 1.017-2.197 0-2.785zM8.93 2.199c-.323-.187-.324-.793 0-.98a.521.521 0 00-.523-.903c-1.016.588-1.016 2.197 0 2.785.323.187.323.793 0 .98a.521.521 0 10.522.903c1.017-.588 1.017-2.197 0-2.785zM4.693 10.54a1.566 1.566 0 01-1.564-1.565.521.521 0 00-1.043 0 2.61 2.61 0 002.607 2.607.521.521 0 100-1.043z" />
      </g>
      <defs>
        <clipPath id="clip0_8881_45366">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default SoupIcon