import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { WritableDraft } from '@reduxjs/toolkit/node_modules/immer/dist/internal';
import { Order } from '../../../bos_common/src/types/OrderTypes';
import { Review } from "../../../bos_common/src/types/ReviewType";

import { ReviewSliceState } from '../../../types/ReviewSlice';
import { createReview, fetchReviewsForOrder } from './reviewAction';

type ReviewSliceCaseReducer = SliceCaseReducers<ReviewSliceState>;

const commonUpdate = (state: WritableDraft<ReviewSliceState>, action: PayloadAction<Review[]>): ReviewSliceState => {
  let review: Review | undefined;
  let order: Order;
  const { payload } = action;
  const byId = { ...state.entities.byId }
  const ids = [...state.entities.ids];
  const byOrder = {...state.entities.byOrder};
  // const orderIds: Set<string> = new Set<string>(payload.map((item) => item.order.id));

  payload.forEach((item) => {
    review = payload.find((subItem) => subItem.id === item.id);
    order = item.order as Order;

    if (review) {
      byId[item.id] = review;
    }

    if (ids.indexOf(item.id) === -1) {
      ids.push(item.id);
    }

    if (!byOrder[order.id]) {
      byOrder[order.id] = [];
    }

    if (byOrder[order.id].indexOf(item.id) === -1) {
      byOrder[order.id] = [...(byOrder[order.id] || []), item.id];
    }

  });

  return {
    ...state,
    loading: false,
    entities: {
      byId,
      ids,
      byOrder,
    }
  }
}

export const reviewSlice = createSlice<ReviewSliceState, ReviewSliceCaseReducer, string>({
  name: 'review',
  initialState: {
    loading: false,
    entities: {
      ids: [],
      byId: {},
      byOrder: {},
    },
    ui: {
      earnRewardsShown: false,
    }
  },
  reducers: {
    setLoading: (state) => {
      return {
        ...state,
        loading: true
      }
    },

    stopLoading: (state) => {
      return {
        ...state,
        loading: false
      }
    },

    updateReview: (state, action: PayloadAction<Review[]>) => {
      const updatedState = commonUpdate(state, action);

      return {
        ...updatedState
      }
    },

    setUpdating: (state) => {
      return {
        ...state,
        updating: true,
      }
    },

    stopUpdating: (state) => {
      return {
        ...state,
        updating: false,
      }
    },
    showEarnRewards: (state) => {
      return {
        ...state,
        ui: {
          earnRewardsShown: true
        }
      }
    },
    hideEarnRewards: (state) => {
      return {
        ...state,
        ui: {
          earnRewardsShown: false
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchReviewsForOrder
      .addCase(fetchReviewsForOrder.pending, (state) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(fetchReviewsForOrder.fulfilled, (state, action) => {
        const updatedState = commonUpdate(state, action as PayloadAction<Review[]>);

        return {
          ...updatedState,
          loading: false
        }
      })
      .addCase(fetchReviewsForOrder.rejected, (state) => {
        return {
          ...state,
          loading: false,
        }
      })
      // createReview
      .addCase(createReview.pending, (state) => {
        return {
          ...state,
          updating: true
        }
      })
      .addCase(createReview.fulfilled, (state, action) => {
        const updatedState = commonUpdate(state, action as PayloadAction<Review[]>);

        return {
          ...updatedState,
          updating: false,
        }
      })
      .addCase(createReview.rejected, (state) => {
        return {
          ...state,
          updating: false,
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  stopLoading,
  updateReview,
  setUpdating,
  stopUpdating,
  showEarnRewards,
  hideEarnRewards
} = reviewSlice.actions;

export default reviewSlice.reducer;
