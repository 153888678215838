import React from "react";
import { createStyles, makeStyles, Theme  } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { If } from "react-if";
import { StoryComment } from "bos_common/src/types/MerchantStoryType";
import CommentIcon from "../../assets/icons/CommentIcon";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    "& .comment-content": {
      marginLeft: theme.spacing(.625),
      marginTop: 1,
      fontWeight: 500,
      color: theme.palette.grey[500],
      overflowWrap: 'anywhere',
    },
  }
}));

export interface CommentMainProps {
  comment: StoryComment;
  replyCount?: number;
  onAboutToComment?: (comment: StoryComment) => void;
}

export const CommentMain = (props: Readonly<CommentMainProps>): JSX.Element => {
  const { comment, replyCount, onAboutToComment } = props;
  const classes = useStyles();

  const handleClickComment = (comment: StoryComment) => {
    if (!onAboutToComment) {
      return;
    }

    onAboutToComment(comment);
  }

  return (
    <div className={classes.root}>
      <Typography component={"div"} noWrap={false} variant="body1" className="comment-content">
        {comment.content}
      </Typography>
      <If condition={Boolean(onAboutToComment)}>
        <IconButton sx={{ fontSize: '16px', alignItems: 'center' }} onClick={() => handleClickComment(comment)}>
          <CommentIcon sx={{mr: 0.5}}/>
          {replyCount ? replyCount : ''}
        </IconButton>
      </If>
    </div>
  );
}

export default CommentMain;
