import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import BackButton from "../common/BackButton";
import { MenuDrawer } from "../common/MenuDrawer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 2),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      maxWidth: "1280px",
      color: theme.palette.background.paper,
      "& .MuiSvgIcon-root": {
        color: theme.palette.background.paper,
      },
      "& .leftContent": {
        zIndex: 1,
        fontSize: 18,
      },
    },
  })
);

interface IMerchantPageParams {
  merchantUsername: string;
  merchandiseId?: string;
}

const StoryHeader = () => {
  const classes = useStyles();
  const history = useHistory();
  const { merchantUsername } = useParams<IMerchantPageParams>();

  const navigateToMenu = () => history.push({ pathname: `/${merchantUsername}/store`, state: { from: window.location.pathname } })

  return (
    <div className={classes.root}>
      <span className="leftContent">
        <BackButton onBack={navigateToMenu}/>
        See More Posts
      </span>
      <MenuDrawer />
    </div>
  );
};

export default StoryHeader;
