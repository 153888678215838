import React from "react";
import { Button, Theme, Typography, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { rgba } from "polished";
import { ChevronRight } from "@material-ui/icons";
import { If, Then } from "react-if";

import CountBox from '../common/CountBox';
import { isEmptyOrNil } from "../../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.background.paper,
      padding: theme.spacing(2),
      marginTop: theme.spacing(1),

      "& .totalItems": {
        fontSize: 10,
        fontWeight: 700,
        color: theme.palette.primary.main,
        padding: "2px 6px",
        background: rgba(theme.palette.primary.main, 0.15),
        borderRadius: "3px",
        marginLeft: theme.spacing(1),
      },

      "& .card-wrapper-header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(2)
      },

      "& .seeMoreButton": {
        padding: theme.spacing(0.8, 1.8),
        borderRadius: "30px",
        textTransform: "unset",
        backgroundColor: 'rgba(54, 66, 69, 0.07)',
        color: '#364245',

        "& span": {
          display: 'flex',
          alignItems: 'center'
        },

        "&:hover": {
          background: rgba(theme.palette.primary.main, 0.15),
        },

        "& .MuiButton-label": {
          display: "flex",
          alignItems: "center",
          lineHeight: 1,
        },
      },
    },
  })
);

type CardWrapperProps = {
  heading: string;
  children: React.ReactElement | React.ReactElement[];
  itemsCount: number;
  handleSeeMore?: () => void;
  moreText?: string;
};

const CardWrapper = (props: CardWrapperProps) => {
  const { heading, itemsCount, children, handleSeeMore, moreText = 'See More' } = props;

  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <div className="card-wrapper-header">
        <Typography variant="h6" component="div" sx={{marginLeft: theme.spacing(1)}}>
          {heading}
          <If condition={itemsCount}>
            <Then>
              &nbsp;&nbsp;<CountBox count={itemsCount} />
            </Then>
          </If>
        </Typography>
        <If condition={!isEmptyOrNil(handleSeeMore)}>
          <Button color="primary" className="seeMoreButton" onClick={handleSeeMore}>
            <Typography variant="subtitle2" component="span">
              {moreText} <ChevronRight />
            </Typography>

          </Button>
        </If>
      </div>

      {children}
    </div>
  );
};

export default CardWrapper;
