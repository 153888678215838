import { SetStateAction } from "react";
import { defaultTo, pick, pipe, pluck, reduce, values, without } from "ramda";

import { getStoredAppContextState, removeAppContextStateStorage, storeAppContextState } from "../services/localStorage";
import { OrderType, MerchandiseModifiersType, MerchantCoupon, Merchant, MerchandiseApiResponseType, MerchantQRCode, Order, Merchandise, LineItem, CouponValueType, AffectedItem, ItemType } from "../services/models";
import { isEmptyOrNil, simpleStringHash } from "../utils";
import { getTotalItems, getSubtotalPrice, getItemQuantity } from "../services/cartUtils";

import { getCouponFreeMerchandises, isCouponValid, getItemsEligibleForCoupon } from "../bos_common/src/services/CouponUtils";
import { TipData } from "../bos_common/src/types/MerchantOrderingConfigType";
import { PromotionCriteriaType } from '../bos_common/src/types/MerchantPromotionType';

import { AppContextStateType, CartItemType, CartType, defaultTipData, MerchantCartConfig, RemoveItemFromCartParams } from "./AppContext";
import config from "../config/config";

export const ADD_MERCHANDISE = "ADD_MERCHANDISE";
export const REMOVE_MERCHANDISE = "REMOVE_MERCHANDISE";
export const CLEAR_CART = "CLEAR_CART";
export const REINSTALL_APP_STATE = "REINSTALL_APP_STATE";
export const UPDATE_TIP_SELECTION = "UPDATE_TIP_SELECTION";
export const UPDATE_COUPON = "UPDATE_COUPON";
export const REDEEM_MERCHANDISE = "REDEEM_MERCHANDISE";
export const CANCEL_REDEMPTION = "CANCEL_REDEMPTION";
export const UPDATE_MERCHANDISE = "UPDATE_MERCHANDISE";
export const UPDATE_PICKUPTIME = "UPDATE_PICKUPTIME";
export const UPDATE_ORDER_TYPE = "UPDATE_ORDER_TYPE";
export const UPDATE_CART_TYPE = "UPDATE_CART_TYPE";

const getModifiersId = (modifiers: MerchandiseModifiersType | undefined, note: string | undefined) => {
  return (modifiers || note) ? simpleStringHash(`${JSON.stringify(modifiers)}${note}`) : undefined
}

const getBasePrice = (cartItem: CartItemType): number => {
  const customizationPrice = pipe(
    values,
    without([undefined, '', null]),
    reduce((acc: number, modiPrice: string) => acc += Number(modiPrice), 0)
  )(cartItem.modifiers)

  return Number(cartItem.price) - Number(customizationPrice)
}

const updateDiscountedItemsInCart = (
  allCartItems: CartItemType[],
  affectedItems: CartItemType[],
  redeemQuantity: number,
  percentage?: number
) => {
  const sortedItems = affectedItems?.sort((a: CartItemType, b: CartItemType) => Number(a.price) > Number(b.price) ? 1 : -1);
  const discountedItems: CartItemType[] = [];
  let toRedeemedQuantity = redeemQuantity;
  const percentageRedemption = defaultTo(1, percentage)

  for (let i = 0; i < sortedItems.length && toRedeemedQuantity > 0; i++) {
    const redeemedThisTime = Math.min(toRedeemedQuantity, Number(sortedItems[i].quantity));
    const couponRedeemedAmount = getBasePrice(sortedItems[i]) * redeemedThisTime * percentageRedemption;

    discountedItems.push({ ...sortedItems[i], couponRedeemedAmount })
    toRedeemedQuantity -= redeemedThisTime;
  }

  return allCartItems.map((cartItem) => {
    const updatedItemIndex = discountedItems.findIndex(item => (item.id === cartItem.id && item.modifiersSignature === cartItem.modifiersSignature));
    if (updatedItemIndex > -1) {
      return discountedItems[updatedItemIndex];
    } else {
      return cartItem;
    }
  });
}

const applyCouponToCart = (cart: CartItemType[], coupon?: MerchantCoupon) => {
  let updatedCart = cart.map((cartItem) => {
    if (cartItem.couponRedeemedAmount) {
      const updatedItem = {
        ...cartItem,
        couponRedeemedAmount: undefined,
      };
      return updatedItem;
    } else {
      return cartItem;
    }
  })

  const allEligibleItems = getItemsEligibleForCoupon(updatedCart, coupon, true) || [];
  const affectedItems = getItemsEligibleForCoupon(updatedCart, coupon) || [];
  const totalAffectedItems = getTotalItems(affectedItems);
  const subtotal = getSubtotalPrice(affectedItems);

  const allCriteria = [
    ...(coupon?.criteriaList || []),
    {
      type: coupon?.criteriaType,
      value: coupon?.criteriaValue,
    },
  ];

  // Criteria Type is not applicable on ABSOLUTE and PERCENTAGE type coupons
  for (const criteria of allCriteria) {
    switch (criteria.type) {
      case PromotionCriteriaType.MINIMUM_QUANTITY:
        if (getTotalItems(allEligibleItems) < Number(criteria.value)) {
          return updatedCart;
        }
        break;
      case PromotionCriteriaType.MINIMUM_SPEND:
        if (subtotal < Number(criteria.value)) {
          return updatedCart;
        }
        break;
      case PromotionCriteriaType.DEVICE_TYPE:
        if (config.appConfig.deviceType !== criteria.value) {
          return updatedCart;
        }
        break;
      case PromotionCriteriaType.NONE:
      default:
        break;
    }
  }

  if (coupon) {
    switch (coupon.valueType) {
      case CouponValueType.ABSOLUTE:
        break;
      case CouponValueType.PERCENTAGE:
        for (const item of affectedItems) {
          item.couponRedeemedAmount = Number(item.price) * Number(coupon.value) * item.quantity;
        }
        break;
      case CouponValueType.BUY_ONE_GET_ONE_FREE:
        {
          const half = Math.floor(totalAffectedItems / 2);
          if (half <= 0 || !affectedItems)
            break;
          updatedCart = updateDiscountedItemsInCart(updatedCart, affectedItems, half)
        }
        break;
      case CouponValueType.BUY_ONE_GET_ONE_ONLY_FREE:
          {
            const half = Math.floor(totalAffectedItems / 2);
            if (half <= 0 || !affectedItems)
              break;
            updatedCart = updateDiscountedItemsInCart(updatedCart, affectedItems, 1)
          }
          break;
      case CouponValueType.BUY_ONE_GET_ONE_PERCENTAGE:
        {
          const half = Math.floor(totalAffectedItems / 2);
          if (half <= 0 || !affectedItems)
            break;
          updatedCart = updateDiscountedItemsInCart(updatedCart, affectedItems, half, Number(coupon.value))
        }
        break;
      case CouponValueType.BUY_ONE_GET_ONE_ONLY_PERCENTAGE:
          {
            const half = Math.floor(totalAffectedItems / 2);
            if (half <= 0 || !affectedItems)
              break;
            updatedCart = updateDiscountedItemsInCart(updatedCart, affectedItems, 1, Number(coupon.value))
          }
          break;
      case CouponValueType.BUY_X_GET_Y_FREE:
        // X and Y are particular items, this translates to buy any of X get any one of Y items for free
        {
          const freeItems = getCouponFreeMerchandises(updatedCart, coupon) ?? [];
          if (!isEmptyOrNil(freeItems)) {
            if (totalAffectedItems === 1 && affectedItems[0].id === freeItems[0].id) {
              break;
            }
            updatedCart = updateDiscountedItemsInCart(updatedCart, [freeItems[0]], 1)
          }
        }
        break;
      case CouponValueType.GET_ONE_FREE:
        {
          const freeItems = getCouponFreeMerchandises(updatedCart, coupon);
          if (freeItems && freeItems.length > 0) {
            const freeItem = freeItems[0];
            const updatedItemIndex = updatedCart.findIndex(item => (item.id === freeItem.id && item.modifiersSignature === freeItem.modifiersSignature));
            if (updatedItemIndex !== -1) {
              updatedCart[updatedItemIndex].couponRedeemedAmount = Number(freeItem.price);
            }
          }
        }
        break;
      case CouponValueType.COMBO_ABSOLUTE:
      case CouponValueType.COMBO_PERCENTAGE:
        {
          // In the combo case, the system will require each affectedItem to exist in the order for the coupon
          // to be eligible.
          let notEligible = false;
          let toRedeemedAmount = coupon.value;
          const discountedItems: CartItemType[] = [];
          const sortedItems = affectedItems?.sort((a: CartItemType, b: CartItemType) => Number(a.price) > Number(b.price) ? 1 : -1);

          coupon.affectedItems?.forEach((aItem) => {
            let qty = aItem.qty || 1;
            sortedItems.forEach((item) => {
              const match = aItem.type === ItemType.CATEGORY ? item.categoryId === aItem.id : item.id === aItem.id;
              if (match) {
                const allowedQuantity = Math.min(qty, item.quantity);
                const couponRedeemedAmount = coupon.valueType === CouponValueType.COMBO_ABSOLUTE
                  ? Math.min(item.price * allowedQuantity, toRedeemedAmount)
                  : item.price * allowedQuantity * coupon.value;
                toRedeemedAmount -= couponRedeemedAmount;
                discountedItems.push({ ...item, couponRedeemedAmount });
                qty -= allowedQuantity;

                if (qty <=0) {
                  return;
                }
              }
            });
            if (qty > 0) {
              notEligible = true;
            }
          });

          if (notEligible) {
            return updatedCart;
          }

          return updatedCart.map((cartItem) => {
            const updatedItemIndex = discountedItems.findIndex(item => (item.id === cartItem.id && item.modifiersSignature === cartItem.modifiersSignature));
            if (updatedItemIndex > -1) {
              return discountedItems[updatedItemIndex];
            } else {
              return cartItem;
            }
          });
        }
        break;
    }
  }

  return updatedCart;
}

const addItemToCart = (
  item: CartItemType,
  merchant: Merchant,
  state: AppContextStateType) => {
  let updatedCart = [...state.cart];
  if (!item.modifiersSignature) {
    item.modifiersSignature = getModifiersId(item.modifiers, item.note)
  }
  const updatedItemIndex = updatedCart.findIndex(
    _item => (_item.id === item.id && _item.modifiersSignature === item.modifiersSignature)
  );

  if (updatedItemIndex < 0) {
    updatedCart.push(item);
  } else {
    const updatedItem = {
      ...updatedCart[updatedItemIndex],
    };
    updatedItem.quantity += item.quantity;
    updatedCart[updatedItemIndex] = updatedItem;
  }

  if (state.coupon) {
    updatedCart = applyCouponToCart(updatedCart, state.coupon);
  }

  const merchantConfig = state.merchantConfig?.merchant
    ? state.merchantConfig
    : {
      ...state.merchantConfig,
      merchant,
      merchandises: state.merchantConfig?.merchandises || [],
      modiTemplates: state.merchantConfig?.modiTemplates || [],
      modiCategories: state.merchantConfig?.modiCategories || [],
      modifiers: state.merchantConfig?.modifiers || [],
      mercCategories: state.merchantConfig?.mercCategories || [],
    }

  const newState = { ...state, cart: updatedCart, merchantConfig };
  storeAppContextState(newState) // switch to use effect later
  return newState;
};

interface RemoveItemFromCart extends RemoveItemFromCartParams {
  state: AppContextStateType
}

const removeItemFromCart = (params: RemoveItemFromCart) => {
  const {
    merchandiseId,
    modifiersSignature,
    clearThisItemFromCart = false,
    state
  } = params

  let updatedCart = [...state.cart];
  const updatedItemIndex = updatedCart.findIndex(item => (item.id === merchandiseId && item.modifiersSignature === modifiersSignature));

  const updatedItem = {
    ...updatedCart[updatedItemIndex]
  };

  if (clearThisItemFromCart) updatedItem.quantity = 0
  else updatedItem.quantity--;

  // make sure the redeemed quantity is never higher than the actual quantity
  if (updatedItem.quantityRedeemed && updatedItem.quantityRedeemed > 0 && updatedItem.quantityRedeemed > updatedItem.quantity) {
    updatedItem.quantityRedeemed = updatedItem.quantity;
  }

  if (updatedItem.quantity <= 0) {
    updatedCart.splice(updatedItemIndex, 1);
  } else {
    updatedCart[updatedItemIndex] = updatedItem;
  }

  if (state.coupon) {
    updatedCart = applyCouponToCart(updatedCart, state.coupon);
  }

  const newState = { ...state, cart: updatedCart };
  if (updatedCart.length === 0) {
    return clearCart(newState)
  } else {
    storeAppContextState(newState)
    return newState;
  }
};

const clearCart = (state: AppContextStateType) => {
  removeAppContextStateStorage()
  return {
    ...state,
    cart: [],
    merchantConfig: undefined,
    tip: defaultTipData,
    pickupTime: undefined,
    coupon: undefined,
  };
}

const reinstallAppState = (storedState: Partial<AppContextStateType>, state: AppContextStateType) => {
  return { ...state, ...storedState }
}

const updateTipSelection = (tip: Partial<TipData>, state: AppContextStateType) => {
  const newState = { ...state, tip }
  storeAppContextState(newState) // switch to use effect later
  return newState
}

const redeemMerchandise = (items: CartItemType[], state: AppContextStateType) => {

  let hasChanged = false;
  const updatedCart = state.cart.map((cartItem) => {
    const updatedItemIndex = items.findIndex(item => (item.id === cartItem.id && item.modifiersSignature === cartItem.modifiersSignature));
    if (updatedItemIndex > -1) {
      const redeemedItem = items[updatedItemIndex];
      const updatedItem = {
        ...cartItem
      };
      if (updatedItem.quantityRedeemed !== redeemedItem.quantityRedeemed) {
        hasChanged = true
      }
      updatedItem.quantityRedeemed = redeemedItem.quantityRedeemed;
      return updatedItem;
    } else {
      return cartItem;
    }
  })

  if (!hasChanged) {
    return state;
  }

  const newState = { ...state, cart: updatedCart };
  storeAppContextState(newState)
  return newState;
}

const cancelRedemption = (state: AppContextStateType) => {
  const hasRedemption = state.cart.reduce((acc, item) => acc + (item?.quantityRedeemed || 0), 0);
  if (hasRedemption === 0) {
    return state;
  }

  const updatedCart = state.cart.map((item, index) => {
    if (item.quantityRedeemed) {
      return { ...item, quantityRedeemed: 0 };
    } else {
      return item;
    }
  })

  const newState = { ...state, cart: updatedCart };
  storeAppContextState(newState) // switch to use effect later
  return newState
}

const updateCoupon = (coupon: MerchantCoupon | undefined, state: AppContextStateType) => {
  const newCoupon = isCouponValid(coupon) ? coupon : undefined;
  if (state.coupon === newCoupon) {
    return state;
  }

  const updatedCart = applyCouponToCart(state.cart, newCoupon);
  const newState = { ...state, cart: updatedCart, coupon: newCoupon };
  storeAppContextState(newState) // switch to use effect later
  return newState;
}

const updatePickupTime = (pickupTime: Date | undefined, state: AppContextStateType) => {
  if (!isEmptyOrNil(pickupTime) && pickupTime?.getTime() === state.pickupTime?.getTime()) {
    return state;
  }
  const newState = { ...state, pickupTime };
  return newState;
}

const updateOrderType = (orderType: OrderType, tableQrCodeObject: MerchantQRCode | undefined, state: AppContextStateType) => {
  const newState = { ...state, orderType, tableQrCodeObject };
  storeAppContextState(newState) // switch to use effect later
  return newState;
}

const _convertOrderLineItemsToCartItems = (
  order: Order | undefined,
  merchandises: Merchandise[] | undefined
): CartItemType[] => {
  if (!order || !merchandises) return []

  const cartItems: CartItemType[] = order.lineItems.reduce((acc: any, item: LineItem) => {
    const selectedMerch = merchandises.find((i: Merchandise) => i.id === item.id)
    if (!selectedMerch || item.refunded) return acc;

    const cartItem = {
      ...selectedMerch,
      ...(item.customizationSignature && {
        modifiersSignature: item.customizationSignature,
        modifiersDisplayOrder: item.customizationDisplayOrder,
        modifiers: item.customization,
      }),
      price: item.price,
      quantity: item.quantity,
      note: item.note,
      couponRedeemedAmount: item.couponRedeemedAmount,
      quantityRedeemed: item.quantityRedeemed,
    } as CartItemType;
    return [...acc, cartItem]
  }, [])

  return cartItems;
}

const updateCartType = (cartType: CartType, merchantConfig: MerchantCartConfig | undefined, openCheckOrder: Order | undefined, state: AppContextStateType) => {
  const newState = {
    ...state,
    cartType,
    openCheckOrder
  }
  if (cartType === CartType.REGULAR) {
    if (state.cartType === cartType || !state.cartType) {
      return state;
    }
    // clear cart if the cartType was different and not null
    return clearCart(newState);
  } else if (cartType === CartType.FROM_OPEN_CHECK && openCheckOrder && merchantConfig) {
    if (state.cartType === cartType && state.openCheckOrder === openCheckOrder) {
      return state;
    }
    return {
      ...newState,
      openCheckOrder,
      merchantConfig,
      OrderType: openCheckOrder.type,
      pickupTime: openCheckOrder.toPickupTime,
      cart: _convertOrderLineItemsToCartItems(openCheckOrder, merchantConfig?.merchandises)
    };
  } else if (cartType === CartType.ADD_ITEMS_OPEN_CHECK && openCheckOrder && merchantConfig) {
    return {
      ...newState,
      merchantConfig,
      openCheckOrder,
      cart: [],
    }
  }

  return newState;
}

const updateMerchandise = (mercData: MerchandiseApiResponseType, state: AppContextStateType) => {
  if (state.merchantConfig) {
    // update of inventory/price of cart items selected
    const updatedCart = state.cart.map((item) => {
      const selectedMerchandise = mercData.merchandises.find(i => i.id === item.id) || []
      return {
        ...item,
        ...(pick(['inventory', 'available', 'pointsToRedeem'], selectedMerchandise))
      }
    })
    const newState = {
      ...state,
      cart: updatedCart,
      merchantConfig: {
        ...state.merchantConfig,
        ...mercData,
      }
    }
    storeAppContextState(newState);
    return newState;
  }
  return state;
}

export const mercReducer = (state: any, action: SetStateAction<any>) => {
  switch (action.type) {
    case ADD_MERCHANDISE:
      return addItemToCart(action.item, action.merchant, state);
    case REMOVE_MERCHANDISE:
      return removeItemFromCart({
        ...action,
        state
      });
    case CLEAR_CART:
      return clearCart(state);
    case REINSTALL_APP_STATE:
      return reinstallAppState(action.storedState, state);
    case UPDATE_TIP_SELECTION:
      return updateTipSelection(action.tip, state);
    case REDEEM_MERCHANDISE:
      return redeemMerchandise(action.items, state);
    case CANCEL_REDEMPTION:
      return cancelRedemption(state);
    case UPDATE_COUPON:
      return updateCoupon(action.coupon, state);
    case UPDATE_MERCHANDISE:
      return updateMerchandise(action.mercData, state);
    case UPDATE_PICKUPTIME:
      return updatePickupTime(action.pickupTime, state);
    case UPDATE_ORDER_TYPE:
      return updateOrderType(action.orderType, action.tableQrCodeObject, state);
    case UPDATE_CART_TYPE:
      return updateCartType(action.cartType, action.merchantConfig, action.openCheckOrder, state);
    default:
      return state;
  }
};
