import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Theme, useMediaQuery, makeStyles, createStyles, IconButton, Box, Avatar } from "@material-ui/core";
import { NavigateBefore } from "@material-ui/icons";
import { If } from "react-if";
import useAxios from "axios-hooks";
import { propOr } from "ramda";
import { rgba } from "polished";

import { FullscreenPaper } from '../bos_common/src/components/Papers';
import SimpleLoader from "../bos_common/src/components/SimpleLoader";
import { Merchant } from "../services/models";
import InputSearchBox from "../bos_common/src/components/InputSearchBox";

import MerchantMenu from "../components/MerchantMenu/MerchantMenu";
import { isEmptyOrNil, isKioskDevice } from "../utils";
import ShoppingCartDrawer from "../components/ShoppingCart/ShoppingCartDrawer";
import ShoppingCartContextProvider from "../context/ShoppingCart/ShoppingCartContextProvider";
import MerchantPromotionsDrawer from "../components/common/MerchantPromotionsDrawer";

import MerchantFab from "../components/MerchantFab";

import NoneExistPage from "./NoneExistPage";
import MerchandiseDrawerPage from './MerchandiseDrawerPage'

interface IMerchantPageParams {
  merchantUserName: string,
  merchandiseId?: string,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(18),
      "& .header": {
        position: "fixed",
        width: "100vw",
        top: 0,
        height: theme.spacing(8),
        padding: `${theme.spacing(1)} ${theme.spacing(0.7)}`,
        boxSizing: "border-box",
        backgroundColor: theme.palette.primary.main,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,

        "& .search-box": {
          flex: 1,
          marginTop: 0
        },

        "& .filledIconButton": {
          color: rgba(theme.palette.secondary.contrastText, .7),
          backgroundColor: rgba(theme.palette.background.paper, .3),

          "&:hover": {
            backgroundColor: rgba(theme.palette.background.paper, .6),
            color: rgba(theme.palette.secondary.contrastText, .8),
          }
        },

        "& .merchantAvatar": {
          width: theme.spacing(6.2),
          height: theme.spacing(6.2)
        },
        "& .headerItem": {
          display: "flex",
          margin: theme.spacing(1.2)
        }
      },
      "& .MuiAppBar-positionSticky": {
        position: "fixed",
        top: theme.spacing(8)
      },
      "& .promotionContainer.sticky": {
        position: "relative",
        top: "unset",
        zIndex: "unset"
      },
      "& .MuiLinearProgress-root": {
        position: "fixed",
        zIndex: 1200,
        top: theme.spacing(8),
        width: "100%"
      }
    },

    totalCartItems: {
      '& .MuiBadge-badge': {
        color: theme.palette.background.paper,
        fontWeight: "bold"
      }
    }
  })
);

const MerchantSearchPage = (): React.ReactElement => {
  const [search, setSearch] = useState("");
  const classes = useStyles();

  const isKioskModeEnabled = isKioskDevice();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const { merchantUserName, merchandiseId } = useParams<IMerchantPageParams>()

  const [showCart, setShowCart] = useState<boolean>(false)

  const [{ data: merchant, loading }] = useAxios<Merchant>(
    { url: '/merchants', params: { username: merchantUserName } }
  )

  if (!merchant && !loading) {
    return <NoneExistPage title={<>Merchant&nbsp;<b>{merchantUserName}</b>&nbsp;not found</>} />
  }

  return (
    <div className={classes.root}>
      <Box className="header">
        <If condition={!isEmptyOrNil(merchant)}>
          <>
            <If condition={isDesktop}>
              <div className="headerItem">
                <Avatar variant="rounded" className="merchantAvatar" src={propOr("", "logo")(merchant)}>{propOr("", "username")(merchant).substring(0, 1)}</Avatar>
              </div>
            </If>
            <div className="search-box headerItem">
              <InputSearchBox
                onChangeQuery={(value: string) => setSearch(value)}
                placeholder={`Search in ${merchant?.officialName || ''}...`}
                defaultValue={search}
                forceSearch
                leftChild={
                  <IconButton sx={{ p: 0 }} component={Link} to={`/${merchant?.username}`}>
                    <NavigateBefore fontSize="large" color="primary" />
                  </IconButton>
                }
              />
            </div>
          </>
        </If>
      </Box>
      <SimpleLoader loading={loading} />
      {merchant &&
        <FullscreenPaper className="page-content">
          <MerchantMenu search={search} merchant={merchant} />
          <MerchantFab onShowCart={setShowCart} />
          <If condition={isKioskModeEnabled}>
            <ShoppingCartContextProvider>
              <ShoppingCartDrawer showCart={showCart} setShowCart={setShowCart} />
            </ShoppingCartContextProvider>
          </If>
          <MerchandiseDrawerPage
            merchant={merchant}
            merchandiseId={merchandiseId}
          />
          <MerchantPromotionsDrawer merchant={merchant} />
        </FullscreenPaper>
      }
    </div >
  );
}

export default MerchantSearchPage;