import {
  Box,
  Card,
  createStyles,
  makeStyles,
  Skeleton,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useContext, useMemo, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import { rgba } from "polished";

import { UserContext } from "bos_common/src/context/UserContext";

import { Merchandise, Merchant } from "../../services/models";
import { CarouselWrapper } from "../Classifieds/styledComponents";
import CardWrapper from "./CardWrapper";
import MerchandiseItemCard from "./MerchandiseItemCard";
import CardsListDrawer from "./CardsListDrawer";
import { isEmptyOrNil } from "../../utils";
import "react-alice-carousel/lib/alice-carousel.css";
import eventTrackingService from "../../services/Tracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../../services/Tracking/events";
import { getEventLabel } from "../../services/Tracking/EventsTracking";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    viewAllCard: {
      "&.MuiButtonBase-root": {
        width: 148,
        height: "100%",
        background: rgba(theme.palette.primary.main, 0.15),
        color: theme.palette.primary.main,
        borderRadius: theme.spacing(1),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      },
    },
  })
);

type FeaturedListProps = {
  merchandises: Merchandise[];
  merchant?: Merchant;
};

const FeaturedList = (props: FeaturedListProps): JSX.Element | null => {
  const { merchandises, merchant } = props;
  const { user } = useContext(UserContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const classes = useStyles();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const cardsLimit = isMobile ? 5 : 8;
  const maxPopularItems = 10;

  const featuredItems = useMemo(() => {
    const allItems: React.ReactElement[] = [];
    const selectedItems: React.ReactElement[] = [];

    merchandises
      .sort((a, b) => {
        if (a.featured === b.featured) {
          return a.unitsSold > b.unitsSold ? -1 : 1;
        } else {
          return a.featured ? -1 : 1;
        }
      })
      .slice(0, maxPopularItems)
      .forEach((item) => {
        allItems.push(
          <MerchandiseItemCard
            merchant={merchant}
            merchandise={item}
            key={item.id}
          />
        );

        if (selectedItems.length < cardsLimit) {
          selectedItems.push(
            <MerchandiseItemCard
              merchant={merchant}
              merchandise={item}
              key={item.id}
            />
          );
        }
      });

    return {
      allItems: allItems,
      selectedItems: selectedItems,
    };
  }, [merchandises]);

  const handleSeeMore = () => {
    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.MERCHANT_STORE_FRONT,
      action: EVENT_ACTIONS.CLICK_POPULAR_ITEMS_SEE_MORE,
      label: getEventLabel(merchant ?? {} as Merchant),
      data: {
        merchant: merchant,
        user,
      },
    });
    setDrawerOpen(true);
  }

  if (isEmptyOrNil(featuredItems.allItems)) return null;

  return (
    <CardWrapper
      heading="Popular Items"
      itemsCount={featuredItems.allItems.length}
      handleSeeMore={
        featuredItems.allItems.length > cardsLimit
          ? handleSeeMore
          : undefined
      }
    >
      <CarouselWrapper>
        <AliceCarousel
          items={featuredItems.selectedItems}
          autoHeight
          disableButtonsControls
          mouseTracking
          disableDotsControls={true}
          infinite={false}
          autoWidth
          responsive={{ 0: { items: 2 }, 800: { items: 4 } }}
        />
      </CarouselWrapper>

      <CardsListDrawer
        open={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        heading="Popular items"
        itemsList={featuredItems.allItems}
      />
    </CardWrapper>
  );
};

export default FeaturedList;
