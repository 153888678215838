import React, { useState, useEffect, useMemo } from 'react';
import {
  Button, createStyles, makeStyles, MenuItem,
  Stack, TextField, Theme, Typography
} from '@material-ui/core';
import { pathOr } from 'ramda';

import BottomDrawer from 'bos_common/src/components/BottomDrawer';
import { PartySetting } from 'bos_common/src/types/ReservationTypes';

import { Merchant } from '../../services/models';
import { TimerSelectChip } from '../Classifieds/styledComponents';
import DateSelectModal from './DateSelectModal';
import { isEmptyOrNil } from '../../utils';
import { getStoreTimeOptions } from '../../services/hoursUtils';

interface IPartySettingProps {
  merchant: Merchant;
  open: boolean;
  setOpen: (v: boolean) => void;
  data: PartySetting;
  setData: (v: PartySetting) => void;
}

type TimeOptionType = {
  label: string,
  minuteOffset: number
}

const useStyle = makeStyles((theme: Theme) => createStyles({
  drawerRoot: {
    '& .MuiPaper-root': {
      overflowY: "unset",
      borderRadius: "20px 20px 0 0", // to override inline style
      '& .reservation-time-select': {
        width: '50%',
        height: theme.spacing(6),

        '& .MuiOutlinedInput-root': {
          height: '100%',
          boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.07)',
          fontSize: 14,
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
          },
          '& .MuiSelect-selectMenu': {
            display: 'flex',
            justifyContent: 'center',
          }
        },
      }
    },
    '& .title': {
      textAlign: 'center',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }
  },
}))

const PartySettingDrawer = (props: IPartySettingProps): JSX.Element | null => {
  const {
    open, setOpen, data, setData, merchant
  } = props;
  const classes = useStyle();
  const [currentData, setCurrentData] = useState<PartySetting>(data);

  const title = (
    <Typography
      gutterBottom
      variant="h6"
      className="title"
      component="div">
      {"Make a reservation"}
    </Typography>
  )

  useEffect(() => {
    setCurrentData(data);
  }, [data])

  const handleClose = (v: boolean) => {
    setOpen(v);
  }

  const handleConfirm = () => {
    setData({
      ...currentData
    })
    setOpen(false);
  }

  const reservationTimeOptions = useMemo(() => {
    if (isEmptyOrNil(currentData.dinnerDate) || isEmptyOrNil(merchant)) return [];

    const dateTS = new Date(currentData.dinnerDate).setHours(0, 0, 0, 0)
    const timeOptionsList = getStoreTimeOptions(dateTS, merchant);

    setCurrentData({
      ...currentData,
      dinnerTime: pathOr(0, [0, 'minuteOffset'], timeOptionsList)
    })

    return timeOptionsList;
  }, [currentData.dinnerDate, merchant]);


  if (!open) {
    return null;
  }

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentData({
      ...currentData,
      dinnerTime: parseInt(event.target.value, 10)
    })
  };

  return (
    <BottomDrawer open={open} setOpen={handleClose} title={title} className={classes.drawerRoot}>
      <Stack spacing={2} sx={{ px: 3, pt: 2, pb: 6 }}>
        <Stack spacing={1}>
          <Typography variant="subtitle1" component="div">Party Size</Typography>
          <Stack direction="row" spacing={1}>
            {
              new Array(8).fill(0).map((_, i) => (
                <TimerSelectChip key={`people_${i}`}
                  selected={currentData.peopleNum === i + 1}
                  label={i + 1}
                  onClick={() => {
                    setCurrentData({
                      ...currentData,
                      peopleNum: i + 1
                    })
                  }}
                />
              ))
            }
          </Stack>
          <Typography variant="subtitle2" color="textSecondary" component="div">Please call restaurant for parties larger than 8.</Typography>
        </Stack>
        <Stack direction="row" spacing={1} justifyContent="space-evenly">
          <DateSelectModal
            data={currentData.dinnerDate}
            onConfirm={(v) => {
              setCurrentData({
                ...currentData,
                dinnerDate: v
              })
            }} />
          <TextField
            id="reservation-time"
            select
            value={currentData.dinnerTime}
            onChange={handleTimeChange}
            variant="outlined"
            className='reservation-time-select'
            SelectProps={{
              displayEmpty: true,
            }}
          >
            {reservationTimeOptions.map((val: TimeOptionType) => {
              return <MenuItem key={val.minuteOffset} value={val.minuteOffset}>
                {val.label}
              </MenuItem>
            })}
          </TextField>
        </Stack>
        <Stack>
          <Button variant={"contained"} size="large" onClick={() => { handleConfirm() }}>Continue</Button>
        </Stack>
      </Stack>
    </BottomDrawer>
  );
}

export default PartySettingDrawer