import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { WritableDraft } from '@reduxjs/toolkit/node_modules/immer/dist/internal';
import { PaymentMethod, SetupIntent } from '@stripe/stripe-js';

import { StripeSliceState } from '../../../types/StripeSlice';
import { createPaymentMethod, fetchUserPaymentMethods, persistCachedPaymentMethod, removePaymentMethod } from './stripeAction';

type UserSliceCaseReducer = SliceCaseReducers<StripeSliceState>;

const updatePaymentMethods = (state: WritableDraft<StripeSliceState>, action: PayloadAction<PaymentMethod[]>): StripeSliceState => {
  let paymentMethod: PaymentMethod | undefined;
  const { payload } = action;
  const stripePaymentMethods = { ...state.entities.stripePaymentMethods };

  payload.forEach((item) => {
    paymentMethod = payload.find((subItem) => subItem.id === item.id);

    if (paymentMethod) {
      stripePaymentMethods[item.id] = paymentMethod;
    }
  });

  return {
    ...state,
    loading: false,
    entities: {
      ...state.entities,
      stripePaymentMethods
    }
  }
}

export const stripeSlice = createSlice<StripeSliceState, UserSliceCaseReducer, string>({
  name: 'stripe',
  initialState: {
    loading: false,
    entities: {
      stripePaymentMethods: {},
      setupIntent: undefined,
    },
  },
  reducers: {
    setLoading: (state) => {
      return {
        ...state,
        loading: true
      }
    },

    stopLoading: (state) => {
      return {
        ...state,
        loading: false
      }
    },

    updatePaymentMethod: (state, action: PayloadAction<PaymentMethod[]>) => {
      const updatedState = updatePaymentMethods(state, action);

      return {
        ...updatedState
      }
    },

    setUpdating: (state) => {
      return {
        ...state,
        updating: true,
      }
    },

    stopUpdating: (state) => {
      return {
        ...state,
        updating: false,
      }
    },

    updateSetupIntent: (state, action: PayloadAction<SetupIntent>) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          setupIntent: action.payload,
        }
      }
    },
    clear: (state) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          stripePaymentMethods: {},
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchUserPaymentMethods
      .addCase(fetchUserPaymentMethods.pending, (state) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(fetchUserPaymentMethods.fulfilled, (state, action) => {
        const updatedState = updatePaymentMethods(state, action as PayloadAction<PaymentMethod[]>);

        return {
          ...updatedState,
        }
      })
      .addCase(fetchUserPaymentMethods.rejected, (state) => {
        return {
          ...state,
          loading: false,
        }
      })
      .addCase(createPaymentMethod.pending, (state) => {
        return ({
          ...state,
          updating: true,
        });
      })
      .addCase(createPaymentMethod.fulfilled, (state, action) => {
        const updatedState = updatePaymentMethods(state, action as PayloadAction<PaymentMethod[]>);

        return ({
          ...updatedState,
          updating: false,
        })
      })
      .addCase(createPaymentMethod.rejected, (state) => {

        return ({
          ...state,
          updating: false,
        })
      })
      .addCase(persistCachedPaymentMethod.pending, (state) => {
        return ({
          ...state,
          updating: true,
        });
      })
      .addCase(persistCachedPaymentMethod.fulfilled, (state, action) => {
        const updatedState = updatePaymentMethods(state, action as PayloadAction<PaymentMethod[]>);

        return ({
          ...updatedState,
          updating: false,
        })
      })
      .addCase(persistCachedPaymentMethod.rejected, (state) => {

        return ({
          ...state,
          updating: false,
        })
      })
      .addCase(removePaymentMethod.pending, (state) => {
        return ({
          ...state,
          updating: true,
        });
      })
      .addCase(removePaymentMethod.fulfilled, (state, action) => {
        // const updatedState = updatePaymentMethods(state, action as PayloadAction<PaymentMethod[]>);
        const { payload: paymentMethods } = action;
        const stripePaymentMethods = {...state.entities.stripePaymentMethods};
        delete stripePaymentMethods[paymentMethods[0]?.id];

        return ({
          ...state,
          entities: {
            ...state.entities,
            stripePaymentMethods,
          },
          updating: false,
        })
      })
      .addCase(removePaymentMethod.rejected, (state) => {

        return ({
          ...state,
          updating: false,
        })
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  stopLoading,
  updatePaymentMethod,
  setUpdating,
  stopUpdating,
  clear,
} = stripeSlice.actions;

export default stripeSlice.reducer;
