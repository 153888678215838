import React from 'react'
import { SvgIcon } from "@material-ui/core";

function CocktailIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M8 11.691a.41.41 0 01-.338-.177L3.287 5.17a.41.41 0 01.338-.643h8.75a.41.41 0 01.338.643l-4.375 6.344a.41.41 0 01-.338.177zM4.406 5.348L8 10.558l3.594-5.21H4.406z" />
      <path d="M8 15a.41.41 0 01-.41-.41V11.28a.41.41 0 01.82 0v3.309A.41.41 0 018 15z" />
      <path d="M11.063 15H4.936a.41.41 0 110-.82h6.125a.41.41 0 110 .82zM8 8.848a.41.41 0 01-.367-.593l3.5-7.028a.41.41 0 11.734.366l-3.5 7.027A.41.41 0 018 8.848z" />
      <path d="M10.583 7.945a.408.408 0 01-.227-.069l-.615-.41a.77.77 0 00-.857 0 1.589 1.589 0 01-1.768 0 .77.77 0 00-.857 0l-.615.41a.41.41 0 11-.455-.682l.615-.41a1.589 1.589 0 011.767 0 .77.77 0 00.858 0 1.589 1.589 0 011.767 0l.615.41a.41.41 0 01-.228.751z" />
    </SvgIcon>
  );
}

export default CocktailIcon