import React from 'react'
import { Typography } from '@material-ui/core';

import { Merchant } from 'bos_common/src/types/MerchantType';
import FollowMerchantCTA, { FollowMerchantCTAVariants } from '../FollowMerchant/FollowMerchantCTA';

const RafflePostCheckin = ({ merchant }: { merchant: Merchant }): JSX.Element => {
  return (
    <>
      {/* <Typography variant="subtitle1" lineHeight={1.2} sx={{ mt: 2 }} textAlign={"center"} gutterBottom>
        Access exclusive deals, updates and more from {merchant?.officialName} when you follow today.
      </Typography>
      <FollowMerchantCTA
        variant={FollowMerchantCTAVariants.expanded}
        merchant={merchant}
      /> */}
      <Typography variant="subtitle1" lineHeight={1.2} sx={{ mt: 2 }} textAlign={"center"} gutterBottom>
        You have successfully checked in at {merchant?.officialName}!
      </Typography>
    </>
  )
}

export default RafflePostCheckin
