import { createStyles, makeStyles, Theme, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { CardHighlightChip } from '../Classifieds/styledComponents';
import ReviewsRatingList from './ReviewsRatingList';
import ReviewsSummaryButtonGroups from './ReviewsSummaryButtonGroup';
import ReviewsSummaryHeader from './ReviewsSummaryHeader';

type ReviewsSummaryCardProps = {
    reviewsTotal: number,
    hasOneMarketReviews: boolean,
    hasYelpReviews: boolean,
    hasGoogleReviews: boolean,
    oneMarketRating: number,
    oneMarketTotal: number,
    googleRating: number,
    googleTotal: number,
    yelpRating: number,
    yelpTotal: number
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        reviewSummaryCardRoot: {
            '& .MuiToggleButtonGroup-root': {
                marginTop: 0,
            },
        }
    })
);

const ReviewsSummaryCard = (props: ReviewsSummaryCardProps) => {
    const { reviewsTotal,
        hasOneMarketReviews,
        hasGoogleReviews,
        hasYelpReviews,
        oneMarketTotal,
        oneMarketRating,
        googleTotal,
        googleRating,
        yelpTotal,
        yelpRating
     } = props;

    const classes = useStyles();

    const buttonGroupProps = {
        selectedReviewSource: "",
        handleReviewSourceChange: null,
        hasOneMarketReviews,
        hasGoogleReviews,
        hasYelpReviews,
        oneMarketTotal,
        oneMarketRating,
        googleTotal,
        googleRating,
        yelpTotal,
        yelpRating,
        isDisabled: true
    };

    return (
        <Typography component="div" className={classes.reviewSummaryCardRoot}>
            <ReviewsSummaryButtonGroups {...buttonGroupProps}/>
        </Typography>
    );
};

export default ReviewsSummaryCard;