import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthHeaders } from "bos_common/src";
import { deleteUserAccount } from "../../../services/userApiService";
import { AuthSliceStateEntities, AuthUIProperties } from "../../../types/AuthSlice";
import { RootState } from "../../store";
import { getToken } from "./authSelector";

export const authFetching = createAction('auth/setLoadingAuth');

export const authFetchSucceed = createAction<AuthSliceStateEntities>('auth/updateAuth');

export const authFetchFailed = createAction('auth/stopLoadingAuth');

export const fetchAuth = createAction<AuthSliceStateEntities>('USER_FETCH_REQUESTED');

export const hideSignUpDrawer = createAction('auth/hideSignUpDrawer');

export const showSignUpDrawer = createAction<AuthUIProperties>('auth/showSignUpDrawer');

export const deleteMyAccount = createAsyncThunk(
  'auth/deleteMyAccount',
  async (_, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const token = getToken(state);
    await deleteUserAccount({ headers: getAuthHeaders(token) });
  }
);
