import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core';
import classNames from 'classnames';

import { Merchant } from '../services/models';
import { getMerchantLogoPlaceholder, isEmptyOrNil } from '../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      borderRadius: theme.spacing(1),
    }
  })
)

interface MerchantAvatarProps {
  merchant: Merchant,
  customClass?: string
}
const MerchantAvatar = (props: MerchantAvatarProps): JSX.Element => {
  const classes = useStyles();

  const { merchant, customClass = '' } = props;
  const { officialName, logo } = merchant

  const handleImageError = (e: any) => {
    e.target.onerror = null;
    e.target.src = getMerchantLogoPlaceholder(merchant)
  }

  const avatarClasses = classNames([classes.root], {
    [customClass]: !isEmptyOrNil(customClass)
  })

  return (
    <img className={avatarClasses} alt={officialName} src={`${logo}_small`} onError={handleImageError} />
  )
}

export default MerchantAvatar