import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, Divider, FormControlLabel, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';

import axios from '../../bos_common/src/services/backendAxios';
import { UserContext } from '../../bos_common/src/context/UserContext';
import { ApplicationPreferences, User } from '../../services/models';
import useStyles from './styles';

const UserApplicationPreferences = () => {
	const classes = useStyles()
	const { user, token, setLoginState, setLoading } = useContext(UserContext)

	const getApplicationPrefData = (localUser: User | undefined) => {
		return {
			notificationSound: localUser?.applicationPreferences?.notificationSound || false
		}
	}

	const [preferences, setPreferences] = useState<ApplicationPreferences>(getApplicationPrefData(user))

	useEffect(() => {
		setPreferences(getApplicationPrefData(user))
	}, [user])

	const handleToggle = (key: keyof ApplicationPreferences) => (event: any) => {
		event.preventDefault();
		const currentValue = preferences[key];
		const updatedPrefs = preferences;
		updatedPrefs[key] = !currentValue;

		setPreferences(updatedPrefs);

		setLoading(true);
		axios.put<User>(
			'/users',
			{ id: user?.id, applicationPreferences: updatedPrefs },
			{ headers: { "Authorization": `JWT ${token}` } }
		).then((response) => {
			setLoading(false)
			if (response.status === 200) {
				setLoginState(response.data, token)
			}
		}).catch((err) => {
			console.log(err)
			setLoading(false)
		})
	}

	const toggleLabels = {
		notificationSound: 'Notification Sounds',
	}

	const RenderPreference = (prop: keyof ApplicationPreferences): React.ReactElement => {
		return (
			<ListItem button onClick={handleToggle(prop)} key={prop}>
				<FormControlLabel label={toggleLabels[prop]} control={<Checkbox color="primary"
            onChange={handleToggle(prop)}
            checked={preferences[prop]}
            inputProps={{ 'aria-labelledby': `${prop}_checkbox` }}
          />} />
			</ListItem>
		)
	}

	return (
		<div className={classes.root}>
			<Typography gutterBottom variant="subtitle1" component="div" className="sectionHeader" style={{ fontWeight: 600 }}>
				Application Preferences
			</Typography>
			<List>
				<Divider light variant="middle" />
				{RenderPreference('notificationSound')}
			</List>
		</div>
	)
}

export default UserApplicationPreferences