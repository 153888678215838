import React, { useContext, useEffect, useMemo } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import useAxios from 'axios-hooks';
import { useHistory } from 'react-router-dom';
import { filter, head, last, pathOr, pipe, pluck, prop, propEq, reverse, slice, sortBy, sum } from 'ramda';
import { Case, Default, Else, If, Switch, Then } from 'react-if';
import { rgba } from 'polished';

// src
import PageHeader from '../components/common/PageHeader';
import { UserContext } from '../bos_common/src/context/UserContext';
import { ColoredPaper } from '../bos_common/src/components/Papers';
import { POINTS_TRANSACTION_TYPE, UserTransactionHistoryType } from '../bos_common/src/types/UserTransactionHistoryType';
import OneMarketPointsCard from '../components/OneMarketPointsCard';
import { commaSeparatedValue, displayTime, getAuthHeaders, isEmptyOrNil } from '../utils';
import { headerCustomStyle } from '../styles/Common.style';
import SimpleLoader from '../bos_common/src/components/SimpleLoader';
import eventTrackingService from '../services/Tracking';
import { EVENT_ACTIONS, EVENT_CATEGORIES } from '../services/Tracking/events';
import GemMonoIcon from '../assets/icons/GemMonoIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .rowTitle': {
        fontWeight: 600,
      },

      '& .pointsContainer': {
        fontSize: "16px",
        fontWeight: 600,
        textAlign: "right",

        '&.earned, &.promotion, &.review': {
          color: theme.palette.secondary.main,
        },
        '&.redeemed, &.refunded': {
          color: theme.palette.secondary.light,
        },
      },
    },
    navWrapper: {
      ...headerCustomStyle(theme, theme.palette.secondary.main),
    },

    pointsBar: {
      width: "100%",
      maxWidth: "90%",

      '& .sectionSpacer': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      '& .pointsContainer': {
        fontSize: "16px",
        fontWeight: 600,

        '&.earned, &.promotion': {
          color: 'green'
        },
        '&.redeemed, &.refunded': {
          color: 'red'
        },
      },
      '& .pointsBar': {
        position: 'relative',
        width: '100%',
        borderRadius: 5,
        height: 8,
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        marginTop: theme.spacing(3.5),

        '& .filledBar': {
          position: 'absolute',
          top: 0,
          left: 0,
          height: 8,
          zIndex: 10,
          borderRadius: 5,
          backgroundColor: theme.palette.background.paper,
          maxWidth: '100%'
        },

        '& .pointsList': {
          position: 'absolute',
          top: "-20px",
          left: 5,
          zIndex: 15,
          width: `100%`,
          display: 'flex',
          justifyContent: 'space-between',

          '& .pointContainer': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            fontSize: 14,

            '& .dot': {
              width: 10,
              height: 10,
              backgroundColor: theme.palette.background.default,
              border: `1px solid`,
              borderColor: "transparent",
              borderRadius: '50%',
            },

            '& .value': {
              color: rgba(theme.palette.background.paper, 0.5),
              fontSize: "10px",
              fontWeight: 600,
              marginBottom: theme.spacing(1)
            },

            '&.isFilled': {
              fontWeight: 500,
              '& .dot': {
                backgroundColor: theme.palette.grey[400],
              },
            }
          }
        }
      }
    }
  })
);

const getTransactionFlow = (action: POINTS_TRANSACTION_TYPE) => {
  switch (action) {
    case POINTS_TRANSACTION_TYPE.earned:
    case POINTS_TRANSACTION_TYPE.promotion:
    case POINTS_TRANSACTION_TYPE.review:
      return '+';
    case POINTS_TRANSACTION_TYPE.redeemed:
    case POINTS_TRANSACTION_TYPE.refunded:
      return '-';
  }
}

const UserHistoricalEarningAndRedemptions = ({ userTransactionsList }: { userTransactionsList: UserTransactionHistoryType[] | undefined }) => {
  const history = useHistory()

  const handleClick = (item: UserTransactionHistoryType) => {
    const { orderId, action } = item
    if (!orderId || action === POINTS_TRANSACTION_TYPE.affiliateReward) return

    history.push({ pathname: `/order/${orderId}`, state: { from: '/1mpoints' } })
  }

  const showBonusPointsLabel = (item: UserTransactionHistoryType) => [POINTS_TRANSACTION_TYPE.promotion, POINTS_TRANSACTION_TYPE.review, POINTS_TRANSACTION_TYPE.affiliateReward, POINTS_TRANSACTION_TYPE.referralReward].includes(item.action)
  const showRefundLabel = (item: UserTransactionHistoryType) => [POINTS_TRANSACTION_TYPE.refunded].includes(item.action)

  return (
    <Box sx={{ marginTop: "-60px" }}>
      <Card>
        <CardContent sx={{ padding: 0, paddingBottom: '0 !important' }}>
          <If condition={isEmptyOrNil(userTransactionsList)}>
            <Then>
              <Box className="row" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
                <Typography variant="body1" >No Transactions Found</Typography>
              </Box>
            </Then>
            <Else>
              {userTransactionsList?.map((item: UserTransactionHistoryType, idx: number) => (
                <CardActionArea onClick={() => handleClick(item)} key={item.id}>
                  {idx !== 0 && <Divider />}
                  <Box className="row" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: `8px 16px` }}>
                    <div>
                      <Typography className="rowTitle" variant="subtitle1" component="div">
                        {pathOr('One Market', ['merchant', 'officialName'], item)}
                      </Typography>
                      <Typography variant="caption">{displayTime(item.createdAt)}</Typography>
                    </div>

                    <Typography variant="subtitle1" className={`pointsContainer ${item.action}`} component="div">
                      {item.points > 0 ? getTransactionFlow(item.action) : ''}{item.points}

                      <If condition={showBonusPointsLabel(item)}>
                        <Typography variant="caption" component="div">
                          <Switch>
                            <Case condition={item.action === POINTS_TRANSACTION_TYPE.review}>
                              Review Points
                            </Case>
                            <Case condition={item.action === POINTS_TRANSACTION_TYPE.affiliateReward}>
                              Affiliate Reward
                            </Case>
                            <Case condition={item.action === POINTS_TRANSACTION_TYPE.referralReward}>
                              Referral Bonus
                            </Case>
                            <Default>
                              Bonus Points
                            </Default>
                          </Switch>
                        </Typography>
                      </If>

                      <If condition={showRefundLabel(item)}>
                        <Typography variant="caption" component="div">Refund</Typography>
                      </If>
                    </Typography>
                  </Box>
                </CardActionArea>
              ))}
            </Else>
          </If>
        </CardContent>
      </Card>
    </Box>
  )
}

const getPointsRewardsList = (totalEarnedPoints: number, currentBalance: number) => {
  const rewardsList = []
  for (let i = 0; i <= totalEarnedPoints; i = i + 500) {
    rewardsList.push(i + 500)
  }
  const currentPointIndex = rewardsList.findIndex(i => i >= currentBalance)
  const after = rewardsList.length - currentPointIndex
  // Due to screen size limitation, we can only display 5-7 points easily
  // get median of rewards list from the current balance point
  return slice(currentPointIndex > 1 ? currentPointIndex - (after < 5 ? 5 - after : 1) : 0, currentPointIndex + (after > 6 ? 6 : after), rewardsList)
}

type OneMarketPointsBarProps = {
  points: number,
  userTransactionsList: UserTransactionHistoryType[] | undefined
}

const OneMarketPointsBar = ({ points: currentBalance, userTransactionsList }: OneMarketPointsBarProps) => {
  const calculateLifetimeEarnedPoints = useMemo(() => {
    return pipe(filter(propEq('action', 'earned')), pluck('points'), sum)(userTransactionsList)
  }, [userTransactionsList, currentBalance])

  const totalEarnedPoints = calculateLifetimeEarnedPoints < currentBalance ? currentBalance : calculateLifetimeEarnedPoints
  const rewardsList = getPointsRewardsList(totalEarnedPoints, currentBalance)
  const maxRewardPoints = last(rewardsList)
  const minRewardPoints = head(rewardsList)

  //const pointsForNextReward = currentBalance < maxRewardPoints ? Math.abs((currentBalance % 500) - 500) : 0
  const classes = useStyles()

  return (
    <div className={classes.pointsBar}>
      <Box display="grid" className="sectionSpacer">

        <div className="pointsBar sectionSpacer">
          <div className="filledBar" style={{ width: `${(currentBalance - minRewardPoints) / (maxRewardPoints - minRewardPoints) * 100}%` }} />
          <div className="pointsList ">
            {rewardsList.map((val: number) => (
              <div className={`pointContainer ${val <= currentBalance ? 'isFilled' : ''}`} key={val}>
                <span className="value">{commaSeparatedValue(val)}</span>
              </div>
            ))}
          </div>
        </div>
      </Box>
    </div>
  )
}

const OneMarketPointsPage = (): React.ReactElement => {
  const classes = useStyles()
  const { user, authenticating, loading: userLoading, token, refreshUser } = useContext(UserContext);

  const [{ data, loading }, fetchTransactionHistory] = useAxios<UserTransactionHistoryType[]>({
    url: '/users/transactionHistory',
    method: "GET",
    headers: getAuthHeaders(token)
  }, { manual: true })

  useEffect(() => {
    refreshUser()
    fetchTransactionHistory()
    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.ONE_MARKET_POINTS,
      action: EVENT_ACTIONS.VISITED_1M_POINTS,
      label: user?.displayName
    })
  }, [])

  const _userTransactionsList = data || []
  const userTransactionsList = reverse(sortBy(prop('createdAt'), _userTransactionsList))
  const points = user?.points || 0;

  return (
    <div className="container">
      <SimpleLoader loading={authenticating || loading} />
      <div className={classes.navWrapper}>
        <PageHeader title="1M Points" leftChild={<GemMonoIcon sx={{ width: 24, height: 24, p: 1 }} color="white" />} />
      </div>
      <OneMarketPointsCard
        points={points}
        transactionProgressbar={
          <OneMarketPointsBar points={points} userTransactionsList={userTransactionsList} />
        }
      />
      {loading || userLoading && <LinearProgress />}
      <ColoredPaper className={classes.root}>
        {user && (
          <CardContent>
            <UserHistoricalEarningAndRedemptions userTransactionsList={userTransactionsList} />
          </CardContent>
        )}
      </ColoredPaper>
    </div>
  );
};

export default OneMarketPointsPage;
