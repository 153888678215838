import React from "react";
import { createStyles, makeStyles, Stack, Theme, useMediaQuery, Typography, Paper, TypographyProps } from "@material-ui/core";
import classNames from "classnames";
import { If } from "react-if";
import { propOr } from "ramda";

import StarIcon from "@material-ui/icons/Star";

import { Merchant } from "../../bos_common/src/types/MerchantType";
import { getAbbreviatedNumber } from "../../bos_common/src/utils";
import { useEnableRatings } from "../../hooks/useMerchantRating";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'inline-flex',

      '& .MuiPaper-root.list-item-icon-container': {
        boxShadow: 'none',
      },
    },
  }))


interface MerchantRatingInfoProps {
  merchant?: Merchant;
  typographyProps?: TypographyProps<"span">;
}

export const renderMerchantRatingStr = (merchant?: Merchant): JSX.Element | null => {
  const isMerchantRatingVisible = useEnableRatings(merchant);

  if (merchant && isMerchantRatingVisible) {
    return (
      <Stack flexDirection="row" component="span" className="merchantRating" alignItems="center">
        <StarIcon color="primary" fontSize="small" />
        {merchant.rating?.overallRating?.toFixed(2)}&nbsp;
        ({getAbbreviatedNumber(propOr(0, "reviewCount", merchant.rating))})
      </Stack>
    );
  }

  return null;
}


export const MerchantRatingInfo = (props: Readonly<MerchantRatingInfoProps>): JSX.Element | null => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const { merchant, typographyProps = {} } = props;

  const paperClassNames = classNames({
    'list-item-icon-container': true,
  })

  const isMerchantRatingVisible = useEnableRatings(merchant);

  return (
    <If condition={isMerchantRatingVisible}>
      <div className={classes.root}>
        <Paper classes={{ root: paperClassNames }}>
          <Stack direction={'row'} alignItems='center'>
            <StarIcon color="primary" fontSize="small" />
            <Typography variant={(isMobile ? "caption" : "body1")} {...typographyProps}>{merchant?.rating?.overallRating?.toFixed(2)}</Typography>&nbsp;
            <Typography variant={(isMobile ? "caption" : "body1")} {...typographyProps}>({getAbbreviatedNumber(propOr(0, "reviewCount", merchant?.rating))})</Typography>
          </Stack>
        </Paper>
      </div>
    </If>
  );
}

export default MerchantRatingInfo;
