import React from 'react';
import { Typography } from '@material-ui/core';
import { pluralString } from 'bos_common/src/utils';

type ReviewsSummaryHeaderProps = {
    reviewsTotal: number,
};

const ReviewsSummaryHeader = (props: ReviewsSummaryHeaderProps) => {
    const { reviewsTotal } = props;
    return (
       <Typography variant="h6" component="div">{pluralString(reviewsTotal, 'review')}</Typography>
    );
};

export default ReviewsSummaryHeader;