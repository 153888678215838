import React, { useContext, useEffect, useState, useRef } from "react";
import { io } from "socket.io-client";
import { Alert, Snackbar } from "@material-ui/core";
import { Link } from "react-router-dom";

import { backendURL } from "../bos_common/src/services/backendAxios";
import { repeatAudio } from "../bos_common/src";
import { isOrderActive } from "../bos_common/src/services/orderUtils";
import { UserContext } from "../bos_common/src/context/UserContext";
import { AppType, ClientType, Message, SocketEvent } from "../bos_common/src/types";

import { Merchant, Order } from "../services/models";
import { AppContext } from "../context/AppContext";
import { isKioskDevice, isNotificationSoundEnabled } from "../utils";
import { VisitedMerchantContext } from "../context/VisitedMerchantContext/VisitedMerchantContext";

export type AlertColor = 'success' | 'info' | 'warning' | 'error';

const NotificationPage = (): React.ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const [bodyText, setBodyText] = useState<React.ReactElement | string>();
  const [alertType, setAlertType] = useState<AlertColor>('info');
  const { activeOrder, setActiveOrder } = useContext(AppContext);
  const { visitedMerchant } = useContext(VisitedMerchantContext);
  const { user } = useContext(UserContext);
  const userRef = useRef(user)

  const playNotificationSound = isNotificationSoundEnabled(userRef.current)

  const [orderUpdateAudio, setAudio] = React.useState<HTMLAudioElement | null>(null);

  useEffect(() => {
    const notifAudio = document.getElementById('app_order_update_audio') as HTMLAudioElement;
    setAudio(notifAudio);
  }, [])

  useEffect(() => {
    if (visitedMerchant && isKioskDevice()) {
      const topic = visitedMerchant.id
      const socket = io(backendURL, {
        reconnectionDelayMax: 10000,
        query: {
          merchantId: topic,
          appType: AppType.MARKETPLACE,
          clientType: ClientType.Web,
        }
      })

      socket.on(topic, (data: Message) => {
        const updatedMerchant = data.payload?.merchant as Merchant
        switch (data.event) {
          case SocketEvent.MERCHANT_STATUS_UPDATED:
            if (updatedMerchant) {
              window.location.reload();
            }
            break;
          default: break;
        }
      })
    }
  }, [visitedMerchant])

  useEffect(() => {
    if (activeOrder && isOrderActive(activeOrder)) {
      const socket = io(backendURL, {
        reconnectionDelayMax: 10000,
        query: {
          orderId: activeOrder.id,
          appType: AppType.MARKETPLACE,
          clientType: ClientType.Web,
        }
      })

      const topic = activeOrder.id
      socket.on(topic, (data: Message) => {
        const newOrderData = data.payload?.order as Order | undefined
        const link = (<Link color="primary" to={`/order/${topic}`}>order</Link>)
        switch (data.event) {
          case SocketEvent.ORDER_PREPARING:
            setBodyText((
              <>
                Your {link} is being prepared
              </>
            ))
            setAlertType('info');
            setOpen(true)
            break;
          case SocketEvent.ORDER_READY:
            // unsubscribe for this order
            socket.off(topic)
            setBodyText((
              <>
                Your {link} is ready
              </>
            ))
            setAlertType('success');
            setOpen(true)
            break;
          case SocketEvent.ORDER_UPDATED:
            // unsubscribe for this order
            socket.off(topic)
            setBodyText((
              <>
                Your {link} is updated
              </>
            ))
            setAlertType('success');
            setOpen(true)
            break;
          case SocketEvent.ORDER_REFUNDED:
            setBodyText((
              <>
                Some items in your {link} are cancelled
              </>
            ))
            setAlertType('info');
            setOpen(true)
            break;
          default: break;
        }
        if (newOrderData) {
          setActiveOrder(newOrderData)
          if (orderUpdateAudio && playNotificationSound) {
            repeatAudio(orderUpdateAudio, 1)
          }
        }
      })
    }
  }, [activeOrder?.id])

  useEffect(() => {
    userRef.current = user
  }, [user])

  const handleClose = () => {
    setOpen(false)
  }

  const renderBody = () => {
    return (
      <Alert
        onClose={handleClose}
        severity={alertType}>
        {bodyText}
      </Alert>
    )
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      transitionDuration={500}
      open={open}
      onClose={handleClose}>
      {renderBody()}
    </Snackbar>
  )
}

export default NotificationPage