import React, { useEffect } from 'react'
import { ThemeProvider, createMuiTheme } from '@material-ui/core'

import BackButton from '../components/common/BackButton'
import { TreasureIcon } from '../components/common/OneMarketIcons'
import PageHeader from '../components/common/PageHeader'
import useStyles from '../components/Lootbox/styles'
import LootboxFeaturedItems from '../components/Lootbox/LootboxFeaturedItems'
import LootboxMissions from '../components/Lootbox/LootboxMissions'
import LootboxVotes from '../components/Lootbox/LootboxVotes'
import LootboxThemeContextProvider from '../context/LootboxThemeContextProvider'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import { getUser } from '../redux/slice/auth/authSelector'
import { getStoresToVote, getStoreVoteCount } from '../redux/slice/vote/voteSelector'
import { fetchStoresToVote } from '../redux/slice/vote/voteAction'

const RenderMainComponent = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const storesToVote = useAppSelector(getStoresToVote);
  const voteStoreCount = useAppSelector(getStoreVoteCount);

  useEffect(() => {
    if (user) {
      dispatch(fetchStoresToVote());
    }
  }, [user]);
  

  return (
    <div className={classes.root}>
      <div>
        <LootboxFeaturedItems />
      </div>
      <div className='content-container'>
        <LootboxMissions />
        <LootboxVotes title="Vote Stores" merchants={storesToVote} subtitle="Wording pending" votable={true} totalVotes={10} countTable={voteStoreCount} />
      </div>
    </div>
  )
}

const LootboxTitle = () => {
  return (
    <>
      {TreasureIcon}&nbsp;Lootbox
    </>
  )
}

const LootboxPage = (): JSX.Element => {
  return (
    <LootboxThemeContextProvider>
    <div className="container">
      <PageHeader
        title={<LootboxTitle />}
        leftChild={<BackButton />}
      />
        <RenderMainComponent />
    </div>
    </LootboxThemeContextProvider>
  )
}

export default LootboxPage;