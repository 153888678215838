import React from 'react'
import { SvgIcon } from "@material-ui/core";

function FusionIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M10.333 3.333c-.85 0-1.646.232-2.333.63a4.637 4.637 0 00-2.333-.63 4.667 4.667 0 000 9.334c.85 0 1.646-.232 2.333-.63.687.398 1.482.63 2.333.63a4.666 4.666 0 100-9.334zM5.667 11.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5c.465 0 .908.093 1.314.258a4.651 4.651 0 000 6.484c-.406.165-.85.258-1.314.258zM8 10.601A3.488 3.488 0 016.833 8c0-1.033.453-1.96 1.167-2.602A3.488 3.488 0 019.167 8c0 1.033-.453 1.96-1.167 2.601zm2.333.899c-.465 0-.908-.093-1.313-.258A4.649 4.649 0 0010.333 8c0-1.26-.501-2.402-1.314-3.242.406-.165.85-.258 1.314-.258 1.93 0 3.5 1.57 3.5 3.5s-1.57 3.5-3.5 3.5z" />
    </SvgIcon>
  );
}

export default FusionIcon