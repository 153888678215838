import React from 'react'
import { SvgIcon } from "@material-ui/core";

function NoodlesIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M14.998 6.222A5.359 5.359 0 009.648 1 5.34 5.34 0 005.67 2.775a3.71 3.71 0 00-4.667 3.447H1v.957a6.96 6.96 0 001.225 3.957 6.996 6.996 0 002.89 2.423V15h5.77v-1.441a6.996 6.996 0 002.89-2.423A6.96 6.96 0 0015 7.179v-.957h-.002zm-.818.957c0 .23-.013.458-.038.684H1.858a6.252 6.252 0 01-.038-.684v-.137h12.36v.137zM2.005 8.684h11.99c-.07.28-.16.554-.268.821H2.273a6.111 6.111 0 01-.268-.821zm2.292-2.462h-.822c.056-.53.447-.96.957-1.076a5.34 5.34 0 00-.135 1.076zm7.404 0a2.06 2.06 0 00-2.054-1.927 2.06 2.06 0 00-2.053 1.927h-.828a2.888 2.888 0 012.881-2.754 2.888 2.888 0 012.882 2.754H11.7zm-.823 0h-2.46a1.24 1.24 0 011.23-1.107c.637 0 1.164.486 1.23 1.107zM9.648 1.82a4.538 4.538 0 014.53 4.402h-.828a3.71 3.71 0 00-3.703-3.575 3.71 3.71 0 00-3.702 3.575h-.828a4.538 4.538 0 014.53-4.402zM4.704 3.468c.14 0 .28.01.417.03-.16.252-.3.519-.416.797a2.06 2.06 0 00-2.054 1.927h-.829a2.888 2.888 0 012.882-2.754zm5.615 9.44l-.256.104v1.168H5.936v-1.168l-.256-.104a6.177 6.177 0 01-3-2.582h10.64a6.177 6.177 0 01-3 2.582z" />
    </SvgIcon>
  );
}

export default NoodlesIcon