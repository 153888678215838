import { Theme, makeStyles, createStyles } from '@material-ui/core'

const styles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			'& .sectionHeader': {
				marginTop: theme.spacing(1),
			},
			'& .inputRow': {
				display: 'flex',
				marginBottom: theme.spacing(2),
			},
			'& .sectionSpacer': {
				marginTop: theme.spacing(2),
				marginBottom: theme.spacing(2),
			},
		},
	})
);

export default styles;