import React from 'react'
import { SvgIcon } from "@material-ui/core";

function BurritoIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M8.384 1.196l-.143.083a3.978 3.978 0 00-1.5-.268c-1.692.02-3.054 1.031-3.899 1.876C1.31 4.422.994 5.843 1 6.765c.004.668.173 1.181.296 1.467l-.079.136 8.7 6.62.17-.233c.843-.145 1.932-.808 2.902-1.779.97-.97 1.633-2.06 1.777-2.903L15 9.902 8.384 1.196zM1.951 7.292a3.083 3.083 0 01-.05-.583c.01-1.075.542-2.147 1.579-3.185 1.04-1.04 2.123-1.582 3.221-1.61.217-.007.414.01.585.036a6.544 6.544 0 00-.587.572c-.194-.019-.389.032-.54.071-.074.02-.144.038-.204.048-.027.003-.106-.007-.158-.013-.204-.024-.484-.058-.763.086-.22.113-.342.307-.441.464a.96.96 0 01-.136.186 1.06 1.06 0 01-.18.06c-.183.052-.412.116-.597.301-.186.186-.25.414-.3.597-.018.061-.044.154-.06.179a.938.938 0 01-.188.137c-.156.099-.35.222-.464.441-.143.28-.11.559-.085.763.006.053.016.132.013.156-.01.062-.029.132-.048.207-.039.15-.09.342-.072.536-.204.19-.38.379-.525.551zm4.008-3.75c-.208.37-.383.786-.49 1.247a4.442 4.442 0 00-.095.567 4.617 4.617 0 00-1.877.62c0-.085-.01-.166-.02-.241a.99.99 0 01-.01-.234c.027-.026.098-.07.146-.101.133-.084.299-.188.425-.355.118-.156.168-.335.209-.48a1 1 0 01.07-.203c.031-.022.134-.05.203-.07.144-.04.324-.09.48-.209.167-.126.271-.292.355-.425.03-.048.075-.118.1-.147.046-.011.167.003.234.012.084.01.175.02.27.019zm3.747 10.155L2.42 8.15c.426-.568 1.447-1.697 2.956-1.888.09.898.444 1.818 1.06 2.749.898 1.355 2.038 2.165 3.39 2.407.24.043.477.066.71.073-.163.94-.565 1.75-.829 2.205zm2.646-1.358a7.003 7.003 0 01-1.538 1.189 7.27 7.27 0 00.64-2.086 7.233 7.233 0 002.085-.641 7.008 7.008 0 01-1.188 1.538zm-2.368-1.807c-1.1-.197-2.041-.875-2.797-2.017-.815-1.23-1.098-2.413-.843-3.513.303-1.308 1.3-2.208 1.823-2.603l5.542 7.293c-.685.4-2.183 1.116-3.725.84z" />
    </SvgIcon>
  );
}

export default BurritoIcon