import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { If } from "react-if";
import { darken } from "polished";

import { isEmptyOrNil } from "../../utils";
import ReviewsRatingList from "./ReviewsRatingList";
import ReviewsOneMarketStats from "./ReviewsOneMarketStats";
import eventTrackingService from "../../services/Tracking";
import { useAppSelector } from "../../redux/hooks";
import { getUser } from "../../redux/slice/auth/authSelector";
import { Merchant } from "bos_common/src/types/MerchantType";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      cursor: "pointer",
      transition: "all 200ms linear",
      position: "relative",
      padding: theme.spacing(2),
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',

      "&:hover": {
        background: darken(0.02, theme.palette.background.paper),
      },

      "& .cardHeader": {
        display: "grid",
        gridTemplateColumns: "auto 1fr auto",
        gridGap: theme.spacing(2),

        "& .userImage": {
          width: theme.spacing(5),
          height: theme.spacing(5),
          borderRadius: "100%",
          objectFit: "cover",
        },
      },

      "& .rating-wrapper": {
        display: "flex",
        alignItems: "center",
        color: theme.palette.secondary.light,
        "& span": {
          marginLeft: theme.spacing(1),
        },
      },

      "& .cardDescription": {
        marginTop: theme.spacing(1),
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
        height: '65px',
      },

      "& .userDetails": {
        "& .logo": {
          maxWidth: theme.spacing(5),
          position: "absolute",
          top: theme.spacing(2),
          right: theme.spacing(1),
        }
      }
    },
  })
);

type ReviewsCardProps = {
  name: string,
  profileImage?: string,
  rating: number,
  createdAt: string,
  reviewText: string,
  reviewLogo: string,
  reviewLink: string,
  portion?: number,
  taste?: number,
  value?: number,
};

const ReviewsCard = (props: ReviewsCardProps) => {
  const { name, profileImage, rating, createdAt, reviewText, reviewLogo, reviewLink, portion = 0, taste = 0, value = 0 } = props;
  const classes = useStyles();

  const renderOneMarketStats = () => {
    if (portion === 0 || taste === 0 || value === 0) {
      return;
    }
    return (
      <ReviewsOneMarketStats taste={taste} portion={portion} value={value} />
    );
  };

  const handleLinkOpen = () => {
    const w = window.open(reviewLink, "_blank");
    if (w) {
      w.focus();
    }
  };

  return (
    <div className={`review-card-wrapper ${classes.root}`} onClick={handleLinkOpen}>
      <div className="cardHeader">
        <If condition={!isEmptyOrNil(profileImage)}>
          <img
            className="userImage"
            src={profileImage}
            alt="img"
          />
        </If>

        <div className="userDetails">
          <Typography variant="subtitle2">
            {name}
          </Typography>

          <Typography variant="subtitle2" component="div" className="rating-wrapper">
            <ReviewsRatingList rating={rating}/>
            <span>{createdAt}</span>
          </Typography>

          <img className="logo" src={reviewLogo} />
        </div>
      </div>
      <div>
        <Typography variant="subtitle2" className="cardDescription">{reviewText}</Typography>
      </div>
    </div>
  );
};

export default ReviewsCard;
