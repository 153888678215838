import React from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

import StoryHeader from "./StoryHeader";
import { Merchant } from "../../services/models";
import { getMerchantLogoPlaceholder } from "../../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: theme.spacing(33),
      position: "relative",
      zIndex: 0,
      [theme.breakpoints.down("sm")]: {
        minHeight: theme.spacing(50),
      },

      "& .heroImage": {
        width: "100%",
        height: "400px",
        position: "absolute",
        top: 0,
        left: 0,
        objectFit: "cover",
        zIndex: -1,
      },

      "& .overlay": {
        width: "100%",
        height: theme.spacing(31.25),
        background:
          "linear-gradient(180deg, #202020 0%, rgba(32, 32, 32, 0) 100%)",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: -1,
      },

      "& .storyHeaderDetails": {
        padding: theme.spacing(0, 2),
        maxWidth: "1280px",
        width: "100%",
        margin: "auto",
        boxSizing: "border-box",
      },

      "& .heroTitle": {
        fontWeight: 700,
        color: theme.palette.background.paper,
      },
    },
  })
);

type StoryHeroSectionProps = {
  title: string;
  heroImage: string;
  merchant: Merchant;
};

const StoryHeroSection = (props: StoryHeroSectionProps) => {
  const { title, heroImage="", merchant } = props;

  const classes = useStyles();

  const handleImageError = (e: any) => {
    e.target.onerror = null;
    e.target.src = getMerchantLogoPlaceholder(merchant);
  }

  return (
    <div className={classes.root}>
      <StoryHeader />

      <img src={heroImage} className="heroImage" onError={handleImageError} />
      <div className="overlay" />
      <div className="storyHeaderDetails">
        <Typography variant="h5" className="heroTitle">
          {title}
        </Typography>
      </div>
    </div>
  );
};

export default StoryHeroSection;
