import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme, Typography, TypographyProps, useTheme } from '@material-ui/core';
import { AlarmOn, Schedule } from '@material-ui/icons';
import useAxios from 'axios-hooks';
import { If, Then, Else } from 'react-if';

import { WaitTime } from '../../bos_common/src/types/WaitTimeType';
import { Merchant, Order } from '../../services/models';
import { pluralString } from '../../utils';
import { useFunctionOnInterval } from '../../bos_common/src';
import { WAIT_TIME_ON_ORDER_STATUS_INTERVAL } from '../../config/constants';
import { format } from 'date-fns';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTypography-root': {
        display: 'flex',
        alignItems: 'center',
        lineHeight: '1.2',
        marginTop: "2px",
      },
      '& .wait-time-icon': {
        marginRight: "5px",
      }
    },
  }),
);

type RenderWaitTimeProps = {
  merchant?: Merchant,
  order?: Order,
  withIcon?: boolean,
  short?: boolean,
  typographyProps?: TypographyProps<"div">,
}
const RenderWaitTime = ({ merchant, order, withIcon = true, short = false, typographyProps = {} }: RenderWaitTimeProps): React.ReactElement => {
  const classes = useStyles()
  const theme = useTheme()
  const [waitColor, setWaitColor] = useState<string>()
  const url = order
    ? `/orders/${order.id}/waitTime`
    : merchant
      ? `/merchants/${merchant.id}/waitTime`
      : undefined;

  const [{ data: waitTime, loading }, executeGet] = useAxios<WaitTime>(
    { url, method: 'get' },
    { manual: true }
  );

  const timerHandler = useFunctionOnInterval(executeGet, WAIT_TIME_ON_ORDER_STATUS_INTERVAL, true)

  useEffect(() => {
    if (waitTime && waitTime.readyTime) {
      if (timerHandler.current) {
        clearTimeout(timerHandler.current)
      }
    }

    if (waitTime) {
      if (waitTime.maxMinutes < 15) {
        setWaitColor(theme.palette.success.main)
      } else if (waitTime.maxMinutes < 45) {
        setWaitColor(theme.palette.warning.main)
      } else {
        setWaitColor(theme.palette.error.main)
      }
    }

  }, [waitTime])

  const getWaitTimeString = (minMinutes: number | undefined, maxMinutes: number | undefined) => {
    if (minMinutes == null || maxMinutes == null) {
      return 'n/a'
    } else {
      return (<span style={{ color: waitColor }}>{minMinutes}-{maxMinutes} min</span>)
    }
  }

  return (
    <div className={classes.root}>
      <If condition={loading && !waitTime}>
        <Then>
          <Typography variant="subtitle1" color="textPrimary" component="div" {...typographyProps}>
            {withIcon && <Schedule className='wait-time-icon' color='primary' />}
            Estimating&nbsp;...
          </Typography>
        </Then>
        <Else>
          <If condition={!!waitTime}>
            <Then>
              <Typography variant="subtitle1" color="textPrimary" component="div" {...typographyProps}>
                {
                  waitTime?.readyTime
                    ? (<>
                      {withIcon && <AlarmOn className='wait-time-icon' color='primary' />}
                      {format(new Date(waitTime?.readyTime), 'MM/dd/yy hh:mm a')}
                    </>)
                    : (waitTime?.priorOrdersInQueue && !short)
                      ? (waitTime.priorOrdersInQueue > 0) && (<>
                          {withIcon && <Schedule className='wait-time-icon' color='primary' />}
                          {pluralString(waitTime.priorOrdersInQueue, 'order')} / {pluralString(waitTime.priorItemsInQueue, 'item')} in queue,
                          est.&nbsp;{getWaitTimeString(waitTime?.minMinutes, waitTime?.maxMinutes)}
                        </>)
                      : (<>
                        {withIcon && <Schedule color="primary" className='wait-time-icon' />}
                        wait time est.&nbsp;{getWaitTimeString(waitTime?.minMinutes, waitTime?.maxMinutes)}
                      </>)
                }
              </Typography>
            </Then>
          </If>
        </Else>
      </If >
    </div>
  )
}

export default RenderWaitTime;