import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { FullscreenPaper } from 'bos_common/src';
import { isPlatformChatEnabled } from 'bos_common/src/services/featureFlagUtils';
import PlatformChatContextProvider from 'bos_common/src/components/PlatformChat/context/PlatformChatContextProvider';
import { generateUserName } from 'bos_common/src/components/PlatformChat/utils';

import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { getCurrentMerchant, getMerchantLoadingState } from '../redux/slice/merchant/merchantSelector';

import { fetchMerchant } from '../redux/slice/merchant/action';
import { isKioskDevice, isMerchantServicesType } from '../utils';
import config from '../config/config';
import { AppContext } from '../context/AppContext';

import NoneExistPage from './NoneExistPage';
import UserChat from '../components/UserChat';
import DocumentMetaTags from '../components/common/DocumentMetaTags';

interface IMerchantPageParams {
  merchantUserName: string,
}

const PlatformChatPage = () => {
  const history = useHistory();
  const location = useLocation<{ from: { pathname: string } }>();

  const { merchantUserName } = useParams<IMerchantPageParams>();

  const { featureFlags } = useContext(AppContext);

  const reduxDispatch = useAppDispatch();
  const merchant = useAppSelector(getCurrentMerchant);
  const loading = useAppSelector(getMerchantLoadingState);

  const [hasFetched, setHasFetched] = useState<boolean>(false);

  useEffect(() => {
    reduxDispatch(fetchMerchant({
      username: merchantUserName
    }));
    setHasFetched(true);
  }, [merchantUserName]);

  const isKioskModeEnabled = isKioskDevice()

  const handleBack = (): void => {
    location.state?.from
      ? history.goBack()
      : history.push(`/`);
  }

  if (isKioskModeEnabled || (merchant && !isMerchantServicesType(merchant))) {
    handleBack();
  }

  if (featureFlags && !isPlatformChatEnabled(featureFlags)) {
    return (<Redirect to={{ pathname: '/', state: location.state }} />);
  }

  if (!merchant && !loading && hasFetched) {
    return <NoneExistPage title={<>Merchant&nbsp;<b>{merchantUserName}</b>&nbsp;not found</>} />
  }

  const sourceInfo = {
    device: config.appConfig.deviceType,
    app: config.appConfig.appType,
  }

  const meta = useMemo(() => ({
    title: `${merchant ? `${merchant.officialName} Chat` : 'One Market'}`,
  }), [merchant])

  return (
    <DocumentMetaTags meta={meta}>
      <div className="container">
        <FullscreenPaper>
          <PlatformChatContextProvider
            merchant={merchant}
            apiKey={config.appConfig.streamChatId}
            sourceInfo={sourceInfo}
            username={generateUserName()}
            chatStarted
          >
            <UserChat />
          </PlatformChatContextProvider>
        </FullscreenPaper>
      </div>
    </DocumentMetaTags>
  )
}

export default PlatformChatPage;