import React from 'react'
import { SvgIcon } from "@material-ui/core";

function OneMarketIcon(props: any) {
  return (
    <SvgIcon color="primary" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M15.6107 8.01144C15.9603 8.01144 16.1296 8.44835 15.8838 8.70936L8.97845 15.8814C8.73261 16.1367 8.31195 15.9552 8.30649 15.5977L8.27917 8.68099C8.27917 8.31217 8.56872 8.01144 8.92382 8.01144H15.6107Z" />
      <path d="M7.69593 0.40233V11.4501C7.69593 11.8189 7.40639 12.1196 7.04583 12.1196L4.66938 12.1083C4.16677 12.1083 3.76251 11.6827 3.75704 11.1607L3.74612 8.7151C3.74612 8.3179 3.43472 8.00014 3.0523 7.99447L0.391776 7.98312C0.0421376 7.98312 -0.132681 7.5462 0.118621 7.28519L7.02944 0.118618C7.27528 -0.136723 7.69593 0.0448524 7.69593 0.40233Z" />
    </SvgIcon>
  );
}

export default OneMarketIcon