import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../bos_common/src/services/backendAxios";
import { getAuthHeaders } from "../../../utils";

export const fetchUserPromotionEntries = createAsyncThunk(
  'marketPlace/fetchUserPromotionEntries',
  async (token: string) => {
    const response = await axios.get('/users/promotions', { headers: getAuthHeaders(token) })
    return response.data;
  }
);