import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Merchandise, MerchandiseCategory } from "../../../bos_common/src/types/MerchandiseType";
import { Merchant } from "../../../bos_common/src/types/MerchantType";
import { getMerchandises, getMerchandisesByUsername } from "../../../services/merchandisesApiService";

type SwapCategoriesType = {
  oldCategoryId: number;
  newCategoryId: number;
}

export const merchandiseFetching = createAction('merchandise/setLoadingAuth');

export const merchandiseFetchSucceed = createAction<Merchandise[]>('merchandise/updateMerchandise');

export const merchandiseFetchCategoriesSucceed = createAction<MerchandiseCategory>('merchandise/updateMerchandiseCategories');

export const deleteMerchandiseCategorySucceed = createAction<number>('merchandise/deleteCategory');

export const deleteMerchandiseItemSucceed = createAction<string>('merchandise/deleteMerchandise');

export const swapMerchandiseCategoriesSucceeded = createAction<SwapCategoriesType>('merchandise/swapMerchandiseCategory');

export const updateMerchandiseCategoriesSucceed = createAction<Merchandise>('merchandise/updateMerchandiseCategory');

export const updatedCategoriesList = createAction<MerchandiseCategory[]>('merchandise/updatedCategoriesList');

export const addFeaturedCategoriesSucceed = createAction<Merchandise>('merchandise/submitFeaturedCategory');

export const merchandiseFetchFailed = createAction('merchandise/stopLoadingMerchandise');

export const createMerchandiseSucceeded = createAction<Merchandise>('merchandise/addMerchandise');

export const fetchMerchandises = createAsyncThunk(
  'merchandise/fetchMerchandises',
  async (merchant: Partial<Merchant>) => {
    if (!merchant) {
      throw new Error('merchant is null');
    }

    let response;
    
    if (merchant?.id) {
      response = await getMerchandises(merchant?.id);
    } 
    else if (merchant?.username) {
      response = await getMerchandisesByUsername(merchant?.username);
    }

    return response?.data;
  }
);
