import React from 'react';
import { Grid, makeStyles, createStyles } from '@material-ui/core';
import LazyLoad from 'react-lazyload';

import { Merchant } from '../../services/models';
import MerchantCard from '../MerchantCard';
import MerchantCardPlaceholder from '../MerchantCardPlaceholder';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .lazyload-wrapper': {
        height: '100%'
      }
    }
  })
)
export interface StoreListProps {
  merchants: Merchant[];
  capToMD?: boolean;    // size capped to medium screens
}

export const StoresListDetailed = (props: Readonly<StoreListProps>): JSX.Element => {
  const { merchants, capToMD = false } = props;
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {
          merchants?.map((merchant) => {
            return (
              <Grid item xs={12} sm={6} md={capToMD ? 6 : 4} lg={capToMD ? 6 : 3} key={merchant.id}>
                <LazyLoad offset={250} height={250} placeholder={<MerchantCardPlaceholder />}>
                  <MerchantCard merchant={merchant} />
                </LazyLoad>
              </Grid>
            )
          })
        }
      </Grid>
    </div>
  );
}

export default StoresListDetailed;
