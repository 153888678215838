import React from "react";
import { MerchandiseApiResponseType, Merchant } from "../../services/models";

// TODO: Move this models or type definition into models or types folder
export interface VisitedMerchantCache {
  merchant: Merchant,
  lastVisitedAt: Date,
}

export type VisitedMerchantDictionary = {[key: string]: VisitedMerchantCache}

export interface VisitedMerchantContextState {
  visitedMerchant?: Merchant;
  setVisitedMerchant: (merchant: Merchant) => void,
  visitedMenu?: MerchandiseApiResponseType;
  setVisitedMenu: (menu: MerchandiseApiResponseType) => void,
  isMerchantOrderingAvailable: boolean,
  visitedMerchantList: VisitedMerchantDictionary,
}

export const VisitedMerchantContext = React.createContext<VisitedMerchantContextState>({
  visitedMerchant: undefined,
  setVisitedMerchant: (_: Merchant) => ({}),
  visitedMenu: undefined,
  setVisitedMenu: (_: MerchandiseApiResponseType) => ({}),
  isMerchantOrderingAvailable: true,
  visitedMerchantList: {},
})
