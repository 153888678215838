import React from "react";
import { Alert, Button } from "@material-ui/core";
import Star from "@material-ui/icons/Star";
import { Merchant, Order } from "../../services/models";
import { lineItemWithoutCarryoutBag } from "../../utils";
import GemIcon from "../../assets/icons/GemIcon";
import OneMarketPointString from "../common/OneMarketPointString";

export interface OrderReviewCTAProps {
  order?: Order;
  merchant?: Merchant;
  onClickLink: () => void;
}

export default function OrderReviewCTA(props: Readonly<OrderReviewCTAProps>): React.ReactElement | null {
  const { order, merchant, onClickLink } = props;

  if (!order || !merchant) {
    return null;
  }

  const rewards = order.lineItems.filter(item => lineItemWithoutCarryoutBag(item, merchant)).length * 50;

  return (
    <Alert
      severity="error"
      iconMapping={{error: <Star fontSize={"large"} color="primary"/>}}
      elevation={3}
      sx={{display: 'flex'}}>
      Help others discover <strong>{merchant.officialName}</strong> and earn {rewards}<GemIcon sx={{height: 12, width: 16}} /><OneMarketPointString />.
      <br/>
      <Button variant="outlined" color="primary" size="small" sx={{mt: 1}} onClick={onClickLink}>
          Write a review
      </Button>
    </Alert>
  )
}