import React from "react";
import { darken, MuiThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core";

const MUIThemeProvider = (props: {
  children: React.ReactNode;
}): React.ReactElement => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#E34D28",
        light: `rgba(138, 198, 208, 0.3)`,
      },
      secondary: {
        main: "#FF8E25",
        light: "#9CA5A8",
      },
      info: {
        main: "#FF8E25",
        light: "#364245",
      },
      background: {
        paper: "#fff",
        default: "#f5faff",
      },
      divider: "rgba(0, 0, 0, 0.1)",
      action: {
        disabledBackground: "#e4e4e4",
      },
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.07)",
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'unset',
          }
        },
        variants: [
          {
            props: { variant: 'text' },
            style: {
              boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.07)",
            }
          }
        ]
      },
      MuiFab: {
        variants: [
          {
            props: { variant: "circular" },
            style: {
              background: "#fff",
              border: "1px solid #E34D28",
              boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.07)',

              "&.Mui-disabled": {
                background: "#9CA5A8",
                border: "1px solid #9CA5A8",
                opacity: 0.4,

                "& .MuiSvgIcon-root": {
                  fill: "#fff",
                },
              },

              "&:hover": {
                borderColor: darken("#E34D28", 0.2),
                background: "transparent",

                "& .MuiSvgIcon-root": {
                  fill: darken("#E34D28", 0.2),
                },
              },

              "& .MuiSvgIcon-root": {
                fill: "#E34D28",
              },
            },
          },
        ],
      },
    },
    typography: {
      "fontFamily": `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    }
  });

  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>;
};

export default MUIThemeProvider;
