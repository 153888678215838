import { createStyles, makeStyles, Theme, Link } from "@material-ui/core";
import classNames from "classnames";
import React from "react";

// src
import { useAppDispatch } from "../../redux/hooks";
import { setShowRewardsEducationDrawer } from "../../redux/slice/merchant/action";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      color: theme.palette.secondary.main,

      '&:hover': {
        textDecoration: "none",
      }
    },
  })
);

const OneMarketPointString = ({ text, className }: { text?: string, className?: string }): React.ReactElement => {
  const classes = useStyles();
  const reduxDispatch = useAppDispatch()

  const handlePointsClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    reduxDispatch(setShowRewardsEducationDrawer(true))
  }

  return (
    <Link
      onClick={handlePointsClick}
      className={classNames({ [classes.root]: true, [`${className}`]: Boolean(className) })}
    >
      {text ? text : "1M points"}
    </Link>
  );
};

export default OneMarketPointString;
