import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

import PageHeader from "../components/common/PageHeader";
import InstagramFeed from "../components/ContentFeed/InstagramFeed";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .feedsContainer': {
        margin: theme.spacing(2),
      }
    },
  })
);

const MerchantFeedPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PageHeader theme="dark" title={"Discovery"} />

      <div className="feedsContainer">
        <InstagramFeed />
      </div>
    </div>
  );
};

export default MerchantFeedPage;
