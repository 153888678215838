import localforage from "localforage";

class Storage {
  save = async <T = unknown>(key: string, value: T): Promise<T> => {
    await localforage.ready();
    return await localforage.setItem<T>(key, value);
  };

  get = async <T = unknown>(key: string): Promise<T | null> => {
    await localforage.ready();
    return await localforage.getItem<T>(key);
  };

  remove = async (key: string): Promise<void> => {
    await localforage.ready();
    return await localforage.removeItem(key);
  };
}

export default Storage;
