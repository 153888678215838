import React from "react";
import { SvgIcon } from "@material-ui/core";

function GemIcon(props: any) {
  return (
    <SvgIcon
      sx={{ width: "41px", height: "41px" }}
      viewBox="0 0 41 41"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_17209_73570)">
        <path d="M29.9009 3.17578H11.1225C10.6861 3.17578 10.2828 3.40859 10.0646 3.78656L0.675371 20.0491C0.457168 20.4271 0.457168 20.8927 0.675371 21.2707L10.0646 37.5333C10.2828 37.9113 10.6861 38.1441 11.1225 38.1441H29.9009C30.3373 38.1441 30.7406 37.9113 30.9588 37.5333L40.348 21.2707C40.5662 20.8927 40.5662 20.4271 40.348 20.0491L30.9588 3.78656C30.7406 3.40859 30.3373 3.17578 29.9009 3.17578Z" fill="#FFD45D" />
        <path d="M40.5109 20.6602H32.5048L26.5078 31.0471L30.5109 37.9806C30.6936 37.8752 30.849 37.7225 30.958 37.5335L40.3473 21.2709C40.4564 21.082 40.5109 20.871 40.5109 20.6602Z" fill="#FF6500" />
        <path d="M26.5086 31.0469H14.5148L10.5117 37.9804C10.6944 38.0859 10.9043 38.1441 11.1225 38.1441H29.9009C30.1191 38.1441 30.3291 38.0859 30.5117 37.9804L26.5086 31.0469Z" fill="#FF9416" />
        <path d="M14.5148 31.0471L8.51789 20.6602H0.511719C0.511719 20.871 0.56625 21.082 0.675391 21.2709L10.0646 37.5336C10.1738 37.7226 10.3291 37.8752 10.5117 37.9807L14.5148 31.0471Z" fill="#FFC13F" />
        <path d="M14.5148 10.2734L10.5117 3.33984C10.3291 3.44531 10.1737 3.59797 10.0646 3.78695L0.675391 20.0497C0.56625 20.2386 0.511719 20.4495 0.511719 20.6604H8.51789L14.5148 10.2734Z" fill="#FFD45D" />
        <path d="M14.5148 10.2731H26.5086L30.5117 3.33945C30.3291 3.23398 30.1191 3.17578 29.9009 3.17578H11.1225C10.9043 3.17578 10.6944 3.23398 10.5117 3.33945L14.5148 10.2731Z" fill="#FFC13F" />
        <path d="M26.5078 10.2734L32.5048 20.6604H40.5109C40.5109 20.4495 40.4564 20.2386 40.3473 20.0497L30.958 3.78695C30.849 3.59797 30.6936 3.44531 30.5109 3.33984L26.5078 10.2734Z" fill="#FF9416" />
        <path d="M26.5103 10.2734H14.5164L8.51953 20.6604L14.5164 31.0473H26.5103L32.5072 20.6604L26.5103 10.2734Z" fill="#FF7B05" />
        <path d="M14.7303 9.54487L15.4948 8.97385C15.6688 8.84393 15.899 9.03799 15.8003 9.23143L15.3667 10.0814C15.3204 10.1723 15.3478 10.2833 15.4311 10.3421L16.2104 10.8928C16.3877 11.0181 16.2743 11.297 16.0598 11.2628L15.1174 11.1131C15.0167 11.0971 14.9195 11.1574 14.8893 11.2549L14.6064 12.1662C14.542 12.3735 14.2417 12.3518 14.2079 12.1374L14.059 11.1949C14.0431 11.0942 13.9557 11.0204 13.8537 11.0217L12.8996 11.0343C12.6825 11.0372 12.6103 10.7449 12.8038 10.6464L13.6542 10.2136C13.7451 10.1674 13.7882 10.0614 13.7554 9.96487L13.4486 9.06135C13.3787 8.85573 13.6344 8.69682 13.788 8.85042L14.4623 9.52549C14.5345 9.5976 14.6486 9.60589 14.7303 9.54487Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_17209_73570">
          <rect width="40" height="40" fill="white" transform="translate(0.511719 0.660156)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default GemIcon;
