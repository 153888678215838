import { BaseIncrement } from "./BaseIncrement";

export enum POINTS_TRANSACTION_TYPE {
  redeemed = 'redeemed',
  earned = 'earned',
  refunded = 'refunded',
  promotion = 'promotion',
  review = 'review',
  expired = 'expired',
  affiliateReward = 'affiliateReward',
  referralReward = 'referralReward',
}

export interface UserTransactionHistoryType extends BaseIncrement {
  orderId?: string;
  userId: string;
  merchantId: string;
  merchant: {
    officialName: string,
    username: string
  }
  points: number;
  balance: number;
  action: POINTS_TRANSACTION_TYPE;
}
