import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme, Link, Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { If } from 'react-if';
import { forceCheck } from 'react-lazyload';

import { FullscreenPaper } from 'bos_common/src';
import { Merchant } from 'bos_common/src/types/MerchantType';
import { StructuredAddress } from 'bos_common/src/types/AddressType';
import { EmbededMap } from 'bos_common/src/components/EmbededMap';
import SimpleLoader from '../bos_common/src/components/SimpleLoader';

import PageHeader from '../components/common/PageHeader';
import BackButton from '../components/common/BackButton';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { searchMerchantsData, searchQueryUpdated } from '../redux/slice/merchant/action';
import { getFilteredMerchants, getMerchantLoadingState, getMerchantSearchQuery } from '../redux/slice/merchant/merchantSelector';
import StoreSearchDrawer from '../components/Stores/StoreSearchDrawer';
import { isProduction } from '../utils';


const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '& .fab': {
      zIndex: 9999,
    },
  },
  followLinkWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),

    '& .action-icon': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const MapSearchPageHeader = ({title}: {title: string}): JSX.Element => {
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  }

  return (
    <PageHeader
      title={title}
      leftChild={<BackButton onBack={handleBack} />}
    />
  );
}

interface MapSearchPageProps {
  liteView?: boolean;
  zipcodes?: string[];
  onBack?: () => void;
}

export const MapSearchPage = ({ liteView = false, zipcodes = [], onBack }: MapSearchPageProps): JSX.Element => {
  const classes = useStyles();
  const claimed = (isProduction() && !liteView) ? true : undefined;
  const [storeLocations, setStoreLocations] = React.useState<StructuredAddress[]>();
  const dispatch = useAppDispatch();
  const stores: Merchant[] = useAppSelector(getFilteredMerchants);
  const isFetchingMerchants: boolean = useAppSelector(getMerchantLoadingState);
  const currentQuery = useAppSelector(getMerchantSearchQuery);

  React.useEffect(() => {
    dispatch(searchMerchantsData({
      searchQuery: currentQuery,
      zipcodes,
      claimed,
    }));
  }, [])

  React.useEffect(() => {
    if (isFetchingMerchants) return;

    const storeLocations: StructuredAddress[] = stores
      .filter((item) => item.structuredAddress && item.structuredAddress.lat && item.structuredAddress.lng && item.structuredAddress.country === 'US')
      .map<StructuredAddress>((item) => ({
        ...item.structuredAddress,
        title: item.officialName,
        subtitle: item.description,
        id: item.username
      } || {}));

    setStoreLocations([...storeLocations]);
    forceCheck();
  }, [stores, isFetchingMerchants]);

  const handleChangeQuery = (query: string): void => {
    dispatch(searchQueryUpdated(query));
    dispatch(searchMerchantsData({
      searchQuery: query,
      zipcodes,
      claimed,
    }));
  }

  const title = liteView ? 'San Mateo Fest' : 'Map Search';
  const placeholder = liteView ? 'Search participating stores' : undefined;

  return (
    <FullscreenPaper className={classes.root}>
      <MapSearchPageHeader title={title}/>
      <SimpleLoader loading={isFetchingMerchants} />
      <If condition={liteView}>
        <Typography variant="body2">
          <Link
            target="_blank"
            href="https://go.1m.app/sanmateofest2022"
            className={classes.followLinkWrapper}
          >
            Find checkpoints at local businesses on the 1m map and set off on your journey to rediscover San Mateo & win prizes!
            <ChevronRight className="action-icon" />
          </Link>
        </Typography>
      </If>
      <EmbededMap storeLocations={storeLocations || []} height={"320px"} />
      <StoreSearchDrawer
        stores={stores}
        onChangeQuery={handleChangeQuery}
        defaultQuery={currentQuery}
        placeholder={placeholder} />
    </FullscreenPaper>
  );
}

export default MapSearchPage;
