import React from 'react'
import { SvgIcon } from "@material-ui/core";

function JuiceIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M13.74 3.488h-1.828l.153-1.668h1.677a.41.41 0 100-.82H11.69a.41.41 0 00-.408.373l-.194 2.115H8.001a.41.41 0 00-.41.41v4.537c-.51-.128-1.06-.038-1.577.246.189-1.238.747-1.808.941-1.972a.41.41 0 00-.529-.627c-.089.075-.22.2-.367.383-.164-.317-.41-.648-.775-.858-.866-.5-1.99-.084-2.037-.066a.41.41 0 00-.26.45c.008.05.21 1.231 1.076 1.731.305.176.721.283 1.266.233a5.54 5.54 0 00-.152.79c-1.168-.71-2.436-.365-3.017.822-.741 1.513-.113 3.843 1.355 4.918.659.482 1.376.631 2.025.423.716.23 1.445.01 2.05-.443v.123c0 .227.184.41.41.41h5.74a.41.41 0 00.41-.41V3.898a.41.41 0 00-.41-.41zm-.41.82v2.415a3.509 3.509 0 01-1.735.232l.242-2.647h1.492zm-2.317 0l-.224 2.453a4.977 4.977 0 00-2.378-.107V4.308h2.602zM3.896 6.216c.806-.14 1.266.11 1.555.897-.816.142-1.27-.119-1.555-.897zm3.184 7.607c-.407.298-.914.468-1.38.27-.182-.077-.314.002-.395.029-.396.134-.86.027-1.305-.3-1.147-.839-1.67-2.736-1.103-3.894.496-1.013 1.54-.904 2.351-.08a.41.41 0 00.586-.002c.806-.83 1.857-.924 2.35.082.566 1.158.043 3.055-1.104 3.895zm1.33.355c0-.633.002-.526 0-.57 1.04-1.485 1.144-3.691 0-4.732V7.493a4.167 4.167 0 012.272.094 4.253 4.253 0 002.647.007v6.584H8.411z" />
    </SvgIcon>
  );
}

export default JuiceIcon