
import React from "react"
import { Card, List, ListItem, ListItemIcon, ListItemText, Typography, makeStyles, createStyles, Theme, CardContent, Divider, Paper, Stack } from "@material-ui/core"
import { Place, Phone, Description, Schedule } from "@material-ui/icons"
import { If, Then } from "react-if"
import ReactMarkdown from "react-markdown"

import { Merchant } from "../../services/models"
import BottomDrawer from "../../bos_common/src/components/BottomDrawer"
import MerchantStoreHours from "./MerchantStoreHours"
import { MerchantRatingInfo } from "./MerchantRatingInfo"
import { useEnableRatings } from "../../hooks/useMerchantRating"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      paddingRight: theme.spacing(1),
      '& .MuiDivider-root:last-child': {
        display: 'none',
      },
      '& .phoneLink': {
        color: theme.palette.primary.main,
      },
    },
  }),
);

const MerchantInfoDrawer = (props: {
  open: boolean,
  setOpen: (open: boolean) => void,
  merchant: Merchant,
}): React.ReactElement | null => {
  if (!open) return null;

  const classes = useStyles();
  const isRatingsEnabled = useEnableRatings(props.merchant);
  const { officialName, category, address, phoneNumber, description, hours, rating } = props.merchant
  const title = (
    <div>
      <Typography variant="h6" component="div">{officialName}</Typography>
      <Stack direction={'row'} alignItems='center'>
        <Typography variant="subtitle1" color="textSecondary" component="div">
          {category}
        </Typography>
        <If condition={isRatingsEnabled}>
          <Then>
            <>&nbsp;&nbsp;{"|"}&nbsp;&nbsp;</>
            <MerchantRatingInfo merchant={props.merchant} typographyProps={{ variant: "subtitle1", color: "textSecondary" }} />
          </Then>
        </If>
      </Stack>
    </div>
  )

  return (
    <BottomDrawer {...props} title={title} maxHeightPx={550} fullWidth>
      <CardContent>
        <Card className={classes.root} elevation={3}>
          <List>
            <If condition={address}>
              <>
                <ListItem>
                  <ListItemIcon>
                    <Place color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={address} />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            </If>
            <If condition={Boolean(hours)}>
              <>
                <ListItem>
                  <ListItemIcon>
                    <Schedule color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={<MerchantStoreHours merchant={props.merchant} />} />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            </If>
            <If condition={phoneNumber}>
              <>
                <ListItem>
                  <ListItemIcon>
                    <Phone color="primary" />
                  </ListItemIcon>
                  <ListItemText>
                    <a href={`tel:${phoneNumber}`} className="phoneLink"> {phoneNumber}</a>
                  </ListItemText>
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            </If>
            <If condition={description}>
              <>
                <ListItem>
                  <ListItemIcon>
                    <Description color="primary" />
                  </ListItemIcon>
                  <ListItemText>
                    <ReactMarkdown>
                      {description || ''}
                    </ReactMarkdown>
                  </ListItemText>
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            </If>
          </List>
        </Card>
      </CardContent>
    </BottomDrawer>
  )
}

export default MerchantInfoDrawer