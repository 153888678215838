import { Alert, Card, CardContent } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../bos_common/src/context/UserContext";

const LogoutPage = (): React.ReactElement => {
  const history = useHistory()
  const { setLoginState } = useContext(UserContext)
  const { search } = useLocation()

  useEffect(() => {
    setLoginState(undefined, undefined)
    const params = new URLSearchParams(search)
    const to = params.get('to')
    if (to) {
      history.replace(to)
    } else {
      history.push('/')
    }
  }, [])

  return (
    <Card variant="outlined">
      <CardContent>
        <Alert severity='success'>You have been logged out and will be redirected back to the store momentarily...</Alert>
      </CardContent>
    </Card>
  )
}

export default LogoutPage