import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useStripe, PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import { PaymentIntent, SetupIntent, PaymentRequest, PaymentRequestPaymentMethodEvent, PaymentMethod, loadStripe } from "@stripe/stripe-js";
import { Alert, Box, createStyles, Fab, Grid, makeStyles, Paper, Theme, Typography, CircularProgress, Snackbar } from "@material-ui/core";
import { CreditCard } from "@material-ui/icons";
import { If, Then } from 'react-if';
import { Elements } from "@stripe/react-stripe-js";

// src
import { UserContext } from "../../bos_common/src/context/UserContext";
import axios from "../../bos_common/src/services/backendAxios";
import renderPrice from "../../bos_common/src/components/Price";
import { getAPIErrorMessage, getAuthHeaders } from "../../bos_common/src/utils";
import SimpleLoader from "../../bos_common/src/components/SimpleLoader";
import { LOCALSTORAGE_APP_KEYS } from "../../bos_common/src/constants";

import { AppContext, CartType } from "../../context/AppContext";
import { Order, Merchant, OrderStatus, OrderType } from "../../services/models"
import { getTotalPrice } from "../../services/cartUtils";
import createOrderService from "../../services/createOrderService";
import CountBox from "../common/CountBox";
import { getLiveMerchantPromotion, isCartFromAnOpenCheckOrder, isEmptyOrNil, stripePublicKey } from "../../utils";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getSignUpDrawerUIProperties, getToken } from "../../redux/slice/auth/authSelector";
import { getStripeLoadingStatus, getUserPaymentMethod, getUserPaymentMethods } from "../../redux/slice/stripe/stripeSelector";
import { clearPaymentMethods, fetchUserPaymentMethods, PaymentMethodCache, persistCachedPaymentMethod } from "../../redux/slice/stripe/stripeAction";

import { checkCartItemsAvailability } from "./utils";
import { CreditCardList } from "./CreditCardList";
import { AddPaymentFormDrawer } from "./AddPaymentFormDrawer";
import eventTrackingService from "../../services/Tracking";
import Storage from "../../services/storage";
import { SignupEntrance } from "../User/SignupDrawer";
import { getEventLabel } from "../../services/Tracking/EventsTracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../../services/Tracking/events";
import { showSignUpDrawer } from "../../redux/slice/auth/authActions";
import { Notification, NotificationSeverity } from "../../types/Notification";
import { isOrderAmountApproximate } from "../MerchantMenu/StoreLocation/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center",
      alignSelf: "center",
      margin: theme.spacing(1),

      '& .credit-card-form-container': {
        marginTop: theme.spacing(4),
        '& .credit-card-pay-btn': {
          marginTop: theme.spacing(3),
          width: '100%',
          position: 'relative',
        },
      },

      "& .credit-card-list .MuiPaper-root": {
        margin: '1rem 0',
      },

      "& .paper-item": {
        display: 'flex',
        padding: '1rem',
        margin: '1rem 0',
        cursor: "pointer"
      },
    },
  }),
);

type CreditCardPaymentProps = {
  merchant?: Merchant,
  currency: string,
  amountInCents: number,
  processing: boolean,
  setProcessing: (_: boolean) => void,
  setError: (_: string) => void,
  createOrder: (_?: string, __?: string, ___?: string, ____?: PaymentMethod) => Promise<void>,
  checkPrerequisite: () => boolean,
  onPaymentMethodAdded?: () => void,
  isPreAuth?: boolean,
}

type PaymentAlertProps = {
  notif?: Notification,
  setNotif: (_?: Notification) => void,
};

const PaymentAlert = ({ notif, setNotif }: PaymentAlertProps): JSX.Element | null => {
  if (!notif || !notif.notificationBody || !notif.notificationBody.open) return null;

  const { open, bodyText, severity, hideAction } = notif.notificationBody;
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={severity === 'error' ? null : 3000}
      transitionDuration={500}>
      <Alert severity={severity} onClose={() => setNotif(undefined)}>{bodyText}</Alert>
    </Snackbar>
  );
}

const CreditCardPaymentForm = (props: CreditCardPaymentProps) => {
  const { merchant, currency, amountInCents, setError, createOrder, processing, setProcessing, checkPrerequisite, onPaymentMethodAdded, isPreAuth } = props;
  const { user } = useContext(UserContext);
  const { cartType } = useContext(AppContext);
  const stripe = useStripe();
  const token = useAppSelector(getToken);
  const userPaymentMethods = useAppSelector(getUserPaymentMethods);
  const paymentMethodLoading = useAppSelector(getStripeLoadingStatus);
  const reduxDispatch = useAppDispatch();
  const [paymentMethodId, setPaymentMethodId] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');
  const [addCardShown, setShowAddCard] = useState<boolean>(false);
  const [hasFetched, setHasFetched] = useState<boolean>(false);
  const paymentMethod = useAppSelector(getUserPaymentMethod)(paymentMethodId);
  const authUI = useAppSelector(getSignUpDrawerUIProperties);

  useEffect(() => {
    // Whether need to show card addition form on initial page load.
    if (hasFetched) {
      if (!paymentMethodLoading && isEmptyOrNil(userPaymentMethods) && !authUI?.drawerShown) {
        setShowAddCard(true);
      } else {
        setShowAddCard(false);
      }
      if (userPaymentMethods.length > 0 && paymentMethodId === '') {
        setPaymentMethodId(userPaymentMethods[0].id)
      }
    }
  }, [userPaymentMethods.length, hasFetched, paymentMethodLoading, authUI]);

  useEffect(() => {
    if (token && token.trim()) {
      reduxDispatch(fetchUserPaymentMethods()).then(() => {
        setHasFetched(true);
      });
    } else {
      setHasFetched(true);
    }
  }, [token]);

  useEffect(() => {
    if (stripe) {
      initSelectedPaymentMethod();
      reduxDispatch(persistCachedPaymentMethod(stripe));
    }
  }, [stripe]);



  const handleClickItem = (paymentMethod: PaymentMethod) => {
    if (processing || !merchant) {
      return
    }

    setPaymentMethodId(paymentMethod.id);
  }

  const handleSubmitPay = async (ev: React.FormEvent) => {
    ev.preventDefault();
    if (processing && !paymentMethodId) {
      return;
    }

    if (!checkPrerequisite()) {
      return;
    }

    if (!paymentMethodId || !paymentMethod) {
      setError('Please select a payment method');
      return;
    }

    if (isPreAuth) {
      authorizeAndOrder();
    } else {
      attemptToPay();
    }

    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.ORDER_CHECKOUT,
      action: EVENT_ACTIONS.CLICK_ORDER_CHECKOUT_PLACE_ORDER,
      label: getEventLabel(merchant ?? {} as Merchant)
    });
  };

  const initSelectedPaymentMethod = async () => {
    const storage = new Storage();
    const unsavedPaymentMethod: PaymentMethodCache | null= await storage.get<PaymentMethodCache>('unsavedPaymentMethod');
    if (unsavedPaymentMethod) {
      setPaymentMethodId(unsavedPaymentMethod.paymentMethodId);
    }
  }

  const authorizeAndOrder = async () => {
    setProcessing(true);
    try {
      createOrder(
        undefined,
        user?.displayName || paymentMethod?.billing_details.name || '',
        user?.phoneNumber || phoneNumber,
        paymentMethod
      );
    } catch (err) {
      setProcessing(false);
      reduxDispatch(clearPaymentMethods());
      const msg = getAPIErrorMessage(err);
      setError(`There is an error! Please add or use another payment method. ${msg}`);
    }
  }

  const attemptToPay = () => {
    // Create PaymentIntent over Stripe API
    setProcessing(true);
    const config = token ? { headers: getAuthHeaders(token) } : {}
    axios.post<PaymentAttempResponse>(
      '/users/pay-with-card',
      {
        merchantId: merchant?.id || '',
        currency,
        amount: amountInCents,
        paymentMethodId: paymentMethodId,
        customerId: paymentMethod?.customer,
      },
      config,
    ).then((response) => {
      setProcessing(false);
      if (response?.data.paymentIntent) {
        createOrder(
          response?.data.paymentIntent.id,
          user?.displayName || paymentMethod?.billing_details.name || '',
          user?.phoneNumber || phoneNumber
        );
      } else {
        setError('Payment service is not available at this moment, please try again later');
      }
    }).catch((err) => {
      setProcessing(false);
      reduxDispatch(clearPaymentMethods());
      const msg = getAPIErrorMessage(err);
      setError(`There is an error! Please add or use another payment method. ${msg}`);
    });
  }

  const handlePaymentMethodCreated = (paymentMethod: PaymentMethod, phone?: string) => {
    setPaymentMethodId(paymentMethod.id);
    setPhoneNumber(phone);
    setShowAddCard(false);
    if (onPaymentMethodAdded) {
      onPaymentMethodAdded();
    }
  }

  const handleClickExistingUser = () => {
    reduxDispatch(showSignUpDrawer({
      drawerShown: true,
      signupEntrance: SignupEntrance.EXISTING_USER_LOGIN,
    }));
  }

  const addPaymentButtonText = isEmptyOrNil(userPaymentMethods)
    ? 'Add a card'
    : 'Or add another card';

  const renderCTAText = useMemo((): string => {
    const priceText = renderPrice(amountInCents / 100);
    if (isCartFromAnOpenCheckOrder(cartType)) {
      return `Pay ${priceText}`;
    }
    if (isPreAuth) {
      return `Place Order & Open Tab ${priceText}`;
    }
    return `Place Order ${priceText}`;
  }, [cartType, merchant]);

  return (
    <div className="credit-card-form-container">
      <Typography variant="subtitle1" style={{ textAlign: 'center', gap: "10px" }} mt="1rem">
        {user ? 'Saved Cards' : 'Credit and Debit cards'} <CountBox count={Number(userPaymentMethods?.length)} />
      </Typography>
      <form onSubmit={handleSubmitPay}>
        <CreditCardList
          paymentMethods={userPaymentMethods} 
          onClickItem={handleClickItem} 
          selectedPaymentMethodId={paymentMethodId}
          withCheckBox />
        <Paper elevation={3} className="paper-item" onClick={() => setShowAddCard(true)}>
          <CreditCard sx={{ height: '54px', width: '54px' }} fontSize="large" />
          <Box sx={{ marginLeft: '1rem' }} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="subtitle1" style={{ lineHeight: 1.2 }}>
              {addPaymentButtonText}
            </Typography>
          </Box>
        </Paper>
        <Fab
          color="primary"
          variant="extended"
          type="submit"
          disabled={processing || !stripe || !paymentMethodId}
          className="credit-card-pay-btn">
          <CreditCard fontSize="large" sx={{mr: 0.5}} />
          {renderCTAText}
          {processing && (
            <CircularProgress
              color="primary"
              size={"small"}
              sx={{
                position: 'absolute',
                zIndex: 10,
              }}
            />
          )}
        </Fab>
      </form>
      <AddPaymentFormDrawer
        open={addCardShown}
        setOpen={setShowAddCard}
        hidePhoneNumber={!isEmptyOrNil(user?.phoneNumber)}
        onPaymentMethodCreated={handlePaymentMethodCreated}
        onClickExistingUser={handleClickExistingUser} />
    </div>
  );
};


type IntegratedPaymentMethodsProps = {
  merchant?: Merchant,
  currency: string,
  amountInCents: number,
  checkPrerequisite: () => boolean,
  setError: (_: string) => void,
  createOrder: (_?: string, __?: string, ___?: string, ____?: PaymentMethod) => Promise<void>,
  setProcessing: (_: boolean) => void,
}
const IntegratedPaymentMethods = (props: IntegratedPaymentMethodsProps) => {
  const { merchant, currency, amountInCents, checkPrerequisite, setError, createOrder, setProcessing } = props;
  const stripe = useStripe();
  const [paymentIntentId, setPaymentIntentId] = useState('');
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const [hide, setHide] = useState(false);

  const handlePaymentMethodReceived = useCallback(async (ev: PaymentRequestPaymentMethodEvent) => {

    // early exist without the proper data
    if (!stripe || !paymentIntentId) return;
    // if (!checkPrerequisite()) return;

    // Confirm the PaymentIntent without handling potential next actions (yet).
    setProcessing(true);
    const payload = await axios.post<PaymentIntent>(
      '/users/confirm-payment-intent',
      {
        merchantId: merchant?.id || '',
        payment_intent_id: paymentIntentId,
        payment_method: ev.paymentMethod.id
      },
    );

    const paymentIntent = (payload.status === 200) ? payload.data : null;

    if (!paymentIntent
      || paymentIntent.status === "requires_payment_method"
      || paymentIntent.status === "requires_action") {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      ev.complete('fail');
      setHide(true);
      setProcessing(false);
      setError('Payment failed, please try using credit cards');
    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      ev.complete('success');
      // The payment has succeeded.
      await createOrder(paymentIntent?.id, ev.payerName)
      setProcessing(false);
    }
  }, [stripe, paymentIntentId]);

  useEffect(() => {
    if (stripe && merchant) {
      // for google pay, apple pay
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: currency.toLowerCase(),
        total: {
          label: 'Total',
          amount: amountInCents,
        },
        requestPayerName: true,
        requestPayerEmail: false,
      });
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      })

      axios.post<PaymentIntent>(
        '/users/create-payment-intent',
        {
          merchantId: merchant.id,
          currency: currency,
          amount: amountInCents,
        },
      ).then((response) => {
        if (response.status === 200) {
          setPaymentIntentId(response.data.id || '');
        }
      })
    }
  }, [stripe, merchant]);

  useEffect(() => {
    if (paymentRequest && paymentIntentId) {
      paymentRequest.on('paymentmethod', handlePaymentMethodReceived);
    }
  }, [paymentRequest, paymentIntentId])

  if (!merchant || !paymentRequest || isEmptyOrNil(paymentIntentId) || hide) return null

  return (
    <div className='integrated-payments-container' style={{ marginTop: 32 }}>
      <Typography variant='subtitle1' component='div' style={{ marginBottom: 16 }}>
        Or use other payment methods
      </Typography>
      <PaymentRequestButtonElement options={{ paymentRequest }} />
    </div>
  )
}


type StripePaymentFormProps = {
  onSuccessSubmit: (order: Order) => void,
  isPreAuth: boolean,
}

type PaymentAttempResponse = {
  setupIntent?: SetupIntent,
  paymentIntent?: PaymentIntent,
}

const StripePaymentForm = (props: StripePaymentFormProps): React.ReactElement => {
  const { isPreAuth } = props;
  const appContext = useContext(AppContext);
  const { cart, merchantConfig, tip, coupon, reFetchMerchandisesForCart, cartType, orderType } = appContext;
  const { merchant } = merchantConfig || {};
  const { user, token, refreshUser } = useContext(UserContext);
  const [processing, setProcessing] = useState(false);
  const classes = useStyles();
  const [notif, setNotif] = useState<Notification>();
  const isDineInOrder = orderType === OrderType.DINEIN;
  const isPriceApproximate = useMemo(() => merchant && isOrderAmountApproximate(merchant), [merchant]);

  const setError = (msg: string) => msg ? setNotif({
    ts: Date.now(),
    notificationBody: {
      open: true,
      bodyText: msg,
      severity: NotificationSeverity.ERROR,
      hideAction: false,
    }
  }) : setNotif(undefined)

  const setSuccessMessage = (msg: string) => msg ? setNotif({
    ts: Date.now(),
    notificationBody: {
      open: true,
      bodyText: msg,
      severity: NotificationSeverity.SUCCESS,
      hideAction: false,
    }
  }) : setNotif(undefined)

  const amount = getTotalPrice({ cart, tip, coupon, orderingConfig: merchant?.orderingConfig });
  // minimum amount accepted by stripe is 0.50
  const amountInCents = Math.round(amount * 100);
  const currency = "USD";

  const stripeAccount = merchant?.production
    ? merchant?.stripe?.stripeAccountId
    : merchant?.stripe?.stripeTestAccountId;

  const stripePromise = useMemo(() => loadStripe(stripePublicKey(merchant?.production)), []);
  const stripeMerchantPromise = useMemo(() => loadStripe(stripePublicKey(merchant?.production), { stripeAccount }), []);

  useEffect(() => {
    // fetch latest merchandise data to
    // 1/ make sure prices are accurate
    // 2/ make sure inventories are accurate
    if (merchant) {
      reFetchMerchandisesForCart();
    }
  }, [])

  const checkPrerequisite = () => {
    if (!isCartFromAnOpenCheckOrder(cartType) && checkCartItemsAvailability({ cart, merchantConfig })) {
      setError("Some items in your cart are sold out, please go back and check")
      return false;
    }

    return true;
  }

  const sendPromotionSmsNotification = (orderId: string) => {
    const promotion = getLiveMerchantPromotion(merchant);
    promotion && axios.post(`orders/${orderId}/promotion-sms`);
  };

  const createOrder = async (paymentIntentId?: string, displayName?: string, phone?: string, paymentMethod?: PaymentMethod,) => {
    try {
      setProcessing(true);
      let cachedPhone = sessionStorage.getItem(LOCALSTORAGE_APP_KEYS.USER_PHONE_NUMBER);
      displayName && sessionStorage.setItem(LOCALSTORAGE_APP_KEYS.USER_DISPLAY_NAME, displayName);

      if (!cachedPhone && phone) {
        sessionStorage.setItem(LOCALSTORAGE_APP_KEYS.USER_PHONE_NUMBER, phone);
        cachedPhone = phone;
      }

      const createOrderPayload = {
        appContext,
        paymentIntentId: paymentIntentId,
        name: displayName,
        user,
        token,
        phone: cachedPhone,
        ...((isPreAuth) && {
          orderStatus: OrderStatus.OPEN_CHECK
        }),
        preAuthPaymentMethod: paymentMethod
      };

      const results = await createOrderService(createOrderPayload);

      setProcessing(false);

      if (results.status === 200) {
        const order = results.data;
        setError('');

        if (!isEmptyOrNil(user)) {
          refreshUser()
        } else {
          // only send notifications if user has not signed i
          sendPromotionSmsNotification(order.id)
        }
        props.onSuccessSubmit(order)

        eventTrackingService.captureCheckoutEvent({
          label: getEventLabel(merchant ?? {} as Merchant),
          data: {
            user,
            merchant,
            order
          }
        })
      } else {
        const errorMessage = `Payment failed, please try again later`
        setError(errorMessage);

        eventTrackingService.captureCheckoutEvent({
          label: getEventLabel(merchant ?? {} as Merchant),
          data: {
            user,
            merchant,
          },
          errorMessage
        })
      }
    } catch (err) {
      console.log(err)
      setProcessing(false);
      const errorMessage = getAPIErrorMessage(err) || `Payment failed, please try again later`;

      eventTrackingService.captureCheckoutEvent({
        label: getEventLabel(merchant ?? {} as Merchant),
        data: {
          user,
          merchant,
        },
        errorMessage
      })
      setError(errorMessage);
    }
  }

  const handlePaymentMethodAdded = () => {
    setSuccessMessage('New payment method added.');
  }

  return (
    <div className={classes.root}>
      <SimpleLoader loading={processing} />
      <PaymentAlert notif={notif} setNotif={setNotif} />
      {(isPreAuth && isDineInOrder) &&
        <Alert severity={"info"}>
          To open a tab, please select a payment method
        </Alert>
      }
      {isPriceApproximate &&
        <Alert severity={"info"}>
          Estimated price. Order amount may change due to weight-based pricing.
        </Alert>
      }
      <Elements stripe={stripePromise}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={6} lg={4}>
            <CreditCardPaymentForm
              merchant={merchant}
              currency={currency}
              amountInCents={amountInCents}
              setError={setError}
              createOrder={createOrder}
              processing={processing}
              setProcessing={setProcessing}
              isPreAuth={isPreAuth}
              checkPrerequisite={checkPrerequisite}
              onPaymentMethodAdded={handlePaymentMethodAdded}
            />
          </Grid>
        </Grid>
      </Elements>
      <If condition={!isPreAuth}>
        <Elements stripe={stripeMerchantPromise}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={6} lg={4}>
              <IntegratedPaymentMethods
                merchant={merchant}
                currency={currency}
                amountInCents={amountInCents}
                checkPrerequisite={checkPrerequisite}
                setError={setError}
                createOrder={createOrder}
                setProcessing={setProcessing}
              />
            </Grid>
          </Grid>
        </Elements>
      </If>
    </div>
  );
}

export default StripePaymentForm