import React from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { useHistory } from 'react-router';
import { Cluster, ClusterStats, MarkerClusterer } from "@googlemaps/markerclusterer";

import { StructuredAddress } from '../types/AddressType';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

import MapMarker from '../assets/icons/Map-Marker.svg';
import MapMarkerStore from '../assets/icons/Map-Marker-with-store-icon.svg';

export interface EmbededMapProps {
  storeLocations: StructuredAddress[];
  height: string;
}

type LatLng = google.maps.LatLng | null | undefined;

const MapStyleArray = [
  {
    "featureType": "administrative",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#444444"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.province",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels",
    "stylers": [
      {
        "hue": "#ffe500"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "all",
    "stylers": [
      {
        "color": "#f2f2f2"
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "landscape.natural",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "landscape.natural.landcover",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "landscape.natural.terrain",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "landscape.natural.terrain",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "landscape.natural.terrain",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "landscape.natural.terrain",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "landscape.natural.terrain",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "landscape.natural.terrain",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "landscape.natural.terrain",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "landscape.natural.terrain",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "landscape.natural.terrain",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi.attraction",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi.business",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.place_of_worship",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi.school",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "all",
    "stylers": [
      {
        "saturation": -100
      },
      {
        "lightness": 45
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#dad5c4"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "transit.station.airport",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "all",
    "stylers": [
      {
        "color": "#9bdffb"
      },
      {
        "visibility": "on"
      }
    ]
  }
]

const CustomClusterRenderer = {
  render: function ({ count, position }: Cluster, stats: ClusterStats) {
    return new google.maps.Marker({
      position,
      icon: {
        url: MapMarker,
        labelOrigin: new google.maps.Point(25, 18),
        scaledSize: new google.maps.Size(50, 50),
      },
      label: {
        text: String(count),
        color: "rgba(255,255,255,0.9)",
        fontSize: "14px",
      },
      // adjust zIndex to be above other markers
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
    });
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&#embeded_map': {
        '& .marker-label': {
          backgroundColor: 'white',
          padding: 5,
          borderRadius: theme.spacing(1),
          fontSize: 10,
          marginTop: 0
        }
      }
    }
  }),
);

export const EmbededMap = (props: EmbededMapProps): JSX.Element => {
  const loader = new Loader({
    apiKey: "AIzaSyBgGQDhX_mo4htOJF8cINNECCW9Pf9_eCY",
  });
  const classes = useStyles()
  const history = useHistory()
  const { storeLocations } = props;
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [markers, setMarkers] = React.useState<google.maps.Marker[]>([]);
  const [markerclusterer, setMarkerClusterer] = React.useState<MarkerClusterer | null>(null);

  React.useEffect(() => {
    loader.load().then(initMap);
  }, []);

  React.useEffect(() => {
    if (!map) return;

    const { markerList, bounds, center } = updateMarkers(storeLocations);
    setMarkers([...markerList]);

    if (markerList.length > 0) {
      map.setCenter(center);
      if (markerList.length > 1) {
        map.fitBounds(bounds);
      } else {
        map.setZoom(11);
      }
    }

    setMarkerClusterer(
      new MarkerClusterer({
        map,
        markers: markerList,
        renderer: CustomClusterRenderer
      }))
  }, [storeLocations, map]);

  const initMap = (): void => {
    const center = new google.maps.LatLng({ lat: 37.78444200981738, lng: -122.40296933567062 });
    const zoom = 11;
    const element = document.getElementById("embeded_map") as HTMLElement;
    if (element) {
      const map = new google.maps.Map(document.getElementById("embeded_map") as HTMLElement, {
        center,
        zoom,
        styles: MapStyleArray,
        mapTypeControl: false,
        zoomControl: false,
        streetViewControl: false,
        minZoom: zoom - 7,
        disableDoubleClickZoom: true,
        gestureHandling: 'greedy',
        restriction: {
          latLngBounds: {
            north: 50,
            south: 23,
            east: -66,
            west: -155,
          },
        },
      });

      setMap(map);
    }
  }

  const updateMarkers = (storeLocations: StructuredAddress[]) => {
    let marker = null;
    let sumLat = 0;
    let sumLng = 0;
    let validCount = 0;
    const markerList: google.maps.Marker[] = [];
    const bounds = new google.maps.LatLngBounds();

    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }

    markerclusterer?.clearMarkers();
    storeLocations.forEach((item) => {
      if (!item.lat || !item.lng || isNaN(item.lat) || isNaN(item.lng)) {
        return;
      }
      const position = new google.maps.LatLng(item.lat as number, item.lng as number);
      marker = new google.maps.Marker({
        position: position,
        title: 'marker-logo-wrapper',
        icon: {
          url: item.icon ?? MapMarkerStore,
          labelOrigin: new google.maps.Point(25, 55),
          scaledSize: new google.maps.Size(46, 46),
        },
        ...(item.title && {
          label: {
            text: item.title,
            color: 'black',
            fontSize: '12px',
            className: 'marker-label'
          }
        })
      });
      if (item.id) {
        marker.addListener('click', () => {
          history.push({ pathname: `/${item.id}`, state: { from: window.location.pathname } })
        })
      }
      sumLat += Number(item.lat);
      sumLng += Number(item.lng);
      validCount += 1;
      bounds.extend(position);
      marker.setMap(map);
      markerList.push(marker);
    });

    const demoninator = Math.max(1, validCount);
    return {
      markerList,
      bounds,
      center: new google.maps.LatLng(sumLat / demoninator, sumLng / demoninator),
    }
  }

  return (<div id="embeded_map" className={classes.root} style={{ height: props.height }}></div>);
}

export default EmbededMap;
