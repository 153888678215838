
import React from 'react'
import { SvgIcon } from "@material-ui/core";

function SandwichIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M15.063 9.173V7.452 7.45c.546-.194.937-.714.937-1.325a3.285 3.285 0 00-3.281-3.281H3.28A3.285 3.285 0 000 6.125a1.408 1.408 0 00.938 1.327v1.721c0 .001 0 .002-.002.002A1.408 1.408 0 000 10.5a2.66 2.66 0 002.656 2.656h10.688A2.66 2.66 0 0016 10.5a1.408 1.408 0 00-.938-1.327zm-4.366-.082l1.56-1.56h1.866l.002.002v1.56H10.698c-.001 0-.002 0-.001-.002zm-6.953-1.56l1.56 1.56a.002.002 0 01-.002.003H1.877l-.002-.002v-1.56h1.868zm1.329 0h5.854c.001 0 .002.002.001.003L8.001 10.46H8L5.072 7.534a.002.002 0 01.001-.003zM3.156 3.786V4.86c0 .253.195.472.448.483a.469.469 0 00.49-.468V3.783l.001-.002h1.247l.002.002V4.86c0 .254.194.472.447.483a.469.469 0 00.49-.468V3.783l.002-.002H7.53l.001.002V4.86c0 .254.195.472.448.483a.469.469 0 00.49-.468V3.783l.001-.002h1.247l.002.002V4.86c0 .254.194.472.447.483a.469.469 0 00.49-.468V3.783l.002-.002h1.247l.001.002V4.86c0 .254.195.472.448.483a.469.469 0 00.49-.468V3.786l.001-.001a2.347 2.347 0 012.217 2.345.468.468 0 01-.47.464H1.408a.468.468 0 01-.47-.464 2.347 2.347 0 012.218-2.344zm10.188 8.433H2.656a1.72 1.72 0 01-1.719-1.722.47.47 0 01.47-.466h4.836l1.426 1.425a.467.467 0 00.662 0l1.425-1.424h4.836a.47.47 0 01.47.465 1.72 1.72 0 01-1.718 1.722z" />
    </SvgIcon>
  );
}

export default SandwichIcon