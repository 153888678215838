import React from "react";
import classNames from "classnames";
import { Button, Theme, useMediaQuery } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { If } from "react-if";

import { RewardsContentStyled } from "../../styles/Common.style";
import { isEmptyOrNil } from "../../utils";
import { DialogCard } from "../LearnMoreDialog";

type ContentWithFloatingVisualProps = {
  contentClassName?: string;
  onCancel?: () => void;
  children?: React.ReactElement | React.ReactElement[];
  illustration?: string | React.ReactNode;
  heading?: string | React.ReactNode;
  subHeadings?: string[];
  title?: string;
  description?: string;
  cardsHeading?: string | React.ReactNode;
  isMultiColumn?: boolean;
  cardsList?: Array<{
    icon: React.ReactNode;
    heading: string;
    description?: string;
  }>;
};

const ContentWithFloatingVisual = (props: ContentWithFloatingVisualProps) => {
  const {
    contentClassName = "",
    onCancel,
    heading,
    title,
    subHeadings,
    isMultiColumn = true,
    cardsHeading,
    illustration,
    description,
    cardsList,
    children,
  } = props;

  return (
    <RewardsContentStyled
      className={classNames({
        multiColumn: isMultiColumn,
        [contentClassName]: contentClassName,
      })}
    >
      <If condition={!isEmptyOrNil(onCancel)}>
        <Button onClick={onCancel} className="closeButton">
          <Close /> Close
        </Button>
      </If>

      <If condition={!isEmptyOrNil(illustration)}>
          <div className="illustration">
            {illustration && illustration}
          </div>
      </If>
      <div className={classNames({ dialogContent: isMultiColumn })}>
        <If condition={!isEmptyOrNil(title)}>
          <p className="title">{title}</p>
        </If>

        <If condition={!isEmptyOrNil(heading)}>
          <p className="heading">{heading}</p>
        </If>

        <If condition={!isEmptyOrNil(subHeadings)}>
          {subHeadings?.map((data, index) => (
            <p className="subHeading" key={`subheading-${index}`}>
              {data}
            </p>
          ))}
        </If>

        <If condition={!isEmptyOrNil(description)}>
          <p className="description">{description}</p>
        </If>

        <If condition={!isEmptyOrNil(cardsHeading)}>
          <h4 className="contentHeading">{cardsHeading}</h4>
        </If>

        <If condition={!isEmptyOrNil(cardsList)}>
          <div className="featuresWrapper">
            {cardsList?.map((data, index) => (
              <DialogCard key={`card-${index}`} {...data} />
            ))}
          </div>
        </If>

        {children && children}
      </div>
    </RewardsContentStyled>
  );
};

export default ContentWithFloatingVisual;
