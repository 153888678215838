import { Grid, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import React from "react";
import { If } from "react-if";

import BottomDrawer from "../../bos_common/src/components/BottomDrawer";
import { isEmptyOrNil } from "../../utils";
import CountBox from "../common/CountBox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiDrawer-paper": {
        width: "60vw",
        borderRadius: 0,

        [theme.breakpoints.down("sm")]: {
          width: "100vw",
          borderTopLeftRadius: theme.spacing(1),
          borderTopRightRadius: theme.spacing(1),
        },

        "& .MuiPaper-root ": {
            boxShadow: "unset"
        }
      },
      [theme.breakpoints.up("sm")]: {
        "& .toolbarWrapper": {
          maxHeight: "unset",
        },
      },

      "& .toolbar": {
          position: "sticky",
          top: 0,
          background: theme.palette.background.paper,
          zIndex: 10,
      }
    },

    cardsListWrapper: {
      "&.MuiGrid-root": {
        maxWidth: "90%",
        width: "100%",
        margin: "auto",
        marginLeft: "auto",

        [theme.breakpoints.down('sm')]: {
            maxHeight: '70vh',
        }
      },
    },
  })
);

type CardsListDrawerProps = {
  open: boolean;
  setDrawerOpen: (_: boolean) => void;
  heading: string;
  subHeading?: string;
  itemsList: React.ReactElement[];
};

const CardsListDrawer = (props: CardsListDrawerProps): JSX.Element | null => {
  const { open, setDrawerOpen, heading, subHeading, itemsList } = props;
  if (!open) return null;

  const classes = useStyles();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <BottomDrawer
      open={open}
      setOpen={setDrawerOpen}
      anchor={isMobile ? "bottom" : "right"}
      className={classes.root}
      fullWidth={!isMobile}
      title={
        <>
          <Typography
            variant="h6"
            className="drawerTitle"
            component="div"
            gutterBottom
            sx={{ display: "flex", alignItems: "center" }}
          >
            {heading}&nbsp;
            <CountBox count={itemsList.length} />
          </Typography>
          <If condition={!isEmptyOrNil(subHeading)}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              className="drawerSubtitle"
              component="div"
            >
              {subHeading}
            </Typography>
          </If>
        </>
      }
    >
      <Grid container spacing={2} className={classes.cardsListWrapper}>
        {itemsList.map((entry: any, i: number) => {
          return (
            <Grid item xs={6} sm={6} md={4} lg={3} key={`row-item-${i}`}>
              {entry}
            </Grid>
          );
        })}
      </Grid>
    </BottomDrawer>
  );
};

export default CardsListDrawer;
