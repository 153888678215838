import React, { useMemo, useState } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import useAxios from "axios-hooks";
import { defaultTo, filter, forEach, pathOr, pipe } from "ramda";
import AliceCarousel from "react-alice-carousel";
import { useParams } from "react-router-dom";

import {
  Merchant,
  MerchantGuide,
  MerchantGuideType,
} from "../../services/models";
import { isEmptyOrNil } from "../../utils";
import { CarouselWrapper } from "../Classifieds/styledComponents";
import CardsListDrawer from "./CardsListDrawer";
import CardWrapper from "./CardWrapper";
import StoryCard from "./StoryCard";
import { MerchantStory } from "bos_common/src/types/MerchantStoryType";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .alice-carousel__stage-item": {
        marginRight: theme.spacing(1),
      },
    },

    linkCard: {
      "&.Container": {
        borderColor: "transparent !important",
        boxShadow: "0px 3px 12px rgb(0 0 0 / 7%)",
      },

      "& .LowerContainer": {
        "& h3": {
          fontSize: 14,
          fontWeight: 600,
        },

        "& .Description.Secondary": {
          display: "none",
        },

        "& .Secondary.SiteDetails": {
          display: "none",
        },
      },
    },
  })
);

interface StoriesListProps {
  merchant?: Merchant;
}

type SiteMetaData = {
  url: string,
  title: string,
  description: string,
  image: string,
  siteName: string,
  hostname: string,
}

const StoriesList = (props: StoriesListProps) => {
  const { merchant } = props;

  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const searchParmas = new URLSearchParams();
  searchParmas.append("merchantId", merchant?.id || "");

  const [{ data: guidesList }] = useAxios({
    url: `/merchants/local-guides?${searchParmas.toString()}`,
    method: "get",
  });

  const [{ data: metaPreviewsList }] = useAxios({
    url: `/preview-metadata?merchantId=${merchant?.id}`,
    method: "get",
  });

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const cardsLimit = isMobile ? 5 : 8;

  const stories = useMemo(() => {
    const allItems: React.ReactElement[] = [];
    const selectedItems: React.ReactElement[] = [];

    // merchantStoriesList
    //   .filter((story: MerchantStory) => story.status === 'published')
    //   .forEach((item: any) => {
    //     const heroPhoto = !isEmptyOrNil(item?.heroPhoto)
    //       ? item?.heroPhoto
    //       : pathOr("", ["merchant", "heroPhoto"], item);

    //     allItems.push(
    //       <StoryCard
    //         image={heroPhoto}
    //         heading={item.title}
    //         merchant={item.merchant}
    //         redirectId={item.id}
    //       />
    //     );

    //     if (selectedItems.length < cardsLimit) {
    //       selectedItems.push(
    //         <StoryCard
    //           image={heroPhoto}
    //           heading={item.title}
    //           merchant={item.merchant}
    //           redirectId={item.id}
    //         />
    //       );
    //     }
    //   });

    guidesList?.forEach((data: MerchantGuide) => {
      const { heroPhoto, title, id, type } = data;

      if (selectedItems.length < cardsLimit) {
        if (type === MerchantGuideType.oneMarket) {
          allItems.push(
            <StoryCard merchant={merchant} image={heroPhoto} heading={title} redirectId={id} />
          );

          selectedItems.push(
            <StoryCard merchant={merchant} image={heroPhoto} heading={title} redirectId={id} />
          );
        }
      }
    });

    pipe(
      defaultTo([]),
      filter(({ title, description, image }: SiteMetaData) => !isEmptyOrNil(title) && !isEmptyOrNil(description) && !isEmptyOrNil(image)),
      forEach((data: SiteMetaData, index: number) => {
        const { title, url, image } = data;

        allItems.push(
          <StoryCard
            image={image}
            heading={title}
            externalUrl={url}
            key={`external-card-${index}`}
            labels={["External"]}
          />
        );

        selectedItems.push(
          <StoryCard
            image={image}
            heading={title}
            externalUrl={url}
            key={`external-card-${index}`}
            labels={["External"]}
          />
        );
      })
    )(metaPreviewsList)

    return {
      allItems,
      selectedItems,
    };
  }, [guidesList, metaPreviewsList]);

  if (isEmptyOrNil(metaPreviewsList) && isEmptyOrNil(guidesList))
    return null;

  return (
    <CardWrapper
      heading="Credible Mentions"
      itemsCount={stories.allItems.length}
      handleSeeMore={
        stories.selectedItems.length > cardsLimit
          ? () => setDrawerOpen(true)
          : undefined
      }
    >
      <CarouselWrapper className={classes.root}>
        <AliceCarousel
          items={stories.selectedItems}
          disableButtonsControls
          mouseTracking
          disableDotsControls={true}
          infinite={false}
          autoWidth
          responsive={{ 0: { items: 1 }, 800: { items: 2 }, 1180: { items: 3 } }}
        />
      </CarouselWrapper>

      <CardsListDrawer
        open={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        heading="Featured items"
        itemsList={stories.allItems}
      />
    </CardWrapper>
  );
};

export default StoriesList;
