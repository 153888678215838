import React from 'react'
import { SvgIcon } from "@material-ui/core";

function RiceBowlIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M14.59 6.742h-.452a2.054 2.054 0 00-.591-1.069c.155-.533.09-1.11-.192-1.597a2.049 2.049 0 00-1.287-.964 2.05 2.05 0 00-.964-1.288 2.05 2.05 0 00-1.598-.19A2.049 2.049 0 008.027 1c-.57 0-1.14.242-1.53.634a2.05 2.05 0 00-1.6.19 2.05 2.05 0 00-.965 1.288 2.05 2.05 0 00-1.287.964 2.05 2.05 0 00-.192 1.597 2.054 2.054 0 00-.59 1.07H1.41a.41.41 0 00-.41.41 6.984 6.984 0 003.281 5.913v.704c0 .678.552 1.23 1.23 1.23h4.977c.679 0 1.23-.552 1.23-1.23v-.704A6.984 6.984 0 0015 7.152a.41.41 0 00-.41-.41zm-11.416-.6a.41.41 0 00.137-.513 1.232 1.232 0 01.044-1.143c.201-.348.563-.576.967-.609a.41.41 0 00.375-.375c.034-.405.261-.766.61-.967a1.232 1.232 0 011.142-.044.41.41 0 00.512-.138c.221-.318.65-.533 1.066-.533.402 0 .78.2 1.012.533a.41.41 0 00.512.138 1.232 1.232 0 011.142.044c.348.2.576.562.61.967a.41.41 0 00.375.375c.404.033.766.261.967.61.201.348.217.775.044 1.142a.41.41 0 00.137.512c.216.15.376.362.462.601H2.712c.086-.24.245-.451.462-.6zm7.724 7.628a.41.41 0 01-.41.41H5.512a.41.41 0 01-.41-.41v-.465h5.796v.465zm.194-1.286H4.907a6.175 6.175 0 01-3.073-4.927h12.332a6.175 6.175 0 01-3.074 4.927z" />
      <path d="M9.668 5.102a.41.41 0 100-.82.41.41 0 000 .82zM11.309 5.922a.41.41 0 100-.82.41.41 0 000 .82zM4.691 5.102a.41.41 0 100-.82.41.41 0 000 .82z" />
    </SvgIcon>
  );
}

export default RiceBowlIcon