import React from 'react'
import { SvgIcon } from "@material-ui/core";

function CakeIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M13.348 4.186c-.869-.41-2.054-.715-3.364-.867C9.77 2.309 9.03 1 8 1S6.23 2.31 6.016 3.32c-1.31.152-2.495.457-3.364.867C1.287 4.832 1 5.571 1 6.078v6.59c0 .96.978 1.781 2.753 2.315a.412.412 0 00.264-.01l.006-.002L8 13.391l3.977 1.58a.41.41 0 00.291.004C14.03 14.442 15 13.623 15 12.668v-6.59c0-.507-.287-1.246-1.652-1.892zM8 1.82c.606 0 1.23 1.372 1.23 2.079 0 .372-.083.418-1.01.41a21.545 21.545 0 00-.44 0c-.905.008-1.01-.029-1.01-.41 0-.794.667-2.079 1.23-2.079zM3.46 14.018c-1.011-.385-1.64-.892-1.64-1.35V9.943c.406.312.957.587 1.64.817v3.258zm0-4.13C2.45 9.503 1.82 8.996 1.82 8.54V7.482c.406.312.957.587 1.64.817v1.59zm4.13 2.783L4.28 13.986V11.56l3.309-1.315v2.426zm0-3.309L4.28 10.677V8.279L7.59 6.964v2.398zm4.13 4.623l-3.31-1.314v-2.426l3.311 1.315v2.425zm.002-3.308L8.41 9.362V6.964l3.312 1.315v2.398zm2.458 1.991c0 .458-.629.965-1.64 1.35V10.76c.683-.23 1.234-.505 1.64-.817v2.725zm0-4.129c0 .458-.629.964-1.64 1.35v-1.59c.683-.23 1.234-.505 1.64-.817V8.54zm-2.035-.974L8.16 5.982c-.13-.048-.19-.041-.32 0L3.855 7.565C2.615 7.17 1.82 6.592 1.82 6.078c0-.715 1.542-1.602 4.147-1.927.038.258.137.468.297.626.365.362.927.355 1.522.35.34-.002.423.001.626.001.52 0 1-.03 1.324-.351.16-.158.259-.368.297-.626 2.605.325 4.147 1.212 4.147 1.927 0 .514-.794 1.091-2.035 1.487z" />
    </SvgIcon>
  );
}

export default CakeIcon
