import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Fab,
} from "@material-ui/core";
import { HowToVote } from "@material-ui/icons";
import { If } from "react-if";
import { Merchant } from "../../bos_common/src/types/MerchantType";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fs14: {
      fontSize: "0.875rem",
      fontWeight: 700,
      lineHeight: "1rem",
    },

    fs12: {
      fontSize: "0.75rem",
      fontWeight: 400,
      color: theme.palette.secondary.light,
      lineHeight: "1rem",
    },

    fs10: {
      fontSize: "0.625rem",
      fontWeight: "bold",
      lineHeight: "1rem",
    },

    cardHeader: {
      marginBottom: "0.625rem",
    },

    content: {
      paddingTop: "0.625rem",
    },

    metric: {
      borderRadius: "3px" /*theme.spacing(1)*/,
      textTransform: "uppercase",
      padding: "2px 6px",

      "& .vote-label": {
        display: "flex",
        alignItems: "center",
        fontSize: "1rem",
      },

      "&>.MuiChip-label": {
        padding: 0,
      },

      "&.voted": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
      },

      "&.zero-voted": {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        color: "#364245",
      },
    },

    metric0: {
      borderRadius: theme.spacing(1),
      textTransform: "uppercase",

      "& .vote-label": {
        display: "flex",
        alignItems: "center",
        fontSize: "1rem",
      },
    },

    voteButton: {
      float: "right",
      clear: "none",
      width: "44px",
      // backgroundColor: theme.palette.primary.light,
      // color: theme.palette.common.white,

      // borderRadius: theme.spacing(1),
      // textTransform: 'uppercase',
      marginTop: "-96px",
      marginRight: "16px",
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.main}`,

      "&:hover": {
        "& .MuiFab-label": { 
          color: theme.palette.common.white 
        },
      },
    },

    voteButtonCaption: {
      fontSize: "0.675rem",
      color: theme.palette.primary.main,
      lineHeight: "0.875rem",
      fontWeight: "bold",
      float: "right",
      marginTop: "-2.5rem",
      marginRight: "1.5rem",
    },
  })
);

interface LootBoxVoteCardProps {
  merchant: Merchant;
  votable?: boolean;
  voteCount?: number;
  onVote?: (merchant: Merchant) => void;
}

const CardTitle = (props: Readonly<LootBoxVoteCardProps>): JSX.Element => {
  const { merchant } = props;
  const classes = useStyles();

  return (
    <div className={`card-header`}>
      <Typography variant="h5" component="div" className={classes.fs14}>
        {merchant.officialName}
      </Typography>

      <Typography variant="subtitle2" className={classes.fs12}>
        {merchant.address}
      </Typography>
    </div>
  );
};

export const LootBoxVoteCard = (
  props: Readonly<LootBoxVoteCardProps>
): JSX.Element => {
  const classes = useStyles();
  const { votable, merchant, voteCount } = props;

  const handleClickVote = (merchant: Merchant) => {
    if (props.onVote) {
      props.onVote(merchant);
    }
  };

  return (
    <div className="card-container">
      <Card>
        <CardHeader title={<CardTitle merchant={merchant} />} />
        <CardContent className={`${classes.content}`}>
          {voteCount && voteCount > 0 ? (
            <Chip
              classes={[classes.metric, classes.fs10, "voted"]}
              label={`${voteCount} ${voteCount === 1 ? "user" : "users"} voted`}
            />
          ) : (
            <Chip
              classes={[classes.metric, classes.fs10, "zero-voted"]}
              label="Be the first to vote!"
            />
          )}
        </CardContent>
        <If condition={votable}>
          <>
            <Fab
              size="medium"
              color="secondary"
              aria-label="add"
              className={classes.voteButton}
              onClick={() => handleClickVote(merchant)}
            >
              <HowToVote />
            </Fab>
            <span className={classes.voteButtonCaption}>Vote</span>
          </>
        </If>
      </Card>
    </div>
  );
};

export default LootBoxVoteCard;
