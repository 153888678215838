import React from 'react'
import { SvgIcon } from "@material-ui/core";

function OrdersMenuIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 14, height: 14 }} viewBox="0 0 14 14" {...props} >
      <path d="M0.5 2.35714C0.5 1.8646 0.695663 1.39223 1.04394 1.04394C1.39223 0.695663 1.8646 0.5 2.35714 0.5H8.85714C9.34969 0.5 9.82206 0.695663 10.1703 1.04394C10.5186 1.39223 10.7143 1.8646 10.7143 2.35714V8.85714H13.5V10.7143C13.5 11.4531 13.2065 12.1617 12.6841 12.6841C12.1617 13.2065 11.4531 13.5 10.7143 13.5H3.28571C2.5469 13.5 1.83834 13.2065 1.31592 12.6841C0.793494 12.1617 0.5 11.4531 0.5 10.7143V2.35714ZM10.7143 12.5714C11.2068 12.5714 11.6792 12.3758 12.0275 12.0275C12.3758 11.6792 12.5714 11.2068 12.5714 10.7143V9.78571H10.7143V12.5714ZM3.75 3.28571C3.62686 3.28571 3.50877 3.33463 3.4217 3.4217C3.33463 3.50877 3.28571 3.62686 3.28571 3.75C3.28571 3.87314 3.33463 3.99123 3.4217 4.0783C3.50877 4.16537 3.62686 4.21429 3.75 4.21429H7.46429C7.58742 4.21429 7.70551 4.16537 7.79259 4.0783C7.87966 3.99123 7.92857 3.87314 7.92857 3.75C7.92857 3.62686 7.87966 3.50877 7.79259 3.4217C7.70551 3.33463 7.58742 3.28571 7.46429 3.28571H3.75ZM3.75 6.07143C3.62686 6.07143 3.50877 6.12034 3.4217 6.20741C3.33463 6.29448 3.28571 6.41258 3.28571 6.53571C3.28571 6.65885 3.33463 6.77694 3.4217 6.86401C3.50877 6.95108 3.62686 7 3.75 7H7.46429C7.58742 7 7.70551 6.95108 7.79259 6.86401C7.87966 6.77694 7.92857 6.65885 7.92857 6.53571C7.92857 6.41258 7.87966 6.29448 7.79259 6.20741C7.70551 6.12034 7.58742 6.07143 7.46429 6.07143H3.75ZM3.75 8.85714C3.62686 8.85714 3.50877 8.90606 3.4217 8.99313C3.33463 9.0802 3.28571 9.19829 3.28571 9.32143C3.28571 9.44456 3.33463 9.56266 3.4217 9.64973C3.50877 9.7368 3.62686 9.78571 3.75 9.78571H5.60714C5.73028 9.78571 5.84837 9.7368 5.93544 9.64973C6.02251 9.56266 6.07143 9.44456 6.07143 9.32143C6.07143 9.19829 6.02251 9.0802 5.93544 8.99313C5.84837 8.90606 5.73028 8.85714 5.60714 8.85714H3.75Z" />
    </SvgIcon>
  );
}

export default OrdersMenuIcon