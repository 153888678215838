import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.secondary.light,
      color: theme.palette.primary.light,

      padding: '5px',
      borderRadius: theme.spacing(1),
      fontSize: '0.9rem'
    }
  })
)
export type LootboxCounterProps = {
  count: string | number,
  suffix: string
}
const LootboxCounter = (props: LootboxCounterProps) => {
  const classes = useStyles()
  const { count, suffix } = props

  return (
    <span className={`lootbox-counter ${classes.root}`}>
      {count}&nbsp;{suffix}
    </span>
  )
}

export default LootboxCounter;