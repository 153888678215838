import { AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "../bos_common/src/services/backendAxios";
import { Order } from "../bos_common/src/types/OrderTypes";

export const getOrder = (id: string, request?: AxiosRequestConfig): Promise<AxiosResponse> => {
  return axios({
    url: `/orders?id=${id}`,
    method: 'GET',
    headers: (request && request.headers) ? request.headers : {},
  });
}

export const putOrder = (order: Partial<Order>, request: AxiosRequestConfig): Promise<AxiosResponse> => {
  return axios.put('/orders',
    {
      ...order,
    }, {
      headers: request.headers,
    }
  );
}

export default {
  getOrder,
  putOrder,
}
