

import React from 'react'
import { SvgIcon } from "@material-ui/core";

function BrazilianIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M5.884 6.524c-.482-.82-.883-1.363-1.178-1.777-.323.453-.697.959-1.179 1.777-.592 1.008-.88 1.736-.88 2.226 0 1.193.866 2.059 2.059 2.059s2.059-.866 2.059-2.06c0-.49-.288-1.217-.88-2.225zm-1.178 3.46c-.739 0-1.235-.495-1.235-1.234 0-.516.662-1.692 1.235-2.564.573.871 1.235 2.048 1.235 2.564 0 .739-.496 1.235-1.235 1.235z" />
      <path d="M4.294 8.338h.824v.823h-.824v-.823zM10.882 5.044h.824v.823h-.824v-.823zM10.882 8.338h.824v.823h-.824v-.823zM10.059 6.69h.823v.824h-.823v-.823zM11.706 6.69h.824v.824h-.824v-.823zM12.53 8.338h.823v.823h-.824v-.823zM10.882 9.985h.824v.824h-.824v-.824zM9.235 8.338h.824v.823h-.824v-.823z" />
      <path d="M15 8.75c0-2.18-3.704-6.85-3.706-6.853C11.292 1.9 8.968 4.831 8 7.137c-.713-1.698-2.173-3.779-3.294-5.24C4.704 1.9 1 6.572 1 8.75c0 1.176.522 2.208 1.351 2.882H1v.824c0 .908.739 1.647 1.647 1.647h10.706c.908 0 1.647-.74 1.647-1.647v-.824h-1.351A3.682 3.682 0 0015 8.75zm-3.706-5.49c1.03 1.41 2.883 4.207 2.883 5.49a2.852 2.852 0 01-2.883 2.882A2.852 2.852 0 018.412 8.75c0-1.283 1.852-4.08 2.882-5.49zM8.94 11.633H7.06c.388-.315.709-.708.94-1.158.231.45.552.843.94 1.158zM1.824 8.75c0-1.283 1.852-4.08 2.882-5.49 1.03 1.41 2.882 4.207 2.882 5.49a2.852 2.852 0 01-2.882 2.882A2.852 2.852 0 011.824 8.75zm12.353 3.706c0 .454-.37.823-.824.823H2.647a.825.825 0 01-.823-.823h12.353z" />
    </SvgIcon>
  );
}

export default BrazilianIcon