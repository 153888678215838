import { AxiosRequestConfig } from "axios";
import axios from "../bos_common/src/services/backendAxios";

export const getMerchantPromotions = (merchantId: string, request?: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  return axios({
    url: `/merchants/${merchantId}/promotions`,
    method: 'GET',
    headers: request?.headers || {},
  });
}

export default {
  getMerchantPromotions,
}
