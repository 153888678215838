import React from "react";
import { createStyles, Stack, Fab, Icon, Typography, makeStyles, Theme } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { MenuDrawer } from "../common/MenuDrawer";
import { ReactComponent as AppLogoIcon } from '../../assets/icons/logo.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(2, 2, 0, 2),
    },
  })
);

export const HeaderTopCTA = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Stack direction={"row"} spacing={1} className={classes.root} justifyContent="space-between">
      <Fab variant="extended" onClick={() => history.push("/")} sx={{textTransform: 'none'}}>
        <Icon component={AppLogoIcon} />&nbsp;&nbsp;
        <Typography component="span"  fontWeight="600">
          One Market
        </Typography>
      </Fab>
      <MenuDrawer menuButtonProps={{ color: "inherit", variant: "contained" }} />
    </Stack>
  );
}

export default HeaderTopCTA;
