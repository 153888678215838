import React from "react";

import { SearchStateType } from "./MarketplaceContextProvider";


export interface MarketplaceStateType {
  searchState: SearchStateType,
  setSearchState: (_: any) => void,
  handleSearchStateChange: (key: string, value: string | number) => void,
  resetSearchState: () => void,
  hasFilters: boolean,
}

export const MarketplaceContext = React.createContext<MarketplaceStateType>({
  searchState: {
    sortBy: "",
    price: -1,
    name: "",
    selectedCategory: "",
  },
  setSearchState: (_: SearchStateType) => ({}),
  handleSearchStateChange: (key: string, value: string | number) => ({}),
  resetSearchState: () => ({}),
  hasFilters: false,
})