import React, { useContext } from "react"
import { Card, CardContent, createStyles, makeStyles, Theme, Typography, Divider, CardMedia } from "@material-ui/core";

import BottomDrawer from "bos_common/src/components/BottomDrawer"
import { UserContext } from "bos_common/src/context/UserContext";
import { Merchant } from "bos_common/src/types/MerchantType";
import { getReferralUrl } from "bos_common/src/services/urls";
import { isEmptyOrNil } from "bos_common/src";

import LinkWithCopyButton from "../common/LinkWithCopyButton";
import { getReferralGiftPromotion } from "../../utils";
import ReferralImg from "../../assets/images/referral-drawer-visual.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .cardHeader": {
        padding: theme.spacing(3, 3, 0),
        textAlign: "center",
      },

      "& .referralImageWrapper": {
        textAlign: "center",
        "& .referralImage": {
          height: 160,
          objectFit: "cover",
        },
      },

      "& .cardTitle": {
        marginBottom: theme.spacing(2),
        color: theme.palette.info.light,

        "& span": {
          color: theme.palette.secondary.main,
        }
      },
    },
  })
);

type ReferralDrawerProps = {
  open: boolean;
  setOpen: (_: boolean) => void;
  merchant: Merchant | undefined;
}

const ReferralDrawer = ({ open, setOpen, merchant }: ReferralDrawerProps) => {
  const classes = useStyles();
  const { user } = useContext(UserContext)
  const promotion = merchant && getReferralGiftPromotion(merchant);

  if (!merchant || !user || !promotion) return null;

  const referralUrl = getReferralUrl(merchant, user, promotion.code || '');

  if(isEmptyOrNil(referralUrl)) return null;

  return (
    <BottomDrawer
      open={open}
      setOpen={setOpen}
      title={<Typography variant="h6" component="div" className="cardTitle">{promotion.title}</Typography>}
    >
      <div className={classes.root}>
        <CardMedia className="referralImageWrapper">
          <img src={ReferralImg} className="referralImage" />
        </CardMedia>
        {/* <div className="cardHeader">
          <Typography variant="h6" component="div" className="cardTitle">{promotion.title}</Typography>
        </div> */}

        <CardContent className="drawerContent" sx={{pt: 0}}>
          <Typography variant="body1" mb={2} className="drawerText">
            {promotion.description}
          </Typography>

          <Divider />

          <Typography variant="body2" mt={2} mb={2}>Copy and share your referral link below with your friends.</Typography>

          <LinkWithCopyButton fullWidth url={referralUrl!} shorten={true} />
        </CardContent>
      </div>
    </BottomDrawer>
  )
}

export default ReferralDrawer;