import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import { If, Then } from "react-if";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import { User } from "bos_common/src/types/UserTypes";
import { StoryComment } from "bos_common/src/types/MerchantStoryType";
import CommentInfo from "./CommentInfo";
import CommentMain from "./CommentMain";
import CommentMenu from "./CommentMenu";


const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '4px 16px',
    paddingLeft: '8px',
    borderLeft: '2px solid #ccc',
    borderBottomLeftRadius: '8px'
  }
}));

export interface CommentReplyItemProps {
  comment: StoryComment;
  user: User | null;
  onAboutToComment?: (comment: StoryComment) => void;
  onDelete?: (storyComment: StoryComment) => void;
}

export const CommentReplyItem = (props: Readonly<CommentReplyItemProps>): JSX.Element => {
  const { comment, user, onDelete } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>();
  const menuOpened = Boolean(anchorEl);

  const handleClickMore = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  }

  const handleCloseMenu = () => {
    setAnchorEl(undefined);
  }

  const handleDelete = (deletedComment: StoryComment) => {
    if (!onDelete) {
      return;
    }

    onDelete(deletedComment);
  }

  return (
    <Box className={classes.root} key={`comment_${comment.id}`}>
      <If condition={user?.id === comment.user?.id}>
        <div>
          <IconButton sx={{ float: 'right', clear: 'none', marginBottom: -6 }} onClick={(e) => handleClickMore(e)}>
            <MoreHorizIcon />
          </IconButton>
          <CommentMenu
            comment={comment}
            onDelete={() => handleDelete(comment)}
            anchorEl={anchorEl}
            onClose={handleCloseMenu}
            menuOpened={menuOpened}
          />
        </div>
      </If>
      <CommentInfo comment={comment} />
      <CommentMain comment={comment} />
    </Box>
  );
}

export default CommentReplyItem;
