import React from "react";
import { SvgIcon } from "@material-ui/core";

function FireIcon(props: any) {
  return (
    <SvgIcon
      sx={{ width: "13px", height: "12px" }}
      viewBox="0 0 13 12"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_14708_71182)">
        <path
          d="M1.45974 5.85234C1.45974 5.85234 1.93384 6.54884 2.92749 7.13838C2.92749 7.13838 2.20784 1.0563 6.89682 0C5.69549 4.40909 8.42495 5.65252 9.4199 3.32311C11.0815 5.54149 9.81988 7.47691 9.81988 7.47691C10.5012 7.57507 11.0735 6.83074 11.0735 6.83074C11.0786 6.92243 11.0815 7.01475 11.0815 7.10773C11.0815 9.80965 8.89112 12 6.18918 12C3.48723 12 1.29688 9.80965 1.29688 7.1077C1.29688 6.67369 1.35369 6.25298 1.45974 5.85234Z"
          fill="#FF6536"
        />
        <path
          d="M11.0733 6.83074C11.0733 6.83074 10.501 7.57507 9.81969 7.47691C9.81969 7.47691 11.0813 5.54149 9.41971 3.32311C8.42476 5.65252 5.6953 4.40909 6.89664 0C6.6455 0.0565781 6.41 0.127664 6.18896 0.211359V12C8.89091 12 11.0813 9.80965 11.0813 7.1077C11.0813 7.01473 11.0784 6.92243 11.0733 6.83074Z"
          fill="#FF421D"
        />
        <path
          d="M4.04346 9.85442C4.04346 11.0394 5.00407 12 6.18902 12C7.37397 12 8.33458 11.0394 8.33458 9.85442C8.33458 9.22018 8.0594 8.65023 7.62189 8.25744C6.79235 9.38443 5.61021 7.67487 6.53149 6.42645C6.53149 6.42645 4.04346 6.73824 4.04346 9.85442Z"
          fill="#FBBF00"
        />
        <path
          d="M8.33453 9.85442C8.33453 9.22018 8.05935 8.65023 7.62184 8.25744C6.79229 9.38443 5.61015 7.67487 6.53143 6.42645C6.53143 6.42645 6.39625 6.44344 6.18896 6.50886V12C7.37392 12 8.33453 11.0394 8.33453 9.85442Z"
          fill="#FFA900"
        />
      </g>
      <defs>
        <clipPath id="clip0_14708_71182">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0.188965)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default FireIcon;
