import React, { useMemo } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  createStyles,
  makeStyles,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import {If} from 'react-if';

import renderPrice from "bos_common/src/components/Price";
import { getItemsEligibleForCoupon } from "bos_common/src/services/CouponUtils";

import { CouponValueType, Merchandise, Merchant, MerchantCoupon } from "../../services/models";
import { isKioskDevice, toFixed2, withFallbackPath } from "../../utils";
import eventTrackingService from "../../services/Tracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../../services/Tracking/events";
import { useAppSelector } from "../../redux/hooks";
import { getUser } from "../../redux/slice/auth/authSelector";
import { PromotionItemCTA } from "./MenuItemCTA";
import { getEventLabel } from "../../services/Tracking/EventsTracking";
import { RenderSoldOut } from "../common/MerchandiseHelper";

const useStylesForPromotionItemCard = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100%",

      "& .card-action-area": {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "row-reverse",
        padding: theme.spacing(1),

        "& .details": {
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          justifyContent: "space-between",
          marginBottom: theme.spacing(1),
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
          height: "100%",
          "& .itemRow": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginBottom: theme.spacing(1),
          },
        },

        "& .merchPhoto": {
          alignItems: "center",
          display: "flex",
          flexFlow: "column",
          flexShrink: 0,
          margin: theme.spacing(0, 3, 3, 0),
          position: 'relative',
          width: 120,
          height: 120,
          [theme.breakpoints.up("md")]: {
            width: 200,
            height: 200,
          },

          "& .cover": {
            height: '100%',
            objectFit: 'cover',
            borderRadius: theme.spacing(1),
          },
          "& .actionButton": {
            position: "absolute",
            bottom: 0,
            right: 0,
            transform: "translate(50%, 50%)",
            "& .MuiButtonBase-root": {
              border: 0,
              "&:hover": {
                background: theme.palette.common.white,
              },
            }
          }
        },
      },
    },
  })
);

/**
 * This method makes a lot of assumptions, not meant to be shared
 * @param subtotal This me
 * @param coupon
 * @returns
 */
 const getComboBasicDiscount = (subtotal: number, coupon: MerchantCoupon): number => {
  switch (coupon.valueType) {
    case CouponValueType.COMBO_PERCENTAGE:
      return toFixed2(subtotal * coupon.value);
    case CouponValueType.COMBO_ABSOLUTE:
      return Math.min(coupon.value || 0, subtotal);
    default:
      return 0;
  }
}

type ComboDealCardProps = {
  coupon: MerchantCoupon;
  merchandises: Merchandise[];
  merchant?: Merchant;
  isAvailable: boolean;
};

export const PromotionItemCard = (props: ComboDealCardProps): JSX.Element | null => {
  const { coupon, merchandises, merchant, isAvailable } = props;
  const affectedMerchandises = useMemo(() => getItemsEligibleForCoupon(merchandises, coupon), [merchandises, coupon]);
  const firstAffectedMerchandise = affectedMerchandises ? affectedMerchandises[0] : undefined;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isKioskModeEnabled = isKioskDevice();
  const classes = useStylesForPromotionItemCard();
  const user = useAppSelector(getUser);

  const getImageSource = () => {
    let url = undefined;
    if (firstAffectedMerchandise?.photos) {
      url = firstAffectedMerchandise?.photos[0]
    }
    if (coupon.photos) {
      url = coupon.photos[0];
    }

    return url;
  }

  const photoURL = getImageSource();
  const CardMediaEnhanced: any = useMemo(
    () => withFallbackPath(CardMedia, photoURL),
    [photoURL]
  );

  if (!affectedMerchandises || affectedMerchandises.length < 2) {
    return <></>;
  }

  const handleCardClick = () => {
    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.MERCHANT_MENU,
      action: EVENT_ACTIONS.CLICK_PROMOTION_CARD,
      data: {
        merchant: merchant,
        user,
      },
      label: getEventLabel(merchant ?? {} as Merchant),
    });
  }

  const itemPageLink = {
    pathname: `/${merchant?.username}/combo/${coupon.id}`,
    state: { from: window.location.pathname },
  };


  const total = toFixed2(affectedMerchandises?.reduce((sum, item) => sum + Number(item.price), 0) || 0);
  const discount = toFixed2(getComboBasicDiscount(total, coupon));


  return (
    <Card className={classes.root} elevation={3}>
      <CardActionArea
        className="card-action-area"
        component={RouterLink}
        to={itemPageLink}
        onClick={handleCardClick}
      >
        <div className="merchPhoto">
          <If condition={photoURL}>
            <CardMediaEnhanced
              className="cover"
              title={coupon.title}
              src={isMobile ? `${photoURL}_small` : `${photoURL}_medium`}
              component="img"
              alt="merch-img"
            />
          </If>

          <div className="actionButton">
            <PromotionItemCTA coupon={coupon} onClick={handleCardClick} />
          </div>
        </div>

        <div className="details">
          <Stack spacing={0.5} direction={"column"}>
            <Typography
              component="div"
              variant={isKioskModeEnabled ? "h6" : "body1"}
              fontWeight={'bold'}
              lineHeight={1.2}>
              {coupon.title}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary" component="div" gutterBottom className={classes.root}>
              {coupon.description}
            </Typography>
            <div>
              <If condition={!isAvailable}>
                <RenderSoldOut />
              </If>
            </div>
          </Stack>

          <div className="itemRow">
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={{ fontSize: '1rem', fontWeight: 700 }} color="secondary">{ `${renderPrice(total - discount)}` }</Typography>&nbsp;
              <Typography style={{ fontSize: '.75rem', fontWeight: 400, textDecoration: 'line-through' }} color="textSecondary">{`${renderPrice(total)}`}</Typography>&nbsp;
            </Box>
          </div>
        </div>
      </CardActionArea>
    </Card>
  );
};

