import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import UserMerchantVote from "../../../bos_common/src/types/UserMerchantVote";
import { VoteSliceState, VoteSliceStateEntities } from '../../../types/VoteSlice';

type VoteSliceCaseReducer = SliceCaseReducers<VoteSliceState>;

export const voteSlice = createSlice<VoteSliceState, VoteSliceCaseReducer, string>({
  name: 'vote',
  initialState: {
    loading: false,
    entities: {
      ids: [], 
      byId: {},
      voteCount: {},
      storesToVoteIds: [],
      votedStoreIds: [],
      voteRemaining: 0,
    }
  },
  reducers: {
    setLoadingVote: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      return {
        ...state,
        loading: true
      }
    },

    stopLoadingVote: (state) => {
      return {
        ...state,
        loading: false
      }
    },

    setUpdatingVote: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      return {
        ...state,
        updating: true
      }
    },

    stopUpdatingVote: (state) => {
      return {
        ...state,
        updating: false,
      }
    },

    updateVote: (state, action: PayloadAction<VoteSliceStateEntities>) => {
      const { payload } = action;

      return {
        ...state,
        loading: false,
        entities: {
          ...payload,
        }
      }
    },

    updateStoreToVoteIds: (state, action: PayloadAction<string[]>) => {
      const { payload } = action;
      const storesToVoteIds = [...state.entities.storesToVoteIds];

      payload.forEach((id) => {
        if (storesToVoteIds.indexOf(id) > -1) {
          return;
        }

        storesToVoteIds.push(id);
      });

      return {
        ...state,
        loading: false,
        entities: {
          ...state.entities, 
          storesToVoteIds: [...storesToVoteIds],
        }
      }
    },

    updateVotedStoreIds: (state, action: PayloadAction<string[]>) => {
      const { payload } = action;
      const votedStoreIds = [...state.entities.votedStoreIds];

      payload.forEach((id) => {
        if (votedStoreIds.indexOf(id) > -1) {
          return;
        }

        votedStoreIds.push(id);
      });

      return {
        ...state,
        loading: false,
        entities: {
          ...state.entities, 
          votedStoreIds: [...votedStoreIds],
        }
      }
    },

    updateVoteCount: (state, action: PayloadAction<{[key: string]: number}>) => {
      const { payload } = action;

      return {
        ...state,
        loading: false,
        entities: {
          ...state.entities, 
          voteCount: {
            ...state.entities.voteCount, 
            ...payload,
          },
        }
      }
    },

    voteCreated: (state, action: PayloadAction<UserMerchantVote>) => {
      const { merchant } = action.payload;
      const { entities } = state;
      const storesToVoteIds = [...entities.storesToVoteIds];
      const votedStoreIds = [...entities.votedStoreIds];
      const voteCount = {...entities.voteCount};
      const voteRemaining = entities.voteRemaining - 1;

      const merchantId = merchant as unknown as string;
      const indexToRemove = storesToVoteIds.indexOf(merchantId);
      votedStoreIds.push(merchantId);
      storesToVoteIds.splice(indexToRemove, 1);
      voteCount[merchantId] = 1;

      return {
        ...state,
        loading: false,
        entities: {
          ...state.entities,
          votedStoreIds,
          storesToVoteIds,
          voteCount,
          voteRemaining,
        }
      }
    },

    updateVoteRemaining: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          voteRemaining: action.payload,
        }
      }
    }
  },
});

// Action creators are generated for each case reducer function
export const { 
  setLoadingVote, 
  stopLoadingVote, 
  updateVote, 
  updateStoreToVoteIds,
  updateVotedStoreIds,
  updateVoteCount,
} = voteSlice.actions;

export default voteSlice.reducer;
