import React, { ChangeEvent, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from "react"
import {
  Toolbar,
  Typography,
  CardContent,
  LinearProgress,
  makeStyles,
  Theme,
  createStyles,
  CardMedia,
  Divider,
  Fab,
  Grid,
  SwipeableDrawer,
  OutlinedInput,
  Box,
  Stack,
  FormHelperText,
} from "@material-ui/core"
import assert from "assert"
import classNames from "classnames"
import { If } from "react-if"

import { UserContext } from "../../bos_common/src/context/UserContext"
import { ColoredPaper } from "../../bos_common/src/components/Papers"
import { isPickupASAP } from "../../bos_common/src/services/orderUtils"
import { LOCALSTORAGE_APP_KEYS } from "../../bos_common/src/constants"
import { repeatAudio } from "../../bos_common/src"

import { Merchant, Order, User } from "../../services/models"
import RenderWaitTime from "../common/WaitTime"
import { isEmailValid, isEmptyOrNil, isNotificationSoundEnabled } from "../../utils"
import OneMarketPointString from "../common/OneMarketPointString"
import AuthorizeUser from "../common/AuthorizeUser"

import PromotionIllustration from "../../assets/images/congratulations-rewards.svg";
import AffiliateUserBannerSrc from '../../assets/images/banner/affiliate-user-banner.svg'
import WarningIllustration from '../../assets/images/warning-error.svg';
import OrderInProgress from '../../assets/images/order-in-progress.svg';
import { getMerchantAffiliateLink } from "bos_common/src/services/urls"
import LinkWithCopyButton from "../common/LinkWithCopyButton"
import GemIcon from "../../assets/icons/GemIcon"

export enum SignupEntrance {
  FAVORTIE = 'favorite',
  EARNED_POINTS = 'earned-points',
  SAVE_ORDER = 'save-order',
  NEW_ORDER = 'new-order',
  PLACE_ORDER = 'place-order',
  USER_LOGIN = 'user_login',
  NEW_DISCOVERY_SIGNUP = 'new-discovery-signup',
  REVIEWS = 'reviews',
  CUSTOM = 'custom',
  RESERVATION = 'reservation',
  AFFILIATE_USER = 'affiliate-user',
  REFERRAL_GIFT_DRAWER = 'referral-gift',
  EXISTING_USER_LOGIN = 'existing-user-login',
  PRE_AUTH = 'pre_auth',
  DEFAULT = 'default',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      '& .MuiPaper-root': {
        borderRadius: "20px 20px 0 0",

        '&:before': {
          content: "''",
          position: "absolute",
          top: -15,
          left: "50%",
          transform: "translateX(-50%)",
          pointerEvents: "none",
          width: "130px",
          height: "6px",
          borderRadius: "50px",
          background: "rgba(255, 255, 255, 0.5)"
        },

        '& .toolbarWrapper': {
          padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
          borderRadius: "20px 20px 0 0 !important", // to override inline style
        },
      },

      '& .toolbar': {
        '& .drawerTitle': {
          width: "100%",
          textAlign: "center",
          color: "#364245",
          lineHeight: 1.2,
        },
      },

      '& .signupImageWrapper': {
        textAlign: "center",
        paddingBottom: "10px",
      },

      '& .signupImage': {
        height: 200,
        objectFit: "cover",
        margin: "auto",
      },

      '& .content': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}, 0`,
        '& .drawerCTA': {
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: "center",
          width: '100%',
          '& .discovery-rewards-phone': {
            '& .signUpActionRow': {
              width: '80vw',
              "& input[type=number]::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
            }
          },
          '& .discovery-rewards-info.signUpActionRow': {
            width: '80vw'
          }
        },
      },
    },
  }),
);

export const SignupCTA = (props: { redirectTo?: string, title?: string, showActionBtn?: boolean }) => {
  return (
    <>
      <Typography gutterBottom variant="subtitle1" color="textSecondary" component="div" textAlign="center" lineHeight={1.2}>
        {props.title ? props.title : "Enter your phone number to get started"}
      </Typography>
      <AuthorizeUser redirectTo={props.redirectTo} showActionBtn={props.showActionBtn} />
    </>
  );
}

const NameEmailPhoneSignUp = (props: { redirectTo?: string, showActionBtn?: boolean, showEmail?: boolean, actionBtnText?: string }) => {
  const [email, setEmail] = useState(sessionStorage.getItem(LOCALSTORAGE_APP_KEYS.USER_EMAIL) || '');
  const [displayName, setDisplayName] = useState(sessionStorage.getItem(LOCALSTORAGE_APP_KEYS.USER_DISPLAY_NAME) || '');
  const [errors, setErrors] = useState({ email: '', displayName: '' });
  const { redirectTo, showActionBtn, showEmail = true, actionBtnText = null } = props;

  const validate = useCallback(() => {
    const errors = {
      email: '',
      displayName: ''
    }

    if (showEmail) {
      if (!email) errors.email = 'Email is required'
      else if (!isEmailValid(email)) errors.email = 'Invalid email'
    }

    if (!displayName) errors.displayName = 'Name is required'

    setErrors(errors)

    return !isEmptyOrNil(errors.email) && !isEmptyOrNil(displayName);
  }, [email, displayName])

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value.trim();
    setEmail(email)
    sessionStorage.setItem(LOCALSTORAGE_APP_KEYS.USER_EMAIL, email)
  }
  const handleDisplayNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const displayName = e.target.value.trim();
    setDisplayName(displayName)
    sessionStorage.setItem(LOCALSTORAGE_APP_KEYS.USER_DISPLAY_NAME, displayName)
  }

  const isEmailInvalid = showEmail && (!email || !isEmptyOrNil(errors.email))

  return (
    <Stack spacing={1} direction={'column'}>
      <Box sx={{ width: '100%' }}>
        <OutlinedInput
          value={displayName}
          size="small"
          onBlur={validate}
          onChange={handleDisplayNameChange}
          placeholder='Display name'
          fullWidth
          required
          error={!isEmptyOrNil(errors.displayName)}
        />
        <FormHelperText sx={{ color: 'red' }}>{errors.displayName}</FormHelperText>
      </Box>

      <If condition={showEmail}>
        <Box sx={{ width: '100%' }}>
          <OutlinedInput
            value={email}
            size="small"
            onBlur={validate}
            onChange={handleEmailChange}
            placeholder='email'
            required
            fullWidth
            error={!isEmptyOrNil(errors.email)}
          />
          <FormHelperText sx={{ color: 'red' }}>{errors.email}</FormHelperText>
        </Box>
      </If>

      <Box>
        <AuthorizeUser
          redirectTo={redirectTo}
          showActionBtn={showActionBtn}
          isDisabled={isEmailInvalid || !displayName}
          actionBtnText={actionBtnText ?? 'Sign Up'}
          useErrorAlert={false}
        />
      </Box>
    </Stack>
  );
}

const UserAffiliateLink = ({ merchant, user }: { merchant: Merchant | undefined, user: User | undefined }) => {
  if (!merchant || !user) return null;

  const affiliateLink = getMerchantAffiliateLink(merchant, user || {} as User)

  if (isEmptyOrNil(affiliateLink)) return null;

  return (
    <div>
      <Typography gutterBottom variant="body1" align="center">
        Include an affiliate Link in <br />your social media post
      </Typography>

      <LinkWithCopyButton url={affiliateLink} shorten={true} />
    </div>
  )
}

const DiscoveryRewardsSignUp = (props: { title: string, redirectTo?: string, showActionBtn?: boolean }) => {
  const [email, setEmail] = useState('')
  const [zipcode, setZipcode] = useState('');
  const [errors, setErrors] = useState({ email: '', zipcode: '' });

  const validate = useCallback(() => {
    const errors = {
      email: '',
      zipcode: ''
    }

    if (!email) errors.email = 'Email is required'
    else if (!isEmailValid(email)) errors.email = 'Invalid email'

    setErrors(errors)

    return !isEmptyOrNil(errors.email)
  }, [email, zipcode])

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value.trim();
    setEmail(email)
    sessionStorage.setItem(LOCALSTORAGE_APP_KEYS.USER_EMAIL, email)
  }
  const handleZipcodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const zipcode = e.target.value.trim();
    setZipcode(zipcode)
    sessionStorage.setItem(LOCALSTORAGE_APP_KEYS.USER_ADDRESS, zipcode)
  }

  return (
    <>
      <Typography gutterBottom variant="subtitle1" color="textSecondary" component="div" textAlign="center" lineHeight={1.2}>
        {props.title}
      </Typography>

      <Box className="discovery-rewards-info signUpActionRow">
        <OutlinedInput
          id={'zipcode'}
          value={zipcode}
          size="small"
          onChange={handleZipcodeChange}
          placeholder='zip code (optional)'
          fullWidth
          type="number"
          error={!isEmptyOrNil(errors.zipcode)}
        />
        <FormHelperText sx={{ color: 'red' }}>{errors.zipcode}</FormHelperText>
      </Box>

      <Box className="discovery-rewards-info signUpActionRow">
        <OutlinedInput
          id={'email'}
          value={email}
          size="small"
          onBlur={validate}
          onChange={handleEmailChange}
          placeholder='email (required)'
          required
          fullWidth
          error={!isEmptyOrNil(errors.email)}
        />
        <FormHelperText sx={{ color: 'red' }}>{errors.email}</FormHelperText>
      </Box>
      <Box className="discovery-rewards-phone">
        <AuthorizeUser
          redirectTo={props.redirectTo}
          showActionBtn={props.showActionBtn}
          isDisabled={!email || !isEmptyOrNil(errors.email)}
          actionBtnText='Sign Up'
          useErrorAlert={false}
        />
      </Box>
    </>
  );
}

const LeaveOrSignupCTA = (props: { redirectTo?: string, onLeave?: () => void }) => {
  return (
    <>
      <SignupCTA redirectTo={props.redirectTo} />
      <Divider style={{ marginTop: '24px', marginBottom: '16px' }} />
      <Fab color="primary" variant="extended" onClick={props.onLeave}>
        Leave anyways
      </Fab>
    </>
  )
}

interface SignupDrawerConfig {
  title: ReactElement | string,
  imageSrc: string | undefined,
  CTA?: ReactElement
}

const SignupDrawer = (props: {
  open: boolean,
  setOpen: (open: boolean) => void,
  entrance: SignupEntrance,
  onCTA?: () => void,
  order?: Order,
  merchant?: Merchant,
  forceShow?: boolean,
  redirectAfterSignup?: string,
  className?: string,
  configOverride?: SignupDrawerConfig,
}): JSX.Element | null => {
  const classes = useStyles()
  const { user, authenticating } = useContext(UserContext)

  const { entrance, open, setOpen, forceShow, order, onCTA, redirectAfterSignup, merchant, className, configOverride = undefined } = props

  const playNotificationSound = isNotificationSoundEnabled(user);
  const noShow = (user || authenticating) && (!forceShow)

  useEffect(() => {
    if (entrance === SignupEntrance.EARNED_POINTS && open && !noShow) {
      const notifAudio = document.getElementById('app_earn_1m_points_audio') as HTMLAudioElement;
      notifAudio && playNotificationSound && repeatAudio(notifAudio, 1)
    }
  }, [])

  const RenderEarnedPointsTitle = () => {
    if (!order) return null;
    return (
      <Box alignItems={"center"}>
        Congratulations, you have earned {order.oneMarketPoints}&nbsp;<GemIcon sx={{ width: 16, height: 16 }} />&nbsp;
        <OneMarketPointString />&nbsp;towards your next purchase. Sign up to claim
      </Box>
    );
  }

  const RenderUserLoginSignupTitle = () => {
    return <>
      <Typography variant="h5" color="primary" sx={{ fontWeight: 600 }}>Buy Local, Get Rewards!</Typography>
      <Typography variant="subtitle2" color="textSecondary">
        {'Sign up or login to your One Market account to support your local businesses & get rewards!'}
      </Typography>
    </>
  }

  const getConfig = (): SignupDrawerConfig => {
    switch (entrance) {
      case SignupEntrance.FAVORTIE:
        return {
          title: 'Sign up to favorite stores',
          imageSrc: PromotionIllustration,
          CTA: <SignupCTA redirectTo={`${location.pathname}?favorite=1`} />
        }
      case SignupEntrance.USER_LOGIN:
        return {
          title: <RenderUserLoginSignupTitle />,
          imageSrc: '../delivery-guy-handing-box.png',
          CTA: <AuthorizeUser showActionBtn actionBtnText="Login" />
        }
      case SignupEntrance.EARNED_POINTS:
        assert(order && order.oneMarketPoints > 0)
        return {
          title: <RenderEarnedPointsTitle />,
          imageSrc: PromotionIllustration,
          CTA: <SignupCTA redirectTo={redirectAfterSignup} />
        }
      case SignupEntrance.SAVE_ORDER:
        return {
          title: 'Keep link to your order?',
          imageSrc: WarningIllustration,
          CTA: <LeaveOrSignupCTA redirectTo={redirectAfterSignup} onLeave={onCTA} />
        }
      case SignupEntrance.NEW_ORDER:
        return {
          title: 'Order received!',
          imageSrc: PromotionIllustration,
          CTA: (order && isPickupASAP(order) ? <RenderWaitTime order={order} /> : undefined)
        }
      case SignupEntrance.PLACE_ORDER:
        return {
          title: 'Sign up to place your order',
          imageSrc: OrderInProgress,
          CTA: <NameEmailPhoneSignUp showActionBtn redirectTo={"/place-open-check-order"} showEmail={false} />
        }
      case SignupEntrance.PRE_AUTH:
        return {
          title: 'Sign up to place your order',
          imageSrc: OrderInProgress,
          CTA: <NameEmailPhoneSignUp showActionBtn redirectTo={"/checkout-preauth"} showEmail={false} />
        }
      case SignupEntrance.NEW_DISCOVERY_SIGNUP:
        return {
          title: <><span>$5 Off</span> your next order</>,
          imageSrc: '../discount-dollars-visual.svg',
          CTA: <DiscoveryRewardsSignUp title="Create a One Market account and claim your reward." showActionBtn redirectTo={redirectAfterSignup} />
        }
      case SignupEntrance.REVIEWS:
        return {
          title: merchant ? `Sign up to review your order at ${merchant.officialName} and earn rewards` : 'Sign up to review your order and earn rewards',
          imageSrc: OrderInProgress,
          CTA: <NameEmailPhoneSignUp redirectTo={redirectAfterSignup} showActionBtn actionBtnText="Continue" />
        }
      case SignupEntrance.RESERVATION:
        return {
          title: 'Sign up or login to reserve',
          imageSrc: undefined,
          CTA: <NameEmailPhoneSignUp showActionBtn redirectTo={merchant && (`${merchant.username}/reserve#reserve`)} />
        }
      case SignupEntrance.AFFILIATE_USER:
        return {
          title: 'Share and Earn',
          imageSrc: AffiliateUserBannerSrc,
          CTA: <UserAffiliateLink merchant={merchant} user={user} />
        }
      case SignupEntrance.EXISTING_USER_LOGIN:
        return {
          title: "Login to your One Market account",
          imageSrc: '../delivery-guy-handing-box.png',
          CTA: <AuthorizeUser showActionBtn actionBtnText="Continue" />
        }
      case SignupEntrance.CUSTOM:
        return {
          title: 'Sign up to collect points, save orders, and favorite shops',
          imageSrc: OrderInProgress,
          CTA: <SignupCTA redirectTo={redirectAfterSignup} />
        }
      default:
        return {
          title: 'Sign up to continue',
          imageSrc: PromotionIllustration,
          CTA: <AuthorizeUser showActionBtn actionBtnText="Continue" />
        }
    }
  }
  const config = configOverride || useMemo(() => getConfig(), [entrance, order, merchant]);

  if (noShow) {
    return null;
  }

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event?.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpen(open)
  }

  const drawerClassNames = classNames(classes.drawer, {
    [className!]: !isEmptyOrNil(className)
  })

  return (
    <SwipeableDrawer
      variant="temporary"
      ModalProps={{
        keepMounted: false,
      }}
      anchor={'bottom'}
      open={open}
      onOpen={toggleDrawer(true)}
      onClose={toggleDrawer(false)}
      className={drawerClassNames}
    >
      <ColoredPaper
        role="presentation"
        className="toolbarWrapper"
      >
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <If condition={!!config.imageSrc}>
              <CardMedia className="signupImageWrapper" title={"sign up to 1m.app"}>
                <img src={config.imageSrc} className="signupImage" />
              </CardMedia>
            </If>

            <Toolbar className="toolbar">
              <Typography variant="h6" className="drawerTitle" component="div">
                {config.title}
              </Typography>
            </Toolbar>

            <CardContent className='content'>
              {authenticating && <LinearProgress />}
              <If condition={!isEmptyOrNil(config?.CTA)}>
                <div className="drawerCTA">
                  {config.CTA}
                </div>
              </If>
            </CardContent>
          </Grid>
        </Grid>
      </ColoredPaper>
    </SwipeableDrawer>
  )
}

export default SignupDrawer