import React, { useContext, useEffect, useState } from "react";

// src
import { useFunctionOnInterval } from "../../bos_common/src/hooks";
import { isOverInventoryLimit } from "../../bos_common/src/services/merchandiseUtils";
import { MERCHANT_DATA_FETCH_INTERVAL } from "../../config/constants";
import { isEmptyOrNil, isMerchandiseAvailable } from "../../utils";
import { AppContext, CartItemType, CartType } from "../AppContext";
import { PointsRedemption, defaultRedeemPointsInfo, ShoppingCartContext } from "./ShoppingCartContext"

const ShoppingCartContextProvider = (props: {
  children: React.ReactNode,
}): React.ReactElement => {

  const [pointsRedeemedInfo, setPointsRedemptionInfo] = useState<PointsRedemption>(defaultRedeemPointsInfo);
  const [appliedRedemption, setApplyRedemption] = useState<boolean>(false);
  const [soldoutItems, setSoldoutItems] = useState<CartItemType[]>([]);
  const [ shoppingBagError, setShoppingBagError ] = useState<boolean>(false);
  const [ pickupOptionsShown, showPickupOptions ] = useState<boolean>(false);
  const { cart, redeemItems, reFetchMerchandisesForCart, removeItemFromCart, cartType } = useContext(AppContext)

  useFunctionOnInterval(reFetchMerchandisesForCart, MERCHANT_DATA_FETCH_INTERVAL, true, true)

  // To find the invalid items in the carts list
  useEffect(() => {
    if (!isEmptyOrNil(cart) && cartType !== CartType.FROM_OPEN_CHECK) {
      const cartItemQuantities: { [key: string]: number; } = {}
      // Finding the lineitems in an open check order
      const invalidItems = cart.filter((item: CartItemType) => {
        cartItemQuantities[item.id] = item.quantity + (cartItemQuantities[item.id] || 0)
        const _isOverStockLimit = isOverInventoryLimit(item, cartItemQuantities[item.id])
        return (_isOverStockLimit || !isMerchandiseAvailable(item));
      })
      setSoldoutItems(invalidItems);
    } else {
      setSoldoutItems([]);
    }
  }, [cart, cartType])

  useEffect(() => {
    if (appliedRedemption) {
      redeemItems(pointsRedeemedInfo.items)
    }
  }, [pointsRedeemedInfo])

  const clearSoldOutItems = () => {
    soldoutItems.forEach((item: CartItemType) => {
      if (item.inventory?.dailyCurrentStock && item.inventory.dailyCurrentStock > 0) {
        for (let i = item.quantity; i > (item.inventory.dailyCurrentStock); i--)
          removeItemFromCart({ merchandiseId: item.id, modifiersSignature: item.modifiersSignature })
      } else if (item.inventory?.dailyCurrentStock === 0) {
        removeItemFromCart({ merchandiseId: item.id, modifiersSignature: item.modifiersSignature, clearThisItemFromCart: true })
      }
    })
  }

  return (
    <ShoppingCartContext.Provider value={{
      pointsRedeemedInfo,
      setPointsRedemptionInfo,
      appliedRedemption,
      setApplyRedemption,
      soldoutItems,
      clearSoldOutItems,
      shoppingBagError,
      setShoppingBagError,
      pickupOptionsShown,
      showPickupOptions,
    }}>
      {props.children}
    </ShoppingCartContext.Provider>
  );
}

export default ShoppingCartContextProvider;