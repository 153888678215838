import React from 'react';
import BottomDrawer from 'bos_common/src/components/BottomDrawer';
import RewardsEducationInfo from './RewardsEducationInfo';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setShowRewardsEducationDrawer } from '../../redux/slice/merchant/action';
import { getShowRewardsEducationDrawer } from '../../redux/slice/merchant/merchantSelector';


const RewardsEducationDrawer = (): JSX.Element => {
  const open = useAppSelector(getShowRewardsEducationDrawer);
  const dispatch = useAppDispatch()

  const setOpen = (open: boolean) => {
    dispatch(setShowRewardsEducationDrawer(open))
  }

  return (
    <BottomDrawer
      open={open}
      setOpen={setOpen}
      keepMounted={false}
      fullWidth
    >
      <RewardsEducationInfo openOnDrawer />
    </BottomDrawer>
  )
}

export default RewardsEducationDrawer;