import React from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Stack from "@material-ui/core/Stack";
import Fab from "@material-ui/core/Fab";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { StoryComment } from "bos_common/src/types/MerchantStoryType";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getUser } from "../../redux/slice/auth/authSelector";
import { commentMerchantStory, removeMerchantStoryComment } from "../../redux/slice/merchantStory/merchantStoryAction";
import { getMerchantStoryComments, getMerchantStoryLoadingStatus, getMerchantStoryUpdatingStatus } from "../../redux/slice/merchantStory/merchantStorySelector";
import { CommentItem } from "./CommentItem";
import Storage from "../../services/storage";
import { showSignUpDrawer } from "../../redux/slice/auth/authActions";
import { SignupEntrance } from "../User/SignupDrawer";

const COMMENT_LEFT = 'COMMENT_LEFT';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    "& .form-container-box": {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(2),
      position: 'relative',
      marginTop: '4px'
    },

    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        borderRadius: theme.spacing(4),
      }
    },

    "& .submit-comment-btn": {
      position: 'absolute',
      right: '6px',
      top: '4px'
    },

    "& .comment-content-text > .MuiOutlinedInput-root > .MuiOutlinedInput-input": {
      paddingRight: theme.spacing(8)
    }
  }
}));

export interface CommentListProps {
  merchantUsername: string,
  storyId: number;
}

export const CommentList = (props: Readonly<CommentListProps>): JSX.Element => {
  const { merchantUsername, storyId } = props;
  const classes = useStyles();
  const reduxDispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const comments = useAppSelector(getMerchantStoryComments)(merchantUsername, Number(storyId));
  const loading = useAppSelector(getMerchantStoryLoadingStatus);
  const updating = useAppSelector(getMerchantStoryUpdatingStatus);
  const [requiredError, setRequiredError] = React.useState<boolean>(false);

  React.useEffect(() => {
    const dbStorage = new Storage();
    dbStorage.get(COMMENT_LEFT)
      .then((res) => {
        if (res) {
          const inputEl = (document.getElementById('comment_content_text') as HTMLInputElement);
          inputEl.value = res as string;
        }

        dbStorage.remove(COMMENT_LEFT);
      });
  }, []);

  const handleDeleteComment = (comment: StoryComment) => {
    reduxDispatch(removeMerchantStoryComment(comment));
  }

  const handleReply = (comment: Partial<StoryComment>) => {
    reduxDispatch(commentMerchantStory({
      merchantStoryId: Number(storyId),
      content: comment.content,
      replyToCommentId: comment.replyToCommentId,
    }));
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const content = e.target[0].value.trim();

    if (!user && content) {
      const dbStorage = new Storage();
      await dbStorage.save<string>(COMMENT_LEFT, content);
      reduxDispatch(showSignUpDrawer({
        drawerShown: true,
        signupEntrance: SignupEntrance.DEFAULT,
      }));
      return;
    }

    if (!content) {
      e.target[0].value = '';
      return setRequiredError(true);
    }

    await reduxDispatch(commentMerchantStory({
      merchantStoryId: Number(storyId),
      content,
    }));

    e.target[0].value = '';
    setRequiredError(false);
  }

  return (
    <Box className={classes.root}>
      <form onSubmit={handleSubmit}>
        <div className="form-container-box">
          <TextField
            id="comment_content_text"
            className="comment-content-text"
            placeholder="Comment..."
            required
            error={requiredError}
            disabled={loading || updating}
          />
          <Fab type="submit" color="primary" variant="extended" className="submit-comment-btn" disabled={loading || updating}>
            <ArrowForwardIcon />
          </Fab>
        </div>
      </form>
      <Stack>
        {
          comments?.map((item) => {
            return (
              <CommentItem
                comment={item}
                user={user}
                onReply={handleReply}
                onDelete={handleDeleteComment}
                key={`comment_${item.id}`} />
            )
          })
        }
      </Stack>
    </Box>
  );
}

export default CommentList;
