

import React from 'react'
import { SvgIcon } from "@material-ui/core";

function ItalianIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <g clipPath="url(#clip0_8881_45281)">
        <path d="M14.607 10.53a6.598 6.598 0 00-1.979-4.106 6.615 6.615 0 00-2.588-1.56c.308-.316.501-.775.559-1.329.04-.391.002-.698-.003-.732l-.047-.354-.354-.048a3.336 3.336 0 00-.733-.003c-.536.056-.983.24-1.297.53a2.417 2.417 0 00-.489-.724C7.251 1.778 6.62 1.51 5.85 1.43c-.555-.058-.998 0-1.016.003l-.354.048-.048.354c-.003.019-.06.461-.003 1.016.08.77.347 1.401.772 1.826.117.117.25.222.397.314a6.625 6.625 0 00-2.226 1.432 6.599 6.599 0 00-1.979 4.106H0v1.744c0 1.28 1.041 2.32 2.32 2.32h11.36c1.279 0 2.32-1.04 2.32-2.32V10.53h-1.393zm-6.11-2.792a6.572 6.572 0 00-.306.564 2.878 2.878 0 00-2.158.768 2.883 2.883 0 00-.847 1.46H4.23a3.81 3.81 0 011.12-2.104 3.807 3.807 0 013.352-1.004c-.07.104-.14.209-.205.316zM7.824 9.24c-.128.419-.215.85-.258 1.289H6.162c.253-.704.9-1.22 1.662-1.29zm1.474-1.016a5.743 5.743 0 011.79-1.836c.28.181.545.388.793.619a4.777 4.777 0 00-2.427 3.522H8.51a5.69 5.69 0 01.79-2.305zm3.236-.498c.194.252.364.519.511.797a2.904 2.904 0 00-1.686 2.006h-.956a3.819 3.819 0 012.131-2.803zm.88 1.66c.121.368.205.751.25 1.143h-1.33c.184-.512.576-.93 1.08-1.143zM9.676 3.32c-.018.301-.093.674-.313.894-.22.22-.59.295-.89.313v-.055c.023-.29.102-.632.309-.84.22-.22.593-.295.894-.312zm-4.332-.977c.46 0 1.22.075 1.668.522.424.424.515 1.122.523 1.585l-.004.087c-.46 0-1.22-.076-1.668-.523-.451-.45-.524-1.213-.523-1.67h.004zm-1.32 4.752A5.671 5.671 0 018 5.483c.749 0 1.477.144 2.151.419a6.69 6.69 0 00-.795.715A4.742 4.742 0 008 6.42a4.74 4.74 0 00-3.299 1.33 4.741 4.741 0 00-1.42 2.78h-.945a5.666 5.666 0 011.69-3.434zm11.037 5.177c0 .763-.62 1.384-1.383 1.384H2.321c-.763 0-1.384-.62-1.384-1.384v-.806h14.126v.806z" />
      </g>
      <defs>
        <clipPath id="clip0_8881_45281">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>    </SvgIcon>
  );
}

export default ItalianIcon