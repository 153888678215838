import React from 'react'
import { SvgIcon } from "@material-ui/core";

function StoreFrontIcon(props: any) {
  return (
    <SvgIcon sx={{ width: 16, height: 16, color: "white" }} viewBox="0 0 16 16" fill="none" {...props} >
      <path d="M14.6659 5.3335C14.6659 5.21251 14.633 5.0938 14.5705 4.99016L12.7645 1.98016C12.6455 1.78339 12.4779 1.62054 12.2778 1.50729C12.0777 1.39404 11.8518 1.33419 11.6219 1.3335H4.37652C3.91119 1.3335 3.47319 1.5815 3.23385 1.98083L1.42785 4.99016C1.36542 5.0938 1.33246 5.21251 1.33252 5.3335C1.33252 6.0035 1.58985 6.6095 1.99919 7.07883V13.3335C1.99919 13.5103 2.06942 13.6799 2.19445 13.8049C2.31947 13.9299 2.48904 14.0002 2.66585 14.0002H7.99919C8.176 14.0002 8.34557 13.9299 8.47059 13.8049C8.59562 13.6799 8.66585 13.5103 8.66585 13.3335V10.0002H11.3325V13.3335C11.3325 13.5103 11.4028 13.6799 11.5278 13.8049C11.6528 13.9299 11.8224 14.0002 11.9992 14.0002H13.3325C13.5093 14.0002 13.6789 13.9299 13.8039 13.8049C13.9289 13.6799 13.9992 13.5103 13.9992 13.3335V7.07883C14.4085 6.6095 14.6659 6.0035 14.6659 5.3335V5.3335ZM13.3219 5.50083C13.2808 5.82277 13.1239 6.11873 12.8805 6.33334C12.637 6.54796 12.3237 6.66651 11.9992 6.66683C11.2639 6.66683 10.6659 6.06883 10.6659 5.3335C10.6659 5.28816 10.6492 5.24816 10.6399 5.2055L10.6532 5.20283L10.1459 2.66683H11.6219L13.3219 5.50083V5.50083ZM6.67052 5.37683L7.21185 2.66683H8.78585L9.32785 5.37683C9.30452 6.09216 8.71919 6.66683 7.99919 6.66683C7.27919 6.66683 6.69385 6.09216 6.67052 5.37683V5.37683ZM4.37652 2.66683H5.85252L5.34585 5.20283L5.35919 5.2055C5.34919 5.24816 5.33252 5.28816 5.33252 5.3335C5.33252 6.06883 4.73452 6.66683 3.99919 6.66683C3.67465 6.66651 3.36134 6.54796 3.11789 6.33334C2.87444 6.11873 2.71753 5.82277 2.67652 5.50083L4.37652 2.66683ZM6.66585 10.6668H3.99919V8.66683H6.66585V10.6668Z" fill="#E34D28" />
    </SvgIcon>
  );
}

export default StoreFrontIcon

