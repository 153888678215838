import { createStyles, Fab, makeStyles, Theme, ToggleButton, ToggleButtonGroup, Tooltip, Typography, useTheme } from '@material-ui/core';
import { ChevronRight, InfoOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Else, If, Then } from 'react-if';

import YelpLogo from "../../assets/icons/yelp-logo.svg";
import GoogleLogo from "../../assets/icons/google_icon.svg";
import OneMarketLogo from "../../assets/icons/onemarket-logo.svg";
import eventTrackingService from '../../services/Tracking';
import { EVENT_ACTIONS, EVENT_CATEGORIES } from '../../services/Tracking/events';
import { Merchant } from 'bos_common/src/types/MerchantType';
import { useAppSelector } from '../../redux/hooks';
import { getUser } from '../../redux/slice/auth/authSelector';
import ReviewsOneMarketStats from './ReviewsOneMarketStats';
import ReviewsSummaryButtonGroups from './ReviewsSummaryButtonGroup';
import { getEventLabel } from '../../services/Tracking/EventsTracking';


type ReviewsSummaryItemsProps = {
    googleItems: any,
    googleRating: number,
    googleTotal: number,
    googleLink: string,
    yelpItems: any,
    yelpRating: number,
    yelpTotal: number,
    yelpLink: string,
    oneMarketItems: any,
    oneMarketRating: number,
    oneMarketTotal: number,
    taste: number,
    value: number,
    portion: number,
    merchant: Merchant
};

type ReviewSource = "Google" | "Yelp" | "1m";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        reviewsSummaryItemsRoot: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',

            "& .review-card-item .cardDescription": {
                height: '100%'
            },
            "& .review-card-item .review-card-wrapper": {
                border: 'none',
                borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
                borderRadius: 0
            },
        }
    })
);

type ReviewToggleButton = {
    items: any,
    icon: string,
    link: string,
    text: string
};

const ReviewsSummaryItems = (props: ReviewsSummaryItemsProps) => {
    const { googleItems, googleRating, googleTotal,
            googleLink, yelpItems, yelpRating, yelpTotal,
            yelpLink, oneMarketItems, oneMarketRating, oneMarketTotal, merchant,
            taste, value, portion
    } = props;

    const classes = useStyles();
    const theme = useTheme();

    const hasOneMarketReviews = oneMarketItems?.length > 0;
    const hasGoogleReviews = googleItems?.length > 0;
    const hasYelpReviews = yelpItems?.length > 0;

    const [{ items, icon, link, text}, setReview] = useState<ReviewToggleButton>({
        items: [],
        icon: "",
        link: "",
        text: ""
    });

    const [selectedReviewSource, setSelectedReviewSource] = useState<ReviewSource>(hasOneMarketReviews ? "1m" : hasYelpReviews ? "Yelp" : "Google");

    useEffect(() => {
        updateReview(selectedReviewSource);
    }, []);

    const user = useAppSelector(getUser);

    const updateReview = (reviewSource: ReviewSource) => {
        switch (reviewSource.toLowerCase()) {
            case "yelp":
                setReview({
                    items: yelpItems,
                    icon: YelpLogo,
                    link: yelpLink,
                    text: "Reviews"
                });
                break;
            case "google":
                setReview({
                    items: googleItems,
                    icon: GoogleLogo,
                    link: googleLink,
                    text: "Reviews"
                });
                break;
            case "1m":
                setReview({
                    items: oneMarketItems,
                    icon: OneMarketLogo,
                    link: "1m.app",
                    text: "Verified Reviews"
                });
                break;
        }
    }

    const handleReviewSourceChange = (
        event: React.MouseEvent<HTMLElement>,
        newReviewSource: ReviewSource | null,
    ) => {
        if (newReviewSource !== null) {
            setSelectedReviewSource(newReviewSource);
            updateReview(newReviewSource);
        }
    };

    const handleOpenWindow = () => {
        eventTrackingService.captureEvent({
            category: EVENT_CATEGORIES.MERCHANT_STORE_FRONT,
            action: EVENT_ACTIONS.CLICK_REVIEW_ITEMS,
            label: getEventLabel(merchant),
            data: {
                merchant: merchant,
                user,
            }
        });
        window.open(link);
    };

    const buttonGroupProps = {
        selectedReviewSource,
        handleReviewSourceChange,
        hasOneMarketReviews,
        hasGoogleReviews,
        hasYelpReviews,
        oneMarketTotal,
        oneMarketRating,
        googleTotal,
        googleRating,
        yelpTotal,
        yelpRating
    };

    return (
        <div className={classes.reviewsSummaryItemsRoot}>
            <ReviewsSummaryButtonGroups {...buttonGroupProps}/>           
            <Typography fontWeight="600" fontSize="14px" color={theme.palette.secondary.light}>
                {text}                
            </Typography>
            <Typography component="div">
                <If condition={selectedReviewSource === '1m'}>
                    <ReviewsOneMarketStats taste={taste} portion={portion} value={value} />
                </If>
                {items.map((item: any, index: number) => {
                return (
                    <div className="review-card-item" key={`${index}-${item}`}>{item}</div>
                );
                })}
            </Typography>
            <Fab
                onClick={() => handleOpenWindow()}
                sx={{margin: theme.spacing(2, 0), textTransform: 'none'}}
                color="default"
                variant="extended"
                size="large"
                >
                <img className="logo" src={icon} /> &nbsp;&nbsp;
                See More on {selectedReviewSource} &nbsp;&nbsp;<ChevronRight />
            </Fab>
        </div>
    );
};

export default ReviewsSummaryItems;
