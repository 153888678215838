import { AxiosRequestConfig } from "axios";
import { pathOr } from "ramda";

// src
import axios from "../bos_common/src/services/backendAxios";
import { User } from "./models";
import { isUserAffiliate } from "./userUtils";

export const checkAffiliateRewardsAtMerchant = async (merchantId: string, user?: User, request?: AxiosRequestConfig): Promise<boolean> => {

  if (!isUserAffiliate(user)) {
    return false;
  }

  const response = await axios({
    url: `/users/merchant-visit`,
    method: 'GET',
    headers: request?.headers,
    params: { merchantId }
  });

  const visitCount = pathOr(0, ['data', 'visitCount'], response)

  return visitCount <= 1;
}

export const deleteUserAccount = (request?: AxiosRequestConfig): Promise<unknown> => {
  return axios({
    url: `/users`,
    method: 'DELETE',
    headers: (request && request.headers) ? request.headers : {},
  });
}
