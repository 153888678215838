import React, { useContext } from "react";
import { useMemo } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardMedia,
  useMediaQuery,
  Typography,
  Stack,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { Star as StarIcon } from "@material-ui/icons";
import { If, Then, Else } from "react-if";
import { filter, head, pathOr, pipe, propEq, reduce, defaultTo } from "ramda";

import MerchandiseDescription from "../../bos_common/src/components/Merchandise/MerchandiseDescription";
import { getCouponForMerchandise, isOverInventoryLimit } from "../../bos_common/src/services/merchandiseUtils";

import {
  Merchandise,
  MerchandiseModifier,
  Merchant,
  MerchantCoupon,
} from "../../services/models";
import { AppContext, CartItemType } from "../../context/AppContext";
import {
  enableRatings,
  getMerchandiseOverallRatings,
  isEmptyOrNil,
  isKioskDevice,
  isMerchandiseAvailable,
  isMerchandiseStockLow,
  isMerchantOnline,
  isNotificationSoundEnabled,
} from "../../utils";
import {
  RenderSoldOut,
  RenderLowStockWarning,
} from "../common/MerchandiseHelper";
import { repeatAudio, withFallbackPath } from "../../bos_common/src/utils";
import { UserContext } from "../../bos_common/src/context/UserContext";
import { MerchandiseUnitPrice } from "./MerchandisePrice";
import { isCouponValidOnMerchandise } from "../../bos_common/src/services/CouponUtils";
import MerchandiseAllergens from "../../bos_common/src/components/Merchandise/MerchandiseAllergens";
import { RenderMerchandiseBadgesComponent } from '../../bos_common/src/components/Merchandise/MerchandiseBadges';
import { MenuItemCTA } from "./MenuItemCTA";
import eventTrackingService from "../../services/Tracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../../services/Tracking/events";
import FireIcon from "../../assets/icons/FireIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100%",

      "& .merchandiseDescription": {
        marginTop: 0,
      },

      "& .card-action-area": {
        display: "flex",
        alignItems: "flex-start",
        padding: theme.spacing(2, 1),

        "& .details": {
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gridGap: theme.spacing(1),
          justifyContent: "space-between",
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
          height: "100%",

          "& .menu-title": {
            [theme.breakpoints.down('md')]: {
              fontSize: theme.spacing(1.75),
            }
          },

          "& .price-container": {
            [theme.breakpoints.down('md')]: {
              fontSize: theme.spacing(1.75),
            },
            fontWeight: 500,
          },
        },

        "& .merchandisePhoto": {
          display: 'flex',
          flexFlow: 'column',
          alignItems: "center",
          position: "relative",
          flexShrink: 0,
          margin: theme.spacing(0, 3, 2, 0),
          width: 120,
          height: 120,
          [theme.breakpoints.up("md")]: {
            width: 200,
            height: 200,
          },
          '& .cover-container': {
            position: 'relative',
          },
          "& .cover": {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: theme.spacing(1),
          },

          "& .actionButton": {
            position: "absolute",
            bottom: 0,
            right: 0,
            transform: "translate(50%, 50%)",

            "& .MuiButtonBase-root": {
              border: 0,
              "&:hover": {
                background: theme.palette.common.white,
              }
            }
          }
        },
        "& .iconWithText": {
          display: 'flex',
          alignItems: "center",

          "& .iconLabel": {
            lineHeight: 1.3,
          },
        },
      },
    },
  })
);

type MerchandiseSoldProps = {
  merchandise: Merchandise;
}

const MerchandiseSold = (props: MerchandiseSoldProps) => {
  const { merchandise } = props;

  if (merchandise.unitsSold <= 50) return null;

  const mode = merchandise.unitsSold > 100 ? 100 : (merchandise.unitsSold > 50 ? 50 : 10);
  const unitsSold = merchandise.unitsSold - merchandise.unitsSold % mode;
  return (
    <div className="iconWithText">
      <FireIcon sx={{ width: 16, mr: 0.5 }} />
      <Typography variant="subtitle2" component={'span'} className="iconLabel">
        {`${unitsSold}+ sold`}
      </Typography>
    </div>
  )
}

export default function MenuItemCard(props: {
  merchandise: Merchandise;
  merchant: Merchant;
  modifiers: MerchandiseModifier[] | undefined;
  coupon?: MerchantCoupon;
  readOnly?: boolean;
}): React.ReactElement {
  const classes = useStyles();
  const { addItemToCart, cart, orderType, setCoupon } = useContext(AppContext);
  const { merchant, merchandise, coupon: inputCoupon, readOnly = false } = props;
  const merchandiseId = merchandise.id;
  const isKioskModeEnabled = isKioskDevice();
  const { user } = useContext(UserContext);
  const playNotificationSound = isNotificationSoundEnabled(user);
  const merchandisePhoto = defaultTo(
    "",
    head(pathOr([], ["merchandise", "photos"], props))
  );
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const coupon = useMemo(() => {
    if (inputCoupon) return inputCoupon;
    return merchandise ? getCouponForMerchandise(merchant, merchandise) : undefined;
  }, [merchant.id, merchandise?.id, inputCoupon?.code]);

  const itemPageLink = {
    pathname: `/${merchant.username}/item/${merchandiseId}`,
    state: { from: window.location.pathname },
  };

  const onAddToCartClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    // Assume no modifiers if an item can be added to
    addItemToCart(
      { ...merchandise, quantity: 1 } as CartItemType,
      merchant
    );
    if (isCouponValidOnMerchandise(merchandise, coupon)) {
      setCoupon(coupon);
    }

    const notifAudio = document.getElementById(
      "app_add_to_cart_audio"
    ) as HTMLAudioElement;
    notifAudio && playNotificationSound && repeatAudio(notifAudio, 1);

    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.MERCHANT_MENU,
      action: EVENT_ACTIONS.CLICK_ITEM_ADDED,
      data: {
        merchant,
        user,
        merchandise,
      },
      label: merchandise.name,
    })
  };

  const quantityUsedWithinCart = pipe(
    filter(propEq("id", merchandiseId)),
    reduce((acc: number, item: CartItemType) => acc + item.quantity, 0)
  )(cart);

  const willBeOverStockLimit = isOverInventoryLimit(
    merchandise,
    quantityUsedWithinCart + 1
  );

  const overallRatings = getMerchandiseOverallRatings(merchandise);
  const numberOfReviews = pathOr(0, ["rating", "reviewCount"])(merchandise);
  const isRatingEnabled = enableRatings(merchant);

  const CardMediaEnhanced: any = useMemo(
    () => withFallbackPath(CardMedia, merchandisePhoto),
    [merchandisePhoto]
  );

  const MerchandiseRating = () => {
    return (
      <If condition={isRatingEnabled && numberOfReviews >= 3 && overallRatings && !isKioskModeEnabled}>
        <div className="iconWithText">
          <StarIcon color="primary" fontSize="small" sx={{mr: 0.5}} />
          <Typography variant="subtitle2" component={'span'} className="iconLabel">
            {overallRatings}
          </Typography>
        </div>
      </If>
    )
  }

  const isAvailable = isMerchandiseAvailable(merchandise);

  const handleMerchandiseItemClick = () => {
    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.MERCHANT_MENU,
      action: EVENT_ACTIONS.CLICK_ITEM_DETAILS,
      label: merchandise.name ?? ""
    })
  }

  return (
    <Card className={classes.root} elevation={3}>
      <CardActionArea
        className="card-action-area"
        component={RouterLink}
        onClick={handleMerchandiseItemClick}
        to={itemPageLink}
      >
        <div className="details">
          <Stack spacing={0.5} direction={"column"} gap={isMobile ? 0.5 : 1}>
            <Typography
              component="div"
              variant={isKioskModeEnabled ? "h6" : "body1"}
              fontWeight={'bold'}
              lineHeight={1.2}
            >
              {merchandise.name}
            </Typography>

            <Stack direction="row" alignItems="center" gap={1} style={{ marginTop: 0 }}>
              <MerchandiseSold merchandise={merchandise} />
              <MerchandiseRating />
            </Stack>

            <MerchandiseDescription
              merchandise={merchandise}
              typographyProps={{ gutterBottom: true }}
              className="merchandiseDescription"
            />

            <MerchandiseAllergens allergens={merchandise?.ingredientTags} />

            <div>
              <If condition={!isAvailable}>
                <Then>
                  <RenderSoldOut />
                </Then>
                <Else>
                  <If condition={isMerchandiseStockLow(merchandise)}>
                    <RenderLowStockWarning />
                  </If>
                </Else>
              </If>
            </div>
          </Stack>

          <div className="price-container">
            <MerchandiseUnitPrice merchandise={merchandise} coupon={coupon} />
          </div>
        </div>

        <div className="merchandisePhoto">
          <If condition={merchandisePhoto && !isEmptyOrNil(merchandisePhoto)}>
            <CardMediaEnhanced
              className="cover"
              title={merchandise.name}
              src={isMobile ? `${merchandisePhoto}_small` : `${merchandisePhoto}_medium`}
              component={"img"}
              alt={"merchandise-image"}
            />
          </If>
          <If condition={!isEmptyOrNil(merchandise.badges)}>
            <RenderMerchandiseBadgesComponent merchandise={merchandise} />
          </If>

          <If condition={!readOnly && isAvailable && isMerchantOnline(merchant, orderType)}>
            <div className="actionButton">
              <MenuItemCTA merchandise={merchandise} disabled={willBeOverStockLimit} onClickAddToCart={onAddToCartClick} />
            </div>
          </If>
        </div>
      </CardActionArea>
    </Card>
  );
}
