import React from 'react';
import AuthorizeUserCommon from "bos_common/src/components/Auth";
import PhoneSignup from 'bos_common/src/components/Auth/PhoneSignup';
import { AuthorizeUserProps } from 'bos_common/src/components/Auth';
import { isEmbeddedMode } from "../../utils";


const AuthorizeUser = (props: AuthorizeUserProps) => {
  if (isEmbeddedMode()) {
    return <PhoneSignup {...props} />;
  } else {
    return <AuthorizeUserCommon {...props} />;
  }
}

export default AuthorizeUser;