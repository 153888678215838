import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Star } from '@material-ui/icons';
import React from 'react';

type ReviewsRatingListProps = {
    rating: number
};

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        reviewRatingWrapper: {
            display: "flex",
            alignItems: "center",
        }
    })
);

const ReviewsRatingList = (props: ReviewsRatingListProps) => {

    const { rating } = props;
    const classes = useStyles();

    const getRating = () => {
        const ratingList: React.ReactNode[] = [];
    
        for (let i = 0; i < rating; i++) {
          ratingList.push(<Star key={`star_${i}`} sx={{ width: 12, height: 12 }} color="primary" />);
        }
    
        return ratingList;
      };

    return (
        <Typography className={classes.reviewRatingWrapper}>
            {getRating()}
        </Typography>
    );
};

export default ReviewsRatingList;