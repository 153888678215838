import React from "react";
import { Alert } from "@material-ui/core";
import { useParams } from "react-router-dom";
import useAxios from "axios-hooks";

import SimpleLoader from "bos_common/src/components/SimpleLoader";
import { ShortURL } from "../bos_common/src/types/ShortURLType";
import { FullscreenPaper } from "bos_common/src";

interface IShortenURLPageParams {
  identifier: string,
}

const ShortURLPage = (): React.ReactElement => {
  const { identifier } = useParams<IShortenURLPageParams>();

  const [{data, loading}] = useAxios<ShortURL>(
    { url: '/shorten-url', method: 'get', params: { identifier } },
  );

  if (data && data.redirectURL) {
    window.location.href = data.redirectURL;
  }

  return (
    <div className="container">
      <SimpleLoader loading={loading} />
      <FullscreenPaper>
        { !data && !loading &&
          <Alert sx={{m: 2}} severity={"warning"}>
            Cannot find URL &quot;{identifier}&quot;
          </Alert>
        }
      </FullscreenPaper>
    </div>
  );
}

export default ShortURLPage;