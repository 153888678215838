import React, { useEffect } from "react";
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { InstagramEmbed } from "react-social-media-embed";
import { ChevronRight, Restaurant } from "@material-ui/icons";
import useAxios from "axios-hooks";
import { If } from "react-if";
import { useHistory } from "react-router-dom";
import { take } from "ramda";
import classNames from "classnames";

import SimpleLoader from "../../bos_common/src/components/SimpleLoader";
import ErrorBoundary from "bos_common/src/components/ErrorBoundary";
import { ContentFeed } from "./types";
import { isEmptyOrNil } from "../../utils";
import RenderOneMarketCard from "../StoreFront/OneMarketFeedCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .feedsContainer": {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
        gridGap: theme.spacing(2),

        [theme.breakpoints.down("lg")]: {
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
        },

        [theme.breakpoints.down("md")]: {
          gridTemplateColumns: "1fr 1fr",
        },

        "& .rsme-embed": {

          "& .instagram-media": {
            minWidth: "unset !important",
            maxWidth: "unset !important",
            width: "100% !important",
            margin: 0,
          },

          "& .instagram-media-registered": {
            minWidth: "unset !important",
            maxWidth: "unset !important",
            width: "100% !important",
            margin: 0,
          },
        },

        "& .merchantInfo": {
          fontSize: 12,
          fontWeight: 600,
          display: "inline-flex",
          justifySelf: 'flex-start',
          marginBottom: theme.spacing(3),
          lineHeight: 1,
        },

        '& .instagramCard': {
          display: 'grid',
          marginRight: '4px',
          gridTemplateRows: '1fr auto',

          '& .rsme-instagram-embed': {
            overflow: "visible !important",
          },
          '& .HeaderCta': {
            display: 'none',
          },
          '& .HoverCard': {
            display: 'none',
          },
          '& .Footer': {
            display: 'none',
          }
        }
      },
    },
  })
);

type RenderFeedCardProps = {
  feed: ContentFeed;
  merchantId: string;
}

const RenderInstagramCard = (props: RenderFeedCardProps) => {
  const { feed, merchantId } = props;

  const history = useHistory();

  if (isEmptyOrNil(feed) || isEmptyOrNil(feed.source)) return null;

  return (
    <div className="instagramCard">
      <InstagramEmbed url={feed?.contentUrl} />

      <If condition={isEmptyOrNil(merchantId)}>
        <Button
          className="merchantInfo"
          variant="outlined"
          color="primary"
          onClick={() =>
            history.push({ pathname: `/${feed.merchant.username}`, state: { from: window.location.pathname } })}
        >
          <Restaurant
            sx={{ mr: 1, width: 20, height: 20 }}
          />
          {feed.merchant.officialName}
          <ChevronRight fontSize="medium" />
        </Button>
      </If>
    </div>
  )
}

type InstagramFeedProps = {
  merchantId?: string;
  setFeedsCount?: (_: number) => void;
  feedsLimit?: number;
  customClassName?: string;
};

//TODO: need to update rendering logic
// const splitToChunks = (array: ContentFeed[], parts: number) => {
//   const result = [];
//   for (let i = parts; i > 0; i--) {
//     result.push(array.splice(0, Math.ceil(array.length / i)));
//   }
//   return result;
// };

const InstagramFeed = (props: InstagramFeedProps) => {
  const { merchantId = "", setFeedsCount, feedsLimit = -1, customClassName = "" } = props;

  const classes = useStyles();

  const url = merchantId ? `/feed/merchants/${merchantId}` : '/feed';
  const [{ data: feeds, loading }] = useAxios<ContentFeed[]>({ url, method: "get" });

  useEffect(() => {
    if (setFeedsCount) {
      setFeedsCount(feeds?.length || 0)
    }
  }, [feeds])

  if (loading && !isEmptyOrNil(merchantId)) return null;

  if (isEmptyOrNil(feeds)) return <>No media available yet</>

  const feedContainerClasses = classNames("feedsContainer", { [customClassName]: !isEmptyOrNil(customClassName) });

  return (
    <Box className={classes.root}>
      <SimpleLoader loading={loading} />

      <Box className={feedContainerClasses}>
        {
          take(feedsLimit, feeds)?.map((feed: ContentFeed) => {
            const { source } = feed;

            switch (source) {
              case "instagram": return <RenderInstagramCard feed={feed} merchantId={merchantId} key={feed.id} />
              case "onemarket": return <RenderOneMarketCard feed={feed} key={feed.id} />
              default: return null
            }
          })
        }
      </Box>
    </Box>
  );
};

const InstagramFeedWithErrorBoundary = (props: InstagramFeedProps): JSX.Element => {
  return (
    <ErrorBoundary
      source='InstagramFeed'
      errorFallbackComponent={<Typography variant="body1" component='div'>Unable to load social media</Typography>}
    >
      <InstagramFeed {...props} />
    </ErrorBoundary>
  )
}

export default InstagramFeedWithErrorBoundary;