import React from 'react'
import { SvgIcon } from "@material-ui/core";

function BurgerIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M12.897 2.782c-1.111-.656-2.58-1.017-4.132-1.017h-1.28c-1.553 0-3.02.36-4.132 1.017C2.158 3.486 1.5 4.452 1.5 5.5V6h13.25v-.5c0-1.048-.658-2.014-1.853-2.718zM2.587 5c.443-1.254 2.506-2.235 4.898-2.235h1.28c2.392 0 4.455.981 4.898 2.235H2.588zM1.5 9.5h13.25v1H1.5v-1zM12.1 7.001c-.775 0-1.18.192-1.538.36-.314.148-.562.265-1.112.265s-.798-.117-1.112-.264c-.358-.17-.764-.36-1.538-.36-.775 0-1.18.19-1.538.36-.314.147-.562.264-1.112.264s-.798-.117-1.112-.264c-.358-.17-.763-.36-1.538-.36v1c.55 0 .798.116 1.112.264.358.169.763.36 1.538.36.774 0 1.18-.191 1.538-.36C6.002 8.118 6.25 8 6.8 8c.55 0 .798.117 1.112.265.358.169.763.36 1.538.36.774 0 1.18-.191 1.538-.36C11.302 8.118 11.55 8 12.1 8c.55 0 .798.117 1.112.265.358.169.764.36 1.538.36v-1c-.55 0-.798-.117-1.112-.264-.358-.17-.764-.36-1.538-.36zM1.5 14a.751.751 0 00.75.75H14a.75.75 0 00.75-.75v-2.5H1.5V14zm1-1.5h11.25v1.25H2.5V12.5z" />
    </SvgIcon>
  );
}

export default BurgerIcon