import { PaymentMethod, SetupIntent } from "@stripe/stripe-js";
import { RootState } from "../../store";

export const getStripeLoadingStatus = (state: RootState): boolean => state.stripe.loading;

export const getStripeUpdatingStatus = (state: RootState): boolean => Boolean(state.stripe.updating);

export const getUserPaymentMethods = (state: RootState): PaymentMethod[] => {
  const { stripePaymentMethods } = state.stripe.entities;
  return Object.values(stripePaymentMethods);
};

export const getUserPaymentMethod = (state: RootState) => (id: string): PaymentMethod | undefined => {
  const { stripePaymentMethods } = state.stripe.entities;
  return stripePaymentMethods[id];
}

export const getSetupIntent = (state: RootState): SetupIntent | undefined => {
  const { setupIntent } = state.stripe.entities;
  return setupIntent;
}
