import { User } from "../../../bos_common/src/types/UserTypes";
import { AuthUIProperties } from "../../../types/AuthSlice";
import { RootState } from "../../store"

export const getToken = (state: RootState): string => state.auth.entities.token;

export const getUser = (state: RootState): User | null => state.auth.entities.user;

export const getAuthLoading = (state: RootState): boolean => state.auth.loading;

export const getSignUpDrawerUIProperties = (state: RootState): AuthUIProperties | undefined => state.auth.ui;
