import { AppType, ClientType } from "../bos_common/src";
import { DeviceType } from "../bos_common/src/types/DeviceType";

/**
 * Load parameter from envvar if specified, or throw an exception otherwise.
 * @param paramName - The name of the parameter to source from envvars (e.g. "BACKEND_CONFIG_PARAM")
 */
const loadRequiredParam = (paramName: string): string => {
  const paramValue = process.env[paramName];
  if (paramValue === undefined) {
    throw new Error(`Required envvar ${paramName} not set.`);
  }
  return paramValue;
};

/**
 * Load parameter from envvar if specified, or fallback to specified default otherwise.
 * @param paramName - The name of the parameter to source from envvars (e.g. "BACKEND_CONFIG_PARAM")
 * @param defaultValue - The fallback value to be used if `paramName` var is not set
 */
const loadOptionalParam = (paramName: string, defaultValue: string): string => {
  return process.env[paramName] || defaultValue;
};

const loadConfig = (): Record<string, any> => ({
  appConfig: {
    appType: AppType.MARKETPLACE,
    deviceType: loadOptionalParam("REACT_APP_DEVICE_TYPE", DeviceType.Regular),
    clientType: loadOptionalParam("REACT_APP_CLIENT_TYPE", ClientType.Web),
    buildEnv: loadOptionalParam("REACT_APP_BUILD_ENV", "dev"),
    gaTrackingId: loadOptionalParam("REACT_APP_GA_TRACKING_ID", ""),
    gtmTrackingId: loadOptionalParam("REACT_APP_GTM_TRACKING_ID", ""),
    pixelId: loadOptionalParam("REACT_APP_FB_PIXEL_ID", ""),
    bitlyAccessCode: loadOptionalParam("REACT_APP_BITLY_ACCESS_TOKEN", ""),
    streamChatId: loadOptionalParam("REACT_APP_STREAM_CHAT_ID", ""),
  },
  env: {
    isStaging: parseInt(loadOptionalParam("REACT_APP_IS_STAGING", "0"), 10),
  },
});

const config = loadConfig();
export default config;
