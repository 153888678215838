import React from 'react';
import { PaymentMethod } from "@stripe/stripe-js";
import { Case, Default, If, Switch } from "react-if";

import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBox from '@material-ui/icons/CheckBox';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { isEmptyOrNil } from "../../utils";

import ImageCardVisa from "../../assets/images/card_visa.png";
import ImageCardMC from "../../assets/images/card_mc.png";
import ImageCardAmex from "../../assets/images/card_amex.png";
import ImageCardOther from "../../assets/images/card_others.png";

export interface CreditCardLogoProps {
  brand?: string | 'visa' | 'mastercard' | 'american express';
}

export const CreditCardLogo = (props: Readonly<CreditCardLogoProps>): JSX.Element => {
  const { brand } = props;

  return (
    <div  style={{ height: '42px', width: '72px', paddingTop: '.75rem', }}>
      <Switch>
        <Case condition={brand === 'visa'}>
          <img src={ImageCardVisa} alt={brand} decoding="async" />
        </Case>
        <Case condition={brand === 'mastercard'}>
          <img src={ImageCardMC} alt={brand} decoding="async" />
        </Case>
        <Case condition={brand === 'american express'}>
          <img src={ImageCardAmex} alt={brand} decoding="async" />
        </Case>
        <Default>
          <img src={ImageCardOther} alt={brand} decoding="async" />
        </Default>
      </Switch>
    </div>
  );
}

const useStylesForCreditCardListItem = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      padding: '1rem',
      margin: '1rem',
      transition: 'all .33s',
      position: 'relative',

      "&.selected": {
        backgroundColor: theme.palette.primary.main,
      },

      "&.selectable": {
        cursor: 'pointer',
      },

      "& .card-holder-name": {
        maxWidth: '64px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },

      "& .card-checkbox": {
        position: 'absolute',
        top: '1rem',
        right: '1rem',
      },
    },
  })
);

export interface CreditCardListItemProps {
  item: PaymentMethod;
  selected?: boolean;
  withCheckBox?: boolean;
  onClick?: (selectedItem: PaymentMethod) => void;
  onDelete?: (paymentMethod: PaymentMethod) => void;
}

export const CreditCardListItem = (props: Readonly<CreditCardListItemProps>): JSX.Element => {
  const classes = useStylesForCreditCardListItem();
  const { item, selected, withCheckBox, onClick, onDelete } = props;
  const theme = useTheme();

  const containerClassNames = classNames([classes.root], {
    selectable: withCheckBox,
  });

  const handleClick = (item: PaymentMethod) => {
    if (onClick) {
      onClick(item);
    }
  }

  const handleClickDelete = (card: PaymentMethod, e: React.MouseEvent<HTMLLabelElement>) => {
    e.stopPropagation();

    if (!onDelete) {
      return;
    }

    if (confirm(`Are you sure to delete ${item.card?.brand} card with last 4 digits: ${item.card?.last4}?`)) {
      onDelete(card);
    }
  }

  return (
    <Paper className={containerClassNames} elevation={3} onClick={() => handleClick(item)}>
      <CreditCardLogo brand={item.card?.brand.toLowerCase()} />
      <Box sx={{ marginLeft: '1rem' }}>
        <Typography variant="subtitle1">xxxx xxxx xxxx {`${!isEmptyOrNil(item.card?.last4) ? item.card?.last4 : 'xxxx'}`}</Typography>
        <Box style={{ display: 'flex' }}>
          <span title={item.billing_details.name || ''}>
            <Typography variant="subtitle2" className="card-holder-name" style={{ color: theme.palette.secondary.light }}>{item.billing_details.name}</Typography>
          </span>
          <Typography variant="subtitle2" style={{ marginLeft: '.5rem', color: '#016FD0' }}>Exp. {`${item.card?.exp_month}/${item.card?.exp_year}`}</Typography>
          <If condition={withCheckBox}>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize='large' />}
              checkedIcon={<CheckBox fontSize='large' color='primary' />}
              checked={selected}
              className="card-checkbox"
            />
          </If>
        </Box>
      </Box>
      <If condition={Boolean(onDelete)}>
        <IconButton color="primary" aria-label="upload picture" component="label" sx={{ marginLeft: 'auto' }} onClick={(e: React.MouseEvent<HTMLLabelElement>) => handleClickDelete(item, e)}>
          <DeleteIcon sx={{ float: 'right', clear: 'none' }} />
        </IconButton>
      </If>
    </Paper>
  );
}

export interface CreditCardListProps {
  paymentMethods: PaymentMethod[];
  selectedPaymentMethodId?: string;
  withCheckBox?: boolean;
  onClickItem?: (selectedItem: PaymentMethod) => void;
  onDeleteItem?: (paymentMethod: PaymentMethod) => void;
}

export const CreditCardList = (props: Readonly<CreditCardListProps>): JSX.Element => {
  const { paymentMethods, selectedPaymentMethodId, withCheckBox, onClickItem, onDeleteItem } = props;

  const creditCardsEl = paymentMethods.map((item: PaymentMethod) =>
    <CreditCardListItem
      item={item}
      key={item.id}
      selected={selectedPaymentMethodId === item.id}
      withCheckBox={withCheckBox}
      onClick={onClickItem}
      onDelete={onDeleteItem} />
  );

  return (
    <Box className="credit-card-list">{creditCardsEl}</Box>
  );
}

export default CreditCardListItem;
