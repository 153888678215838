import {
  Box,
  Card,
  CardMedia,
  createStyles,
  makeStyles,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { MerchantStory } from "bos_common/src/types/MerchantStoryType";
import { descend, filter, pathOr, pipe, prop, reduce, sort } from "ramda";
import React from "react";
import AliceCarousel from "react-alice-carousel";
import { useHistory, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { fetchMerchantStories } from "../redux/slice/merchantStory/merchantStoryAction";
import { getMerchantStories } from "../redux/slice/merchantStory/merchantStorySelector";
import { Merchant } from "../services/models";
import eventTrackingService from "../services/Tracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../services/Tracking/events";
import { CarouselWrapper } from "./Classifieds/styledComponents";
import StoryCTA from "./MerchantStories/StoryCTA";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    storyClass: {
      display: "flex",
      alignItems: "center",
      height: "100%",
      overflow: "unset",
      minHeight: "112px",
      justifyContent: "space-between",
      borderRadius: theme.spacing(2),

      [theme.breakpoints.down('sm')]: {
        minHeight: 90,
      },

      "& .storySubtitle": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        lineClamp: 3 /* number of lines to show */,
        boxOrient: "vertical",
      },
    },
  })
);

type StoryCardProps = {
  story: MerchantStory;
  merchant: Merchant;
  showImage?: boolean;
};

const StoryCard = (props: StoryCardProps) => {
  const { story, merchant, showImage=true } = props;

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  
  const classes = useStyles();

  const reactHistory = useHistory();

  const handleStoryClick = (item: MerchantStory) => {
    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.MERCHANT_STORY,
      action: EVENT_ACTIONS.CLICK_FEED_POST,
      label: `${item.title} - ${item.merchant.officialName}`,
    });

    reactHistory.push(`/${merchant?.username}/story/${item.id}`);
  };

  return (
    <Card className={classes.storyClass} sx={{ mr: 1, my: 1 }}>
      <Stack direction="row" onClick={() => handleStoryClick(story)}>
        {showImage && pathOr(null, ["photos", 0], story) && (
          <CardMedia
            component="img"
            alt={story.title}
            height={isMobile ? "90px" : "112px"}
            sx={{width: isMobile ? "90px" : "112px", objectFit: "cover", borderRadius: (theme) => theme.spacing(2, 0, 0, 2)}}
            image={pathOr(null, ["photos", 0], story)}
          />
        )}
        <Stack px={2} py={1.5} gap={1} justifyContent="space-between">
          <Typography variant="subtitle1" lineHeight={1} mb={0.5} fontWeight="bold" component="div">
            {story.title}
          </Typography>
          
          <StoryCTA alignLeft={true} size="small" story={story} style={{ fontSize: 12, height: 30, margin: 0 }} />
        </Stack>
      </Stack>
    </Card>
  );
};

type StoriesCarouselProps = {
  merchant: Merchant;
};

interface IMerchantFabParams {
  merchantUserName: string;
}

const StoriesCarousel = (props: StoriesCarouselProps) => {
  const { merchant } = props;

  const { merchantUserName } = useParams<IMerchantFabParams>();

  const reduxDispatch = useAppDispatch();

  const merchantStories = useAppSelector(getMerchantStories)(merchantUserName);

  React.useEffect(() => {
    reduxDispatch(fetchMerchantStories(merchant));
  }, []);

  const storiesList = pipe(
    filter((story: MerchantStory) => story.status === "published"),
    sort(descend(prop("updatedAt"))),
    reduce(
      (prev: React.ReactNode[], current: MerchantStory) => [
        ...prev,
        <StoryCard key={current.id} merchant={merchant} story={current} />,
      ],
      []
    )
  )(merchantStories)

  return (
    <CarouselWrapper sx={{ m: 1 }}>
      <AliceCarousel
        disableDotsControls
        mouseTracking
        items={storiesList}
        disableButtonsControls
        paddingRight={10}
        responsive={{ 0: { items: 1 }, 800: { items: 2 }, 900: { items: 3 } }}
      />
    </CarouselWrapper>
  );
};

export default StoriesCarousel;
