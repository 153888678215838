import React from "react";
import { Fab, Stack, Theme, Typography } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { LibraryBooks } from "@material-ui/icons";
import { lighten } from "polished";
import { useHistory } from "react-router-dom";

import FavoriteButton from "../common/FavoriteButton";
import { Merchandise, Merchant, MerchantGuideRecommendation } from "../../services/models";
import { getMerchantLogoPlaceholder } from "../../utils";
import { If } from "react-if";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .cardTitleContainer": {
        margin: theme.spacing(1, 0),

        "& .cardTitle": {
          fontSize: 18,
          fontWeight: 700,
          color: theme.palette.info.light,
          marginBottom: theme.spacing(2),

          "& span": {
            color: theme.palette.primary.main,
          }
        },
      },

      "& .cardVisual": {
        maxWidth: "600px",
        width: "100%",
        height: "250px",
        borderRadius: theme.spacing(1),
        objectFit: "cover",
      },

      "& .cardHeadingWrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gridGap: theme.spacing(2),
        margin: theme.spacing(2, 0, 3),

        "& .cardHeading": {
          fontSize: 18,
          color: theme.palette.info.light,
          fontWeight: "bold",
        }
      },

      "& .orderNowButton": {
        fontWeight: 700,
        color: theme.palette.primary.main,
        background: lighten(0.4, theme.palette.primary.main),
        boxShadow: "none",
        fontSize: 14,

        "&:hover": {
          background: theme.palette.primary.main,
          color: theme.palette.background.paper,
        },
      },

      "& .cardDescription": {
        fontSize: 16,
        fontWeight: 500,
        color: "#939393",
      },

      "& .buttonWrapper": {
        display: "flex",
        justifyContent: "center",
      },

      "& .browseOrderButton": {
        background: theme.palette.background.paper,
        color: theme.palette.primary.main,
        boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.07)",
      },
    },
  })
);

type LocalGuideCardProps = {
  merchant: Merchant;
  merchandise: Merchandise;
  recommendation: MerchantGuideRecommendation;
  currentIndex: number;
};

const LocalGuideCard = (props: LocalGuideCardProps) => {
  const {
    merchant,
    merchandise,
    recommendation,
    currentIndex
  } = props;

  const classes = useStyles();
  const history = useHistory();

  const cardDescription = recommendation.description ?? merchandise.description
  const cardImageSrc = (merchandise?.photos && merchandise?.photos[0]) || getMerchantLogoPlaceholder(merchant)

  const onOrderNowClick = () => {
    history.push({
      pathname: `/${merchant.username}/item/${merchandise.id}`,
      state: { from: window.location.pathname },
    })
  }

  const onBrowseMenuClick = () => {
    history.push({
      pathname: `/${merchant.username}`,
      state: { from: window.location.pathname },
    })
  }

  return (
    <div className={classes.root}>
      <Stack direction={'row'} alignItems={'center'} justifyContent='space-between' className='cardTitleContainer'>
        <div className="cardTitle">
          <span>Top {currentIndex + 1}:</span> {merchant.officialName}
        </div>
        <FavoriteButton merchant={merchant} />
      </Stack>

      <img
        src={cardImageSrc}
        alt="Guide visual"
        className="cardVisual"
      />

      <div className="cardHeadingWrapper">
        <div className="cardHeading">
          {merchandise.name}
        </div>
        <If condition={merchant.claimed}>
          <Fab variant="extended" color="primary" className="orderNowButton" onClick={onOrderNowClick}>
            Order Now
          </Fab>
        </If>
      </div>

      <p className="cardDescription">
        {cardDescription}
      </p>

      <div className="buttonWrapper">
        <Fab variant="extended" color="inherit" className="browseOrderButton" onClick={onBrowseMenuClick}>
          <LibraryBooks sx={{ mr: 1 }} /> Browse Full Menu & Order
        </Fab>
      </div>
    </div>
  );
};

export default LocalGuideCard;
