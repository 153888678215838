import axios, { AxiosResponse } from "axios";
import { isEmptyOrNil } from "../utils";

type BitlyShortenURLResponse = {
  references: { [key: string]: any };
  link: string;
  id: string;
  long_url: string;
  archived: boolean;
  created_at: string;
  custom_bitlinks: [string];
  tags: [string];
  deeplinks: [
    {
      guid: string;
      bitlink: string;
      app_uri_path: string;
      install_url: string;
      app_guid: string;
      os: string;
      install_type: string;
      created: string;
      modified: string;
      brand_guid: string;
    }
  ];
};

class BitlyService {
  accessToken: string;

  constructor(accessToken: string) {
    this.accessToken = accessToken;
  }

  shortenUrl = async (
    long_url: string
  ): Promise<AxiosResponse<BitlyShortenURLResponse> | undefined> => {
    try {
      if (isEmptyOrNil(long_url)) throw new Error("long_url is required");

      const data = {
        long_url,
        domain: `local.1m.app`,
      };
      const headers = {
        Authorization: `Bearer ${this.accessToken}`,
        "Content-Type": "application/json",
      };
      const response: AxiosResponse<BitlyShortenURLResponse> = await axios.post(
        `https://api-ssl.bitly.com/v4/shorten`,
        data,
        { headers }
      );

      return response;
    } catch (error) {
      console.log(`failed to shorten URL`, error);
      return;
    }
  };
}

export default BitlyService;
