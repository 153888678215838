import React from "react";
import { SvgIcon } from "@material-ui/core";

function LootBoxIcon(props: any) {
  return (
    <SvgIcon
    sx={{width: "49px", height: "45px"}} viewBox="0 0 49 45" fill="none"
      {...props}
    >
<path d="M0.75 25.5896V43.2114C0.75 43.9862 1.37803 44.6143 2.15287 44.6143H14.9363C14.9363 43.8041 14.9363 26.2927 14.9363 25.5896H0.75Z" fill="#F8B62D"/>
<path d="M3.70631 43.2114V25.5896H0.75V43.2114C0.75 43.9862 1.37803 44.6143 2.15288 44.6143H5.10919C4.33444 44.6143 3.70631 43.9862 3.70631 43.2114Z" fill="black" fillOpacity="0.1"/>
<path d="M7.69828 13.9612C3.86091 13.9612 0.75 17.0721 0.75 20.9096V25.8794H14.9363V20.9096C14.9363 17.0721 11.5357 13.9612 7.69828 13.9612Z" fill="#FFA812"/>
<path d="M9.18337 14.1187C8.70028 14.0164 8.20331 13.9612 7.69828 13.9612C3.8609 13.9611 0.75 17.072 0.75 20.9094V25.8792H3.70631V20.9094C3.70631 17.5769 6.05287 14.7938 9.18337 14.1187Z" fill="black" fillOpacity="0.1"/>
<path d="M39.0007 13.9612H10.5957C14.4332 13.9612 17.544 17.0721 17.544 20.9095V26.7485H27.6003C27.6801 26.1743 28.175 25.7281 28.7703 25.7281H28.9673H33.5003H33.6974C34.2926 25.7281 34.7875 26.1743 34.8674 26.7485H45.9489V20.9095C45.9491 17.0721 42.8382 13.9612 39.0007 13.9612Z" fill="#FBC700"/>
<path d="M14.7706 13.9612H10.5957C14.4332 13.9612 17.544 17.0721 17.544 20.9095V26.7485H21.7189V20.9095C21.7189 17.0721 18.608 13.9612 14.7706 13.9612Z" fill="#FFA812"/>
<path d="M11.1728 13.9612H7.69873C11.5362 13.9612 14.647 17.0721 14.647 20.9095V26.7485H18.1212V20.9095C18.1212 17.0721 15.0103 13.9612 11.1728 13.9612Z" fill="#FFE02F"/>
<path d="M41.8018 13.9612H38.3276C42.1651 13.9612 45.2759 17.0721 45.2759 20.9095V26.7485H48.7501V20.9095C48.7502 17.0721 45.6393 13.9612 41.8018 13.9612Z" fill="#FFEA7E"/>
<path d="M34.8673 25.8794C34.8747 25.9326 34.8799 25.9865 34.8799 26.0415V31.0672C34.8799 31.7176 34.3478 32.2498 33.6973 32.2498H28.7701C28.1197 32.2498 27.5875 31.7177 27.5875 31.0672V26.0415C27.5875 25.9864 27.5927 25.9326 27.6001 25.8794H14.6465V44.6143H47.2438C48.0755 44.6143 48.7497 43.9401 48.7497 43.1084V25.8794H34.8673Z" fill="#FFEA7E"/>
<path d="M14.6465 25.8794H18.1207V44.6143H14.6465V25.8794Z" fill="#FFE02F"/>
<path d="M44.359 29.3535H34.8799V31.0673C34.8799 31.7176 34.3478 32.2498 33.6974 32.2498H28.7701C28.1198 32.2498 27.5876 31.7177 27.5876 31.0673V29.3535H19.0372C18.5309 29.3535 18.1206 29.7639 18.1206 30.2701V40.2235C18.1206 40.7297 18.5309 41.14 19.0372 41.14H44.3589C44.8651 41.14 45.2755 40.7296 45.2755 40.2235V30.2702C45.2756 29.764 44.8652 29.3535 44.359 29.3535Z" fill="#FBC700"/>
<path d="M21.6923 40.2235V30.2701C21.6923 29.7639 22.1027 29.3535 22.6089 29.3535H19.0377C18.5314 29.3535 18.1211 29.7639 18.1211 30.2701V40.2235C18.1211 40.7297 18.5314 41.14 19.0377 41.14H22.6089C22.1027 41.14 21.6923 40.7296 21.6923 40.2235Z" fill="#FFA812"/>
<path d="M34.3748 8.73929H33.8505V8.21503C33.8505 7.81491 33.5262 7.49072 33.1262 7.49072C32.7262 7.49072 32.4019 7.815 32.4019 8.21503V8.73929H31.8776C31.4776 8.73929 31.1533 9.06357 31.1533 9.4636C31.1533 9.86372 31.4776 10.1879 31.8776 10.1879H32.4019V10.7122C32.4019 11.1123 32.7262 11.4365 33.1262 11.4365C33.5262 11.4365 33.8505 11.1122 33.8505 10.7122V10.1879H34.3748C34.7748 10.1879 35.0991 9.86363 35.0991 9.4636C35.0991 9.06357 34.7748 8.73929 34.3748 8.73929Z" fill="#FFE02F"/>
<path d="M23.9461 7.97997C23.1317 7.56334 22.4664 6.89697 22.0516 6.08144C21.9615 5.90425 21.7807 5.79175 21.5819 5.79175C21.3832 5.79175 21.2024 5.90434 21.1123 6.08144C20.6975 6.89697 20.0323 7.56334 19.2178 7.97997C19.0412 8.07025 18.9292 8.25081 18.9292 8.4491V8.46203C18.9292 8.65825 19.0378 8.83928 19.2125 8.92844C20.0243 9.34244 20.6879 10.0045 21.1039 10.8152C21.1933 10.9893 21.374 11.0972 21.5697 11.0972H21.5942C21.7899 11.0972 21.9706 10.9893 22.06 10.8152C22.4759 10.0045 23.1396 9.34244 23.9514 8.92844C24.1261 8.83928 24.2347 8.65825 24.2347 8.46203V8.4491C24.2346 8.25072 24.1227 8.07025 23.9461 7.97997Z" fill="#FFEA7E"/>
<path d="M29.6299 1.92225C29.0579 1.62965 28.5906 1.16165 28.2994 0.588935C28.2361 0.464529 28.109 0.385498 27.9694 0.385498C27.8299 0.385498 27.7028 0.464529 27.6395 0.588935C27.3483 1.16165 26.881 1.62965 26.309 1.92225C26.1851 1.98572 26.1064 2.11247 26.1064 2.25168V2.26078C26.1064 2.39859 26.1827 2.52572 26.3054 2.58834C26.8755 2.87915 27.3416 3.34406 27.6337 3.9134C27.6964 4.03565 27.8234 4.1115 27.9608 4.1115H27.9781C28.1155 4.1115 28.2424 4.03565 28.3052 3.9134C28.5973 3.34406 29.0634 2.87915 29.6335 2.58834C29.7562 2.52572 29.8324 2.39859 29.8324 2.26078V2.25168C29.8324 2.11247 29.7538 1.98572 29.6299 1.92225Z" fill="#FFE02F"/>
<path d="M21.048 8.92853C20.8733 8.83938 20.7647 8.65835 20.7647 8.46213V8.44919C20.7647 8.25091 20.8768 8.07044 21.0533 7.98006C21.6187 7.69084 22.112 7.28097 22.4998 6.78466C22.329 6.56603 22.1786 6.33091 22.0517 6.08153C21.9615 5.90434 21.7808 5.79175 21.5819 5.79175C21.3832 5.79175 21.2024 5.90434 21.1123 6.08153C20.6975 6.89706 20.0323 7.56344 19.2178 7.98006C19.0412 8.07034 18.9292 8.25091 18.9292 8.44919V8.46213C18.9292 8.65835 19.0378 8.83938 19.2125 8.92853C20.0243 9.34253 20.6879 10.0046 21.1039 10.8153C21.1933 10.9893 21.374 11.0973 21.5697 11.0973H21.5942C21.7899 11.0973 21.9706 10.9893 22.06 10.8153C22.1849 10.5717 22.3327 10.3419 22.4997 10.1279C22.1109 9.6295 21.616 9.21813 21.048 8.92853Z" fill="#FFE02F"/>
<path d="M27.8511 2.58843C27.7284 2.52581 27.6522 2.39868 27.6522 2.26087V2.25178C27.6522 2.11256 27.7308 1.98581 27.8548 1.92234C28.1925 1.74956 28.4923 1.51443 28.7423 1.23394C28.5689 1.03931 28.4186 0.823592 28.2993 0.588935C28.2361 0.464529 28.109 0.385498 27.9694 0.385498C27.8299 0.385498 27.7028 0.464529 27.6395 0.588935C27.3483 1.16165 26.881 1.62965 26.309 1.92225C26.1851 1.98572 26.1064 2.11247 26.1064 2.25168V2.26078C26.1064 2.39859 26.1827 2.52572 26.3054 2.58834C26.8755 2.87915 27.3416 3.34406 27.6337 3.9134C27.6964 4.03565 27.8234 4.1115 27.9608 4.1115H27.9781C28.1155 4.1115 28.2424 4.03565 28.3052 3.9134C28.4235 3.68278 28.5716 3.47053 28.7423 3.27881C28.4915 2.99728 28.1903 2.7614 27.8511 2.58843Z" fill="#FBC700"/>
<path d="M33.6976 24.8108H28.7704C28.0917 24.8108 27.5396 25.3629 27.5396 26.0416V31.0673C27.5396 31.746 28.0916 32.2981 28.7704 32.2981H33.6976C34.3763 32.2981 34.9285 31.746 34.9285 31.0673V26.0416C34.9285 25.3629 34.3764 24.8108 33.6976 24.8108Z" fill="#928392"/>
<path d="M30.0206 31.0673V26.0416C30.0206 25.363 30.5727 24.8108 31.2515 24.8108H28.7704C28.0917 24.8108 27.5396 25.3629 27.5396 26.0416V31.0673C27.5396 31.746 28.0916 32.2981 28.7704 32.2981H31.2515C30.5727 32.298 30.0206 31.7459 30.0206 31.0673Z" fill="#7A6D79"/>
<path d="M31.2341 30.991C30.834 30.991 30.5098 30.6667 30.5098 30.2667V28.8664C30.5098 28.4663 30.834 28.1421 31.2341 28.1421C31.6341 28.1421 31.9584 28.4664 31.9584 28.8664V30.2667C31.9584 30.6667 31.6341 30.991 31.2341 30.991Z" fill="#685E68"/>
<path d="M31.2338 29.2811C31.9039 29.2811 32.4471 28.7379 32.4471 28.0678C32.4471 27.3977 31.9039 26.8545 31.2338 26.8545C30.5637 26.8545 30.0205 27.3977 30.0205 28.0678C30.0205 28.7379 30.5637 29.2811 31.2338 29.2811Z" fill="#685E68"/>
<path d="M7.7947 16.9186C5.54976 16.8656 3.70654 18.6763 3.70654 20.9095V22.4292C3.70654 22.7019 3.92761 22.9229 4.20032 22.9229H11.1968C11.4695 22.9229 11.6906 22.7019 11.6906 22.4292V20.9844C11.6906 18.8044 9.97401 16.9701 7.7947 16.9186Z" fill="#7A6D79"/>
<path d="M6.60398 22.4292V20.9095C6.60398 19.221 7.65801 17.7747 9.14235 17.1922C8.72385 17.0261 8.27029 16.9298 7.7947 16.9186C5.54976 16.8656 3.70654 18.6763 3.70654 20.9095V22.4292C3.70654 22.7019 3.92761 22.9229 4.20032 22.9229H7.09776C6.82504 22.9229 6.60398 22.7019 6.60398 22.4292Z" fill="#685E68"/>
    </SvgIcon>
  );
}

export default LootBoxIcon;

