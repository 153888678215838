import React from "react";
import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { darken } from "polished";
import { If } from "react-if";

import { isEmptyOrNil } from "bos_common/src/utils";
import { PromotionCriteriaType } from "bos_common/src/types/MerchantPromotionType";

import { Merchant, MerchantCoupon } from "../../services/models";
import RenderWaitTimeSlider from "../common/WaitTimeSlider";
import MerchantHeader from "../MerchantInfo/MerchantHeader";
import PercentageIcon from "../../assets/icons/PercentageIcon";
import StoreLocationSelector from "../MerchantMenu/StoreLocation/StoreLocationSelector";
import { isMultiStore } from "../MerchantMenu/StoreLocation/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      alignItems: "flex-start",
      width: "100%",

      "& .actionButton": {
        background: theme.palette.background.paper,
        borderRadius: theme.spacing(1.5),
        width: "100%",
        textTransform: 'none',
        display: 'flex',
        justifyContent: 'flex-start',

        [theme.breakpoints.up("md")]: {
          minWidth: "170px",
        },
        "&:hover": {
          background: darken(0.1, theme.palette.background.paper),
        }
      },

      "& .navigationCta": {
        padding: theme.spacing(2),
        background: theme.palette.background.paper,
        borderRadius: theme.spacing(2.8, 2.8, 0, 0),
      },
    },
  })
);

type StoreFrontHeaderProps = {
  merchant: Merchant;
};

const StoreFrontHeader = (props: StoreFrontHeaderProps) => {
  const { merchant } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isStoreSelectorShown = isMultiStore(merchant);

  const otherPromotions = merchant.promotions?.filter((item) =>
    item.criteriaType !== PromotionCriteriaType.REFERRAL_TYPE
  ) || [];
  const otherCoupons = otherPromotions.reduce((agg: MerchantCoupon[], p) => p.coupons ? [...agg, ...p.coupons] : agg, []);
  const coupon = otherCoupons.length > 0 ? otherCoupons[0] : undefined;

  return (
    <div className={classes.root}>
      <div className="navigationCta">
        <MerchantHeader merchant={merchant} />
      </div>
      <If condition={isStoreSelectorShown}>
        <Grid container sx={{padding: theme.spacing(1, 1, 0, 1)}}>
          <Grid item xs={12} sm={6} lg={4}>
            <StoreLocationSelector merchant={merchant} popupByDefault={false} />
          </Grid>
        </Grid>
      </If>
      <If condition={isMobile}>
        <Grid container sx={{padding: theme.spacing(1, 1, 0, 1)}}>
          <If condition={!isEmptyOrNil(coupon?.title)}>
            <Grid item xs={12}>
              <Button className="actionButton" color="inherit">
                <PercentageIcon color="secondary" sx={{ width: 20, mr: 0.5}} />
                <Typography variant="subtitle2">
                  {coupon?.title ?? ""}
                </Typography>
              </Button>
            </Grid>
          </If>
        </Grid>
      </If>
    </div>
  );
};

export default StoreFrontHeader;
