import React from 'react';
import { Link } from 'react-scroll';
import { AppBar, Chip, createStyles, List, ListItem, makeStyles, Theme } from '@material-ui/core';
import { rgba } from 'polished';

// src
import { ColoredPaper } from '../../bos_common/src';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: "unset",
      borderRadius: ({ borderRadius = "20px 20px 0px 0px !important" }: any) => borderRadius,
      marginBottom: theme.spacing(2),

      [theme.breakpoints.down('sm')]: {
        padding: `0 ${theme.spacing(2)}`
      },

      '& .ordersTabs': {
        display: 'inline-flex',
        gap: theme.spacing(2),
        padding: 0,
        background: theme.palette.background.paper,
        // boxShadow: "0px 1px 12px rgba(0, 0, 0, 0.07)",
        borderRadius: theme.spacing(1),
        justifyContent: "center",

        [theme.breakpoints.down('sm')]: {
          gap: theme.spacing(1),
        },
      },

      "& .item": {
        display: "inline-block",
        padding: `${theme.spacing(2)} 0`,
        width: "unset",

        "&::last-child": {
          marginRight: 0,
        },

        "& .active": {
          "& .link": {
            color: theme.palette.primary.main,
            background: rgba(theme.palette.primary.main, 0.15),
            borderColor: theme.palette.primary.main,
          },
        },

        "& .link": {
          color: theme.palette.secondary.light,
          borderRadius: 8,
          padding: `${theme.spacing(1)} ${theme.spacing(2.7)}`,
          fontSize: "14px",
          background: rgba(theme.palette.secondary.light, 0.15),
          border: `1px solid ${theme.palette.secondary.light}`,

          "@media (max-width: 700px)": {
            padding: `${theme.spacing(1)} ${theme.spacing(0.8)}`,
          },

          "& .MuiChip-label": {
            padding: 0,
            marginTop: -1,

            "@media (max-width: 700px)": {
              fontSize: "12px",
            },
          },
        },
      },
    }
  })
);

type SectionsAppBarProps = {
  sections: {
    key: string,
    title: string,
    [key: string]: any
  }[]
}

const FloatingSectionsHeader = ({ sections }: SectionsAppBarProps) => {
  const classes = useStyles({})

  return (
    <AppBar
      component={ColoredPaper}
      position="sticky"
      color="default"
      className={classes.root}
    >
      <List className="ordersTabs">
        {sections.map((item) =>
          <ListItem key={item.key} className="item">
            <Link
              activeClass="active"
              to={item.key}
              spy
              smooth
              duration={500}
              offset={-150}
            >
              <Chip
                label={item.title}
                variant="filled"
                className="link"
                clickable
              />
            </Link>
          </ListItem>
        )}
      </List>
    </AppBar>
  );
}

export default FloatingSectionsHeader;
