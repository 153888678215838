import { AxiosRequestConfig, AxiosResponse } from "axios";
import { axios } from "../bos_common/src/services/reservationBackendAxios";
//import { Reservation } from "../bos_common/src/types/ReservationTypes";

export const getReservation = (id: string, request?: AxiosRequestConfig): Promise<AxiosResponse> => {
  return axios({
    url: `/customer/booking/${id}`,
    method: 'GET',
    headers: (request && request.headers) ? request.headers : {},
  });
}

export const cancelReservation = (id: string, request?: AxiosRequestConfig): Promise<AxiosResponse> => {
  return axios({
    url: `/customer/booking/cancel`,
    method: 'POST',
    data: {
      id
    },
    headers: (request && request.headers) ? request.headers : {},
  })
}

// export const putOrder = (order: Partial<Order>, request: AxiosRequestConfig): Promise<AxiosResponse> => {
//   return axios.put('/orders',
//     {
//       ...order,
//     }, {
//       headers: request.headers,
//     }
//   );
// }

export default {
  getReservation,
  cancelReservation,
}
