import React from "react";
import { Divider } from "@material-ui/core";
import { LineItem, Order } from "../../services/models";
import OrderItemCard from "./OrderItemCard";
import OrderSummary from "../../bos_common/src/components/OrderSummary";

export default function OrderItems(props: {
  order: Order,
}): React.ReactElement {

  return (
    <div>
      {/* <Typography gutterBottom variant="h6" component="div">
        Receipt
      </Typography> */}
      {props.order.lineItems.map((item: LineItem) => {
        return (
          <OrderItemCard item={item} key={`${item.id}-${item.customizationSignature}`} />
        )
      })}
      <Divider light={true} variant="middle" />
      <OrderSummary order={props.order} />
    </div>
  )
}