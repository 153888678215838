import React, { useContext, useState } from "react";
import { Switch, Case, Default, If } from "react-if";
import { useHistory } from "react-router-dom";
import { useMediaQuery, Theme, Fab, Divider, Typography, Stack } from "@material-ui/core";
import assert from "assert";

import { isEmptyOrNil } from "bos_common/src";
import { MultiFabContainer } from "bos_common/src/components/FabContainers";
import { UserContext } from "bos_common/src/context/UserContext";
import { isCouponEnabled } from "bos_common/src/services/featureFlagUtils";

import CartItems from "../../components/ShoppingCart/CartItems";
import CartSummary from "../../components/ShoppingCart/CartSummary";
import { AppContext } from "../../context/AppContext";
import { ShoppingCartContext } from "../../context/ShoppingCart/ShoppingCartContext";
import { getTotalItems } from "../../services/cartUtils";
import { isPaymentDisabled } from "../../utils";
import { SignupEntrance } from "../../components/User/SignupDrawer";
import ShoppingCartCouponInput from "../../components/ShoppingCart/ShoppingCartCouponInput";
import { useAppDispatch } from "../../redux/hooks";
import { showSignUpDrawer } from "../../redux/slice/auth/authActions";

const PickUpNoPayCart = (props: {
  isValidPickupTime: boolean,
}) => {
  const { isValidPickupTime } = props;
  const history = useHistory();
  const { user } = useContext(UserContext)
  const { featureFlags, merchantConfig, cart } = useContext(AppContext)
  const { soldoutItems, clearSoldOutItems, showPickupOptions } = useContext(ShoppingCartContext);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isPaymentDisabledVal = isPaymentDisabled(merchantConfig?.merchant);
  const reduxDispatch = useAppDispatch();

  assert(isPaymentDisabledVal);
  const count = getTotalItems(cart);

  const handleOpenCheckOrderCheckout = () => {
    if (isEmptyOrNil(user)) {
      setShowSignup(true)
    } else {
      history.push("/place-open-check-order")
    }
  }

  const setShowSignup = (open: boolean) => {
    reduxDispatch(showSignUpDrawer({
      drawerShown: open,
      signupEntrance: SignupEntrance.PLACE_ORDER,
    }));
  }

  const RenderFabCTA = () => {
    const areCartItemsOutOfOrder = !isEmptyOrNil(soldoutItems);

    return (
      <MultiFabContainer fabStyle={{ padding: "24px 0 16px" }} fadeTop={isMobile}>
        <Switch>
          <Case condition={areCartItemsOutOfOrder}>{() =>
            <Fab color="primary" variant="extended" size="large" onClick={clearSoldOutItems}>
              Clear unavailable items
            </Fab>}
          </Case>
          <Case condition={count < 1}>{() =>
            <Fab color="primary" variant="extended" size="large" disabled>
              No items to checkout
            </Fab>}
          </Case>
          <Case condition={!isValidPickupTime}>{() =>
            <Fab color="primary" variant="extended" size="large" onClick={() => showPickupOptions(true)}>
              Schedule your pickup
            </Fab>}
          </Case>
          <Default>{() =>
            <Stack>
              <Typography variant="subtitle1">Payment collected at pick-up</Typography>
              <Fab
                color="primary"
                variant="extended"
                size="large"
                onClick={handleOpenCheckOrderCheckout}>
                Place order
              </Fab>
            </Stack>}
          </Default>
        </Switch>
      </MultiFabContainer>
    )
  }

  return (
    <>
      <CartItems />
      <If condition={isCouponEnabled(featureFlags)}>
        <ShoppingCartCouponInput />
      </If>
      <Divider />
      <CartSummary />
      <RenderFabCTA />
    </>
  )
}

export default PickUpNoPayCart;