import React, { useContext } from "react";
import { Alert } from "@material-ui/core";

import { Order } from "../../services/models";
import { UserContext } from "../../bos_common/src/context/UserContext";
import AuthorizeUser from "../common/AuthorizeUser";
import OneMarketPointString from "../common/OneMarketPointString";
import GemIcon from "../../assets/icons/GemIcon";

export default function Order1MPoints({ order }: {
  order?: Order,
}): React.ReactElement | null {
  const { user, authenticating } = useContext(UserContext)
  if (!order || authenticating) {
    return null
  }

  const showSignup = !order.userId && !user
  if (order.oneMarketPoints > 0) {
    if (showSignup) {
      return (
        <Alert severity="info" elevation={3}>
          Your purchase has earned &nbsp;{order.oneMarketPoints}<GemIcon sx={{height: 12, width: 16}} />
          <OneMarketPointString />. Sign up to claim those points, save order history and enable fast checkout
          <div style={{ marginTop: 8 }}>
            <AuthorizeUser />
          </div>
        </Alert>
      )
    } else {
      if (user?.id === order.userId) {
        return (
          <Alert severity="success" elevation={3}>
            Your purchase has earned {order.oneMarketPoints}<GemIcon sx={{height: 12, width: 16}} />&nbsp;
            <OneMarketPointString />!
          </Alert>
        )
      }
    }
  } else {
    if (showSignup) {
      return (
        <Alert severity="info" elevation={3}>
          Sign up to save order history and enable fast checkout
          <div style={{ marginTop: 8 }}>
            <AuthorizeUser />
          </div>
        </Alert>
      )
    }
  }

  return null
}