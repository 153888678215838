import React, { useContext, useEffect, useRef, useState } from "react"
import { UserContext } from "../bos_common/src/context/UserContext"
import { AuthResultData, loginWithAuth0Token, storeAuthState } from "../bos_common/src/services/auth0"
import { isEmbeddedMode } from "../utils"


const NativeAuthHandler = () => {
  if (!isEmbeddedMode()) {
    return null
  }

  const [authResponseJson, setAuthResponse] = useState<string>("")
  const authResponseRef = useRef<HTMLInputElement>(null)
  const { authenticating, setAuthenticating, setLoginState } = useContext(UserContext)

  useEffect(() => {
    if (authenticating) {
      const timer = setInterval(() => {
        if (authResponseRef?.current?.value && authResponseRef?.current.value != "") {
          clearInterval(timer)
          setAuthResponse(authResponseRef?.current?.value)
        }
      }, 500);
    }

  }, [authenticating])

  useEffect(() => {
    if (authResponseJson) {
      try {
        const { idToken, cancelled } = JSON.parse(authResponseJson);
        setAuthResponse('');
        if (cancelled) {
          setAuthenticating(false);
        } else {
          loginWithAuth0Token(idToken)
            .then((data: AuthResultData | undefined) => {
              storeAuthState({ token: data?.token })
              setLoginState(data?.user, data?.token)
              setAuthResponse('');
              setAuthenticating(false);
            })
            .catch((err) => {
              setAuthenticating(false);
            })
        }
      } catch (err) {
        console.log(err, 'NativeAuthResponseError')
      }
    }
  }, [authResponseJson])

  return (
    <>
      <input type="hidden" id="auth_response" value={authResponseJson} ref={authResponseRef} />
    </>
  )
}

export default NativeAuthHandler;