

import React from 'react'
import { SvgIcon } from "@material-ui/core";

function GuacamoleIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M15.055 10.214a.486.486 0 00-.566.387 6.588 6.588 0 01-2.366 3.93H3.878a6.548 6.548 0 01-2.464-4.674H15.087a.484.484 0 100-.97h-.977l.227-1.047c.125-.576-.003-1.09-.352-1.411-.35-.321-.873-.406-1.436-.233l-2.189.67a.485.485 0 00.284.927l2.19-.67c.222-.069.407-.061.495.02.087.08.11.264.06.491l-.27 1.254h-2.09l-1.315-1.22a.486.486 0 00-.66.711l.549.509H5.636l-.26-2.877c-.022-.252.027-.367.028-.378.025-.006.145.01.342.15l1.061.752c.014.467.179.755.329.92.203.223.49.347.806.347.286 0 .589-.096.901-.285L12.53 5.28c.529-.32.834-.794.838-1.3.004-.506-.294-.984-.817-1.313L9.575.8C9.258.6 8.938.5 8.623.5c-.75 0-1.309.584-1.393 1.453L6.897 5.41l-.59-.418c-.675-.479-1.152-.333-1.377-.204-.226.13-.594.473-.518 1.308l.251 2.79H2.384l-.364-.895c-.054-.132-.064-.25-.025-.3.041-.054.18-.098.398-.073l1.366.16a.484.484 0 10.113-.962l-1.365-.16c-.55-.067-1.017.098-1.283.449-.253.332-.29.788-.102 1.251l.216.53H.912a.484.484 0 00-.484.485 7.519 7.519 0 002.99 6.029.484.484 0 00.293.099h8.579c.106 0 .209-.036.293-.1a7.557 7.557 0 002.858-4.62.485.485 0 00-.386-.566zM8.623 1.47c.128 0 .28.053.436.151l2.975 1.868c.233.146.365.323.364.485-.001.162-.137.336-.371.479L8.34 6.687c-.208.126-.34.145-.4.145-.061 0-.078-.019-.088-.029-.048-.053-.096-.207-.07-.466l.412-4.291c.017-.174.092-.577.428-.577z" />
    </SvgIcon>
  );
}

export default GuacamoleIcon