import {
  pipe,
  propOr,
  filter,
  pluck,
  reduce,
  flatten,
  uniq,
  without,
  includes,
  keys,
  sum
} from 'ramda'

import { isOverInventoryLimit } from "../../bos_common/src/services/merchandiseUtils";
import { CartItemType, MerchantCartConfig } from '../../context/AppContext';
import { LineItem, Merchandise, MerchandiseModifier, Order } from '../../services/models';
import { isMerchandiseAvailable } from '../../utils';

type checkCartItemsAvailabilityParams = {
  cart: CartItemType[],
  merchantConfig: MerchantCartConfig | undefined
  openCheckOrder?: Order
}

export const checkCartItemsAvailability = (params: checkCartItemsAvailabilityParams): boolean => {
  const { cart, merchantConfig, openCheckOrder } = params
  const cartItemQuantities: { [key: string]: number; } = {}

  cart.forEach((item: CartItemType) => {
    cartItemQuantities[item.id] =
      item.quantity + (cartItemQuantities[item.id] || 0) - getItemsQuantityAlreadyBought(item, openCheckOrder)
  })

  const isAnyCartItemsNotAvailable = pipe(
    propOr([], 'merchandises'),
    filter((merch: Merchandise) => {
      const itemCount = cartItemQuantities[merch.id] || 0
      if (itemCount > 0) {
        const _isOverStockLimit = isOverInventoryLimit(merch, itemCount)
        return (_isOverStockLimit || !isMerchandiseAvailable(merch));
      } else {
        // pass through because this item is not in the order
        return false
      }
    }),
    (x: Merchandise[]) => x.length > 0
  )(merchantConfig);

  const cartUsedModifiersList = pipe(
    pluck('modifiers'),
    reduce((acc: string[], item: any) => [...acc, ...keys(item)], []),
    flatten,
    uniq,
    without([undefined, '', null])
  )(cart)

  const isAnyModifierItemUnavailable = pipe(
    propOr([], 'modifiers'),
    filter((modifier: MerchandiseModifier) => {
      const isModifierSelectedWithinCart = includes(modifier.name, cartUsedModifiersList);
      return isModifierSelectedWithinCart && !modifier?.available
    }),
    (x: MerchandiseModifier[]) => x.length > 0
  )(merchantConfig);

  return isAnyCartItemsNotAvailable || isAnyModifierItemUnavailable
}

export const getItemsQuantityAlreadyBought = (item: CartItemType, openCheckOrder?: Order) => {
  return pipe(
    propOr([], 'lineItems'),
    filter((lineItem: LineItem) => lineItem.id === item.id && lineItem.customizationSignature === item.modifiersSignature),
    pluck('quantity'),
    without(['', undefined, 'null']),
    sum
  )(openCheckOrder)
}