import React, { useContext } from "react";
import { makeStyles, Theme, createStyles, Fab } from "@material-ui/core";
import { Else, If, Then } from "react-if";

// src
import { AppContext } from "../../context/AppContext";
import { isEmptyOrNil, isKioskDevice } from "../../utils";
import { ShoppingCartContext } from "../../context/ShoppingCart/ShoppingCartContext";
import { useAppSelector } from "../../redux/hooks";
import { getUser } from "../../redux/slice/auth/authSelector";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../../services/Tracking/events";
import eventTrackingService from "../../services/Tracking";
import { getEventLabel } from "../../services/Tracking/EventsTracking";
import { Merchant } from "bos_common/src/types/MerchantType";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#F7931E !important',
    },
  }),
);

const PointsRedemptionButton = () => {
  const user = useAppSelector(getUser);
  const { cart, redeemItems, cancelRedemption, merchantConfig } = useContext(AppContext);
  const { pointsRedeemedInfo, appliedRedemption, setApplyRedemption } = useContext(ShoppingCartContext);

  const isKioskModeEnabled = isKioskDevice()
  if (isEmptyOrNil(cart) || pointsRedeemedInfo.redeemPointsToBeApplied < 1 || isKioskModeEnabled) return null

  const classes = useStyles();
  const handleClick = (checked: boolean) => {
    setApplyRedemption(checked)

    if (checked) {
      redeemItems(pointsRedeemedInfo.items)
    } else {
      cancelRedemption();
    }    

    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.SHOPPING_CART,
      action: checked ? EVENT_ACTIONS.CLICK_APPLY_1M_POINTS : EVENT_ACTIONS.CLICK_UNAPPLY_1M_POINTS,
      data: {
        merchant: merchantConfig?.merchant,
        user,
      },
      label: getEventLabel(merchantConfig?.merchant ?? {} as Merchant),
    })
  }

  return (
    <If condition={appliedRedemption}>
      <Then>
        <Fab color="primary" variant="extended" size="large" onClick={() => handleClick(false)} className={classes.root}>
          Cancel 1M points redemption
        </Fab>
      </Then>
      <Else>
        <Fab color="primary" variant="extended" size="large" onClick={() => handleClick(true)} className={classes.root}>
          Apply 1M points to save ${pointsRedeemedInfo.redeemAmount}
        </Fab>
      </Else>
    </If>
  )
}

export default PointsRedemptionButton