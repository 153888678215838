import React, { useEffect } from "react";
import { Divider, Theme, useMediaQuery } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import useAxios from "axios-hooks";
import { useHistory, useParams } from "react-router-dom";
import { Else, If, Then } from "react-if";

import SimpleLoader from "bos_common/src/components/SimpleLoader";
import { MerchandiseApiResponseType, Merchant } from "../services/models";
import FeaturedList from "../components/StoreFront/FeaturedList";
import StoriesList from "../components/StoreFront/StoriesList";
import StoreFrontHero from "../components/StoreFront/StoreFrontHero";
import StoreFrontFooter from "../components/StoreFront/StoreFrontFooter";
import MerchantMediaList from "../components/StoreFront/MerchantMediaList";
import ReviewsList from "../components/StoreFront/ReviewsList";
import StoriesFeed from "../components/StoreFront/StoriesFeed";
import PlatformRaffleDrawer from "../components/PlatformRaffles/PlatformRaffleDrawer";
import StoreFrontHeroSkeleton from "../components/Skeletons/StoreFrontHeroSkeleton";
import MerchandiseListSkeleton from "../components/Skeletons/MerchandiseListSkeleton";
import FollowMerchantCard from "../components/StoreFront/FollowMerchantCard";
import { isFollowMerchantEnabled } from "../utils";
import { isStoreThatHidesStoriesList } from "../services/merchantStoryService";

interface IMerchantPageParams {
  merchantUserName: string;
}


const StoreFrontPage = () => {
  const { merchantUserName } = useParams<IMerchantPageParams>();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [{ data: merchant, loading: merchantLoading }] = useAxios<Merchant>({
    url: "/merchants",
    params: { username: merchantUserName },
  });
  const [{ data: merchandiseData, loading: merchandiseLoading }, fetchMerchandises] = useAxios<MerchandiseApiResponseType>({
    url: "/merchandises",
    params: { merchantId: merchant?.id },
  }, { manual: true });

  useEffect(() => {
    if (merchant) {
      fetchMerchandises();
    }
  }, [merchant])

  const { merchandises = [] } = merchandiseData || {};
  const loading = merchantLoading || merchandiseLoading;

  return (
    <div className={'container'}>
      <SimpleLoader fixed={true} loading={loading} />

      <If condition={merchantLoading}>
        <Then>
          <StoreFrontHeroSkeleton />
          <Divider sx={{ mt: 1 }} />
          <MerchandiseListSkeleton />
        </Then>
      </If>

      {merchant &&
        <>
          <StoreFrontHero merchant={merchant} />
          <Divider sx={{ mt: 1 }} />

          {/* <If condition={isMobile}>
            <FollowMerchantCard merchant={merchant} />
          </If> */}

          <If condition={merchandiseLoading}>
            <Then>
              <MerchandiseListSkeleton />
            </Then>
            <Else>
              <FeaturedList merchant={merchant} merchandises={merchandises} />
            </Else>
          </If>

          <StoriesFeed merchant={merchant} />

          <If condition={merchant.orderingConfig?.showRatings}>
            <Then>
              <ReviewsList merchant={merchant} merchandises={merchandises} />
            </Then>
          </If>

          <MerchantMediaList merchant={merchant} />

          <If condition={!isStoreThatHidesStoriesList(merchant.id)}>
            <StoriesList merchant={merchant} />          
          </If>

          <StoreFrontFooter merchant={merchant} merchandises={merchandises}/>

        </>}
      <PlatformRaffleDrawer />
    </div>
  );
};

export default StoreFrontPage;
