import React, { Dispatch, ReactNode, SetStateAction } from "react";
import {
  createStyles,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";
import { rgba } from "polished";
import { If } from "react-if";

// src
import { isEmptyOrNil } from "../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&.MuiFormControl-root": {
        border: 0,
        background: rgba(theme.palette.info.light, 0.1),
        borderRadius: 20,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: theme.spacing(1.6),
      },

      "& .MuiSelect-root": {
        display: "flex",
        fontSize: 14,
        fontWeight: 600,
      },

      "& .MuiOutlinedInput-notchedOutline": {
        border: 0,
        paddingLeft: 0,
      },

      "& .MuiSelect-outlined": {
        background: "transparent",
        alignItems: "center",
      },

      "& .selectLabel": {
        color: theme.palette.secondary.light,
        fontSize: theme.spacing(1.75),
        fontWeight: 600,
      },
    },
  })
);

interface FilterDropdownProps {
  options: {
    value: string;
    label: string;
    preIcon?: ReactNode;
  }[];
  currentFilter: string;
  setFilter: Dispatch<SetStateAction<string>>;
}

const FilterDropdown = (props: FilterDropdownProps) => {
  const { options, currentFilter, setFilter } = props;
  const classes = useStyles();

  return (
    <>
      <FormControl
        fullWidth
        variant="outlined"
        size="small"
        className={classes.root}
      >
        <Typography className="selectLabel">Sort By</Typography>
        <Select
          value={currentFilter}
          onChange={(e) => setFilter(e.target.value)}
          displayEmpty
          MenuProps={{
            disablePortal: true,
          }}
        >
          {options.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <If condition={!isEmptyOrNil(item.preIcon)}>{item.preIcon}</If>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};


export default FilterDropdown;