import { Merchant } from "../../../services/models";
import { RootState } from "../../store";

export const getStoresToVote = (state: RootState): Merchant[] => {
  const { vote, merchant } = state;

  return vote.entities.storesToVoteIds.map((id) => merchant.entities.byId[id]) || [];
}

export const getVotedStores = (state: RootState): Merchant[] => {
  const { vote, merchant } = state;

  return vote.entities.votedStoreIds.map((id) => merchant.entities.byId[id]);
}

export const getStoreVoteCountById = (merchantId: string): (state: RootState) => number => {
  return (state: RootState): number => {
    const { vote } = state;
    return vote.entities.voteCount[merchantId];
  }
}

export const getStoreVoteCount = (state: RootState): { [key: string]: number } => {
  const { vote } = state;
  return vote.entities.voteCount;
}

export const getVotesRemaining = (state: RootState): number => {
  const { vote } = state;
  return vote.entities.voteRemaining;
}
