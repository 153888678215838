import React from "react";
import { createStyles,Stack,  makeStyles, Theme, Fab, Icon, Typography } from "@material-ui/core";
import { rgba } from "polished";

import { Merchant } from "../../services/models";
import { getMerchantLogoPlaceholder, isEmptyOrNil } from "../../utils";
import ShareButton from "../common/ShareButton";
import { MenuDrawer } from "../common/MenuDrawer";
import { ReactComponent as AppLogoIcon } from '../../assets/icons/logo.svg';
import { useHistory } from "react-router-dom";
import FavoriteButton from "../common/FavoriteButton";
import StoreFrontHeader from "./StoreFrontHeader";
import HeaderTopCTA from "./HeaderTopCTA";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardsWrapper: {
      marginTop: theme.spacing(-4), // offsets into picture
      width: "100%",
      position: 'relative',
    },
    root: {
      minHeight: 300,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      flexDirection: "column",
      alignItems: 'center',
      boxSizing: 'border-box',
      position: "relative",

      '& *': {
        boxSizing: 'border-box'
      },
      
      '& .MuiButtonBase-root': {
        backgroundColor: theme.palette.background.paper,
        transition: 'all 200ms linear',
        '&:hover': {
          backgroundColor: rgba(theme.palette.background.paper, 0.4),
        }
      }
    },
  })
);

type StoreFrontHeroProps = {
  merchant: Merchant;
};

const StoreFrontHero = (props: StoreFrontHeroProps) => {
  const { merchant } = props;

  const classes = useStyles();
  const history = useHistory();

  if (isEmptyOrNil(merchant)) return null;
  const bannerPhoto = merchant?.heroPhoto || getMerchantLogoPlaceholder(merchant || {} as Merchant);

  return (
    <div>
      <div className={classes.root} style={{ backgroundImage: `url(${bannerPhoto})` }}>
        <HeaderTopCTA />
      </div>
      <div className={classes.cardsWrapper}>
        <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{position: 'absolute', right: 25, top: -25}}>
          <FavoriteButton merchant={merchant ?? {} as Merchant} />
          <ShareButton merchant={merchant} />
        </Stack>
        <StoreFrontHeader merchant={merchant} />
      </div>
    </div>
  );
};

export default StoreFrontHero;
