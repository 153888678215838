import React, { useEffect, useState } from 'react';
import { Typography, CardContent, makeStyles, Theme, createStyles, CardMedia, useMediaQuery, Button } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import { head, pathOr } from 'ramda';

import { Merchant } from '../../bos_common/src/types/MerchantType';

import { useAppSelector } from '../../redux/hooks';
import { getUserMerchantPromotionEntries } from '../../redux/slice/marketPlace/marketPlaceSelector';
import { isEmptyOrNil } from '../../utils';
import { UserPromotionEntry } from '../../services/models';
import { LOCALSTORAGE_MARKETPLACE_APP_KEYS } from '../../services/localStorage';
import BottomDrawer from '../../bos_common/src/components/BottomDrawer';
import { differenceInMinutes } from 'date-fns';
import PromotionIllustration from "../../assets/images/congratulations-rewards.svg"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      '& .drawerImageWrapper': {
        textAlign: "center",
        paddingTop: "10px",
        paddingBottom: "10px",
      },
      '& .drawerImage': {
        height: 200,
        objectFit: "cover",
        margin: "auto",
      },
      '& .drawerTitle': {
        textAlign: 'center'
      },
      '& .content': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '& .content-container': {
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        },
      }
    },
    promoContent: {
      maxWidth: 400,
      textAlign: 'center',
      '& .action-btn': {
        width: '100%',
        marginTop: theme.spacing(2)
      },
    },
  }),
);

interface PromoContentProps {
  setOpen: (_: boolean) => void,
  description: string
}

const PromoContent = ({ setOpen, description }: PromoContentProps) => {
  if (!description) return null

  const classes = useStyles()

  return (
    <div className={classes.promoContent}>
      <Typography gutterBottom variant="subtitle1" color="textSecondary" component="div" textAlign="center">
        {ReactHtmlParser(description)}
      </Typography>
      <Button
        color="primary"
        variant="contained"
        onClick={() => setOpen(false)}
        className="action-btn"
      >
        Done
      </Button>
    </div >
  )
}

type MerchantPromotionsDrawer = {
  merchant: Merchant | undefined,
}

const MerchantPromotionsDrawer = ({ merchant }: MerchantPromotionsDrawer): React.ReactElement | null => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [open, setOpen] = useState<boolean>(false)
  const promotionEntries = merchant ? useAppSelector(getUserMerchantPromotionEntries(merchant.id)) : undefined;
  const promotionEntry: UserPromotionEntry | undefined = promotionEntries ? head(promotionEntries) : undefined;

  useEffect(() => {
    if (isEmptyOrNil(promotionEntry) || !merchant) return

    const storageKey = LOCALSTORAGE_MARKETPLACE_APP_KEYS.PROMO_MERCHANT_DRAWER;
    const localStorageData = localStorage.getItem(storageKey) ?? ""
    const visitedDrawers = isEmptyOrNil(localStorageData)
      ? {}
      : JSON.parse(localStorageData)

    const lastDateStr = visitedDrawers[merchant.id];
    if (lastDateStr) {
      const lastTime = Date.parse(lastDateStr);
      if (differenceInMinutes(new Date(), lastTime) < 24 * 60) {
        return;
      }
    }

    visitedDrawers[merchant.id] = new Date().toISOString();
    localStorage.setItem(storageKey, JSON.stringify(visitedDrawers))

    setOpen(true);
  }, [promotionEntry])

  if (!open) {
    return null;
  }

  const title = pathOr('', ['promotion', 'title'], promotionEntry)
  const description = pathOr('', ['promotion', 'description'], promotionEntry)

  return (
    <BottomDrawer
      open={open}
      setOpen={setOpen}
    >
      <div className={classes.drawer}>
        <CardMedia className="drawerImageWrapper">
          <img src={PromotionIllustration} width={150} className="drawerImage" />
        </CardMedia>
        <CardContent className='content'>
          {!isEmptyOrNil(title) && (
            <Typography variant="h6" className="drawerTitle" component="div">
              {title}
            </Typography>
          )}
          {
            !isEmptyOrNil(description) &&
            <div className="content-container">
              <PromoContent setOpen={setOpen} description={description} />
            </div>
          }
        </CardContent>
      </div>
    </BottomDrawer>
  )
}

export default MerchantPromotionsDrawer;