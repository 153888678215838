import React from "react";
import { Box, Card, CardActionArea, Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { path } from "ramda";
import { Link as RouterLink } from "react-router-dom";
import { If } from "react-if";

import TypographyEllipsis from "bos_common/src/components/TypographyEllipsis";
import ImageWithFallbackSrc from "bos_common/src/components/ImageWithFallbackSrc";

import { MerchandiseUnitPrice } from "../MerchantMenu/MerchandisePrice";
import { Merchandise, Merchant } from "../../services/models";
import FireIcon from "../../assets/icons/FireIcon";
import eventTrackingService from "../../services/Tracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../../services/Tracking/events";
import { useAppSelector } from "../../redux/hooks";
import { getUser } from "../../redux/slice/auth/authSelector";
import { getEventLabel } from "../../services/Tracking/EventsTracking";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingRight: theme.spacing(1),
      maxWidth: theme.spacing(18.5),
      width: "100%",
      cursor: "pointer",

      "& .cardThumbnail": {
        boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.07)",
        borderRadius: theme.spacing(1),
        width: theme.spacing(18.5),
        height: theme.spacing(18.5),
        objectFit: "cover",
        margin: 0,
      },
    },
  })
);

type MerchandiseItemCardProps = {
  merchandise: Merchandise;
  merchant?: Merchant;
};

const MerchandiseItemCard = (props: MerchandiseItemCardProps) => {
  const { merchandise, merchant } = props;

  const classes = useStyles();

  const user = useAppSelector(getUser);

  const handleCardClick = () => {
    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.MERCHANT_STORE_FRONT,
      action: EVENT_ACTIONS.CLICK_POPULAR_ITEMS,
      label: getEventLabel(merchant ?? {} as Merchant),
      data: {
        merchant: merchant,
        user,
      },
    });
  }

  const itemPageLink = {
    pathname: `/${merchant?.username}/item/${merchandise.id}`,
    state: { from: window.location.pathname },
  };

  const mode = merchandise.unitsSold > 100 ? 100 : (merchandise.unitsSold > 50 ? 50 : 10);
  const unitsSold = merchandise.unitsSold - merchandise.unitsSold % mode;

  const imagePath = path(["photos", 0], merchandise);

  return (
    <Card
      className={classes.root}
      elevation={3}
      sx={{ boxShadow: "none" }}
      onDragStart={(e) => e.preventDefault()}
      onClick={handleCardClick}
    >
      <CardActionArea
        className="card-action-area"
        component={RouterLink}
        to={itemPageLink}
      >
        <ImageWithFallbackSrc src={`${imagePath}_small`} fallbackSrc={imagePath} className="cardThumbnail" />
        <Box p={1}>
          <TypographyEllipsis>{merchandise.name}</TypographyEllipsis>
          <Typography variant="subtitle2">
            <MerchandiseUnitPrice merchandise={merchandise} />
          </Typography>
          <If condition={merchandise.unitsSold >= 50}>
            <Typography variant="subtitle2">
              {unitsSold}+
              &nbsp;sold&nbsp;
              <FireIcon />
            </Typography>
          </If>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default MerchandiseItemCard;
