import React from "react"
import { Box, Card, createStyles, makeStyles, Skeleton, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingRight: theme.spacing(1),
      maxWidth: theme.spacing(18.5),
      width: "100%",
      cursor: "pointer",
    }
  })
);

const MerchandiseItemCardSkeleton = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Card
      className={classes.root}
      elevation={3}
      sx={{ boxShadow: "none", marginRight: (theme) => theme.spacing(1) }}
      onDragStart={(e) => e.preventDefault()}
    >
      <Skeleton
        sx={{ bgcolor: (theme) => theme.palette.secondary.light, borderRadius: .5 }}
        variant="rectangular"
        width={150}
        height={150}
      />
      <Box p={1}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
    </Card>
  );
}

export default MerchandiseItemCardSkeleton;