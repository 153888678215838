import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory, createHashHistory } from "history";
import { BrowserRouter, HashRouter } from 'react-router-dom';

import { isKioskDevice, isEmbeddedMode } from './utils';
import App from './App';
import './index.css';

import eventTrackingService from './services/Tracking';

if (isKioskDevice() || isEmbeddedMode()) {
  const history = createHashHistory();
  ReactDOM.render(
    <HashRouter>
      <App history={history} />
    </HashRouter>,
    document.getElementById('root')
  );
} else {
  eventTrackingService.initializeTracking();

  const history = createBrowserHistory();
  ReactDOM.render(
    <BrowserRouter>
      <App history={history} />
    </BrowserRouter>,
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
