import React from "react";
import { Card, CardActionArea, Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Link as RouterLink } from "react-router-dom";
import { If } from "react-if";

import { StyledGreyChip } from "../Classifieds/styledComponents";
import { Merchant } from "../../services/models";
import { getMerchantLogoPlaceholder, isEmptyOrNil } from "../../utils";
import eventTrackingService from "../../services/Tracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../../services/Tracking/events";
import { useAppSelector } from "../../redux/hooks";
import { getUser } from "../../redux/slice/auth/authSelector";
import { getEventLabel } from "../../services/Tracking/EventsTracking";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: theme.spacing(0, 0, 1, 1),
      boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.07)",
      margin: theme.spacing(2, 0),

      "& .card-action-area": {
        minHeight: 230,
      },

      "& .cardThumbnail": {
        width: "100%",
        height: theme.spacing(16),
        objectFit: "cover",
        borderRadius: theme.spacing(1, 1, 0, 0),
        marginBottom: 0,
      },

      "& .cardContent": {
        padding: theme.spacing(1),
      },

      "& .cardHeading": {
        overflow: "hidden",
        textOverflow: "ellipsis",
      },

      "& .labels": {
        display: "flex",
        gridGap: theme.spacing(1),
        flexWrap: "wrap",
      },
    },
  })
);

type StoryCardProps = {
  image?: string;
  heading: string;
  labels?: string[];
  merchant?: Merchant;
  redirectId?: string | number;
  externalUrl?: string;
};

const StoryCard = (props: StoryCardProps) => {
  const { image, heading, labels, merchant, redirectId, externalUrl } = props;

  const classes = useStyles();

  const user = useAppSelector(getUser);

  const handleImageError = (e: any) => {
    e.target.onerror = null;
    e.target.src = getMerchantLogoPlaceholder(merchant || ({} as Merchant));
  };

  const handleStoryClick = () => {
    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.MERCHANT_STORE_FRONT,
      action: EVENT_ACTIONS.CLICK_CREDIBLE_MENTIONS,
      label: getEventLabel(merchant ?? {} as Merchant)
    });
  }

  return (
    <Card
      className={classes.root}
      elevation={3}
      onDragStart={(e) => e.preventDefault()}
      onClick={handleStoryClick}
    >
      <CardActionArea
        className="card-action-area"
        component={RouterLink}
        target={externalUrl ? "_blank" : "_self"}
        to={
          externalUrl
            ? { pathname: externalUrl }
            : merchant
              ? `/${merchant?.username}/story/${redirectId}`
              : `/guides/${redirectId}`
        }
      >
        <img className="cardThumbnail" src={image} onError={handleImageError} />

        <div className="cardContent">
          <Typography variant="subtitle2" className="cardHeading">{heading}</Typography>

          <If condition={!isEmptyOrNil(labels)}>
            <div className="labels">
              {labels?.map((data: string, index: number) => {
                return (
                  <StyledGreyChip
                    key={`label-${index}`}
                    variant="filled"
                    size="small"
                    label={data}
                  />
                );
              })}
            </div>
          </If>

        </div>
      </CardActionArea>
    </Card>
  );
};

export default StoryCard;
