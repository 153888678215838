import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'

import { AuthSliceStateEntities, AuthSliceState, AuthUIProperties } from '../../../types/AuthSlice'

type AuthSliceCaseReducer = SliceCaseReducers<AuthSliceState>;

export const authSlice = createSlice<AuthSliceState, AuthSliceCaseReducer, string>({
  name: 'auth',
  initialState: {
    loading: true,
    entities: {
      user: null,
      token: '',
    },
    ui: {
      drawerShown: false,
      signupEntrance: undefined,
    }
  },
  reducers: {
    setLoadingAuth: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      return {
        ...state,
        loading: true
      }
    },

    stopLoadingAuth: (state) => {
      return {
        ...state,
        loading: false
      }
    },

    updateAuth: (state, action: PayloadAction<AuthSliceStateEntities>) => {
      const { payload } = action;

      return {
        ...state,
        loading: false,
        entities: {
          ...payload,
        }
      }
    },

    showSignUpDrawer: (state, action: PayloadAction<AuthUIProperties>) => {
      const { payload } = action;

      return {
        ...state,
        ui: {
          ...payload
        }
      }
    },

    hideSignUpDrawer: (state) => {
      return {
        ...state,
        ui: {
          drawerShown: false,
          signupEntrance: undefined,
        }
      }
    },

    resetAuth: (state) => {
      return {
        ...state,
        loading: false,
        entities: {
          user: null,
          token: '',
        }
      }
    }
  },
});

// Action creators are generated for each case reducer function
export const { 
  setLoadingAuth, 
  stopLoadingAuth, 
  updateAuth, 
  showSignUpDrawer,
  hideSignUpDrawer,
  resetAuth } = authSlice.actions;

export default authSlice.reducer;
