import React from "react";
import LazyLoad from "react-lazyload";
import { Grid } from "@material-ui/core";
import { Else, If, Then } from "react-if";

import { Merchandise, MerchandiseModifier } from "bos_common/src/types/MerchandiseType";
import { MerchantCoupon } from "bos_common/src/types/MerchantCouponType";
import { Merchant } from "bos_common/src/types/MerchantType";
import MenuItemCard from "./MenuItemCard";
import { isZeroAndStore } from 'bos_common/src/services/merchantService';

interface MerchandiseListProps {
  merchandises: Merchandise[];
  merchant: Merchant;
  modifiers: MerchandiseModifier[] | undefined;
  coupon?: MerchantCoupon;
  readOnly?: boolean;
}

const MerchandiseList = ({
  merchandises = [],
  merchant,
  modifiers,
  coupon,
  readOnly = false,
}: MerchandiseListProps): JSX.Element => {

  // sort by available so that unavailable items are listed at the end and filter visible merchandises
  merchandises
    ?.sort((a: Merchandise, b: Merchandise) => {
      if (a.inventory?.dailyCurrentStock === 0) return 1;
      if (b.inventory?.dailyCurrentStock === 0) return -1;

      return (a.displayOrder !== b.displayOrder)
        ? (a.displayOrder > b.displayOrder ? 1 : -1)
        : (a.name > b.name ? 1 : -1);
    })
    .filter((item) => item.visible);

  const renderItemCard = (merchandise: Merchandise) => {
    return (
      <MenuItemCard
        merchandise={merchandise}
        merchant={merchant}
        modifiers={modifiers}
        coupon={coupon}
        readOnly={readOnly}
      />
    );
  };

  return (
    <If condition={merchandises.length > 0}>
      <Then>
        {merchandises.map((merchandise: Merchandise) => (
          <Grid item xs={12} sm={6} lg={4} key={merchandise.id} className="merchandise-grid">
            <If condition={isZeroAndStore(merchant)}>
              <Then>
                {renderItemCard(merchandise)}
              </Then>
              <Else>
                <LazyLoad offset={400} height={200}>
                  {renderItemCard(merchandise)}
                </LazyLoad>
              </Else>
            </If>
          </Grid>
        ))}
      </Then>
    </If>
  );
};

export default MerchandiseList;
