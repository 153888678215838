import React, { SetStateAction } from "react"
import { CartItemType } from "../AppContext"

export interface PointsRedemption {
  redeemPointsToBeApplied: number,
  items: CartItemType[],
  redeemAmount: number,
}

export const defaultRedeemPointsInfo: PointsRedemption = {
  redeemPointsToBeApplied: 0,
  redeemAmount: 0,
  items: []
}

export interface ShoppingCartContextType {
  pointsRedeemedInfo: PointsRedemption,
  appliedRedemption: boolean,
  soldoutItems: CartItemType[],
  setPointsRedemptionInfo: (_: PointsRedemption) => void,
  setApplyRedemption: (_: boolean) => void,
  clearSoldOutItems: () => void,
  shoppingBagError: boolean,
  setShoppingBagError: (_: boolean) => void,
  pickupOptionsShown: boolean,
  showPickupOptions: (_: boolean) => void,
}

export const ShoppingCartContext = React.createContext<ShoppingCartContextType>({
  pointsRedeemedInfo: defaultRedeemPointsInfo,
  soldoutItems: [],
  appliedRedemption: false,
  setPointsRedemptionInfo: (_: SetStateAction<PointsRedemption>) => ({}),
  setApplyRedemption: (_: SetStateAction<boolean>) => ({}),
  clearSoldOutItems: () => ({}),
  shoppingBagError: false,
  setShoppingBagError: (_: SetStateAction<boolean>) => ({}),
  pickupOptionsShown: false,
  showPickupOptions: (_: SetStateAction<boolean>) => ({}),
})
