import React, { useContext, useState, useRef } from 'react';
import { useHistory } from "react-router";
import { CardContent, createStyles, makeStyles, Theme, Fab, Drawer, Alert, Divider, LinearProgress, CircularProgress } from "@material-ui/core";
import { CreditCard } from "@material-ui/icons";
import { If, Then, Else } from 'react-if';

// src
import { getTotalItems, getTotalPrice } from "../../services/cartUtils";
import { Merchant, Order } from '../../services/models';
import createOrderService from '../../services/createOrderService';
import { LOCALSTORAGE_MARKETPLACE_APP_KEYS } from '../../services/localStorage';

import { getAPIErrorMessage } from '../../bos_common/src/utils';
import { ColoredPaper } from '../../bos_common/src/components/Papers';
import { UserContext } from '../../bos_common/src/context/UserContext';
import { FabContainer } from '../../bos_common/src/components/FabContainers';
import renderPrice from "../../bos_common/src/components/Price";
import axios from '../../bos_common/src/services/backendAxios';

import { getLiveMerchantPromotion, isEmptyOrNil } from '../../utils';
import { AppContext } from '../../context/AppContext';
import { ShoppingCartContext } from '../../context/ShoppingCart/ShoppingCartContext';
import BackButton from '../common/BackButton';

import ShoppingCartHeader from './ShoppingCartHeader';
import TipOptions from './TipOptions';
import CartItems from './CartItems';
import CartSummary from './CartSummary';
import StartOverDialog from './StartOverDialog';
import ShoppingCartCouponInput from './ShoppingCartCouponInput';
import { CartBagItemCard } from './CartBagItemCard';
import { OrderPickupTypeOptions } from './OrderPickupTypeOptions';
import eventTrackingService from '../../services/Tracking';
import ShoppingCartRecDrawer from './ShoppingCartRecommendationDrawer';
import { useWindowStorage } from 'bos_common/src/hooks';
import { getEventLabel } from '../../services/Tracking/EventsTracking';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .container': {
        maxWidth: 600,
        width: '100vw',
        '& .drawer-content': {
          position: 'relative',
          paddingBottom: 0,
          paddingTop: theme.spacing(1),
          '& .cta-container': {
            position: 'sticky',
            bottom: theme.spacing(2),
            width: '100%',
            display: "flex",
            flexDirection: "column",
            marginTop: theme.spacing(2),
          },
          '& .empty-cart-message': {
            marginTop: theme.spacing(2),
          },
          '& .fabProgress': {
            position: 'absolute',
            top: '50%',
            left: '80%',
            marginTop: -18,
            marginLeft: -18,
          },
        },
      },
    },
  }),
);

declare let window: any;

type PromiseResponse = {
  reject: (reason?: string) => void,
  resolve: (value?: string) => void,
}
let nativeHandler: PromiseResponse | undefined = undefined;

window.onPaymentMessage = (response: string) => {
  const { error = null } = JSON.parse(response)
  if (nativeHandler) {
    const copyHandler = nativeHandler;
    nativeHandler = undefined;
    if (error) {
      copyHandler.reject(error);
    } else {
      copyHandler.resolve(response);
    }
  }
};

interface ShoppingCartDrawerProps {
  showCart: boolean, setShowCart: (arg0: boolean) => void;
}
const ShoppingCartDrawer = (props: ShoppingCartDrawerProps): React.ReactElement | null => {
  const classes = useStyles();
  const history = useHistory();
  const { showCart, setShowCart } = props

  const appContext = useContext(AppContext);
  const { cart, merchantConfig, tip, coupon, clearCart, orderType } = appContext;
  const { orderingConfig } = merchantConfig?.merchant || {};
  const { user, token, refreshUser } = useContext(UserContext)
  const { soldoutItems, clearSoldOutItems, setShoppingBagError } = useContext(ShoppingCartContext)

  const cartItemCardRef = useRef<HTMLElement>();

  const [loading, setLoading] = useState<boolean>(false)
  const [errorMsg, setError] = useState<string>("")
  const [isRecommendationDrawerOpen, toggleRecommendationDrawer] = useWindowStorage(
    LOCALSTORAGE_MARKETPLACE_APP_KEYS.CART_RECOMMENDATION_DRAWER,
    false,
    'sessionStorage');

  const count = getTotalItems(cart)
  const total = getTotalPrice({ cart, tip, coupon, orderingConfig })

  const sendPromotionSmsNotification = (orderId: string) => {
    const promotion = getLiveMerchantPromotion(merchantConfig?.merchant);
    promotion && axios.post(`orders/${orderId}/promotion-sms`)
  };

  const sendMessageToNative = async (total: number) => {
    setLoading(true)
    return new Promise<string>((resolve, reject) => {
      if (window?.webkit) {
        window?.webkit.messageHandlers.checkoutMessageHandler.postMessage(total);
        nativeHandler = { resolve, reject } as PromiseResponse;
      } else if (window?.checkoutMessageHandler) {
        window.checkoutMessageHandler.postMessage(total)
        nativeHandler = { resolve, reject } as PromiseResponse;
      }
    });
  }

  const handlePaymentResponse = (paymentResJson: string) => {
    if (paymentResJson) {
      const { payment_intent_id: paymentIntentId = null, cancelled, name, phone } = JSON.parse(paymentResJson)

      if (cancelled && cancelled === true) {
        setLoading(false)
        return
      }

      createOrderService({
        appContext,
        paymentIntentId,
        name,
        user,
        token,
        phone
      }).then((results) => {
        setLoading(false);
        if (results.status === 200) {
          const order = results.data as Order
          if (phone)
            sendPromotionSmsNotification(order.id)
          if (!isEmptyOrNil(user))
            refreshUser()

          clearCart()
          history.push({
            pathname: `/order/${order.id}`,
            state: { new: true }
          });

          eventTrackingService.captureCheckoutEvent({
            label: getEventLabel(merchantConfig?.merchant ?? {} as Merchant),
            data: {
              user,
              merchant: merchantConfig?.merchant,
              order
            }
          })
        } else {
          const errorMessage = 'Could not complete order, please contact staff'
          setError(errorMessage);
          eventTrackingService.captureCheckoutEvent({
            label: getEventLabel(merchantConfig?.merchant ?? {} as Merchant),
            data: {
              user,
              merchant: merchantConfig?.merchant,
            },
            errorMessage
          })
        }
      }).catch((err) => {
        const errorMessage = getAPIErrorMessage(err) || `Could not complete order, please contact staff`;
        eventTrackingService.captureCheckoutEvent({
          label: getEventLabel(merchantConfig?.merchant ?? {} as Merchant),
          data: {
            user,
            merchant: merchantConfig?.merchant,
          },
          errorMessage
        })
        setError(errorMessage);
        setLoading(false);
        console.log(err)
      })
    }
  }

  const RenderFabCTA = () => {
    const areCartItemsOutOfOrder = !isEmptyOrNil(soldoutItems)

    const handleOrderCheckout = () => {
      setError("");
      if (orderingConfig?.carryoutBag && !sessionStorage.getItem(LOCALSTORAGE_MARKETPLACE_APP_KEYS.CARRYOUT_BAG_CHOSEN)) {
        setShoppingBagError(true);
        setError("Please choose one of the bag options");
        const offset = cartItemCardRef?.current?.getBoundingClientRect();
        const top = offset?.top;
        window.scrollTo({
          top: (Number(top) - 140),
          behavior: 'smooth',
        })
        return;
      }

      if (window?.webkit || window?.checkoutMessageHandler) {
        sendMessageToNative(total)
          .then((data: string) => {
            handlePaymentResponse(data)
          })
          .catch((err) => {
            //setError(err);
            setLoading(false);
            console.log(err);
          })
      } else {
        history.push("/checkout");
      }
    }

    return (
      <FabContainer alignment="center" fabStyle={{ padding: "24px 0 16px" }}>
        <If condition={areCartItemsOutOfOrder}>
          <Then>
            <Fab color="primary" variant="extended" size="large" disabled={loading} onClick={clearSoldOutItems}>
              Remove unavailable items
            </Fab>
          </Then>
          <Else>
            <Fab color="primary" variant="extended" size="large" disabled={count < 1 || loading} onClick={handleOrderCheckout}>
              <CreditCard fontSize="large" />&nbsp;
              Continue to Payment {renderPrice(total)}
            </Fab>
          </Else>
        </If>
        <If condition={loading}>
          <CircularProgress size={36} className={"fabProgress"} />
        </If>
      </FabContainer>
    )
  }

  const closeDrawer = () => {
    setError("");
    setShowCart(false)
  }

  if (!showCart) {
    return null;
  }

  return (
    <Drawer anchor="right" open={showCart} onClose={closeDrawer} className={classes.root} ModalProps={{ keepMounted: true }}>
      <div className="container">
        <ShoppingCartHeader
          leftChild={<BackButton onBack={closeDrawer} />}
          title="Shopping Cart"
          orderType={orderType}
          merchant={merchantConfig?.merchant}
          errorMessage={errorMsg}
        />

        <If condition={loading}>
          <LinearProgress />
        </If>

        <ColoredPaper>
          <CardContent className="drawer-content">
            <If condition={count === 0}>
              <Then>
                <Alert severity="info" className="empty-cart-message">
                  {"You don't have any item in your cart"}
                </Alert>
              </Then>
              <Else>
                <CartItems />
                <CartBagItemCard cartItemCardRef={cartItemCardRef} toggleRecommendationDrawer={toggleRecommendationDrawer} />
                <OrderPickupTypeOptions />
                <Divider />
                <TipOptions />
                <ShoppingCartCouponInput />
                <Divider />
                <CartSummary />
              </Else>
            </If>

            <RenderFabCTA />
          </CardContent>
        </ColoredPaper>
      </div>
      <StartOverDialog onConfirm={closeDrawer} />

      <ShoppingCartRecDrawer open={isRecommendationDrawerOpen} setOpen={toggleRecommendationDrawer} />
    </Drawer>
  )
}

export default ShoppingCartDrawer
