import React from 'react'
import { SvgIcon } from "@material-ui/core";

function JapaneseFoodIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M12.257 12.52c.174-.274.275-.599.275-.947V7.882h-2.33v-1.15c0-.833-.362-1.599-.986-2.122.257-.429.395-.922.395-1.427V1H4.059v2.183c0 .505.138.998.395 1.427a2.752 2.752 0 00-.986 2.122v5.789h-1.73v.41c0 1.14.928 2.069 2.069 2.069h8.386c1.141 0 2.07-.928 2.07-2.07v-.41h-2.006zm-9.63.821h10.746c-.17.488-.635.839-1.18.839H3.807c-.545 0-1.01-.351-1.18-.839zM4.88 1.821h3.912v1.362c0 .4-.124.79-.35 1.116H5.23a1.957 1.957 0 01-.35-1.116V1.82zm.259 3.299h3.394c.534.365.85.96.85 1.612v1.15H6.95v3.69c0 .35.101.674.275.949H4.289V6.732c0-.652.315-1.247.849-1.612zm6.573 6.453c0 .483-.36.883-.825.948h-2.29a.958.958 0 01-.826-.948V8.702h3.941v2.87z" />
    </SvgIcon>
  );
}

export default JapaneseFoodIcon