import React, { useState } from "react";
import QRCode from 'qrcode.react';
import { Alert, Button, createStyles, Divider, IconButton, makeStyles, Snackbar, Theme, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { FileCopyOutlined, InfoOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";

import { displayOrderShortCode } from "../../bos_common/src/services/orderUtils";
import { getMarketPlaceOrderStatusURL } from "../../bos_common/src/services/urls";
import { Merchant, Order, OrderType } from "../../services/models";
import CopyAddressButton from "../common/CopyAddressButton";
import OrderItems from "../Order/OrderItems";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .section': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        '& .merchantLink': {
          color: theme.palette.text.primary,
        }
      },
      '& .leftRight': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      '& .qrcode': {
        marginTop: theme.spacing(1),
      },
      '& .shareBtn': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      '& .MuiSnackbar-root': {
        justifyContent: 'center',
      },
      '& .merchant-address': {
        display: 'flex',
        alignItems: 'center',
        lineHeight: '1.2',
        '& .MuiIconButton-root': {
          padding: 0,
          marginLeft: theme.spacing(0.5),
        }
      }
    },
  }),
);

const RenderOrderReceipt = ({ order }: { order: Order }) => {
  return (
    <div className={"section"}>
      <Typography gutterBottom variant="subtitle1" color="textSecondary" component="div">
        Receipt
      </Typography>
      <OrderItems order={order} />
      <Typography variant="body2" component="div" color="textSecondary" display="flex">
        <InfoOutlined fontSize="small"/>&nbsp;&nbsp;It will show as 1M.APP on your statement
      </Typography>
    </div>
  )
}

type PickUpDetails = {
  order: Order;
  merchant: Merchant;
}
const PickupDetails = (props: PickUpDetails): React.ReactElement => {
  const { order, merchant } = props
  if (order.type !== OrderType.PICKUP) {
    return <RenderOrderReceipt order={order} />
  }

  const theme = useTheme()
  const classes = useStyles()
  const url = getMarketPlaceOrderStatusURL(order)
  const [openAlert, setOpenAlert] = useState(false)

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  };

  const shareUrl = async () => {
    if (navigator.share) {
      await navigator.share({
        url: url,
        title: 'Share your order',
      })
    } else if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(url)
        setOpenAlert(true)
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <div className={classes.root}>
      <div className="section">
        <Typography gutterBottom variant="subtitle1" color="textSecondary" component="div">
          Pickup Details
        </Typography>
        <Typography variant="body2" color="textSecondary" component="div" style={{ lineHeight: '1.2' }}>
          <Link className="merchantLink" to={`/${merchant.username}`}>{merchant.officialName}</Link>
        </Typography>
        <Typography variant="body2" color="textPrimary" component="div" className={'merchant-address'}>
          {merchant.address}&nbsp;<CopyAddressButton merchant={merchant} />
        </Typography>
      </div>

      <Divider />
      <div className={"section"}>
        <div className="leftRight">
          <div>
            <Typography gutterBottom variant="subtitle1" color="textSecondary" component="div">
              Pickup Code
            </Typography>
            <Typography variant="body2" component="div">
              {displayOrderShortCode(order)}
            </Typography>
            <Button variant="outlined" color="primary" size="small" className="shareBtn" onClick={shareUrl}>
              Share
            </Button>
          </div>
          <div className="qrcode">
            <QRCode
              value={url}
              size={100} />
          </div>
        </div>
      </div>
      <Divider />

      <RenderOrderReceipt order={order} />

      <Snackbar open={openAlert} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Link copied to your clipboard
        </Alert>
      </Snackbar>
    </div>
  )
}

export default PickupDetails