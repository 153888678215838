import React, { useEffect, useState } from "react";
import { createStyles, Divider, makeStyles, Theme } from "@material-ui/core";
import useAxios from "axios-hooks";
import { propOr } from "ramda";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { Else, If, Then } from "react-if";

import ContentWithFloatingVisual from "../components/common/ContentWithFloatingVisual";
import SimpleLoader from "../bos_common/src/components/SimpleLoader";
import LocalGuideCard from "../components/LocalGuide/LocalGuideCard";
import { isEmptyOrNil } from "../utils";
import { MERCHANT_LOGO_PLACEHOLDER_DATA } from "../config/constants";
import { MerchantGuide, MerchantGuideRecommendation } from "../services/models";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .dialogImage": {
        maxHeight: "100vh",

        [theme.breakpoints.up("md")]: {
          height: "100%",
        },
      },

      "& .dialogContent": {
        maxWidth: 600,

        "& .title": {
          color: theme.palette.primary.main,
        },

        "& .heading": {
          marginBottom: theme.spacing(0.5),
        },

        "& .subHeading": {
          color: theme.palette.secondary.light,
        },
      },

      "& .illustration": {
        [theme.breakpoints.between("sm", "md")]: {
          display: 'block',
        }
      },
    },
  })
);

interface IMerchantGuideParams {
  guideId?: string;
}

const LocalGuidePage = () => {
  const classes = useStyles();

  const { guideId } = useParams<IMerchantGuideParams>();
  const history = useHistory();

  const [currentGuide, setCurrentGuide] = useState<MerchantGuide>();

  const [{ data: guideData, loading }, executeGet] = useAxios(
    {
      url: `/merchants/local-guides`,
      method: "get",
    },
    { manual: true }
  );

  useEffect(() => {
    executeGet()
      .then((response) => {
        if (isEmptyOrNil(response.data) || response.status !== 200) {
          history.push("/notfound");
        }
      })
      .catch(() => history.push("/notfound"));
  }, []);

  useEffect(() => {
    const guide = guideData?.find(
      (item: MerchantGuide) => item.id.toString() === guideId
    );

    if (!isEmptyOrNil(guideData) && !guide) {
      history.push("/notfound");
      return;
    }
    setCurrentGuide(guide);
  }, [guideData]);

  const recommendationsList = propOr([], 'recommendations', currentGuide).sort((a: MerchantGuideRecommendation, b: MerchantGuideRecommendation) => (a?.displayOrder || 0) - (b?.displayOrder || 0))

  return (
    <If condition={loading}>
      <Then>
        <SimpleLoader loading={loading} />
      </Then>
      <Else>
        <ContentWithFloatingVisual
          contentClassName={classes.root}
          illustration={
            <img
              className="dialogImage"
              src={currentGuide?.heroPhoto || MERCHANT_LOGO_PLACEHOLDER_DATA['american']}
              alt="Dialog visual"
            />
          }
          title="Local Top Guides"
          heading={currentGuide?.title}
          subHeadings={[currentGuide?.subtitle || ""]}
        >
          <div>
            <Divider sx={{ mb: 2, mt: 2 }} />
            {
              recommendationsList.map((data: MerchantGuideRecommendation, index: number) => {
                const { merchant, merchandise } = data;

                return (
                  <>
                    <LocalGuideCard
                      currentIndex={index}
                      merchant={merchant}
                      merchandise={merchandise}
                      recommendation={data}
                    />

                    <If condition={index !== recommendationsList.length - 1}>
                      <Divider sx={{ mb: 2, mt: 2 }} />
                    </If>
                  </>
                );
              })}
          </div>
        </ContentWithFloatingVisual>
      </Else>
    </If>
  );
};

export default LocalGuidePage;
