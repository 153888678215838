import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { MerchantStory, StoryComment } from "bos_common/src/types/MerchantStoryType";
import { Merchant } from "bos_common/src/types/MerchantType";
import { deleteCommentMerchantStory, getMerchantStories, getMerchantStoryComments, getMerchantStoryLiked, postCommentMerchantStory, postLikeMerchantStory } from "../../../services/merchantStoryApiService";
import { getAuthHeaders } from "../../../utils";
import { RootState } from "../../store";
import { getToken } from "../auth/authSelector";

export interface RemoveCommentPayload {
  id: number;
  commentId: number;
}

export interface FetchMerchantStoryCommentsParams {
  story: Partial<MerchantStory>;
  page?: number;
  dateCursor?: Date;
}

export const commentRemoved = createAction<RemoveCommentPayload>('merchantStory/removeComment');

export const fetchMerchantStories = createAsyncThunk(
  'merchantStory/fetchMerchantStories',
  async (merchant: Partial<Merchant>) => {
    if (!merchant?.username) {
      throw new Error("Merchant's username not provided");
    }
    const response = await getMerchantStories(merchant.username);

    return response.data;
  }
);

export const likeMerchantStory = createAsyncThunk(
  'merchantStory/likeMerchantStory',
  async (story: Partial<MerchantStory>, thunkAPI) => {
    if (!story?.id) {
      throw new Error("Merchant story's id not provided");
    }

    const state: RootState = thunkAPI.getState() as RootState;
    const token = getToken(state);
    const response = await postLikeMerchantStory(story.id, { headers: getAuthHeaders(token) });

    return [response.data];
  }
);

export const fetchMerchantStoryComments = createAsyncThunk(
  'merchantStory/fetchMerchantStoryComments',
  async (params: FetchMerchantStoryCommentsParams) => {
    const { story, page } = params;
    if (!story?.id) {
      throw new Error("Merchant story's id not provided");
    }

    const response = await getMerchantStoryComments(story, { pageNumber: page ?? 1, pageSize: 20 });

    return response.data;
  }
);

export const commentMerchantStory = createAsyncThunk(
  'merchantStory/commentMerchantStory',
  async (storyComment: Partial<StoryComment>, thunkAPI) => {
    if (!storyComment?.merchantStoryId && !storyComment?.content) {
      throw new Error("Missing required params");
    }

    const state: RootState = thunkAPI.getState() as RootState;
    const token = getToken(state);
    const response = await postCommentMerchantStory(storyComment, { headers: getAuthHeaders(token) });

    return [response.data];
  }
);

export const removeMerchantStoryComment = createAsyncThunk(
  'merchantStory/removeMerchantStoryComment',
  async (storyComment: StoryComment, thunkAPI) => {
    if (!storyComment) {
      throw new Error("Missing required params");
    }

    const state: RootState = thunkAPI.getState() as RootState;
    const token = getToken(state);
    await deleteCommentMerchantStory({ id: storyComment.merchantStoryId, commentId: storyComment.id }, { headers: getAuthHeaders(token) });
    thunkAPI.dispatch(commentRemoved({ id: storyComment.merchantStoryId, commentId: storyComment.id }));

    return [storyComment];
  }
);

export const fetchCheckStoryLiked = createAsyncThunk(
  'merchantStory/isStoryLiked',
  async (story: MerchantStory, thunkAPI) => {
    if (!story) {
      throw new Error("Missing required params");
    }

    const state: RootState = thunkAPI.getState() as RootState;
    const token = getToken(state);
    const response = await getMerchantStoryLiked(story.id, { headers: getAuthHeaders(token) });

    return response.data;
  }
);
