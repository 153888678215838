import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Card, Button, makeStyles, createStyles, Box, Theme } from '@material-ui/core'
import { If } from "react-if"
import { rgba } from 'polished'
import classNames from 'classnames'

import ConfirmationAlert from 'bos_common/src/components/ConfirmationAlert'

import { AppContext, CartType } from '../../context/AppContext'
import { OrderType, Merchant } from '../../services/models'
import { enabledReservations } from '../../utils'

interface OrderOption {
  type: OrderType,
  label: string,
  disabled?: boolean
}

interface IMerchantPageParams {
  merchantUserName: string,
  merchandiseId?: string,
}

const ORDER_OPTIONS: OrderOption[] = [
  {
    type: OrderType.PICKUP,
    label: 'Pickup',
  },
  {
    type: OrderType.RESERVATION,
    label: 'Reservation',
  }
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxRootStyle: {
    },
    cardRootStyle: {
      "&.MuiCard-root": {
        width: 'max-content',
        marginLeft: '50%',
        transform: 'translate(-50%, 0)',
        padding: 4,
        marginBottom: 3,
      }
    },
    buttonRootStyle: {
      "&.MuiButton-root": {
        height: 35,
        borderRadius: 8,
        textTransform: 'unset',
        padding: '4px 8px',
        '&:not(:last-child)': {
          margin: '0 4px 0 0',
        },
        '&.primary': {
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: 'none',
        },
        '&.selected': {
          background: rgba(theme.palette.primary.main, 0.15),
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: 8,
          color: theme.palette.primary.main
        },
        "&.Mui-disabled": {
          color: 'rgba(0, 0, 0, 0.26)'
        }
      }
    }
  })
)

const OrderOptionsGroup = (props: {
  merchant?: Merchant
}): React.ReactElement => {
  const history = useHistory()
  const location = useLocation();
  const { merchantUserName } = useParams<IMerchantPageParams>()
  const { merchant } = props
  const { setCartType, setOrderType, orderType, featureFlags, tableQrCodeObject } = useContext(AppContext)
  const { boxRootStyle, cardRootStyle, buttonRootStyle } = useStyles()
  const [showOrderTypeAlert, setShowOrderTypeAlert] = useState<boolean>(false)
  const [orderOptions, setOrderOptions] = useState<OrderOption[]>([])
  const isReservationFeatureEnabled = enabledReservations(featureFlags, merchant)

  useEffect(() => {
    const res = isReservationFeatureEnabled ? [...ORDER_OPTIONS] : [...ORDER_OPTIONS.slice(0, 1)];
    if (tableQrCodeObject) res.unshift({
      type: OrderType.DINEIN,
      label: 'Dine-In',
    });
    setOrderOptions(res)
  }, [tableQrCodeObject?.id, isReservationFeatureEnabled])

  const changeOrderTypeToPickUp = () => {
    setOrderType(OrderType.PICKUP)
    setCartType(CartType.REGULAR)
    history.push(`/${merchantUserName}`)
    setShowOrderTypeAlert(false)
  }

  const handleSwitchOption = (order: OrderOption) => {
    if (order.type !== orderType) {
      switch (order.type) {
        case OrderType.PICKUP:
          if (orderType === OrderType.DINEIN) {
            setShowOrderTypeAlert(true)
          } else {
            setOrderType(order.type)
            history.replace({
              pathname: `/${merchantUserName}`,
              search: location.search,
            })
          }
          break
        case OrderType.DINEIN:
          break;
        case OrderType.RESERVATION:
          setOrderType(order.type)
          history.replace({
            pathname: `/${merchantUserName}/reserve`,
            search: location.search,
          })
          break;
        default:
          setOrderType(order.type)
          break
      }
    }
  }

  return (
    <Box className={boxRootStyle}>
      <If condition={orderType === OrderType.DINEIN}>
        <ConfirmationAlert
          title="Would you like to switch from Dine in to Pick up?"
          isOpen={showOrderTypeAlert}
          onConfirm={changeOrderTypeToPickUp}
          onCancel={() => setShowOrderTypeAlert(false)} >
          {''}
        </ConfirmationAlert>
      </If>
      <Card classes={{root: cardRootStyle}}>
      {
        orderOptions.map((orderOption: OrderOption) =>
          <Button
            classes={{
              root: classNames([
                buttonRootStyle,
                orderType === orderOption.type
                  ? 'selected'
                  : 'primary'
              ])
            }}
            onClick={() => handleSwitchOption(orderOption)}
            key={orderOption.type}
            disabled={orderOption.disabled}
          >
            {orderOption.label}
          </Button>
        )
      }
      </Card>
    </Box>
  )
}

export default OrderOptionsGroup
