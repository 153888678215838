import React, { useContext, useEffect, useMemo } from "react"
import { Else, If, Then } from "react-if";
import { propOr } from "ramda";
import useAxios from "axios-hooks";
import { Chat, Email, Star } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { CardContent, Chip, createStyles, IconButton, List, makeStyles, Stack, Theme, Typography } from "@material-ui/core";

import { UserContext } from "bos_common/src/context/UserContext";
import SimpleLoader from "bos_common/src/components/SimpleLoader";
import { MerchantFollower } from "bos_common/src/types/MerchantFollower";
import { ColoredPaper, getAuthHeaders, isEmptyOrNil } from "bos_common/src";

import CountBox from "../components/common/CountBox";
import PageHeader from "../components/common/PageHeader";
import { headerCustomStyle } from "../styles/Common.style";
import { FavoriteData, Merchant } from "../services/models";
import HorizontalContentCard from "../components/HorizontalContentCard";
import FloatingSectionsHeader from "../components/common/FloatingSectionsHeader";
import SubscriptionSettingsDrawer from "../components/SubscriptionSettingsDrawer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&.MuiPaper-root": {
        borderRadius: theme.spacing(2.5, 2.5, 0, 0),
        zIndex: 1,
      }
    },

    navWrapper: {
      ...headerCustomStyle(theme, theme.palette.primary.main),
    },
  })
);

enum SettingsType {
  subscription = "subscription",
  favorites = "favorites"
}

const SubscriptionSettingsPage = () => {
  const classes = useStyles();
  const { user, authenticating, token } = useContext(UserContext);

  const history = useHistory();

  const handleCardClick = (path: string) => {
    history.push({ pathname: path, state: { from: window.location.pathname } })
  }

  const [{ data: followedMerchants, loading }, fetchFollowedMerchants] = useAxios<MerchantFollower[]>(
    {
      url: `/users/followedStores`,
      method: "get",
      headers: getAuthHeaders(token),
    },
    { manual: true }
  );

  const [{ data: favoritesMerchants, loading: favoritesLoading }, fetchFavoritesMerchants] = useAxios<FavoriteData>(
    {
      url: `/favorites/data`,
      method: "get",
      headers: getAuthHeaders(token),
    },
    { manual: true }
  );

  const getChips = (data: MerchantFollower) => {
    return (
      <Stack direction="row" gap={1}>
        <If condition={data.smsEnabled}>
          <Then>
            <Chip
              variant="filled"
              sx={{height: (theme: Theme) => theme.spacing(3)}}
              label="SMS"
              icon={<Chat sx={{width: 15, height: 15}} />}
            />
          </Then>
        </If>

        <If condition={data.emailEnabled}>
          <Then>
            <Chip
              variant="filled"
              sx={{height: (theme: Theme) => theme.spacing(3)}}
              label="Email"
              icon={<Email sx={{width: 15, height: 15}} />}
            />
          </Then>
        </If>
      </Stack>
    )
  }

  const getSettingsCards = (type: SettingsType) => {
    if (type === SettingsType.subscription) {
      return followedMerchants?.map((data) => {
        const { merchant } = data;
        return (
          <HorizontalContentCard
            key={merchant.id}
            cardTitle={merchant.officialName}
            cardSubTitle={merchant.address || ""}
            handleCardClick={() => handleCardClick(`/subscription-settings/${merchant.id}`)}
            extraContent={getChips(data)}
            merchant={merchant}
          />
        )
      })
    }

    const merchants = propOr([], "merchants", favoritesMerchants);
    return merchants.map((merchant: Merchant) => (
      <HorizontalContentCard
        key={merchant.id}
        cardTitle={merchant.officialName}
        cardSubTitle={merchant.address || ""}
        handleCardClick={() => handleCardClick(`/${merchant.username}`)}
        merchant={merchant}
      />
    ))
  }

  useEffect(() => {
    if (isEmptyOrNil(user)) return;

    fetchFollowedMerchants({
      data: {
        user: user,
      }
    })
    fetchFavoritesMerchants({
      url: `/favorites/data?userId=${user?.id}`
    })
  }, [user])

  const sections = useMemo(() => [
    {
      title: 'Subscriptions',
      key: 'subscriptions',
      type: SettingsType.subscription
    },
    {
      title: 'Favorites',
      key: 'favorites',
      type: SettingsType.favorites
    },
  ].map((section) => ({
    ...section,
    list: getSettingsCards(section.type),
  })), [followedMerchants, favoritesMerchants]);

  return (
    <div className="container">
      <SimpleLoader loading={authenticating || loading} />
      <div className={classes.navWrapper}>
        <PageHeader
          title='Subscriptions & Favorites'
          leftChild={<IconButton> <Star fontSize="large" /> </IconButton>}
        />
      </div>

      <ColoredPaper className={classes.root}>
        <If condition={!isEmptyOrNil(user)}>
          <CardContent sx={{ padding: 0 }}>
            <FloatingSectionsHeader sections={sections} />

            {
              sections.map((data) => {
                const { key, title, list } = data;

                return (
                  <div key={key} id={key} className="ordersList">
                    <Typography sx={{ px: 3 }} gutterBottom variant="h6" component="div" className="sectionHeader">
                      {title} <CountBox count={list?.length || 0} />
                    </Typography>

                    <List sx={{ px: 3, py: 1 }}>
                      <If condition={isEmptyOrNil(list)}>
                        <Then>
                          <Typography>No data available</Typography>
                        </Then>
                        <Else>
                          {
                            list?.map((item: React.ReactElement) => item)
                          }
                        </Else>
                      </If>
                    </List>
                  </div>
                )
              })
            }
          </CardContent>
        </If>

        <SubscriptionSettingsDrawer
          followedMerchants={followedMerchants}
          fetchFollowedMerchants={fetchFollowedMerchants}
        />
      </ColoredPaper>
    </div>
  )
}

export default SubscriptionSettingsPage;
