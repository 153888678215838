import { makeUseAxios } from 'axios-hooks';
import Axios from 'axios';
import LRU from 'lru-cache';


let backendBaseURL = 'http://localhost:7778';
if (process.env.NODE_ENV === 'production') {
  backendBaseURL = 'https://reservation-api.1m.app';
  if (process.env.REACT_APP_IS_STAGING === '1') {
    backendBaseURL = 'https://staging.reservation-api.1m.app';
  }
}

const apiBaseURL = `${backendBaseURL}/api`
export const backendURL = backendBaseURL
export const backendAPIURL = apiBaseURL

export const axios = Axios.create({
  baseURL: apiBaseURL,
});

const cache = new LRU({ max: 10, maxAge: 120 * 1000 });
const useAxios = makeUseAxios({ cache, axios });
export default useAxios;
