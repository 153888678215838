import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, createStyles, Fab, LinearProgress, makeStyles, Stack, Theme, Typography } from '@material-ui/core';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { If, Then } from 'react-if';
import { compareAsc, format } from 'date-fns';
import {
  PermIdentity as PermIdentityIcon,
  CalendarToday as CalendarTodayIcon,
  AccessTime as AccessTimeIcon,
  Clear as ClearIcon,
} from "@material-ui/icons"

import { FullscreenPaper } from 'bos_common/src/components/Papers';
import { OccasionEnum, ReservationStatus } from 'bos_common/src/types/ReservationTypes';

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchReservationCancel, fetchReservationDetail } from "../../redux/slice/reservation/reservationAction";
import { fetchMerchant } from "../../redux/slice/merchant/action";
import { getReservation, getReservationLoadingState } from '../../redux/slice/reservation/reservationSelector';
import { getMerchant, getMerchantLoadingState } from '../../redux/slice/merchant/merchantSelector';
import CloseButton from '../common/CloseButton';
import PageHeader from '../common/PageHeader';
import { getTimeLabelFromOffset } from '../../utils';
import { TimerSelectChip } from '../Classifieds/styledComponents';
import CopyAddressButton from "../common/CopyAddressButton";
import { axios } from 'bos_common/src/services/reservationBackendAxios';


interface IReservationStatusPageParams {
  reservationId: string,
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    minHeight: '100vh',

    '& .content': {
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2),
      '& .MuiTypography-root': {
        color: 'white',
        textAlign: 'center',
      },
      '& .chips': {
        width: '100%',
        padding: `0 8px`,
        border: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
        color: 'white'
      },
      '& .copyAddressButton': {
        '& .MuiSvgIcon-root': {
          color: 'white',
        }
      },
    },
  },
  btns: {
    position: 'absolute',
    bottom: theme.spacing(10),
  }
}))

const ReservationStatusPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{ from?: { pathname: string }, new: boolean }>()
  const { reservationId } = useParams<IReservationStatusPageParams>()

  const from = location.state?.from;
  const newReservation = location.state?.new;

  const reduxDispatch = useAppDispatch();
  const reservation = useAppSelector(getReservation)(reservationId);
  const reservationLoading = useAppSelector(getReservationLoadingState)
  const merchantLoading = useAppSelector(getMerchantLoadingState);
  const merchant = useAppSelector(getMerchant)(reservation?.merchantId || '');
  const loading = reservationLoading || merchantLoading;

  useEffect(() => {
    reduxDispatch(fetchReservationDetail(reservationId));
  }, [reservationId])

  useEffect(() => {
    if (reservation) {
      reduxDispatch(fetchMerchant({ id: reservation.merchantId }));
    }
  }, [reservation?.merchantId])

  const onClose = () => {
    // if (needToSignup()) {
    //   setSignupEntrance(SignupEntrance.SAVE_ORDER)
    //   setShowDrawer(true)
    // } else {
      exitPage()
    //}
  }

  const exitPage = () => {
    if (from?.pathname === location.pathname || !from) {
      history.push(`/${merchant?.username}/reserve`);
    }
    else {
      history.goBack();
    }
  }

  const handleCancelOrder = () => {
    if (reservation && compareAsc(new Date(reservation?.beforeCancelTime), new Date()) >= 0) {
      reduxDispatch(fetchReservationCancel(reservationId))
    } else {
      console.log('Order cannot be canceled')
    }
  }


  return (
    <FullscreenPaper className={classes.root}>
      <PageHeader theme="dark" title={"Reservation"} leftChild={<CloseButton onClose={onClose} />} />
      <If condition={loading}>
        <Then><LinearProgress /></Then>
      </If>
      {reservation && (
        <Stack className="content" spacing={5}>
          <Stack spacing={1}>
            <Typography variant="body2">Check-in code</Typography>
            <Typography variant="h3">{reservation.ackNo}</Typography>
            <Typography variant="h5">{reservation.displayName}</Typography>
          </Stack>
          <Stack spacing={1}>
            <Stack direction="row" spacing={1} >
              <TimerSelectChip className={"chips"}
                label={
                  <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    <PermIdentityIcon sx={{ mr: 0.75 }} fontSize="small" />
                    {`Party for ${reservation.peopleNum}`}
                  </Stack>
                } />

              <TimerSelectChip className={"chips"}
                label={
                  <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    <CalendarTodayIcon sx={{ mr: 0.75 }} fontSize="small" />
                    {format(new Date(reservation.dinnerDate || new Date()), 'EEE, MMM dd')}
                  </Stack>
                } />
            </Stack>
            <Stack direction="row" spacing={1}>
              <TimerSelectChip className={"chips"}
                label={
                  <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    <AccessTimeIcon sx={{ mr: 0.75 }} fontSize="small" />
                    {getTimeLabelFromOffset(reservation.dinnerTime || 0)}
                  </Stack>
                } />
              {
                reservation.occasion !== 0 && <TimerSelectChip className={"chips"}
                  label={
                    <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                      {OccasionEnum[reservation.occasion || 1]}
                    </Stack>
                  } />
              }
            </Stack>
          </Stack>
          {merchant && (
            <Stack direction="column" alignItems={"center"} spacing={1}>
              <Typography gutterBottom variant="subtitle1" color="textSecondary" component="div">
                AT
              </Typography>
              <Typography variant="h5" color="textSecondary" component="div" style={{ lineHeight: '1.2' }}>
                <Link className="merchantLink" to={`/${merchant.username}/reserve`}>{merchant.officialName}</Link>
              </Typography>
              <TimerSelectChip
                className={"chips"}
                label = {<>{merchant.address}&nbsp;<CopyAddressButton merchant={merchant} /></>}
              />
            </Stack>
          )}
          <Stack className={classes.btns} spacing={1}>
            {
              reservation.status !== ReservationStatus.Cancelled
                ?  (compareAsc(new Date(reservation.beforeCancelTime), new Date()) >= 0)
                    ? (
                      <Fab color="primary" sx={{pl: 5, pr: 5}}  variant="extended" onClick={() => { handleCancelOrder() }}>
                        <ClearIcon sx={{ mr: 1 }} fontSize="small" />
                        Cancel
                      </Fab>
                      )
                    : (<Typography variant="body1">Order cannot be canceled</Typography>)
                : (<Typography variant="body1">The order has been canceled</Typography>)
            }
          </Stack>
        </Stack>
      )}
    </FullscreenPaper>
  )
}

export default ReservationStatusPage