import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { includes, map, pathOr, pipe, propOr, reject } from 'ramda';
import { rgba } from 'polished';
import { If } from 'react-if';
import classNames from 'classnames';

import { UserContext } from '../bos_common/src/context/UserContext';
import { ColoredPaper } from '../bos_common/src/components/Papers';
import { displayOrderStatus, getItemsInOrder, sortOrders } from '../bos_common/src/services/orderUtils'
import axios from '../bos_common/src/services/backendAxios';
import renderPrice from "../bos_common/src/components/Price";
import SimpleLoader from '../bos_common/src/components/SimpleLoader';
import PageHeader from '../components/common/PageHeader';
import { getAuthHeaders } from '../bos_common/src';

import { LineItem, Merchant, Order, OrderStatus, OrderType } from '../services/models'
import { displayDate, isEmptyOrNil } from '../utils';
import { headerCustomStyle } from '../styles/Common.style';
import FloatingSectionsHeader from '../components/common/FloatingSectionsHeader';
import CheckIcon from '../assets/icons/CheckIcon';
import CountBox from '../components/common/CountBox';
import CustomizedChip from '../components/common/CustomizedChip';
import GemMonoIcon from '../assets/icons/GemMonoIcon';
import { ReviewsOutlined } from '@material-ui/icons';
import HorizontalContentCard from '../components/HorizontalContentCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&.MuiPaper-root": {
        borderRadius: theme.spacing(2.5, 2.5, 0, 0),
        zIndex: 1,
      },

      '& .ordersList': {
        position: "relative",
        zIndex: 10,
        background: theme.palette.background.paper,
      },

      '& .review-btn': {
        backgroundColor: rgba(theme.palette.error.main, 0.1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,

        '& .MuiButton-label': {
          textTransform: "none",
          display: 'flex',
          justifyContent: 'space-between',
          color: theme.palette.error.main,

          '& .MuiChip-root': {
            fontSize: 14
          }
        },
      },

      '& .sectionHeader': {
        fontWeight: 600,
        fontSize: "18px",
        display: "flex",
        gap: "10px",
        alignItems: "center",
      },

      '& .orderDetails': {
        '& .orderTime': {
          fontWeight: 600,
          fontSize: "14px",
        },

        '& .orderPrice': {
          fontSize: "14px",
          fontWeight: "normal",
          color: theme.palette.info.light,
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "8px",
        }
      },

      '& .orderType': {
        padding: `${theme.spacing(0.3)} ${theme.spacing(0.8)}`,
        borderRadius: "3px",
        fontSize: '10px',

        '&.dark': {
          background: theme.palette.primary.main,
          color: "#fff",
        },

        '&.light': {
          background: rgba(theme.palette.primary.main, 0.15),
          color: theme.palette.primary.main,
        }
      },

      '& .customChip': {
        background: rgba("#3ADD92", 0.15),
        color: "#3ADD92",
        padding: `${theme.spacing(0.3)} ${theme.spacing(0.8)}`,
        borderRadius: "3px",
        fontSize: '10px',

        '&.grey': {
          background: rgba(theme.palette.common.black, 0.15),
          color: theme.palette.common.black,
        }

      }
    },

    navWrapper: {
      ...headerCustomStyle(theme, theme.palette.primary.main),
    },
  })
);

interface OrderData {
  orders: Order[];
  merchants: Merchant[];
}

interface OrderTransformed {
  orders: Order[];
  merchantLookup: { [key: string]: Merchant; };
}

const getOrderType = (orderType: string) => {
  switch (orderType) {
    case OrderType.DINEIN:
      return 'Dine In'
    case OrderType.PICKUP:
      return 'Pickup'
    case OrderType.RESERVATION:
      return 'Reservation'
    default: return ''
  }
}

const getChipClass = (orderType: string) => {
  switch (orderType) {
    case OrderType.PICKUP:
      return 'light'
    case OrderType.DINEIN:
    case OrderType.RESERVATION:
    default:
      return 'dark'
  }
}

const orderSummary = (order: Order) => {
  const totalItems = getItemsInOrder(order, true);
  const orderStatus = displayOrderStatus(order)
  const orderStatusClasses = classNames({
    'customChip': true,
    'grey': orderStatus === 'Refunded'
  })

  return (
    <div className="orderDetails">
      <div className="orderTime">
        {displayDate(order.createdAt)}
      </div>
      <div className="orderPrice">
        {renderPrice(order.amount)} <span>({totalItems} items)</span>
        <span className={`orderType ${getChipClass(order.type)}`}>{getOrderType(order.type)}</span>
        <span className={orderStatusClasses}>{orderStatus}</span>
      </div>
    </div>
  )
}

const hasNoLineItemWithReview = (order: Order, merchantLookup: { [key: string]: Merchant } | undefined): boolean => {
  const reviewedMerchandiseIds = pipe(propOr([], 'reviews'), map(pathOr('', ['merchandise', 'id'])))(order)
  const carryoutBagId = pathOr('', [order.merchantId, 'orderingConfig', 'carryoutBag', 'id'], merchantLookup)
  return pipe(
    propOr([], 'lineItems'),
    reject((i: LineItem) => includes(i.id, [carryoutBagId, ...reviewedMerchandiseIds]) || i.refunded),
    isEmptyOrNil
  )(order);
}

const getEarnablePointsForOrder = (order: Order, merchantLookup: { [key: string]: Merchant } | undefined): boolean => {
  const reviewedMerchandiseIds = pipe(propOr([], 'reviews'), map(pathOr('', ['merchandise', 'id'])))(order)
  const carryoutBagId = pathOr('', [order.merchantId, 'orderingConfig', 'carryoutBag', 'id'], merchantLookup)
  return pipe(
    propOr([], 'lineItems'),
    reject((i: LineItem) => includes(i.id, [carryoutBagId, ...reviewedMerchandiseIds]) || i.refunded),
    (lineItems: LineItem[]) => lineItems.length * 50
  )(order);
}

const OrdersPage = (): React.ReactElement => {
  const title = 'Orders'
  const classes = useStyles()
  const history = useHistory()
  const { user, authenticating, token } = useContext(UserContext);
  const [orderLoading, setOrderLoading] = useState<boolean>(false)
  const [data, setData] = useState<OrderTransformed>();

  useEffect(() => {
    if (user) {
      setOrderLoading(true)
      axios.get<OrderData>('/orders/data', {
        params: { userId: user.id },
        headers: getAuthHeaders(token),
      }).then((response) => {
        setOrderLoading(false)
        if (response.status === 200) {
          const merchantLookup: { [key: string]: Merchant; } = {}
          if (response.data.merchants) {
            response.data.merchants.reduce(function (lookup, merchant) {
              lookup[merchant.id] = merchant
              return merchantLookup
            }, merchantLookup)
          }
          setData({
            orders: response.data.orders,
            merchantLookup,
          })
        }
      }).catch((err) => {
        console.log(err)
        setOrderLoading(false)
      })
    }
  }, [user])

  const filerAndSortOrders = (searchFn: (order: Order) => boolean): Order[] => {
    const filtered = data?.orders?.filter((order) => searchFn(order)) || []
    return sortOrders(filtered)
  }
  const pastOrderTypes = [OrderStatus.FULFILLED, OrderStatus.CONFIRMED, OrderStatus.REFUNDED, OrderStatus.VOIDED, OrderStatus.PAID_EXTERNALLY];
  const sections = useMemo(() => [
    {
      title: 'Open Checks',
      key: 'open-checks',
      searchFn: (order: Order) => order.status === OrderStatus.OPEN_CHECK && getItemsInOrder(order, true) > 0
    },
    {
      title: 'Active Orders',
      key: 'active-orders',
      searchFn: (order: Order) => [OrderStatus.PAID, OrderStatus.PREPARING].includes(order.status)
    },
    {
      title: 'Past Orders',
      key: 'past-orders',
      searchFn: (order: Order) => pastOrderTypes.includes(order.status) || getItemsInOrder(order, true) <= 0
    },
  ].map((section) => ({
    ...section,
    list: filerAndSortOrders(section.searchFn),
  })).filter(i => !isEmptyOrNil(i.list)), [data]);

  const onOrderClick = (order: Order) => {
    history.push({
      pathname: order.status === OrderStatus.OPEN_CHECK ? `/oc-order/${order.id}` : `/order/${order.id}`,
      state: { from: '/orders' }
    })
  }

  return (
    <div className="container">
      <SimpleLoader loading={authenticating || orderLoading} />
      <div className={classes.navWrapper}>
        <PageHeader title={title} leftChild={<IconButton><CheckIcon fontSize="large" viewBox="-3 -2 15 15" sx={{ width: "20px", height: "20px" }} /></IconButton>} />
      </div>

      <ColoredPaper className={classes.root}>
        <If condition={Boolean(user)}>
          <CardContent sx={{ padding: "0px" }}>
            <If condition={!orderLoading && isEmptyOrNil(propOr([], 'orders', data))}>
              <Typography sx={{ padding: "24px" }} gutterBottom variant="subtitle1" component="div">
                No orders yet
              </Typography>
            </If>

            {/* Pills for Orders Sections */}
            <FloatingSectionsHeader sections={sections} />

            {/* sections for the orders grouped by merchants */}
            {
              sections.map(({ title, list, key }) => {
                return (
                  <div key={key} id={key} className="ordersList">
                    <Typography sx={{ px: 3 }} gutterBottom variant="h6" component="div" className="sectionHeader">
                      {title} <CountBox count={list.length} />
                    </Typography>
                    <List sx={{ px: 3, py: 1 }}>
                      {
                        list.map((order: Order) => {
                          const merchant = data?.merchantLookup[order.merchantId]
                          if (!merchant) return null

                          const canAddReviewToTheOrder = key === 'past-orders' && !hasNoLineItemWithReview(order, data?.merchantLookup)

                          return (
                            <HorizontalContentCard
                              key={order.id}
                              cardTitle={merchant.officialName || ""}
                              handleCardClick={() => onOrderClick(order)}
                              cardSubTitle={merchant?.address || ""}
                              merchant={merchant}
                              extraContent={
                                <ListItemText
                                  primary={orderSummary(order)}
                                />
                              }
                              cardFooter={
                                <If condition={canAddReviewToTheOrder}>
                                  <Button
                                    variant="text"
                                    color="inherit"
                                    fullWidth
                                    className='review-btn'
                                    onClick={() =>
                                      history.push({
                                        pathname: `/order/${order.id}/reviews`,
                                        state: { from: '/orders' }
                                      })
                                    }
                                    endIcon={
                                      <CustomizedChip
                                        variant="filled"
                                        color='secondary'
                                        size='small'
                                        label={<><GemMonoIcon color="white" sx={{ width: 14 }} />&nbsp;{getEarnablePointsForOrder(order, data?.merchantLookup)}</>}
                                      />
                                    }
                                  >
                                    <Stack direction={'row'} alignItems={'center'} gap={1}><ReviewsOutlined fontSize='small' />Write a Review</Stack>
                                  </Button>
                                </If>
                              }
                            />
                          )
                        })
                      }
                    </List>
                  </div>
                )
              })
            }
          </CardContent>
        </If>
      </ColoredPaper>
    </div >
  );
};

export default OrdersPage;
