import React, { useContext, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

import StoreFrontIcon from '../../../assets/icons/StoreFrontIcon';
import BoxWithClick from 'bos_common/src/components/BoxWithClick';

import StoreFrontSelectorDrawer from './StoreLocationSelectorDrawer';
import useStyles from './styles'
import { getSelectedStoreLabel, isMultiStore, shouldOpenStoreSelectorDrawer } from './utils';
import { Merchant, OrderType } from '../../../services/models';
import { AppContext } from '../../../context/AppContext';
import { getMerchantStores } from '../../../services/merchantsApiService';
import { isKioskDevice } from '../../../utils';

const StoreLocationSelector = ({ merchant, popupByDefault=true }: {
  merchant: Merchant,
  popupByDefault?: boolean
}): JSX.Element | null => {
  const classes = useStyles()
  const { orderType } = useContext(AppContext);
  const isEligible = (orderType !== OrderType.DINEIN) && isMultiStore(merchant) && !isKioskDevice();

  const [isDrawerOpen, toggleDrawer] = useState<boolean>(false)
  const [merchantStores, setMerchantStores] = useState<Merchant[]>([]);

  useEffect(() => {
    const openStoreSelectorDrawer = isEligible && popupByDefault && shouldOpenStoreSelectorDrawer(merchant)
    toggleDrawer(openStoreSelectorDrawer)

    const fetch = async () => {
      const stores = await getMerchantStores(merchant.id);
      setMerchantStores(stores);
    }
    if (!merchantStores?.find((m: Merchant) => m.id === merchant.id)) {
      fetch();
    }

  }, [merchant])

  if (!isEligible) return null;

  const handleSelectorClick = () => toggleDrawer(true)
  const selectedStoreLabel = getSelectedStoreLabel(merchant)

  return (
    <>
      <div className={`${classes.storeFrontSelectorContainer} storeFrontSelector`}>
        <BoxWithClick handleClick={handleSelectorClick} isSelected={false}>
          <>
            <StoreFrontIcon sx={{width: 20, height: 20}}/>
            <div className='labelContainer'>
              <Typography variant='subtitle2' component='span' className='pickup-label'>
                Pick up at
              </Typography>
              &nbsp;
              <Typography variant='subtitle2' component='span' className='pickup-location'>
                {selectedStoreLabel}
              </Typography>
            </div>
            <ArrowDropDown />
          </>
        </BoxWithClick>
      </div>

      <StoreFrontSelectorDrawer
        merchant={merchant}
        stores={merchantStores}
        open={isDrawerOpen}
        setOpen={(boolean) => toggleDrawer(boolean)}
      />
    </>
  )
}

export default StoreLocationSelector;