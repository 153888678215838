import React from "react";
import { SvgIcon } from "@material-ui/core";

function PicturesIcon(props: any) {
  return (
    <SvgIcon
      sx={{ width: "41px", height: "41px" }}
      viewBox="0 0 41 41"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_17209_73220)">
        <path d="M1.32218 11.4493L31.5045 3.37977C32.4427 3.12953 33.4436 3.6925 33.6938 4.63086L39.6678 26.8062C39.918 27.7445 39.355 28.7141 38.3854 28.9643L8.20317 37.0338C7.2649 37.284 6.26404 36.7211 6.01381 35.7827L0.0711723 13.6073C-0.179062 12.6691 0.383905 11.6995 1.32218 11.4493Z" fill="#E4E7E7" />
        <path d="M3.85414 13.2292L30.2519 6.1919C30.9712 6.00424 31.7219 6.44213 31.9408 7.16146L36.8826 25.521C37.0703 26.2404 36.6324 26.9911 35.913 27.1787L9.51525 34.216C8.79592 34.4037 8.04521 33.9658 7.82627 33.2465L2.88457 14.8869C2.66562 14.1675 3.10351 13.4168 3.85414 13.2292Z" fill="#208DB2" />
        <path d="M6.79449 11.1367H38.2278C39.2286 11.1367 40.0105 11.9499 40.0105 12.9195V36.2208C40.0105 37.2216 39.1973 38.0035 38.2278 38.0035H6.79449C5.79363 38.0035 5.01172 37.1903 5.01172 36.2208V12.9196C5.01172 11.9499 5.82492 11.1367 6.79449 11.1367Z" fill="#F3F3F3" />
        <path d="M8.89179 13.6367H36.1966C36.9472 13.6367 37.5727 14.2623 37.5727 15.0129V34.1544C37.5727 34.905 36.9472 35.5305 36.1966 35.5305H8.89179C8.14117 35.5305 7.51562 34.905 7.51562 34.1544V15.0129C7.51562 14.2623 8.10988 13.6367 8.89179 13.6367Z" fill="#A3E0F5" />
        <path d="M7.51562 15.0129V21.7061C7.82836 21.7374 8.14117 21.7687 8.4539 21.7687C12.426 21.7687 15.6476 18.5472 15.6476 14.575C15.6476 14.2623 15.6163 13.9495 15.585 13.6367H8.89179C8.10988 13.6367 7.51562 14.2623 7.51562 15.0129Z" fill="#EFC75E" />
        <path d="M9.79886 28.9609C9.01695 28.9609 8.23504 29.0235 7.51562 29.1173V34.1529C7.51562 34.9035 8.14117 35.529 8.89179 35.529H19.307C19.3696 35.2475 19.4009 34.9974 19.4009 34.7158C19.4008 31.5256 15.116 28.9609 9.79886 28.9609Z" fill="#3DB39E" />
        <path d="M31.5994 26.1484C23.0295 26.1484 16.0547 30.3395 15.9609 35.5315H36.1659C36.9165 35.5315 37.542 34.906 37.542 34.1554V26.8678C35.6967 26.43 33.695 26.1484 31.5994 26.1484Z" fill="#4BC2AD" />
      </g>
      <defs>
        <clipPath id="clip0_17209_73220">
          <rect width="40" height="40" fill="white" transform="translate(0.0117188 0.660156)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default PicturesIcon;
