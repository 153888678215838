import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles, Grid, Alert, TextField, Fab, Typography } from '@material-ui/core'
import { If } from 'react-if';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';

import SimpleLoader from '../../bos_common/src/components/SimpleLoader';
import { UserContext } from '../../bos_common/src/context/UserContext';
import { getAPIErrorMessage } from '../../bos_common/src';
import createOrderService from '../../services/createOrderService';
import { Merchant, Order } from '../../services/models';
import eventTrackingService from '../../services/Tracking';

import { AppContext } from '../../context/AppContext';
import { isEmptyOrNil } from '../../utils';
import { checkCartItemsAvailability } from './utils';
import { getEventLabel } from '../../services/Tracking/EventsTracking';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center",
      alignSelf: "center",
      margin: theme.spacing(1),

      "& .form-container": {
        marginTop: theme.spacing(4),

        '& .title-container': {
          marginBottom: theme.spacing(2)
        },

        '& .user-info-field': {
          marginBottom: theme.spacing(2),
          background: 'white',
        },

        '& .confirm-order-btn': {
          width: "100%"
        },

        '& .react-tel-input': {
          marginBottom: theme.spacing(3),

          "& .flag-dropdown, & .flag-dropdown.open .selected-flag": {
            borderRadius: '8px 0 0 8px'
          },

          '& input': {
            width: "100%",
            height: "56px",
            borderRadius: "8px",
            border: "1px solid rgba(0, 0, 0, 0.15)",
          },

          '& .country-list': {
            borderRadius: '0px',
          },
        }
      }
    },
  }),
);

interface FormValues { name: string; phone: string }

type NoPaymentFormType = {
  onSuccessSubmit: (order: Order) => void
}
const NoPaymentForm = (props: NoPaymentFormType) => {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const { cart, merchantConfig, reFetchMerchandisesForCart } = appContext;
  const { user, token, authenticating, refreshUser } = useContext(UserContext)

  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState<string>('')

  useEffect(() => {
    // fetch latest merchandise data to
    // 1/ make sure prices are accurate
    // 2/ make sure inventories are accurate
    if (merchantConfig?.merchant) {
      reFetchMerchandisesForCart();
    }
  }, [])

  const createOrderWithoutPaymentIntent = async (formValues: FormValues) => {
    const { name, phone } = formValues
    setProcessing(true)

    if (phone.length < 11) {
      setError("Phone number is required");
      setProcessing(false);
      return;
    }

    if (checkCartItemsAvailability({ cart, merchantConfig })) {
      setError("Some items in your cart are sold out");
      setProcessing(false);
      return;
    }
    try {
      const results = await createOrderService({
        appContext,
        name,
        user,
        token,
        phone
      });

      if (results.status === 200) {
        const order = results.data;
        setError('')
        setProcessing(false)

        if (!isEmptyOrNil(user)) {
          refreshUser()
        }

        props.onSuccessSubmit(order)

        eventTrackingService.captureCheckoutEvent({
          label: getEventLabel(merchantConfig?.merchant ?? {} as Merchant),
          data: {
            user,
            merchant: merchantConfig?.merchant,
            order
          }
        })
      } else {
        setError(`Unable to process your order. Please try again later`);
        setProcessing(false);

        eventTrackingService.captureCheckoutEvent({
          label: getEventLabel(merchantConfig?.merchant ?? {} as Merchant),
          data: {
            user,
            merchant: merchantConfig?.merchant,
          },
          errorMessage: "Unable to process your order. Please try again later"
        })
      }
    } catch (err) {
      console.log(err)
      const errorMessage = getAPIErrorMessage(err) || `Unable to process your order. Please try again later`;

      eventTrackingService.captureCheckoutEvent({
        label: getEventLabel(merchantConfig?.merchant ?? {} as Merchant),
        data: {
          user,
          merchant: merchantConfig?.merchant,
        },
        errorMessage: "Unable to process your order. Please try again later"
      })

      setError(errorMessage);
      setProcessing(false);
    }
  }

  // form methods
  const { register, handleSubmit, formState, getValues, setValue } = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      name: user?.displayName,
      phone: user?.phoneNumber
    },
  });
  const { isValid } = formState

  const registerTextField = (name: keyof FormValues, options?: any) => {
    const { ref: inputRef, ...inputProps } = register(name, options);
    return { ...inputProps, inputRef }
  }

  const formValues = getValues()

  return (
    <div className={classes.root}>
      <SimpleLoader loading={processing || authenticating} />
      <If condition={error}>
        <Alert severity='warning'>{error}</Alert>
      </If>

      <Grid container className='form-container' justifyContent="center">
        <Grid item xs={12} sm={6} lg={4}>

          <form onSubmit={handleSubmit(createOrderWithoutPaymentIntent)}>
            <TextField
              fullWidth
              required
              label="Preferred Name"
              variant="outlined"
              className="user-info-field"
              {...registerTextField("name", { required: 'Your name is required to confirm the' })}
            />
            <PhoneInput
              country={'us'}
              onlyCountries={['us', 'pk', 'cn', 'id', 'in']}
              value={formValues.phone}
              specialLabel=""
              placeholder="+1 (234) 567-8910"
              onChange={p => { setValue('phone', `${p ? `+${p}` : ''}`, { shouldValidate: true, shouldTouch: true }) }}
            />
            <Fab
              variant='extended'
              type="submit"
              color='primary'
              className='confirm-order-btn'
              disabled={processing || authenticating || !isValid}>
              Submit Order
            </Fab>
            <Typography variant="subtitle2" color="inherit" textAlign="center">
              No charge is required on this order
            </Typography>
          </form>
        </Grid>
      </Grid>
    </div>
  )
}

export default NoPaymentForm;