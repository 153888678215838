import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { PaymentMethod, SetupIntent, Stripe, StripeCardElement, StripeError } from "@stripe/stripe-js";
import stripeApiService, { deletePaymentMethod } from "../../../services/stripeApiService";
import { getAuthHeaders } from "../../../utils";
import { RootState } from "../../store";
import { getToken } from "../auth/authSelector";
import Storage from "../../../services/storage";
import { LOCALSTORAGE_APP_KEYS } from "../../../bos_common/src/constants";

interface CreatePaymentMethodActionParams {
  stripe: Stripe;
  card: StripeCardElement;
  name: string;
}

export interface PaymentMethodCache {
  name: string;
  setupIntentId: string;
  paymentMethodId: string;
}

export const setupIntentCreateSucceed = createAction<SetupIntent>('stripe/updateSetupIntent');

export const setupIntentCreateFailed = createAction<StripeError>('stripe/createPaymentMethod/rejected');

export const clearPaymentMethods = createAction('stripe/clear');

export const fetchUserPaymentMethods = createAsyncThunk(
  'stripe/fetchUserPaymentMethods',
  async (_, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const token = getToken(state);
    const response = await stripeApiService.getUserPaymentMethods({ headers: getAuthHeaders(token) });
    return response.data.data || [];
  }
);

export const createPaymentMethod = createAsyncThunk(
  'stripe/createPaymentMethod',
  async (params: CreatePaymentMethodActionParams, thunkAPI) => {
    const { stripe, card, name } = params;
    const state: RootState = thunkAPI.getState() as RootState;
    const token = getToken(state);
    const resSetupIntent = await stripeApiService.createSetupIntent({ headers: getAuthHeaders(token) });
    const { setupIntent, error } = await stripe.confirmCardSetup(resSetupIntent?.data.client_secret, {
      payment_method: {
        card,
        billing_details: {
          name,
        },
      }
    });

    if (error) {
      console.error(error);
      throw new Error(error.message);
    }

    if (setupIntent) {
      thunkAPI.dispatch(setupIntentCreateSucceed(setupIntent));
    }

    if (!setupIntent?.payment_method) {
      throw new Error("payment_method is undefined");
    }

    const resPaymentMethod = await stripeApiService.updateUserPaymentMethod(setupIntent?.payment_method, { headers: getAuthHeaders(token) });

    return [resPaymentMethod.data];
  }
);

export const cachePaymentMethod = createAsyncThunk(
  'stripe/cachePaymentMethod',
  async (params: CreatePaymentMethodActionParams, thunkAPI) => {
    const { stripe, card, name } = params;
    const state: RootState = thunkAPI.getState() as RootState;
    const token = getToken(state);

    const resSetupIntent = await stripeApiService.createSetupIntent({ headers: getAuthHeaders(token) });
    const { setupIntent, error } = await stripe.confirmCardSetup(resSetupIntent?.data.client_secret, {
      payment_method: {
        card,
        billing_details: {
          name,
        },
      }
    });
    const storage = new Storage();

    if (error) {
      console.error(error);
      throw new Error(error.message);
    }

    if (setupIntent) {
      thunkAPI.dispatch(setupIntentCreateSucceed(setupIntent));
    }

    if (!setupIntent?.payment_method) {
      throw new Error("payment_method is undefined");
    }

    await storage.save<PaymentMethodCache>('unsavedPaymentMethod', {
      name,
      setupIntentId: setupIntent?.id,
      paymentMethodId: setupIntent?.payment_method,
    });

    // TODO: using IndexedDb
    sessionStorage.setItem(LOCALSTORAGE_APP_KEYS.USER_DISPLAY_NAME, name ?? '');

    return setupIntent?.payment_method;
  }
);

export const persistCachedPaymentMethod = createAsyncThunk(
  'stripe/persistCachedPaymentMethod',
  async (stripe: Stripe, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const token = getToken(state);
    const storage = new Storage();
    const cachedPaymentMethod = await storage.get<PaymentMethodCache>('unsavedPaymentMethod');

    if (!token) {
      await thunkAPI.dispatch(clearPaymentMethods());
    }

    if (cachedPaymentMethod && token) {
      // update user payment method takes care of everything
      const resPaymentMethod = await stripeApiService.updateUserPaymentMethod(cachedPaymentMethod.paymentMethodId, { headers: getAuthHeaders(token) });
      await storage.remove('unsavedPaymentMethod');
      return [resPaymentMethod.data];
    }

    return [];
  }
);

export const clearUnsavedPaymentMethod = createAsyncThunk(
  'stripe/cleanAllCache',
  async (_) => {
    const storage = new Storage();

    await storage.remove('unsavedPaymentMethod');
  }
);

export const removePaymentMethod = createAsyncThunk(
  'stripe/removePaymentMethod',
  async (paymentMethod: PaymentMethod, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const token = getToken(state);
    await deletePaymentMethod(
      paymentMethod.id,
      { 
        headers: getAuthHeaders(token) 
      }
    );

    return [paymentMethod];
  }
);
