import React, { useCallback, useMemo, useEffect } from "react";
import {
  FormControl, Checkbox, FormControlLabel,
  FormGroup, FormHelperText, FormLabel, Divider, RadioGroup, Radio, makeStyles, Theme, createStyles
} from "@material-ui/core";
import { head, keys, pathOr, pick, pipe, propOr, values } from "ramda";
import { useFormContext, Controller } from "react-hook-form";

import { MerchandiseApiResponseType, MerchandiseModifierCategory, MerchandiseModifier } from "../../services/models";
import renderPrice from '../../bos_common/src/components/Price';
import FormGroupCardWrapper from "../common/FormGroupCardWrapper";
import { isEmptyOrNil } from "../../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',

      '& .scrollStartPoint': {
        position: 'absolute',
        top: -70,
      }
    },
  }),
);

interface ModifierMap { [key: number]: MerchandiseModifier; }

const isCategorySelectionLimitValid = (
  quantity: number | null,
  categoryModifiersValues: boolean[],
  checkSide: 'min' | 'max',
) => {
  if (quantity == null || quantity < 1) return true;

  const {length} = categoryModifiersValues.filter(i => i)
  return checkSide === 'min'
    ? length >= quantity
    : length <= quantity;
}

const getModifierLabel = (m: MerchandiseModifier) => {
  const price = Number(m.price);
  return price === 0 ? m.name : `${m.name} (+ ${renderPrice(price)})`
}

const renderModifierRadioSelect = (category: MerchandiseModifierCategory, order: number, toModifiers: ModifierMap) => {
  const { formState, control } = useFormContext();
  const { errors } = formState;

  const classes = useStyles();

  const categoryFieldName = `${order}_c${category.id}`
  const modifiers = useMemo(() => category.modifierIds.map((modifierId) => toModifiers[modifierId]).filter((val) => val), [category]);
  const defaultModifier = modifiers.find((modifier: MerchandiseModifier) => modifier.isDefault && modifier.available)
  const helperText = errors[categoryFieldName] ? errors[categoryFieldName].message : category.description as string | undefined
  const required = category.minQuantity > 0
  const rules = required ? { required: 'required' } : undefined

  return (
    <FormGroupCardWrapper key={category.id} id={category.id} elevation={2}>
      <FormControl
        className={classes.root}
        required={required}
        error={Boolean(errors[categoryFieldName])}
        variant="standard"
        fullWidth>
        <div id={categoryFieldName} className='scrollStartPoint' />
        <FormLabel>{category.name}</FormLabel>
        <Divider />
        <Controller
          defaultValue={`${defaultModifier?.id || ''}`}
          name={categoryFieldName}
          control={control}
          rules={rules}
          render={({ field }) =>
            <RadioGroup aria-label={category.name} {...field}>
              {modifiers.map((m: MerchandiseModifier, index: number) => {
                return (m &&
                  <div key={m.id}>
                    {(index !== 0) && <Divider />}
                    <FormControlLabel value={`${m.id}`} control={<Radio color="primary" disabled={!m?.available} />} label={getModifierLabel(m)} />
                  </div>
                )
              })}
            </RadioGroup>
          }
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </FormGroupCardWrapper>
  )
}

const renderModifierMultiSelect = (category: MerchandiseModifierCategory, order: number, toModifiers: ModifierMap) => {
  const { formState, control, getValues, trigger } = useFormContext();
  const { errors } = formState;
  const formValues = getValues();
  const classes = useStyles();
  const controlName = (m: MerchandiseModifier) => `${order}_${m.id}` as const;
  const modifiers = useMemo(() => category.modifierIds.map((modifierId) => toModifiers[modifierId]).filter((val) => val), [category]);
  
  const categoryModifiersIds: string[] = modifiers.map((m) => controlName(m));
  const errorMessage = pipe(pick(categoryModifiersIds), values, pathOr(undefined, [0, 'type']))(errors);
  const firstErrorMessageKey = pipe(pick(categoryModifiersIds), keys, head)(errors);
  const helperText = errorMessage ?? category.description as string | undefined;

  const hasNoMax = category.maxQuantity <= 0 || category.maxQuantity >= modifiers.length;
  const hasNoMin = category.minQuantity < 1;

  const modifiersValues = categoryModifiersIds.map(id => formValues[id]);
  const categoryModifiersValuesString = JSON.stringify(modifiersValues);

  const isMaximumSelectionLimitReached = useMemo(() => {
    return !(
      hasNoMax
      || isCategorySelectionLimitValid(category.maxQuantity - 1, modifiersValues, 'max')
    );
  }, [category, categoryModifiersValuesString]);

  const validateModifiersSelection = useCallback(() => {
    return (
      hasNoMin
      || isCategorySelectionLimitValid(category.minQuantity, modifiersValues, 'min')
    ) && (
      hasNoMax
      || isCategorySelectionLimitValid(category.maxQuantity, modifiersValues, 'max')
    );
  }, [category, categoryModifiersValuesString]);

  const maxSelectionLimitText = useMemo(() => {
    const minimumText = category.minQuantity <= 0 ? '' : ` at least ${category.minQuantity}`;
    const maximumText = hasNoMax ? '' : ` up to ${category.maxQuantity}`;
    return (minimumText || maximumText) ? `(Select${minimumText}${maximumText} items)${category.minQuantity > 0 ? '*' : ''}` : '';
  }, [category]);

  // trigger validation on template render and selection change
  useEffect(() => {
    if (!modifiers.length) {
      return;
    }

    trigger(modifiers.map(m => controlName(m)));
  }, [modifiers, categoryModifiersValuesString]);

  return (
    <FormGroupCardWrapper key={category.id} id={category.id} elevation={2}>
      <FormControl
        className={classes.root}
        fullWidth
        error={!isEmptyOrNil(errorMessage)}
        variant="standard">
        <div id={firstErrorMessageKey} className='scrollStartPoint' />
        <FormLabel>{category.name} {maxSelectionLimitText}</FormLabel>
        <Divider />
        <FormGroup>
          {modifiers.map((m: MerchandiseModifier, index: number) => {
            const isDisabledAfterMaxSelection = isMaximumSelectionLimitReached && !propOr(false, controlName(m), formValues)
            const isCheckboxDisabled = !m?.available || isDisabledAfterMaxSelection;

            return (m &&
              <div key={m.id}>
                {(index !== 0) && <Divider />}
                <Controller
                  defaultValue={m.isDefault && m.available}
                  name={controlName(m)}
                  control={control}
                  rules={{
                    validate: {
                      required: validateModifiersSelection
                    }
                  }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox color="primary" {...field} disabled={isCheckboxDisabled} checked={field.value} />}
                      label={getModifierLabel(m)}
                    />
                  )}
                />
              </div>
            )
          })}
        </FormGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </FormGroupCardWrapper>
  )
}

const ModifierTemplate = (props: {
  templateId: number | undefined,
  mercData: MerchandiseApiResponseType | undefined,
}) => {
  const { templateId, mercData } = props
  if (!templateId || !mercData) {
    return null
  }
  const template = mercData.modiTemplates.find((t) => (t.id === templateId))
  if (!template) {
    return null
  }

  const toModifiers: ModifierMap = {}
  mercData.modifiers.map((modifier) => toModifiers[modifier.id] = modifier)

  return (
    <div>
      {template.modifierCategoryIds.map((categoryId: number, index: number) => {
        const numCategoryId = Number(categoryId)
        const category = mercData.modiCategories.find((c) => (c.id === numCategoryId))

        if (!category) {
          return null
        }

        if (category.maxQuantity === 1 && category.minQuantity === 1) {
          return renderModifierRadioSelect(category, index, toModifiers)
        } else {
          return renderModifierMultiSelect(category, index, toModifiers)
        }

      })}
    </div>
  )
}

export default ModifierTemplate;

