import { Merchant, MerchantCoupon } from "../../../services/models";
import { RootState } from "../../store";

export const getMerchants = (state: RootState): Merchant[] => {
  const { entities } = state.merchant;
  return entities.ids.map((id) => entities.byId[id]);
}

export const getMerchantLoadingState = (state: RootState): boolean => {
  return state.merchant.loading || false
}

export const getMerchant = (state: RootState) => (id: string): Merchant => {
  return state.merchant.entities.byId[id];
}

export const getCurrentMerchant = (state: RootState): Merchant | undefined => {
  const { entities, currentMerchantId } = state.merchant;

  return currentMerchantId
    ? entities.byId[currentMerchantId]
    : undefined;
}

export const getMerchantByUsername = (state: RootState) => (username: string): Merchant | undefined => {
  const { entities } = state.merchant;

  return Object.values(entities.byId).find((item) => item.username === username);
  // return currentMerchantId
  //   ? entities.byId[currentMerchantId]
  //   : undefined;
}

export const getMerchantSearchQuery = (state: RootState): string => {
  return state.merchant.currentSearchQuery;
}

export const getCouponByCode = (state: RootState) => (code: string): MerchantCoupon | undefined => {
  const { merchant } = state;

  return merchant.coupons
    ? Object.values(merchant.coupons).find((merchantCoupon: MerchantCoupon) => merchantCoupon.code === code)
    : undefined;
}

export const getFilteredMerchants = (state: RootState): Merchant[] => {
  const { entities, currentSearchQuery } = state.merchant;
  return entities.byNameSearchParams && entities.byNameSearchParams[currentSearchQuery]
    ? entities.byNameSearchParams[currentSearchQuery].map((id: string) => entities.byId[id])
    : entities.ids.map((id: string) => entities.byId[id]);
}

export const getShowRewardsEducationDrawer = (state: RootState): boolean => {
  return state.merchant.showRewardsEducationDrawer || false
}
