
import React from 'react'
import { SvgIcon } from "@material-ui/core";

function ChickenWingsIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 13" {...props} >
      <path d="M4.69 5.789a.41.41 0 10-.58.58.41.41 0 00.58-.58zM4.11 7.53a.41.41 0 10-.581.58.41.41 0 00.58-.58zM5.85 8.11a.41.41 0 10-.58.58.41.41 0 00.58-.58z" />
      <path d="M11.9 2.555c-1.26-1.26-3.414-.751-3.977.939L7.024 6.19l-.233-.607a2.985 2.985 0 00-2.175-1.855 2.986 2.986 0 00-2.74.812 3 3 0 000 4.237l4.718 4.718a2.232 2.232 0 003.794-1.338l.629-5.86a2.876 2.876 0 003.863-.182.41.41 0 000-.58l-2.98-2.98zm-.501 2.98l-.907-.907a.41.41 0 00-.58.58l.358.359-.113 1.048-.246-.246a.41.41 0 10-.58.58l.714.714-.113 1.048-.6-.602a.41.41 0 00-.581.58L9.82 9.76l-.248 2.31a1.411 1.411 0 01-2.398.846L2.456 8.197A2.162 2.162 0 011.82 6.66c0-.581.226-1.127.637-1.538a2.168 2.168 0 011.99-.59 2.168 2.168 0 011.579 1.347l1.473 3.83a.41.41 0 00.766-.295l-.776-2.018L8.7 3.753a1.583 1.583 0 012.619-.618l2.66 2.661a2.055 2.055 0 01-2.581-.26z" />
    </SvgIcon>
  );
}

export default ChickenWingsIcon