import React from "react"
import { Alert, Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import { Share } from "@material-ui/icons";

import { Merchant } from "bos_common/src/types/MerchantType";
import { User } from "bos_common/src/types/UserTypes";
import { getReferralUrl } from "bos_common/src/services/urls";

import LinkWithCopyButton from "../common/LinkWithCopyButton";
import { getReferralGiftPromotion, isEmptyOrNil } from "../../utils";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto 100%",
    backgroundPosition: "right",
  }
}))

type UserReferralBannerProps = {
  merchant: Merchant;
  user?: User;
}

const UserReferralBanner = (props: UserReferralBannerProps) => {
  const { merchant, user } = props;
  const classes = useStyles();
  const promotion = getReferralGiftPromotion(merchant);

  if(!user || !promotion) return null;

  const referralUrl = getReferralUrl(merchant, user, promotion.code || '');

  if(isEmptyOrNil(referralUrl)) return null;

  return (
    <Alert
      severity="error"
      iconMapping={{ error: <Share fontSize={"large"} color="primary" /> }}
      elevation={3}
      sx={{ display: 'flex' }}
      className={classes.root}>
      {promotion.description}
      <Box sx={{mt: 2}}>
        <LinkWithCopyButton url={referralUrl} shorten={true} />
      </Box>
    </Alert>
  )
}

export default UserReferralBanner;
