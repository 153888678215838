import React, { useEffect, useState } from 'react';
import { Typography, CardContent, makeStyles, Theme, createStyles, Box, Toolbar } from '@material-ui/core';
import { differenceInMinutes } from 'date-fns';

import BottomDrawer from '../../bos_common/src/components/BottomDrawer';

import { useAppSelector } from '../../redux/hooks';
import { getUserPromotionEntries } from '../../redux/slice/marketPlace/marketPlaceSelector';
import { isEmptyOrNil } from '../../utils';
import { LOCALSTORAGE_MARKETPLACE_APP_KEYS } from '../../services/localStorage';
import MerchantPromotionsEntries from '../MerchantInfo/MerchantPromotionsEntries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      '& .content': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .content-container': {
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
          [theme.breakpoints.up('sm')]: {
            maxWidth: 500
          }
        },
      }
    },
  }),
);

const PromotionsDrawer = (): React.ReactElement | null => {
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)
  const promotionEntries = useAppSelector(getUserPromotionEntries);

  useEffect(() => {
    if (isEmptyOrNil(promotionEntries)) return

    const storageKey = LOCALSTORAGE_MARKETPLACE_APP_KEYS.PROMOTION_DRAWER;
    const localStorageData = localStorage.getItem(storageKey);
    if (localStorageData) {
      const lastTime = Date.parse(localStorageData);
      if (differenceInMinutes(new Date(), lastTime) < 24 * 60) {
        return;
      }
    }
    localStorage.setItem(storageKey, new Date().toISOString())

    setOpen(true);
  }, [promotionEntries])

  return (
    <BottomDrawer
      open={open}
      setOpen={setOpen}
      maxHeightPx={500}
      title={<Typography variant="h6" className="drawerTitle" component="div">Unique deals for you!</Typography>}
    >
      <div className={classes.drawer}>
        <CardContent className='content'>
          <div className="content-container">
            <MerchantPromotionsEntries setOpen={setOpen} userPromotionEntries={promotionEntries} />
          </div>
        </CardContent>
      </div>
    </BottomDrawer>
  )
}

export default PromotionsDrawer;