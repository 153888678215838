import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm, FormProvider, SubmitHandler, Controller, useFormContext, FieldValues } from "react-hook-form";
import {
  CardHeader,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Fab,
  Grid,
  FormControl,
  FormLabel,
  FormHelperText,
  TextField,
  useMediaQuery,
  Drawer,
  Box,
  Card,
  Rating as OverallRating,
  Stack
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import useAxios from "axios-hooks";
import { Case, Default, If, Switch, Then } from "react-if";
import { useHistory, useLocation } from "react-router-dom";
import { pipe, propOr, find, map, propEq, without, filter, any, includes, all, reduce, head, pathOr, path, keys } from "ramda";
import ReactMarkdown from "react-markdown";

import { ColoredPaper, FullscreenPaper } from '../bos_common/src/components/Papers';
import { FabContainer } from "../bos_common/src/components/FabContainers";
import SimpleLoader from "../bos_common/src/components/SimpleLoader";
import { UserContext } from "../bos_common/src/context/UserContext";
import { getCouponForMerchandise, isOverInventoryLimit } from '../bos_common/src/services/merchandiseUtils';
import { isCouponValidOnMerchandise } from "../bos_common/src/services/CouponUtils";
import {
  Merchant,
  MerchandiseApiResponseType,
  MerchandiseModifiersType,
  Merchandise,
  MerchandiseModifierCategory,
  MerchandiseModifier,
} from "../services/models";

import PageHeader from "../components/common/PageHeader";
import BackButton from "../components/common/BackButton";
import QuantityInput from "../components/MerchantMenu/QuantityInput";
import ModifierTemplate from "../components/MerchantMenu/ModifierTemplate";
import FormGroupCardWrapper from "../components/common/FormGroupCardWrapper";
import CustomizedChip from "../components/common/CustomizedChip";
import { AppContext, CartItemType } from "../context/AppContext";
import { VisitedMerchantContext } from "../context/VisitedMerchantContext/VisitedMerchantContext";
import {
  enableOrderItemNote,
  enableRedeemPoints,
  getOfflineMerchantMessage,
  getRequiredRedemptionPoints,
  isEmptyOrNil,
  isMerchandiseAvailable,
  isMerchandiseStockLow,
  isNotificationSoundEnabled,
  repeatAudio,
  isAddingItemToOpenCheck,
  enableRatings,
  scrollToTop,
} from "../utils";
import { RenderSoldOut, RenderLowStockWarning } from "../components/common/MerchandiseHelper";

import { MerchandiseUnitPrice } from "../components/MerchantMenu/MerchandisePrice";
import { setShowRewardsEducationDrawer } from "../redux/slice/merchant/action";
import { useAppDispatch } from "../redux/hooks";

import { Rating } from "../bos_common/src/types/ReviewType";
import { MerchantCoupon } from "../bos_common/src/types/MerchantCouponType";
import MerchandiseAllergens from "../bos_common/src/components/Merchandise/MerchandiseAllergens";
import MerchandiseReviewsDrawer from "bos_common/src/components/MerchandiseReviewsDrawer";
import ShareButton from "../components/common/ShareButton";
import MerchandiseMedia from "../components/MerchantMenu/MerchandiseMedia";
import eventTrackingService from "../services/Tracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../services/Tracking/events";
import GemIcon from "../assets/icons/GemIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      '& .MuiDrawer-paperAnchorBottom': {
        scrollBehavior: 'smooth',
      }
    },

    root: {
      '& .share-btn': {
        position: 'absolute',
        right: 20,
        top: 20,
      },

      '& .merchandise-title': {
        display: 'flex',
        marginBottom: theme.spacing(1),
      },

      '& .quantityInput': {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        zIndex: 999,

        [theme.breakpoints.down('sm')]: {
          position: 'fixed',
          bottom: 3,
          width: 'fit-content',
          left: theme.spacing(2),
        },
      },

      '& .ratingLink': {
        color: "black"
      },

      "& .ratingCard": {
        display: "flex",
        padding: `${theme.spacing(2)} ${theme.spacing(0.35)} ${theme.spacing(2)} ${theme.spacing(2)}`,
        alignItems: "center",
        cursor: "pointer",

        "&:hover": {
          boxShadow: "2px 4px 13px rgb(0 0 0 / 13%)"
        },

        "& .ratingSection": {
          flex: 1,
          marginRight: theme.spacing(0.35)
        },

        "& .ratingCardRightIcon": {
          color: theme.palette.secondary.light,
          padding: 0
        },

        "& .ratingBox": {
          display: "flex",
          "& .ratingItem": {
            display: "flex",
            alignItems: "center",
            padding: `0 ${theme.spacing(0.25)}`,
          },
          "&.boxJumbo": {
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: theme.spacing(1),
            "& .MuiSvgIcon-root": {
              fontSize: '1.2rem',
            },
          }
        }
      }
    },
    pointsEarnedContainer: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.warning.main,
      cursor: 'pointer',
    }
  }),
);

interface FormValues { [key: string]: any; }

const RenderOrderNoteItem = ({ formMethods }: { formMethods: FormValues }) => {
  const orderNoteError = formMethods?.formState?.errors?.note
  const orderNoteHelperText = orderNoteError ? orderNoteError.message : orderNoteError as string | undefined

  return (
    <FormGroupCardWrapper elevation={3}>
      <FormControl
        error={Boolean(orderNoteError)}
        variant="standard"
        fullWidth>
        <FormLabel>Special Instructions</FormLabel>
        <Controller
          name="note"
          control={formMethods.control}
          rules={{
            maxLength: { value: 250, message: 'Maximum 250 characters are allowed' }
          }}
          render={({ field }) =>
            <TextField
              {...field}
              error={Boolean(orderNoteError)}
              id="note"
              multiline
              rows={4}
              placeholder="Any notes to add? Could be allergies, less spicy and etc"
              variant="outlined"
            />
          }
        />
        {orderNoteHelperText && <FormHelperText>{orderNoteHelperText}</FormHelperText>}
      </FormControl>
    </FormGroupCardWrapper>
  )
}

interface MerchandiseRatingCard {
  rating: Rating,
  onArrowIconClick: () => void,
  numOfReviews: number
}


const MerchandiseRatingCard = ({ rating, numOfReviews, onArrowIconClick }: MerchandiseRatingCard) => {

  const getRatingValue = (key: string) => propOr(0, key)(rating);

  return (
    <Card className="ratingCard" onClick={onArrowIconClick}>
      <Box className="ratingSection">
        <Box className="ratingBox boxJumbo">
          <Typography variant="subtitle2">{numOfReviews} reviews</Typography>
          <OverallRating
            name="size-medium"
            icon={<StarIcon fontSize="small" color="primary" />}
            precision={0.5}
            value={getRatingValue("overall")}
            readOnly
          />
        </Box>
        <Stack direction="row" flexWrap="wrap" sx={{ justifyContent: "space-between", gap: "3px" }}>
          <Box className="ratingBox">
            <Typography variant="subtitle2">Taste</Typography>
            <div className="ratingItem">
              <StarIcon fontSize="small" color="primary" />
              <Typography variant="subtitle2">
                {getRatingValue("taste").toFixed(2)}
              </Typography>
            </div>
          </Box>
          <Box className="ratingBox">
            <Typography variant="subtitle2">Portion</Typography>
            <div className="ratingItem">
              <StarIcon fontSize="small" color="primary" />
              <Typography variant="subtitle2">
                {getRatingValue("portion").toFixed(2)}
              </Typography>
            </div>
          </Box>
          <Box className="ratingBox">
            <Typography variant="subtitle2">Value</Typography>
            <div className="ratingItem">
              <StarIcon fontSize="small" color="primary" />
              <Typography variant="subtitle2">
                {getRatingValue("value").toFixed(2)}
              </Typography>
            </div>
          </Box>
        </Stack>
      </Box>
      <ChevronRightIcon className="ratingCardRightIcon" />
    </Card>
  );
};

type ComboMerch = {
  ids: string[],
  curIndex: number,
}

const parseComboData = (search: string, merchandise: Merchandise|null) => {
  const searchParams = new URLSearchParams(search);
  if (merchandise && searchParams.get('combo') && searchParams.get('ids')) {
    const ids = searchParams.get('ids')?.split(',') || [];
    const curIndex = ids?.findIndex((id) => id === merchandise.id);

    return curIndex > -1 ? {
      ids,
      curIndex
    } : undefined;
  }

  return undefined;
}

interface RenderMerchandiseProps {
  merchant: Merchant | undefined,
  merchandise: Merchandise | null,
  onSubmit: SubmitHandler<FormValues>,
  merchandiseData: MerchandiseApiResponseType | undefined,
  setQuantity: (arg0: number) => void,
  quantity: number,
  coupon?: MerchantCoupon,
}

const RenderMerchandiseFab = ({ merchandise, merchandiseData, quantity }: RenderMerchandiseProps) => {
  const { cart, orderType } = useContext(AppContext);
  const { visitedMerchant, isMerchantOrderingAvailable } = useContext(VisitedMerchantContext);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const location = useLocation();
  const {formState: {errors}} = useFormContext();
  const comboMerch = useMemo(
    () => parseComboData(location.search, merchandise),
    [location.search, merchandise]
  );

  const comboStep = (comboMerch?: ComboMerch) => {
    if (comboMerch) {
      const { curIndex, ids } = comboMerch;
      if (curIndex < ids.length-1) {
        return `Add to combo (${curIndex+1}/${ids.length})`
      } else {
        return `Finish combo (${curIndex+1}/${ids.length})`;
      }
    }
    return 'Add to cart1';
  }

  const quantityUsedWithinCart = pipe(
    filter(propEq('id', merchandise?.id)),
    reduce((acc: number, item: CartItemType) => (acc + item.quantity), 0)
  )(cart)
  const willBeOverStockLimit = isOverInventoryLimit(merchandise, quantity + quantityUsedWithinCart)

  const isAnyRequiredModifiersUnavailable = pipe(
    propOr([], 'modiTemplates'),
    find(propEq('id', merchandise?.modifierTemplateId)),
    propOr([], 'modifierCategoryIds'),
    map((i: string) => merchandiseData?.modiCategories.find(propEq('id', Number(i)))),
    without([undefined, null, '', {}]),
    filter(propEq('minQuantity', 1)),
    any((category: MerchandiseModifierCategory) => {
      return pipe(
        propOr([], 'modifiers'),
        filter((x: MerchandiseModifier) => includes(`${x.id}`, category.modifierIds)),
        all(propEq('available', false))
      )(merchandiseData)
    })
  )(merchandiseData);

  const fabDisabled = !isMerchantOrderingAvailable
    || Boolean(willBeOverStockLimit)
    || isAnyRequiredModifiersUnavailable
    || Boolean(Object.keys(errors).length);

  return (
    <FabContainer alignment='right' fabStyle={{ padding: "24px 16px 16px" }} fadeTop={isMobile}>
      <Fab disabled={fabDisabled} variant="extended" color="primary" type="submit">
        <Switch>
          <Case condition={!isMerchantOrderingAvailable}>{getOfflineMerchantMessage(orderType, visitedMerchant)}</Case>
          <Case condition={willBeOverStockLimit || isAnyRequiredModifiersUnavailable}>Out of stock</Case>
          <Case condition={!!comboMerch}>{comboStep(comboMerch)}</Case>
          <Default>Add to Cart</Default>
        </Switch>
      </Fab>
    </FabContainer>
  )
}

const RenderModifierForm = (props: RenderMerchandiseProps) => {
  const { merchant, merchandise, onSubmit, merchandiseData, setQuantity, quantity } = props
  const { featureFlags } = useContext(AppContext);
  const { isMerchantOrderingAvailable } = useContext(VisitedMerchantContext);
  const formMethods = useFormContext();

  const onError = (errors: FieldValues) => {
    const firstErrorKey = keys(errors)[0]

    if (!isEmptyOrNil(firstErrorKey)) {
      const elem = document.getElementById(firstErrorKey);

      elem!.scrollIntoView()
    }
  }

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit, onError)}>
      <If condition={merchandise && merchandise.modifierTemplateId}>
        <ModifierTemplate templateId={merchandise?.modifierTemplateId} mercData={merchandiseData} />
      </If>

      <If condition={enableOrderItemNote(featureFlags) && merchant?.orderingConfig?.enabledNotes === true}>
        <RenderOrderNoteItem formMethods={formMethods} />
      </If>

      <If condition={merchandise && isMerchandiseAvailable(merchandise)}>
        <Then>
          <If condition={isMerchantOrderingAvailable}>
            <Then>
              <div className="quantityInput">
                <QuantityInput quantity={quantity} minQuantity={1} onQuantityChange={(q) => setQuantity(q)} />
              </div>
            </Then>
          </If>
          <RenderMerchandiseFab {...props} />
        </Then>
      </If>
    </form>
  )
}

const updateMerchandisePrice = (modifiers: MerchandiseModifiersType, merchandise: Merchandise | null): Merchandise | null => {
  if (Object.keys(modifiers).length === 0 || !merchandise) {
    return merchandise;
  }

  // compute cost of modifiers, all have to be taxable
  const modifierCost = Object.values(modifiers).reduce((c: number, d: number) => {
    return c + Number(d)
  }, 0)

  if (modifierCost > 0) {
    const merchandiseCopy: Merchandise = { ...merchandise }
    merchandiseCopy.price = Number(merchandiseCopy.price) + modifierCost
    return merchandiseCopy
  } else {
    return merchandise;
  }
}

const getUpdatedMerchandiseWithPrice = (modifiersList: MerchandiseModifier[] | undefined, selectedModifiers: any, merchandise: Merchandise | null) => {
  const toModifiers = {}
  modifiersList?.map((modifier) => {
    toModifiers[modifier.id] = modifier
  })

  // Cleanses up modifier data
  const updatedModifiers: MerchandiseModifiersType = {};
  const displayOrder: string[] = [];
  Object
    .entries(selectedModifiers)
    .map(([key, value]: any) => {
      let modifier: MerchandiseModifier | undefined = undefined;
      if (typeof value === 'boolean') {
        if (value) {
          const [order, mid] = key.split('_');
          modifier = toModifiers[mid]
          if (order && modifier) {
            displayOrder.push(modifier.name);
          }
        }
      } else {
        const [order, cid] = key.split('_');
        modifier = toModifiers[value];
        if (order && modifier) {
          displayOrder.push(modifier.name);
        }
      }

      if (modifier)
        updatedModifiers[modifier?.name] = modifier?.price
    })

  return { updatedModifiers, updatedMerchandise: updateMerchandisePrice(updatedModifiers, merchandise), modifiersDisplayOrder: displayOrder }
}

interface CalculatePerItemRedeemPointsProps {
  merchandise: Merchandise | null,
  merchantPointsPerDollar: number,
  modifiers: MerchandiseModifier[] | undefined,
  modifierFormValues: FormValues,
  handlePointsClick: () => void
}

const CalculatePerItemEarnedPoints = ({
  merchantPointsPerDollar,
  modifierFormValues,
  modifiers,
  merchandise,
  handlePointsClick,
}: CalculatePerItemRedeemPointsProps) => {
  const classes = useStyles();
  const { note, ...selectedModifiers } = modifierFormValues;
  const { updatedMerchandise } = getUpdatedMerchandiseWithPrice(modifiers, selectedModifiers, merchandise)
  const totalPoints = Math.round(((updatedMerchandise?.price ?? 0) * merchantPointsPerDollar))

  if (totalPoints === 0) return null

  return (
    <CustomizedChip
      color="secondary"
      onClick={handlePointsClick}
      label={<>+{totalPoints}&nbsp;<GemIcon sx={{width: 16}} color="secondary" /></>}
    />
  )
}

const RenderMerchandise = ({
  merchant,
  merchandise,
  onSubmit,
  merchandiseData,
  setQuantity,
  quantity,
  coupon,
}: RenderMerchandiseProps) => {
  const classes = useStyles();
  const { featureFlags } = useContext(AppContext)
  const reduxDispatch = useAppDispatch()
  const history = useHistory()

  const title = merchandise?.name || ""
  const description = merchandise?.description
  // const pricePrefix = merchandise?.modifierTemplateId ? "Starting at " : "";
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const formMethods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {},
  });

  const modifierFormValues = formMethods.watch();

  const isRedeem1mPointsEnabled = enableRedeemPoints(featureFlags)
  const isRatingEnabled = enableRatings(merchant);
  const pointsRequiredForRedemption = isRedeem1mPointsEnabled ? getRequiredRedemptionPoints(merchandise, merchant) : 0
  const numOfReviews = pathOr(0, ["rating", "reviewCount"])(merchandise);
  const overallRating = path(["rating", "rating"], merchandise);

  const resetModifiers = () => {
    const selectedModifiersTable = {};

    for (const [key] of Object.entries(modifierFormValues)) {
      switch (typeof modifierFormValues[key]) {
        case 'boolean':
          selectedModifiersTable[key] = null;
          break;
        case 'string':
          selectedModifiersTable[key] = '';
          break;
        default:
          selectedModifiersTable[key] = undefined; // Become uncontrolled
          break;
      }
    }

    formMethods.reset({
      selectedModifiers: {
        ...selectedModifiersTable
      },
      note: '',
    });
  }

  const handleSubmitModifiers = (e: FormValues) => {
    onSubmit(e);
    resetModifiers();
  }

  const handlePointsClick = () => {
    reduxDispatch(setShowRewardsEducationDrawer(true))
  }

  const onMerchandiseSelect = () => {
    history.push({ pathname: `${location.pathname}/reviews`, state: { from: window.location.pathname } });
  }

  return (
    <FullscreenPaper className={classes.root}>
      <FormProvider {...formMethods} >
        <Grid container justifyContent="space-evenly" elevation={0} component={ColoredPaper}>
          <Grid item xs={12} md={5} sx={{position: 'relative'}}>
          {merchandise &&
            <>
              <MerchandiseMedia merchandise={merchandise} />
              <div className="share-btn"><ShareButton merchant={merchant} merchandise={merchandise} /></div>
            </>
          }
          </Grid>
          <Grid item xs={12} md={5}>
            <CardHeader
              title={
                <div className="merchandise-title">
                  <Typography variant={isMobile ? "h6" : "h5"} component="div">
                    {title}
                  </Typography>
                </div>
              }
              subheader={
                <>
                  <Stack sx={{ mb: 1 }} direction="row" alignItems="center" spacing={1}>
                    <If condition={isRedeem1mPointsEnabled}>
                      <CalculatePerItemEarnedPoints
                        merchantPointsPerDollar={merchant?.orderingConfig?.pointsPerDollar ?? 0}
                        modifierFormValues={modifierFormValues}
                        modifiers={merchandiseData?.modifiers}
                        merchandise={merchandise}
                        handlePointsClick={handlePointsClick}
                      />
                    </If>
                    <MerchandiseAllergens allergens={merchandise?.ingredientTags} />
                  </Stack>
                  <Typography variant="subtitle1" gutterBottom color="black" alignItems="center" display="flex">
                    {merchandise && <MerchandiseUnitPrice merchandise={merchandise} coupon={coupon} />}
                    <If condition={isRedeem1mPointsEnabled && pointsRequiredForRedemption > 0}>
                      <CustomizedChip
                        sx={{ml: 1}}
                        color="secondary"
                        label={<>{pointsRequiredForRedemption}&nbsp;<GemIcon sx={{width: 16}} color="secondary" />&nbsp;to redeem</>}
                        onClick={handlePointsClick}
                      />
                    </If>
                  </Typography>
                  <If condition={merchandise && !isMerchandiseAvailable(merchandise)}>
                    <RenderSoldOut />
                  </If>
                  <If condition={isMerchandiseStockLow(merchandise)}>
                    <RenderLowStockWarning />
                  </If>
                  <Typography variant="subtitle1" gutterBottom color="textSecondary">
                    <ReactMarkdown>
                      {description || ''}
                    </ReactMarkdown>
                  </Typography>
                  <If condition={isRatingEnabled && numOfReviews >= 3 && overallRating} >
                    <MerchandiseRatingCard
                      rating={overallRating}
                      numOfReviews={numOfReviews}
                      onArrowIconClick={onMerchandiseSelect}
                    />
                  </If>
                </>
              }
            />
            <RenderModifierForm
              setQuantity={setQuantity}
              quantity={quantity}
              merchant={merchant}
              merchandise={merchandise}
              onSubmit={handleSubmitModifiers}
              merchandiseData={merchandiseData}
            />
          </Grid>
        </Grid>
      </FormProvider>
    </FullscreenPaper >
  )
}

type MerchandiseDrawerPageProps = {
  merchant: Merchant,
  merchandiseId: string | undefined,
  coupon?: MerchantCoupon,
}

const MerchandiseDrawerPage = (props: MerchandiseDrawerPageProps): React.ReactElement | null => {
  const { merchant, merchandiseId, coupon: inputCoupon } = props;
  const showDrawer = Boolean(merchandiseId);
  if (!showDrawer) return null;

  const { addItemToCart, cartType, setCoupon } = useContext(AppContext)
  const { visitedMenu } = useContext(VisitedMerchantContext);
  const { user } = useContext(UserContext)
  const history = useHistory();
  const location = useLocation<{ from: { pathname: string } }>();

  const [quantity, setQuantity] = useState<number>(1);
  const [merchandiseData, setMerchandiseData] = useState<MerchandiseApiResponseType | undefined>()

  const classes = useStyles();

  const merchandises = merchandiseData?.merchandises
  const merchandise = merchandises ? merchandises[0] : null
  const playNotificationSound = isNotificationSoundEnabled(user);

  const coupon = useMemo(() => {
    if (inputCoupon) return inputCoupon;
    return merchandise ? getCouponForMerchandise(merchant, merchandise) : undefined;
  }, [merchant.id, merchandise?.id, inputCoupon?.code]);

  const [{ loading }, fetchMerchandise] = useAxios<MerchandiseApiResponseType>(
    { url: '/merchandises' }, { manual: true }
  )

  const isReviewPageActive = location.pathname.endsWith("reviews");

  useEffect(() => {
    // scroll to page top
    scrollToTop();

    if (!isEmptyOrNil(merchandiseId)) {
      const match = visitedMenu?.merchandises.find((item) => item.id === merchandiseId)
      if (visitedMenu && match) {
        setMerchandiseData({
          ...visitedMenu,
          merchandises: [match],
        });
      } else {
        fetchMerchandise({
          params: { id: merchandiseId }
        }).then((response) => setMerchandiseData(response.data))
      }
    } else {
      setMerchandiseData(undefined);
    }
  }, [merchandiseId])

  /**
  * On click header back event handler.
  */
  const handleBack = (): void => {
    const merchantUserName = merchant.username;
    const fromParam = pathOr(undefined, ['state', 'from'], location);

    if (!fromParam) {
      history.replace({
        ...location,
        pathname: `/${merchantUserName}`,
      });
    } else {
      history.goBack();
    }
  }

  /**
   * After they add an item to the cart
   */
  const handleForward = (comboMerch?: {curIndex: number, ids: string[]}): void => {
    const merchantUserName = merchant.username;
    const search = location.search;
    if (comboMerch) {
      const { curIndex, ids } = comboMerch;
      if (curIndex > -1 && curIndex < ids.length-1) {
        const nextId = ids[curIndex + 1];
        history.push({
          pathname: `/${merchantUserName}/item/${nextId}`,
          search,
          state: { from: location.pathname }
        });
        return;
      }
    }

    history.replace({
      pathname: `/${merchantUserName}`,
    });
  }

  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
    if (quantity < 1) { return }

    const { note, ...selectedModifiers } = data
    const { updatedMerchandise, updatedModifiers, modifiersDisplayOrder } = getUpdatedMerchandiseWithPrice(merchandiseData?.modifiers, selectedModifiers, merchandise)

    if (updatedMerchandise) {
      const cartItem = {
        ...updatedMerchandise,
        quantity,
        ...(isEmptyOrNil(updatedMerchandise.modifierTemplateId) ? {
          modifiersSignature: undefined
        } : {
          modifiers: updatedModifiers,
          modifiersDisplayOrder
        }),
        ...(!isEmptyOrNil(note) && { note }),
      } as CartItemType;

      addItemToCart(
        cartItem,
        merchant,
      );

      const search = location.search;
      const comboMerch = parseComboData(search, merchandise);

      // Don't add coupon when users are adding this as part of a combo
      if (!comboMerch && merchandise && isCouponValidOnMerchandise(merchandise, coupon)) {
        setCoupon(coupon);
      }

      // play audio to confirm adding to cart
      const notifAudio = document.getElementById('app_add_to_cart_audio') as HTMLAudioElement;
      notifAudio && playNotificationSound && repeatAudio(notifAudio, 1);

      setQuantity(1);

      eventTrackingService.captureEvent({
        category: EVENT_CATEGORIES.MERCHANDISE,
        action: EVENT_ACTIONS.CLICK_ITEM_ADDED,
        data: {
          merchant,
        },
        label: updatedMerchandise.name,
      });

      // navigate to merchant page
      handleForward(comboMerch);
    }
  }

  if (!merchandiseId) {
    return null;
  }

  const onCloseReviewDrawer = () => {
    if (location?.state?.from) {
      history.goBack();
    } else {
      history.push(`/${merchant?.username}/item/${merchandise?.id}`);
    }
  };

  return (
    <Drawer
      anchor="bottom"
      open={showDrawer}
      onClose={handleBack}
      ModalProps={{ keepMounted: true }}
      className={classes.drawer}
    >
      <div className="container">
        <PageHeader
          title={isAddingItemToOpenCheck(cartType) ? "Add Items to Order" : ""}
          hideOnScroll={false}
          leftChild={<BackButton onBack={handleBack} />}
        />
        <SimpleLoader loading={loading} />
        <If condition={Boolean(merchandise)}>
          <RenderMerchandise
            setQuantity={setQuantity}
            quantity={quantity}
            merchant={merchant}
            merchandise={merchandise}
            onSubmit={onSubmit}
            coupon={coupon}
            merchandiseData={merchandiseData} />
        </If>
      </div>
      <If condition={isReviewPageActive}>
        <MerchandiseReviewsDrawer merchandise={merchandise} closeDrawer={onCloseReviewDrawer} />
      </If>
    </Drawer>
  );
}

export default MerchandiseDrawerPage;
