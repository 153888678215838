import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .bold": {
        fontWeight: "bold"
      },

      "& .MuiAppBar-root": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,

        "& .MuiSvgIcon-root": {
          color: theme.palette.common.white,
        },

        "& .MuiAppBar-colorInherit": {
          backgroundColor: "transparent"
        }
      },

      "& .header-container": {
        color: theme.palette.common.white,
      },

      "& .content-container": {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },

      "& .lootbox-featured-container": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        overflow: "auto",

        display: "flex",
        alignItems: "flex-end",

        padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
        paddingBottom: 0,
      },

      "& .section-container": {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),

        "& .section-header": {
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),

          "& .section-title": {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between"
          }
        },

        "& .section-content": {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
        },

        "& .section-footer": {
          '& .MuiFab-extended': {
            width: '100%',
            borderRadius: theme.spacing(1)
          }
        },
      },

      "& .card-container": {
        marginBottom: theme.spacing(1.5),

        '& .MuiCardHeader-root': {
          paddingBottom: 0,
        }
      },

      "& .progress-indicator": {
        position: "relative",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),

        "& .treasure-icon": {
          position: "absolute",
          right: -20,
          top: -27,

          "& img": {
            height: 50,
            width: 50
          }
        }
      }
    }
  })
)

export default useStyles;