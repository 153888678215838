import { anyPass, filter, find, isEmpty, isNil, keys, path, pathEq, pathOr, pipe, propOr, slice, split, without } from "ramda";
import formatTime from 'date-fns/format';
import { renderToStaticMarkup } from 'react-dom/server';
import { addMinutes } from "date-fns";
import qs, { ParsedQs } from "qs";
import { History, Location } from "history";

import config from './config/config';
import { MERCHANT_LOGO_PLACEHOLDER_DATA, SAN_MATEO_MARKETING_EXPERIMENT_A, SAN_MATEO_MARKETING_EXPERIMENT_B } from "./config/constants";

import { DeviceType } from "./bos_common/src/types/DeviceType";
import { DAYS_OF_WEEK, formatDate } from "./bos_common/src/utils";
import { MerchantPromotion, PromotionCriteriaType, PromotionValueType } from "./bos_common/src/types/MerchantPromotionType";
import { isPromotionValid } from "./bos_common/src/services/PromotionUtils";
import { isCouponValidOnMerchandise } from "./bos_common/src/services/CouponUtils";
import { LOCALSTORAGE_APP_KEYS, MERCHANT_EXPENSE_LEVELS } from "./bos_common/src/constants";
import { getRoundedMinutesOffset } from "bos_common/src/services/hoursUtils";
import CalendarEvent from "bos_common/src/types/crm/CalendarEventType";

import { CouponCriteria, CouponValueType, FeatureFlags, LineItem, Merchandise, Merchant, MerchantCoupon, OrderType, SpecialHours, User } from "./services/models";
import { nextOpenHour } from "./services/hoursUtils";
import PercentageIcon from "./assets/icons/PercentageIcon";
import GemMonoIcon from "./assets/icons/GemMonoIcon";

import { CartItemType, CartType } from "./context/AppContext";
import { MERCHANTS_CATEGORIES_LIST } from "./components/MerchantCategoriesFilters";

export const extractQueryParamsFromLocation = (location: Location): ParsedQs => {
  if (isEmptyOrNil(location)) return {}

  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  return params;
}

export const deleteQueryParamsFromLocation = (location: Location, toDeleteParams: string[], history: History): void => {
  if (isEmptyOrNil(toDeleteParams) || !history) return;
  const params = new URLSearchParams(location.search)

  toDeleteParams.forEach((p: string) => {
    params.delete(p)
  })

  const updatedParams = !isEmptyOrNil(params.toString()) ? `?${params.toString()}` : ''
  const updatedPath = `${location.pathname}${updatedParams}`;
  history.replace(updatedPath, location.state);
}

export const isProduction = () => process.env.NODE_ENV === "production" && config.env.isStaging === 0
export const isDevelopment = () => process.env.NODE_ENV === "development";

export const isEmptyOrNil = anyPass([isEmpty, isNil])

export const getSiteBaseUrl = (): string => {
  if (process.env.NODE_ENV == "production") {
    return "https://www.1m.app"
  } else {
    return "http://localhost:3000"
  }
}

export const getMerchantUrl = (merchant: Merchant): string => {
  return getSiteBaseUrl() + '/' + merchant.username
}

export const simpleStringHash = (input: string): number => {
  let hash = 0, i, chr;
  if (input.length === 0) return hash;
  for (i = 0; i < input.length; i++) {
    chr = input.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0;
  }
  return hash;
}

export const isKioskDevice = (): boolean => config.appConfig.deviceType === DeviceType.Kiosk

export const isAppEmbed = (): boolean => config.appConfig.deviceType === DeviceType.AppEmbed

export const isWebViewEmbed = (): boolean => config.appConfig.deviceType === DeviceType.WebviewEmbed

export const isEmbeddedMode = (): boolean => isAppEmbed() || isWebViewEmbed()

export const isEmailValid = (str: string): boolean => {
  if (isEmptyOrNil(str)) return false

  const regEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/gi
  return new RegExp(regEx).test(str.toLowerCase())
}

export const getTimeLabelFromOffset = (timeOffset: number | null, format = 'p'): string => {
  return timeOffset == null ? 'N/A' : formatTime(new Date().setHours(0, timeOffset, 0), format);
}

export const getStoreStatusLabel = (merchant: Merchant, orderType: OrderType = OrderType.PICKUP): { label: string, canOrder: boolean } => {
  if (merchant.online) {
    if (isStoreOpen(merchant, new Date())) {
      return { label: "Open", canOrder: true };
    }

    if (isMerchantOrderingAhead(merchant)) {
      return { label: "Order ahead", canOrder: true };
    }

    const nextOpenHourLabel = nextOpenHour(merchant);
    if (
      !isEmptyOrNil(merchant.hours) &&
      !isEmptyOrNil(nextOpenHourLabel)
    ) {
      return { label: `Opens at ${nextOpenHourLabel}`, canOrder: false };
    }
  }

  return { label: "Opens later", canOrder: false };
};

export const isStoreOpen = (merchant: Merchant | undefined, date: Date): boolean => {
  if (!merchant) return false

  const currentMinsOffset = getRoundedMinutesOffset(date)
  const day = date.getDay();
  const weekday = DAYS_OF_WEEK[day]
  const todayDateStr = formatTime(date, 'MM-dd');

  const hours = merchant?.hours ?? {}

  // not open if no hours are set
  if (isEmptyOrNil(hours)) return false;

  const customizations = propOr([], 'customizations', hours)
  const normalHours: SpecialHours[] = filter((sh: SpecialHours) =>
    sh.dayOfWeek === weekday, customizations);
  const specialHours: SpecialHours[] = filter((sh: SpecialHours) =>
    sh.specificDate === todayDateStr, customizations);

  if (specialHours?.length > 0) {
    const openTimes = specialHours.filter((sh: SpecialHours) => sh.fromMinOffset <= currentMinsOffset && sh.toMinOffset >= currentMinsOffset)
    return openTimes.length > 0;
  } else if (normalHours?.length > 0) {
    const openTimes = normalHours.filter((sh: SpecialHours) => sh.fromMinOffset <= currentMinsOffset && sh.toMinOffset >= currentMinsOffset)
    return openTimes.length > 0;
  } else {
    const isOpenTimeValid = !isEmptyOrNil(hours.defaultFromMinOffset) && currentMinsOffset >= hours.defaultFromMinOffset
    const isCloseTimeValid = !isEmptyOrNil(hours.defaultToMinOffset) && currentMinsOffset <= hours.defaultToMinOffset

    return (isOpenTimeValid && isCloseTimeValid);
  }
}

export const isStoreHoursConfigured = (merchant: Merchant) => {
  if (!merchant) return false

  const getHoursProp = (propName: string) => pathOr(null, ['hours', propName], merchant)
  return !isEmptyOrNil(getHoursProp('customizations')) || (!isEmptyOrNil(getHoursProp('defaultToMinOffset')) && !isEmptyOrNil(getHoursProp('defaultFromMinOffset')))
}

export const isMerchantServicesType = (merchant: Merchant): boolean => {
  return merchant.type === 'services';
}

export const isMerchantOrderingAhead = (merchant: Merchant) => {
  return merchant.orderingConfig?.orderAhead && merchant.orderingConfig?.numDaysToOrderAhead >= 0 && isStoreHoursConfigured(merchant)
}

export const isMerchantOnline = (merchant: Merchant | undefined, orderType: OrderType = OrderType.PICKUP): boolean => {
  if (!merchant) return false;
  if (isKioskDevice() || orderType === OrderType.DINEIN) return merchant?.kioskOrdering;
  if (!merchant?.online) return false;

  const date = new Date();
  const isValidTime = orderType === OrderType.PICKUP
    ? isMerchantOrderingAhead(merchant) || isStoreOpen(merchant, date)
    : isStoreOpen(merchant, date)

  return isValidTime;
}

export const getOfflineMerchantMessage = (orderType: OrderType, merchant?: Merchant): string => {
  // for kiosk orders
  if (isKioskDevice()) return 'Ordering not available yet';

  // for dine in orders
  if (orderType === OrderType.DINEIN) return 'Dine-in ordering not available yet'

  return 'Online ordering not available yet';
}

export const isMerchandiseStockLow = (merchandise: Merchandise | null): boolean => {
  // if merchandise is not available or if inventory is null, it is not low stock
  if (!merchandise || !merchandise?.inventory?.dailyCurrentStock) return false
  return merchandise.inventory.dailyCurrentStock <= 2
}

export const getSortedMerchantsListBasedOnStatus = (list: Merchant[]): Merchant[] => {
  const openStores: Merchant[] = []
  const closedStores: Merchant[] = []
  const openLaterStore: Merchant[] = []

  list.forEach(merchant => {
    const storeStatus = getStoreStatusLabel(merchant)
    if (storeStatus.label === 'Open') openStores.push(merchant)
    else if (storeStatus.label === 'Opens later') closedStores.push(merchant)
    else openLaterStore.push(merchant)
  })

  return [...openStores, ...openLaterStore, ...closedStores]
}


export const enablePlatformRaffles = (featureFlags: FeatureFlags | undefined): boolean => {
  return pathEq(['enableRaffles'], 0, featureFlags)
}

export const enableOrderItemNote = (featureFlags: FeatureFlags | undefined): boolean => {
  return pathEq(['enableOrderItemNote'], 1, featureFlags)
}

export const enableStartOver = (featureFlags: FeatureFlags | undefined): boolean => {
  return pathEq(['enableStartOver'], 1, featureFlags)
}

export const enableRedeemPoints = (featureFlags: FeatureFlags | undefined): boolean => {
  return pathEq(['enableRedeemPoints'], 1, featureFlags)
}

export const enableRatings = (merchant: Merchant | undefined): boolean => {
  return pathOr(false, ['orderingConfig', 'showRatings'], merchant);
}

export const enableSanMateoMP = (featureFlags: FeatureFlags | undefined): boolean => {
  return pathEq(['enableSanMateoMP'], 1, featureFlags)
}

export const enabledReservations = (featureFlags: FeatureFlags | undefined, merchant: Merchant | undefined): boolean => {
  return pathEq(['enableReservations'], 1, featureFlags) && merchant?.orderingConfig?.enabledReservations;
}

// get points required to redeem an item
// Return points if merchandise has pointsToRedeem specified
// else compute points based on defaultPointsToRedeemADollar set in Merchant Ordering Config
export const getRequiredRedemptionPoints = (merchandise: Merchandise | CartItemType | null, merchant: Merchant | undefined): number => {
  const itemPrice = Number(`${merchandise?.price ?? 0}`)
  return merchandise?.pointsToRedeem ?? Math.round(itemPrice * (merchant?.orderingConfig?.defaultPointsToRedeemADollar ?? 0))
}

export const isMerchandiseAvailable = (merchandise: Merchandise): boolean => {
  const currentDailyStock = path(['inventory', 'dailyCurrentStock'], merchandise)
  if (isEmptyOrNil(currentDailyStock)) return true

  return currentDailyStock > 0
}

export const isOpenCheckEnabled = (merchant: Merchant | undefined): boolean => {
  return merchant?.orderingConfig?.enabledOpenCheck ?? false
}

export const isPaymentDisabled = (merchant: Merchant | undefined): boolean => {
  return merchant?.orderingConfig?.disableDineInPayment ?? false;
}

export const isUserEligibleForPromotion = (criteriaList: CouponCriteria[], user?: User): boolean => {
  for (const criteria of criteriaList) {
    switch (criteria?.type) {
      case PromotionCriteriaType.DEVICE_TYPE:
        return config.deviceType === criteria?.value;
      case PromotionCriteriaType.NONE:
        break;
      default:
        break;
    }
  }
  return true;
}

export const isMerchandisePromoted = (merchandise: Merchandise, promotion?: MerchantPromotion, coupon?: MerchantCoupon): boolean => {
  // ignore promotion object for now

  // check coupon promotions
  if (!coupon || !coupon.promotion || !isCouponValidOnMerchandise(merchandise, coupon)) {
    return false;
  }

  return true;
}

export const getMerchantPromotionCoupon = (merchant: Merchant): MerchantCoupon | undefined => {
  const promotion = pathOr({}, ['promotions', 0], merchant);
  return pathOr({}, ['coupons', 0], promotion);
}

export const isDiscountPromotion = (valueType: PromotionValueType | CouponValueType): boolean => [
  PromotionValueType.ABSOLUTE_REWARDS,
  PromotionValueType.PERCENTAGE_REWARDS,
].findIndex((item) => item === valueType) === -1

export const getPromotionChipStyleConfig = (valueType: PromotionValueType | CouponValueType): { icon: (props: any) => JSX.Element, backgroundColor: string } => {
  return isDiscountPromotion(valueType)
    ? { icon: PercentageIcon, backgroundColor: "#FF8E25" }
    : { icon: GemMonoIcon, backgroundColor: "#FF8E25" }
}

export const isNotificationSoundEnabled = (user: User | undefined): boolean => pathOr(true, ['applicationPreferences', 'notificationSound'], user)

export const getLiveMerchantPromotion = (merchant?: Merchant): MerchantPromotion | undefined => {
  if (!merchant) return undefined
  return find((p: MerchantPromotion) => isPromotionValid(p), propOr([], 'promotions', merchant))
}

export const isAddingItemToOpenCheck = (cartType: CartType | undefined): boolean => {
  return cartType === CartType.ADD_ITEMS_OPEN_CHECK;
}

export const isCartFromAnOpenCheckOrder = (cartType: CartType | undefined): boolean => {
  return cartType === CartType.FROM_OPEN_CHECK;
}

export const commaSeparatedValue = (value: number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export * from "./bos_common/src/utils"

export const scrollToTop = (elementId?: string): void => {
  if (isEmptyOrNil(elementId)) {
    window.scrollTo(0, 0);
    return;
  }

  const el = document.getElementById(elementId!) as HTMLDivElement
  if (!isEmptyOrNil(el)) {
    el.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}

export const stripePublicKey = (production?: boolean): string => {
  const productionFlag = production === undefined ? isProduction() : production;
  return productionFlag
    ? 'pk_live_51IotjOIJSYDtijmKrOPBFfTy72gs124DsB5JsD4KTVGKB65pR1TZwu4Bqbew2l2wfL6g9UMDGaTIboEbgipjKqlo00XfpgRZWA'
    : 'pk_test_51IotjOIJSYDtijmKqRuxe97OR3doGxkozedGxdULMUlmtu0ehEa5mhAoGU89Ncnzr324lwCHoU5EoEG9ZBFJnTEv00lAYXcUwB';
}

export const lineItemWithoutCarryoutBag = (item: LineItem, merchant: Merchant | undefined): boolean => {
  return item.id !== pathOr('', ['orderingConfig', 'carryoutBag', 'id'], merchant)
}

export const getCampaignMarketingExperiment = (search: string) => {
  const params = qs.parse(search, { ignoreQueryPrefix: true })
  const cid = propOr('default', 'cid', params)

  let marketingExperimentValue = sessionStorage.getItem(LOCALSTORAGE_APP_KEYS.SAN_MATEO_MARKETING_TEST_EXPERIMENT)
  if (!marketingExperimentValue) {
    const testValue = `${(Math.floor(Math.random() * 10)) / 10}`  //get random value from 0 to 1

    // save the experiment value to session storage
    sessionStorage.setItem(LOCALSTORAGE_APP_KEYS.SAN_MATEO_MARKETING_TEST_EXPERIMENT, testValue)
    marketingExperimentValue = testValue
  }

  const selectedMarketingExperiment = Number(marketingExperimentValue) < 0.5
    ? SAN_MATEO_MARKETING_EXPERIMENT_A
    : SAN_MATEO_MARKETING_EXPERIMENT_B
  return propOr(selectedMarketingExperiment['default'], cid, selectedMarketingExperiment)
};

export const getMerchantCategory = (merchant: Merchant) => {
  const { category = '' } = merchant ?? {};
  return keys(MERCHANT_LOGO_PLACEHOLDER_DATA).find((name: string) => category.split(',').includes(name)) ?? 'american'
};

export const getMerchantLogoPlaceholder = (merchant: Merchant) => {
  const logo = getMerchantCategory(merchant ?? {});
  return `${MERCHANT_LOGO_PLACEHOLDER_DATA[logo]}_small`;
}

export const getMerchantCategoryLogoUrl = (merchant: Merchant) => {
  const category = getMerchantCategory(merchant);
  const icon = MERCHANTS_CATEGORIES_LIST.find((m) => m.value === category);
  const mockup = renderToStaticMarkup(icon?.icon).replace('<svg', '<svg title="marker-logo" xmlns="http://www.w3.org/2000/svg"');
  return `data:image/svg+xml,${encodeURIComponent(mockup)}`;
};

export const getMerchantCategoryList = (merchant: Merchant): string[] => pipe(
  propOr("", "category"),
  split(","),
  slice(0, 3),
  without([" ", ""])
)(merchant);

export const getMerchantExpenseLevel = (merchant: Merchant) => merchant &&
  MERCHANT_EXPENSE_LEVELS?.find((item: any) => item.value === merchant.expenseLevel)

export const getMerchantAddress = (merchant: Merchant, full = false) => merchant && (
  (full || !merchant.structuredAddress)
    ? merchant.address
    : [merchant.structuredAddress?.line1,
    merchant.structuredAddress?.line2,
    merchant.structuredAddress?.city
    ].filter((item) => item).join(", ")
)

/**
 * Credit to: https://stackoverflow.com/a/21742107/3342601
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export const getMobileOS = (): string => {
  const userAgent = navigator.userAgent || navigator.vendor;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "iOS";
  }

  return "";
}

// ["bitesizedtreats", "jmttoast", "kazka", "kazka_popup", "drop84", "mikestore"]
// const FOLLOW_MERCHANT_ENABLED = ['b_7bsx0ka2j', 'b_6sbtpw495', b_wfj9o5b71, 'b_q9zmwdt0j']
const FOLLOW_MERCHANT_ENABLED = ['b_6sbtpw495', 'b_wfj9o5b71', 'b_q9zmwdt0j', 'b_fnmnetjg7', 'b_rPhNKgo9', 'b_Jem1M9xE', "b_g1evh3qcw", "b_rPhNKgo9"]
// export const isFollowMerchantEnabled = (merchant?: Merchant): boolean => !isEmptyOrNil(merchant) && FOLLOW_MERCHANT_ENABLED.includes(propOr('', 'id', merchant));
export const isFollowMerchantEnabled = (merchant?: Merchant): boolean => false;

// ["ZERO& Tea Truck", "ZERO& Stoneridge Mall", "Zeroand StanfordMall"]
const STOREFRONT_MERCHANT_ENABLED = ['b_np2n2hyfk', 'b_ofo1mu720', 'b_ajfglvb36']
export const isMerchantStoreFrontEnabled = (merchant?: Merchant): boolean => !isEmptyOrNil(merchant) && STOREFRONT_MERCHANT_ENABLED.includes(propOr('', 'id', merchant));

// order review utils
export type ReviewOneMarketStats = {
  overall: number,
  taste: number,
  portion: number,
  value: number
}

export const getRatingsFromReview = (internalReviews: any): ReviewOneMarketStats => {
  let count = 0;
  let sum = 0;
  let taste = 0;
  let value = 0;
  let portion = 0;
  internalReviews.reviews.forEach((review: any) => {
    sum += review.rating.overall ?? 0;
    taste += review.rating.taste ?? 0;
    value += review.rating.value ?? 0;
    portion += review.rating.portion ?? 0;
    count++;
  });
  return {
    overall: sum / count,
    taste: (taste / count),
    portion: (portion / count),
    value: (value / count),
  };
};

export const getReferralGiftPromotion = (merchant: Merchant): MerchantPromotion | null => {
  const promotion = pipe(
    propOr([], 'promotions'),
    find((item: MerchantPromotion) => item.criteriaType === PromotionCriteriaType.REFERRAL_TYPE),
  )(merchant)

  return promotion;
}

export const copyTextToClipboard = (text: string) => {
  try {
    navigator.clipboard.writeText(text)
  } catch (err) {
    console.log('unable to copy text', err)
  }
}

export const getSearchQueryParamsObject = (location: Location): { [key: string]: string } | undefined => {
  const keyValueParams: { [key: string]: string } = {};
  const searchParams: { [key: string]: string }[] = location
    .search
    .substring(1)
    .split('&')
    .map((key) => { const splitted = key.split('='); return { [splitted[0]]: splitted[1] } });

  searchParams.forEach((obj) => {
    for (const [k, v] of Object.entries(obj)) {
      keyValueParams[k] = v;
    }
  });

  return keyValueParams;
}

export const getCalendarEventStartEndDates = (event: CalendarEvent, format = 'KK:mm a'): { startDate: string, endDate: string } => {
  const start = new Date(event.date);
  const end = addMinutes(start, event.duration)

  return {
    startDate: formatDate(start, format),
    endDate: formatDate(end, format)
  }
}
