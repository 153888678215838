import React from "react";
import { Button, createStyles, Theme, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import BottomDrawer from "bos_common/src/components/BottomDrawer";

import PromotionIllustration from "../../assets/images/congratulations-rewards.svg";
import { isEmptyOrNil } from "../../utils";
import { If } from "react-if";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(5),
      borderRadius: "24px",
      maxWidth: 700,
      width: "100%",

      display: "grid",
      gridTemplateColumns: "auto 1fr",
      gridGap: "40px",
      alignItems: 'center',
      margin: '0 auto',

      "@media (max-width: 700px)": {
        textAlign: "center",
        gridTemplateColumns: "1fr",
        maxWidth: 'unset',
        width: "auto"
      },

      "& .dialogImage": {
        maxWidth: "180px",
        width: "100%",

        "@media (max-width: 700px)": {
          margin: "auto",
        },
      },

      "& .dialogContent": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",

        "& .description": {
          color: theme.palette.grey[600],
          fontSize: "16px",
          lineHeight: 1.3,
        },

        "& .content": {
          display: 'flex',
          alignItems: 'flex-start',
          marginBottom: theme.spacing(1.2)
        },

        '& .confirmButton': {
          marginTop: theme.spacing(1),
        },
      },
    },
  })
);

type PromotionDialogProps = {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  title: string;
  description: string;
  promotionImage?: string;
  children?: React.ReactElement;
};

const PromotionDialog = (props: PromotionDialogProps) => {
  const {
    isOpen,
    setOpen,
    title,
    description,
    promotionImage = PromotionIllustration,
    children
  } = props;

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BottomDrawer onClose={handleClose} setOpen={setOpen} open={isOpen}>
      <CardContent className={classes.root}>
        <img className="dialogImage" src={promotionImage} />

        <div className="dialogContent">
          <div>
            <Typography variant="h6" gutterBottom>{title}</Typography>

            <If condition={!isEmptyOrNil(description)}>
              <p className="description">
                {description}
              </p>
            </If>

            <If condition={!isEmptyOrNil(children)}>
              <div className="content">
                {children!}
              </div>
            </If>
          </div>

          <Button onClick={handleClose} color="primary" variant="contained" className="confirmButton">
            Continue
          </Button>
        </div>
      </CardContent>
    </BottomDrawer>
  );
};

export default PromotionDialog;
