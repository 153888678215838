import React from "react";
import { makeStyles, Theme, createStyles, Divider, useMediaQuery } from "@material-ui/core";

import { DialogCard } from "../LearnMoreDialog";
import BannerImage from "../../assets/images/banner/deserve-rewards.png";
import KnowYourRewardsImage from "../../assets/images/merchant-rewards-card.png";
import RedeemPointsImage from "../../assets/images/redeem-reminder.png";
import ShoppingIcon from "../../assets/icons/ShoppingIcon";
import ReviewIcon from "../../assets/icons/ReviewIcon";
import LootBoxIcon from "../../assets/icons/LootboxIcon";
import JoinPromotionIcon from "../../assets/icons/JoinPromotionIcon";
import { RewardsContentStyled } from "../../styles/Common.style";
import { If } from "react-if";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .contentVisual": {
        width: "100%",

        "&.small": {
          margin: "auto",
          width: "auto",
          maxWidth: "100%",
          display: "flex",

          [theme.breakpoints.down("sm")]: {
            width: "90%",
          },
        },
      },

      "& .stepsHeading": {
        color: theme.palette.info.light,
        fontSize: 18,
        fontWeight: 700,
        margin: 0,
        marginBottom: theme.spacing(3),

        "& span": {
          color: theme.palette.secondary.main,
        },
      },

      "& .contentDescription": {
        color: theme.palette.info.light,
        fontSize: 14,
        fontWeight: 500,
        margin: 0,
        marginBottom: theme.spacing(3),

        "& span": {
          color: theme.palette.secondary.main,
        },
      },
    },
  })
);

const cardsList = [
  {
    heading: "Make a Qualified Purchase",
    icon: <ShoppingIcon />,
  },
  {
    heading: "Post a Review",
    icon: <ReviewIcon />,
  },
  {
    heading: "Complete Lootbox Tasks",
    icon: <LootBoxIcon />,
  },
  {
    heading: "Join Promotion & Special Events",
    icon: <JoinPromotionIcon />,
  },
];

const RewardsInfoContent = () => {
  const classes = useStyles();

  const isTabletModeOnly = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("sm", "md")
  );

  return (
    <RewardsContentStyled className="multiColumn">
      <If condition={!isTabletModeOnly}>
        <img src={BannerImage} className="dialogImage" />
      </If>
      <div className="dialogContent">
        <p className="title">One Market 101</p>
        <h3 className="heading">
          Supporting Local <span className="orange">Deserves Rewards!</span>
        </h3>

        <h4 className="subHeading">Introducing One Market Points</h4>
        <p className="description">
          Shop small with One Market and earn rewards points for your local
          buys. Rack up the rewards points and treat yourself by redeeming
          points on any item from a One Market partnered store.
        </p>

        <h4 className="contentHeading">
          <span>STEP 1</span> Earn Everytime You...
        </h4>
        <div className="featuresWrapper">
          {cardsList.map((data, index) => (
            <DialogCard key={`card-${index}`} {...data} />
          ))}
        </div>

        <div className={classes.root}>
          <Divider sx={{ margin: `32px 0` }} />

          <h3 className="stepsHeading">
            <span>STEP 2</span> Know Your Rewards!
          </h3>
          <img
            className="contentVisual small"
            src={KnowYourRewardsImage}
            alt="know-your-rewards"
          />

          <p className="contentDescription">
            <span>“x% Reward Points”</span> means you get One Market Points that
            equals to x% of your purchase value at this store.
          </p>

          <Divider sx={{ margin: `32px 0` }} />

          <h3 className="stepsHeading">
            <span>STEP 3</span> Redeem!
          </h3>
          <img
            className="contentVisual small"
            src={RedeemPointsImage}
            alt="know-your-rewards"
          />
        </div>
      </div>
    </RewardsContentStyled>
  );
};

export default RewardsInfoContent;
