import React from 'react';
import FeaturedItemCard from './FeaturedItemCard';

const LootboxFeaturedItems = () => {
  return (
    <div className='lootbox-featured-container'>
      <FeaturedItemCard
        title='Lootbox Earning Last Week'
        points='1000'
        earnedReward='01 Ramen Dinner'
        />
      <FeaturedItemCard
        title='All Active Missions Worth'
        points='1000'
        earnedReward='01 Italian Dinner'
      />
      <FeaturedItemCard
        title='Lootbox Earning Last Week'
        points='1000'
      />
      <FeaturedItemCard
        title='All Active Missions Worth'
        points='1000'
      />
    </div>
  )
}

export default LootboxFeaturedItems;