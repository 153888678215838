import React from "react";

import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

import BottomDrawer from "../../bos_common/src/components/BottomDrawer";

import ReviewEarnRewards from '../../assets/images/congratulations-rewards.svg';
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    drawerContent: {
      maxWidth: `400px`,
      textAlign: 'center',
      margin: '0 auto',
    }
  })
)

export interface RewardsEarnedDrawerProps {
  reviewsLength: number;
  open: boolean;
  setOpen: () => void;
  onConfirmClose: () => void;
}

export const RewardsEarnedDrawer = (props: Readonly<RewardsEarnedDrawerProps>): JSX.Element => {
  const { reviewsLength, open, setOpen, onConfirmClose } = props;
  const classes = useStyles();

  const handleClickConfirm = () => {
    onConfirmClose();
  }

  return (
    <BottomDrawer
        open={open}
        setOpen={setOpen}
        onClose={handleClickConfirm}
        title={''}
      >
        <CardContent className={classes.drawerContent}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <img src={ReviewEarnRewards} alt="Earn rewards" style={{ height: '250px', width: '200px', marginBottom: '1rem' }} />
            <Typography variant="body1" textAlign={"center"} lineHeight={1.5} gutterBottom>
              Congratulations! You&apos;ve made your review. {(reviewsLength + 1) * 50} points have been added to <Link to='/1mpoints'>your wallet</Link>.
            </Typography>
            <Button variant="contained" color="primary" fullWidth style={{ marginTop: '1rem' }} onClick={handleClickConfirm}>Done</Button>
          </div>
        </CardContent>
      </BottomDrawer>
  );
}

export default RewardsEarnedDrawer;
