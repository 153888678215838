import React, { useContext, useState } from "react"
import { pathOr } from "ramda";
import { Else, If, Then } from "react-if";
import useAxios, { RefetchOptions } from "axios-hooks";
import { AxiosPromise, AxiosRequestConfig } from "axios";
import { MailOutline, Message } from "@material-ui/icons";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Controller, FormProvider, useForm, useFormContext } from "react-hook-form";
import { Alert, Button, createStyles, Drawer, makeStyles, Snackbar, Stack, Switch, Theme, Typography } from "@material-ui/core";

import { UserContext } from "bos_common/src/context/UserContext";
import { MerchantFollower } from "bos_common/src/types/MerchantFollower";
import { ColoredPaper, getAuthHeaders, isEmptyOrNil } from "bos_common/src";

import BackButton from "./common/BackButton";
import PageHeader from "./common/PageHeader";
import SimpleLoader from "bos_common/src/components/SimpleLoader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      '& .MuiDrawer-paperAnchorBottom': {
        scrollBehavior: 'smooth',
      }
    },
  }),
);

type SubscriptionSettingsDrawerProps = {
  followedMerchants?: MerchantFollower[];
  fetchFollowedMerchants: (config?: AxiosRequestConfig<any> | undefined, options?: RefetchOptions | undefined) => AxiosPromise<MerchantFollower[]>;
}

interface IMerchantPageParams {
  merchantId?: string,
}

type CardWithToggleProps = {
  icon: React.ReactElement;
  title: string;
  name: string;
}

type FormType = {
  emailEnabled: boolean;
  smsEnabled: boolean;
}

const CardWithToggle = (props: CardWithToggleProps) => {
  const { title, icon, name } = props;

  const { control } = useFormContext();

  return (
    <ColoredPaper>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ padding: 2 }}>
        <Stack direction="row" alignItems="center" gap={2}>
          {icon}

          <Typography>{title}</Typography>
        </Stack>

        <Controller
          control={control}
          name={name}
          render={({
            field: { onChange, value, ref },
          }) => (
            <Switch onChange={onChange} color="primary" checked={value} inputRef={ref} />
          )}
        />
      </Stack>
    </ColoredPaper>
  )
}

const SubscriptionSettingsDrawer = (props: SubscriptionSettingsDrawerProps) => {
  const { followedMerchants, fetchFollowedMerchants } = props;
  const { merchantId } = useParams<IMerchantPageParams>();
  const showDrawer = !isEmptyOrNil(merchantId);
  if (!showDrawer) return null;

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{ from: { pathname: string } }>();
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const { token } = useContext(UserContext);

  const currentMerchant = followedMerchants?.find((item) => item.merchant.id === merchantId)

  const [{ data: followed, loading }, executeFollowedMerchant] = useAxios(
    {
      url: `/users/follow/${merchantId}`,
      method: "post",
      headers: getAuthHeaders(token),
    },
    { manual: true }
  );

  /**
  * On click header back event handler.
  */
  const handleBack = (): void => {
    const fromParam = pathOr(undefined, ['state', 'from'], location);

    if (!fromParam) {
      history.replace({
        ...location,
        pathname: `/subscription-settings`,
      });
    } else {
      history.goBack();
    }
  }

  const handleFormSubmit = (values: FormType) => {
    executeFollowedMerchant({
      data: {
        ...values
      }
    })
      .then(() => {
        fetchFollowedMerchants()
          .then(() => handleBack());
      })
      .catch(() => {
        setOpenAlert(true);
      });
  }

  const handleAlertClose = () => {
    setOpenAlert(false);
  }

  const methods = useForm<FormType>({
    mode: 'onChange',
    defaultValues: {
      emailEnabled: currentMerchant?.emailEnabled ?? true,
      smsEnabled: currentMerchant?.smsEnabled ?? true,
    }
  })

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <Drawer
        anchor="bottom"
        open={showDrawer}
        onClose={handleBack}
        ModalProps={{ keepMounted: true }}
        className={classes.drawer}
      >
        <div className="container">
          <PageHeader
            title={currentMerchant?.merchant.officialName}
            hideOnScroll={false}
            leftChild={<BackButton onBack={handleBack} />}
          />
          <SimpleLoader loading={loading} />
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack gap={2} p={2} maxWidth={1024} sx={{ boxSizing: 'border-box' }} width="100%" margin="auto">
              <CardWithToggle
                icon={<Message fontSize="large" color="primary" />}
                title="Accept SMS notifications"
                name="smsEnabled"
              />

              <CardWithToggle
                icon={<MailOutline fontSize="large" color="primary" />}
                title="Accept email notifications"
                name="emailEnabled"
              />

              <Button type="submit" variant="contained" color="primary">Save</Button>
            </Stack>
          </form>
        </div>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openAlert}
          autoHideDuration={3000}
          onClose={handleAlertClose}
          sx={{ zIndex: 9999 }}
          ClickAwayListenerProps={{
            onClickAway: handleAlertClose
          }}
        >
          <Alert severity="error">
            Failed to update subscription settings
          </Alert>
        </Snackbar>
      </Drawer>
    </FormProvider >
  )
}

export default SubscriptionSettingsDrawer;
