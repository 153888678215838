import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { isEmptyOrNil } from 'bos_common/src';
import { Else, If, Then } from 'react-if';
import { LocationCardMerchant } from './StoreLocationSelectorDrawer';

interface StoreLocationSelectCardProps {
    store: LocationCardMerchant;
}

const StoreLocationSelectorCard = (props: StoreLocationSelectCardProps) => {
    const { store : {address, name, hours, distance, hasHoursToday} } = props;
    const theme = useTheme();
    return (
        <div>
            <Typography variant="subtitle1" fontWeight={600}>
                <If condition={!isEmptyOrNil(distance)}>
                    <Then>
                        {name} {distance}
                    </Then>
                    <Else>
                        {name}
                    </Else>
                </If>

            </Typography>
            <Typography variant="subtitle2" sx={{color: theme.palette.info.light}}>
                {address}
            </Typography>
            <Typography variant="subtitle2"  sx={{color: hasHoursToday ? 'inherit' : theme.palette.error.main}}>
                {hours}
            </Typography>
        </div>
    );
};
export default StoreLocationSelectorCard;