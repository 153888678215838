import React, { useContext, useMemo } from "react";
import {
  Theme,
  Typography,
  CardActionArea,
  Card,
  Box,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { pathOr, pipe, propOr, without, pluck, dropRepeats, find, defaultTo } from "ramda";
import { If, Then } from "react-if";
import { rgba } from "polished";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";

import { Merchant } from "../services/models";
import { getMerchantCategoryList, getMerchantExpenseLevel, getMerchantLogoPlaceholder, getStoreStatusLabel, isEmptyOrNil } from "../utils";
import { AppContext } from "../context/AppContext";

import { MerchantPromotion } from "bos_common/src/types/MerchantPromotionType";
import { ClassifiedDetailsType, ClassifiedKey } from "bos_common/src/types/ClassifiedType";
import ImageWithFallbackSrc from "bos_common/src/components/ImageWithFallbackSrc";

import PercentageIcon from "../assets/icons/PercentageIcon";
import ExclusiveTickIcon from "../assets/icons/ExclusiveTickIcon";

import RenderMerchantRewards from "./common/MerchantRewards";
import MerchantPromotionChip from "./common/MerchantPromotionChip";
import MerchantCategoriesList from "./common/MerchantCategoriesList";
import { CardHighlightChip, StyledExpenseChip } from "./Classifieds/styledComponents";
import MerchantOrderAheadChip from "./common/MerchantOrderAheadChip";
import MerchantRatingInfo from "./MerchantInfo/MerchantRatingInfo";
import eventTrackingService from "../services/Tracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../services/Tracking/events";
import { getEventLabel } from "../services/Tracking/EventsTracking";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      backgroundColor: 'transparent !important',
      height: '100%',

      '& .MuiCardActionArea-root': {
        height: 'inherit',
        paddingBottom: theme.spacing(1)
      },

      "& .heroPhotoWrapper": {
        position: "relative",
        marginBottom: theme.spacing(1),
        display: "flex",

        "& .overlay": {
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          borderRadius: theme.spacing(1),
          background: rgba(theme.palette.info.light, 0.6),
          zIndex: 10,
          display: "none",
          color: theme.palette.background.paper,
          fontWeight: 500,

          "&.show": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }
        },

        "& .highlights-container": {
          position: "absolute",
          padding: "10px 10px",

          "& .MuiChip-root": {
            marginBottom: "5px",

            "&:not(:last-child)": {
              marginRight: '5px'
            }
          }
        }
      },

      "& .heroPhoto": {
        maxWidth: "100%",
        width: "100%",
        borderRadius: theme.spacing(1),
        height: 170,
        objectFit: "cover",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },

      "& .cardContent": {
        display: "flex",
        justifyContent: "space-between",
        padding: `0 ${theme.spacing(0.5)}`,
        alignItems: "center",

        '& .list-item-icon-container': {
          background: 'transparent',
        },

        "& .MuiTypography-root": {
          textOverflow: 'ellipsis',
          width: 'auto',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        },
      },


      "& .subtitleWrapper": {
        display: "flex",
        alignItems: "center",
        gap: "6px",
        color: theme.palette.secondary.light,
        fontWeight: 400,
        fontSize: "12px",
      },
    },
  })
);

type MerchantCardProps = {
  merchant: Merchant;
};

const MerchantCard = (props: MerchantCardProps) => {
  const { merchant } = props;

  const classes = useStyles();

  const { orderType } = useContext(AppContext);
  const location = useLocation();

  const categoriesList: string[] = useMemo(() => getMerchantCategoryList(merchant), [merchant]);

  const storeStatusLabel = useMemo(() => getStoreStatusLabel(merchant, orderType), [merchant, orderType]);

  const renderLabels = () => {
    const labelsList = [];

    if (storeStatusLabel.canOrder) {
      labelsList.push(
        <span>{storeStatusLabel.label}</span>
      )
    }

    const rewards = RenderMerchantRewards(merchant);
    if (rewards) {
      labelsList.push(rewards);
    }

    const city = merchant.structuredAddress?.city;
    if (city) {
      labelsList.push(<span>{city}</span>);
    }

    if (!isEmptyOrNil(labelsList)) {
      return labelsList
        .map<React.ReactNode>((t, index) => (
          <React.Fragment key={`${index}-${Math.random() * 100}`}>
            {t}
          </React.Fragment>
        ))
        .reduce((prev, curr) => [prev, "|", curr]);
    }

    return null;
  }

  const highlightedPromotion = pathOr({}, ['promotions', 0], merchant) as MerchantPromotion

  const highlightedClassifiedLabel = pipe(
    propOr([], ['classifieds']),
    pluck('details'),
    dropRepeats,
  )(defaultTo([], merchant))

  const classifiedEntries = without([undefined], [
    find((item: ClassifiedDetailsType) => item.key === ClassifiedKey.exclusive, highlightedClassifiedLabel),
    find((item: ClassifiedDetailsType) => item.key !== ClassifiedKey.exclusive, highlightedClassifiedLabel)
  ])

  const HeroImagePath = React.useMemo(() => {
    return isEmptyOrNil(merchant.heroPhoto) ? getMerchantLogoPlaceholder(merchant) : `${merchant.heroPhoto}_medium`
  }, [merchant]);

  const expenseLevel = getMerchantExpenseLevel(merchant)

  const handleMerchantCardClick = () => {
    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.MERCHANT_DISCOVERY,
      action: EVENT_ACTIONS.CLICK_MERCHANT_STORE_FRONT,
      label: getEventLabel(merchant ?? {} as Merchant)
    });
  }

  return (
    <Card className={classes.root}>
      <CardActionArea
        component={Link}
        to={{
          pathname: `/${merchant.username}/store`,
          state: { from: location.pathname },
        }}
        onClick={handleMerchantCardClick}
      >
        <div className="heroPhotoWrapper">
          <ImageWithFallbackSrc src={HeroImagePath} fallbackSrc={merchant?.heroPhoto ?? ""} className="heroPhoto" />

          <div className="highlights-container">
            <If condition={!isEmptyOrNil(highlightedPromotion)}>
              <MerchantPromotionChip promotion={highlightedPromotion} />
            </If>
            <MerchantOrderAheadChip merchant={merchant} />
            <If condition={!isEmptyOrNil(classifiedEntries)}>
              <Then>
                {
                  classifiedEntries.map((data: ClassifiedDetailsType) => (
                    <CardHighlightChip
                      key={data.key}
                      icon={data.key === ClassifiedKey.exclusive ? <ExclusiveTickIcon /> : <PercentageIcon />}
                      label={data.name}
                      color="secondary"
                      size='small'
                      variant='filled'
                      sx={{ backgroundColor: "#0068FF" }}
                    />
                  ))
                }
              </Then>
            </If>
          </div>
        </div>

        <Box sx={{ p: 1 }}>
          <div className="cardContent">
            <Typography component="div" variant="h6">
              {merchant.officialName}
            </Typography>
            <MerchantRatingInfo merchant={merchant} />
          </div>
          <div className="subtitleWrapper">
            {renderLabels()}
          </div>
          <Box sx={{ pr: 2, mt: 1, display: "flex", alignItems: "flex-end" }}>
            <If condition={!isEmptyOrNil(merchant?.expenseLevel) && !isEmptyOrNil(expenseLevel)}>
              <Then>
                <StyledExpenseChip
                  label={expenseLevel?.label || ""}
                  variant="filled"
                  size="small"
                  sx={{ marginRight: 1 }}
                />
              </Then>
            </If>
            <If condition={!isEmptyOrNil(categoriesList)}>
              <MerchantCategoriesList categoriesList={categoriesList} />
            </If>
          </Box>
        </Box>
      </CardActionArea>
    </Card >
  );
};

export default MerchantCard;
