import React from "react";
import { IconButton } from "@material-ui/core"
import { FileCopyOutlined } from "@material-ui/icons"
import { Merchant } from "bos_common/src/types/MerchantType"

const CopyAddressButton = ({ merchant } : { merchant: Merchant | undefined }): React.ReactElement | null=> {

  if (!merchant) {
    return null;
  }

  const copyAddressToClipboard = () => {
    try {
      navigator.clipboard.writeText(merchant?.address || '')
    } catch (err) {
      console.log('unable to copy address', err)
    }
  }
  return (
    <IconButton
      className="copyAddressButton"
      onClick={() => copyAddressToClipboard()}
      color="secondary"
      aria-label="copy address">
      <FileCopyOutlined fontSize='small' color="primary" />
    </IconButton>
  )
}

  export default CopyAddressButton;