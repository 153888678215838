import React, { useState } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core";

import BottomDrawer from "../../bos_common/src/components/BottomDrawer";
import { Merchant } from "../../services/models";
import InstagramFeed from "../ContentFeed/InstagramFeed";
import CardWrapper from "./CardWrapper";
import eventTrackingService from "../../services/Tracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../../services/Tracking/events";
import { getEventLabel } from "../../services/Tracking/EventsTracking";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerFeeds: {

      [theme.breakpoints.down('sm')]: {
        maxHeight: "70vh",
      },

      [theme.breakpoints.up('sm')]: {
        maxWidth: "70vw",
      },

      [theme.breakpoints.up('md')]: {
        maxWidth: "50vw",
      },

      "&.feedsContainer": {
        padding: theme.spacing(1, 3),
        gridTemplateColumns: "1fr 1fr 1fr 1fr",

        [theme.breakpoints.down('md')]: {
          gridTemplateColumns: "1fr 1fr",
        }
      },
    },
  })
);

type MerchantMediaListProps = {
  merchant?: Merchant;
};

const MerchantMediaList = (props: MerchantMediaListProps) => {
  const { merchant } = props;

  const classes = useStyles();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [feedsCount, setFeedsCount] = useState(0);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const cardsLimit = isMobile ? 6 : 8;

  const handleSeeMoreClick = () => {
    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.MERCHANT_STORE_FRONT,
      action: EVENT_ACTIONS.CLICK_SEEMORE_LOCALBUZZ,
      data: {
        merchant: merchant,
      },
      label: getEventLabel(merchant ?? {} as Merchant),
    });
    setIsDialogOpen(true);
  }

  return (
    <CardWrapper
      heading="Local Buzz"
      itemsCount={feedsCount}
      handleSeeMore={
        feedsCount > cardsLimit ? handleSeeMoreClick : undefined
      }
    >
      <InstagramFeed
        feedsLimit={cardsLimit}
        merchantId={merchant?.id}
        setFeedsCount={setFeedsCount}
      />

      <BottomDrawer
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        anchor={isMobile ? "bottom" : "right"}
        fullWidth={!isMobile}
        title="Social Media"
      >
        <InstagramFeed
          customClassName={classes.drawerFeeds}
          merchantId={merchant?.id}
          setFeedsCount={setFeedsCount}
        />
      </BottomDrawer>
    </CardWrapper>
  );
};

export default MerchantMediaList;
