import { AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "../bos_common/src/services/backendAxios";
import { Review } from "../bos_common/src/types/ReviewType";

export interface ReviewRequestBody {
  review: Partial<Review>,
}

export const getReviewsForOrder = (orderId: string, request?: AxiosRequestConfig): Promise<AxiosResponse> => {
  return axios({
    url: `/orders/${orderId}/reviews`,
    method: 'GET',
    headers: (request && request.headers) ? request.headers : {},
  });
}

export const postReview = (body: ReviewRequestBody, request?: AxiosRequestConfig): Promise<AxiosResponse> => {
  return axios({
    url: '/reviews',
    method: 'POST',
    data: { ...body.review },
    headers: (request && request.headers) ? request.headers : {},
  });
}

export default {
  getReviewsForOrder,
  postReview,
}
