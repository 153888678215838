import React from "react";
import { SvgIcon } from "@material-ui/core";

function ShoppingIcon(props: any) {
  return (
    <SvgIcon
      sx={{ width: "49px", height: "48px" }}
      viewBox="0 0 49 48"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_10177_54428)">
        <path
          d="M31.6022 1.23816L47.5122 17.1482C49.1627 18.7987 49.1627 21.4636 47.5122 23.1141L29.8304 40.7959C29.2734 41.3525 28.3986 41.3525 27.8416 40.7959C27.8416 40.7959 24.4211 38.25 23.8641 38.807C23.3071 39.364 25.853 42.7844 25.853 42.7844C26.4097 43.3414 26.4097 44.2163 25.853 44.7733L23.8641 46.7618C22.2136 48.4127 19.5487 48.4127 17.8978 46.7618L1.98816 30.8522C0.33728 29.2013 0.33728 26.5367 1.98816 24.8859L3.97668 22.897C4.53369 22.3403 5.40857 22.3403 5.96558 22.897C5.96558 22.897 9.38599 25.4429 9.94299 24.8859C10.4996 24.3289 7.9541 20.9084 7.9541 20.9084C7.39709 20.3514 7.39709 19.4766 7.9541 18.9196L25.6359 1.23816C27.2867 -0.41272 29.9513 -0.41272 31.6022 1.23816Z"
          fill="#FF7816"
        />
        <path
          d="M39.5569 9.19287L47.5121 17.1477C49.1626 18.7986 49.1626 21.4631 47.5121 23.114L29.8303 40.7958C29.2733 41.3524 28.3984 41.3524 27.8414 40.7958C27.8414 40.7958 24.421 38.2499 23.864 38.8069C23.307 39.3639 25.8529 42.7843 25.8529 42.7843C26.4095 43.3413 26.4095 44.2162 25.8529 44.7732L23.864 46.7617C22.2135 48.4126 19.5486 48.4126 17.8977 46.7617L9.94287 38.8069L39.5569 9.19287Z"
          fill="#FF4B00"
        />
        <path
          d="M11.9321 26.8748C12.4818 26.3251 12.4818 25.4355 11.9321 24.8859L7.95471 20.9084C8.5033 21.457 8.5033 22.3484 7.95471 22.897C7.40613 23.4459 6.5144 23.4459 5.96582 22.897L9.94324 26.8748C10.4929 27.4241 11.3824 27.4241 11.9321 26.8748Z"
          fill="#FF4B00"
        />
        <path
          d="M21.8756 36.8185C21.326 37.3682 21.326 38.2577 21.8756 38.807L25.8531 42.7845C25.3045 42.2359 25.3045 41.3445 25.8531 40.7959C26.4016 40.2473 27.293 40.2473 27.8416 40.7959L23.8642 36.8185C23.3145 36.2688 22.425 36.2688 21.8756 36.8185Z"
          fill="#D03B00"
        />
        <path
          d="M23.647 17.1478C22.0023 15.5028 19.326 15.5028 17.681 17.1478C16.036 18.7928 16.036 21.4691 17.681 23.1141C19.3257 24.7592 22.002 24.7592 23.647 23.1141C25.2924 21.4691 25.2924 18.7928 23.647 17.1478ZM21.6584 21.1256C21.3018 21.4823 20.8824 21.5354 20.6642 21.5354C20.4459 21.5354 20.0266 21.4823 19.6699 21.1256C19.3132 20.7689 19.2598 20.3492 19.2598 20.131C19.2598 19.9127 19.3129 19.4934 19.6696 19.1367C20.0266 18.78 20.4459 18.7269 20.6642 18.7269C20.8824 18.7269 21.3018 18.78 21.6584 19.1367C22.2067 19.6849 22.2067 20.577 21.6584 21.1256Z"
          fill="#FDBF00"
        />
        <path
          d="M39.5572 17.1478C37.9122 15.5028 35.2359 15.5028 33.5909 17.1478C31.9463 18.7928 31.9463 21.4691 33.5909 23.1141C35.2359 24.7592 37.9122 24.7592 39.5572 23.1141C41.2022 21.4691 41.2022 18.7928 39.5572 17.1478ZM37.5687 21.1256C37.212 21.4823 36.7927 21.5354 36.5744 21.5354C36.3562 21.5354 35.9365 21.4823 35.5798 21.1256C35.0316 20.577 35.0316 19.6849 35.5798 19.1367C35.9365 18.78 36.3562 18.7269 36.5744 18.7269C36.7927 18.7269 37.212 18.78 37.5687 19.1367C38.1169 19.6849 38.1169 20.577 37.5687 21.1256Z"
          fill="#FF9F00"
        />
        <path
          d="M28.0225 9.90897L29.832 18.9181L31.9999 29.7964C32.0794 30.2736 31.9201 30.7511 31.6022 31.069C31.4034 31.2679 31.1646 31.3873 30.8859 31.4667C30.1106 31.6062 29.3746 31.1089 29.2156 30.3531L27.4058 21.3443L25.2382 10.4656C25.099 9.68998 25.596 8.95426 26.3518 8.79532C27.1275 8.65616 27.8632 9.15311 28.0225 9.90897Z"
          fill="#FDBF00"
        />
        <path
          d="M15.9093 28.8633L19.8867 32.8407C20.4434 33.3977 20.4434 34.2726 19.8867 34.8296C19.3297 35.3866 18.4548 35.3866 17.8978 34.8296L13.9204 30.8522C13.3638 30.2952 13.3638 29.4203 13.9204 28.8633C14.4774 28.3063 15.3523 28.3063 15.9093 28.8633Z"
          fill="#FF4B00"
        />
        <path
          d="M15.9092 32.8406L17.8977 30.8521L19.8866 32.8406C20.4432 33.3976 20.4432 34.2725 19.8866 34.8295C19.3296 35.3865 18.4547 35.3865 17.8977 34.8295L15.9092 32.8406Z"
          fill="#D03B00"
        />
        <path
          d="M29.8324 18.918L32 29.7963C32.0795 30.2734 31.9206 30.751 31.6023 31.0689C31.4035 31.2677 31.1647 31.3871 30.8864 31.4666C30.1107 31.6061 29.375 31.1088 29.2157 30.3529L27.4062 21.3441L29.8324 18.918Z"
          fill="#FF9F00"
        />
      </g>
      <defs>
        <clipPath id="clip0_10177_54428">
          <rect
            width="48"
            height="48"
            fill="white"
            transform="translate(0.75)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default ShoppingIcon;
