import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Else, If, Then } from 'react-if';
import { propOr } from 'ramda';

// src
import renderPrice, { renderPriceWithUnit } from '../../bos_common/src/components/Price';
import { Merchandise } from '../../bos_common/src/types/MerchandiseType';
import { CouponValueType, MerchantCoupon } from '../../bos_common/src/types/MerchantCouponType';
import { isCouponValid, isCouponValidOnMerchandise } from '../../bos_common/src/services/CouponUtils';
import { isPromotionValid } from '../../bos_common/src/services/PromotionUtils';

import { CartItemType } from '../../context/AppContext';
import { toFixed2 } from '../../utils';

export interface MerchandisePriceProps {
  merchandise: Merchandise | CartItemType;
  coupon?: MerchantCoupon;
  isCart?: boolean;
}

export const getBasicDiscountAmount = (subtotal: number, coupon: MerchantCoupon | undefined): number => {
  if (!isCouponValid(coupon)) return 0;
  if (coupon?.promotion && !isPromotionValid(coupon.promotion)) return 0;

  switch (coupon?.valueType) {
    case CouponValueType.PERCENTAGE:
      return toFixed2(subtotal * coupon.value);
    case CouponValueType.ABSOLUTE:
      return Math.min(coupon?.value || 0, subtotal);
    default:
      return 0;
  }
}

const renderMerchadiseDiscountedPrice = ({
  merchandise,
  coupon,
  currency = 'USD',
  isCart = false,
}: { merchandise: Merchandise | CartItemType, coupon: MerchantCoupon | undefined, currency?: string, isCart?: boolean }): string | null => {

  const quantity = propOr(1, 'quantity', merchandise)
  const couponRedeemedAmount = propOr(0, 'couponRedeemedAmount', merchandise);
  const value = merchandise.price * quantity;

  const isCouponValid = coupon && isCouponValidOnMerchandise(merchandise, coupon, true)

  // We only render the lower price if there is a promotion and the merchandise is included
  if (!isCouponValid) {
    return null;
  }

  const discount = isCart ? couponRedeemedAmount : getBasicDiscountAmount(value, coupon);
  if (discount <= 0) {
    return null;
  }

  return renderPrice(Math.max(value - discount, 0), currency);
};

export const MerchandisePrice = (props: Readonly<MerchandisePriceProps>): JSX.Element => {
  const { merchandise, coupon, isCart } = props;

  const quantity = propOr(1, 'quantity', merchandise)
  const originalPrice = renderPrice(merchandise.price * quantity);
  const merchandiseDiscountedPrice = renderMerchadiseDiscountedPrice({ merchandise, coupon, isCart })

  return (
    <If condition={merchandiseDiscountedPrice}>
      <Then>
        <Typography style={{ fontSize: '1rem', fontWeight: 700 }} color="secondary">{merchandiseDiscountedPrice}</Typography>&nbsp;
        <Typography style={{ fontSize: '.75rem', fontWeight: 400, textDecoration: 'line-through' }} color="textSecondary">{originalPrice}</Typography>&nbsp;
      </Then>
      <Else>
        {originalPrice}
      </Else>
    </If>
  );
};

export const MerchandiseUnitPrice = (props: Readonly<MerchandisePriceProps>): JSX.Element => {
  const { merchandise, coupon, isCart } = props;

  const quantity = propOr(1, 'quantity', merchandise)
  const originalPrice = renderPriceWithUnit(merchandise.price * quantity, 'USD', merchandise.unit, merchandise.unitQuantity);
  const merchandiseDiscountedPrice = renderMerchadiseDiscountedPrice({ merchandise, coupon, isCart })

  return (
    <If condition={merchandiseDiscountedPrice}>
      <Then>
        <Typography style={{ fontSize: '1rem', fontWeight: 700 }} color="secondary">{merchandiseDiscountedPrice}</Typography>&nbsp;
        <Typography style={{ fontSize: '.75rem', fontWeight: 400, textDecoration: 'line-through' }} color="textSecondary">{originalPrice}</Typography>&nbsp;
      </Then>
      <Else>
        {originalPrice}
      </Else>
    </If>
  );
};

export default MerchandisePrice;
