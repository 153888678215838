import React from "react";
import { SvgIcon } from "@material-ui/core";

function CashOnDeliveryIcon(props: any) {
  return (
    <SvgIcon
      sx={{ width: "41px", height: "41px" }}
      viewBox="0 0 41 41"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_17209_73580)">
        <path d="M19.2587 11.6573C16.8356 10.1259 18.9466 6.40195 21.533 7.8548C12.3203 2.15018 26.1617 10.7274 10.9791 1.31841C10.8437 1.23449 10.6809 1.20665 10.5253 1.2408C10.3693 1.28075 10.2347 1.37905 10.1492 1.51541L5.97073 8.45172C5.89085 8.58591 5.86673 8.74606 5.90354 8.89782C5.94036 9.04959 6.03517 9.18089 6.16766 9.26357L20.4946 18.3127C20.5599 18.3552 20.6329 18.3844 20.7096 18.3984C20.7862 18.4124 20.8649 18.411 20.941 18.3944C21.0171 18.3777 21.0891 18.346 21.1529 18.3013C21.2167 18.2565 21.2709 18.1995 21.3124 18.1336C21.4497 17.9182 23.6954 14.4475 23.736 14.367L19.2587 11.6573Z" fill="#9DCC6B" />
        <path d="M19.258 11.6578C16.8355 10.1284 18.9446 6.40276 21.5323 7.8553C12.3175 2.14061 23.814 9.27399 13.4622 2.85938C12.8427 4.78468 10.0864 5.47161 8.64497 4.01707L5.97073 8.45222C5.89085 8.58641 5.86673 8.74656 5.90354 8.89832C5.94036 9.05009 6.03517 9.18139 6.16766 9.26407L20.494 18.3132C20.5593 18.3557 20.6324 18.3847 20.709 18.3987C20.7856 18.4127 20.8643 18.4113 20.9403 18.3946C21.0164 18.3779 21.0884 18.3463 21.1522 18.3016C21.216 18.2569 21.2702 18.1999 21.3118 18.1341L23.735 14.3678L19.258 11.6578Z" fill="#9DCC6B" />
        <path d="M24.053 13.8842L23.7367 14.3677L19.2597 11.6577C17.8521 10.8686 17.8602 8.6359 19.2597 7.84929L19.2657 7.85529C19.6069 7.64596 19.9995 7.53516 20.3999 7.53516C20.8002 7.53516 21.1928 7.64596 21.5341 7.85529L21.546 7.86129C22.2103 8.27713 21.5898 9.28567 20.9191 8.87598C20.1869 8.38929 19.1884 9.14167 19.4149 9.98036C19.4436 10.1158 19.4997 10.2439 19.5798 10.3568C19.6599 10.4697 19.7622 10.5651 19.8805 10.637L23.8621 13.0486C23.9305 13.09 23.9899 13.1447 24.0367 13.2095C24.0836 13.2744 24.1169 13.348 24.1347 13.4259C24.1525 13.5039 24.1545 13.5846 24.1404 13.6634C24.1264 13.7421 24.0966 13.8172 24.053 13.8842Z" fill="#84B749" />
        <path d="M32.7114 4.79385L27.2912 1.74947C26.1672 1.11169 24.8671 0.854295 23.5848 1.01567C22.3026 1.17704 21.1068 1.74853 20.1758 2.64493L17.4297 5.31316C17.4901 5.35055 21.5923 7.89424 21.5306 7.85609C18.9465 6.40332 16.832 10.1262 19.2564 11.6585L24.8853 15.067C27.6401 16.7179 29.6878 14.6955 31.9351 13.3362C31.8793 13.1978 31.8509 13.0498 31.8515 12.9005V6.33393C31.8533 6.02704 31.9724 5.73248 32.1844 5.51054C32.3964 5.2886 32.6851 5.15607 32.9916 5.14008C32.9466 4.99269 32.8462 4.86855 32.7114 4.79385Z" fill="#F6CCAF" />
        <path d="M32.9919 5.14032C32.5776 5.17224 32.2668 5.36224 32.0846 5.6357L27.2912 2.94339C26.1672 2.3056 24.8671 2.0482 23.5849 2.20955C22.3026 2.37091 21.1068 2.94239 20.1758 3.83878L18.1758 5.77878L17.4297 5.31316L20.1758 2.64493C21.1068 1.74853 22.3026 1.17704 23.5848 1.01567C24.8671 0.854295 26.1672 1.11169 27.2912 1.74947L32.7114 4.79385C32.8463 4.86854 32.9469 4.99279 32.9919 5.14032Z" fill="#F3BD98" />
        <path d="M24.6916 36.1796H12.7532C12.5949 36.1796 12.443 36.1166 12.3311 36.0047C12.2191 35.8927 12.1562 35.7409 12.1562 35.5826V21.8533C12.1568 21.3785 12.3456 20.9233 12.6813 20.5876C13.017 20.2518 13.4722 20.063 13.947 20.0625H28.2732C28.748 20.063 29.2032 20.2518 29.5389 20.5876C29.8746 20.9233 30.0634 21.3785 30.0639 21.8533V31.4041C30.0639 31.4892 30.0458 31.5732 30.0106 31.6507C29.9755 31.7282 29.9242 31.7972 29.8603 31.8533L25.0848 36.0317C24.976 36.127 24.8363 36.1796 24.6916 36.1796Z" fill="#FFCC66" />
        <path d="M25.2864 29.0181H23.4955C22.7155 29.0147 22.7146 27.8275 23.4955 27.8242H25.2863C26.0665 27.8275 26.0673 29.0148 25.2864 29.0181Z" fill="#FFBA55" />
        <path d="M22.5642 25.3308L21.1103 24.3372L19.6565 25.3308C19.2815 25.6057 18.7076 25.3029 18.723 24.838V20.6594C18.723 20.5011 18.7858 20.3493 18.8978 20.2373C19.0097 20.1254 19.1616 20.0625 19.3199 20.0625H22.9014C23.0596 20.0627 23.2113 20.1256 23.3231 20.2376C23.4349 20.3495 23.4977 20.5012 23.4977 20.6594V24.8379C23.5123 25.3024 22.9403 25.6056 22.5642 25.3308Z" fill="#F8834B" />
        <path d="M24.6916 36.1796H12.7532C12.5949 36.1796 12.443 36.1166 12.3311 36.0047C12.2191 35.8927 12.1562 35.7409 12.1562 35.5826V21.8533C12.1568 21.3785 12.3456 20.9233 12.6813 20.5876C13.017 20.2518 13.4722 20.063 13.947 20.0625H28.2732C28.748 20.063 29.2032 20.2518 29.5389 20.5876C29.8746 20.9233 30.0634 21.3785 30.0639 21.8533V31.4041C30.0639 31.4892 30.0458 31.5732 30.0106 31.6507C29.9755 31.7282 29.9242 31.7972 29.8603 31.8533L25.0848 36.0317C24.976 36.127 24.8363 36.1796 24.6916 36.1796Z" fill="#FFCC66" />
        <path d="M25.2864 29.0181H23.4955C22.7155 29.0147 22.7146 27.8275 23.4955 27.8242H25.2863C26.0665 27.8275 26.0673 29.0148 25.2864 29.0181Z" fill="#FFBA55" />
        <path d="M22.5642 25.3308L21.1103 24.3372L19.6565 25.3308C19.2815 25.6057 18.7076 25.3029 18.723 24.838V20.6594C18.723 20.5011 18.7858 20.3493 18.8978 20.2373C19.0097 20.1254 19.1616 20.0625 19.3199 20.0625H22.9014C23.0596 20.0627 23.2113 20.1256 23.3231 20.2376C23.4349 20.3495 23.4977 20.5012 23.4977 20.6594V24.8379C23.5123 25.3024 22.9403 25.6056 22.5642 25.3308Z" fill="#F8834B" />
        <path d="M28.2725 20.0625H21.1094V36.1796H24.691C24.836 36.1791 24.976 36.1261 25.0849 36.0303L29.8604 31.8517C29.9246 31.7962 29.9761 31.7274 30.0111 31.6501C30.0461 31.5728 30.064 31.4888 30.0633 31.404V21.8533C30.0619 21.3788 29.8728 20.9241 29.5373 20.5885C29.2017 20.253 28.747 20.0639 28.2725 20.0625Z" fill="#FFBA55" />
        <path d="M25.2864 29.0181H23.4955C22.7155 29.0147 22.7146 27.8275 23.4955 27.8242H25.2863C26.0665 27.8275 26.0673 29.0148 25.2864 29.0181Z" fill="#FFA92C" />
        <path d="M22.9001 20.0625H21.1094V24.3363L22.5659 25.3332C22.9351 25.6067 23.5109 25.3007 23.4971 24.8377V20.6593C23.4966 20.5012 23.4335 20.3497 23.3217 20.2378C23.2098 20.126 23.0583 20.063 22.9001 20.0625Z" fill="#F66824" />
        <path d="M13.4618 2.85921C12.8422 4.78452 10.086 5.47144 8.64453 4.0169L10.1488 1.51575C10.1897 1.44766 10.2438 1.38843 10.3079 1.34154C10.3721 1.29465 10.4449 1.26104 10.5222 1.24269C10.5995 1.22434 10.6797 1.22162 10.758 1.23468C10.8364 1.24774 10.9114 1.27632 10.9785 1.31875C11.0518 1.3639 13.3847 2.81098 13.4618 2.85921Z" fill="#6F9A3D" />
        <path d="M9.46876 8.76076C9.47017 9.52894 9.17258 10.2675 8.63899 10.8201C8.0456 10.4494 6.7676 9.64107 6.16768 9.26214C6.03518 9.17949 5.94036 9.04821 5.90354 8.89645C5.86673 8.74469 5.89086 8.58455 5.97076 8.45037L6.84222 7.00583L7.47499 5.94922C8.05752 6.15375 8.56218 6.53407 8.91932 7.03769C9.27645 7.5413 9.46843 8.14337 9.46876 8.76076Z" fill="#6F9A3D" />
        <path d="M22.9777 15.5555L22.3032 16.6001L21.3124 18.1342C21.2709 18.2001 21.2166 18.2572 21.1529 18.3019C21.0891 18.3467 21.0171 18.3783 20.941 18.395C20.8649 18.4117 20.7862 18.4131 20.7096 18.399C20.6329 18.385 20.5598 18.3559 20.4945 18.3134C19.8895 17.9311 18.6317 17.1348 18.0352 16.7553C18.6201 14.6885 21.4955 13.9921 22.9777 15.5555Z" fill="#6F9A3D" />
        <path d="M15.7374 12.3028C12.5819 12.2028 12.5827 7.62719 15.7374 7.52734C18.8929 7.62765 18.8922 12.203 15.7374 12.3028Z" fill="#6F9A3D" />
        <path d="M31.6396 29.1253C31.3996 28.7625 31.0346 28.5007 30.6141 28.3896C30.1936 28.2784 29.7469 28.3256 29.359 28.5223C28.3463 29.0161 20.9118 32.6431 20.1066 33.0351C20.1795 32.9102 20.2395 32.7782 20.2857 32.6411C20.3778 32.3978 20.4341 32.1425 20.4528 31.883V31.877C20.559 30.3418 19.3188 29.0113 17.7905 29.0178H13.7914L13.6064 28.4563C13.4896 28.0987 13.2628 27.7872 12.9583 27.5663C12.6538 27.3455 12.2873 27.2265 11.9111 27.2266H9.17122C9.01303 27.227 8.86147 27.2901 8.74961 27.402C8.63776 27.5138 8.57471 27.6654 8.57422 27.8236V36.1328C8.57511 36.2467 8.60805 36.358 8.66927 36.4541C8.73048 36.5502 8.8175 36.627 8.92037 36.6759L15.4388 39.6785C16.8058 40.2908 18.3218 40.491 19.8009 40.2547C21.28 40.0183 22.658 39.3556 23.7661 38.3477L31.359 31.4712C31.6858 31.1829 31.895 30.7844 31.9467 30.3517C31.9985 29.9191 31.8892 29.4825 31.6396 29.1253Z" fill="#F6CCAF" />
        <path d="M31.3588 31.469L23.766 38.3459C22.6579 39.3537 21.2799 40.0164 19.8008 40.2528C18.3217 40.4892 16.8058 40.289 15.4388 39.6766L8.92037 36.674C8.8175 36.625 8.73048 36.5482 8.66927 36.4521C8.60805 36.356 8.57511 36.2447 8.57422 36.1308V34.9371C8.57511 35.051 8.60804 35.1624 8.66926 35.2584C8.73047 35.3545 8.8175 35.4314 8.92037 35.4803L15.4388 38.4829C16.8059 39.0951 18.3218 39.2953 19.8009 39.0589C21.28 38.8224 22.658 38.1596 23.7661 37.1517L31.3588 30.2751C31.5787 30.0757 31.7467 29.8257 31.8484 29.5469C31.9707 29.8792 31.9893 30.2408 31.9019 30.5839C31.8145 30.927 31.6252 31.2356 31.3588 31.469Z" fill="#F3BD98" />
        <path d="M7.97241 38.5666H4.3938C4.23548 38.5666 4.08365 38.5037 3.97171 38.3918C3.85977 38.2799 3.79688 38.128 3.79688 37.9697V26.6282C3.79688 26.4699 3.85977 26.318 3.97171 26.2061C4.08365 26.0941 4.23548 26.0312 4.3938 26.0312H7.97218C8.44778 26.0318 8.90375 26.2209 9.24007 26.5572C9.57639 26.8935 9.76559 27.3494 9.76618 27.825V36.7729C9.76559 37.2484 9.57642 37.7043 9.24015 38.0406C8.90388 38.3769 8.44797 38.566 7.97241 38.5666Z" fill="#425B72" />
        <path d="M9.76618 35.8711V36.7725C9.76563 37.2488 9.57615 37.7055 9.2393 38.0423C8.90245 38.3791 8.44576 38.5685 7.96941 38.569H4.3938C4.23563 38.5686 4.08407 38.5055 3.97223 38.3937C3.86038 38.2818 3.79734 38.1303 3.79688 37.9721V35.8711H9.76618Z" fill="#374F68" />
        <path d="M21.5374 32.3359C21.5089 33.0225 21.217 33.6717 20.7223 34.1486C20.2276 34.6255 19.5682 34.8934 18.8811 34.8967H16.2307C15.4499 34.8942 15.4484 33.7055 16.2307 33.7029H18.881C19.1245 33.702 19.3639 33.6404 19.5776 33.5237C19.7913 33.407 19.9724 33.2388 20.1047 33.0343L21.5374 32.3359Z" fill="#E7A273" />
        <path d="M7.37744 34.2612C7.37405 35.0418 6.18698 35.0418 6.18359 34.2612C6.18682 33.4806 7.37413 33.4807 7.37744 34.2612Z" fill="#1F2F42" />
        <path d="M25.3043 9.48736C25.2203 9.62177 25.0864 9.71736 24.9319 9.75317C24.7775 9.78898 24.6152 9.76207 24.4806 9.67836L24.1462 9.47544C23.0622 8.80475 20.3302 7.1139 19.2812 6.4609C19.6253 6.25196 20.0206 6.14246 20.4231 6.14456C20.8256 6.14667 21.2197 6.26029 21.5616 6.47282L25.1073 8.66359C25.2424 8.74698 25.3389 8.88045 25.3758 9.03482C25.4128 9.18919 25.387 9.3519 25.3043 9.48736Z" fill="#E7A273" />
        <path d="M36.6302 14.6907H33.0487C32.5739 14.6902 32.1187 14.5014 31.7829 14.1657C31.4472 13.83 31.2583 13.3748 31.2578 12.9V6.33228C31.2584 5.85788 31.4471 5.40307 31.7825 5.06762C32.118 4.73218 32.5728 4.5435 33.0472 4.54297H36.6302C36.7885 4.54297 36.9403 4.60586 37.0523 4.7178C37.1642 4.82975 37.2271 4.98158 37.2271 5.13989V14.0937C37.2271 14.1721 37.2117 14.2498 37.1817 14.3222C37.1517 14.3946 37.1077 14.4604 37.0523 14.5159C36.9969 14.5713 36.9311 14.6153 36.8586 14.6453C36.7862 14.6753 36.7086 14.6907 36.6302 14.6907Z" fill="#425B72" />
        <path d="M37.227 5.13989V6.33374H31.2578C31.2575 6.09848 31.3036 5.86547 31.3935 5.64805C31.4834 5.43064 31.6153 5.2331 31.7816 5.06675C31.948 4.9004 32.1456 4.7685 32.363 4.67862C32.5804 4.58874 32.8134 4.54265 33.0487 4.54297H36.6302C36.7884 4.54346 36.9399 4.60651 37.0517 4.71835C37.1635 4.83019 37.2266 4.98174 37.227 5.13989Z" fill="#374F68" />
        <path d="M34.8384 11.7066C34.8349 12.4872 33.6478 12.4872 33.6445 11.7066C33.6479 10.9259 34.8351 10.926 34.8384 11.7066Z" fill="#1F2F42" />
        <path d="M23.7361 14.367L23.5152 14.7193L8.03125 5.04326L10.1503 1.51541C10.2358 1.37905 10.3705 1.28075 10.5264 1.2408C10.682 1.20665 10.8448 1.23449 10.9803 1.31841L21.5341 7.8548C18.9464 6.40134 16.8377 10.1272 19.2599 11.6573L23.7361 14.367Z" fill="#84B749" />
        <path d="M13.4618 2.85888C12.8422 4.78418 10.086 5.47111 8.64453 4.01657C9.00261 3.41572 9.78192 2.12226 10.1488 1.51541C10.2343 1.37905 10.3689 1.28075 10.5248 1.2408C10.6805 1.20665 10.8433 1.23449 10.9787 1.31841C10.9028 1.27349 12.8823 2.49272 13.4618 2.85888Z" fill="#678C38" />
        <path d="M18.1253 9.91378C18.145 7.55894 14.9842 6.64763 13.7422 8.60932L17.7801 11.1318C18.0039 10.7648 18.1232 10.3436 18.1253 9.91378Z" fill="#678C38" />
      </g>
      <defs>
        <clipPath id="clip0_17209_73580">
          <rect width="40" height="40" fill="white" transform="translate(0.511719 0.660156)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default CashOnDeliveryIcon;
