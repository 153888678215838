import * as React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';
import OneMarketPointString from './common/OneMarketPointString';
import { rgba } from 'polished';
import { commaSeparatedValue } from '../utils';
import { Help } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: `${theme.palette.secondary.main} !important`,
      padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
      boxSadow: "none",
      borderRadius: `0 !important`,
      marginTop: theme.spacing(3.5),

      '& .pointsTitle': {
        color: rgba(theme.palette.background.paper, 0.75),
        fontWeight: 600,
        fontSize: "18px",
      },

      '& .totalPoints': {
        color: theme.palette.background.paper,
        fontWeight: 300,
        fontSize: "64px",
        '& .MuiSvgIcon-root': {
          color: theme.palette.background.paper,
          verticalAlign: 'center',
        }
      },

      '& .redeemMessage': {
        color: theme.palette.background.paper,
        fontSize: "12px",
        fontWeight: 700,
        padding: `${theme.spacing(0.4)} ${theme.spacing(0.6)}`,
        background: rgba(0, 0, 0, 0.15),
        margin: 0
      }
    },
    row1: {
      display: 'flex',

      '& .details' : {
        display: 'flex',
        alignItems:"center",
        flexDirection: 'column',
        flex: "1 1 auto",
      },
    },
    cover: {
      width: 96,
      height: 96,
    },
  }),
);

export default function OneMarketPointsCard(props: {
  points: number,
  transactionProgressbar?: React.ReactNode
}): React.ReactElement {
  const classes = useStyles()


  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.row1}>
          <div className="details">
            <OneMarketPointString className="pointsTitle" text="Points Balance" />
            <Typography variant="h4" color="textSecondary" className="totalPoints">
              {commaSeparatedValue(props.points)}&nbsp;
              <a target='_blank' rel="noreferrer" href={'/help/1mpoints'}><Help /></a>
            </Typography>

            {/* todo */}
            {/* <p className="redeemMessage">You are close to redeem a fine dinner 👀</p> */}

            {props.transactionProgressbar}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}