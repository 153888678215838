import { Base } from "./Base";
import { DiningTable } from "./DiningTableType";

export enum ReservationStatus {
  Reserved = 1,
  Delayed,
  NoShow,
  Arrived,
  NoShowConfirmation,
  Cancelled,
  Finished
}

export enum Occasion {
  None,
  Birthday,
  Anniversary,
  DateNight,
  BusinessMeal,
  Celebration
}

export enum Channel {
  GoogleMap = 'GoogleMap',
  OneMApp = 'OneMApp',
  Website = 'Website',
  Facebook = 'Facebook',
  Phone = 'Phone',
  WalkIn = 'WalkIn',
}

export interface PartySetting {
  merchantId: string;
  peopleNum: number;
  dinnerTime: number;
  dinnerDate: string;
  specialRequest?: string;
  occasion?: Occasion;
}

export enum OccasionEnum {
  "Birthday" = 1,
  "Anniversary" = 2,
  "Date night" = 3,
  "Business meal" = 4,
  "Celebrations" = 5,
}

export interface ReserveRequest extends PartySetting {
  displayName: string;
  phone: string;
  interCode: string;
  email: string;
}

export interface Reservation extends Base {
  id: string;
  ackNo: string;
  merchantId: string;
  dinnerTime: number;
  dinnerDate: string;
  endDinnerDate: string;
  endDinnerTime: number;
  peopleNum: number;
  displayName: string;
  phone: string;
  interCode: string;
  email: string;
  specialRequest?: string;
  occasion?: Occasion;
  diningTables: DiningTable[]; // 餐桌
  channel: number;
  staffName: string;
  status: ReservationStatus;
  beforeCancelTime: Date;
}