import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, createStyles, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import { Else, If, Then } from 'react-if';
import { rgba } from 'polished';

interface Props {
  onCompleteAction: () => void,
  circular?: boolean,
  autoRedirectDuration?: number
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginBottom: theme.spacing(2),

    "& .circular-progress-container": {
      display: "flex",
      textAlign: "left",
      alignItems: "center",

      "& .circular-progress-text-container": {
        marginRight: theme.spacing(2),

        "& .circular-progress-label": {
          fontSize: theme.spacing(2.5),
          lineHeight: 1.2,
          color: rgba(theme.palette.background.paper, .5),
          fontWeight: 700,

          "& .seconds": {
            fontSize: theme.spacing(3.4),
            color: theme.palette.background.paper,
            fontWeight: 700
          }
        }
      },

      "& .MuiCircularProgress-root": {
        color: "white"
      },

      "& .completedProgress": {
        position: "absolute",
        right: 0,
        color: rgba(theme.palette.background.paper, .5)
      }
    }
  },
}))

const AutoRedirectProgress = ({ onCompleteAction, autoRedirectDuration = 60, circular }: Props): React.ReactElement => {
  const [progress, setProgress] = useState(0);

  const classes = useStyles()

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= autoRedirectDuration ? autoRedirectDuration : prevProgress + 1));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  if (progress >= autoRedirectDuration && onCompleteAction) { onCompleteAction(); }
  return <div className={classes.root}>
    <If condition={circular}>
      <Then>
        <Box className="circular-progress-container">
          <Box className="circular-progress-text-container">
             <Typography className="circular-progress-label" variant="subtitle1" color="textSecondary" component="span">Starting over in<br/>
               <span className="seconds">{autoRedirectDuration - progress}s</span>
            </Typography>
          </Box>
          <CircularProgress variant="determinate" color='secondary' value={progress * (100 / autoRedirectDuration)} />
          <CircularProgress className="completedProgress" variant="determinate" value={100} />
        </Box>
      </Then>
      <Else>
         <Typography variant="subtitle1" color="textSecondary" component="p">Starting over in {autoRedirectDuration - progress}s</Typography>
         <LinearProgress variant="determinate" value={progress * (100 / autoRedirectDuration)} />
      </Else>
    </If>

  </div>
}

export default AutoRedirectProgress
