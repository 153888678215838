import React from "react";
import { SvgIcon } from "@material-ui/core";

function CommentIcon(props: any) {
  return (
    <SvgIcon
      sx={{ width: "20px", height: "21px", color: "inherit" }}
      viewBox="0 0 20 21"
      {...props}>
      <path d="M7 20.375C6.73478 20.375 6.48043 20.2696 6.29289 20.0821C6.10536 19.8946 6 19.6402 6 19.375V16.375H2C1.46957 16.375 0.960859 16.1643 0.585786 15.7892C0.210714 15.4141 0 14.9054 0 14.375V2.375C0 1.265 0.9 0.375 2 0.375H18C18.5304 0.375 19.0391 0.585714 19.4142 0.960786C19.7893 1.33586 20 1.84457 20 2.375V14.375C20 14.9054 19.7893 15.4141 19.4142 15.7892C19.0391 16.1643 18.5304 16.375 18 16.375H11.9L8.2 20.085C8 20.275 7.75 20.375 7.5 20.375H7ZM8 14.375V17.455L11.08 14.375H18V2.375H2V14.375H8ZM4 5.375H16V7.375H4V5.375ZM4 9.375H13V11.375H4V9.375Z"/>
    </SvgIcon>
  );
}

export default CommentIcon;