


import React from 'react'
import { SvgIcon } from "@material-ui/core";

function VietnameseFoodIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <g clipPath="url(#clip0_7792_41691)">
        <path d="M3.605 11.984a.44.44 0 100-.88.44.44 0 000 .88z" />
        <path d="M15.061 12.863h-.91c.277-.368.442-.824.442-1.319a2.2 2.2 0 00-2.198-2.197h-.001a2.2 2.2 0 00-1.757-3.516H6.242a2.2 2.2 0 00-2.198 2.197c0 .495.165.951.441 1.319h-.88a2.2 2.2 0 00-2.198 2.197c0 .495.165.951.441 1.319h-.91a.44.44 0 000 .879h.637l.515 1.03c.225.449.677.728 1.18.728h9.46c.503 0 .955-.28 1.18-.729l.515-1.029h.636a.44.44 0 000-.88zM8 6.71h2.638a1.32 1.32 0 011.319 1.318 1.32 1.32 0 01-1.319 1.319H8h-.001c.276-.368.44-.824.44-1.319 0-.494-.164-.95-.44-1.318zm.88 3.516a1.32 1.32 0 011.319 1.318 1.32 1.32 0 01-1.319 1.319 1.32 1.32 0 01-1.319-1.319 1.32 1.32 0 011.319-1.318zM6.242 6.71A1.32 1.32 0 017.56 8.028a1.32 1.32 0 01-1.318 1.319 1.32 1.32 0 01-1.319-1.319A1.32 1.32 0 016.242 6.71zm.88 6.153h-1.76c.276-.368.44-.824.44-1.319 0-.494-.164-.95-.44-1.318h1.76c-.276.367-.44.824-.44 1.318 0 .495.164.951.44 1.319zm-4.836-1.319a1.32 1.32 0 011.319-1.318 1.32 1.32 0 011.318 1.318 1.32 1.32 0 01-1.318 1.319 1.32 1.32 0 01-1.319-1.319zm10.838 2.834a.437.437 0 01-.393.243H3.269a.437.437 0 01-.393-.243l-.318-.636h10.884l-.318.636zm-.729-1.515h-1.76c.277-.368.441-.824.441-1.319 0-.494-.164-.95-.44-1.318h1.759a1.32 1.32 0 011.319 1.318 1.32 1.32 0 01-1.319 1.319zM6.068 3.093c.417-.279.666-.75.666-1.26S6.485.852 6.068.573a.44.44 0 10-.487.732.621.621 0 01.274.528c0 .22-.1.412-.274.528a1.52 1.52 0 00-.666 1.274c0 .52.249.996.666 1.274a.44.44 0 10.487-.731.644.644 0 01-.274-.543c0-.225.102-.428.274-.542zM8.266 3.093c.417-.279.666-.75.666-1.26s-.25-.981-.666-1.26a.44.44 0 10-.488.732.622.622 0 01.275.528c0 .22-.1.412-.275.528a1.52 1.52 0 00-.666 1.274c0 .52.25.996.666 1.274a.44.44 0 10.488-.731.644.644 0 01-.275-.543c0-.225.103-.428.275-.542zM10.463 3.093c.417-.279.667-.75.667-1.26s-.25-.981-.667-1.26a.44.44 0 10-.487.732.621.621 0 01.274.528c0 .22-.1.412-.274.528a1.52 1.52 0 00-.666 1.274c0 .52.249.996.666 1.274a.44.44 0 10.487-.731.644.644 0 01-.274-.543c0-.225.102-.428.274-.542z" />
        <path d="M6.242 8.468a.44.44 0 100-.88.44.44 0 000 .88zM8.879 11.984a.44.44 0 100-.88.44.44 0 000 .88z" />
      </g>
      <defs>
        <clipPath id="clip0_7792_41691">
          <path fill="#fff" d="M0 0h16v15.998H0z" transform="translate(0 .001)" />
        </clipPath>
      </defs>

    </SvgIcon>
  );
}

export default VietnameseFoodIcon