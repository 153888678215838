import React from 'react';
import { Stack } from '@material-ui/core';

// src
import { StyledGreyChip } from '../Classifieds/styledComponents';

interface MerchantCategoriesListProps {
  categoriesList: string[]
}

const MerchantCategoriesList = ({ categoriesList }: MerchantCategoriesListProps) => {
  return (
    <Stack direction={"row"} sx={{ flexWrap: 'wrap', mt: 1 }}>
      {categoriesList.map((data, index) =>
        <StyledGreyChip sx={{marginRight: 1}} label={data} key={`category-${index}`} variant='filled' size="small" />
      )}
    </Stack>
  );
}

export default MerchantCategoriesList;
