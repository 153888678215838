import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Alert, CardContent, LinearProgress, Typography } from "@material-ui/core";

import { ColoredPaper } from "../bos_common/src/components/Papers";
import { UserContext } from "../bos_common/src/context/UserContext";

import AuthorizeUser from "../components/common/AuthorizeUser";
import PageHeader from "../components/common/PageHeader";

const LoginPage = (): React.ReactElement => {
  const title = "Log in"
  const history = useHistory()
  const location = useLocation<{ from: { pathname: string } }>()
  const from = location.state?.from || { pathname: '/' }
  const { user, authenticating } = useContext(UserContext)

  useEffect(() => {
    if (user) {
      setTimeout(() => history.replace({ pathname: from.pathname, hash: 'openDrawer' }), 1000)
    }
  }, [user]);

  return (
    <div className="container">
      <PageHeader title={title} />
      <ColoredPaper>
        {authenticating &&
          <div>
            <LinearProgress />
            <CardContent>
              <Typography gutterBottom variant="subtitle1" component="div">
                Logging in
              </Typography>
            </CardContent>
          </div>
        }
        {!user && !authenticating && (
          <CardContent>
            <Typography gutterBottom variant="subtitle1" component="div" className={"signUpPrompt"}>
              Wait less, accumulate points and favorite shops, sign up/login with your phone number
            </Typography>
            <AuthorizeUser redirectTo={from.pathname} />
          </CardContent>
        )}
        {user && (
          <CardContent>
            <Alert severity='success'>You have logged in, redirecting...</Alert>
          </CardContent>
        )}
      </ColoredPaper>
    </div>
  );
};

export default LoginPage;
