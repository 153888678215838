import React from 'react'
import { SvgIcon } from "@material-ui/core";

function FishIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M10.375 2.813h-8.5a.939.939 0 010-1.876h7.063a.469.469 0 000-.937H1.875A1.877 1.877 0 000 1.875C0 2.909.841 3.75 1.875 3.75h.96c.029.32.088.633.176.938H1.406a.469.469 0 000 .937h1.98a5.25 5.25 0 004.645 2.813h1.954c.732 0 1.328.595 1.328 1.326 0 .806-.289 1.003-2.915 2.554-.302.178-.632.373-.991.587a2.347 2.347 0 00-2.22-1.592H4.25a.469.469 0 00-.469.468c0 .766.37 1.447.94 1.875-.57.428-.94 1.11-.94 1.875 0 .26.21.469.469.469h.938a2.348 2.348 0 002.296-1.875h.137c2.41 0 5.271-.103 6.979-1.809.929-.927 1.4-2.232 1.4-3.879a5.632 5.632 0 00-5.625-5.624zm-6.598.937H8.5c1.034 0 1.875.841 1.875 1.875A1.877 1.877 0 018.5 7.5h-.469c-2.2 0-4.017-1.644-4.254-3.75zm6.755 3.817a2.803 2.803 0 00.067-3.812 4.697 4.697 0 014.185 3.09A2.802 2.802 0 0111.791 8.4a2.27 2.27 0 00-1.259-.833zm.613 4.147c.295.386.662.71 1.077.955-1.056.363-2.281.473-3.431.505l.084-.049c1.048-.619 1.77-1.045 2.27-1.411zm-6.346.536h.388c.612 0 1.133.392 1.326.938h-.388a1.409 1.409 0 01-1.326-.938zm.388 2.813H4.8a1.41 1.41 0 011.326-.939h.388a1.41 1.41 0 01-1.325.938zm8.75-3.41a3.72 3.72 0 01-.602.484 2.817 2.817 0 01-1.495-1.06c.29-.368.41-.767.41-1.313 0-.133-.012-.263-.034-.39a3.736 3.736 0 002.836-1.253c.007.105.01.21.01.316 0 1.408-.368 2.46-1.125 3.216z" />
      <path d="M7.063 5.625a.469.469 0 100-.938.469.469 0 000 .938z" />
    </SvgIcon>
  );
}

export default FishIcon