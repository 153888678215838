import React, { useEffect, useContext } from "react";
import useAxios from "axios-hooks";
import {
  CardContent,
  Theme,
  Grid,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { pluck, isEmpty, without, reject } from "ramda";
import { If, Then } from "react-if";

import { FullscreenPaper } from "bos_common/src/components/Papers";
import MerchandiseListing from "bos_common/src/components/Merchandise/MerchandiseListing";
import { groupMerchandiseDataByCategories } from "bos_common/src/services/merchandiseUtils";
import { PromotionCriteriaType } from "bos_common/src/types/MerchantPromotionType";
import { useFetchApiOnInterval } from "bos_common/src/hooks";
import SimpleLoader from "bos_common/src/components/SimpleLoader";

import {
  Merchandise,
  Merchant,
  MerchandiseApiResponseType,
  MerchantCoupon,
} from "../../services/models";
import { MERCHANT_DATA_FETCH_INTERVAL } from "../../config/constants";
import { VisitedMerchantContext } from "../../context/VisitedMerchantContext/VisitedMerchantContext";
import { isEmptyOrNil, isFollowMerchantEnabled, scrollToTop } from "../../utils";
import CountBox from "../common/CountBox";
import PromotionButton from "../Promotions/PromotionButton";
import NotFoundImage from "../../assets/images/warning-error.svg";
import FollowMerchantCard from "../StoreFront/FollowMerchantCard";
import MerchandiseList from "./MerchandiseList";
import FeaturedDeals from "./FeaturedDeals";
import ComboDeals from "./ComboDeals";
import useStyles from "./styles";
import { AppContext } from "../../context/AppContext";


const NoMatchFound = () => (
  <div className="noMatchFound">
    <img src={NotFoundImage} />
    <Typography className="noMatchText">No matching item found</Typography>
  </div>
)

const searchMerchandises = (searchStr: string, merchandises: Merchandise[]) => {
  try {
    const search = searchStr.toLowerCase();
    const tagToSearch = search.toLowerCase().replaceAll(' ', '_')

    const searchMerchandiseName = (m: Merchandise) => m.name.toLowerCase().includes(search);
    const searchMerchandiseDescription = (m: Merchandise) => m.description.toLowerCase().includes(search);
    const searchMerchandiseTags = (m: Merchandise) => (m.ingredientTags &&
      Object.keys(m.ingredientTags).join(" ").includes(tagToSearch)
    );

    return merchandises.filter((m: Merchandise) =>
      searchMerchandiseName(m) ||
      searchMerchandiseDescription(m) ||
      searchMerchandiseTags(m)
    );
  } catch (error) {
    console.log('unable to search merchandises', error)
    return []
  }
}

type MerchantMenuProps = {
  merchant: Merchant,
  search?: string,
  coupon?: MerchantCoupon,
};
const MerchantMenu = (props: MerchantMenuProps): React.ReactElement => {
  const { merchant, search, coupon } = props;
  const { setVisitedMenu } = useContext(VisitedMerchantContext);
  const { orderType } = useContext(AppContext);

  const [{ data: merchandiseData, loading }, fetchMerchandises] = useAxios<MerchandiseApiResponseType>(
    {
      url: "/merchandises",
      params: { merchantId: merchant.id },
    },
    { useCache: false }
  );

  useFetchApiOnInterval(fetchMerchandises, MERCHANT_DATA_FETCH_INTERVAL, true);

  const { mercCategories = [], merchandises = [] } = merchandiseData || {};

  useEffect(() => {
    merchandiseData && setVisitedMenu(merchandiseData);
  }, [merchandiseData])

  useEffect(() => {
    scrollToTop();
  }, [])

  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const filteredMerchandises = search ? searchMerchandises(search, merchandises) : merchandises;
  const isSearchResultEmpty = !isEmptyOrNil(search) && !isEmptyOrNil(merchandises) && isEmptyOrNil(filteredMerchandises);

  const comboPromotions = merchant.promotions?.filter((item) => item.criteriaType === PromotionCriteriaType.COMBO_TYPE) || [];
  const comboCoupons = comboPromotions.reduce((agg: MerchantCoupon[], p) => p.coupons ? [...agg, ...p.coupons] : agg, []);
  const otherPromotions = merchant.promotions?.filter((item) =>
    item.criteriaType !== PromotionCriteriaType.COMBO_TYPE && item.criteriaType !== PromotionCriteriaType.REFERRAL_TYPE
  ) || [];
  const otherCoupons = otherPromotions.reduce((agg: MerchantCoupon[], p) => p.coupons ? [...agg, ...p.coupons] : agg, []);
  const otherCoupon = otherCoupons.length > 0 ? otherCoupons[0] : undefined;

  const groupedMerchandises = groupMerchandiseDataByCategories({
    categories: mercCategories,
    merchandises: filteredMerchandises,
    merchant,
    orderType
  });

  const categories = without([null], pluck('category', groupedMerchandises));

  return (
    <FullscreenPaper className={classes.merchantMenuContainer}>
      <SimpleLoader loading={loading} />
      <MerchandiseListing
        categories={categories}
        menuItemsAvailable={!isEmpty(merchandises)}
        loading={loading}
      >
        <>
          <If condition={isMobile}>
            <Then>
              <PromotionButton merchant={merchant} merchandises={merchandises} coupon={coupon} />
              {/* <FollowMerchantCard merchant={merchant} */}
            </Then>
          </If>
          <FeaturedDeals
            merchant={merchant}
            merchandises={filteredMerchandises}
            modifiers={merchandiseData?.modifiers}
            coupon={otherCoupon}
          />
          <ComboDeals
            merchant={merchant}
            merchandises={filteredMerchandises}
            coupons={comboCoupons}
          />
          {groupedMerchandises?.map(
            ({ category, merchandises }, idx: number) => (
              <CardContent
                className={classes.root}
                key={idx}
                id={`category-${category ? category.id : "0"}`}
              >
                {category && (
                  <div className="category-info">
                    {
                      <Typography variant="body1" component="div" display='flex' alignItems='center'>
                        {category.name}&nbsp;&nbsp;
                        <CountBox count={merchandises.length} />
                      </Typography>
                    }
                    {category.description && (
                      <Typography
                        variant="subtitle2"
                        component="p"
                        color="textSecondary"
                      >
                        {category.description}
                      </Typography>
                    )}
                  </div>
                )}

                <Grid container spacing={2}>
                  <MerchandiseList
                    merchandises={merchandises}
                    modifiers={merchandiseData?.modifiers}
                    merchant={merchant}
                    coupon={coupon}
                  />
                </Grid>
              </CardContent>
            )
          )}
        </>
      </MerchandiseListing>

      <If condition={isSearchResultEmpty}>
        <NoMatchFound />
      </If>
    </FullscreenPaper>
  );
};

export default MerchantMenu;
