import React from 'react'
import { SvgIcon } from "@material-ui/core";

function SaladIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M7.037 12.032c2.365.4 3.74-.479 4.02-1.463a1.274 1.274 0 00-.082-.946c1.274-.507 2.261-1.566 2.215-2.5-.013-.27-.138-.822-.885-1.137.598-.612.783-1.21.82-1.647.058-.69-.209-1.355-.737-1.86-.519-.542-1.184-.81-1.874-.75-.438.036-1.035.22-1.648.818C8.552 1.8 8 1.675 7.73 1.662c-.939-.052-1.992.941-2.499 2.215a1.274 1.274 0 00-.946-.082c-.985.28-1.863 1.657-1.464 4.02.396 2.335-1.62 4.018-1.654 4.046a.448.448 0 00.564.697c.1-.082 2.46-2.025 1.974-4.892-.326-1.929.333-2.869.824-3.009.199-.056.36.014.463.196.042.367.388.403.404.405a.458.458 0 00.493-.43.435.435 0 00.005-.065c.269-1.244 1.239-2.207 1.771-2.207a.58.58 0 01.021 0c.375.02.477.715.503 1.014a.447.447 0 00.817.21c.481-.711 1.03-1.113 1.584-1.16.414-.035.823.135 1.165.492.343.328.513.736.478 1.15-.047.556-.449 1.104-1.161 1.585a.448.448 0 00.212.817c.298.027.994.128 1.013.503.026.528-.947 1.521-2.208 1.793a.42.42 0 00-.06.004.457.457 0 00-.433.496c.002.015.039.36.405.402.182.102.252.264.195.462-.139.492-1.08 1.15-3.009.824-2.867-.484-4.81 1.875-4.891 1.976a.447.447 0 00.696.563c.017-.02 1.702-2.051 4.046-1.655z" />
      <path d="M4.893 9.486a.448.448 0 00.492.749 9.807 9.807 0 001.332-1.048A12.244 12.244 0 008.248 7.43c.359-.014.86.099 1.201.283a.447.447 0 10.424-.79 3.556 3.556 0 00-1.038-.343c.564-.809 1.092-1.28 1.734-1.56a.448.448 0 10-.356-.821C9.12 4.67 8.43 5.56 7.953 6.282a14.73 14.73 0 01-1.056 1.422c-.093-.46-.296-.871-.543-1.124a.448.448 0 10-.64.627c.23.235.441.885.29 1.421-.35.317-.716.599-1.11.858zM13.608 5.465a.447.447 0 10-.438.782c.68.381.992.856.926 1.411-.098.818-.997 1.713-2.004 1.995a.447.447 0 00-.23.71c.262.33.336.692.22 1.072-.182.593-.786 1.116-1.575 1.366-1.153.365-2.11.219-3.034.078-1.172-.18-2.385-.364-3.673.663a.448.448 0 10.559.7c.982-.782 1.863-.648 2.979-.478.977.149 2.087.318 3.44-.11 1.065-.336 1.893-1.086 2.16-1.956a1.988 1.988 0 00-.066-1.379c1.12-.505 1.991-1.529 2.114-2.555.063-.534-.03-1.544-1.378-2.299z" />
    </SvgIcon>
  );
}

export default SaladIcon