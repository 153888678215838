import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Else, If, Then } from 'react-if';
import {
  Box, Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, List,
  ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Stack, Theme, Typography
} from '@material-ui/core';
import { CheckCircle, Phone, Search, ArrowBack, Info } from '@material-ui/icons';
import useAxios from 'axios-hooks';

import SimpleLoader from 'bos_common/src/components/SimpleLoader';
import InputSearchBox from 'bos_common/src/components/InputSearchBox';
import CalendarEvent from 'bos_common/src/types/crm/CalendarEventType';
import CustomerScheduleEntry, { AttendeeStatus } from 'bos_common/src/types/crm/CustomerScheduleEntryType';
import { Merchant } from 'bos_common/src/types/MerchantType';
import { getAPIErrorMessage } from 'bos_common/src';

import SideLayoutWithIllustration from './common/SideLayoutWithIllustration';
import { KIOSK_CLASS_CHECKIN_DELAY_INTERVAL } from '../config/constants';
import { isEmptyOrNil, getCalendarEventStartEndDates } from '../utils';
import CheckInIllustration from '../assets/images/kiosk-class-check-in.svg'
import MerchantAvatar from './MerchantAvatar';


const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '& .attendees-section': {
      padding: theme.spacing(5),
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
      height: 'inherit',

      '& .attendees-list': {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.spacing(1),
        color: '#000'
      }
    }
  },

  dialog: {
    '& .MuiPaper-root': {
      width: '100%',
    },

    '& .MuiDialogContent-root.dialog-success-section': {
      padding: theme.spacing(5),
      alignItems: 'center',
      justifyContent: 'center'
    },

    '& .dialog-actions': {
      justifyContent: 'center',
      gridGap: 16,
      padding: theme.spacing(2, 0),

      '& .MuiButton-root': {
        minWidth: 150
      }
    }
  }
}))

type AttendeesListProps = {
  attendeesList: CustomerScheduleEntry[]
  onCheckInClick: (_: CustomerScheduleEntry) => void;
}

const AttendeesList = (props: AttendeesListProps) => {
  const { attendeesList, onCheckInClick } = props;

  if (isEmptyOrNil(attendeesList)) return null;

  return (
    <List className="attendees-list">
      {attendeesList.map(attendee => (
        <ListItem key={attendee.id}>
          <ListItemText>{attendee.customer?.displayName ?? ""} ({attendee.customer?.phoneNumber ?? ""})</ListItemText>
          <ListItemSecondaryAction>
            <If condition={attendee.status === AttendeeStatus.PRESENT}>
              <Then>
                <Fab
                  disabled
                  variant="extended"
                  size="medium"
                  color="primary">
                  Checked-in
                </Fab>
              </Then>
              <Else>
                <Fab
                  variant="extended"
                  color="primary"
                  size="medium"
                  onClick={() => onCheckInClick(attendee)}>
                  Check-in
                </Fab>
              </Else>
            </If>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>

  )
}

interface CustomerAttendanceDialogProps {
  isOpen: boolean,
  handleCancelClick: () => void,
  attendee?: CustomerScheduleEntry,
  onSuccess: (_: CustomerScheduleEntry) => void
}

const CustomerAttendanceDialog = ({ isOpen, handleCancelClick, attendee, onSuccess }: CustomerAttendanceDialogProps) => {
  if (!isOpen || !attendee) return null;

  const classes = useStyles();

  const [{ data: result, loading: processing, error }, markAttendance] = useAxios<CustomerScheduleEntry>({
    url: `customers/${attendee?.customerId}/events/${attendee.eventId}/presence`,
    method: "POST"
  }, { manual: true })

  const handleConfirmClick = async () => {
    await markAttendance().then(({ data }) => {
      // hide dialog after 3 seconds of response
      setTimeout(() => {
        onSuccess(data)
        handleCancelClick()
      }, 3000)
    })
  }

  return (
    <Dialog
      open={isOpen}
      onClose={isEmptyOrNil(result) && handleCancelClick}
      maxWidth="sm"
      fullWidth
      sx={{ margin: 0, maxHeight: "unset" }}
      className={classes.dialog}
    >
      <If condition={isEmptyOrNil(result) && isEmptyOrNil(error)}>
        <Then>
          <SimpleLoader loading={processing} />

          <DialogContent sx={{ py: 3 }}>
            <Typography variant="subtitle1" align='center' color="textSecondary" gutterBottom>
              You are checking in:
            </Typography>
            <Typography variant="h5" align='center' fontWeight="bold" gutterBottom>{attendee.customer?.displayName ?? ''}</Typography>
            <Typography variant="h5" align='center' fontWeight="bold" gutterBottom>
              <Phone sx={{ mr: 0.5 }} />{attendee.customer?.phoneNumber ?? ''}
            </Typography>
            <Typography variant="subtitle1" align='center'>Is this correct?</Typography>
          </DialogContent>

          <DialogActions className="dialog-actions">
            <Button variant="text" color="primary" onClick={handleCancelClick} disabled={processing}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleConfirmClick} disabled={processing}>
              Confirm
            </Button>
          </DialogActions>
        </Then>
        <Else>
          <DialogContent className='dialog-success-section'>
            <Typography variant="body1" fontWeight="bold" align="center">
              <If condition={isEmptyOrNil(error)}>
                <Then>
                  <Typography variant="h1" align="center">
                    <CheckCircle color='primary' fontSize='inherit' />
                  </Typography>

                  Your have checked in successfully
                </Then>
                <Else>
                  <Typography variant="h1" align="center">
                    <Info color='primary' fontSize='inherit' />
                  </Typography>
                  
                  {getAPIErrorMessage(error)}
                </Else>
              </If>
            </Typography>
          </DialogContent>
        </Else>
      </If>
    </Dialog>
  )
}

interface KioskClassCheckInDialogProps {
  merchant?: Merchant,
  isOpen: boolean,
  handleClose: () => void,
  calendarEvent?: CalendarEvent
}

const KioskClassCheckInDialog = ({ merchant, handleClose, isOpen, calendarEvent }: KioskClassCheckInDialogProps): JSX.Element | null => {
  if (!calendarEvent) return null;

  const classes = useStyles()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [selectedItem, setSelectedItem] = useState<CustomerScheduleEntry>()
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Go back to main page after 5 minutes
    if (isOpen) {
      intervalRef.current = setTimeout(handleClose, (KIOSK_CLASS_CHECKIN_DELAY_INTERVAL))
    } else if (intervalRef.current) {
      clearTimeout(intervalRef.current);
      intervalRef.current = null;
    }
  }, [isOpen])

  const attendeesList = useMemo(() => {
    if (!calendarEvent || isEmptyOrNil(searchQuery)) return [];

    return calendarEvent.customerEntries.filter(i => {
      const customerName = (i.customer?.displayName ?? "").toLocaleLowerCase();
      return customerName.includes(searchQuery.toLocaleLowerCase()) || (i.customer?.phoneNumber ?? "").includes(searchQuery)
    })
  }, [calendarEvent, searchQuery])

  const getEventDates = useCallback(
    (event: CalendarEvent) => {
      const { startDate, endDate } = getCalendarEventStartEndDates(event)
      return `${startDate} - ${endDate}`
    },
    [calendarEvent],
  )

  const handleSearchChange = (s: string) => {
    setSearchQuery(s)
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
      intervalRef.current = null;
    }
    intervalRef.current = setTimeout(handleClose, (KIOSK_CLASS_CHECKIN_DELAY_INTERVAL))
  }

  const onCheckInClick = (attendee: CustomerScheduleEntry) => {
    setSelectedItem(attendee)
  }

  const onAttendanceUpdate = () => {
    handleClose();
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
      intervalRef.current = null;
    }
  }

  return (
    <Dialog
      open={isOpen}
      fullScreen
    >
      <SideLayoutWithIllustration
        className={classes.root}
        illustrationPath={CheckInIllustration}
        leftSideContent={
          <Box sx={{ p: 4 }}>
            {merchant &&
              <Stack display="flex" direction="row" justifyContent={"space-between"} alignItems="center" sx={{ mb: 4 }}>
                <MerchantAvatar merchant={merchant} />
                <Fab variant='circular' onClick={handleClose}><ArrowBack fontSize="large" /> </Fab>
              </Stack>
            }
            <Box>
              <Typography variant="h3" fontWeight="bolder" gutterBottom whiteSpace="pre-wrap">{calendarEvent?.title}</Typography>
              <Typography variant="h6">{calendarEvent?.staff?.user?.displayName ?? ''}</Typography>
              <Typography variant="h6">{getEventDates(calendarEvent)}</Typography>
            </Box>
          </Box>
        }
      >
        <If condition={isEmptyOrNil(calendarEvent)}>
          <Then>
            <Typography variant="h6" sx={{ p: 3 }}>Class does not exist</Typography>
          </Then>
          <Else>
            <Box className="attendees-section">
              <Typography variant="h5" fontWeight="bold">Search your name or phone number (last 3 digits)</Typography>

              <Box sx={{ my: 3 }}>
                <InputSearchBox
                  onChangeQuery={handleSearchChange}
                  leftChild={<Search />}
                  placeholder="Search attendees..."
                  minLength={2}
                />
              </Box>

              <If condition={!isEmptyOrNil(searchQuery) && isEmptyOrNil(attendeesList)}>
                <Then>
                  <Typography>Unable to find attendee</Typography>
                </Then>
                <Else>
                  <AttendeesList attendeesList={attendeesList} onCheckInClick={onCheckInClick} />
                </Else>
              </If>
            </Box>

            <CustomerAttendanceDialog
              onSuccess={onAttendanceUpdate}
              isOpen={!isEmptyOrNil(selectedItem)}
              handleCancelClick={() => setSelectedItem(undefined)}
              attendee={selectedItem}
            />
          </Else>
        </If>
      </SideLayoutWithIllustration>
    </Dialog>
  )
}

export default KioskClassCheckInDialog;