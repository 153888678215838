import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { MerchantPromotion } from '../../bos_common/src/types/MerchantPromotionType';
import { CardHighlightChip, EllipsizedText } from '../Classifieds/styledComponents';
import { getPromotionChipStyleConfig } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .labelContainer": {
        display: 'grid',
        gridTemplateColumns: '15px 1fr',
        gridColumnGap: '10px',

        alignItems: 'center',
        justifyContent: 'center'
      }
    }
  })
);


const MerchantPromotionChip = (props: { promotion: MerchantPromotion }) => {
  const { promotion } = props;
  const classes = useStyles();

  const { icon: PromotionIcon, backgroundColor } = getPromotionChipStyleConfig(promotion.valueType)

  return (
    <CardHighlightChip
      className={classes.root}
      label={(
        <div className='labelContainer'>
          <PromotionIcon sx={{ color: "white", width: 16, height: 16 }} /><EllipsizedText>{promotion.title}</EllipsizedText>
        </div>
      )}
      color="secondary"
      size='small'
      variant='filled'
      sx={{ backgroundColor }}
    />
  )
}

export default MerchantPromotionChip;