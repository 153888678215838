import React, { ChangeEvent, useCallback, useState } from "react";
import {
  Box,
  createStyles,
  Dialog,
  FormHelperText,
  OutlinedInput,
  Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { If, Then } from "react-if";

import { LOCALSTORAGE_APP_KEYS } from "../../bos_common/src/constants";
import { isEmailValid, isEmptyOrNil } from "../../utils";
import AuthorizeUser from "../common/AuthorizeUser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiPaper-root": {
        borderRadius: "24px",
        maxWidth: 700,
        width: "100%",
        gridGap: theme.spacing(2),
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        overflow: "unset",

        "& .dialogImage": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: `${theme.spacing(3)} 0 0 ${theme.spacing(3)}`,
        },

        "& .dialogContent": {
          padding: theme.spacing(4),

          "& .heading": {
            color: "#364245",
            fontWeight: 600,
            fontSize: 24,
            textAlign: "center",
            margin: 0,
            marginBottom: theme.spacing(2),
          },

          "& .description": {
            color: theme.palette.secondary.light,
            fontSize: "16px",
            lineHeight: 1.3,
            margin: '0 auto',
            marginBottom: theme.spacing(3),
            textAlign: "center",

            [theme.breakpoints.down('sm')]: {
              maxWidth: "300px",
              width: "100%",
              textAlign: "left",
            }
          },

          "& .confirmButton": {
            width: "100%",
            textTransform: 'unset',
            marginTop: theme.spacing(2),
          },

          "& .signUpActionRow": {
            width: "unset",
          }
        },

        "& input[type=number]::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        "& input[type=number]::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },

      },
    },
  })
);

type NewUserPromoDialogProps = {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  title: string;
  description: string;
  promotionImage?: string;
  children?: React.ReactElement;
  redirectTo?: string;
  phoneFieldOnly?: boolean;
};

const NewUserPromoDialog = (props: NewUserPromoDialogProps) => {
  const {
    isOpen,
    setOpen,
    title,
    description,
    redirectTo = '',
    children,
    phoneFieldOnly,
  } = props;

  const classes = useStyles();

  const [email, setEmail] = useState('')
  const [zipcode, setZipcode] = useState('')
  const [errors, setErrors] = useState({ email: '', zipcode: '' });

  const validate = useCallback(() => {
    const errors = {
      email: '',
      zipcode: ''
    }

    if (!email) errors.email = 'email is required'
    else if (!isEmailValid(email)) errors.email = 'Invalid email'

    setErrors(errors)

    return !isEmptyOrNil(errors.email)
  }, [email, zipcode])

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value.trim();
    setEmail(email)
    sessionStorage.setItem(LOCALSTORAGE_APP_KEYS.USER_EMAIL, email)
  }

  const handleZipcodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const zipcode = e.target.value.trim();
    setZipcode(zipcode)
    sessionStorage.setItem(LOCALSTORAGE_APP_KEYS.USER_ADDRESS, zipcode)
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog className={classes.root} onClose={handleClose} open={isOpen}>
      <img className="dialogImage" src={'/discount-dollars-visual.svg'} />

      <div className="dialogContent">
        <div>
          <h3 className="heading">{title}</h3>
          <p className="description">{description}</p>
          {children && children}
        </div>

        <If condition={!phoneFieldOnly}>
          <Then>
            <Box sx={{ mb: '10px' }}>
              <OutlinedInput
                id={'zipcode'}
                value={zipcode}
                size="small"
                onChange={handleZipcodeChange}
                placeholder='zip code (optional)'
                fullWidth
                type="number"
                error={!isEmptyOrNil(errors.zipcode)}
              />
              {errors.zipcode && <FormHelperText sx={{ color: 'red' }}>{errors.zipcode}</FormHelperText>}
            </Box>

            <Box sx={{ mb: '10px' }}>
              <OutlinedInput
                id={'email'}
                value={email}
                size="small"
                onBlur={validate}
                onChange={handleEmailChange}
                placeholder='email (required)'
                fullWidth
                required
                error={!isEmptyOrNil(errors.email)}
              />
              {errors.email && <FormHelperText sx={{ color: 'red' }}>{errors.email}</FormHelperText>}
            </Box>
          </Then>
        </If>

        <Box>
          <AuthorizeUser
            redirectTo={redirectTo}
            showActionBtn
            isDisabled={!phoneFieldOnly && (!email || !isEmptyOrNil(errors.email))}
            actionBtnText='Sign Up'
            useErrorAlert={false}
          />
        </Box>
      </div>
    </Dialog>
  );
};

export default NewUserPromoDialog;
