import { styled, Theme } from "@material-ui/core";

export const headerCustomStyle = (theme: Theme, backgroundColor: string) => {
  return {
    "& header": {
      paddingBottom: theme.spacing(4),
      background: backgroundColor,
      zIndex: 1,
      boxShadow: "unset",

      "& .toolbar": {
        "& .title": {
          textAlign: "left",
          color: "#fff",
        },

        "& .left": {
          "& svg": {
            fill: "#fff",
          },
        },

        "& .right": {
          "& svg": {
            fill: "#fff",
          },
        },
      },
    },
  };
};

export const RewardsContentStyled = styled('div')(({theme}) => ({
  display: "grid",

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: "1fr",
  },

  '&.multiColumn': {
    gridTemplateColumns: "0.9fr 1fr",

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: "1fr",
    },
  },

  "& .dialogContent": {
    padding: `${theme.spacing(5)} ${theme.spacing(5)}`,
    position: "relative",

    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
    },

    "& .title": {
      color: theme.palette.secondary.light,
      fontWeight: "bold",
      fontSize: "18px",
      margin: 0,
      marginBottom: theme.spacing(0.5),
    },

    "& .illustration": {
      [theme.breakpoints.between("sm", "md")]: {
        display: 'none',
      }
    },

    "& .subHeading": {
      fontSize: 18,
      fontWeight: 700,
      color: theme.palette.info.light,
      margin: 0,
      marginBottom: theme.spacing(2),
    },

    "& .heading": {
        fontSize: 32,
        fontWeight: 700,
        color: theme.palette.info.light,
        margin: 0,
        marginBottom: theme.spacing(4),

        '& span': {
          color: theme.palette.primary.main,
          '&.green': {
            color: '#22AC38'
          },
          '&.orange': {
            color: theme.palette.secondary.main,
          }
        },

      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
        marginBottom: theme.spacing(2),
      }
    },

    '& .description': {
      fontSize: 14,
      fontWeight: 500,
      color: '#939393',
      margin: 0,
      marginBottom: theme.spacing(3),
    },

    '& .contentHeading': {
      color: theme.palette.info.light,
      fontSize: 18,
      fontWeight: 700,
      margin: 0,
      marginBottom: theme.spacing(3),

      '& span': {
        color: theme.palette.secondary.main,
      }
    },

    '& .featuresWrapper': {
      display: "grid",
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.spacing(2),

      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr'
      }
    },
  },

  "& .dialogImage": {
    width: "100%",
    height: "auto",
    position: 'sticky',
    top: 0,
    objectFit: 'cover',

    [theme.breakpoints.down('md')]: {
      height: 250,
      position: 'static',
      objectPosition: '0 30%',
    }
  },
}))