import React from 'react'
import { SvgIcon } from "@material-ui/core";

function ExclusiveTickIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 18, height: 18 }} viewBox="0 0 14 14" fill="none" {...props} >
        <path d="M6.285 11.04H5.7525L4.5 9.75003H2.6475L2.25 9.37503V7.56003L0.982498 6.27003V5.73753L2.25 4.44753V2.62503L2.6475 2.25003H4.5L5.7525 0.967529H6.285L7.575 2.25003H9.3975L9.7725 2.61753V4.44753L11.055 5.73753V6.27003L9.75 7.56003V9.37503L9.375 9.75003H7.575L6.285 11.04V11.04ZM5.0475 7.86003H5.58L8.4075 5.03253L7.875 4.50003L5.3175 7.06503L4.2825 6.03003L3.75 6.56253L5.0475 7.86003Z" fill="white"/>
    </SvgIcon>
  );
}

export default ExclusiveTickIcon

