import React from 'react'
import { SvgIcon } from "@material-ui/core";

function LaddooIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M15.531 11.313h-.68c.499-.532.805-1.247.805-2.032V8.97a.469.469 0 00-.469-.469h-.312c-.485 0-.944.117-1.349.325a27.987 27.987 0 01-.042-.172c-.133-.55-.285-1.174-.733-1.623-.448-.448-1.072-.599-1.622-.732-.278-.067-.54-.13-.765-.223-.211-.088-.432-.225-.666-.37-.486-.3-1.037-.643-1.698-.643s-1.212.342-1.699.644c-.233.144-.454.281-.665.369-.225.093-.487.156-.765.223-.55.133-1.173.284-1.622.732-.448.449-.599 1.072-.732 1.622-.067.278-.13.54-.224.765-.05.12-.114.24-.178.35a2.35 2.35 0 00-1.1 1.546H.47a.469.469 0 000 .937h.287l.787 2.361A2.029 2.029 0 003.47 16h9.06c.876 0 1.65-.558 1.927-1.389l.787-2.361h.287a.469.469 0 000-.938zM3.43 8.873c.113-.472.221-.918.483-1.18.262-.262.708-.37 1.18-.483.3-.073.61-.148.903-.27.282-.116.545-.28.8-.438C7.212 6.244 7.605 6 8 6c.394 0 .788.244 1.205.502.255.159.519.322.8.439.293.121.603.196.904.269.471.114.917.221 1.179.483.262.263.37.708.485 1.18.044.184.09.371.146.557a2.987 2.987 0 00-.344.44 2.97 2.97 0 00-2.5-1.37h-.313a.469.469 0 00-.468.469v.312c0 .785.306 1.5.806 2.031H5.61a2.348 2.348 0 00-2.298-1.874H3.28c.057-.188.103-.379.149-.565zm8.47 2.433a2.035 2.035 0 01-1.861-1.862c.99.079 1.783.871 1.862 1.862zm-8.586-.931c.61 0 1.132.392 1.325.938H1.987a1.408 1.408 0 011.325-.938zm10.255 3.94c-.15.447-.566.748-1.038.748H3.47c-.472 0-.889-.301-1.038-.748l-.688-2.065h12.512l-.688 2.065zm-.718-3.009a2.035 2.035 0 011.862-1.862 2.035 2.035 0 01-1.862 1.862zM8 3.438c.259 0 .469-.21.469-.47v-2.5a.469.469 0 00-.938 0v2.5c0 .26.21.47.469.47zM9.875 3.438c.259 0 .469-.21.469-.47v-2.5a.469.469 0 00-.938 0v2.5c0 .26.21.47.469.47zM6.125 3.438c.259 0 .469-.21.469-.47v-2.5a.469.469 0 00-.938 0v2.5c0 .26.21.47.469.47z" />
    </SvgIcon>
  );
}

export default LaddooIcon