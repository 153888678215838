import React, { ReactNode, useContext, useState } from "react";
import useAxios from "axios-hooks";
import { If, Then, Else } from "react-if";
import { useHistory, useLocation } from "react-router";
import { ContactMail, ExitToApp, Info, People } from "@material-ui/icons";
import {
  Box,
  Chip,
  createStyles,
  Divider,
  makeStyles,
  Stack,
  Theme,
} from "@material-ui/core";
import {
  Chat,
  Channel,
  MessageInput,
  Window,
  LoadingIndicator,
  MessageList,
} from "stream-chat-react";

import { urlToCampaign } from 'bos_common/src/services/urls';
import { ChatGroup } from "bos_common/src/types/ChatGroupType";
import SimpleLoader from "bos_common/src/components/SimpleLoader";
import ConfirmationAlert from "bos_common/src/components/ConfirmationAlert";
import ChatSettings from "bos_common/src/components/PlatformChat/ChatSettings";
import FloatingActionsMenu from "bos_common/src/components/FloatingActionsMenu";
import {
  MessageListWrapper,
  RenderBookServiceButton,
  RenderMessage,
} from "bos_common/src/components/PlatformChat/templates";
import { ChatMessageInputStyle } from "bos_common/src/components/PlatformChat/style";
import ChatErrorMessage from "bos_common/src/components/PlatformChat/ChatErrorMessage";
import { PlatformChatContext } from "bos_common/src/components/PlatformChat/context/PlatformChatContext";

import { Merchant } from "../services/models";
import { useAppSelector } from "../redux/hooks";
import { getCurrentMerchant } from "../redux/slice/merchant/merchantSelector";

import ChatBannerImg from "../assets/images/chat-banner.svg";
import DownloadAppDrawer from "./common/DownloadAppDrawer";
import MerchantInfoDrawer from "./MerchantInfo/MerchantInfoDrawer";
import MerchantHeader from "./MerchantInfo/MerchantHeader";
import eventTrackingService from "../services/Tracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../services/Tracking/events";

import { getMobileOS, handleBookingRedirect } from "../utils";
import StoriesCarousel from "./StoriesCarousel";

import "stream-chat-react/dist/css/index.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...ChatMessageInputStyle(theme),

      "& .str-chat__message--me": {
        "& .str-chat__avatar": {
          "& .str-chat__avatar-fallback": {
            background: theme.palette.primary.main,
          },
        },
      },

      "& .str-chat-channel": {
        height: "calc(100vh - 170px)",
        overflow: "auto",
        "& .str-chat__main-panel": {
          position: "relative",
          "& .story-carousel": {
            position: "absolute",
            left: "0",
            top: "0",
            zIndex: 10,
            width: '100%',
          }
        },
      },
    },
    chatContainer: {
      "& .chat-settings-container": {
        "& .chat-settings-header": {
          backgroundColor: theme.palette.primary.main,

          "& img": {
            height: "20vh",
            width: "100%",
          },
        },
      },

      "& .merchantHeader": {
        alignItems: "center",

        [theme.breakpoints.down('sm')]: {
          gap: theme.spacing(1),

          "& .merchantHeading": {
            fontSize: theme.spacing(2),
          },

          "& .categoriesWrapper": {
            fontSize: theme.spacing(1.5),
          },

          "& .address": {
            fontSize: theme.spacing(1.5),
          },

          "& .merchantImage": {
            width: theme.spacing(5),
            height: theme.spacing(5),
          },
        },

        "& .MuiButton-root": {
          whiteSpace: "nowrap",

          [theme.breakpoints.up('sm')]: {
            marginLeft: 'auto',
          },

          [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0.5, 2),
          },
        },
      }
    },
  })
);

type ConfirmationMessageProps = {
  handleConfirm: () => void;
  confirmationMessage: boolean;
  setConfirmationMessage: (_: boolean) => void;
};

const ConfirmationMessage = (props: ConfirmationMessageProps) => {
  const { handleConfirm, confirmationMessage, setConfirmationMessage } = props;

  return (
    <ConfirmationAlert
      title="Are you sure you want to exit?"
      isOpen={confirmationMessage}
      onConfirm={handleConfirm}
      onCancel={() => setConfirmationMessage(false)}
    >
      This chat will no longer be accessible after you exit.
    </ConfirmationAlert>
  );
};

const ActionsDropdown = () => {
  const [openAboutDrawer, setAboutDrawer] = useState<boolean>(false);
  const { cleanUpChat } = useContext(PlatformChatContext);

  const [confirmationMessage, setConfirmationMessage] =
    useState<boolean>(false);

  const merchant = useAppSelector(getCurrentMerchant);

  const history = useHistory();

  const handleExit = async () => {
    await cleanUpChat()
    history.push(`/${merchant?.username}/store`)
  };

  const itemsList = [
    {
      icon: <Info />,
      text: "About Us",
      handleClick: () => setAboutDrawer(true),
    },
    {
      icon: <ExitToApp />,
      text: "Exit chat",
      handleClick: () => setConfirmationMessage(true),
    },
  ];

  return (
    <>
      <FloatingActionsMenu actionItems={itemsList} />

      <ConfirmationMessage
        handleConfirm={handleExit}
        confirmationMessage={confirmationMessage}
        setConfirmationMessage={setConfirmationMessage}
      />

      <MerchantInfoDrawer
        open={openAboutDrawer}
        setOpen={setAboutDrawer}
        merchant={merchant || ({} as Merchant)}
      />
    </>
  );
};

const RenderExternalChatActions = () => {
  const merchant = useAppSelector(getCurrentMerchant);
  const [isDownloadAppOpen, setDownloadAppOpen] = useState<boolean>(false);

  const [{ data: chatGroupsList = [], loading }] = useAxios<ChatGroup[]>({
    url: `chat-groups/owned-by?id=${merchant!.id}`,
    method: 'get'
  })

  if (loading) return <LoadingIndicator />

  const handleOnClick = (group: ChatGroup) => {
    const osName = getMobileOS();
    const url = urlToCampaign(group.qrCodeLink, 'DigitalLobby', merchant?.username || '', osName);

    window?.open(url, '_blank')?.focus()
  }

  const externalChatGroupsList = chatGroupsList.map((group: ChatGroup, index: number) => {
    return (
      <Chip
        key={`group_${index}`}
        sx={{ px: 1, fontSize: (theme) => theme.spacing(2), }}
        label={group.name}
        icon={<People sx={{ width: 16, height: 15 }} />}
        onClick={() => handleOnClick(group)}
      />
    )
  })

  const handleInfoClick = () => {
    window?.open(`/${merchant?.username}/store`, "_blank")?.focus();

    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.MERCHANT_CHAT,
      action: EVENT_ACTIONS.MERCHANT_INFO_CTA,
    });
  }

  // keep the top margin so that people will not mistakenly click on the pills
  return (
    <>
      <Stack
        sx={{ overflowY: "auto", mt: { xs: 1, sm: 2 } }}
        direction="row"
        gap={0.5}
        p={1}
        justifyContent="flex-start"
      >
        <Chip
          sx={{ px: 1, fontSize: (theme) => theme.spacing(2) }}
          label="Info"
          onClick={handleInfoClick}
          icon={<ContactMail sx={{ width: 16, height: 15 }} />}
        />
        {externalChatGroupsList}
      </Stack>
      <DownloadAppDrawer
        isOpen={isDownloadAppOpen}
        setIsOpen={setDownloadAppOpen}
      />
    </>
  );
};

const UserChatScreen = () => {
  const classes = useStyles();
  const merchant = useAppSelector(getCurrentMerchant);
  const { chatClient, channel, isChatDisabled, isChatStarted } =
    useContext(PlatformChatContext);

  if (!chatClient) {
    if (isChatDisabled) {
      return <ChatErrorMessage />;
    }

    return <SimpleLoader loading={true} />
  }

  const disableMessageInput = isChatDisabled || !isChatStarted;

  const handleReadMoreEvent = () => {
    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.MERCHANT_CHAT,
      action: EVENT_ACTIONS.READ_MORE_CTA
    });
  }

  const handleBookClassEvent = () => {
    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.MERCHANT_CHAT,
      action: EVENT_ACTIONS.BOOK_CLASS,
      label: "shoppable-card"
    });
  }

  return (
    <Box className={classes.root}>
      <Chat client={chatClient} theme="messaging light">
        <ChatErrorMessage />
        <Channel channel={channel}>
          <Window>
            <div className="story-carousel">
              {merchant && <StoriesCarousel merchant={merchant} />}
            </div>
            <MessageListWrapper>
              <MessageList Message={
                () =>
                  <RenderMessage
                    handleReadMoreEvent={handleReadMoreEvent}
                    handleBookClassEvent={handleBookClassEvent}
                  />

              }/>
            </MessageListWrapper>
            <Stack direction="row" alignItems="center" sx={{ pr: 1, mt: 1 }}>
              <ActionsDropdown />
              <MessageInput disabled={disableMessageInput} />
            </Stack>
          </Window>
        </Channel>

        <RenderExternalChatActions />
      </Chat>
    </Box>
  );
};

const UserChat = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{ from: { pathname: string } }>();

  const { isChatStarted, cleanUpChat } = useContext(PlatformChatContext);

  const [confirmationMessage, setConfirmationMessage] =
    useState<boolean>(false);

  const merchant = useAppSelector(getCurrentMerchant);

  const welcomeMessage = `Welcome to ${merchant?.officialName}! Please type in your name below.`;

  const handleBack = (): void => {
    cleanUpChat();

    location.state?.from
      ? history.goBack()
      : history.push(`/${merchant?.username}/store`);
  };

  if (isChatStarted) {
    window.onbeforeunload = function (event: any) {
      return cleanUpChat();
    };
  }

  const handleBookClassEvent = (url: string) => {
    handleBookingRedirect(url);

    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.MERCHANT_CHAT,
      action: EVENT_ACTIONS.BOOK_CLASS,
      label: 'chat-header-cta'
    });
  }

  return (
    <Box className={classes.chatContainer}>
      <If condition={!isChatStarted}>
        <Then>
          <div className="chat-settings-container">
            <div className="chat-settings-header">
              <img src={ChatBannerImg} />
            </div>
            <ChatSettings welcomeMessage={welcomeMessage} />
          </div>
        </Then>
        <Else>
          <Box sx={{ p: {sm: 2, xs: 1}, pb: 0 }}>
            <MerchantHeader
              className="merchantHeader"
              direction="row"
              merchant={merchant || ({} as Merchant)}
              cta={
                <RenderBookServiceButton
                  buttonText="Trial"
                  merchant={merchant}
                  handleClick={handleBookClassEvent}
                />
              }
            />
          </Box>
          <Divider />
          <UserChatScreen />
        </Else>
      </If>

      <ConfirmationMessage
        handleConfirm={handleBack}
        confirmationMessage={confirmationMessage}
        setConfirmationMessage={setConfirmationMessage}
      />
    </Box>
  );
};

export default UserChat;
