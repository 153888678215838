import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { If, Then, Else } from 'react-if';
import { format } from 'date-fns';

import { Merchant, SpecialHours } from '../../services/models';
import { daysOfWeek, getSpecialTimings } from '../../services/hoursUtils';
import { getTimeLabelFromOffset, isEmptyOrNil } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .special-hours-contianer': {
        display: "grid",
        gridTemplateColumns: "82px auto",

        '& .range-label': {},

        '& .timings-container': {
          "& ul":{
            listStyle: 'none',
            padding: `0 ${theme.spacing(1)}`
          }
        }
      }
    }
  })
)

const HourGroup = ({ day, hourGroups }: { day: string; hourGroups: SpecialHours[] }) => {

  return (
    <div className='special-hours-contianer' key={`hours_${day}`}>
      <span className='range-label'>{day}</span>
      <span className='timings-container'>
        <ul>
          {
            hourGroups.map((hours: SpecialHours) => {
              const { fromMinOffset, toMinOffset } = hours
              const timing = (fromMinOffset == 0 || toMinOffset == 0)
                ? 'Closed'
                : `${getTimeLabelFromOffset(fromMinOffset)} - ${getTimeLabelFromOffset(toMinOffset)}`

              return <li key={`${day}_${timing}`}>{timing}</li>
            })
          }
        </ul>
      </span>
    </div >
  )
}

const SpecialTimings = ({ customizations }: { customizations: SpecialHours[] }) => {
  const classes = useStyles()

  const formattedSpecialTimings = getSpecialTimings(customizations)
  const SpecialTimings: Array<React.ReactElement> = []

  const days = Array.from(daysOfWeek.values());
  days.forEach((day: string) => {
    const hourGroups = formattedSpecialTimings[day];
    if (hourGroups != null) {
      SpecialTimings.push(
        <HourGroup key={day} day={day} hourGroups={hourGroups} />
      )
    }
  })

  Object.keys(formattedSpecialTimings).forEach((key: string) => {
    if (!days.includes(key)) {
      const hourGroups = formattedSpecialTimings[key];
      if (hourGroups != null) {
        SpecialTimings.push(
          <HourGroup day={key} key={key} hourGroups={hourGroups} />
        )
      }
    }
  })

  return (
    <div className={classes.root}>
      {SpecialTimings}
    </div>
  )
}

type MerchantStoreHourProps = {
  merchant: Merchant
}
const MerchantStoreHours = ({ merchant }: MerchantStoreHourProps): React.ReactElement => {
  const { hours } = merchant
  const noTimingsAvailable = !hours || (isEmptyOrNil(hours?.defaultFromMinOffset) && isEmptyOrNil(hours?.defaultToMinOffset) && isEmptyOrNil(hours.customizations))
  return (
    <div className='timings-container'>
      <If condition={noTimingsAvailable}>
        <Then>
          No store hours specified
        </Then>
        <Else>
          <If condition={!isEmptyOrNil(hours?.defaultFromMinOffset) && !isEmptyOrNil(hours?.defaultToMinOffset)}>
            {`${getTimeLabelFromOffset(hours?.defaultFromMinOffset)} - ${getTimeLabelFromOffset(hours?.defaultToMinOffset)}`}
          </If>
          <If condition={!isEmptyOrNil(hours.customizations)}>
            <SpecialTimings customizations={hours.customizations} />
          </If>
        </Else>
      </If>

    </div >
  )
}


export const MerchantStoreHoursForToday = ({ merchant }: MerchantStoreHourProps): React.ReactElement => {
  const date = new Date();
  const day = (date.getDay() + 6) % 7;
  const dayOfWeek = daysOfWeek.get(day);
  const dateStr = format(date, 'MM-dd');

  const { hours } = merchant;
  const noTimingsAvailable = !hours || (isEmptyOrNil(hours?.defaultFromMinOffset) && isEmptyOrNil(hours?.defaultToMinOffset) && isEmptyOrNil(hours.customizations))
  if (noTimingsAvailable) {
    return <div>No store hours specified</div>;
  }

  if (hours?.customizations) {
    const formattedSpecialTimings = getSpecialTimings(hours.customizations);
    const hourGroups = formattedSpecialTimings[dateStr] || (dayOfWeek ? formattedSpecialTimings[dayOfWeek] : null);
    if (hourGroups != null) {
      return (
        <div className='special-hours-contianer'>
          <span className='range-label'>{"Today's hours:"}&nbsp;</span>
          <span className='timings-container'>
            {hourGroups
              .map<React.ReactNode>((hours: SpecialHours) => {
                const { fromMinOffset, toMinOffset } = hours
                const timing = (fromMinOffset == 0 || toMinOffset == 0)
                  ? 'Closed'
                  : `${getTimeLabelFromOffset(fromMinOffset)} - ${getTimeLabelFromOffset(toMinOffset)}`

                return <span key={`${day}_${timing}`}>{timing}</span>
              })
              .reduce((prev, curr) => [prev, `, `, curr])
            }
          </span>
        </div >
      )
    }
  }

  return (
    <div className='special-hours-contianer'>
      <If condition={!isEmptyOrNil(hours?.defaultFromMinOffset) && !isEmptyOrNil(hours?.defaultToMinOffset)}>
        <>
          <span className='range-label'>{"Today's hours:"}&nbsp;</span>
          <span className='timings-container'>
            {`${getTimeLabelFromOffset(hours?.defaultFromMinOffset)} - ${getTimeLabelFromOffset(hours?.defaultToMinOffset)}`}
          </span>
        </>
      </If>
    </div >
  )
}


export default MerchantStoreHours;