import React from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentMethod, StripeCardElementOptions } from "@stripe/stripe-js";
import PhoneInput from "react-phone-input-2";
import { If, Then } from "react-if";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/core/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBox from "@material-ui/icons/CheckBox";
import { Link } from "@material-ui/core";

import BottomDrawer from "bos_common/src/components/BottomDrawer";
import PhoneVerificationDialog from "bos_common/src/components/Auth/PhoneVerificationDialog";

import { cachePaymentMethod, clearUnsavedPaymentMethod, createPaymentMethod } from "../../redux/slice/stripe/stripeAction";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getStripeUpdatingStatus } from "../../redux/slice/stripe/stripeSelector";
import { PHONE_ONLY_COUNTRIES } from "../../config/constants";
import { isEmptyOrNil } from "../../utils";
import { getUser } from "../../redux/slice/auth/authSelector";
import eventTrackingService from "../../services/Tracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../../services/Tracking/events";

const options: StripeCardElementOptions = {
  style: {
    base: {
      fontSmoothing: "antialiased",
      fontSize: "16px",
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    minHeight: '280px',
    padding: '1rem 2rem 1rem',

    "& .phone-input": {
      margin: `${theme.spacing(2)} 0`,

      "& .react-tel-input": {
        "& .flag-dropdown, & .flag-dropdown.open .selected-flag": {
          borderRadius: '6px 0 0 6px'
        },
        "& input": {
          width: "100%",
          height: "44px",
          borderRadius: "6px",
          border: "1px solid rgba(0, 0, 0, 0.15)",
        },
        "& .country-list": {
          borderRadius: '0px',
        },
        "& .phoneInput": {
          "&:focus": {
            borderColor: theme.palette.primary.main,
            boxShadow: "unset",
          },
        },
      },
      "& .helper-text": {
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: '0.75rem',
        marginTop: '3px'
      },
    },

    "& .phone-number-container": {
      marginTop: theme.spacing(4),
    },

    "& .react-tel-input .form-control": {
      width: '100%',
      borderColor: theme.palette.primary.main,
    },

    "& .credit-card-input": {
      margin: '1rem 0',
      padding: '1rem',
      border: `1px solid #c4c4c4`,
      borderRadius: '.5rem',
      "&.StripeElement--focus": {
        borderColor: theme.palette.primary.main,
        borderWidth: "2px",
        padding: 'calc(1rem - 1px)',
      },
    },

    "& .checkbox-container": {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      marginTop: '1rem',
      marginBottom: '1rem',
      alignItems: 'flex-start',

      "& .card-checkbox": {
        padding: '4px',
        paddingTop: 0,
        float: 'left',
      },
    }
  }
}));

export interface AddPaymentFormDrawerProps {
  open: boolean;
  setOpen: (_: boolean) => void;
  hidePhoneNumber?: boolean;
  onPaymentMethodCreated?: (paymentMethod: PaymentMethod, phone?: string) => void;
  onClickExistingUser?: () => void;
}

export const AddPaymentFormDrawer = (props: Readonly<AddPaymentFormDrawerProps>): JSX.Element | null => {
  const { open, setOpen, hidePhoneNumber = true, onPaymentMethodCreated, onClickExistingUser } = props;
  if (!open) {
    return null;
  }

  const user = useAppSelector(getUser);
  const classes = useStyles();
  const elements = useElements();
  const stripe = useStripe();
  const updating = useAppSelector(getStripeUpdatingStatus);
  const reduxDispatch = useAppDispatch();
  const [name, setName] = React.useState<string>('');
  const [phone, setPhoneNumber] = React.useState<string>('');
  const [error, setError] = React.useState<string>('');
  const [selected, setSelected] = React.useState<boolean>(true);
  const [phoneSignupOpen, setPhoneSignupOpen] = React.useState(false);

  React.useEffect(() => {
    if (!open) {
      const card = elements?.getElement(CardElement);
      card?.clear();
      setName('');
      setError('');
    }
  }, [open]);

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value;
    setName(value);
    setError("");
  }

  const handleSubmit = async (e: React.FormEvent) => {
    if (updating || !isValid()) {
      e.preventDefault();
      return;
    }

    e.preventDefault();
    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.ORDER_CHECKOUT,
      action: EVENT_ACTIONS.CLICK_ORDER_CHECKOUT_ADD_PAYMENT
    });

    try {
      const card = elements?.getElement(CardElement);

      if (!elements || !card || !stripe) {
        throw new Error("Could not connect to payment services");
      }

      if (isCacheNewCard()) {
        setPhoneSignupOpen(true);

        return reduxDispatch(cachePaymentMethod({
          stripe, card, name,
        }));
      } else {
        // NOTE: This will be useful when the user accidentally not unchecked the checkbox on the first card addition attempt
        // and click the add cta button.
        // Then trying second attempt without checking the checkbox.
        reduxDispatch(clearUnsavedPaymentMethod());
      }

      reduxDispatch(createPaymentMethod({
        stripe,
        card,
        name,
      }))
        .then((res: any) => {
          if (isEmptyOrNil(res?.payload)) {
            setError("Could not add card, please check the information and try again");
            return;
          }

          const createdPaymentMethod = res.payload[0] as PaymentMethod;
          if (onPaymentMethodCreated) {
            onPaymentMethodCreated(createdPaymentMethod, phone);
          }
        });

    } catch (error) {
      throw new Error("Could not connect to payment services");
    }
  }

  const isValid = () => {
    if (!hidePhoneNumber && phone.length < 11) {
      setError("Phone number is required");
      return false;
    }

    if (name.trim().length < 1) {
      setError("Name is required");
      return false;
    }

    return true;
  }

  const handleClickCheckbox = () => {
    setSelected(!selected);
  }

  const handleClickExistingUser = (e: React.MouseEvent) => {
    e.preventDefault();

    if (onClickExistingUser) {
      onClickExistingUser();
    }
  }

  const isCacheNewCard = (): boolean => {
    return !hidePhoneNumber && selected;
  }

  return (
    <BottomDrawer
      open={open}
      setOpen={setOpen}
      title={<Typography variant="h6">Add payment method</Typography>}
    >
      <>
        <div className={classes.root}>
          <div className="credit-card-inputs-row">
            <If condition={Boolean(!user)}>
              <Link href="#" color="inherit" onClick={handleClickExistingUser} style={{ display: 'flex', justifyContent: 'flex-end', textDecoration: 'underline' }}>
                Existing user?
              </Link>
            </If>
            <form onSubmit={handleSubmit}>
              <If condition={!hidePhoneNumber}>
                <Then>
                  <Box style={{ margin: '1rem 0' }}>
                    <PhoneInput
                      inputClass="phoneInput"
                      country={'us'}
                      onlyCountries={PHONE_ONLY_COUNTRIES}
                      value={phone}
                      specialLabel=""
                      placeholder="+1 (234) 567-8910"
                      onChange={p => {
                        setPhoneNumber(p);
                        setError("");
                      }}
                    />
                    <Typography variant="caption" lineHeight={1.2}>Add phone number to receive order updates, required</Typography>
                  </Box>
                  <Divider />
                </Then>
              </If>
              <Box style={{ margin: '1rem 0' }}>
                <TextField
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  color="primary"
                  value={name}
                  onChange={handleChangeName}
                  style={{ width: '100%' }}
                  required />
              </Box>
              <CardElement
                className="credit-card-input"
                options={options}
              />
              <Box style={{ margin: '1rem 0' }}>
                {error && <Alert severity="warning" sx={{ mb: 1 }}>{error}</Alert>}
                <If condition={!hidePhoneNumber}>
                  <Box className='checkbox-container'>
                    <Checkbox
                      icon={<CheckBoxOutlineBlank />}
                      checkedIcon={<CheckBox color='primary' />}
                      onClick={handleClickCheckbox}
                      checked={selected}
                      className="card-checkbox"
                    />
                    <Typography variant="caption" sx={{ textAlign: 'justify' }} lineHeight={1.5}>
                      Save my card for future use and sign up for a One Market Account. By clicking on continue, you agree to our <a href="/help/terms-of-use" target='_blank'>Terms of Services</a> and <a href="/help/privacy-policy" target='_blank'>Privacy Policy</a>.
                    </Typography>
                  </Box>
                </If>
                <Button color="primary" variant="contained" size="large" style={{ width: '100%', position: 'relative' }} type="submit" disabled={updating}>
                  {isCacheNewCard() ? 'Continue' : 'Add'}
                  <If condition={updating}>
                    <Then>
                      <CircularProgress
                        size={'1.5rem'}
                        sx={{
                          color: 'white',
                          position: 'absolute',
                          zIndex: 1,
                        }}
                      />
                    </Then>
                  </If>
                </Button>
              </Box>
            </form>
          </div>
        </div>
        <PhoneVerificationDialog
          phoneNumber={phone}
          open={phoneSignupOpen}
          setOpen={setPhoneSignupOpen}
        />
      </>
    </BottomDrawer>
  );
}

export default AddPaymentFormDrawer;
