import React from "react";
import { CardContent, Typography, Grid } from "@material-ui/core";

import { Merchandise } from "bos_common/src/types/MerchandiseType";
import { Merchant } from "bos_common/src/types/MerchantType";
import { MerchantCoupon } from "bos_common/src/types/MerchantCouponType";
import { filterComboByItemsAvailability } from "bos_common/src/services/CouponUtils";

import CountBox from "../common/CountBox";
import { PromotionItemCard } from "./PromotionItemCard";
import useStyles from "./styles";
import MarketingOneLiner from "../common/MarketingOneLiner";

interface ComboDealsProps {
  merchandises?: Merchandise[];
  merchant: Merchant;
  coupons: MerchantCoupon[];
}

const ComboDeals = ({ merchandises = [], merchant, coupons }: ComboDealsProps): JSX.Element | null => {
  if (coupons.length < 1) return null;

  const classes = useStyles();
  const availableCouponsIds = React.useMemo(() => {
    return filterComboByItemsAvailability(coupons, merchandises).map(coupon => coupon.id);
  }, [coupons]);
  const sortedCoupons = React.useMemo(() => {
    return coupons?.sort((itemA, itemB) => new Date(itemA.createdAt).getTime() > new Date(itemB.createdAt).getTime() ? 1 : -1);
  }, [coupons]);

  const renderComboDealsPromotions = () => {
    return sortedCoupons.map((item) => {
      return  (
        <Grid item xs={12} sm={6} lg={4} key={item.id}>
          <PromotionItemCard
            coupon={item}
            merchandises={merchandises}
            merchant={merchant}
            isAvailable={availableCouponsIds.includes(item.id)}
          />
        </Grid>
      );
    });
  };

  return (
    <CardContent className={classes.root}>
      <div className="category-info">
        <Typography variant="body1" component="div" display='flex' alignItems='center'>
          Featured Combos&nbsp;&nbsp;
          <CountBox count={coupons.length} />
        </Typography>
        <MarketingOneLiner description={'Buy more and save more'} />
      </div>
      <Grid container spacing={2}>
        {renderComboDealsPromotions()}
      </Grid>
    </CardContent>
  )
}

export default ComboDeals;
