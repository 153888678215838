import React, { useContext, useEffect, useState } from 'react'
import { Alert } from '@material-ui/core';
import { If } from 'react-if';
import { useHistory } from 'react-router';

// src
import SimpleLoader from '../bos_common/src/components/SimpleLoader';
import { UserContext } from '../bos_common/src/context/UserContext';
import { AppContext, CartType } from '../context/AppContext';
import { checkCartItemsAvailability } from '../components/Payment/utils';
import { Order, OrderStatus } from '../services/models';
import { FullscreenPaper } from '../bos_common/src';
import { getAPIErrorMessage, isEmptyOrNil } from '../utils';
import createOrderService from '../services/createOrderService';
import { cleanUpStateAfterOder } from '../services/localStorage';

type PlaceOpenCheckOrderPageProps = any

const PlaceOpenCheckOrderPage = (props: PlaceOpenCheckOrderPageProps) => {
  const history = useHistory()
  const appContext = useContext(AppContext)
  const { cart, merchantConfig, clearCart, setCartType } = appContext
  const { user, token, refreshUser, authenticating } = useContext(UserContext);

  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState<string>('')

  const onSuccessCreate = (order: Order) => {
    clearCart()
    cleanUpStateAfterOder();

    setCartType(CartType.REGULAR)
    history.push(`/oc-order/${order.id}`)
  }

  const createOrderWithOpenCheck = async () => {
    setProcessing(true)
    setCartType(CartType.FROM_OPEN_CHECK)
    if (checkCartItemsAvailability({ cart, merchantConfig })) {
      setError("Some items in your cart are sold out");
      setProcessing(false);
      return;
    }

    try {
      const results = await createOrderService({
        appContext,
        name: user?.displayName,
        user,
        token,
        orderStatus: OrderStatus.OPEN_CHECK
      });

      if (results.status === 200) {
        const order = results.data
        setError('')
        setProcessing(false)

        if (!isEmptyOrNil(user)) refreshUser()

        onSuccessCreate(order)
      } else {
        setError(`Unable to process your order. Please try again later`);
        setProcessing(false);
      }
    } catch (err) {
      const message = getAPIErrorMessage(err) || `Unable to process your order. Please try again later`;
      setError(message);
      setProcessing(false);
      console.log(err)
    }
  }

  useEffect(() => {
    if (isEmptyOrNil(cart)) {
      setError('No items found in the Shopping Cart')
    } else if (!isEmptyOrNil(user)) {
      createOrderWithOpenCheck()
    }
  }, [user, cart])

  return (
    <FullscreenPaper>
      <SimpleLoader loading={processing || authenticating} />
      <If condition={error}>
        <Alert severity='warning'>{error}</Alert>
      </If>
    </FullscreenPaper>
  )
}

export default PlaceOpenCheckOrderPage

