import React, { useContext, useEffect, useState } from 'react';
import { Else, If, Then } from 'react-if';
import { useLocation } from 'react-router-dom';
import { Button, Theme, useMediaQuery, Typography } from '@material-ui/core';
import qs from 'qs';
import { propOr } from 'ramda';

import axios from '../bos_common/src/services/backendAxios';
import { MerchantPromotion, MerchantPromotionType } from '../bos_common/src/types/MerchantPromotionType';
import { useInterval } from '../bos_common/src/hooks';
import { isPromotionValid } from '../bos_common/src/services/PromotionUtils';
import { getAuthHeaders } from '../bos_common/src/utils';
import { UserContext } from '../bos_common/src/context/UserContext';

import NewUserPromoDialog from './Promotions/NewUserPromoDialog';
import SignupDrawer, { SignupCTA, SignupEntrance } from './User/SignupDrawer';

export interface PlatformPromotionalModalProps {
  defaultPromotionCode?: string;
  delay?: number;
}

export const PlatformPromotionModal = (props: Readonly<PlatformPromotionalModalProps>): JSX.Element | null => {
  const {
    defaultPromotionCode,
    delay = 0
  } = props;

  const location = useLocation();
  const { user, token, refreshUser, authenticating } = useContext(UserContext)
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const promotionCode = propOr(defaultPromotionCode, 'pc', params);
  const isRedemption = propOr(null, 'redeem', params);
  const defaultSignupRewards = promotionCode === 'san_mateo_signup_rewards'; // Special case

  params['redeem'] = '1';
  const redirectSearchURL = qs.stringify(params);
  // TODO, only works on /
  const redirectURL = isRedemption ? `${location.pathname}?${redirectSearchURL}#newUser` : `${location.pathname}?${redirectSearchURL}#newUser`;

  const [isRewardsDrawerVisible, toggleRewardsDrawer] = useState(false);
  const [isReady, setReady] = useState(false);
  const [canShow, setCanShow] = useState(false);
  const [hasRedeemed, setHasRedeemed] = useState(false);
  const [promotion, setPromotion] = useState<MerchantPromotion>();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (promotionCode) {
      axios.get<MerchantPromotion>("/promotions", {
        params: {
          code: promotionCode,
          type: MerchantPromotionType.PLATFORM
        }
      }).then((response) => {
        if (response.status === 200) {
          setPromotion(response.data as MerchantPromotion);
        }
      })
    }
  }, [promotionCode])

  const redeemPromotion = async () => {
    try {
      if (promotion && isPromotionValid(promotion)) {
        await axios.post(`/users/promotions/${promotion.id}`, user, { headers: getAuthHeaders(token) })
        setPromotion(undefined);
        setHasRedeemed(true);
      }
      refreshUser();
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (!authenticating && promotion) {
      if (isRedemption) {
        if (user && !hasRedeemed) {
          redeemPromotion();
        }
      } else {
        if (defaultSignupRewards) {
          setCanShow(!user && isPromotionValid(promotion));
        } else {
          setCanShow(isPromotionValid(promotion));
        }
      }
    }
  }, [user, authenticating, promotion])

  const interval = useInterval(() => {
    setReady(true);
    interval && clearInterval(interval.current || 0);
  }, delay);

  useEffect(() => {
    toggleRewardsDrawer(isReady && canShow);
  }, [isReady, canShow])

  if (!isRewardsDrawerVisible || !promotion) {
    return null;
  }

  return (
    <If condition={!!user}>
      <Then>
        <If condition={!defaultSignupRewards}>
          <Then>
            <SignupDrawer
              open={isRewardsDrawerVisible}
              setOpen={toggleRewardsDrawer}
              entrance={SignupEntrance.CUSTOM}
              forceShow={true}
              configOverride={{
                title: promotion.title,
                imageSrc: "../delivery-guy-handing-box.png",
                CTA: <div>
                        <Typography gutterBottom variant="subtitle1" color="textSecondary" component="div" textAlign="center" lineHeight={1.2}>
                          {promotion.description}
                        </Typography>
                        <Button onClick={redeemPromotion} color="primary" variant="contained" sx={{width: '100%'}}>Claim</Button>
                    </div>
              }}
            />
          </Then>
        </If>
      </Then>
      <Else>
        <If condition={isMobile}>
          <Then>
            <If condition={!defaultSignupRewards}>
              <Then>
                <SignupDrawer
                  open={isRewardsDrawerVisible}
                  setOpen={toggleRewardsDrawer}
                  entrance={SignupEntrance.CUSTOM}
                  configOverride={{
                    title: promotion.title,
                    imageSrc: "../delivery-guy-handing-box.png",
                    CTA: <SignupCTA redirectTo={redirectURL} showActionBtn title={promotion.description} />
                  }}
                />
              </Then>
              <Else>
                <SignupDrawer
                  open={isRewardsDrawerVisible}
                  setOpen={toggleRewardsDrawer}
                  entrance={SignupEntrance.NEW_DISCOVERY_SIGNUP}
                  redirectAfterSignup={redirectURL}
                />
              </Else>
            </If>
          </Then>
          <Else>
            <If condition={!defaultSignupRewards}>
              <Then>
                <NewUserPromoDialog
                  redirectTo={redirectURL}
                  isOpen={isRewardsDrawerVisible}
                  setOpen={toggleRewardsDrawer}
                  title={promotion.title}
                  description={promotion.description}
                  phoneFieldOnly
                />
              </Then>
              <Else>
                <NewUserPromoDialog
                  redirectTo={redirectURL}
                  isOpen={isRewardsDrawerVisible}
                  setOpen={toggleRewardsDrawer}
                  title="$5 Off your next order"
                  description="Create a One Market account and claim your reward."
                />
              </Else>
            </If>
          </Else>
        </If>
      </Else>
    </If>


  );
}

export default PlatformPromotionModal;
