import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { rgba } from 'polished';

import HeartIllustration from "../../assets/images/congratulations-rewards.svg";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wideMode: {
      backgroundColor: theme.palette.primary.main,
      height: 'calc(100vh - 6px)',
      position: "relative",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1,
      display: 'grid',
      gridTemplateColumns: '0.5fr 1fr',

      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '0.8fr 1fr',
      },

      "& .status-container": {
        width: "max-content"
      },

      "& .orderStatusImage": {
        position: "absolute",
        bottom: 0,
        right: 0,
        objectFit: "cover",
        "z-index": -1
      },

      '& .orderStatusIllustration': {
        background: `url(${HeartIllustration}) no-repeat`,
        transform: 'scaleX(-1)',
        width: '100%',
        height: '100%',
        backgroundPosition: 'center left',
      },

      "& .redirect-progress-container": {
        position: "absolute",
        right: theme.spacing(5),
        top: theme.spacing(3)
      },

      "& .pickup-details": {
        textAlign: "left",

        "& .detail":{
          marginTop: theme.spacing(2)
        },

        "& .detail-heading": {
          fontSize: theme.spacing(3),
          color: rgba(theme.palette.background.paper, .5),
          fontWeight: 700,
          lineHeight: 1.2,
        },

        "& .detail-value": {
          fontWeight: 700,
          fontSize: theme.spacing(4)
        }
      },

      '& .order-status-container': {
        backgroundColor: "transparent",
        textAlign: 'center',
        display: 'flex',
        alignItems: 'baseline',
        flexDirection: "column",
        boxShadow: 'none',
        marginLeft: "25%",
        color: theme.palette.background.paper,
        width: "max-content",

        "& .orderStatusHeading": {
          fontSize: theme.spacing(5),
          fontWeight: 800,
        },

        '& .pick-up-duration-text': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: theme.spacing(1),

            "& .MuiTypography-root": {
              fontSize: theme.spacing(3),
              textTransform: "capitalize",
              color: theme.palette.background.paper,
              fontWeight: 700,

              "& span" :{
                color: `${theme.palette.background.paper} !important`
              }
            }
          }
      },

      '& .cta-button': {
        marginTop: theme.spacing(4),
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.main,
        textTransform: "capitalize",
        width: theme.spacing(12.5),
        height: theme.spacing(7.5),
        borderRadius: theme.spacing(6.25),
        fontSize: theme.spacing(2.5),
        fontWeight: 700
      },

      '& .spacer': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },

    },

    tabletMode : {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      height: 'calc(100vh - 6px)',

      "& .heart-illustration-image": {
        transform: "scaleX(-1)"
      },

      "& .detail": {
        "&:not(:last-child)": {
          marginBottom: theme.spacing(1)
        },

        "& .detail-heading": {
          fontSize: "1.2rem"
        },

        "& .detail-value": {
          fontWeight: 500
        }
      },

      '& .order-status-container': {
        backgroundColor: theme.palette.primary.main,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.background.paper,
        boxShadow: '4px 0 5px 0px rgba(0,0,0,0.1)',

        "& .orderStatusHeading": {
          fontWeight: 800
        },

        '& .content': {
          margin: '0 auto',
          width: '100%',

          '& img': {
            maxWidth: '100%',
            maxHeight: '400px',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
          },
          '& .pick-up-duration-text': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            "& .MuiTypography-root": {
              fontSize: theme.spacing(3),
              textTransform: "capitalize",
              color: theme.palette.background.paper,
              fontWeight: 700,

              "& span" :{
                color: `${theme.palette.background.paper} !important`
              }
            }
          },
          '& .promotion-image': {
            maxWidth: "180px"
          },
        },
      },

      '& .order-details-container': {
        display: 'flex',
        alignItems: 'center',

        '& .content': {
          width: '80%',
          margin: '0 auto',
          padding: theme.spacing(0),

          '& .spacer': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
          },
          '& .cta-button': {
            marginTop: theme.spacing(3),
            width: '100%'
          },
        },
      },
    },

    compactMode: {
      flexGrow: 1,
      '& .order-status-container': {
        flex: 1,
        height: '100%',
        backgroundColor: '#f5faff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '4px 0 5px 0px rgba(0,0,0,0.1)',

        "& .orderStatusHeading": {
          textAlign: "center",
          fontWeight: 500,

          "& .MuiSvgIcon-root": {
            fontSize: "1.3rem"
          },

          fontSize: "1.3rem"
        },

        '& .content': {
          padding: theme.spacing(2),
          width: 300,
          margin: '0 auto',

          "& .heart-illustration-container": {
            textAlign: "center"
          },

          '& img': {
            width: '65%',
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
          },
          '& .pick-up-duration-text': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          },
          '& .promotion-image': {
            maxWidth: "180px"
          },
        },
      },

      "& .detail":{
        marginTop: theme.spacing(1.5)
      },

      "& .detail-value": {
        fontSize: "1.2rem",
        fontWeight: 500
      },

      '& .order-details-container': {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(4),

        '& .content': {
          width: '100%',
          '& .spacer': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
          },
          '& .cta-button': {
            marginTop: theme.spacing(4),
            width: '100%',
          },
        },
      },
    }
  })
)