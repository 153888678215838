import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { WritableDraft } from '@reduxjs/toolkit/node_modules/immer/dist/internal';
import { Order } from '../../../bos_common/src/types/OrderTypes';
import { MerchantPromotion } from "../../../bos_common/src/types/MerchantPromotionType";

import { MerchantPromotionReduxSlice, MerchantPromotionSliceStateEntities } from '../../../types/MerchantPromotionSlice';
import { fetchMerchantPromotion } from './merchantPromotionAction';

type MerchantPromotionSliceCaseReducer = SliceCaseReducers<MerchantPromotionReduxSlice>;

const commonUpdate = (state: WritableDraft<MerchantPromotionReduxSlice>, action: PayloadAction<MerchantPromotion[]>): MerchantPromotionReduxSlice => {
  let promotion: MerchantPromotion | undefined;
  const { payload } = action;
  const byId = { ...state.entities.byId }
  const ids = [...state.entities.ids];

  payload.forEach((item) => {
    promotion = payload.find((subItem) => subItem.id === item.id);

    if (promotion) {
      byId[item.id] = promotion;
    }

    if (ids.indexOf(item.id) === -1) {
      ids.push(item.id);
    }
  });

  return {
    ...state,
    loading: false,
    entities: {
      byId,
      ids,
    }
  }
}

export const merchantPromotionSlice = createSlice<MerchantPromotionReduxSlice, MerchantPromotionSliceCaseReducer, string>({
  name: 'merchantPromotion',
  initialState: {
    loading: false,
    entities: {
      ids: [],
      byId: {},
    },
  },
  reducers: {
    setLoading: (state) => {
      return {
        ...state,
        loading: true
      }
    },

    stopLoading: (state) => {
      return {
        ...state,
        loading: false
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerchantPromotion.pending, (state) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(fetchMerchantPromotion.fulfilled, (state, action) => {
        const updatedState = commonUpdate(state, action as PayloadAction<MerchantPromotion[]>);

        return {
          ...updatedState,
        }
      })
      .addCase(fetchMerchantPromotion.rejected, (state) => {
        return {
          ...state,
          loading: false,
        }
      })
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  stopLoading,
  updateMerchantPromotion,
} = merchantPromotionSlice.actions;

export default merchantPromotionSlice.reducer;
