import { propOr } from "ramda";

import { isOpenCheckOrder } from "../../bos_common/src/services/orderUtils";
import { isEmptyOrNil } from "../../utils";

import { EVENT_ACTIONS } from "./events";
import { CaptureOrderCheckoutEvent } from "./types"

export const getOrderCheckoutEventAction = (params: CaptureOrderCheckoutEvent) => {
  const { data, errorMessage = '' } = params
  let action: EVENT_ACTIONS;

  if (isOpenCheckOrder(propOr({}, 'order', data))) {
    action = isEmptyOrNil(errorMessage) ? EVENT_ACTIONS.CLICK_OPEN_CHECK_ORDER_UPDATED : EVENT_ACTIONS.CLICK_OPEN_CHECK_ORDER_UPDATE_FAILED
  } else {
    action = isEmptyOrNil(errorMessage) ? EVENT_ACTIONS.VISITED_ORDER_CHECKOUT_COMPLETED : EVENT_ACTIONS.VISITED_ORDER_CHECKOUT_FAILED
  }

  return action;
}