import React from 'react';
import { Card, CardContent, SwipeableDrawer, SwipeableDrawerProps, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  content: {
    paddingTop: '0.625rem',
  },
});

interface AllVotesDrawerProps extends Partial<SwipeableDrawerProps> {
  anchor: "bottom" | "left" | "right" | "top" | undefined;
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const AllVotesDrawer = (props: Readonly<AllVotesDrawerProps>): JSX.Element => {
  const classes = useStyles();
  return (
    <SwipeableDrawer {...props} style={{borderRadius: '20px'}}>
      <Card>
        <CardContent className={`${classes.content}`}>
          <TextField id="outlined-basic" label="Search with name, city, postal code..." variant="outlined" fullWidth />
        </CardContent>
      </Card>
    </SwipeableDrawer>
  )
}