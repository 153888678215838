import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { WritableDraft } from '@reduxjs/toolkit/node_modules/immer/dist/internal';
import { Reservation } from 'bos_common/src/types/ReservationTypes';

import { ReservationSliceState } from '../../../types/ReservationSlice';
import { fetchReservationCancel, fetchReservationDetail } from './reservationAction';

type ReservationSliceCaseReducer = SliceCaseReducers<ReservationSliceState>;

const commonUpdate = (state: WritableDraft<ReservationSliceState>, action: PayloadAction<Reservation[]>): ReservationSliceState => {
  let tempRef: Reservation | undefined;
  const { payload } = action;
  const byId = { ...state.entities.byId }
  const ids = [...state.entities.ids];

  payload.forEach((item) => {
    tempRef = payload.find((subItem) => subItem.id === item.id);
    if (tempRef) {
      byId[item.id] = tempRef;
    }

    if (ids.indexOf(item.id) === -1) {
      ids.push(item.id);
    }

  });

  return {
    ...state,
    loading: false,
    updating: false,
    entities: {
      byId,
      ids,
    }
  }
}

export const reservationSlice = createSlice<ReservationSliceState, ReservationSliceCaseReducer, string>({
  name: 'reservation',
  initialState: {
    loading: false,
    updating: false,
    entities: {
      ids: [],
      byId: {},
    },
  },
  reducers: {
    setLoading: (state) => {
      return {
        ...state,
        loading: true
      }
    },

    stopLoading: (state) => {
      return {
        ...state,
        loading: false
      }
    },

    updateReservation: (state, action: PayloadAction<Reservation[]>) => {
      const updatedState = commonUpdate(state, action);

      return {
        ...updatedState
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReservationDetail.pending, (state) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(fetchReservationDetail.fulfilled, (state, action) => {
        const updatedState = commonUpdate(state, action as PayloadAction<Reservation[]>);

        return {
          ...updatedState,
        }
      })
      .addCase(fetchReservationDetail.rejected, (state) => {
        return {
          ...state,
          loading: false,
        }
      })
    
    builder.addCase(fetchReservationCancel.pending, (state) => {
      return {
        ...state,
        loading: true
      }
    }).addCase(fetchReservationCancel.fulfilled, (state, action) => {
      const updatedState = commonUpdate(state, action as PayloadAction<Reservation[]>);

        return {
          ...updatedState,
        }
    }).addCase(fetchReservationCancel.rejected, (state) => {
      return {
        ...state,
        loading: false,
      }
    })
  //     .addCase(updateOrderDetail.pending, (state) => {
  //       return {
  //         ...state,
  //         updating: true
  //       }
  //     })
  //     .addCase(updateOrderDetail.fulfilled, (state, action) => {
  //       const updatedState = commonUpdate(state, action as PayloadAction<Order[]>);

  //       return {
  //         ...updatedState,
  //       }
  //     })
  //     .addCase(updateOrderDetail.rejected, (state) => {
  //       return {
  //         ...state,
  //         updating: false,
  //       }
  //     });
   },
});

// Action creators are generated for each case reducer function
export const { setLoading, stopLoading, updateReservation } = reservationSlice.actions;

export default reservationSlice.reducer;
