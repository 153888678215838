import React from 'react'
import { SvgIcon } from "@material-ui/core";

function AmericanIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M7.781 7.125h1.094a.437.437 0 000-.875H7.78a1.095 1.095 0 000 2.187h.437a.219.219 0 010 .438H7.125a.438.438 0 000 .875h1.093a1.095 1.095 0 000-2.188h-.437a.219.219 0 010-.437zM11.5 6.25c-.724 0-1.313.589-1.313 1.312v1.75a.438.438 0 00.876 0v-.437h.874v.437a.437.437 0 10.876 0v-1.75c0-.723-.59-1.312-1.313-1.312zM11.062 8v-.438a.438.438 0 01.876 0V8h-.876zM5.375 6.25a.438.438 0 00-.437.437v1.75a.438.438 0 01-.875 0v-1.75a.438.438 0 00-.875 0v1.75c0 .724.589 1.313 1.312 1.313.724 0 1.313-.589 1.313-1.313v-1.75a.437.437 0 00-.438-.437z" />
      <path d="M14.99 7.604a7 7 0 10-13.98.793 7 7 0 0013.98-.793zm-1.356 2.802H2.366a6.142 6.142 0 010-4.812h11.268a6.138 6.138 0 010 4.812zm-2.79 3.02a6.034 6.034 0 01-1.303.503c-.015.004-.01.208-.01-2.648h1.313v2.145zm-4.375.506a6.054 6.054 0 01-1.303-.501c-.014-.007-.01.155-.01-2.15H6.47v2.65zM8 1.875a6.168 6.168 0 015.172 2.844H2.828A6.168 6.168 0 018 1.875zM2.826 11.28h1.455v1.587a6.105 6.105 0 01-1.455-1.587zm4.518 2.808v-2.808h1.312v2.808a6.245 6.245 0 01-1.312 0zm4.375-1.221V11.28h1.455a6.104 6.104 0 01-1.455 1.587z" />
      <path d="M10.406 4.062a.437.437 0 100-.875.437.437 0 000 .875zM8 3.625a.437.437 0 100-.875.437.437 0 000 .875zM5.594 4.062a.437.437 0 100-.875.437.437 0 000 .875z" />
    </SvgIcon>
  );
}

export default AmericanIcon