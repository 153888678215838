import React from "react";
import { CardContent, Typography, Grid } from "@material-ui/core";
import { pathOr } from "ramda";

import { MerchantCoupon, ItemType } from "bos_common/src/types/MerchantCouponType";
import { Merchandise, MerchandiseModifier } from "bos_common/src/types/MerchandiseType";
import { Merchant } from "bos_common/src/types/MerchantType";

import CountBox from "../common/CountBox";
import useStyles from "./styles";
import { isMerchandiseAvailable } from "../../utils";
import MarketingOneLiner from "../common/MarketingOneLiner";
import MerchandiseList from "./MerchandiseList";

interface FeaturedDealsProps {
  merchandises: Merchandise[];
  merchant: Merchant;
  modifiers: MerchandiseModifier[] | undefined;
  coupon?: MerchantCoupon;
}

const FeaturedDeals = ({ merchandises, merchant, modifiers, coupon }: FeaturedDealsProps): JSX.Element => {

  const classes = useStyles();
  const featuredDealItems = React.useMemo(() => {
    if (coupon && coupon.affectedItems) {
      return merchandises.filter((m) => {
        if (!isMerchandiseAvailable(m)) return false; // keep only available items
        const match = coupon.affectedItems?.filter((item) => {
          return (item.type === ItemType.CATEGORY) ? (Number(item.id) === m.categoryId) : (item.id === m.id);
        });
        return match && (match?.length > 0);
      })
    }
    return [];
  }, [merchant, merchandises, coupon]);

  if (!coupon || featuredDealItems.length < 1)
    return <></>;

  return (
    <CardContent className={classes.root}>
      <div className="category-info">
        <Typography variant="body1" component="div" display='flex' alignItems='center'>
          Featured Deals&nbsp;&nbsp;
          <CountBox count={featuredDealItems.length} />
        </Typography>
        <MarketingOneLiner description={coupon.title || ''} />
      </div>
      <Grid container spacing={2}>
        <MerchandiseList
          merchandises={featuredDealItems}
          modifiers={modifiers}
          merchant={merchant}
          coupon={coupon}
        />
      </Grid>
    </CardContent>
  )
}

export default FeaturedDeals;