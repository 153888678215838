import React, { useMemo } from "react";
import { Else, If, Then } from "react-if";
import { useMediaQuery, Theme, Typography, Stack, Box, makeStyles, createStyles } from "@material-ui/core";
import { rgba } from "polished";

import { isEmptyOrNil } from "bos_common/src/utils";
import { Merchant } from "bos_common/src/types/MerchantType";

import { isKioskDevice, getMerchantAddress, getMerchantCategoryList, getMerchantExpenseLevel } from "../../utils";
import renderMerchantRewards from "../common/MerchantRewards";
import MerchantAvatar from "../MerchantAvatar";
import MerchantInfoCTA from "./MerchantInfoCTA";
import { renderMerchantRatingStr } from "./MerchantRatingInfo";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .merchantRewards, .merchantRating": {
        display: "inline-flex",
      },

      "& .categoriesWrapper": {
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",

        "& .pipe": {
          color: rgba(54, 66, 69, 0.25)
        }
      },

      "& .merchant-info": {
        position: 'absolute',
        right: 0
      },
    },
  })
);

interface MerchantHeaderProps {
  merchant: Merchant;
  direction?: 'row' | 'column';
  cta?: React.ReactNode;
  className?: string;
}
const MerchantHeader = ({ merchant, direction = 'column', cta, className }: MerchantHeaderProps) => {
  const classes = useStyles();
  const addressLine = useMemo(() => merchant && getMerchantAddress(merchant), [merchant]);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isKioskModeEnabled = isKioskDevice();

  const rewardsLabel = renderMerchantRewards(merchant || {} as Merchant);
  const ratingsLabel = renderMerchantRatingStr(merchant);
  const expenseLabel = getMerchantExpenseLevel(merchant || {} as Merchant)?.label;
  const categoriesLabel = getMerchantCategoryList(merchant || {} as Merchant).join(", ");

  const renderHeaderSubtitle = useMemo(() => {
    const labelList = isKioskModeEnabled
      ? [expenseLabel, categoriesLabel, rewardsLabel]
      : [expenseLabel, categoriesLabel, rewardsLabel, ratingsLabel];

    const filteredLabels = labelList.filter((n) => !isEmptyOrNil(n))

    if (isEmptyOrNil(filteredLabels)) return;

    return filteredLabels
      .map<React.ReactNode>((t, index) => (
        <React.Fragment key={index}>
          {t}
        </React.Fragment>
      ))
      .reduce((prev, curr) => [prev, <span key={`${-Math.random() * 100}`}>&nbsp;&nbsp;<span className="pipe">{"|"}</span>&nbsp;&nbsp;</span>, curr]);
  }, [merchant]);

  const KioskHeader = () => {
    return (
      <Stack alignItems="center" spacing={1} direction={"row"} className={classes.root}>
        <MerchantAvatar
          merchant={merchant}
        />
        <div>
          <Typography variant={isMobile ? "h6" : "h5"} component="div" fontWeight={600}>
            {merchant.officialName}
          </Typography>
          <If condition={!isEmptyOrNil(renderHeaderSubtitle)}>
            <div className="categoriesWrapper">
              {renderHeaderSubtitle}
            </div>
          </If>
        </div>
      </Stack>
    );
  }

  if (isKioskModeEnabled) {
    return <KioskHeader />;
  }

  const merchantHeaderClasses = classNames(classes.root, {
    [className!]: !isEmptyOrNil(className)
  })

  return (
    <Stack direction={direction} alignItems="flex-start" gap="4px" position={"relative"} className={merchantHeaderClasses}>
      <MerchantAvatar
        merchant={merchant}
        customClass="merchantImage"
      />
      <Stack>
        <Typography
          variant={isMobile ? "h6" : "h5"}
          component="div"
          fontWeight={600}
          lineHeight={1.2} 
          className="merchantHeading"
        >
          {merchant.officialName}
          <Box className="merchant-info">
            <If condition={isEmptyOrNil(cta)}>
              <Then>
                <MerchantInfoCTA buttonType="arrow" merchant={merchant ?? {} as Merchant} />
              </Then>
            </If>
          </Box>
        </Typography>
        <If condition={!isEmptyOrNil(renderHeaderSubtitle)}>
          <div className="categoriesWrapper">
            {renderHeaderSubtitle}
          </div>
        </If>
        <If condition={!isEmptyOrNil(addressLine)}>
          <Typography variant={"subtitle2"} className="address">
            {addressLine}
          </Typography>
        </If>
        <If condition={!isEmptyOrNil(cta) && isMobile}>
          <Then>
            {cta}
          </Then>
        </If>
      </Stack>
      <If condition={!isEmptyOrNil(cta) && !isMobile}>
        <Then>
          {cta}
        </Then>
      </If>
    </Stack>
  );
}

export default MerchantHeader;