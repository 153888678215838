import React from "react";
import { makeStyles, Theme, createStyles, useTheme } from "@material-ui/core";
import { rgba } from "polished";
import PercentageIcon from "../../assets/icons/PercentageIcon";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: "inline-flex",
    alignItems: 'center',
    padding: theme.spacing(1),
    background: rgba(theme.palette.secondary.main, 0.15),
    color: theme.palette.secondary.main,
    fontWeight: 700,
    borderRadius: theme.spacing(1),
    fontSize: '14px',

    "& .tag": {
      background: theme.palette.background.paper,
      fontSize: "10px",
      padding: `${theme.spacing(0.3)} ${theme.spacing(0.8)}`,
      borderRadius: "3px",
    },
  }
}))

const MarketingOneLiner = ({ tag, description }: { tag?: string, description: string }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <PercentageIcon sx={{width: 20, mr: 0.5}} color="secondary"/>
      {tag && <span className="tag">{tag}</span>}
      <div>{description}</div>
    </div>
  );
}

export default MarketingOneLiner;
