import React, { useState, useEffect } from 'react'
import { Alert, Button, Typography } from '@material-ui/core';

import { Order } from 'bos_common/src/types/OrderTypes'

import usePlatformRaffleHook from './PlatformRaffleHook'
import { isEmptyOrNil } from '../../utils';
import { PlatformRaffleDrawer } from './PlatformRaffleDrawer';

const PlatformRaffleCheckInCard = ({ order }: { order?: Order }): JSX.Element | null => {
  const [open, setOpen] = useState<boolean>(false);

  const raffleDataObject = usePlatformRaffleHook({ order })

  const {
    isRaffleCheckedIn = false,
    scannedMerchant,
    loading
  } = raffleDataObject

  useEffect(() => {
    if (isRaffleCheckedIn) {
      setOpen(true)
    }
  }, [isRaffleCheckedIn])


  if (loading || isEmptyOrNil(scannedMerchant)) return null;

  return (
    <Alert severity="info" elevation={3}>
      <Typography variant="body1" >
        Check in at {scannedMerchant!.officialName} to get raffle tickets!
      </Typography>
      <div style={{ marginTop: 8 }}>
        <Button color='primary' variant="contained" onClick={() => setOpen(true)}>Check-in</Button>
      </div>
      <PlatformRaffleDrawer {...raffleDataObject} open={open} setOpen={setOpen} />
    </Alert>
  )
}

export default PlatformRaffleCheckInCard;