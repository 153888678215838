import { AxiosRequestConfig, AxiosResponse } from "axios";
import axios, { backendAPIURL } from "../bos_common/src/services/backendAxios";

export interface VoteRequestBody {
  merchant: string;
}

export const getStoresToVote = (request: AxiosRequestConfig): Promise<AxiosResponse> => { // Promise<AxiosResponse<Order>> => {
  return axios({
    url: '/votes/stores',
    method: 'GET',
    headers: request.headers,
  });
}

export const getVotedStores = (request: AxiosRequestConfig): Promise<AxiosResponse> => { // Promise<AxiosResponse<Order>> => {
  return axios({
    url: '/votes/stores/voted',
    method: 'GET',
    headers: request.headers,
  })
}

export const postVote = (voteBody: VoteRequestBody, request: AxiosRequestConfig): Promise<AxiosResponse> => { // Promise<AxiosResponse<Order>> => {
  return axios({
    url: '/votes',
    method: 'POST',
    data: { ...voteBody },
    headers: request.headers,
  })
}

export const getStoreVotesCount = (merchantIds: string[], request: AxiosRequestConfig): Promise<AxiosResponse> => {
  const url = new URL(`${backendAPIURL}/votes/stores/count`);
  url.searchParams.append('merchantIds', merchantIds.join(','));

  return axios({
    url: url.toString(),
    method: 'GET',
    headers: request.headers,
  })
}

export const getRemainingVotes = (request: AxiosRequestConfig): Promise<AxiosResponse> => {
  return axios({
    url: '/votes/remaining',
    method: 'GET',
    headers: request.headers,
  })
}

export default {
  getStoresToVote,
  getVotedStores,
  postVote,
  getStoreVotesCount,
  getRemainingVotes,
}
