import { utcToZonedTime } from 'date-fns-tz';
import { PromotionValueType, MerchantPromotion } from '../types/MerchantPromotionType';
import { Merchant } from '../types/MerchantType';

export const isPromotionValid = (promotion: MerchantPromotion | null | undefined): boolean => {
  if (!promotion || !promotion.live) {
    console.log('promotion is not live');
    return false;
  }

  if (promotion.limit && promotion.usedCount >= promotion.limit) {
    console.log('promotion limit exceeded');
    return false;
  }

  const isPercentageValueType =
    promotion.valueType === PromotionValueType.PERCENTAGE_DISCOUNT;
  if (isPercentageValueType && promotion.value > 1) {
    console.log('promotion percentage limit incorrect');
    return false;
  }

  const now = new Date();
  if (now < new Date(promotion.startDate) || now > new Date(promotion.endDate)) {
    console.log('promotion expired');
    return false;
  }

  if (promotion.promotionRoutines) {
    const dayOfWeek = now.getDay();
    const minutesOffset = now.getHours() * 60 + now.getMinutes();
    const found = promotion.promotionRoutines?.find((item) => item.dayOfWeek === dayOfWeek);
    if (found) {
      return found.startMinOffset < minutesOffset && minutesOffset < found.endMinOffset;
    }
    return false;
  }

  return true;
};
