import { Logger, LogLevels } from "./loggerTypes"
import config from "../../config/config"

console.log("------------ BROWSER CONSOLE LOG------------ ")
console.log("------------ BROWSER CONSOLE LOG------------ ")
console.log(`config.buildEnv: ${config.appConfig.buildEnv}`)

let userId: string | number | null = null
let userProperties: Record<string, any> | null = null
let deviceId: string | null = null
let version: string | null = null
const tags: Record<string, any> = {}

/**
 * Logger for use in local development
 * Prints everything to the browser console
 */
function consoleLog(
  level: LogLevels,
  message: string | Error,
  data?: { [s: string]: any },
  tags?: { [s: string]: string }
) {
  // Build payload from available properties only
  const payload = {
    ...(data && { data }),
    ...(tags && { tags }),
  }
  console.log(`[${level}]: `, message, { payload })
}

const consoleLogger: Logger = {
  fatal: consoleLog.bind(null, LogLevels.fatal),
  error: consoleLog.bind(null, LogLevels.error),
  warning: consoleLog.bind(null, LogLevels.warning),
  debug: consoleLog.bind(null, LogLevels.debug),
  info: consoleLog.bind(null, LogLevels.info),
  setVersion: _version => (version = _version),
  setDeviceId: _deviceId => (deviceId = _deviceId),
  setUserId: _userId => (userId = _userId),
  setUserProperties: _userProperties => (userProperties = _userProperties),
  setTag: (key: string, value: string | boolean | number | null) => (tags[key] = value),
}

export default consoleLogger
