import React from 'react'
import { SvgIcon } from "@material-ui/core";

function PizzaIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M14.996 10.434c0-2.454-.999-4.79-2.814-6.582C10.437 2.131 8.08 1.075 5.577 1a.409.409 0 00-.403.287L1.02 14.467a.41.41 0 00.492.52l13.176-3.335a.41.41 0 00.309-.377c.008-.17 0-.68 0-.841zM4.552 5.987a.82.82 0 11-.493 1.565l.493-1.565zm6.313 5.786l-1.495.379a.82.82 0 111.495-.379zm1.614-.409l-.796.202a1.642 1.642 0 00-1.634-1.515 1.642 1.642 0 00-1.498 2.308l-6.529 1.653 1.79-5.678A1.643 1.643 0 005.948 6.77c0-.734-.484-1.356-1.149-1.566l.549-1.74c3.943-.12 7.188 3.054 7.188 6.97 0 .463-.057.93-.057.93zm.845-.213c.417-4.561-3.168-8.486-7.717-8.51l.254-.806c4.613.295 8.315 4.09 8.315 8.599l.004.5-.857.217z" />
      <path d="M8.408 5.128c-.904 0-1.64.736-1.64 1.64 0 .904.736 1.64 1.64 1.64.905 0 1.64-.736 1.64-1.64 0-.904-.735-1.64-1.64-1.64zm0 2.46a.821.821 0 010-1.64.821.821 0 010 1.64zM5.948 9.228c-.905 0-1.64.736-1.64 1.64 0 .905.735 1.64 1.64 1.64.904 0 1.64-.735 1.64-1.64 0-.904-.736-1.64-1.64-1.64zm0 2.46a.821.821 0 010-1.64.821.821 0 010 1.64z" />
    </SvgIcon>
  );
}

export default PizzaIcon