import React from 'react';
import Fab from '@material-ui/core/Fab';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import { If } from 'react-if';
import { isEmptyOrNil } from '../../utils';

export interface ReviewFABProps {
  onNext: () => void;
  onBack?: () => void;
  disableNext?: boolean;
  lastItem?: boolean,
  backText?: string,
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '& .reviewFab': {
      '&:first-child': {
        marginRight: '10px'
      }
    },
  }
}));

export const ReviewFAB = (props: Readonly<ReviewFABProps>): JSX.Element => {
  const classes = useStyles();
  const { onNext, onBack, disableNext, lastItem, backText } = props;

  const handleClickNext = () => {
    onNext();
  }

  const handleClickBack = () => {
    if (onBack) {
      onBack();
    }
  }

  return (
    <div className={classes.root}>
      <If condition={!isEmptyOrNil(onBack)}>
        <Fab
          variant="extended"
          color="secondary"
          className='reviewFab'
          sx={{ backgroundColor: '#FFF', color: '#E34D28' }}
          onClick={handleClickBack}
        >
          <ArrowBack sx={{ mr: 1 }} />
          {backText ?? 'Back'}
        </Fab>
      </If>
      <Fab
        variant="extended"
        color="primary"
        className='reviewFab'
        disabled={disableNext}
        onClick={handleClickNext}
        sx={{pl: 4, pr: 4}}
      >
        <If condition={!lastItem}>
          <ArrowForward sx={{ mr: 1 }} />
        </If>
        {lastItem ? 'Done' : 'Next'}
      </Fab>
    </div>
  );
}

export default ReviewFAB;
