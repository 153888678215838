import React from 'react'
import { SvgIcon } from "@material-ui/core";

function ChineseIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M13.95 9.866l.003-.049a1.652 1.652 0 001.297-1.63c0-.453-.177-.874-.488-1.187a1.688 1.688 0 00-.812-2.231 1.688 1.688 0 00-1.526-1.82 1.688 1.688 0 00-2.057-1.187c-.546-.774-1.603-.928-2.39-.408a1.688 1.688 0 00-2.344.408A1.688 1.688 0 003.577 2.95 1.688 1.688 0 002.05 4.769 1.688 1.688 0 001.238 7a1.692 1.692 0 00-.15 2.203c.236.317.581.532.96.614l.002.05a1.688 1.688 0 00-.812 2.23 1.692 1.692 0 00-.15 2.204 1.627 1.627 0 001.75.585 18.587 18.587 0 0110.223-.029c.6.17 1.252-.022 1.663-.492.253-.29.526-.751.526-1.08 0-.453-.177-.874-.488-1.188a1.688 1.688 0 00-.812-2.23zM1.768 8.695a.843.843 0 01.247-1.233.425.425 0 00.112-.63.84.84 0 01.495-1.363.425.425 0 00.32-.555.84.84 0 01.933-1.11.425.425 0 00.49-.413.84.84 0 011.256-.725c.226.125.51.022.603-.219a.84.84 0 011.427-.252c.17.195.472.195.641 0 .38-.436 1.227-.418 1.485.252.092.241.377.344.602.22a.84.84 0 011.256.724.425.425 0 00.49.412.84.84 0 01.932 1.11.425.425 0 00.321.556.84.84 0 01.496 1.362c-.163.2-.11.499.111.632.26.156.415.427.415.724 0 .372-.24.713-.616.798a1.677 1.677 0 00-1.36-.938 1.688 1.688 0 00-2.057-1.187c-.546-.774-1.603-.928-2.39-.409a1.688 1.688 0 00-2.344.409 1.688 1.688 0 00-2.056 1.187 1.686 1.686 0 00-1.36.938.773.773 0 01-.449-.29zm12.316 5.111a.773.773 0 01-.79.234 19.287 19.287 0 00-5.35-.73 19.304 19.304 0 00-5.344.76.773.773 0 01-.832-.277.843.843 0 01.247-1.233.425.425 0 00.112-.631.842.842 0 01.495-1.363.425.425 0 00.321-.555.84.84 0 01.793-1.121c.14 0 .3.065.478-.083a.425.425 0 00.152-.318.84.84 0 011.255-.725c.226.125.51.021.603-.22a.84.84 0 011.427-.251c.17.195.472.194.641 0 .38-.436 1.227-.419 1.485.252.092.24.377.344.602.219a.84.84 0 011.256.725.425.425 0 00.49.412.84.84 0 01.932 1.11.425.425 0 00.321.556.84.84 0 01.496 1.362c-.163.2-.11.498.111.631.256.154.41.42.415.712-.015.072-.12.31-.316.534z" />
      <path d="M11.767 10.055A4.674 4.674 0 008 8.187a4.674 4.674 0 00-3.766 1.868.425.425 0 10.68.51A3.839 3.839 0 018 9.026c1.264 0 2.378.597 3.088 1.54a.425.425 0 00.679-.51zM11.767 4.958A4.674 4.674 0 008 3.09a4.674 4.674 0 00-3.766 1.868.425.425 0 10.68.51A3.839 3.839 0 018 3.928c1.266 0 2.38.599 3.088 1.54a.425.425 0 00.679-.51z" />
    </SvgIcon>
  );
}

export default ChineseIcon