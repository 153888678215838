import React, { useContext, useEffect, useState } from "react";
import { Alert, createStyles, IconButton, makeStyles, Snackbar, Theme } from "@material-ui/core";
import { Share } from "@material-ui/icons";
import { rgba } from "polished";

import { UserContext } from "../../bos_common/src/context/UserContext";
import { Merchant, Order, Merchandise } from "../../services/models";
import { isKioskDevice, isEmptyOrNil } from "../../utils";
import { getMarketPlaceMerchantURL, getMarketPlaceOrderStatusURL } from "../../bos_common/src/services/urls";
import { getAffiliateLink } from "../Order/UserAffiliateBanner";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&.MuiButtonBase-root': {
        backgroundColor: 'white',
        transition: 'all 200ms linear',
        boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.07)",
        '&:hover': {
          backgroundColor: `#FFFFFF66`,
        }
      }
    },
  })
);

interface ShareButtonProps {
  merchant?: Merchant,
  order?: Order,
  merchandise?: Merchandise
}

const ShareButton = (props: ShareButtonProps): React.ReactElement | null => {
  if (isKioskDevice()) {
    return null;
  }

  const { merchant, order, merchandise } = props;
  const { user } = useContext(UserContext);
  const [url, setUrl] = useState('');
  const [openAlert, setOpenAlert] = useState(false)
  const classes = useStyles();

  let title = '';

  useEffect(() => {
    const setLink = async () => {
      if (merchant) {
        if (user && !isEmptyOrNil(user?.affiliateCode))
          setUrl(await getAffiliateLink({ merchant, user, merchandise }))
        else
          setUrl(getMarketPlaceMerchantURL(merchant, user, merchandise));
        title = 'Share the store';
      } else if (order) {
        setUrl(getMarketPlaceOrderStatusURL(order))
        title = 'Share your order';
      }
    }
    setLink();
  }, [merchant, user, merchandise])

  if (isEmptyOrNil(url)) {
    return null;
  }

  const onShareClicked = async () => {
    if (navigator.share) {
      await navigator.share({
        url,
        title,
      });
    } else if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(url)
        setOpenAlert(true);
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleClose = () => {
    setOpenAlert(false);
  }

  return (
    <>
      <IconButton
        className={classes.root}
        aria-label="share"
        onClick={onShareClicked}
      >
        <Share fontSize="small" color="inherit" />
      </IconButton>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openAlert}
        autoHideDuration={3000}
        onClose={handleClose}
        sx={{ zIndex: 9999 }}
        ClickAwayListenerProps={{
          onClickAway: handleClose
        }}>
        <Alert severity="success">
          Link copied to your clipboard
        </Alert>
      </Snackbar>
    </>
  );
};

export default ShareButton;
