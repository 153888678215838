import React, { useMemo } from 'react';
import { Box, CssBaseline, Divider, Drawer, Typography, styled, Theme, } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

// src
import InputSearchBox from '../../bos_common/src/components/InputSearchBox';

import { Merchant } from '../../services/models';
import OneMarketIcon from '../../assets/icons/OneMarketIcon';
import { getSortedMerchantsListBasedOnStatus } from '../../utils';
import StoresListDetailed from './StoresListDetailed';
import { If, Then } from 'react-if';


const drawerBleeding = 56;

type MaterialThemeObject = { theme: Theme }

const Root = styled('div')(({ theme }: MaterialThemeObject) => ({
  zIndex: 0,
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? 'transparent' : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }: MaterialThemeObject) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const StyledDrawer = styled(Drawer)(({ theme }: MaterialThemeObject) => ({
  '&.MuiDrawer-root > .MuiPaper-root': {
    height: `calc(60% - ${drawerBleeding}px)`,
    position: 'relative',
    overflow: 'visible',
    paddingTop: theme.spacing(2),
    borderRadius: theme.spacing(1),
  }
}))

interface StoreSearchDrawerProps {
  stores: Merchant[];
  onChangeQuery: (q: string) => void;
  defaultQuery?: string;
  placeholder?: string;
}

export const StoreSearchDrawer = (props: Readonly<StoreSearchDrawerProps>): JSX.Element => {
  const { stores, onChangeQuery, defaultQuery, placeholder } = props;
  const container = document.body;

  // Sort Merchant: Show online merchants at start
  const filteredStoresList = useMemo(() => getSortedMerchantsListBasedOnStatus(stores || []), [stores]);

  return (
    <Root>
      <CssBaseline />
      <StyledDrawer
        container={container}
        anchor="bottom"
        open={true}
        hideBackdrop
        ModalProps={{
          keepMounted: true,
        }}
        variant="permanent"
      >
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: '100%',
            overflow: 'auto',
          }}
        >
          <InputSearchBox
            defaultValue={defaultQuery}
            onChangeQuery={onChangeQuery}
            placeholder={placeholder}
            leftChild={<OneMarketIcon sx={{ width: 24, height: 24 }} />} />
          <Divider sx={{ margin: '1rem 0' }} />
          <Typography sx={{ fontSize: '0.825rem', fontWeight: 'bold', }} gutterBottom>
            {filteredStoresList.length} results
          </Typography>

          <StoresListDetailed merchants={filteredStoresList} />
        </StyledBox>
      </StyledDrawer>
    </Root>
  );
}

export default StoreSearchDrawer;
