import React, { useContext, useEffect } from "react";
import { Alert, CardContent } from "@material-ui/core";
import { If, Then, Else } from 'react-if';

import { AppContext, CartItemType } from "../../context/AppContext";
import { ShoppingCartContext } from "../../context/ShoppingCart/ShoppingCartContext";

import { ColoredPaper } from 'bos_common/src/components/Papers';
import { UserContext } from "bos_common/src/context/UserContext";

import BackButton from "../../components/common/BackButton";
import ShoppingCartHeader from "../../components/ShoppingCart/ShoppingCartHeader";

import {
  enableRedeemPoints,
  isEmptyOrNil,
  isKioskDevice,
  isStoreOpen,
  toFixed2,
  getRequiredRedemptionPoints,
  isAddingItemToOpenCheck,
  isPaymentDisabled,
  isCartFromAnOpenCheckOrder
} from "../../utils";

import { OrderType } from "../../services/models";
import { getCartDiscountedAmount, getTotalItems } from "../../services/cartUtils";
import PickUpNoPayCart from "./PickupNoPayCart";
import PickupCart from "./PickupCart";
import DineInCart from "./DineInCart";
import { CheckoutMode } from "./types";
import { getFarmersMarketMerchants } from "bos_common/src/services/merchantService";

type ShoppingCartPageProps = {
  checkoutModeOverride?: CheckoutMode,
}

const ShoppingCartPage = ({ checkoutModeOverride = undefined }: ShoppingCartPageProps): React.ReactElement => {
  const { cart, merchantConfig, featureFlags, pickupTime, orderType, cartType, openCheckOrder, coupon, tableQrCodeObject } = useContext(AppContext);
  const { pointsRedeemedInfo, setPointsRedemptionInfo } = useContext(ShoppingCartContext);
  const { user } = useContext(UserContext);
  const merchant = merchantConfig?.merchant;

  const isRedeem1mPointsEnabled = enableRedeemPoints(featureFlags);
  const isKioskModeEnabled = isKioskDevice();

  const isDineInOrderType = orderType === OrderType.DINEIN
  const isFromOpenCheckOrder = isCartFromAnOpenCheckOrder(cartType) || isAddingItemToOpenCheck(cartType);

  const now = new Date();
  const finalPickupTime = pickupTime ? new Date(pickupTime) : now;
  const isValidPickupTime = isFromOpenCheckOrder || (finalPickupTime.getTime() >= now.getTime() && isStoreOpen(merchant, finalPickupTime));
  const isPaymentDisabledVal = isPaymentDisabled(merchant);

  const calculateAmountForRedeemedItems = () => {
    let hasChanged = false;
    let userWalletPoints = user?.points || 0
    let redeemAmount = 0;
    let redeemPoints = 0;
    let couponAmount = getCartDiscountedAmount(cart, coupon);

    const redeemedCartItems = cart.map((item: CartItemType) => {
      const pointsPerItem = getRequiredRedemptionPoints(item, merchant)
      let quantityRedeemed = 0;

      for (let i = 0; i < item.quantity; i++) {
        let remainingPrice = Number(item.price);
        if (couponAmount > 0) {
          // minus coupon values first
          const toRedeemCouponAmount = Math.min(couponAmount, remainingPrice);
          couponAmount = couponAmount - toRedeemCouponAmount;
          remainingPrice = remainingPrice - toRedeemCouponAmount;
        }

        const remainingPointsToRedeem = remainingPrice / item.price * pointsPerItem;
        if (remainingPointsToRedeem > 0) {

          const toRedeemPoints = Math.min(userWalletPoints, remainingPointsToRedeem);
          userWalletPoints -= toRedeemPoints;
          redeemPoints += toRedeemPoints;
          redeemAmount += toRedeemPoints / pointsPerItem * item.price;
          quantityRedeemed += (toRedeemPoints / pointsPerItem);
        }
      }
      return {
        ...item,
        quantityRedeemed: quantityRedeemed,
      }
    });

    if (redeemPoints !== pointsRedeemedInfo.redeemPointsToBeApplied || redeemAmount !== pointsRedeemedInfo.redeemAmount) {
      hasChanged = true;
    }

    if (hasChanged) {
      // This will update the cart, which will call this method again, to avoid infinite loops, we need to check for actual change
      setPointsRedemptionInfo({
        redeemPointsToBeApplied: redeemPoints,
        redeemAmount: toFixed2(redeemAmount),
        items: redeemedCartItems,
      })
    }
  }

  useEffect(() => {
    if (!isEmptyOrNil(user) && !isKioskModeEnabled && isRedeem1mPointsEnabled) {
      calculateAmountForRedeemedItems()
    }
  }, [user, cart, isRedeem1mPointsEnabled, coupon])

  const getTitle = () => {
    if (isAddingItemToOpenCheck(cartType)) {
      return "Add Items to Order";
    }
    if (openCheckOrder) {
      return `Check from ${merchant?.officialName ?? 'Store'}`;
    }
    if (isDineInOrderType) {
      return (
        <div style={{lineHeight: 1}}>
          <div>Shopping Cart</div>
          <span style={{fontSize: 14}}>Table: {tableQrCodeObject?.identifier}</span>
        </div>
      );
    }
    return 'Shopping Cart';
  }

  const title = getTitle();
  const count = getTotalItems(cart);

  const renderCart = () => {
    return (
      <If condition={isDineInOrderType}>
        <Then>
          <DineInCart isValidPickupTime={isValidPickupTime} checkoutModeOverride={checkoutModeOverride} />
        </Then>
        <Else>
          {/* all pick up */}
          <If condition={isPaymentDisabledVal && merchant && (getFarmersMarketMerchants().has(merchant.id))}>
            <Then>
              <PickUpNoPayCart isValidPickupTime={isValidPickupTime} />
            </Then>
            <Else>
              <PickupCart isValidPickupTime={isValidPickupTime} />
            </Else>
          </If>
        </Else>
      </If>
    );
  }

  return (
    <div className="container">
      <ShoppingCartHeader
        leftChild={<BackButton />}
        orderType={orderType}
        title={title}
        merchant={merchant} />
      <ColoredPaper>
        <CardContent className={"content"}>
          <If condition={count === 0}>
            <Then>
              <Alert severity="info">
                {"You don't have any item in your cart"}
              </Alert>
            </Then>
            <Else>
              {renderCart()}
            </Else>
          </If>
        </CardContent>
      </ColoredPaper>
    </div>
  )
}

export default ShoppingCartPage;