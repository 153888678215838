import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { append, dissoc, lensProp, set, propOr, values } from 'ramda';
import {
  Merchandise,
  MerchandiseCategory,
  MerchandiseModifier,
  MerchandiseModifierCategory,
  MerchandiseModifierTemplate,
} from '../../../bos_common/src/types/MerchandiseType';

import { MerchandiseSliceState } from '../../../types/MerchandiseSlice';
import { fetchMerchandises } from './merchandiseActions';

const mapCategoriesToMerchandises = (merchandisesList: Merchandise[]): any => {
  return values(merchandisesList).reduce(
    (acc: string[], item: Merchandise) => {
      return {
        ...acc,
        [`${item.categoryId}`]: append(item.id, propOr([], item.categoryId, acc)),
      };
    },
    {}
  );
}

type MerchandiseSliceCaseReducer = SliceCaseReducers<MerchandiseSliceState>;

export const merchandiseSlice = createSlice<
  MerchandiseSliceState,
  MerchandiseSliceCaseReducer,
  string
>({
  name: 'merchandise',
  initialState: {
    loading: false,
    entities: {
      ids: null,
      byId: {},
    },
    merchandiseCategories: {},
    modifierCategories: {},
    modifierTemplates: {},
    modifiers: {},
    relations: {
      modifierToModifierCategories: {},
      modifierTemplateToMerchandises: {},
      modifierTemplateToModifierCategories: {},
    },
  },
  reducers: {
    setLoadingMerchandise: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    stopLoadingMerchandise: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    addMerchandise: (state, action) => {
      const { payload } = action;
      const ids = [...(state.entities.ids || [])];
      const byId = { ...state.entities.byId };
      const updatedMerchandises = [...values(byId), payload].reduce(
        (acc: string[], item: Merchandise) => {
          return {
            ...acc,
            [item.id]: item,
          };
        },
        {}
      );

      if (ids.indexOf(payload.id) === -1) {
        ids.push(payload.id);
      }

      return {
        ...state,
        entities: {
          ids,
          byId: updatedMerchandises,
        }
      };
    },

    updateMerchandise: (state, action: PayloadAction<Merchandise[]>) => {
      let foundIndex = -1;
      const { payload } = action;
      const byId = { ...state.entities.byId };
      const ids = [...(state.entities.ids || [])];

      payload.forEach((item: Merchandise) => {
        foundIndex = ids.findIndex((id: string) => id === item.id);

        if (foundIndex === -1) {
          ids.push(item.id);
        }

        byId[item.id] = item;
      });

      return {
        ...state,
        loading: false,
        entities: {
          ids,
          byId,
        },
      };
    },

    updateMerchandiseCategories: (state, action: PayloadAction<MerchandiseCategory>) => {
      const { payload } = action;

      let categoriesList = { ...state.merchandiseCategories };
      if (categoriesList[payload.id]) {
        categoriesList[payload.id] = payload
      } else {
        categoriesList = { ...categoriesList, [payload.id]: payload }
      }

      return {
        ...state,
        merchandiseCategories: categoriesList,
      };
    },

    updatedCategoriesList: (state, action) => {
      const { payload } = action;

      const categoriesList = payload.reduce((acc: string[], item: MerchandiseCategory) => {
        return {
          ...acc,
          [item.id]: item,
        };
      }, {});

      return {
        ...state,
        merchandiseCategories: categoriesList,
      };
    },

    deleteCategory: (state, action) => {
      const { payload } = action;

      const categoriesList = values({ ...state.merchandiseCategories });

      const indexOfItemInArray = categoriesList.findIndex(
        (item: MerchandiseCategory) => item.id === payload
      );

      if (indexOfItemInArray !== -1) {
        categoriesList.splice(indexOfItemInArray, 1);
      }

      const merchantCategoriesMap = categoriesList.reduce((acc: string[], item: MerchandiseCategory) => {
        return {
          ...acc,
          [item.id]: item,
        };
      }, {});

      return {
        ...state,
        merchandiseCategories: merchantCategoriesMap,
      };
    },

    swapMerchandiseCategory: (state, action) => {
      const { payload } = action;
      const byId = { ...state.entities.byId };

      const updatedMerchandises = values(byId)
        .map((item: Merchandise) => {
          if (payload.oldCategoryId === item.categoryId) {
            return {
              ...item,
              categoryId: payload.newCategoryId,
            };
          }
          return item;
        })
        .reduce(
          (acc: string[], item: Merchandise) => ({
            ...acc,
            [item.id]: item,
          }),
          {}
        );

      return {
        ...state,
        entities: {
          ids: [...(state.entities.ids || [])],
          byId: updatedMerchandises,
        },
      };
    },

    updateMerchandiseCategory: (state, action) => {
      const { payload } = action;
      const byId = { ...state.entities.byId };

      const updatedMerchandises = values(byId)
        .map((item: Merchandise) => {
          if (payload.id === item.id) {
            return {
              ...item,
              categoryId: payload.categoryId,
            };
          }
          return item;
        })
        .reduce(
          (acc: string[], item: Merchandise) => ({
            ...acc,
            [item.id]: item,
          }),
          {}
        );

      return {
        ...state,
        entities: {
          ids: [...(state.entities.ids || [])],
          byId: updatedMerchandises,
        },
      };
    },

    submitFeaturedCategory: (state, action) => {
      const { payload } = action;
      const byId = { ...state.entities.byId };

      const updatedMerchandises = set(lensProp(payload.id), payload, byId)

      return {
        ...state,
        entities: {
          ids: [...(state.entities.ids || [])],
          byId: updatedMerchandises,
        },
      };
    },

    deleteMerchandise: (state, action) => {
      const { payload } = action;
      const updatedMerchandises = dissoc(payload, { ...state.entities.byId });

      return {
        ...state,
        entities: {
          ids: [...(state.entities.ids || [])],
          byId: updatedMerchandises,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerchandises.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(fetchMerchandises.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(fetchMerchandises.fulfilled, (state, action) => {
        let foundIndex: number;
        const { payload } = action;
        const ids = [...(state.entities.ids || [])];
        const byId = { ...state.entities.byId };
        const merchandiseCategories = { ...state.merchandiseCategories };
        const modifierCategories = { ...state.modifierCategories };
        const modifierTemplates = { ...state.modifierTemplates };
        const modifiers = { ...state.modifiers };
        const modifierToModifierCategories = { ...state.relations.modifierToModifierCategories };
        const modifierTemplateToMerchandises = {
          ...state.relations.modifierTemplateToMerchandises,
        };
        const modifierTemplateToModifierCategories = {
          ...state.relations.modifierTemplateToModifierCategories,
        };

        payload?.merchandises.forEach((item: Merchandise) => {
          foundIndex = ids.findIndex((id: string) => item.id === id);

          if (foundIndex === -1) {
            ids.push(item.id);
          }

          byId[item.id] = item;
        });

        payload?.mercCategories.forEach((item: MerchandiseCategory) => {
          merchandiseCategories[item.id] = item;
        });

        payload?.modiCategories.forEach((item: MerchandiseModifierCategory) => {
          modifierCategories[item.id] = item;
        });

        payload?.modiTemplates.forEach((item: MerchandiseModifierTemplate) => {
          modifierTemplates[item.id] = item;
          modifierTemplateToMerchandises[item.id] = payload.merchandises
            .filter(
              (merchandiseItem: Merchandise) => merchandiseItem.modifierTemplateId === item.id
            )
            .map((merchandiseItem: Merchandise) => merchandiseItem.id);

          modifierTemplateToModifierCategories[item.id] = payload.modiTemplates
            .filter((modCatItem: MerchandiseModifierTemplate) =>
              modCatItem.modifierCategoryIds.includes(item.id)
            )
            .map((modCatItem: MerchandiseModifierTemplate) => `${modCatItem.id}`);
        });

        payload?.modifiers.forEach((item: MerchandiseModifier) => {
          modifiers[item.id] = item;
          modifierToModifierCategories[item.id] = payload.modiCategories
            .filter((modCatItem: MerchandiseModifierCategory) =>
              modCatItem.modifierIds.includes(item.id)
            )
            .map((modCatItem: MerchandiseModifierCategory) => `${modCatItem.id}`);
        });

        return {
          ...state,
          loading: false,
          entities: {
            byId,
            ids,
          },
          merchandiseCategories,
          modifierCategories,
          modifierTemplates,
          modifiers,
          relations: {
            modifierToModifierCategories,
            modifierTemplateToMerchandises,
            modifierTemplateToModifierCategories,
          },
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoadingMerchandise,
  stopLoadingMerchandise,
  updateMerchandise,
  updateMerchandiseCategories,
  deleteCategory,
  swapMerchandiseCategory,
  submitFeaturedCategory,
  updatedCategoriesList
} = merchandiseSlice.actions;

export default merchandiseSlice.reducer;
