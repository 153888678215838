import React from "react";
import {
  Dialog,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import { If } from "react-if";
import classNames from "classnames";

import { isEmptyOrNil } from "../utils";
import ContentWithFloatingVisual from "./common/ContentWithFloatingVisual";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiPaper-root": {
        [theme.breakpoints.down("md")]: {
          margin: 0,
          maxHeight: "100%"
        }
      },

      "& .closeButton": {
        position: "absolute",
        top: 40,
        right: 40,
        zIndex: 10,
        padding: theme.spacing(1.2),
        color: theme.palette.info.light,
        textTransform: 'unset',
        background: '#ededed',

        [theme.breakpoints.down('sm')]: {
          position: "fixed",
          top: 10,
          right: 10,
          padding: theme.spacing(0.5),
          backgroundColor: "#ededed"
        }
      },
    },

    dialogCard: {
      padding: `${theme.spacing(3)} ${theme.spacing(1.8)}`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.07)",
      borderRadius: "20px",
      textAlign: 'center',
      minHeight: 115,

      '& .cardHeading': {
        fontSize: 18,
        fontWeight: 700,
        margin: 0,
        marginBottom: theme.spacing(0.5),
        color: theme.palette.info.light,
      },

      '& .cardDescription': {
        fontSize: 14,
        fontWeight: 500,
        margin: 0,
        marginBottom: theme.spacing(0.5),
        color: theme.palette.secondary.light,
      },

      '& .cardIcon': {
        marginBottom: theme.spacing(2),
      }
    }
  })
);

type DialogCardProps = {
  heading: string | React.ReactNode;
  description?: string;
  icon: React.ReactNode;
}

export const DialogCard = (props: DialogCardProps) => {
  const { icon, heading, description } = props;

  const classes = useStyles();

  return (
    <div className={classes.dialogCard}>
      <div className="cardIcon">
        {icon}
      </div>
      <h3 className="cardHeading">{heading}</h3>
      <If condition={!isEmptyOrNil(description)}>
        <p className="cardDescription">{description}</p>
      </If>
    </div>
  )
}

type LearnMoreDialogProps = {
  isOpen: boolean;
  onCancel: () => void;
  children?: React.ReactElement | React.ReactElement[];
  illustration?: string | React.ReactNode;
  heading?: string | React.ReactNode;
  subHeadings?: string[];
  title?: string;
  description?: string;
  cardsHeading?: string | React.ReactNode;
  isMultiColumn?: boolean;
  className?: string;
  cardsList?: Array<{
    icon: React.ReactNode;
    heading: string;
    description?: string;
  }>;
};

const LearnMoreDialog = (props: LearnMoreDialogProps) => {
  const { className, isOpen, onCancel, ...rest } = props;

  const classes = useStyles();

  const dialogClasses = classNames(classes.root, {
    [className!]: !isEmptyOrNil(className)
  })

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      maxWidth="lg"
      className={dialogClasses}
      sx={{ margin: 0, maxHeight: "unset" }}
    >
      <ContentWithFloatingVisual
        {...rest}
      />
    </Dialog>
  );
};

export default LearnMoreDialog;
