import { Order } from "../services/models";

export enum NotificationSeverity {
  SUCCESS = "success",
  WARNING = "warning",
  INFO = "info",
  ERROR = "error"
}

export type NotificationBody = {
  open: boolean,
  bodyText: string,
  severity: NotificationSeverity,
  hideAction: boolean
}

export type Notification = {
  ts: number,
  order?: Order | null,
  notificationBody?: NotificationBody | null,
}
