import React from "react";
import { Stack, Skeleton } from "@material-ui/core";

const MerchantHeaderSkeleton = (): JSX.Element => {

  return (
    <Stack gap="4px">
      <Skeleton sx={{
        width: 64,
        height: 64,
        borderRadius: .5
      }} />
      <Skeleton sx={{ width: '195px' }} />
      <Skeleton sx={{ width: '195px' }} />
      <Skeleton sx={{ width: '195px' }} />
    </Stack>
  );
}

export default MerchantHeaderSkeleton;