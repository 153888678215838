import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Alert, Box, Typography, Paper, TextField, CardContent } from '@material-ui/core';
import { If } from 'react-if';
import * as EmailValidator from 'email-validator';

import { Rating, Review, ReviewType } from '../../bos_common/src/types/ReviewType';
import SimpleLoader from '../../bos_common/src/components/SimpleLoader';
import { LineItem } from '../../bos_common/src/types/OrderTypes';

import { fetchOrderDetail } from '../../redux/slice/order/orderAction';
import { getOrder } from '../../redux/slice/order/orderSelector';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { createReview, hideEarnRewardsDrawerUI, showEarnRewardsDrawerUI } from '../../redux/slice/review/reviewAction';
import { getReviewLoadingStatus, getReviewsByOrder, getReviewUpdatingStatus, isEarnRewardsDrawerShown } from '../../redux/slice/review/reviewSelector';

import ReviewFAB from '../../components/Review/ReviewFAB';
import RatingField from '../../components/Review/RatingField';
import { isEmptyOrNil, lineItemWithoutCarryoutBag, pluralString } from '../../utils';
import { getMerchant } from '../../redux/slice/merchant/merchantSelector';
import RewardsEarnedDrawer from './RewardsEarnedDrawer';
import { UserContext } from 'bos_common/src/context/UserContext';
import { User } from 'bos_common/src/types/UserTypes';

export interface ReviewMerchandiseProps {
  merchandiseId: string;
  onNext: (lineItem?: Partial<LineItem>) => void;
  onBack: () => void;
  onDone: () => void;
}

export const ReviewMerchandise = (props: Readonly<ReviewMerchandiseProps>): JSX.Element => {
  const { merchandiseId, onNext, onBack, onDone } = props;
  const history = useHistory();
  const location = useLocation<{ from: { pathname: string } }>();
  const match = useRouteMatch();
  const orderId = match.params['orderId'] as string;

  const reduxDispatch = useAppDispatch();
  const loading = useAppSelector(getReviewLoadingStatus);
  const updating = useAppSelector(getReviewUpdatingStatus);
  const order = useAppSelector(getOrder)(orderId);
  const reviews = useAppSelector(getReviewsByOrder)(order?.id);
  const merchant = useAppSelector(getMerchant)(order?.merchantId);
  const earnRewardsDrawerShown: boolean = useAppSelector(isEarnRewardsDrawerShown);
  //const user = useAppSelector(getUser);
  const { user, refreshUser } = useContext(UserContext);

  const lastItemToReview = order
    && order.lineItems.filter((item) => lineItemWithoutCarryoutBag(item, merchant)).length - 1 === reviews.length;

  const [rating, setRating] = useState<Rating>({
    taste: 0,
    portion: 0,
    value: 0,
    overall: 0,
  });
  const [comment, setComment] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const lineItem = useMemo(() => {
    return order?.lineItems.find((item) => item.id === merchandiseId);
  }, [order]);

  useEffect(() => {
    if (!order) {
      reduxDispatch(fetchOrderDetail(orderId));
    }
  }, [orderId]);

  const handleChangeRating = (ratingField: string, value: number) => {
    setRating({
      ...rating,
      [ratingField]: value,
    });
  }

  const handleReviewNext = () => {
    try {
      validate();

      const newUser = {...user};
      if (!isEmptyOrNil(name)) {
        newUser.displayName = name;
      }
      if (!isEmptyOrNil(email)) {
        newUser.email = email;
      }

      const newReview: Partial<Review> = {
        order: orderId as any,
        orderId,
        merchandise: merchandiseId as any,
        merchant: order.merchantId as any, // For TypeORM compatibility, should find better way for this.
        rating,
        comment,
        email,
        user: newUser as any,
        reviewType: ReviewType.MERCHANDISE,
      };

      reduxDispatch(createReview(newReview))
        .then(() => {
          if (lastItemToReview && user) {
            reduxDispatch(showEarnRewardsDrawerUI());
          } else {
            refreshUser();
            onNext();
          }
        });
    } catch (exception) {
      const { message } = (exception as Error);
      setErrorMessage(message);
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  }

  const handleConfirmClose = () => {
    reduxDispatch(hideEarnRewardsDrawerUI());
    onDone();
    location.state?.from
      ? history.goBack()
      : history.push(`/order/${orderId}`);
  }

  const validate = () => {
    const commentWordsCount = comment.trim().split(' ').length;

    if (rating.overall === 0 || rating.portion === 0 || rating.taste === 0 || rating.value === 0) {
      throw new Error("Please provide a rating for all categories");
    }

    if (commentWordsCount < 10) {
      throw new Error("Please write at least 10 words in your comment");
    }

    if (commentWordsCount > 500) {
      throw new Error("Please restrict your comment to 500 words");
    }

    if (isEmptyOrNil(user?.email)) {
      if (isEmptyOrNil(email)) {
        throw new Error("Please enter your email");
      }
      if (!EmailValidator.validate(email)) {
        throw new Error("Please enter a valid email");
      }
    }

    if (isEmptyOrNil(user?.displayName)) {
      if (isEmptyOrNil(name)) {
        throw new Error("Please enter your name");
      }
    }
  }

  const remainingWordCount = React.useMemo(() => {
    if (isEmptyOrNil(comment)) {
      return 10;
    } else {
      const diff = 10 - comment.trim().split(' ').length;
      return diff <= 0 ? 0 : diff
    }
  }, [comment]);

  return (
    <Box>
      <SimpleLoader loading={loading || updating} />
      <If condition={errorMessage}>
        <CardContent>
          <Alert severity="error">{errorMessage}</Alert>
        </CardContent>
      </If>

      <div>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Select your ratings
          </Typography>

          <Paper elevation={2} sx={{mt: 2, mb: 2}}>
            <RatingField label='Taste' fieldName='taste' value={rating.taste} onChangeRating={handleChangeRating} />
            <RatingField label='Portion size' fieldName='portion' value={rating.portion} onChangeRating={handleChangeRating} />
            <RatingField label='Value for money' fieldName='value' value={rating.value} onChangeRating={handleChangeRating} />
            <RatingField label='Overall Experience' fieldName='overall' value={rating.overall} onChangeRating={handleChangeRating} />
          </Paper>

          <Paper elevation={2} style={{ marginBottom: '1rem' }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              multiline
              rows={3}
              fullWidth
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder={`How did you like ${merchant?.officialName ?? merchant?.username}'s ${lineItem?.name}?`}
              helperText={remainingWordCount > 0 ? `${pluralString(remainingWordCount, 'more word')} needed` : ''}
            />
          </Paper>

          <If condition={isEmptyOrNil(user?.email)}>
            <Paper elevation={2} style={{ marginBottom: '1rem' }}>
              <TextField
                required
                fullWidth
                placeholder="Email"
                name="email"
                label="Email"
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Paper>
          </If>

          <If condition={isEmptyOrNil(user?.displayName)}>
            <Paper elevation={2} style={{ marginBottom: '1rem' }}>
              <TextField
                required
                fullWidth
                placeholder="Name"
                name="name"
                label="Display name"
                value={name}
                type="text"
                onChange={(e) => setName(e.target.value)}
              />
            </Paper>
          </If>

          <ReviewFAB onNext={handleReviewNext} onBack={onBack} lastItem={lastItemToReview} disableNext={updating || loading}/>
        </CardContent>

      </div>
      <RewardsEarnedDrawer
        onConfirmClose={handleConfirmClose}
        reviewsLength={reviews.length}
        open={earnRewardsDrawerShown}
        setOpen={() => reduxDispatch(showEarnRewardsDrawerUI())} />
    </Box>
  );
}

export default ReviewMerchandise;
