import React, { useContext, useEffect, useMemo } from "react";
import {
  Button,
  createStyles,
  Fab,
  makeStyles,
  Theme,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { pathOr } from "ramda";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import { If, Then } from "react-if";

// src
import { UserContext } from "../../bos_common/src/context/UserContext";
import { MerchantPromotion, PromotionCriteriaType, PromotionValueType } from "../../bos_common/src/types/MerchantPromotionType";
import { isCouponValid, isValidExpiryDate } from "../../bos_common/src/services/CouponUtils";
import { Merchandise, Merchant, MerchantCoupon } from "../../services/models";
import { getPromotionChipStyleConfig, isUserEligibleForPromotion } from "../../utils";
import CustomDrawer from "../CustomDrawer";
import PromotionDialog from "./PromotionDialog";
import PromotionIllustration from "../../assets/images/congratulations-rewards.svg"
import RewardIllustration from "../../assets/images/give-rewards.png"
import { useWindowStorage } from "bos_common/src";
import { LOCALSTORAGE_MARKETPLACE_APP_KEYS } from "../../services/localStorage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
      padding: theme.spacing(2),

      "&.sticky": {
        zIndex: 10,
        position: "sticky",
        top: "68px",
        background: theme.palette.common.white,
      },

      "& .promotion-button": {
        color: theme.palette.common.white,
        backgroundColor: "#F7932E",
        textTransform: 'unset',
        '& .button-title': {
          whiteSpace: "nowrap",
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: 'block',
          textAlign: 'left',
        },
        "&:hover": {
          backgroundColor: "#F7932E",
        },
        "&.mobile .button-title": {
          width: 'calc(100vw - 72px)',
        },
        "&.non-mobile .button-title": {
          maxWidth: '250px',
        },
    },
    },
  })
);

type PromoData = {
  coupon?: MerchantCoupon,
  promotion?: MerchantPromotion,
}

type PromotionButtonProps = {
  merchant?: Merchant;
  merchandises?: Merchandise[];
  coupon?: MerchantCoupon;
};

const PromotionButton = (props: PromotionButtonProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const { merchant, merchandises, coupon } = props;
  const location = useLocation<{ promotion: MerchantPromotion }>()
  const { user } = useContext(UserContext);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  /**
   * Priorities:
   *   promotion in props > promotion in location state > promotion from merchant
   */

  const validDeals = useMemo(() => {
    // gather all merchant promotions
    const candidates: PromoData[] = merchant?.promotions?.map((promotion) => ({
      coupon: promotion?.coupons?.find((c: MerchantCoupon) => isCouponValid(c)),
      promotion,
    })) || [];
    // sort by createdAt date
    candidates.sort((a, b) => (a.promotion?.createdAt || 0) < (b.promotion?.createdAt || 0) ? 1 : -1 )
    // move the highlighted to the front
    const idx = candidates.findIndex((ele) => ele.promotion?.id === location.state?.promotion?.id);
    if (idx !== -1) {
      const tmp = candidates[0];
      candidates[0] = candidates[idx];
      candidates[idx] = tmp;
    }
    // remove ones that should be hidden
    return candidates.filter((candidate: PromoData) => {
      const allCriteria = [
        ...(candidate.coupon?.criteriaList || []),
        {
          type: candidate.coupon?.criteriaType || PromotionCriteriaType.NONE,
          value: candidate.coupon?.criteriaValue,
        },
        {
          type: candidate.promotion?.criteriaType || PromotionCriteriaType.NONE,
          value: undefined,
        }
      ];
      const isCouponEligible = candidate.coupon && isUserEligibleForPromotion(allCriteria, user);
      const isPromotionEligible = candidate.promotion && isUserEligibleForPromotion(allCriteria, user);
      let hidePromotion = false;
      switch (candidate.promotion?.criteriaType) {
        case PromotionCriteriaType.REFERRAL_TYPE:
          hidePromotion = true;
          break;
      }

      return (!hidePromotion && (isCouponEligible || isPromotionEligible));
    })
  }, [merchant, merchandises, user]);

  const {coupon: couponFinal, promotion: promotionFinal} = pathOr({}, [0], validDeals);
  const storageKey = LOCALSTORAGE_MARKETPLACE_APP_KEYS.PROMO_MERCHANT_DRAWER1;
  const [isPromotionDrawerOpen, setPromotionDrawerOpen] = useWindowStorage(storageKey, !!promotionFinal, 'sessionStorage');
  const [isPromotionDialogOpen, setPromotionDialogOpen] = useWindowStorage(storageKey, !!promotionFinal, 'sessionStorage');

  const finalTitle = couponFinal ? couponFinal.title : promotionFinal?.oneliner;
  const finalDescription = couponFinal ? couponFinal.description : promotionFinal?.description;
  if ((!couponFinal && !promotionFinal) || !finalTitle) {
    return (<></>);
  }

  const finalImage = promotionFinal?.criteriaType === PromotionCriteriaType.REFERRAL_TYPE
    ? RewardIllustration
    : PromotionIllustration;

  const DialogProps = {
    title: finalTitle,
    description: finalDescription||'',
    promotionImage: finalImage,
  }

  const DialogContent = couponFinal && (
    <Typography fontSize={isMobile ? "14px" : "16px"} color={theme.palette.grey[600]}>
      Coupon <b>{couponFinal.code}</b> will be automatically applied to your next order at {merchant?.officialName}.<br />
      <If condition={isValidExpiryDate(couponFinal.endDate)}>
        <Then>
          Valid until <b>{format(new Date(couponFinal.endDate), 'MMM d, p')}</b>.
        </Then>
      </If>
    </Typography>
  )

  const { icon: PromotionIcon } = getPromotionChipStyleConfig(
    couponFinal?.valueType || promotionFinal?.valueType || PromotionValueType.ABSOLUTE_DISCOUNT,
  );

  return isMobile ? (
    <div className={`${classes.root} sticky`}>
      <Button
        className="promotion-button mobile"
        onClick={() => setPromotionDrawerOpen(true)}>
        {<PromotionIcon sx={{color: "white", width: 20, mr: 0.5}} />}
        <span className="button-title">{finalTitle}</span>
      </Button>
      <CustomDrawer {...DialogProps} isOpen={isPromotionDrawerOpen} setIsOpen={setPromotionDrawerOpen}>
        {DialogContent}
      </CustomDrawer>
    </div>
  ) : (
    <span className={classes.root}>
      <Fab
        variant="extended"
        className="promotion-button non-mobile"
        onClick={() => setPromotionDialogOpen(true)}
      >
        {<PromotionIcon sx={{ width: 22, height: 22, color: "white" }} />}
        <span className="button-title">{finalTitle}</span>
      </Fab>
      <PromotionDialog {...DialogProps} isOpen={isPromotionDialogOpen} setOpen={setPromotionDialogOpen}>
        <>{DialogContent}</>
      </PromotionDialog>
    </span>
  );
};

export default PromotionButton;