export enum EVENT_CATEGORIES {
  MERCHANT_MENU = "MERCHANT_MENU",
  MERCHANT_STORE_FRONT = "MERCHANT_STORE_FRONT",
  MERCHANT_STORY = "MERCHANT_STORY",
  SHOPPING_CART = "SHOPPING_CART",
  ORDER_CHECKOUT = "ORDER_CHECKOUT",
  ORDER_STATUS = "ORDER_STATUS",
  MERCHANDISE = "MERCHANDISE", // NOT USED
  GLOBAL = "GLOBAL",
  MERCHANT_DISCOVERY = "MERCHANT_DISCOVERY",
  SAN_MATEO_DISCOVERY = "SAN_MATEO_DISCOVERY",
  ONE_MARKET_POINTS = "ONE_MARKET_POINTS",
  HAMBURGER_MENU = 'HAMBURGER_MENU', 
  MERCHANT_CHAT = 'MERCHANT_CHAT', 
}

export enum EVENT_ACTIONS {

  // MERCHANT_MENU ACTIONS
  VISITED_MENU = "VISITED_MENU",
  CLICK_ITEM_DETAILS = "CLICK_ITEM_DETAILS",
  CLICK_VISIT_SHOPPING_CART = "CLICK_VISIT_SHOPPING_CART",

  // MERCHANT_STORE_FRONT ACTIONS
  CLICK_INFO = "CLICK_INFO",
  CLICK_POPULAR_ITEMS = 'CLICK_POPULAR_ITEMS',
  CLICK_POPULAR_ITEMS_SEE_MORE = 'CLICK_POPULAR_ITEMS_SEE_MORE',
  CLICK_SEEMORE_LOCALBUZZ = 'CLICK_SEEMORE_LOCALBUZZ',
  CLICK_SEEMORE_REVIEWS = 'CLICK_SEEMORE_REVIEWS',
  CLICK_REVIEW_ITEMS = 'CLICK_REVIEW_ITEMS',
  CLICK_CREDIBLE_MENTIONS = 'CLICK_CREDIBLE_MENTIONS',
  CLICK_PICKUP = "CLICK_PICKUP",
  CLICK_RESERVE = "CLICK_RESERVE",
  CLICK_PROMOTION_CARD = "CLICK_PROMOTION_CARD",

  // MERCHANT_STORY ACTIONS
  CLICK_FEED_FOLLOW = "CLICK_FEED_FOLLOW",
  CLICK_FEED_FOLLOW_COMPLETE = "CLICK_FEED_FOLLOW_COMPLETE",
  CLICK_FEED_POST = "CLICK_FEED_POST",
  CLICK_FEED_ITEM = "CLICK_FEED_ITEM",
  CLICK_FEED_EXTERNAL_LINK = "CLICK_FEED_EXTERNAL_LINK",

  // CART ACTIONS
  CLICK_GOTO_CART = "CLICK_GOTO_CART",
  CLICK_ITEM_ADDED = "CLICK_ITEM_ADDED",
  CLICK_ITEM_REMOVED = 'CLICK_ITEM_REMOVED',
  CLICK_APPLY_1M_POINTS = "CLICK_APPLY_1M_POINTS",
  CLICK_UNAPPLY_1M_POINTS = "CLICK_UNAPPLY_1M_POINTS",
  CLICK_SCHEDULE_ORDER = 'CLICK_SCHEDULE_ORDER',

  // ORDER CHECKOUT ACTIONS

  CLICK_OPEN_CHECK_ORDER_UPDATED = "CLICK_OPEN_CHECK_ORDER_UPDATED",
  CLICK_OPEN_CHECK_ORDER_UPDATE_FAILED = "CLICK_OPEN_CHECK_ORDER_UPDATE_FAILED",

  CLICK_ORDER_CHECKOUT_START = "CLICK_ORDER_CHECKOUT_START",
  CLICK_ORDER_CHECKOUT_ADD_PAYMENT = "CLICK_ORDER_CHECKOUT_ADD_PAYMENT",
  CLICK_ORDER_CHECKOUT_PLACE_ORDER = "CLICK_ORDER_CHECKOUT_PLACE_ORDER",

  VISITED_ORDER_CHECKOUT_COMPLETED = "VISITED_ORDER_CHECKOUT_COMPLETED",
  VISITED_ORDER_CHECKOUT_FAILED = "VISITED_ORDER_CHECKOUT_FAILED",
  // ORDER_STATUS ACTIONS
  VISITED_ORDER_STATUS = 'VISITED_ORDER_STATUS',

  // GLOBAL ACTIONS
  VISITED_1M_APP = "VISITED_ONE_MARKET",
  // MERCHANT_DISCOVERY
  VISITED_MAP_DISCOVERY = "VISITED_MAP_DISCOVERY",
  CLICK_MERCHANT_STORE_FRONT = "CLICK_MERCHANT_STORE_FRONT",

  // SAN_MATEO_DISCOVERY
  VISITED_SAN_MATEO = "VISITED_SAN_MATEO_MARKETPLACE",

  // ONE_MARKET_POINTS
  VISITED_1M_POINTS = 'VISITED_1M_POINTS',

  // HAMBURGER MENU
  CLICK_LOGIN = "CLICK_LOGIN",

  // MERCHANT CHAT
  BOOK_CLASS = "BOOK_CLASS",
  MERCHANT_INFO_CTA = "MERCHANT_INFO_CTA",
  READ_MORE_CTA = "READ_MORE_CTA", 
}