import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Order } from "../../../bos_common/src/types/OrderTypes";
import { Review } from "../../../bos_common/src/types/ReviewType";
import { getReviewsForOrder, postReview } from "../../../services/reviewsApiService";
import { getAuthHeaders } from "../../../utils";
import { RootState } from "../../store";
import { getToken } from "../auth/authSelector";

export const reviewsFetching = createAction('review/setLoading');

export const reviewsFetchSucceed = createAction<Review[]>('review/updateReview');

export const reviewsFetchFailed = createAction('review/stopLoading');

export const hideEarnRewardsDrawerUI = createAction('review/hideEarnRewards');

export const showEarnRewardsDrawerUI = createAction('review/showEarnRewards');

export const fetchReviewsForOrder = createAsyncThunk(
  'review/fetchReviewForOrder',
  async (order: Order, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const token = getToken(state);
    const response = await getReviewsForOrder(order.id, { headers: getAuthHeaders(token) });

    return response.data;
  }
);

export const createReview = createAsyncThunk(
  'review/createReview',
  async (review: Partial<Review>, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const token = getToken(state);
    const response = await postReview({ review }, { headers: getAuthHeaders(token) });
    return [response.data];
  }
);
