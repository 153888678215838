import React from "react";
import classNames from "classnames";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

import RewardsInfoContent from "./RewardsInfoContent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      "&.drawerContent": {
        height: "90vh",
        overflow: "auto",
        [theme.breakpoints.up("sm")]: {
          height: "75vh",
        },

        "& .multiColumn": {
          maxWidth: 1280,
          width: "100%",
          margin: "auto",
        }
      },

      "& .drawerContent": {
        padding: theme.spacing(3),

        "& .blockImg": {
          width: "100%",
          objectFit: "contain",
          height: 250,
          objectPosition: "left bottom",
        },
      },
    },
  })
);

const RewardsEducationInfo = ({ openOnDrawer }: { openOnDrawer?: boolean }) => {
  const classes = useStyles();

  const containerClassNames = classNames(classes.drawer, {
    'drawerContent': openOnDrawer
  });

  return (
    <div className={containerClassNames}>
      <RewardsInfoContent />
    </div>
  );
};

export default RewardsEducationInfo;
