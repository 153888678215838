import React from "react";
import { Avatar, Theme, Typography } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import classNames from "classnames";
import { If } from "react-if";
import ReactMarkdown from "react-markdown";
import remarkGFM from "remark-gfm";

import { isEmptyOrNil } from "../../utils";
import { AvatarPaper } from "../../bos_common/src";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.background.paper,
      borderRadius: 20,
      padding: theme.spacing(2, 2),
      margin: theme.spacing(0, 2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      "&.secondary": {
        background: "#FFF5F0",
      },

      "& .merchantContent": {
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        gridGap: theme.spacing(1),
      },

      "& .ownerThumbnailContainer": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "45px",
        height: "45px",

        "& .ownerThumbnail": {
          width: theme.spacing(5),
          height: theme.spacing(5),
          borderRadius: "100%",
        },
      },

      "& .heroTitle": {
        fontWeight: 800,
        color: theme.palette.primary.main,
      },
    },
  })
);

interface CustomImageTag {
  src: string,
  alt: 'story-image' | 'video' | string,
  title?: string
}
const CustomImageTag = (props: CustomImageTag) => {
  const { alt, src } = props

  const handleDragStart = (e: React.DragEvent<HTMLElement>) => e.preventDefault();

  switch (alt) {
    case 'video':
      return (
        <video width="400" height="300" controls onDragStart={handleDragStart}>
          <source src={src} type="video/mp4" />
          <source src={src} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      )
    default:
      return (<img alt={alt} src={src} />)
  }
}

interface StoryContentCardProps {
  type?: "primary" | "secondary";
  image?: string;
  title?: string;
  subTitle?: string;
  description: string;
  showImage?: boolean;
}
const StoryContentCard = (props: StoryContentCardProps) => {
  const { showImage = false, image, type = "primary", title, subTitle, description } = props;

  const classes = useStyles();

  const rootClasses = classNames(classes.root, {
    secondary: type === "secondary",
  });

  const logoSrc = !isEmptyOrNil(image) ? image : "/user-avatar.png";
  const markdownCustomComponents = {
    // eslint-disable-next-line react/display-name
    img: ({ node, ...props }: { node: any, props?: CustomImageTag }) => <CustomImageTag src={""} alt={""} {...props} />
  }

  return (
    <div className={rootClasses}>
      <div className="merchantContent">
        <If condition={showImage}>
          <AvatarPaper className='ownerThumbnailContainer'>
            <Avatar alt="image" className="ownerThumbnail" src={logoSrc} />
          </AvatarPaper>
        </If>

        <div className="merchantInfoWrapper">
          <If condition={!isEmptyOrNil(title)}>
            <Typography
              variant="h6"
              className="heroTitle"
            >
              {title}
            </Typography>
          </If>

          <If condition={!isEmptyOrNil(subTitle)}>
            <Typography
              variant="subtitle2">
              {subTitle}
            </Typography>
          </If>
        </div>
      </div>

      <ReactMarkdown components={markdownCustomComponents} plugins={[remarkGFM]}>
        {description}
      </ReactMarkdown>
    </div>
  );
};

export default StoryContentCard;
