import React, { useContext, useState } from "react"
import { Switch, Case, Default, If } from "react-if"
import { useHistory } from "react-router-dom"
import { Fab, Button, Alert, SwipeableDrawer, Toolbar, Typography, CardContent, Divider } from "@material-ui/core"

import { isEmptyOrNil, ColoredPaper } from "bos_common/src"
import { MultiFabContainer } from "bos_common/src/components/FabContainers"
import renderPrice from "bos_common/src/components/Price"
import { UserContext } from "bos_common/src/context/UserContext"
import { isCouponEnabled } from "bos_common/src/services/featureFlagUtils"

import CartItems from "../../components/ShoppingCart/CartItems"
import CartSummary from "../../components/ShoppingCart/CartSummary"
import PointsRedemptionButton from "../../components/ShoppingCart/PointsRedemptionButton"
import ShoppingCartCouponInput from "../../components/ShoppingCart/ShoppingCartCouponInput"
import TipOptions from "../../components/ShoppingCart/TipOptions"
import { SignupEntrance } from "../../components/User/SignupDrawer"
import { AppContext, CartType } from "../../context/AppContext"
import { ShoppingCartContext } from "../../context/ShoppingCart/ShoppingCartContext"
import { getTotalItems, getTotalPrice } from "../../services/cartUtils"
import eventTrackingService from "../../services/Tracking"
import { EVENT_CATEGORIES, EVENT_ACTIONS } from "../../services/Tracking/events"
import { isOpenCheckEnabled, isPaymentDisabled, isAddingItemToOpenCheck } from "../../utils"
import { getEventLabel } from "../../services/Tracking/EventsTracking"
import { Merchant } from "../../services/models"
import { useAppDispatch } from "../../redux/hooks"
import { showSignUpDrawer } from "../../redux/slice/auth/authActions"
import { CheckoutMode } from "./types"

const DineInCart = (props: {
  isValidPickupTime: boolean,
  checkoutModeOverride?: CheckoutMode
}) => {
  const { isValidPickupTime, checkoutModeOverride } = props;
  const history = useHistory();
  const { cart, merchantConfig, tip, coupon, featureFlags, cartType } = useContext(AppContext)
  const { soldoutItems, clearSoldOutItems } = useContext(ShoppingCartContext)
  const [showPayOptionsDrawer, togglePayOptionsDrawer] = useState<boolean>(false)
  const { user } = useContext(UserContext)
  const reduxDispatch = useAppDispatch();

  const count = getTotalItems(cart)
  const total = getTotalPrice({ cart, tip, coupon, orderingConfig: merchantConfig?.merchant.orderingConfig })
  // ignore invalid pick up check for dine ins
  const isDisabled = count < 1;


  // A new cart, for an open check order
  const isNewOpenCheckCart = checkoutModeOverride === undefined && isOpenCheckEnabled(merchantConfig?.merchant);
  const isPaymentDisabledForDineIn = isPaymentDisabled(merchantConfig?.merchant);

  const handleNoPayOpenCheckCheckout = () => {
    /**
     * No payment involved, either merchant has turned of in app payment, or
     * customer selects to pay later
     */
    togglePayOptionsDrawer(false)
    if (isEmptyOrNil(user)) {
      reduxDispatch(showSignUpDrawer({
        drawerShown: true,
        signupEntrance: SignupEntrance.PLACE_ORDER,
      }));
    } else {
      history.push("/place-open-check-order")
    }
  }

  const handleOrderCheckout = (checkoutMode?: CheckoutMode) => {
    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.ORDER_CHECKOUT,
      action: EVENT_ACTIONS.CLICK_ORDER_CHECKOUT_START,
      data: {
        merchant: merchantConfig?.merchant,
        user
      },
      label: getEventLabel(merchantConfig?.merchant ?? {} as Merchant),
    })

    if (checkoutMode === CheckoutMode.PRE_AUTH) {
      if (isEmptyOrNil(user)) {
        reduxDispatch(showSignUpDrawer({
          drawerShown: true,
          signupEntrance: SignupEntrance.PRE_AUTH,
        }));
      } else {
        history.push("/checkout-preauth");
      }
    } else {
      history.push("/checkout");
    }
  }

  const RenderFabCTA = () => {
    const areCartItemsOutOfOrder = !isEmptyOrNil(soldoutItems);

    return (
      <MultiFabContainer>
        <Switch>
          <Case condition={areCartItemsOutOfOrder}>{() =>
            <Fab color="primary" variant="extended" size="large" onClick={clearSoldOutItems}>
              Clear unavailable items
            </Fab>}
          </Case>
          <Case condition={count < 1}>{() =>
            <Fab color="primary" variant="extended" size="large" disabled>
              No items to checkout
            </Fab>}
          </Case>
          <Case condition={isNewOpenCheckCart && isAddingItemToOpenCheck(cartType)}>{() =>
            <Fab
              color="primary"
              variant="extended"
              size="large"
              onClick={() => history.push(`/place-open-check-order`)}>
              Add to Open Tab
            </Fab>}
          </Case>
          <Case condition={isPaymentDisabledForDineIn}>{() => {
            return cartType === CartType.REGULAR
            ? (
                <Fab
                  color="primary"
                  variant="extended"
                  size="large"
                  onClick={() => handleNoPayOpenCheckCheckout()}>
                  Open a Tab and Pay Later
                </Fab>
              )
            : (
                <Alert severity="warning">
                  {"Online payment is not supported at this store. Please checkout at the counter or ask for paper check."}
                </Alert>
              );
          }}
          </Case>
          <Case condition={isNewOpenCheckCart && checkoutModeOverride === undefined}>{() =>
            <Fab
              color="primary"
              variant="extended"
              size="large"
              onClick={() => togglePayOptionsDrawer(true)}>
              {`Continue to payment ${renderPrice(total)}`}
            </Fab>}
          </Case>
          <Default>{() =>
            <>
              <PointsRedemptionButton />
              <Fab
                color="primary"
                variant="extended"
                size="large"
                disabled={isDisabled}
                onClick={() => handleOrderCheckout(checkoutModeOverride)}
              >
                {`Continue to payment ${renderPrice(total)}`}
              </Fab>
            </>}
          </Default>
        </Switch>
      </MultiFabContainer>
    )
  }

  const PayOptionsDrawer = () => {
    const handleOptionClick = (checkoutMode: CheckoutMode) => {
      togglePayOptionsDrawer(false)
      handleOrderCheckout(checkoutMode);
    }

    return (
      <SwipeableDrawer
        variant="temporary"
        ModalProps={{
          keepMounted: false,
        }}
        anchor={'bottom'}
        open={showPayOptionsDrawer}
        onOpen={() => togglePayOptionsDrawer(true)}
        onClose={() => togglePayOptionsDrawer(false)}
      >
        <ColoredPaper
          role="presentation"
          className="toolbarWrapper"
        >
          <Toolbar className="toolbar" sx={{ justifyContent: 'center' }}>
            <Typography variant="h6" className="drawerTitle" component="div">
              How do you want to pay?
            </Typography>
          </Toolbar>
          <CardContent sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              disabled={isDisabled}
              onClick={() => handleOptionClick(CheckoutMode.PAY_NOW)}
              sx={{ maxWidth: 550, marginBottom: 1 }}
              fullWidth
            >
              Pay Now
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={isDisabled}
              onClick={() => handleOptionClick(CheckoutMode.PRE_AUTH)}
              sx={{ maxWidth: 550 }}
              fullWidth
            >
              Open a Tab and Pay Later
            </Button>
          </CardContent>
        </ColoredPaper>
      </SwipeableDrawer>
    )
  }

  return (
    <>
      <CartItems />
      {!isNewOpenCheckCart &&
        <>
          <TipOptions />
        </>
      }
      <If condition={isCouponEnabled(featureFlags)}>
        <ShoppingCartCouponInput />
      </If>
      <Divider />
      <CartSummary />
      <RenderFabCTA />
      <PayOptionsDrawer />
    </>
  )
}

export default DineInCart;