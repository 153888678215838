import React, { useContext, useRef } from "react";
import { Switch, Case, Default, If } from "react-if";
import { useHistory } from "react-router-dom";
import { useMediaQuery, Theme, Fab, Divider } from "@material-ui/core";
import { CreditCard } from "@material-ui/icons";

import { useWindowStorage, isEmptyOrNil } from "bos_common/src";
import { MultiFabContainer } from "bos_common/src/components/FabContainers";
import renderPrice from "bos_common/src/components/Price";
import { isCouponEnabled } from "bos_common/src/services/featureFlagUtils";
import { isOpenCheckForPickup } from "bos_common/src/services/merchantService";
import { UserContext } from "bos_common/src/context/UserContext";

import { CartBagItemCard } from "../../components/ShoppingCart/CartBagItemCard";
import CartItems from "../../components/ShoppingCart/CartItems";
import CartSummary from "../../components/ShoppingCart/CartSummary";
import { OrderPickupTypeOptions } from "../../components/ShoppingCart/OrderPickupTypeOptions";
import PointsRedemptionButton from "../../components/ShoppingCart/PointsRedemptionButton";
import ShoppingCartCouponInput from "../../components/ShoppingCart/ShoppingCartCouponInput";
import ShoppingCartRecDrawer from "../../components/ShoppingCart/ShoppingCartRecommendationDrawer";
import TipOptions from "../../components/ShoppingCart/TipOptions";
import { AppContext } from "../../context/AppContext";
import { ShoppingCartContext } from "../../context/ShoppingCart/ShoppingCartContext";
import { getTotalItems, getTotalPrice } from "../../services/cartUtils";
import { LOCALSTORAGE_MARKETPLACE_APP_KEYS } from "../../services/localStorage";
import { showSignUpDrawer } from "../../redux/slice/auth/authActions";
import { useAppDispatch } from "../../redux/hooks"
import { SignupEntrance } from "../../components/User/SignupDrawer"

const zeroCurrencyRegExpt = new RegExp(/(\$)0.0+/, 'gi');

const PickupCart = ({ isValidPickupTime }: { isValidPickupTime: boolean }) => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { featureFlags, merchantConfig, cart, tip, coupon } = useContext(AppContext)
  const { soldoutItems, clearSoldOutItems, setShoppingBagError, showPickupOptions } = useContext(ShoppingCartContext);
  const reduxDispatch = useAppDispatch();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { orderingConfig } = merchantConfig?.merchant || {};

  const [isRecommendationDrawerOpen, toggleRecommendationDrawer] = useWindowStorage(
    LOCALSTORAGE_MARKETPLACE_APP_KEYS.CART_RECOMMENDATION_DRAWER,
    false,
    'sessionStorage');

  const cartItemCardRef = useRef<HTMLElement>();

  const count = getTotalItems(cart);
  const total = getTotalPrice({ cart, tip, coupon, orderingConfig });

  const handleContinuePaymentClick = () => {
    const { id } = merchantConfig!.merchant;
    if (orderingConfig?.carryoutBag && !sessionStorage.getItem(LOCALSTORAGE_MARKETPLACE_APP_KEYS.CARRYOUT_BAG_CHOSEN)) {
      setShoppingBagError(true);
      const offset = cartItemCardRef?.current?.getBoundingClientRect();
      const top = offset?.top;
      window.scrollTo({
        top: (Number(top) - 140),
        behavior: 'smooth',
      })
    } else {
      if (isOpenCheckForPickup(id)) {

        if (isEmptyOrNil(user)) {
          reduxDispatch(showSignUpDrawer({
            drawerShown: true,
            signupEntrance: SignupEntrance.PRE_AUTH,
          }));
        } else {
          history.push("/checkout-preauth");
        }
      } else {
        history.push("/checkout");
      }
    }
  }

  const RenderFabCTA = () => {
    const areCartItemsOutOfOrder = !isEmptyOrNil(soldoutItems);
    const price = renderPrice(total);

    return (
      <MultiFabContainer fabStyle={{ padding: "24px 0 16px" }} fadeTop={isMobile}>
        <Switch>
          <Case condition={areCartItemsOutOfOrder}>{() =>
            <Fab color="primary" variant="extended" size="large" onClick={clearSoldOutItems}>
              Clear unavailable items
            </Fab>}
          </Case>
          <Case condition={count < 1}>{() =>
            <Fab color="primary" variant="extended" size="large" disabled>
              No items to checkout
            </Fab>}
          </Case>
          <Case condition={!isValidPickupTime}>{() =>
            <Fab color="primary" variant="extended" size="large" onClick={() => showPickupOptions(true)}>
              Schedule your pickup
            </Fab>}
          </Case>
          <Default>{() =>
            <>
              <PointsRedemptionButton />
              <Fab
                color="primary"
                variant="extended"
                size="large"
                onClick={handleContinuePaymentClick}
              >
                <CreditCard fontSize="large" />&nbsp;
                {zeroCurrencyRegExpt.test(price) ? `Checkout ${price}` : `Continue to Payment ${price}`}
              </Fab>
            </>}
          </Default>
        </Switch>
      </MultiFabContainer>
    )
  }

  return (
    <>
      <CartItems />
      <CartBagItemCard toggleRecommendationDrawer={toggleRecommendationDrawer} cartItemCardRef={cartItemCardRef} />
      <OrderPickupTypeOptions />
      <TipOptions />
      <If condition={isCouponEnabled(featureFlags)}>
        <ShoppingCartCouponInput />
      </If>
      <Divider />
      <CartSummary />
      <RenderFabCTA />
      <ShoppingCartRecDrawer open={isRecommendationDrawerOpen} setOpen={toggleRecommendationDrawer} />
    </>
  )
}

export default PickupCart;