
import React from 'react'
import { SvgIcon } from "@material-ui/core";

function RamenIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M5.129 14.59c0 .226.184.41.41.41h4.922a.41.41 0 00.41-.41c0-1.162 4.129-1.583 4.129-5.742a.41.41 0 00-.41-.41h-.46a3.697 3.697 0 00-3.67-3.282c-.912 0-1.788.34-2.461.942a3.718 3.718 0 00-.41-.318V3.46h7a.41.41 0 100-.82h-7v-.82h7a.41.41 0 100-.82H3.08a.41.41 0 000 .82h.41v.82h-.41a.41.41 0 000 .82h.41v2.32A3.695 3.695 0 001.87 8.437h-.46a.41.41 0 00-.411.41c0 4.16 4.129 4.58 4.129 5.743zm8.174-6.153h-.833a2.054 2.054 0 00-2.01-1.64c-.593 0-1.128.253-1.503.658a3.689 3.689 0 00-.411-.746 2.873 2.873 0 011.915-.733 2.875 2.875 0 012.842 2.461zm-1.682 0H9.3c.17-.477.625-.82 1.16-.82.535 0 .99.343 1.16.82zM6.77 5.367a3.663 3.663 0 00-.82-.188V1.82h.82v3.547zM4.309 1.82h.82V5.18a3.661 3.661 0 00-.82.188V1.82zm1.23 4.156a2.876 2.876 0 012.842 2.461h-.832a2.054 2.054 0 00-2.01-1.64c-.99 0-1.819.705-2.01 1.64h-.832a2.876 2.876 0 012.842-2.46zm1.16 2.461h-2.32c.17-.477.625-.82 1.16-.82.535 0 .99.343 1.16.82zm7.465.82c-.251 3.257-3.538 3.335-4.05 4.923H5.886c-.512-1.589-3.799-1.666-4.05-4.922h12.328z" />
    </SvgIcon>
  );
}

export default RamenIcon