import React from "react";
import { Typography, Fab, Stack } from "@material-ui/core";
import { Check } from "@material-ui/icons";

import { displayOrderShortCode } from "../../bos_common/src/services/orderUtils";

import { Merchant, Order } from "../../services/models";
import AutoRedirectProgress from "../common/AutoRedirectProgress";
import RenderWaitTime from "../common/WaitTime";

import HeartIllustration from "../../assets/images/congratulations-rewards.svg";
import OrderConfirmationImage from "../../assets/images/kisok-order-confirmation-bg.svg";

import { useStyles } from "./styles";

interface GetPickUpDetailsProps {
  order: Order;
}

const GetPickUpDetails = ({ order }: GetPickUpDetailsProps) => {
  return (
    <div className="pickup-details">
      <div className="detail">
        <Typography
          className="detail-heading"
          variant="subtitle1"
          color="textSecondary"
          component="div"
        >
          Order for
        </Typography>
        <Typography className="detail-value" variant="h5">
          {order.userDisplayName}
        </Typography>
      </div>

      <div className="detail">
        <Typography
          className="detail-heading"
          variant="subtitle1"
          color="textSecondary"
          component="div"
        >
          Pickup code
        </Typography>
        <Typography className="detail-value" variant="h5" component="div">
          {displayOrderShortCode(order)}
        </Typography>
      </div>
    </div>
  );
};
interface KioskOrderStatusProps {
  order: Order;
  onClose: () => void;
  merchant?: Merchant;
}

const KioskOrderStatusWideMode = ({
  order,
  onClose,
}: KioskOrderStatusProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.wideMode} style={{}}>
      <img className="orderStatusImage" src={OrderConfirmationImage} />
      <div className='orderStatusIllustration' />
      <div className="status-container">
        <div className="redirect-progress-container">
          <AutoRedirectProgress
            circular
            onCompleteAction={onClose}
            autoRedirectDuration={5}
          />
        </div>

        <div className="order-status-container">
          <Typography variant="h4" className="orderStatusHeading">
            Order received!
          </Typography>

          <div className="pick-up-duration-text">
            <RenderWaitTime withIcon={false} short order={order} />
          </div>

          <GetPickUpDetails order={order} />

          <Fab
            color="default"
            variant="extended"
            size="large"
            onClick={onClose}
            className="cta-button"
          >
            Done
          </Fab>

        </div>
      </div>
    </div>
  );
};

const KioskOrderStatusTabletMode = ({
  order,
  onClose,
}: KioskOrderStatusProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.tabletMode}>
      <div className="order-status-container">
        <div className="content">
          <Typography variant="h4" className="orderStatusHeading">
            Order received
          </Typography>

          <div className="heart-illustration-container">
            <img
              className="heart-illustration-image"
              src={HeartIllustration}
              alt="heart-illustration"
            />
          </div>

          <div className="pick-up-duration-text">
            <RenderWaitTime short order={order} />
          </div>
        </div>
      </div>

      <div className="order-details-container">
        <div className="content">
          <AutoRedirectProgress
            onCompleteAction={onClose}
            autoRedirectDuration={5}
          />

          <GetPickUpDetails order={order} />

          <Fab
            color="primary"
            variant="extended"
            size="large"
            onClick={onClose}
            className="cta-button"
          >
            Done
          </Fab>
        </div>
      </div>
    </div>
  );
};

const KioskOrderStatusCompactMode = ({
  order,
  onClose,
}: KioskOrderStatusProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <Stack className={classes.compactMode}>
      <div className="order-status-container">
        <div className="content">
          <Typography variant="h4" className="orderStatusHeading">
            <Check fontSize="large" />
            &nbsp;Order received
          </Typography>
          <div className="heart-illustration-container">
            <img
              className="heart-illustration-image"
              src={HeartIllustration}
              alt="heart-illustration"
            />
          </div>
          <div className="pick-up-duration-text">
            <RenderWaitTime order={order} short />
          </div>
        </div>
      </div>

      <div className="order-details-container">
        <div className="content">
          <AutoRedirectProgress
            onCompleteAction={onClose}
            autoRedirectDuration={5}
          />
          <GetPickUpDetails order={order} />

          <Fab
            color="primary"
            variant="extended"
            size="large"
            onClick={onClose}
            className="cta-button"
          >
            Done
          </Fab>
        </div>
      </div>
    </Stack>
  );
};

export {
  KioskOrderStatusCompactMode,
  KioskOrderStatusWideMode,
  KioskOrderStatusTabletMode,
};
