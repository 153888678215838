import { BaseIncrement } from "./BaseIncrement";

export enum AnnouncementType {
  OFFLINE = 'offline',
  GENERAL = 'general',
}

export interface MerchantAnnouncement extends BaseIncrement {
  merchantId: string;
  type: AnnouncementType;
  content: string;
  startDate: Date;
  endDate: Date;
  live: boolean;
}
