import React from 'react'
import { SvgIcon } from "@material-ui/core";

function PastaIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 13 }} viewBox="0 0 16 13" {...props} >
      <path d="M14.392 9.633a.4.4 0 01.062-.556l.074-.061a1.332 1.332 0 000-2.037 8.942 8.942 0 00-.073-.061.4.4 0 01-.063-.557l.057-.075c.485-.644.285-1.6-.524-2.002-.224-.112-.317-.347-.221-.56.473-1.055-.573-2.055-2.337-1.042-.904.518-1.903 1.476-2.97 2.847A.5.5 0 018 5.722a.5.5 0 01-.397-.193C6.536 4.16 5.537 3.2 4.633 2.682c-1.76-1.01-2.812-.016-2.337 1.042.088.196.026.436-.24.569-.76.381-1.005 1.328-.505 1.994l.057.074a.4.4 0 01-.063.556l-.073.062a1.334 1.334 0 000 2.036l.073.062a.4.4 0 01.063.556l-.057.075c-.482.641-.29 1.596.524 2.003.224.112.317.347.22.56l-.029.067c-.208.471-.055.98.374 1.236.282.168.915.358 1.993-.261.904-.519 1.903-1.477 2.97-2.848A.5.5 0 018 10.274a.5.5 0 01.397.193c1.067 1.37 2.066 2.328 2.97 2.847.563.324 1.006.426 1.34.426.893 0 1.32-.737 1.026-1.402l-.03-.066c-.088-.197-.025-.436.24-.569.765-.383 1.003-1.332.506-1.994l-.057-.075zm-.457-1.316a9.552 9.552 0 01-.067.057 1.32 1.32 0 00-.206 1.812l.055.073c.147.195.087.489-.2.633a1.346 1.346 0 00-.649 1.754c.043.096.057.12.021.142a.353.353 0 01-.173.034c-.46 0-1.675-.45-3.596-2.919A1.41 1.41 0 008 9.357c-.442 0-.85.199-1.12.546-2.266 2.912-3.55 3.016-3.77 2.884-.036-.02-.021-.045.021-.14.283-.629.045-1.409-.664-1.764-.241-.12-.345-.41-.184-.624l.055-.073a1.32 1.32 0 00-.207-1.812 8.29 8.29 0 01-.066-.057.418.418 0 010-.64l.067-.056a1.32 1.32 0 00.206-1.812l-.055-.073c-.145-.193-.09-.488.2-.633.608-.303.973-1.023.62-1.817-.025-.06-.009-.07.007-.079.22-.131 1.504-.027 3.77 2.885.27.347.678.546 1.12.546.442 0 .85-.2 1.12-.546 2.266-2.912 3.55-3.016 3.77-2.885.016.01.032.02.006.08-.331.745-.04 1.486.637 1.824.243.122.344.412.184.625l-.055.072a1.32 1.32 0 00.206 1.813l.067.056c.13.11.15.248.15.32 0 .073-.02.21-.15.32z" />
      <path d="M10.823 8.455c-.672 0-.967-.217-1.349-.458.382-.24.677-.458 1.349-.458a.458.458 0 100-.915c-.894 0-1.376.306-1.764.552-.332.211-.572.363-1.059.363s-.727-.152-1.059-.363c-.388-.246-.87-.552-1.764-.552a.458.458 0 000 .915c.672 0 .967.218 1.349.458-.382.24-.677.458-1.349.458a.458.458 0 000 .916c.894 0 1.376-.306 1.764-.553.332-.21.572-.363 1.059-.363s.727.153 1.059.363c.388.247.87.553 1.764.553a.458.458 0 100-.916z" />
    </SvgIcon>
  );
}

export default PastaIcon