import React from "react";
import { IconButton } from "@material-ui/core";
import { NavigateBefore } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router";

export default function BackButton(props: {
  onBack?: () => void,
}): React.ReactElement {
  const history = useHistory();
  const onBack = props.onBack ? props.onBack : () => history.goBack();

  return (
    <IconButton color="primary" onClick={onBack} >
      <NavigateBefore fontSize="large" />
    </IconButton>
  )
}