import React from 'react'
import { Chip, createStyles, makeStyles, PropTypes, Theme } from '@material-ui/core'

const useStyles = makeStyles(() => createStyles({
  root: {
    gap: 4,
    borderRadius: '7px !important',
    '& .MuiChip-label': {
      paddingLeft: '5px',
      paddingRight: '5px',
      display: 'flex',
      alignItems: 'center',
    },
    '&.MuiChip-filled': {
      color: "white"
    }
  }
}))

type ChipColor = Exclude<PropTypes.Color, 'inherit'>;

type Props = {
  label: React.ReactNode | string,
  variant?: 'outlined' | 'filled',
  size?: "medium" | "small",
  color?: ChipColor,
  disabled?: boolean,
  icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
  onClick?: () => void,
  className?: string,
  sx?: any,
}

const CustomizedChip = ({
  label,
  color = 'default' as ChipColor,
  variant = 'outlined',
  icon,
  size = 'medium',
  disabled = false,
  className,
  sx,
  onClick }
  : Props
): JSX.Element => {
  const classes = useStyles()

  const clickProp = onClick ? { onClick } : {}

  return (
    <Chip
      variant={variant}
      color={color}
      label={label}
      icon={icon}
      size={size}
      disabled={disabled}
      className={className}
      sx={sx}
      classes={{
        root: classes.root
      }}
      {...clickProp}
    />
  )
}

export default CustomizedChip
