import React from "react";
import { SvgIcon } from "@material-ui/core";

function JoinPromotionIcon(props: any) {
  return (
    <SvgIcon
      sx={{ width: "49px", height: "48px" }}
      viewBox="0 0 49 48"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_10177_54492)">
        <path
          d="M35.517 3.01929H12.9829C12.4592 3.01929 11.9753 3.29866 11.7134 3.75222L0.446383 23.2673C0.184539 23.7209 0.184539 24.2796 0.446383 24.7332L11.7134 44.2484C11.9753 44.7019 12.4592 44.9813 12.9829 44.9813H35.517C36.0407 44.9813 36.5247 44.7019 36.7865 44.2484L48.0536 24.7332C48.3154 24.2796 48.3154 23.7209 48.0536 23.2673L36.7865 3.75222C36.5247 3.29866 36.0407 3.01929 35.517 3.01929Z"
          fill="#FFD45D"
        />
        <path
          d="M48.25 24.0002H38.6426L31.4463 36.4646L36.25 44.7848C36.4692 44.6582 36.6557 44.4751 36.7866 44.2483L48.0536 24.7331C48.1846 24.5064 48.25 24.2533 48.25 24.0002Z"
          fill="#FF6500"
        />
        <path
          d="M31.4463 36.4646H17.0538L12.25 44.7848C12.4692 44.9114 12.7211 44.9812 12.9829 44.9812H35.5171C35.7789 44.9812 36.0308 44.9114 36.25 44.7848L31.4463 36.4646Z"
          fill="#FF9416"
        />
        <path
          d="M17.0538 36.4646L9.85741 24.0002H0.25C0.25 24.2533 0.315438 24.5064 0.446406 24.7332L11.7135 44.2484C11.8444 44.4752 12.0308 44.6583 12.25 44.7849L17.0538 36.4646Z"
          fill="#FFC13F"
        />
        <path
          d="M17.0538 11.5359L12.25 3.21558C12.0308 3.34214 11.8443 3.52533 11.7135 3.75211L0.446406 23.2674C0.315438 23.4941 0.25 23.7472 0.25 24.0002H9.85741L17.0538 11.5359Z"
          fill="#FFD45D"
        />
        <path
          d="M17.0538 11.536H31.4463L36.25 3.21569C36.0308 3.08913 35.7789 3.01929 35.5171 3.01929H12.9829C12.7211 3.01929 12.4692 3.08913 12.25 3.21569L17.0538 11.536Z"
          fill="#FFC13F"
        />
        <path
          d="M31.4463 11.5359L38.6426 24.0002H48.25C48.25 23.7472 48.1846 23.4941 48.0536 23.2674L36.7866 3.75211C36.6557 3.52533 36.4692 3.34214 36.25 3.21558L31.4463 11.5359Z"
          fill="#FF9416"
        />
        <path
          d="M31.4464 11.5359H17.0537L9.85742 24.0002L17.0537 36.4646H31.4464L38.6426 24.0002L31.4464 11.5359Z"
          fill="#FF7B05"
        />
        <path
          d="M17.3129 10.6631L18.2303 9.97785C18.4391 9.82195 18.7153 10.0548 18.5969 10.2869L18.0766 11.3069C18.021 11.416 18.0539 11.5492 18.1538 11.6198L19.089 12.2805C19.3018 12.4309 19.1657 12.7656 18.9083 12.7246L17.7774 12.5449C17.6566 12.5257 17.5399 12.5982 17.5037 12.7151L17.1642 13.8087C17.0869 14.0575 16.7266 14.0314 16.686 13.7741L16.5074 12.6431C16.4883 12.5222 16.3834 12.4337 16.261 12.4353L15.116 12.4504C14.8555 12.4539 14.7689 12.1031 15.0011 11.9849L16.0216 11.4656C16.1306 11.4101 16.1824 11.2829 16.143 11.1671L15.7748 10.0829C15.691 9.8361 15.9979 9.64542 16.1821 9.82973L16.9913 10.6398C17.0779 10.7264 17.2148 10.7363 17.3129 10.6631Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10177_54492">
          <rect
            width="48"
            height="48"
            fill="white"
            transform="translate(0.25 0.000244141)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default JoinPromotionIcon;
