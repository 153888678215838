import React from "react";
import { SvgIcon } from "@material-ui/core";

function ReviewIcon(props: any) {
  return (
    <SvgIcon
      sx={{ width: "49px", height: "48px" }}
      viewBox="0 0 49 48"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_10177_54532)">
        <path
          d="M44.4665 0.0205078H3.99255C1.92558 0.0205078 0.25 1.69608 0.25 3.76305V15.2986C0.25 17.3656 1.92558 19.0412 3.99255 19.0412H6.86005L10.4304 23.8071L14.0008 19.0412H44.4665C46.5335 19.0412 48.2091 17.3656 48.2091 15.2986V3.76305C48.2091 1.69608 46.5335 0.0205078 44.4665 0.0205078Z"
          fill="#FFD36C"
        />
        <path
          d="M44.4667 0.0205078H40.5586C42.6256 0.0205078 44.3011 1.69608 44.3011 3.76305V15.2986C44.3011 17.3656 42.6256 19.0412 40.5586 19.0412H44.4667C46.5337 19.0412 48.2093 17.3656 48.2093 15.2986V3.76305C48.2093 1.69608 46.5337 0.0205078 44.4667 0.0205078Z"
          fill="#FCC645"
        />
        <path
          d="M10.6205 4.89407L11.9664 7.62108C12.0048 7.69886 12.079 7.7528 12.1649 7.76534L15.1743 8.20267C15.3906 8.23413 15.4769 8.49988 15.3204 8.65244L13.1428 10.7751C13.0806 10.8357 13.0523 10.9229 13.0669 11.0085L13.581 14.0058C13.6179 14.2212 13.3918 14.3854 13.1984 14.2838L10.5067 12.8687C10.4298 12.8283 10.3381 12.8283 10.2613 12.8687L7.56958 14.2838C7.3761 14.3855 7.15001 14.2213 7.18697 14.0058L7.70101 11.0085C7.71567 10.923 7.6873 10.8357 7.62516 10.7751L5.44761 8.65235C5.29109 8.49978 5.37745 8.23403 5.5937 8.20257L8.60316 7.76525C8.68904 7.7528 8.76325 7.69886 8.80165 7.62098L10.1475 4.89398C10.2444 4.69809 10.5238 4.69809 10.6205 4.89407Z"
          fill="#EAAF20"
        />
        <path
          d="M24.4658 4.89406L25.8117 7.62107C25.8501 7.69885 25.9243 7.75279 26.0102 7.76533L29.0196 8.20266C29.2359 8.23412 29.3223 8.49987 29.1657 8.65243L26.9881 10.7751C26.9259 10.8357 26.8976 10.9229 26.9122 11.0085L27.4263 14.0058C27.4632 14.2212 27.2371 14.3854 27.0437 14.2838L24.352 12.8687C24.2752 12.8283 24.1834 12.8283 24.1066 12.8687L21.4149 14.2838C21.2214 14.3855 20.9953 14.2213 21.0323 14.0058L21.5463 11.0085C21.561 10.923 21.5326 10.8357 21.4705 10.7751L19.2928 8.65243C19.1363 8.49987 19.2227 8.23412 19.4389 8.20266L22.4484 7.76533C22.5343 7.75289 22.6085 7.69894 22.6469 7.62107L23.9927 4.89406C24.0897 4.69807 24.3692 4.69807 24.4658 4.89406Z"
          fill="#EAAF20"
        />
        <path
          d="M38.3115 4.89406L39.6574 7.62107C39.6958 7.69885 39.77 7.75279 39.8559 7.76533L42.8653 8.20266C43.0816 8.23412 43.168 8.49987 43.0114 8.65243L40.8338 10.7751C40.7716 10.8357 40.7433 10.9229 40.7579 11.0085L41.272 14.0058C41.3089 14.2212 41.0828 14.3854 40.8894 14.2838L38.1977 12.8687C38.1209 12.8283 38.0291 12.8283 37.9523 12.8687L35.2606 14.2838C35.0671 14.3855 34.841 14.2213 34.878 14.0058L35.392 11.0085C35.4067 10.923 35.3783 10.8357 35.3162 10.7751L33.1385 8.65243C32.982 8.49987 33.0684 8.23412 33.2846 8.20266L36.2941 7.76533C36.38 7.75289 36.4542 7.69894 36.4926 7.62107L37.8384 4.89406C37.9353 4.69807 38.2148 4.69807 38.3115 4.89406Z"
          fill="#EAAF20"
        />
        <path
          d="M10.3822 47.9795C15.9781 47.9795 20.5144 43.4432 20.5144 37.8473C20.5144 32.2514 15.9781 27.7151 10.3822 27.7151C4.78634 27.7151 0.25 32.2514 0.25 37.8473C0.25 43.4432 4.78634 47.9795 10.3822 47.9795Z"
          fill="#F7F3F1"
        />
        <path
          d="M10.3725 39.2947C12.3976 39.2947 14.0394 37.653 14.0394 35.6278C14.0394 33.6027 12.3976 31.9609 10.3725 31.9609C8.34729 31.9609 6.70557 33.6027 6.70557 35.6278C6.70557 37.653 8.34729 39.2947 10.3725 39.2947Z"
          fill="#EBE1DC"
        />
        <path
          d="M10.3723 39.2947C6.95612 39.2947 4.14091 41.8674 3.7583 45.181V45.5146C5.53443 47.0503 7.84978 47.9794 10.3821 47.9794C12.9133 47.9794 15.2276 47.0511 17.0034 45.5166L17.0058 45.3741C16.7125 41.9678 13.8546 39.2947 10.3723 39.2947Z"
          fill="#EBE1DC"
        />
        <path
          d="M46.424 32.2987H26.5456C24.2408 32.2987 24.2379 28.7283 26.5456 28.7283H46.424C48.7287 28.7283 48.7316 32.2987 46.424 32.2987Z"
          fill="#EBE1DC"
        />
        <path
          d="M46.4718 39.6324H26.5934C24.2887 39.6324 24.2858 36.062 26.5934 36.062H46.4718C48.7766 36.062 48.7795 39.6324 46.4718 39.6324Z"
          fill="#EBE1DC"
        />
        <path
          d="M46.5202 46.9662H26.6418C24.337 46.9662 24.3341 43.3958 26.6418 43.3958H46.5202C48.8249 43.3958 48.8278 46.9662 46.5202 46.9662Z"
          fill="#EBE1DC"
        />
      </g>
      <defs>
        <clipPath id="clip0_10177_54532">
          <rect
            width="48"
            height="48"
            fill="white"
            transform="translate(0.25)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default ReviewIcon;
