import { call, put, takeLatest } from 'redux-saga/effects'
import { AuthSliceStateEntities } from '../../../types/AuthSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { authFetchFailed, authFetching, authFetchSucceed } from './authActions';

export const delay = (time: number) => new Promise(resolve => setTimeout(resolve, time));

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* loadAuth(action: PayloadAction<AuthSliceStateEntities>) {
   try {
      const { user, token } = action.payload;
      yield put(authFetching());
      yield put(authFetchSucceed({ user, token }));
   } catch (e: any) {
      yield put(authFetchFailed());
   }
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* authSaga(): Generator {
  yield takeLatest("USER_FETCH_REQUESTED", loadAuth);
}

export default authSaga;