import React from 'react'
import { Merchandise } from 'bos_common/src/types/MerchandiseType';
import { Add, ChevronRight } from "@material-ui/icons";
import { useTheme } from '@material-ui/core/styles';
import { Else, If, Then } from 'react-if';
import Fab from '@material-ui/core/Fab';
import { MerchantCoupon } from 'bos_common/src/types/MerchantCouponType';

export interface MenuItemCTAProps {
  merchandise: Merchandise;
  disabled?: boolean;
  onClickAddToCart: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const MenuItemCTA = (props: Readonly<MenuItemCTAProps>): JSX.Element => {
  const { merchandise, disabled, onClickAddToCart } = props;
  const theme = useTheme();

    return (
      <If condition={merchandise && merchandise.modifierTemplateId}>
        <Then>
          <Fab color="secondary" size="small" aria-label="enter">
            <ChevronRight style={{ fill: theme.palette.common.black}} />
          </Fab>
        </Then>
        <Else>
          <Fab
            color="secondary"
            size="small"
            aria-label="add"
            disabled={disabled}
            onClick={onClickAddToCart}
          >
            <Add style={{ fill: theme.palette.common.black}} />
          </Fab>
        </Else>
      </If>
    );
}

export interface PromotionItemCTAProps {
  coupon: MerchantCoupon;
  disabled?: boolean;
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const PromotionItemCTA = (props: Readonly<PromotionItemCTAProps>): JSX.Element => {
  const { coupon, disabled, onClick } = props;
  const theme = useTheme();

    return (
      <Fab color="secondary" size="small" aria-label="enter" disabled={disabled} onClick={onClick}>
        <ChevronRight style={{ fill: theme.palette.common.black }} />
      </Fab>
    );
}

export default MenuItemCTA;
