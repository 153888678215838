import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { WritableDraft } from '@reduxjs/toolkit/node_modules/immer/dist/internal';
import { MerchantStory, StoryComment } from 'bos_common/src/types/MerchantStoryType';

import { MerchantStorySliceState } from '../../../types/MerchantStorySlice';
import { commentMerchantStory, fetchMerchantStories, fetchMerchantStoryComments, likeMerchantStory, RemoveCommentPayload, removeMerchantStoryComment } from './merchantStoryAction';

type MerchantStorySliceCaseReducer = SliceCaseReducers<MerchantStorySliceState>;

const commonUpdate = (state: WritableDraft<MerchantStorySliceState>, action: PayloadAction<MerchantStory[]>): MerchantStorySliceState => {
  const { payload } = action;
  const byId = { ...state.entities.byId }
  const ids = [...state.entities.ids];

  payload.forEach((item) => {
    byId[item.id] = item;

    if (ids.indexOf(item.id) === -1) {
      ids.push(item.id);
    }
  });

  return {
    ...state,
    loading: false,
    entities: {
      byId,
      ids,
    }
  }
}

const preserveComments = (state: WritableDraft<MerchantStorySliceState>, action: PayloadAction<MerchantStory[]>, previousStoryState: MerchantStory, updatedStory?: MerchantStory) => {
  const { byId } = state.entities;
  const id = action.payload[0].id;
  const updatedState = commonUpdate(state, action as PayloadAction<MerchantStory[]>);

  return {
    ...updatedState,
    entities: {
      ...updatedState.entities,
      byId: {
        ...byId,
        [id]: {
          ...updatedState.entities.byId[id],
          comments: updatedStory?.comments 
            ? [ ...updatedStory.comments, ...previousStoryState.comments]
            : [ ...previousStoryState.comments],
        }
      }
    },
  }
}

export const merchantStorySlice = createSlice<MerchantStorySliceState, MerchantStorySliceCaseReducer, string>({
  name: 'merchantStory',
  initialState: {
    loading: false,
    entities: {
      ids: [],
      byId: {},
    },
  },
  reducers: {
    setLoading: (state) => {
      return {
        ...state,
        loading: true
      }
    },

    stopLoading: (state) => {
      return {
        ...state,
        loading: false
      }
    },

    updateMerchantStory: (state, action: PayloadAction<MerchantStory[]>) => {
      const updatedState = commonUpdate(state, action);

      return {
        ...updatedState
      }
    },

    removeComment: (state, action: PayloadAction<RemoveCommentPayload>) => {
      const { byId } = state.entities;
      const { payload } = action;
      const story = { ...byId[payload.id], comments: [ ...byId[payload.id].comments ] };
      const commentIndex = story.comments.findIndex((item) => item.id === payload.commentId);
      story.comments.splice(commentIndex, 1);

      return {
        ...state,
        entities: {
          ...state.entities,
          byId: {
            ...byId,
            [payload.id]: { ...story }
          }
        }
      }
    },

    setUpdating: (state) => {
      return {
        ...state,
        updating: true,
      }
    },

    stopUpdating: (state) => {
      return {
        ...state,
        updating: false,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerchantStories.pending, (state) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(fetchMerchantStories.fulfilled, (state, action) => {
        const updatedState = commonUpdate(state, action as PayloadAction<MerchantStory[]>);

        return {
          ...updatedState,
          loading: false
        }
      })
      .addCase(fetchMerchantStories.rejected, (state) => {
        return {
          ...state,
          loading: false,
        }
      })
      .addCase(likeMerchantStory.pending, (state) => {
        return {
          ...state,
          liking: true,
        }
      })
      .addCase(likeMerchantStory.fulfilled, (state, action) => {
        const { byId } = state.entities;
        const id = action.payload[0].id;
        const previousStory = { ...byId[id] }

        return {
          ...preserveComments(state, action, previousStory),
          liking: false,
        }
      })
      .addCase(likeMerchantStory.rejected, (state) => {
        return {
          ...state,
          liking: false,
        }
      })
      .addCase(commentMerchantStory.pending, (state) => {
        return {
          ...state,
          updating: true
        }
      })
      .addCase(commentMerchantStory.fulfilled, (state, action) => {
        const { byId } = state.entities;
        const id = action.payload[0].id;
        const previousStory = { ...byId[id] }

        return {
          ...preserveComments(state, action, previousStory, action.payload[0]),
          updating: false
        }
      })
      .addCase(commentMerchantStory.rejected, (state) => {
        return {
          ...state,
          updating: false,
        }
      })
      .addCase(fetchMerchantStoryComments.pending, (state) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(fetchMerchantStoryComments.fulfilled, (state, action) => {
        const { payload } = action as PayloadAction<StoryComment[]>;
        const { byId, ids } = state.entities;

        if (payload.length > 0) {
          const comments: StoryComment[] = [];
          const { merchantStoryId } = payload[0];
          const merchantStory: MerchantStory = byId[merchantStoryId];

          payload.forEach((item) => {
            if (merchantStory.comments && merchantStory.comments.findIndex((commentItem) => commentItem.id === item.id) === -1) {
              comments.push(item);
            }
          });

          const updatedStory = {
            ...byId[merchantStoryId],
            comments: merchantStory.comments 
            ? [...merchantStory.comments, ...comments]
            : [...payload],
          }

          return {
            ...state,
            loading: false,
            entities: {
              byId: {
                ...byId,
                [merchantStoryId]: {
                  ...updatedStory,
                }
              },
              ids,
            }
          }
        }

        return {
          ...state,
          loading: false,
        }
      })
      .addCase(fetchMerchantStoryComments.rejected, (state) => {
        return {
          ...state,
          loading: false,
        }
      })
      .addCase(removeMerchantStoryComment.pending, (state) => {
        return {
          ...state,
          updating: true
        }
      })
      .addCase(removeMerchantStoryComment.fulfilled, (state, action) => {
        const { payload } = action as PayloadAction<StoryComment[]>;
        const { byId, ids } = state.entities;
        const currentStory = byId[payload[0].merchantStoryId];

        return {
          ...state,
          updating: false,
          entities: {
            ids,
            byId: {
              ...byId,
              [currentStory.id]: {
                ...currentStory,
                interactions: {
                  ...currentStory.interactions,
                  comments: currentStory.interactions.comments - 1,
                }
              }
            },
          },
        }
      })
      .addCase(removeMerchantStoryComment.rejected, (state) => {
        return {
          ...state,
          updating: false,
        }
      })
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  stopLoading,
  updateMerchantStory,
  removeComment,
  setUpdating,
  stopUpdating,
} = merchantStorySlice.actions;

export default merchantStorySlice.reducer;
