import { UserPromotionEntry } from "../../../services/models";
import { RootState } from "../../store";

export const getUserPromotionEntries = (state: RootState): UserPromotionEntry[] | undefined => {
  return state.marketPlace.entities.userPromotionEntriesList
}

export const getUserMerchantPromotionEntries = (merchantId: string): (state: RootState) => UserPromotionEntry[] | undefined => {
  return (state: RootState): UserPromotionEntry[] | undefined => {
    return state.marketPlace.entities.userPromotionEntriesList?.filter(i => i.merchant.id === merchantId)
  }
}