import React, { useContext, useEffect, useState } from 'react'
import { makeStyles, Theme, createStyles, Alert, CardContent, Typography, Box, Button, } from '@material-ui/core';
import { Else, If, Then } from 'react-if';
import { useHistory, useLocation, useParams } from 'react-router';
import { AddShoppingCart, Check } from '@material-ui/icons';
import { Link } from 'react-router-dom';

// src
import PageHeader from '../components/common/PageHeader'
import { AppContext, CartType, MerchantCartConfig } from '../context/AppContext';
import { FullscreenPaper, pluralString } from '../bos_common/src';
import CloseButton from '../components/common/CloseButton';
import { MerchandiseApiResponseType, Merchant, MerchantCoupon, MerchantQRCode, Order, OrderStatus, OrderType } from '../services/models';
import axios from '../bos_common/src/services/backendAxios';
import SimpleLoader from '../bos_common/src/components/SimpleLoader';
import ConfirmationAlert from '../bos_common/src/components/ConfirmationAlert';
import { getItemsInOrder } from '../bos_common/src/services/orderUtils';

import OrderStatusImage from '../assets/images/order-in-progress.svg'
import CheckIcon from '../assets/icons/CheckIcon'
import { isEmptyOrNil, isPaymentDisabled } from '../utils';
import { isOrderActive } from "../bos_common/src/services/orderUtils"
import RenderWaitTime from '../components/common/WaitTime';
import { getMerchantCoupon } from '../services/merchantCouponApiService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      minHeight: '100vh',
      flex: 'unset',

      '& .content': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        color: 'white',
        padding: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flex: '1 1 auto',

        '& .order-image': {
          width: '60vw',
          maxWidth: '400px',
          marginBottom: theme.spacing(2),
        },

        '& .action-btn': {
          backgroundColor: theme.palette.primary.dark,
          borderRadius: 30
        },

        '& .wait-time': {
          backgroundColor: theme.palette.primary.dark,
          padding: theme.spacing(1),
          margin: theme.spacing(2),
          borderRadius: theme.spacing(1)
        }
      }
    },
  }),
);

interface IOrderStatusPageParams {
  orderId: string,
}

function OpenCheckOrderStatusPage() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{ from: { pathname: string } }>();
  const from = location.state?.from;

  const { orderId } = useParams<IOrderStatusPageParams>();

  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [order, setOrder] = useState<Order>();
  const [error, setError] = useState<string>();
  const [merchant, setMerchant] = useState<Merchant>();
  const [coupon, setCoupon] = useState<MerchantCoupon>();
  const [merchantConfig, setMerchantConfig] = useState<MerchantCartConfig>();

  const {
    setOrderType,
    setCartType,
    activeOrder,
    setActiveOrder,
    coupon: cartCoupon,
    setCoupon: setCartCoupon,
    merchantConfig: cartMerchantConfig
  } = useContext(AppContext);

  const fetch = async () => {
    setLoading(true)

    try {
      const orderResponse = await axios.get<Order>('/orders', { params: { id: orderId } })
      const orderData = orderResponse.data
      setOrder(orderData);

      if (cartMerchantConfig && cartMerchantConfig.merchant.id === orderData.merchantId) {
        setMerchant(cartMerchantConfig.merchant);
        setMerchantConfig(cartMerchantConfig);
      } else {
        const merchantResponse = await axios.get<Merchant>('/merchants', { params: { id: orderData.merchantId } })
        const merchantData = merchantResponse.data
        const merchandisesResponse = await axios.get<MerchandiseApiResponseType>('/merchandises', { params: { merchantId: merchantData.id } })
        setMerchant(merchantData);
        setMerchantConfig({ merchant: merchantData, ...merchandisesResponse.data });
      }

      if (orderData.couponRedemptionInfo) {
        if (orderData.couponRedemptionInfo.couponId === cartCoupon?.id) {
          setCoupon(cartCoupon);
        } else {
          const response = await getMerchantCoupon(orderData.merchantId, orderData.couponRedemptionInfo.couponCode);
          setCoupon(response.data);
        }
      }

      setLoading(false);
    } catch (thrown: any) {
      console.log(thrown.message)
      setError("Sorry, we could not find your order.");
      setLoading(false);
    }
  }

  useEffect(() => {
    fetch()
  }, [orderId])

  // set Active order so that we can subscribe to those events in notification center
  useEffect(() => {
    if (order && isOrderActive(order) && order.id !== activeOrder?.id) {
      setActiveOrder(order)
    }
  }, [order?.id])

  // if order status has updated from notifications, fetch again
  useEffect(() => {
    if (order && order.id === activeOrder?.id && order.status !== activeOrder.status) {
      fetch()
    }
  }, [activeOrder])

  const tableQrCodeObject = merchant?.qrCodes?.find((i: MerchantQRCode) => `${i.id}` === `${order?.tableId}`)
  const isOpenCheckOrder = order?.status === OrderStatus.OPEN_CHECK;
  const isDineIn = order?.type === OrderType.DINEIN;
  const isOrderVoidedOrMarkAsPaid = order?.status === OrderStatus.PAID_EXTERNALLY || order?.status === OrderStatus.VOIDED;
  const isPaidOrCompleted = order?.status === OrderStatus.PAID || order?.status === OrderStatus.FULFILLED;

  const handleCheckClick = () => {
    if (isEmptyOrNil(order)) return
    if (tableQrCodeObject) {
      setOrderType(OrderType.DINEIN, tableQrCodeObject)
    }
    setCartType(CartType.FROM_OPEN_CHECK, merchantConfig, order);
    setCartCoupon(coupon);
    history.push("/cart-pay-now")
  }

  const handleAddMoreClick = () => {
    if (isEmptyOrNil(order)) return
    setCartType(CartType.ADD_ITEMS_OPEN_CHECK, merchantConfig, order);
    setCartCoupon(coupon);

    if (tableQrCodeObject) {
      setOrderType(OrderType.DINEIN, tableQrCodeObject)
      history.push(`/${merchant?.username}?tableid=${tableQrCodeObject?.id}`)
    }

    history.push(`/${merchant?.username}`)
  }

  const handleReceiptClick = () => {
    if (!order) return
    history.push(`/order/${order.id}`, { from: location.pathname });
  }

  const onClose = () => {
    if (from) {
      setCartType(CartType.REGULAR);
      history.goBack();
    } else {
      setCartType(CartType.REGULAR);
      tableQrCodeObject
        ? history.push(`/${merchant?.username}?tableid=${tableQrCodeObject?.id}`)
        : history.push(`/${merchant?.username}`);
    }
  }

  const totalQuantity = order ? getItemsInOrder(order, true) : 0;
  const paymentDisabled = isPaymentDisabled(merchantConfig?.merchant);

  const title = loading
    ? ""
    : isDineIn
      ?`Table: ${tableQrCodeObject?.identifier ?? ""} at ${merchant?.officialName ?? ""}`
      : 'Your pickup order';

  return (
    <FullscreenPaper className={classes.root}>
      <ConfirmationAlert
        title={`You can find this order later in "Your Orders".`}
        isOpen={showAlert}
        onConfirm={onClose}
        confirmLabel="Continue">
        {''}
      </ConfirmationAlert>
      <PageHeader
        theme="dark"
        leftChild={<CloseButton color="white" onClose={() => setShowAlert(true)} disabled={loading} />}
        title={title}
      />
      <SimpleLoader loading={loading} />
      <If condition={error}>
        <CardContent>
          <Alert severity="warning">{error}</Alert>
        </CardContent>
      </If>
      <Box className="content">
        <Box sx={{ textAlign: 'center' }}>
          <img className="order-image" src={OrderStatusImage} alt="your order" />

          <If condition={order && totalQuantity === 0}>
            <Then>
              <Typography variant="h6" component="div" lineHeight="1.2" color="red">
                This order has no items, all items have been cancelled
              </Typography>
            </Then>
            <Else>
              <If condition={isOpenCheckOrder}>
                <If condition={isDineIn}>
                  <Then>
                    <Typography variant="h6" component="div" lineHeight="1.2">
                      {pluralString(totalQuantity, 'item')} {totalQuantity < 2 ? 'has' : 'have'} been sent to the kitchen!
                    </Typography>
                    <div className="wait-time">
                      <RenderWaitTime
                        short
                        order={order}
                        typographyProps={{
                          color: 'white',
                          justifyContent: 'center'
                        }}
                      />
                    </div>
                    <If condition={paymentDisabled}>
                      <Then>
                        <Typography variant="subtitle1" component="div" gutterBottom lineHeight="1.2" margin="16px">
                          Online payment is not supported at this store. Please checkout at the counter or ask for paper check.
                        </Typography>
                      </Then>
                      <Else>
                        <Typography variant="subtitle1" component="div" gutterBottom lineHeight="1.2" margin="16px">
                          You can pay below after the meal or whenever you are ready.
                        </Typography>
                      </Else>
                    </If>
                  </Then>
                  <Else>
                    <Typography variant="h6" component="div" lineHeight="1.2">
                      Your order of {pluralString(totalQuantity, 'item')} {totalQuantity < 2 ? 'has' : 'have'} been received by&nbsp;
                      <Link to={`/${merchant?.username}`} style={{ color: 'white' }} >
                        {merchant?.officialName}
                      </Link>!
                    </Typography>
                    <Typography variant="subtitle1" component="div" gutterBottom lineHeight="1.2" margin="16px">
                      <If condition={paymentDisabled}>
                        <Then> Payment will be collected at pick-up </Then>
                        <Else> You can pay whenever you are ready </Else>
                      </If>
                    </Typography>
                  </Else>
                </If>
              </If>
            </Else>
          </If>
          <If condition={isOrderVoidedOrMarkAsPaid}>
            <Typography variant="h6" component="div" lineHeight="1.2" >
              Merchant has confirmed your payment. Have a nice day!
            </Typography>
          </If>
          <If condition={isPaidOrCompleted}>
            <Typography variant="h6" component="div" lineHeight="1.2" >
              Your tab is paid and closed. Have a nice day!
            </Typography>
          </If>
        </Box>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <If condition={isOpenCheckOrder}>
            <Then>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={handleCheckClick}
                disabled={isEmptyOrNil(order) || loading || totalQuantity <= 0}
                className="action-btn">
                <CheckIcon viewBox="-3 -2 15 15" />
                &nbsp;
                <If condition={isDineIn}>
                  <Then>{paymentDisabled ? 'See check' : 'See check and pay'}</Then>
                  <Else>{paymentDisabled ? 'See details' : 'See details and pay'}</Else>
                </If>
              </Button>
              <If condition={isDineIn}>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={handleAddMoreClick}
                  disabled={isEmptyOrNil(order) || loading}
                  className="action-btn"
                >
                  <AddShoppingCart />&nbsp;Add Items
                </Button>
              </If>
            </Then>
          </If>
          <If condition={isOrderVoidedOrMarkAsPaid || isPaidOrCompleted}>
            <Then>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={handleReceiptClick}
                disabled={isEmptyOrNil(order) || loading}
                className="action-btn">
                <CheckIcon viewBox="-3 -2 15 15" />&nbsp;See receipt
              </Button>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={onClose}
                disabled={isEmptyOrNil(order) || loading}
                className="action-btn"
              >
                <Check />&nbsp;Done
              </Button>
            </Then>
          </If>
        </div>
      </Box>
    </FullscreenPaper >
  )
}

export default OpenCheckOrderStatusPage
