import React, { useState, useContext } from "react";
import { Box, CardActionArea, createStyles, IconButton, makeStyles, Stack, Theme, Typography } from "@material-ui/core";
import { Schedule, ChevronRight, EventAvailable } from "@material-ui/icons";
import { Else, If, Then } from "react-if";
import classNames from "classnames";

import { Merchant, OrderType } from "../../services/models";
import { displayTime, isAddingItemToOpenCheck, isCartFromAnOpenCheckOrder, isEmptyOrNil, isKioskDevice, isStoreOpen } from '../../utils';
import { AppContext, CartType } from "../../context/AppContext";
import RenderWaitTime from "../common/WaitTime";

import ScheduleOrderDrawer from "./ScheduleOrderDrawer";
import MerchantAddress from "../MerchantInfo/MerchantAddress";
import { ShoppingCartContext } from "../../context/ShoppingCart/ShoppingCartContext";
import eventTrackingService from "../../services/Tracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../../services/Tracking/events";
import { getEventLabel } from "../../services/Tracking/EventsTracking";

type PickupOptionsProps = {
  merchant: Merchant,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',

      '&.needPickupTime': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',

        '& .merchantAddressText': {
          color: 'rgba(255, 255, 255, 0.8)'
        }
      },

      '& .iconWrapper': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 20,
        minWidth: 40,
        height: 40,
        color: "white",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
    },
    kioskOptionsContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
      '& > div': {
        marginLeft: theme.spacing(1)
      }
    },
  }),
);

const RenderKioskModeOption = ({ merchant }: Omit<PickupOptionsProps, 'isKioskMode'>): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.kioskOptionsContainer}>
      <Schedule color="primary" />
      {merchant &&
        <RenderWaitTime merchant={merchant} withIcon={false} />
      }
    </div>
  )
}

const RenderNoScheduleOptions = ({ merchant }: Omit<PickupOptionsProps, 'isKioskMode'>): React.ReactElement | null => {
  const classes = useStyles();

  if (!merchant) return null

  return (
    <div className={classes.root}>
      <Stack direction={'row'} alignItems="center" spacing={2}>
        <div className="iconWrapper"><Schedule /></div>
        <Box>
          <RenderWaitTime
            merchant={merchant}
            withIcon={false}
            typographyProps={{
              variant: "subtitle1",
            }}
          />
          <div className="merchantAddressText">
            <MerchantAddress merchant={merchant} showIcon={false} typographyProps={{ variant: "caption", color: 'GrayText' }} />
          </div>
        </Box>
      </Stack>
    </div>
  )
}

const RenderStandardPickupOptions = ({ merchant }: Omit<PickupOptionsProps, 'isKioskMode'>): React.ReactElement => {
  const classes = useStyles();
  const { cartType, pickupTime, setPickupTime } = useContext(AppContext);
  const { pickupOptionsShown, showPickupOptions } = useContext(ShoppingCartContext);
  const now = new Date();
  const finalPickupTime = pickupTime ? new Date(pickupTime) : now;
  const isFromOpenCheckOrder = isCartFromAnOpenCheckOrder(cartType) || isAddingItemToOpenCheck(cartType);
  const readOnly = isFromOpenCheckOrder;
  const isValidPickupTime = isFromOpenCheckOrder || (finalPickupTime.getTime() >= now.getTime() && isStoreOpen(merchant, finalPickupTime))

  const onChangeDate = (d: Date) => {
    setPickupTime(d || undefined)

    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.SHOPPING_CART,
      action: EVENT_ACTIONS.CLICK_SCHEDULE_ORDER,
      label: getEventLabel(merchant ?? {} as Merchant)
    });
  }

  const Icon = isValidPickupTime ? <EventAvailable /> : <Schedule />
  const pickupTimeLabel = pickupTime ? displayTime(pickupTime) : ""
  const pickupTimeString = isValidPickupTime
    ? `Pick up ${isEmptyOrNil(pickupTime) ? 'ASAP' : `at ${pickupTimeLabel}`}`
    : 'Schedule your pickup'

  const rootClasses = classNames([classes.root], {
    ['needPickupTime']: !isValidPickupTime
  })

  return (
    <div className={rootClasses}>
      <CardActionArea onClick={() => showPickupOptions(true)}>
        <Stack direction={'row'} alignItems="center" spacing={2}>
          <div className="iconWrapper">{Icon}</div>
          <Box>
            <Typography variant="subtitle1" component="div" color={isValidPickupTime ? "default" : "white"}>
              {pickupTimeString}
            </Typography>
            <div className="merchantAddressText">
              <MerchantAddress merchant={merchant} showIcon={false} typographyProps={{ variant: "caption", color: isValidPickupTime ? 'GrayText' : 'inherit' }} />
            </div>
          </Box>
        </Stack>
      </CardActionArea>
      <If condition={!readOnly}>
        <div>
          <IconButton onClick={() => showPickupOptions(true)} color={isValidPickupTime ? "primary" : "inherit"}>
            <ChevronRight />
          </IconButton>

          <ScheduleOrderDrawer
            merchant={merchant}
            defaultValue={pickupTime}
            open={pickupOptionsShown}
            setOpen={showPickupOptions}
            onSchedule={onChangeDate}
            onClose={() => showPickupOptions(false)}
          />
        </div>
      </If>
    </div>
  )
}

const PickupOptions = ({ merchant }: PickupOptionsProps): React.ReactElement => {
  const isKioskModeEnabled = isKioskDevice()
  const { orderType } = useContext(AppContext)

  return isKioskModeEnabled || orderType === OrderType.DINEIN
    ? <RenderKioskModeOption merchant={merchant} />
    :
    <If condition={merchant.orderingConfig?.orderAhead}>
      <Then><RenderStandardPickupOptions merchant={merchant} /></Then>
      <Else>
        <RenderNoScheduleOptions merchant={merchant} />
      </Else>
    </If>
}

export default PickupOptions