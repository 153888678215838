import { DAYS_OF_WEEK } from "bos_common/src";
import { getRoundedMinutesOffset } from "bos_common/src/services/hoursUtils";
import { parse, format } from "date-fns";
import {
  any, ascend, find, groupBy, keys, lensPath, map, mapObjIndexed,
  omit, over, pathOr, pipe, pluck, prop, propOr, sort, sortBy, uniq
} from "ramda";

// src
import { Merchant, OperationHours, SpecialHours } from "../bos_common/src/types/MerchantType";
import { getTimeLabelFromOffset, isEmptyOrNil } from "../utils";

export const daysOfWeek = new Map([
  [0, "Monday"],
  [1, "Tuesday"],
  [2, "Wednesday"],
  [3, "Thursday"],
  [4, "Friday"],
  [5, "Saturday"],
  [6, "Sunday"]
]);

type DayNumbers = 0 | 2 | 1 | 3 | 4 | 5 | 6

type FormattedSpecialTimings = {
  [key: string]: Array<SpecialHours>
}

export const getSpecialTimings = (customizations: SpecialHours[]): FormattedSpecialTimings => {
  const createDayOfWeekGroups = (customization: SpecialHours) => customization.dayOfWeek || customization.specificDate;
  const groupedHours = pipe(
    groupBy(createDayOfWeekGroups),
    mapObjIndexed((value: Array<SpecialHours>) => sort(ascend(prop('fromMinOffset')), value))
  )(customizations)

  return groupedHours;
}

export const hoursForDate = (merchant: Merchant, date: Date): null | SpecialHours[] | OperationHours => {
  const dayOfWeek = DAYS_OF_WEEK[date.getDay()];
  const dateStr = format(date, 'MM-dd');

  const { hours } = merchant;
  const noTimingsAvailable = !hours || (isEmptyOrNil(hours?.defaultFromMinOffset) && isEmptyOrNil(hours?.defaultToMinOffset) && isEmptyOrNil(hours.customizations))
  if (noTimingsAvailable) {
    return null;
  }

  if (hours?.customizations) {
    const formattedSpecialTimings = getSpecialTimings(hours.customizations);
    const hourGroups = formattedSpecialTimings[dateStr] || (dayOfWeek ? formattedSpecialTimings[dayOfWeek] : null);
    if (hourGroups) {
      return hourGroups;
    }
  }

  return hours;
}

export const openHourForDate = (merchant: Merchant, date: Date): number | null => {
  const hours = hoursForDate(merchant, date);
  if (!hours) {
    return null;
  }

  if (Array.isArray(hours)) {
    return hours[0].fromMinOffset;
  }

  return hours.defaultFromMinOffset;
}

// returns as a specialhour object so it can be formatted according to component needs
export const nextSpecialHours = (merchant: Merchant): SpecialHours | undefined => {
  const date = new Date();
  const dayMinutesOffset = date.getHours() * 60 + date.getMinutes();
  const dayOfWeek = format(date, 'EEEE'); // for normal hours
  const dateStr = format(date, 'MM-dd'); // for special hours

  const { hours } = merchant;
  const noTimingsAvailable = !hours || (isEmptyOrNil(hours?.defaultFromMinOffset) && isEmptyOrNil(hours?.defaultToMinOffset) && isEmptyOrNil(hours.customizations))
  if (noTimingsAvailable) {
    return;
  }

  if (hours?.customizations) {
    const formattedSpecialTimings = getSpecialTimings(hours.customizations);
    // prioritize special hours over normal hours
    const hourGroups = propOr(propOr([], dayOfWeek, formattedSpecialTimings), dateStr, formattedSpecialTimings)

    if (!isEmptyOrNil(hourGroups)) {
      const hourGroup = hourGroups.find((hg: SpecialHours) => hg.fromMinOffset > dayMinutesOffset);
      if (hourGroup) {
        return hourGroup;
      }
    }

    const nextAvailableStoreOpeningDate: Date | undefined = pipe(
      omit([dayOfWeek, dateStr]), // remove sameday dates from object
      keys,
      map((d: string) => ({ //format days/date strings
        date: Array.from(daysOfWeek.values()).includes(d) ?
          parse(d, 'EEEE', date, { weekStartsOn: Math.min(date.getDay() + 1, 6) as DayNumbers }) :
          parse(d, 'MM-dd', date)
      })),
      uniq,
      sortBy(prop('date')),
      pluck('date'),
      find((d: Date) => !isEmptyOrNil(d) && (d >= date)),
    )(formattedSpecialTimings)

    // find Next Available Day hour group
    if (nextAvailableStoreOpeningDate) {
      const nextDayOfWeek = format(nextAvailableStoreOpeningDate, 'EEEE');
      const nextDateStr = format(nextAvailableStoreOpeningDate, 'MM-dd');
      const nextDayHourGroups: SpecialHours[] = formattedSpecialTimings[nextDateStr] || propOr([], nextDayOfWeek, formattedSpecialTimings)

      const nextHourGroup = propOr({}, 0, nextDayHourGroups)

      return nextHourGroup as SpecialHours;
    }
  }
  return;
}
export const nextOpenHour = (merchant: Merchant): string | null => {
  const date = new Date();
  const dayMinutesOffset = date.getHours() * 60 + date.getMinutes();
  const dayOfWeek = format(date, 'EEEE'); // for normal hours
  const dateStr = format(date, 'MM-dd'); // for special hours

  const { hours } = merchant;
  const noTimingsAvailable = !hours || (isEmptyOrNil(hours?.defaultFromMinOffset) && isEmptyOrNil(hours?.defaultToMinOffset) && isEmptyOrNil(hours.customizations))
  if (noTimingsAvailable) {
    return null;
  }

  if (hours?.customizations) {
    const formattedSpecialTimings = getSpecialTimings(hours.customizations);
    // prioritize special hours over normal hours
    const hourGroups = propOr(propOr([], dayOfWeek, formattedSpecialTimings), dateStr, formattedSpecialTimings)

    if (!isEmptyOrNil(hourGroups)) {
      const hourGroup = hourGroups.find((hg: SpecialHours) => hg.fromMinOffset > dayMinutesOffset);
      if (hourGroup) {
        return (hourGroup.fromMinOffset === hourGroup.toMinOffset) ? null : getTimeLabelFromOffset(hourGroup.fromMinOffset);
      }
    }

    const nextAvailableStoreOpeningDate: Date | undefined = pipe(
      omit([dayOfWeek, dateStr]), // remove sameday dates from object
      keys,
      map((d: string) => ({ //format days/date strings
        date: Array.from(daysOfWeek.values()).includes(d) ?
          parse(d, 'EEEE', date, { weekStartsOn: Math.min(date.getDay() + 1, 6) as DayNumbers }) :
          parse(d, 'MM-dd', date)
      })),
      uniq,
      sortBy(prop('date')),
      pluck('date'),
      find((d: Date) => !isEmptyOrNil(d) && (d >= date)),
    )(formattedSpecialTimings)

    // find Next Available Day hour group
    if (nextAvailableStoreOpeningDate) {
      const nextDayOfWeek = format(nextAvailableStoreOpeningDate, 'EEEE');
      const nextShortDayOfWeek = format(nextAvailableStoreOpeningDate, 'EEE');
      const nextDateStr = format(nextAvailableStoreOpeningDate, 'MM-dd');
      const nextDayHourGroups: SpecialHours[] = formattedSpecialTimings[nextDateStr] || propOr([], nextDayOfWeek, formattedSpecialTimings)

      const nextHourGroup = propOr({}, 0, nextDayHourGroups)

      // show Day label with time when next Day is other than tommorrow
      const nextDayStr = isEmptyOrNil(nextHourGroup.dayOfWeek) ? format(parse(nextHourGroup.specificDate, 'MM-dd', date), 'EEE') : nextShortDayOfWeek;
      return `${getTimeLabelFromOffset(nextHourGroup.fromMinOffset)} (${nextDayStr})`;
    }
  }

  return hours.defaultFromMinOffset === hours.defaultToMinOffset ? null : getTimeLabelFromOffset(hours.defaultFromMinOffset);
}

export const findSpecialDays = (d: Date, merchantSpecialHours: SpecialHours[]): SpecialHours[] | undefined => {
  const dateStr = format(d, 'MM-dd')
  const dayOfWeek = DAYS_OF_WEEK[d.getDay()];

  const specialHourDays: SpecialHours[] | undefined = merchantSpecialHours.filter((i: SpecialHours) => i.specificDate === dateStr);
  if (!isEmptyOrNil(specialHourDays)) return specialHourDays;

  const normalHourDays: SpecialHours[] | undefined = merchantSpecialHours.filter((i: SpecialHours) => i.dayOfWeek === dayOfWeek);
  return normalHourDays;
}


const isTimeSelectionInvalid = (timeValue: number, merchant: Merchant): boolean => {
  // if merchant hours or if default to and min time are null then do not disable the time
  const defaultFromMinOffset = pathOr(null, ["hours", "defaultFromMinOffset"], merchant)
  const defaultToMinOffset = pathOr(null, ["hours", "defaultToMinOffset"], merchant)
  const pickupStartsAtOffset = pathOr(0, ["orderingConfig", "pickupStartsAtOffset"], merchant)
  const pickupEndsAtOffset = pathOr(0, ["orderingConfig", "pickupEndsAtOffset"], merchant)

  if (!merchant?.hours || isEmptyOrNil(defaultFromMinOffset) || isEmptyOrNil(defaultToMinOffset)) return true

  // timeValue should lie between the defaultFromMinOffset and defaultToMinOffset
  const isInvalidTimeValue = timeValue < (defaultFromMinOffset + pickupStartsAtOffset) || timeValue >= (defaultToMinOffset - pickupEndsAtOffset)

  return isInvalidTimeValue
}

const isTimeSelectionInvalidForSpecialHours = (specialDays: SpecialHours[] = [], timeValue: number, merchant: Merchant) => {
  const pickupStartsAtOffset = pathOr(0, ["orderingConfig", "pickupStartsAtOffset"], merchant)

  // timeValue should lie between the toMinOffset and fromMinOffset
  const isValidTimeValue = (specialDay: SpecialHours) => {
    const { toMinOffset, fromMinOffset } = specialDay
    return (fromMinOffset + pickupStartsAtOffset) <= timeValue && toMinOffset >= timeValue
  }
  return !any(isValidTimeValue, specialDays)
}

export const isTimeItemDisabled = (timeValue: number, selectedDate: number, merchant: Merchant | undefined): boolean => {
  if (!merchant) return true;

  const merchantSpecialHours = pathOr([], ['hours', 'customizations'], merchant)
  const specialDays = findSpecialDays(new Date(selectedDate), merchantSpecialHours)

  const now = new Date()
  const currentMinsOffset = getRoundedMinutesOffset(now)
  const todayTS = now.setHours(0, 0, 0, 0)

  if (selectedDate === todayTS) {
    const invalidCheck = !isEmptyOrNil(specialDays)
      ? isTimeSelectionInvalidForSpecialHours(specialDays, timeValue, merchant)
      : isTimeSelectionInvalid(timeValue, merchant)
    return (timeValue < currentMinsOffset) || invalidCheck
  }

  return !isEmptyOrNil(specialDays)
    ? isTimeSelectionInvalidForSpecialHours(specialDays, timeValue, merchant)
    : isTimeSelectionInvalid(timeValue, merchant)
}

export type TimeOptionType = {
  label: string,
  minuteOffset: number
}

const formatMinutes = (m: number) => ("0" + m).slice(-2)

export const getStoreTimeOptions = (selectedDate: number, merchant: Merchant | undefined, gap = 20): TimeOptionType[] => {
  let timeOptions: TimeOptionType[] = []
  const AMPM: string[] = ['AM', 'PM']
  const todayTS = new Date().setHours(0, 0, 0, 0)

  let offset = 0

  AMPM.map((val: string) => {
    for (let i = 0; i < 12; i++) {
      const hour = (i === 0) ? 12 : i
      let j
      for (j = 0; j < 60 - gap; j += gap) {
        if (!isTimeItemDisabled(offset, selectedDate, merchant))
          timeOptions.push({
            label: `${hour}:${formatMinutes(j)} - ${hour}:${j + 20} ${val}`,
            minuteOffset: offset
          })
        offset += gap
      }
      if (!isTimeItemDisabled(offset, selectedDate, merchant))
        timeOptions.push({
          label: `${hour}:${formatMinutes(j)} - ${i + 1}:00 ${i === 11 ? val === 'AM' ? 'PM' : 'AM' : val}`,
          minuteOffset: offset
        })
      offset += gap
    }
  })
  if (timeOptions.length !== 0 && selectedDate === todayTS) {
    timeOptions = over(lensPath([0, 0]), (x: string) => `ASAP (${x})`, timeOptions)
  }

  return timeOptions
}