import React, { useState } from 'react'
import { If } from 'react-if';
import { useHistory, useParams } from 'react-router-dom';

import { MerchantCoupon } from 'bos_common/src/types/MerchantCouponType';
import { Merchant } from 'bos_common/src/types/MerchantType';

import MerchantPromotionsDrawer from '../../../components/common/MerchantPromotionsDrawer';
import MerchantFab from '../../../components/MerchantFab';
import MerchantHero from '../../../components/MerchantInfo/MerchantHero';
import MerchantInfoHeader from '../../../components/MerchantInfo/MerchantInfoHeader';
import MerchantMenu from '../../../components/MerchantMenu/MerchantMenu';
import RenderOfflineMerchantDrawer from '../../../components/MerchantOfflineDrawer';
import ShoppingCartDrawer from '../../../components/ShoppingCart/ShoppingCartDrawer';

import ShoppingCartContextProvider from '../../../context/ShoppingCart/ShoppingCartContextProvider';
import { isKioskDevice } from '../../../utils';

import ComboDealsDrawerPage from '../../ComboDealsDrawerPage';
import MerchandiseDrawerPage from '../../MerchandiseDrawerPage';
import { Divider } from '@material-ui/core';

interface IMerchantPageParams {
  merchantUserName: string,
  merchandiseId?: string,
  comboCouponId?: string,
}

const ProductMerchantMenuPage = ({ merchant, coupon }: { merchant: Merchant, coupon?: MerchantCoupon }): JSX.Element => {
  const history = useHistory()
  const isKioskModeEnabled = isKioskDevice();
  const [showCart, setShowCart] = useState<boolean>(false);
  const { merchandiseId, comboCouponId } = useParams<IMerchantPageParams>();

  const onSearchClick = () => {
    history.push(`/${merchant?.username}/search`);
  }

  return (
    <>
      <If condition={!isKioskModeEnabled}>
        <MerchantHero merchant={merchant} />
      </If>
      <MerchantInfoHeader merchant={merchant} />
      <Divider />
      <MerchantMenu merchant={merchant} coupon={coupon} />
      <RenderOfflineMerchantDrawer merchant={merchant} />
      <MerchantFab onShowCart={setShowCart} onSearch={onSearchClick} coupon={coupon} />
      <If condition={isKioskModeEnabled}>
        <ShoppingCartContextProvider>
          <ShoppingCartDrawer showCart={showCart} setShowCart={setShowCart} />
        </ShoppingCartContextProvider>
      </If>
      <MerchandiseDrawerPage
        merchant={merchant}
        merchandiseId={merchandiseId}
        coupon={coupon}
      />
      <ComboDealsDrawerPage
        merchant={merchant}
        comboCouponId={comboCouponId}
      />
      <MerchantPromotionsDrawer merchant={merchant} />
    </>
  );
}

export default ProductMerchantMenuPage;