import { Chip, Stack, styled } from "@material-ui/core";
import { AllInclusiveOutlined, Language } from "@material-ui/icons";
import { rgba } from "polished";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import {
  AmericanIcon,
  BowlIcon,
  BrazilianIcon,
  BurgerIcon,
  BurritoIcon,
  CakeIcon,
  ChickenWingsIcon,
  ChineseIcon,
  CocktailIcon,
  CoffeeBeanIcon,
  CoffeeCupIcon,
  EcoFriendlyIcon,
  GuacamoleIcon,
  OutlineHotpotIcon,
  JapaneseFoodIcon,
  KimchiIcon,
  LaddooIcon,
  FusionIcon,
  NoodlesIcon,
  PizzaIcon,
  RiceBowlIcon,
  SteakIcon,
  SushiIcon,
  TacoIcon,
  VietnameseFoodIcon,
  HotpotIcon,
  PastaIcon,
  SaladIcon,
  RamenIcon,
  SandwichIcon,
  SoupIcon,
  FishIcon,
  BeverageIcon,
  BreakfastIcon,
  ItalianIcon,
  JuiceIcon,
} from "../assets/icons/categories";
import { MarketplaceContext } from "../context/MarketplaceContext/MarketplaceContext";
import { isEmptyOrNil } from "../utils";

type CategoryFilter = {
  value: string;
  label: string;
  icon: any;
  selectedIcon?: any;
};

export const StyledChip = styled(Chip)(({ theme }) => ({
  "&.MuiChip-root": {
    borderRadius: 8,
    cursor: "pointer",
    border: "1px solid grey",
    fontSize: 14,

    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    height: 50,
    minWidth: 80,
    marginBottom: 4,

    "&.MuiChip-filledPrimary": {
      backgroundColor: rgba(theme.palette.primary.main, 0.25),
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },

    "& .MuiChip-icon": {
      margin: 0,
      height: 24,
    },

    "&.filters-chip": {
      "&.MuiChip-filledDefault": {
        backgroundColor: "transparent",
        border: 0,
      },
    },
  },
}));

export const MERCHANTS_CATEGORIES_LIST: CategoryFilter[] = [
  {
    value: '',
    label: 'All',
    icon: <AllInclusiveOutlined style={{ fontSize: '18px' }} />
  },
  {
    value: "american",
    label: "American",
    icon: <AmericanIcon />,
  },
  {
    value: "asian",
    label: "Asian",
    icon: <BowlIcon />,
  },
  {
    value: "asian fusion",
    label: "Asian Fusion",
    icon: <RiceBowlIcon />,
  },
  // {
  //   value: 'bakery',
  //   label: 'Bakery',
  //   icon: <BreadIcon />
  // },
  {
    value: "bar",
    label: "Bar",
    icon: <CocktailIcon />,
  },
  {
    value: "bbq",
    label: "BBQ",
    icon: <SteakIcon />,
  },
  {
    value: "beverage",
    label: "Beverage",
    icon: <BeverageIcon />,
  },
  {
    value: "brazilian",
    label: "Brazilian",
    icon: <BrazilianIcon />,
  },
  {
    value: "breakfast",
    label: "Breakfast",
    icon: <BreakfastIcon />,
  },
  {
    value: "burger",
    label: "Burger",
    icon: <BurgerIcon />,
  },
  {
    value: "burrito",
    label: "Burrito",
    icon: <BurritoIcon />,
  },
  {
    value: "cafe",
    label: "Café",
    icon: <CoffeeCupIcon />,
  },
  {
    value: "chinese",
    label: "Chinese",
    icon: <ChineseIcon />,
  },
  {
    value: "coffee",
    label: "Coffee",
    icon: <CoffeeBeanIcon />,
  },
  {
    value: "desserts",
    label: "Desserts",
    icon: <CakeIcon />,
  },
  {
    value: "fusion",
    label: "Fusion",
    icon: <FusionIcon />,
  },
  {
    value: "hot pot",
    label: "Hot Pot",
    icon: <HotpotIcon />,
  },
  {
    value: "indian",
    label: "Indian",
    icon: <LaddooIcon />,
  },
  {
    value: "italian",
    label: "Italian",
    icon: <ItalianIcon />,
  },
  {
    value: "japanese",
    label: "Japanese",
    icon: <JapaneseFoodIcon />,
  },
  {
    value: "juice",
    label: "Juice",
    icon: <JuiceIcon />,
  },
  {
    value: "korean",
    label: "Korean",
    icon: <KimchiIcon />,
  },
  {
    value: "mexican",
    label: "Mexican",
    icon: <GuacamoleIcon />,
  },
  {
    value: "noodles",
    label: "Noodles",
    icon: <NoodlesIcon />,
  },
  {
    value: "pasta",
    label: "Pasta",
    icon: <PastaIcon />,
  },
  {
    value: "pizza",
    label: "Pizza",
    icon: <PizzaIcon />,
  },
  {
    value: "ramen",
    label: "Ramen",
    icon: <RamenIcon />,
  },
  {
    value: "salad",
    label: "Salad",
    icon: <SaladIcon />,
  },
  {
    value: "sandwich",
    label: "Sandwich",
    icon: <SandwichIcon />,
  },
  {
    value: "soup",
    label: "Soup",
    icon: <SoupIcon />,
  },
  {
    value: "seafood",
    label: "Seafood",
    icon: <FishIcon />,
  },
  {
    value: "sushi",
    label: "Sushi",
    icon: <SushiIcon />,
  },
  {
    value: "tacos",
    label: "Tacos",
    icon: <TacoIcon />,
  },
  {
    value: "thai",
    label: "Thai",
    icon: <OutlineHotpotIcon />,
  },
  {
    value: "vietnamese",
    label: "Vietnamese",
    icon: <VietnameseFoodIcon />,
  },
  {
    value: "vegetarian",
    label: "Vegetarian",
    icon: <EcoFriendlyIcon />,
  },
  {
    value: "wings",
    label: "Wings",
    icon: <ChickenWingsIcon />,
  },
];

const MerchantCategoriesFilters = ({ redirectTo }: { redirectTo?: string }) => {
  const { searchState, handleSearchStateChange } = useContext(MarketplaceContext);
  const history = useHistory()

  const handleChange = (key: string, value: string) => {
    handleSearchStateChange(key, value);

    if (!isEmptyOrNil(redirectTo)) {
      history.push(redirectTo!);
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      overflow="auto"
      sx={{ marginBottom: 2, marginTop: 2 }}
    >
      {
        MERCHANTS_CATEGORIES_LIST.map((category) => {
          const isCategorySelected = searchState.selectedCategory === category.value;
          return (
            <StyledChip
              key={category.value}
              label={category.label}
              icon={
                isCategorySelected && category.selectedIcon
                  ? category.selectedIcon
                  : category.icon
              }
              variant={"filled"}
              className="filters-chip"
              color={isCategorySelected && isEmptyOrNil(redirectTo) ? "primary" : "default"}
              onClick={() =>
                handleChange(
                  "selectedCategory",
                  isCategorySelected ? "" : category.value
                )
              }
            />
          );
        })
      }
    </Stack>
  );
};

export default MerchantCategoriesFilters;
