import React from 'react';
import Star from "@material-ui/icons/Star";
import { useTheme } from "@material-ui/core";

export interface StarReviewProps {
  value: number;
  onChangeRating: (value: number) => void
}

const getColor = (value: number): string => {

  const theme = useTheme();

  if (value > 4) {
    return '#E34D28';
  }
  else if (value > 2) {
    return '#F7CB2E';
  }
  else if (value > 0) {
    return theme.palette.secondary.light;
  }
  else {
    return 'rgba(229, 229, 229, 0.75)';
  }
}

export const StarReview = (props: Readonly<StarReviewProps>): JSX.Element => {
  const { value, onChangeRating } = props;
  const color = "#E34D28" //getColor(value);

  const handleClickRate = (value: number) => {
    onChangeRating(value);
  }

  return (
    <div style={{ display: 'inline-flex', flexDirection: 'row', marginLeft: 'auto' }}>
      {[1, 2, 3, 4, 5].map(starValue =>
        <Star
          key={starValue}
          onClick={() => handleClickRate(starValue)}
          style={{ color: value >= starValue ? color : 'rgba(229, 229, 229, 0.75)' }}
        />
      )}
    </div>
  );
}

export default StarReview;
