import React, { useMemo } from "react";
import { AccessTime } from "@material-ui/icons";
import { If } from "react-if";

import { nextOpenHour } from "../../services/hoursUtils";
import { Merchant } from "../../services/models";
import { isStoreOpen } from "../../utils";
import { CardHighlightChip } from "../Classifieds/styledComponents";

type MerchantOrderAheadChipProps = {
  merchant: Merchant;
};

const MerchantOrderAheadChip = (props: MerchantOrderAheadChipProps): JSX.Element | null => {
  const { merchant } = props;

  const isMerchantStoreOpen = isStoreOpen(merchant, new Date());
  const storeOpenTimingsLabel = useMemo(() => !isMerchantStoreOpen ? nextOpenHour(merchant) : null, [isMerchantStoreOpen, merchant]);

  const isOrderAheadVisible = !isMerchantStoreOpen && merchant.orderingConfig?.orderAhead && storeOpenTimingsLabel

  return (
    <If condition={isOrderAheadVisible}>
      <CardHighlightChip
        icon={<AccessTime />}
        label={`Store opens at ${storeOpenTimingsLabel ?? ""}`}
        color="secondary"
        size="small"
        variant="filled"
        sx={{ backgroundColor: "#E34D28" }}
      />
    </If>
  );
};

export default MerchantOrderAheadChip;
