import React, { useContext, useEffect, useState } from "react";
import { createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import { FavoriteBorderSharp, Favorite as FavIcon } from "@material-ui/icons";
import useAxios from "axios-hooks";
import { propOr } from "ramda";
import { Else, If, Then } from "react-if";
import { useLocation, useHistory } from "react-router";

import { UserContext } from "../../bos_common/src/context/UserContext";
import axios from "../../bos_common/src/services/backendAxios";
import { Merchandise, Merchant, Favorite } from "../../services/models";

import { SignupEntrance } from "../User/SignupDrawer";
import { deleteQueryParamsFromLocation, extractQueryParamsFromLocation, isKioskDevice } from "../../utils";
import { useAppDispatch } from "../../redux/hooks";
import { showSignUpDrawer } from "../../redux/slice/auth/authActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&.MuiButtonBase-root': {
        backgroundColor: 'white',
        transition: 'all 200ms linear',
        boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.07)",

        '&:hover': {
          backgroundColor: `#FFFFFF66`,
        }
      }
    },
  })
);

interface FavoriteButtonProps {
  merchant: Merchant;
  merchandise?: Merchandise;
  onUpdateSuccess?: (favorited: boolean) => void;
}
const FavoriteButton = (props: FavoriteButtonProps): React.ReactElement | null => {
  if (isKioskDevice()) {
    return null;
  }

  const classes = useStyles();

  const { user } = useContext(UserContext);
  const [favorited, setFavorited] = useState<boolean>(false);
  const [favLoading, setFavLoading] = useState<boolean>(false);

  const location = useLocation();
  const history = useHistory();
  const params = extractQueryParamsFromLocation(location);
  const isFavoriteCallback = Boolean(propOr("", "favorite", params));

  const reduxDispatch = useAppDispatch();

  const [, executeFavoritePut] = useAxios(
    { url: "/favorite/update", method: "POST" },
    { manual: true }
  );

  const toggleMerchantFavoriteState = (newFav: boolean) => {
    setFavorited(newFav);

    setFavLoading(true);
    executeFavoritePut({
      data: {
        userId: user?.id,
        merchantId: props.merchant.id,
        merchandiseId: props.merchandise?.id,
        favorited: newFav,
      },
    })
      .then((response) => {
        setFavLoading(false);
        if (response.status === 200) {
          props.onUpdateSuccess && props.onUpdateSuccess(newFav);
        } else {
          setFavorited(!newFav);
        }
      })
      .catch((err) => {
        setFavLoading(false);
        setFavorited(!newFav);
      });
  };

  const getStoreFavoriteState = () => {
    setFavLoading(true);
    axios
      .get<Favorite[]>("/favorites", {
        params: {
          userId: user?.id,
          merchantId: props.merchant.id,
          merchandiseId: props.merchandise?.id,
        },
      })
      .then((response) => {
        setFavLoading(false);
        if (response.status === 200) {
          const isFavorite = response.data.length > 0;
          setFavorited(isFavorite);

          if (!isFavorite && isFavoriteCallback){
            deleteQueryParamsFromLocation(location, ['favorite'], history)
            toggleMerchantFavoriteState(true);
          }

        }
      })
      .catch((err) => {
        console.log(err);
        setFavLoading(false);
      });
  };

  useEffect(() => {
    if (user) {
      getStoreFavoriteState();
    }
  }, [user]);

  const saveFavorite = () => {
    if (user && props.merchant) {
      toggleMerchantFavoriteState(!favorited);
    } else {
      setShowSignup(true);
    }
  };

  const setShowSignup = (open: boolean) => {
    reduxDispatch(showSignUpDrawer({
      drawerShown: open,
      signupEntrance: SignupEntrance.FAVORTIE,
    }));
  }

  return (
    <>
      <IconButton
        className={classes.root}
        aria-label="favorite"
        disabled={favLoading}
        onClick={saveFavorite}
      >
        <If condition={favorited}>
          <Then>
            <FavIcon fontSize="small" color="primary" />
          </Then>
          <Else>
            <FavoriteBorderSharp fontSize="small" color="inherit" />
          </Else>
        </If>
      </IconButton>
    </>
  );
};

export default FavoriteButton;
