import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      flex: "1 1 auto",

      "& .category-info": {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
      },
      "&:last-child": {
        minHeight: "calc(100vh - 150px)",
      },
      '& .lazyload-wrapper': {
        height: '100%'
      },
    },
    merchantMenuContainer: {
      "& .noMatchFound": {
        height: "68vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        "& img": {
          height: theme.spacing(25)
        },

        "& .noMatchText": {
          color: theme.palette.secondary.light,
          marginTop: theme.spacing(1)
        }
      },
    },

    mediaWrapper: {
      margin: theme.spacing(0, 3),

      '& .mediaHeading': {
        fontSize: theme.spacing(3),
        fontWeight: 700,
        marginTop: theme.spacing(2),
      }
    }
  })
);

export default useStyles;
