import React, { useEffect } from 'react';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { History } from "history";
import { Provider } from "react-redux";
import store from './redux/store';

import CallbackPage from './bos_common/src/pages/CallbackPage';
import PrivacyPolicyPage from './bos_common/src/pages/PrivacyPolicyPage';
import TermsOfServicePage from './bos_common/src/pages/TermsOfServicePage';
import CopyrightPolicyPage from './bos_common/src/pages/CopyrightPolicyPage';
import UserContextProvider from './bos_common/src/context/UserContextProvider';
import ErrorBoundary, { ErrorReporterParams } from './bos_common/src/components/ErrorBoundary';
import AppErrorPage from './bos_common/src/pages/AppErrorPage';

import ProtectedRoute from './components/ProtectedRoute';
import NoneExistPage from './pages/NoneExistPage';
import MerchantPage from './pages/MerchantPage';
import MerchantSearchPage from './pages/MerchantSearchPage';
import ShoppingCartPage from './pages/ShoppingCart';
import HomePage from './pages/HomePage';
import OrderStatusPage from './pages/OrderStatusPage';
import KioskOrderStatusPage from './pages/KioskOrderStatusPage';
import CheckoutPage from './pages/CheckoutPage';
import LogoutPage from './pages/LogoutPage';
import UserSettingsPage from './pages/UserSettingsPage';
import OneMarketPointsPage from './pages/OneMarketPointsPage';
import FavoritesPage from './pages/FavoritesPage';
import LoginPage from './pages/LoginPage';
import NotificationPage from './pages/NotificationPage';
import OrdersPage from './pages/OrdersPage';
import AffiliateEducationPage from './pages/AffiliateEducationPage';
import HelpOneMarketPointsPage from './pages/HelpOneMarketPointsPage';
import PlaceOpenCheckOrderPage from './pages/PlaceOpenCheckOrderPage';
import OpenCheckOrderStatusPage from './pages/OpenCheckOrderStatusPage';
import VisitHistoryPage from "./pages/VisitHistoryPage";
import ReviewMerchandisePage from './pages/ReviewMerchandisePage';
import LootboxPage from './pages/LootboxPage';
import LootboxVotesPage from './pages/LootboxVotesPage';
import MapSearchPage from './pages/MapSearchPage';
import UserPaymentMethods from './pages/UserPaymentMethods';
import MerchantStoryPage from './pages/MerchantStoryPage';
import LocalGuidePage from './pages/LocalGuidePage';
import MerchantFeedPage from './pages/MerchantFeedPage';
import StoreFrontPage from './pages/StoreFrontPage';
import ShortURLGoPage from './pages/ShortURLGoPage';
import PlatformChatPage from './pages/PlatformChatPage';

import NativeAuthHandler from './components/NativeAuthHandler';
import AppContextProvider from './context/AppContextProvider';
import MUIThemeProvider from "./theme/MUIThemeProvider"
import ShoppingCartContextProvider from './context/ShoppingCart/ShoppingCartContextProvider';
import VisitedMerchantContextProvider from './context/VisitedMerchantContext/VisitedMerchantContextProvider';

import { isKioskDevice, isEmbeddedMode } from './utils';
import logger from './services/logger';
import config from './config/config';
import { OrderType } from './services/models';

import RewardsEducationDrawer from './components/Rewards/RewardsEducationDrawer';
import ReservationStatusPage from './components/Reservation/ReservationStatusPage';
import MarketplaceContextProvider from './context/MarketplaceContext/MarketplaceContextProvider';
import { CheckoutMode } from './pages/ShoppingCart/types';
import { SAN_MATEO_AREA_ZIPCODES } from './config/constants';

import './App.css';
import SubscriptionSettingsPage from './pages/SubscriptionSettingsPage';

function App({ history }: { history: History }): React.ReactElement {
  const skipLogin = isKioskDevice() || isEmbeddedMode()

  useEffect(() => {
    logger.setTag('AppType', config.appConfig.appType)
    logger.setTag('DeviceType', config.appConfig.deviceType)
    logger.setTag('ClientType', config.appConfig.clientType)
  }, []);

  const logErrorToSentry = (params: ErrorReporterParams) => {
    const { source, error, errorInfo } = params
    logger.error(error, { source, errorInfo })
  }

  return (
    <Provider store={store}>
      <MUIThemeProvider>
        <ErrorBoundary
          errorFallbackComponent={<AppErrorPage />}
          source={config.appConfig.appType}
          reportError={logErrorToSentry}
        >
          <div className="App">
            <UserContextProvider skipLogin={skipLogin}>
              <AppContextProvider>
                <VisitedMerchantContextProvider>
                  {/* Todo: Need to move 'MarketplaceContextProvider' to redux */}
                  <MarketplaceContextProvider>
                    <Router history={history}>
                      <Switch>
                        <Route exact path="/callback" component={CallbackPage} />
                        <Route exact path="/" component={HomePage} />
                        {/* <Route exact path="/home" component={HomePage} /> */}
                        <Route exact path="/logout" component={LogoutPage} />
                        <Route exact path="/login" component={LoginPage} />
                        <Route exact path="/go/:identifier" component={ShortURLGoPage} />
                        <Route exact path="/checkout" component={CheckoutPage} />
                        <Route exact path="/cart">
                          <ShoppingCartContextProvider>
                            <ShoppingCartPage />
                          </ShoppingCartContextProvider>
                        </Route>
                        <Route exact path="/cart-pay-now">
                          <ShoppingCartContextProvider>
                            <ShoppingCartPage checkoutModeOverride={CheckoutMode.PAY_NOW} />
                          </ShoppingCartContextProvider>
                        </Route>
                        <Route exact path="/discovery" component={MapSearchPage} />
                        <Route exact path="/sanmateofest">
                          <MapSearchPage liteView zipcodes={SAN_MATEO_AREA_ZIPCODES} />
                        </Route>
                        <Route exact path="/notfound" component={NoneExistPage} />
                        <Route exact path="/help/privacy-policy" component={PrivacyPolicyPage} />
                        <Route exact path="/help/terms-of-use" component={TermsOfServicePage} />
                        <Route exact path="/help/copyright-policy" component={CopyrightPolicyPage} />
                        <Route exact path="/help/1mpoints" component={HelpOneMarketPointsPage} />
                        <Route exact path="/help/affiliate" component={AffiliateEducationPage} />
                        <Route exact path="/feed" component={MerchantFeedPage} />
                        <ProtectedRoute exact path="/settings" component={UserSettingsPage} />
                        <ProtectedRoute exact path="/1mpoints" component={OneMarketPointsPage} />
                        <ProtectedRoute exact path="/orders" component={OrdersPage} />
                        <ProtectedRoute exact path="/subscription-settings" component={SubscriptionSettingsPage} />
                        <ProtectedRoute exact path="/subscription-settings/:merchantId" component={SubscriptionSettingsPage} />
                        <ProtectedRoute exact path="/lootbox/votes" component={LootboxVotesPage} />
                        <ProtectedRoute exact path="/lootbox" component={LootboxPage} />
                        <ProtectedRoute exact path="/place-open-check-order" component={PlaceOpenCheckOrderPage} />
                        <ProtectedRoute exact path="/checkout-preauth">
                          <CheckoutPage isPreAuth />
                        </ProtectedRoute>
                        <ProtectedRoute exact path="/open-check-order-status/:orderId" component={OpenCheckOrderStatusPage} />
                        <ProtectedRoute exact path="/oc-order/:orderId" component={OpenCheckOrderStatusPage} />
                        <ProtectedRoute exact path="/payment-method" component={UserPaymentMethods} />
                        <Route exact path="/visit-history" component={VisitHistoryPage} />
                        <Route exact path="/order/:orderId" component={isKioskDevice() ? KioskOrderStatusPage : OrderStatusPage} />
                        <Route exact path="/order/:orderId/reviews" component={OrderStatusPage} />
                        <Route exact path="/reservation/:reservationId" component={ReservationStatusPage} />
                        <Redirect exact path="/sanmateo" to="/" />
                        {/* <Route exact path="/sanmateo" component={SanMateoDiscoveryPage} />
                        <Route exact path="/sanmateo/discovery" component={MerchantDiscoveryPage} /> */}
                        <Route exact path="/sanmateo" render={() => <Redirect exact to="/" />} />
                        <Route exact path="/sanmateo/discovery" render={() => <Redirect exact to="/discovery" />} />
                        <Route exact path="/:merchantUserName/chat" component={PlatformChatPage} />
                        <Route exact path="/:merchantUsername/story" component={MerchantStoryPage} />
                        <Route exact path="/:merchantUsername/story/:storyId" component={MerchantStoryPage} />
                        <Route exact path="/:merchantUserName/item/:merchandiseId" component={MerchantPage} />
                        <Route exact path="/:merchantUserName/item/:merchandiseId/reviews" component={MerchantPage} />
                        <Route exact path="/:merchantUserName/store" component={StoreFrontPage} />
                        <Route exact path="/:merchantUserName/combo/:comboCouponId" component={MerchantPage} />
                        <Route exact path="/:merchantUserName/reserve">
                          <MerchantPage orderType={OrderType.RESERVATION} />
                        </Route>
                        <Route exact path="/:merchantUserName" component={MerchantPage} />
                        <Route exact path="/:merchantUserName/search" component={MerchantSearchPage} />
                        <Route exact path="/:merchantUserName/search/item/:merchandiseId" component={MerchantSearchPage} />
                        <Route exact path="/:merchantUserName/search/item/:merchandiseId/reviews" component={MerchantSearchPage} />
                        <Route exact path="/guides/:guideId" component={LocalGuidePage} />
                        <ProtectedRoute exact path="/order/:orderId/merchandise/:merchandiseId/reviews" component={ReviewMerchandisePage} />
                        <Route render={() => <NoneExistPage title="Page not found" />} />
                      </Switch>
                      <NotificationPage />

                      {/* Rewards Education Info Drawer, can be trigger from redux store State */}
                      <RewardsEducationDrawer />
                      {isEmbeddedMode() && <NativeAuthHandler />}
                    </Router>
                  </MarketplaceContextProvider>
                </VisitedMerchantContextProvider>
              </AppContextProvider>
            </UserContextProvider>
            <audio id="app_order_update_audio">
              <source src="https://1m-assets.s3.us-west-1.amazonaws.com/audio/order-update.mp3" type="audio/mpeg" />
              <track />
            </audio>
            <audio id="app_earn_1m_points_audio">
              <source src="https://1m-assets.s3.us-west-1.amazonaws.com/audio/earned-1m-points.mp3" type="audio/mpeg" />
              <track />
            </audio>
            <audio id="app_add_to_cart_audio">
              <source src="https://1m-assets.s3.us-west-1.amazonaws.com/audio/add-to-cart.mp3" type="audio/mpeg" />
              <track />
            </audio>
          </div >
        </ErrorBoundary >
      </MUIThemeProvider >
    </Provider>
  )
}

export default App;
