import React from "react";
import { createStyles, makeStyles, Theme, Box } from "@material-ui/core";

import HeaderTopCTA from "../StoreFront/HeaderTopCTA";
import MerchantHeaderSkeleton from "./MerchantHeaderSkeleton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardsWrapper: {
      marginTop: theme.spacing(-4), // offsets into picture
      width: "100%",
      position: 'relative',
      padding: theme.spacing(2),
      background: theme.palette.background.paper,
      borderRadius: theme.spacing(2.8, 2.8, 0, 0),
      boxSizing: "border-box",
    },
    root: {
      minHeight: 300,

      '& *': {
        boxSizing: 'border-box'
      },
    },
  })
);

const StoreFrontHeroSkeleton = (): JSX.Element => {

  const classes = useStyles();

  return (
    <div>
      <Box className={classes.root} sx={{ backgroundColor: (theme) => theme.palette.secondary.light }}>
        <HeaderTopCTA />
      </Box>
      <div className={classes.cardsWrapper}>
        <MerchantHeaderSkeleton />
      </div>
    </div>
  );
};

export default StoreFrontHeroSkeleton;
