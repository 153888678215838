import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { If } from 'react-if';
import { Card, createStyles, Divider, FormControl, FormControlLabel, FormLabel, makeStyles, Radio, RadioGroup, Theme, Typography } from '@material-ui/core';
import { pathOr } from 'ramda';

import QuantityInput from '../MerchantMenu/QuantityInput';
import { AppContext, CartItemType } from '../../context/AppContext';
import { Merchandise } from '../../services/models';
import renderPrice from '../../bos_common/src/components/Price';
import { ShoppingCartContext } from '../../context/ShoppingCart/ShoppingCartContext';
import { isEmptyOrNil, useWindowStorage } from '../../bos_common/src';
import { LOCALSTORAGE_MARKETPLACE_APP_KEYS } from '../../services/localStorage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .errorMessage': {
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(1),
        fontSize: 12
      },

      "& .cartItemsWrapper": {
        padding: theme.spacing(0, 1),
      }
    },
  })
);

export interface QuantityChangeParams {
  count: number;
  change: number;
}

type MerchandiseNullable = Merchandise | null | undefined;
type BagsAddition = 'with' | 'without' | "";

type CartBagItemCardProps = {
  cartItemCardRef: React.MutableRefObject<HTMLElement | undefined>;
  toggleRecommendationDrawer: (_: boolean) => void;
}

export const CartBagItemCard = ({cartItemCardRef, toggleRecommendationDrawer}: CartBagItemCardProps): JSX.Element | null => {
  const { merchantConfig, cart, removeItemFromCart } = useContext(AppContext)
  const { shoppingBagError, setShoppingBagError } = useContext(ShoppingCartContext);

  const [bagsCount, setBagsCount] = useState(0);
  const [utensilsCount, setUtensilsCount] = useState(0);
  const [merchantCarryoutBag, setMerchantCarryoutBag] = useState<MerchandiseNullable>(null);
  const [merchantUtensils, setMerchantUtensils] = useState<MerchandiseNullable>(null);
  const [bagChosen, setBagChosen] = useWindowStorage(LOCALSTORAGE_MARKETPLACE_APP_KEYS.CARRYOUT_BAG_CHOSEN, false, 'sessionStorage');

  const classes = useStyles();

  useEffect(() => {
    const carryoutBag = pathOr(null, ["merchant", "orderingConfig", "carryoutBag"], merchantConfig);
    const utensils = pathOr(null, ["merchant", "orderingConfig", "utensils"], merchantConfig);

    if (!carryoutBag && !utensils) {
      return;
    }

    setMerchantCarryoutBag(carryoutBag);
    setMerchantUtensils(utensils);
    const carryoutBagInCart: CartItemType | undefined = cart.find((item) => item.id === carryoutBag.id);
    const utensilsInCart = cart.find((item) => item.id === utensils?.id);
    const bagsQuantity = carryoutBagInCart?.quantity || 0;
    const utensilsQuantity = utensilsInCart?.quantity || 0;
    setBagsCount(bagsQuantity);
    setUtensilsCount(utensilsQuantity);
    if ((bagsQuantity + utensilsQuantity) > 0) {
      setBagChosen(true);
    }
  }, [merchantConfig, cart]);

  if (!merchantCarryoutBag && !merchantUtensils) {
    return null;
  }

  const handleChangeBags = (e: SyntheticEvent) => {
    const bagsAddition: BagsAddition = (e.target as HTMLInputElement).value as BagsAddition;
    if (bagsAddition === 'with') {
      toggleRecommendationDrawer!(true);
    } else {
      if (merchantCarryoutBag) {
        for (let i=0; i<bagsCount; i++) {
          removeItemFromCart({ merchandiseId: merchantCarryoutBag.id, modifiersSignature: undefined });
        }
      }
      if (merchantUtensils) {
        for (let i=0; i<utensilsCount; i++) {
          removeItemFromCart({ merchandiseId: merchantUtensils!.id, modifiersSignature: undefined });
        }
      }

      setBagsCount(0);
      setUtensilsCount(0);
    }
    setShoppingBagError(false);
    setBagChosen(true);
  }

  return (
    <Card elevation={3} className={classes.root} ref={cartItemCardRef}>
      <div className='cartItemsWrapper'>
        <FormControl variant="standard" fullWidth error={shoppingBagError}>
          <FormLabel color="primary" style={{ padding: '8px 0px' }}>Bags?</FormLabel>
          <Divider />
          <RadioGroup
            aria-label="Carry out bags"
            name="radio-buttons-group"
            value={bagChosen ? ((bagsCount + utensilsCount) > 0 ? 'with' :'without') : ''}
            onChange={handleChangeBags}
          >
            <FormControlLabel value="without" control={<Radio color="primary" />} label="No"  />
            <Divider />
            <FormControlLabel value="with" control={<Radio color="primary" />} label="Yes" />

            <If condition={shoppingBagError}>
              <div className='errorMessage'>Please choose a bag option, required</div>
            </If>
          </RadioGroup>
        </FormControl>
      </div>
    </Card>
  );
}
