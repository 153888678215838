import { AxiosRequestConfig, AxiosResponse } from "axios";
import { MerchantStory, StoryComment, StoryLike } from "bos_common/src/types/MerchantStoryType";
import axios from "../bos_common/src/services/backendAxios";

export type MerchantStoriesResponse = AxiosResponse<MerchantStory[]>;
export type MerchantStoryResponse = AxiosResponse<MerchantStory>;
export type StoryCommentsResponse = AxiosResponse<StoryComment[]>;
export type StoryLikeResponse = AxiosResponse<StoryLike>;

export interface DeleteCommentParams {
  id: number;
  commentId: number;
}

export interface Pagination {
  pageNumber: number;
  pageSize: number;
}

export const getMerchantStories = (merchantUsername: string): Promise<MerchantStoriesResponse> => {
  return axios({
    url: `/merchants/stories?merchantUsername=${merchantUsername}`,
    method: 'GET',
  });
}

export const getMerchantStoryByMerchantId = (merchantId: string): Promise<MerchantStoriesResponse> => {
  return axios({
    url: `/merchants/stories?merchantId=${merchantId}`,
    method: 'GET',
  });
}

export const getMerchantStoryDetail = (id: number): Promise<MerchantStoryResponse> => {
  return axios({
    url: `stories/${id}`,
    method: 'GET',
  });
}

export const postLikeMerchantStory = (id: number, request: AxiosRequestConfig): Promise<MerchantStoryResponse> => {
  return axios({
    url: `stories/${id}/likes`,
    method: 'POST',
    headers: request.headers,
  });
}

export const getMerchantStoryLiked = (id: number, request: AxiosRequestConfig): Promise<StoryLikeResponse> => {
  return axios({
    url: `stories/${id}/liked`,
    method: 'GET',
    headers: request.headers,
  });
}

export const getMerchantStoryComments = (story: Partial<MerchantStory>, pagination: Pagination = { pageNumber: 1, pageSize: 20 }): Promise<StoryCommentsResponse> => {
  const { pageNumber, pageSize } = pagination;

  return axios({
    url: `stories/${story?.id}/comments?pageSize=${pageSize}&pageNumber=${pageNumber ?? 1}`,
    method: 'GET',
  });
}

export const postCommentMerchantStory = (storyComment: Partial<StoryComment>, request: AxiosRequestConfig): Promise<MerchantStoryResponse> => {
  return axios({
    url: `stories/${storyComment?.merchantStoryId}/comments`,
    method: 'POST',
    headers: request.headers,
    data: {
      content: storyComment?.content,
      replyToCommentId: storyComment?.replyToCommentId,
    }
  });
}

export const deleteCommentMerchantStory = (params: DeleteCommentParams, request: AxiosRequestConfig): Promise<MerchantStoryResponse> => {
  const { id, commentId } = params;

  return axios({
    url: `stories/${id}/comments/${commentId}`,
    method: 'DELETE',
    headers: request.headers,
  });
}

export default {
  getMerchantStories,
  getMerchantStoryByMerchantId,
  getMerchantStoryDetail,
  postLikeMerchantStory,
  getMerchantStoryLiked,
  getMerchantStoryComments,
  postCommentMerchantStory,
}
