import { LineItem, Order } from "../../../bos_common/src/types/OrderTypes";
import { RootState } from "../../store";

export const getOrder = (state: RootState) => (id: string): Order => {
  const { entities } = state.order;
  return entities.byId[id];
}

export const getOrderLoadingState = (state: RootState): boolean => {
  return state.order.loading;
}

export const getLineItem = (state: RootState) => (orderId: string, merchandiseId: string): LineItem | undefined => {
  const { entities } = state.order;
  return entities.byId[orderId]?.lineItems.find((item) => item.id === merchandiseId);
}
