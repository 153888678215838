
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, styled } from '@material-ui/core';
import { Cancel, CheckCircle } from '@material-ui/icons';
import { Merchant } from "../../bos_common/src/types/MerchantType";
import LootboxCounter from './LootboxCounter';

export const LootboxVotedCustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiTypography-root": {
    fontSize: '0.875rem',
    color: theme.palette.secondary.light
  },
  "& .MuiDialogTitle-root": {
    textAlign: 'center',
    "& .MuiTypography-root": {
      fontWeight: 600,
      fontSize: '1.125rem',
      color: '#364245',
    },
  },
  "& .MuiSvgIcon-root": {
    color: '#F7932E',
    marginBottom: '-8px',
  },
  "& .MuiDialogActions-root": {
    justifyContent: 'center',
    "& .MuiButton-root": {
      width: '100%',
      color: '#FFF',
      fontSize: '0.875rem',
    },
  },
  "& .lootbox-counter": {
    fontSize: '0.625rem',
    fontWeight: 'bold',
    borderRadius: '3px',
    marginLeft: '8px',
  },
}));

export interface SimpleDialogProps {
  open: boolean;
  votesRemaining: number;
  storeVoteCount: number;
  store: Merchant,
  onClose: () => void;
}

export const LootboxVotedDialog = (props: Readonly<SimpleDialogProps>): JSX.Element => {
  const { onClose, open, votesRemaining, storeVoteCount, store } = props;

  const handleClose = () => {
    onClose();
  };

  const handleClickDone = () => {
    onClose();
  };

  const renderTitle = () => {
    if (votesRemaining <= 0) {
      return (
        <DialogTitle>
          <Cancel />
          <span>Out of Votes</span>
        </DialogTitle>
      );
    }
    else if (storeVoteCount <= 0) {
      return (
        <DialogTitle>
          <CheckCircle />
          <span>First Voter</span>
          <LootboxCounter count={votesRemaining} suffix={`${votesRemaining === 1 ? 'vote' : 'votes'} remaining`} />
        </DialogTitle>
      );
    }
    else {
      return(
        <DialogTitle>
          <CheckCircle />
          <span>Voted</span>
          <LootboxCounter count={votesRemaining} suffix={`${votesRemaining === 1 ? 'vote' : 'votes'} remaining`} />
        </DialogTitle>
      );
    }
  }

  const renderMessage = () => {
    if (votesRemaining <= 0) {
      return (
        <Typography>
          We still appreciate your passion! We will give you another 10 votes on the first day of next month.
        </Typography>
      );
    }
    else if (storeVoteCount === 1) {
      return (
        <Typography>
          Thanks for introducing {store.username} to us. You will get 150 reward points when they launch on One Market.
        </Typography>
      );
    }
    else {
      return(
        <Typography>
          Thanks for introducing {store.username} to us. You will get 150 reward points when they launch on One Market.
        </Typography>
      );
    }
  }

  return (
    <LootboxVotedCustomDialog onClose={handleClose} open={open} maxWidth="sm">
      {renderTitle()}
      <DialogContent>
        <Typography>
          {renderMessage()}
        </Typography>
        
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClickDone}>Done</Button>
      </DialogActions>
    </LootboxVotedCustomDialog>
  );
}

export default LootboxVotedDialog;
