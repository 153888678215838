import React, { useContext, useEffect, useMemo, useState } from 'react'
import { makeStyles, createStyles, Theme, TextField, InputAdornment } from '@material-ui/core';
import { debounce } from "debounce";

import { isCouponEnabled } from '../../bos_common/src/services/featureFlagUtils';

import { AppContext } from '../../context/AppContext';
import useAxios from 'axios-hooks';
import { Loyalty } from '@material-ui/icons';
import { MerchantCoupon } from '../../services/models';
import { getAPIErrorMessage, getAuthHeaders } from '../../bos_common/src/utils';
import { UserContext } from '../../bos_common/src/context/UserContext';
import { useAppSelector } from '../../redux/hooks';
import { getUserPromotionEntries } from '../../redux/slice/marketPlace/marketPlaceSelector';
import { defaultTo, path } from 'ramda';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiTextField-root': {
        marginTop: theme.spacing(.5),
        marginBottom: theme.spacing(1),
        width: '100%',
        maxWidth: 375,
      },
      '& .MuiInputBase-input': {
        fontSize: 14,
      },
    },
  })
)

interface CouponMessage {
  hasError: boolean;
  message: string;
}


const ShoppingCartCouponInput = () => {
  const classes = useStyles();
  const { coupon, setCoupon, featureFlags, merchantConfig } = useContext(AppContext);
  const { token } = useContext(UserContext);
  const [couponCode, setCouponCode] = useState<string>(coupon?.code || "");
  const [couponMessage, setMessage] = useState<CouponMessage>({ hasError: false, message: "" });

  const merchantId = merchantConfig?.merchant?.id;
  const userPromotionEntries = useAppSelector(getUserPromotionEntries);
  const promotionEntry = userPromotionEntries?.find(i => i.merchant.id === merchantId)

  const [, executeCouponCheck] = useAxios<MerchantCoupon>({
    url: `/merchants/${merchantConfig?.merchant.id}/coupons`,
    method: 'get',
    headers: getAuthHeaders(token)
  },
    { manual: true }
  )

  const checkCouponCode = (code: string, curCoupon: MerchantCoupon | undefined) => {
    if (code.length > 0) {
      setCouponCode(code);
      executeCouponCheck({
        params: {
          code,
        }
      }).then((response) => {
        if (response.status === 200) {
          // applyCoupon
          const couponRst = response.data as MerchantCoupon | undefined;
          setCoupon(couponRst);
          setMessage({ hasError: false, message: "Coupon applied" });
        }
      }).catch((err) => {
        // remove coupon
        if (curCoupon !== undefined) {
          setCoupon(undefined);
        }
        setMessage({ hasError: true, message: getAPIErrorMessage(err) || "Invalid code" });
      })
    } else {
      // remove coupon
      if (curCoupon !== undefined) {
        setCoupon(undefined);
      }
      setMessage({ hasError: false, message: "" });
    }
  }

  const debouncedHandler = useMemo(() => debounce(checkCouponCode, 1000), []);

  useEffect(() => {
    if (couponCode || promotionEntry) {
      const code = defaultTo(couponCode, path(['treatment', 'couponCode'], promotionEntry))
      debouncedHandler(code, coupon)
    }

    return () => {
      debouncedHandler.clear();
    }
  }, []);

  if (!isCouponEnabled(featureFlags) || !merchantConfig?.merchant || merchantConfig.merchant.orderingConfig?.enabledCoupons !== true) {
    return null;
  }

  const handleCodeChange = (event: any) => {
    setCouponCode(event.target.value);

    debouncedHandler(event.target.value, coupon);
    if (event.target.value?.length === 0) {
      debouncedHandler.flush();
    }
  }

  return (
    <div className={classes.root}>
      <TextField
        error={couponMessage.hasError}
        hiddenLabel
        variant="outlined"
        value={couponCode}
        onChange={handleCodeChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Loyalty fontSize="small" />
            </InputAdornment>
          ),
        }}
        size="small"
        margin="none"
        placeholder="Coupon code?"
        helperText={couponMessage.message}
      />
    </div>
  )
}

export default ShoppingCartCouponInput