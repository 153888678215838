import React, { useContext, useMemo } from "react";
import { AppContext } from "../../context/AppContext";
import { getPriceBreakdown, getTotalItems, getTotalPrice } from "../../services/cartUtils";
import { OrderSummaryTemplate } from "../../bos_common/src/components/OrderSummary";
import { isOrderAmountApproximate } from "../MerchantMenu/StoreLocation/utils";

const CartSummary = (): React.ReactElement => {
  const { cart, tip, coupon, merchantConfig } = useContext(AppContext);
  const orderingConfig = merchantConfig?.merchant.orderingConfig;
  const isApproximate = merchantConfig?.merchant && isOrderAmountApproximate(merchantConfig?.merchant);
  const priceBreakdown = useMemo(() =>
    getPriceBreakdown(cart, tip, coupon, orderingConfig),
    [cart, tip, coupon, orderingConfig]
  );


  return (
    <OrderSummaryTemplate
      amount={getTotalPrice({ cart, tip, coupon, orderingConfig })}
      currency={"USD"}
      priceBreakdown={priceBreakdown}
      quantity={getTotalItems(cart)}
      isApproximate={isApproximate}
    />
  )
}

export default CartSummary