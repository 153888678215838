import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

import FavoriteButton from "../common/FavoriteButton";
import { Merchant } from "../../services/models";
import { getMerchantLogoPlaceholder, isEmptyOrNil } from "../../utils";
import ShareButton from "../common/ShareButton";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      position: "relative",
      padding: theme.spacing(2),
      justifyContent: "flex-end",
      alignItems: "flex-start",

      [theme.breakpoints.down('md')]: {
        height: '20vh'
      },

      "& .MuiButtonBase-root": {
        "&:not(:last-child)": {
          marginRight: theme.spacing(1)
        },
      }
    },
  })
);

const MerchantHero = ({ merchant, height = '30vh' }: { merchant: Merchant, height?: string }) => {
  const classes = useStyles();

  const heroPhotoURL = !isEmptyOrNil(merchant.heroPhoto) ? merchant.heroPhoto : getMerchantLogoPlaceholder(merchant)

  return (
    <div className={classes.root} style={{ height: height, background: `url(${heroPhotoURL}) no-repeat center center/cover` }}>
      <FavoriteButton merchant={merchant} />
      <ShareButton merchant={merchant} />
    </div>
  );
};

export default MerchantHero;
