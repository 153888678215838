import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Merchant } from "../../../bos_common/src/types/MerchantType";
import { getMerchantCoupon } from "../../../services/merchantCouponApiService";
import { getMerchant, getMerchantBySearchParams, MerchantQueryParams, MerchantSearchParams } from "../../../services/merchantsApiService";
import { getAuthHeaders } from "../../../utils";
import { RootState } from "../../store";
import { getToken } from "../auth/authSelector";

export interface MerchantCouponFetchParams {
  merchantId: string;
  couponCode: string;
}

export const merchantsFetching = createAction('merchant/setLoadingMerchant');

export const merchantsFetchSucceed = createAction<Merchant[]>('merchant/updateMerchant');

export const merchantsFetchFailed = createAction('merchant/stopLoadingMerchant');

export const fetchMerchants = createAction('MERCHANTS_FETCH_REQUESTED');

export const searchQueryUpdated = createAction<string>('merchant/updateCurrentSearchQuery');

export const setShowRewardsEducationDrawer = createAction<boolean>('merchant/toggleShowEducationDrawer');

export const searchMerchantsData = createAsyncThunk(
  'merchant/searchMerchantsData',
  async (searchParams: MerchantSearchParams, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const token = getToken(state);
    const response = await getMerchantBySearchParams(searchParams, { headers: getAuthHeaders(token) });
    return response.data;
  }
);

export const fetchMerchant = createAsyncThunk(
  'merchant/fetchMerchant',
  async (queryParams: MerchantQueryParams, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const token = getToken(state);
    const response = await getMerchant(queryParams, { headers: getAuthHeaders(token) });
    return [response.data];
  }
);

export const fetchMerchantCoupon = createAsyncThunk(
  'merchant/fetchMerchantCoupon',
  async (params: MerchantCouponFetchParams, thunkAPI) => {
    const { merchantId, couponCode } = params;
    const state: RootState = thunkAPI.getState() as RootState;
    const token = getToken(state);
    const response = await getMerchantCoupon(merchantId, couponCode, { headers: getAuthHeaders(token) });
    return [response.data];
  }
);
