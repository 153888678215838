import React from 'react';
import { Typography } from '@material-ui/core';
import { If } from 'react-if';

import { isEmptyOrNil } from '../../utils';

export type SectionHeaderProps = {
  title: string,
  subtitle?: string,
  CountComponent?: any
}

const SectionHeader = (props: SectionHeaderProps) => {
  const { title, subtitle, CountComponent } = props

  return (
    <div className='section-header'>
      <Typography variant='h5' component='div' className='section-title'>
        {title}&nbsp;{!isEmptyOrNil(CountComponent) && <CountComponent />}
      </Typography>

      <If condition={!isEmptyOrNil(subtitle)}>
        <Typography variant='subtitle2' component="p" color="textSecondary">
          {subtitle}
        </Typography>
      </If>
    </div>
  )
}

export default SectionHeader;