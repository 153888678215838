import React from "react";
import { Alert, Typography } from "@material-ui/core";
import { Merchant, Order } from "../../services/models";
import { DeviceType } from "../../bos_common/src/types/DeviceType";
import { toFixed2 } from "../../utils";
import renderPrice from "../../bos_common/src/components/Price";


export default function OrderLocalImpact({order, merchant}: {
  order?: Order,
  merchant?: Merchant
}): React.ReactElement | null {
  if (!order || !merchant) return null;

  const save = toFixed2(order.amount * 0.06);
  if (save > 0.3 && order.sourceInfo.device != DeviceType.Kiosk) {
    return (
      <Alert severity="success" elevation={3}>
        {merchant?.officialName} received 100% of the revenue on this order. You saved them
        <Typography variant="subtitle1" component="span">
          &nbsp;{renderPrice(save)}&nbsp;
        </Typography>
        in commission that other third-party delivery platforms charge. Thank you!
      </Alert>
    )
  }

  return null
}