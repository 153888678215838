import React, { useState, useContext } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import { useRouteMatch } from 'react-router-dom';
import { pathOr } from 'ramda';

import { Merchandise } from '../../bos_common/src/types/MerchandiseType';
import SimpleLoader from '../../bos_common/src/components/SimpleLoader';
import { UserContext } from '../../bos_common/src/context/UserContext';

import { fetchOrderDetail, updateOrderDetail } from '../../redux/slice/order/orderAction';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchReviewsForOrder } from '../../redux/slice/review/reviewAction';
import { getReviewLoadingStatus, getReviewsByOrder } from '../../redux/slice/review/reviewSelector';
import { getOrder } from '../../redux/slice/order/orderSelector';
import { fetchMerchant } from '../../redux/slice/merchant/action';
import { getMerchant } from '../../redux/slice/merchant/merchantSelector';

import PageHeader from '../common/PageHeader';
import BackButton from '../common/BackButton';
import ReviewFAB from './ReviewFAB';
import { isEmptyOrNil, lineItemWithoutCarryoutBag } from '../../utils';
import { getMerchandisesByMerchantId } from '../../redux/slice/merchandise/merchandiseSelector';
import { fetchMerchandises } from '../../redux/slice/merchandise/merchandiseActions';
import { LineItem } from '../../bos_common/src/types/OrderTypes';

export interface ReviewSelectItemProps {
  currentlyOpen: boolean;
  onNext: (lineItem?: Partial<LineItem>) => void;
  onBack: () => void;
}

export const ReviewSelectItem = (props: Readonly<ReviewSelectItemProps>): JSX.Element => {
  const { onNext, onBack } = props;
  const [value, setValue] = useState<string>('');
  const match = useRouteMatch();
  const orderId = match.params['orderId'] as string;

  const reduxDispatch = useAppDispatch();
  const { user } = useContext(UserContext);
  const order = useAppSelector(getOrder)(orderId);
  const reviews = useAppSelector(getReviewsByOrder)(orderId);
  const loading = useAppSelector(getReviewLoadingStatus);
  const merchant = useAppSelector(getMerchant)(order?.merchantId);
  const merchandises = useAppSelector(getMerchandisesByMerchantId)(order?.merchantId);

  React.useEffect(() => {
    if (order) {
      reduxDispatch(fetchReviewsForOrder(order));
      const lineItems = order.lineItems.filter((item) => lineItemWithoutCarryoutBag(item, merchant));
      if (lineItems.length === 1) {
        const lineItem = lineItems[0]
        setValue(lineItem.id)
      }
    }
  }, [order?.id]);

  React.useEffect(() => {
    if (user && order && !order.userId) {
      // associate this order with user
      reduxDispatch(updateOrderDetail({ id: order.id, userId: user.id }));
    }
  }, [user, order?.id]);

  React.useEffect(() => {
    if (!order) {
      reduxDispatch(fetchOrderDetail(orderId));
    }
  }, [orderId])

  React.useEffect(() => {
    if (order) {
      if (!merchant) {
        reduxDispatch(fetchMerchant({ id: order.merchantId }));
      }

      if (isEmptyOrNil(merchandises)) {
        reduxDispatch(fetchMerchandises({ id: order.merchantId }));
      }
    }
  }, [order?.merchantId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }

  const handleReviewNext = () => {
    onNext({ id: value });
  }

  const reviewedLineItemIds = React.useMemo(() => {
    return reviews
      ? reviews.map((item) => (item.merchandise as Merchandise)?.id)
      : [];
  }, [reviews]);

  const renderLineItemOptions = (): JSX.Element => {
    if (!order || !order.lineItems || !merchant || isEmptyOrNil(merchandises)) {
      return <Typography p="1rem">Loading items...</Typography>
    }

    const lineItems: JSX.Element[] = [];
    const availableMerchandiseIds = merchandises.map((item: Merchandise) => item.id);

    order.lineItems
      .filter((item) => {
        return (
          item.id !== pathOr('', ['orderingConfig', 'carryoutBag', 'id'], merchant)
          && availableMerchandiseIds.includes(item.id)
        )
      })
      .forEach((item) => {
        lineItems.push(
          <FormControlLabel
            key={`opt_${item.id}`}
            value={item.id}
            control={<Radio color='primary' />}
            label={item.name}
            style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)", padding: '1rem', marginLeft: 0, marginRight: 0, }}
            disabled={reviewedLineItemIds.includes(item.id)}
          />
        )
      });

    if (lineItems.length === 0) {
      if (value) {
        // if no item, disable button.
        setValue('');
      }

      return <Typography p="1rem">Items are no longer available.</Typography>
    }

    return (
      <RadioGroup
        aria-label="gender"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {lineItems}
      </RadioGroup>
    );
  }

  // NOTE: Use some inline styles, because makeStyles break the FAB, cause unknown.
  return (
    <Box>
      <SimpleLoader loading={loading} />

      <div>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Review your order from {merchant?.officialName}
          </Typography>

          <Paper elevation={2} sx={{mt: 2, mb: 2}}>
            <FormLabel component="legend" style={{ border: "1px solid rgba(0, 0, 0, 0.1)", padding: '1rem' }}>
              Choose the item you want to review.
            </FormLabel>
            {renderLineItemOptions()}
          </Paper>
          <ReviewFAB onBack={onBack} backText="Done" onNext={handleReviewNext} disableNext={Boolean(!value)} />
        </CardContent>
      </div>
    </Box>
  );
}

export default ReviewSelectItem;
