import React, { SyntheticEvent, useContext, useEffect } from 'react';
import { Card, createStyles, Divider, FormControl, FormControlLabel, FormLabel, makeStyles, Radio, RadioGroup, Theme } from '@material-ui/core';

import { AppContext, } from '../../context/AppContext';
import { OrderPickupType, OrderType } from '../../services/models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }),
);

export const OrderPickupTypeOptions = (): JSX.Element | null => {
  const clasess = useStyles()
  const { orderType, orderPickupType, setOrderPickupType, merchantConfig } = useContext(AppContext)
  if (!merchantConfig?.merchant.orderingConfig?.enabledEatHere || orderType === OrderType.DINEIN) return null

  const handleSwitchToggle = (e: SyntheticEvent) => {
    const orderPickupType: OrderPickupType = (e.target as HTMLInputElement).value as OrderPickupType;
    if (orderPickupType !== null)
      setOrderPickupType(orderPickupType)
  }

  return (
    <Card elevation={3} className={clasess.root}>
      <div style={{ padding: '0px 8px ' }}>
        <FormControl variant="standard" fullWidth>
          <FormLabel color="primary" style={{ padding: '8px 0px' }}>Pickup option?</FormLabel>
          <Divider />
          <RadioGroup
            aria-label="Carry out bags"
            name="radio-buttons-group"
            value={orderPickupType}
            onChange={handleSwitchToggle}
          >
            <FormControlLabel value={OrderPickupType.TO_GO} control={<Radio color="primary" />} label="To go" />
            <Divider />
            <FormControlLabel value={OrderPickupType.EAT_HERE} control={<Radio color="primary" />} label="For here" />
          </RadioGroup>
        </FormControl>
      </div>
    </Card>
  );
}
