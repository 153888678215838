import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { CardContent, Theme, useMediaQuery } from '@material-ui/core'
import { If, Switch, Case, Default } from 'react-if'

// src
import { Merchant, Order } from '../services/models'
import axios from '../bos_common/src/services/backendAxios'
import SimpleLoader from '../bos_common/src/components/SimpleLoader'

import {
  KioskOrderStatusCompactMode,
  KioskOrderStatusTabletMode,
  KioskOrderStatusWideMode,
} from '../components/KioskOrderStatus/KioskOrderStatus'

interface KioskModeStatusContentProps {
  order: Order
  onClose: () => void
  merchant?: Merchant
}
const KioskModeStatusContent = ({
  order,
  onClose,
}: KioskModeStatusContentProps) => {
  const isTabletPortrait = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Switch>
      <Case condition={isMobile}>
        <KioskOrderStatusCompactMode
          order={order}
          onClose={onClose}
        />
      </Case>
      <Case condition={isTabletPortrait}>
        <KioskOrderStatusTabletMode
          order={order}
          onClose={onClose}
        />
      </Case>
      <Default>
        <KioskOrderStatusWideMode
          order={order}
          onClose={onClose}
        />
      </Default>
    </Switch>
  )
}

interface IOrderStatusPageParams {
  orderId: string
}
const KioskOrderStatusPage = (): React.ReactElement => {
  const history = useHistory()
  const { orderId } = useParams<IOrderStatusPageParams>()

  const [merchant, setMerchant] = useState<Merchant>()
  const [loading, setLoading] = useState(true)
  const [order, setOrder] = useState<Order>()
  const [error, setError] = useState<string>()

  const fetch = () => {
    setLoading(true)

    axios
      .get<Order>('/orders', { params: { id: orderId } })
      .then((response) => {
        setOrder(response.data)
        const merchantId = response.data.merchantId
        if (merchant?.id !== merchantId) {
          axios
            .get<Merchant>('/merchants', { params: { id: merchantId } })
            .then((response1) => {
              setLoading(false)
              setMerchant(response1.data)
            })
            .catch((thrown1) => {
              console.log(thrown1.message)
              setError('Cannot load order status')
              setLoading(false)
            })
        } else {
          setLoading(false)
        }
      })
      .catch((thrown) => {
        console.log(thrown.message)
        setError('Cannot load order status')
        setLoading(false)
      })
  }

  // initial data load
  useEffect(() => {
    fetch()
  }, [])

  const onClose = () => {
    exitPage()
  }

  const exitPage = () => {
    history.push(`/${merchant?.username}`)
  }

  return (
    <div className='container'>
      <If condition={loading}>
        <SimpleLoader loading={loading} />
      </If>

      {order && <KioskModeStatusContent order={order} onClose={onClose} />}

      <If condition={error}>
        <CardContent>
          <p>Sorry, we could not find your order.</p>
        </CardContent>
      </If>
    </div>
  )
}

export default KioskOrderStatusPage
