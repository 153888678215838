import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { WritableDraft } from '@reduxjs/toolkit/node_modules/immer/dist/internal';
import { Merchant } from "../../../bos_common/src/types/MerchantType";
import { MerchantCoupon } from '../../../services/models';

import { MerchantReduxSlice, MerchantSliceState } from '../../../types/MerchantSlice';
import { fetchMerchant, fetchMerchantCoupon, searchMerchantsData } from './action';

type MerchantSliceCaseReducer = SliceCaseReducers<MerchantSliceState>;

const commonUpdate = (state: WritableDraft<MerchantReduxSlice>, action: PayloadAction<Merchant[]>): MerchantReduxSlice => {
  let merchant: Merchant | undefined;
  const { payload } = action;
  const byId = { ...state.entities.byId }
  const ids = [...state.entities.ids];
  const byNameSearchParams = { ...state.entities.byNameSearchParams };

  payload.forEach((item) => {
    merchant = payload.find((subItem) => subItem.id === item.id);
    if (merchant) {
      byId[item.id] = merchant;
    }

    if (ids.indexOf(item.id) > -1) {
      // Exit foreach
      return;
    }

    ids.push(item.id);
  });



  if (state.currentSearchQuery) {
    byNameSearchParams[state.currentSearchQuery] = payload.map((item: Merchant) => item.id);
  }

  return {
    ...state,
    loading: false,
    entities: {
      byId,
      ids,
      byNameSearchParams,
    }
  }
}

export const merchantSlice = createSlice<MerchantSliceState, MerchantSliceCaseReducer, string>({
  name: 'merchant',
  initialState: {
    loading: false,
    entities: {
      ids: [],
      byId: {},
      byNameSearchParams: {}
    },
    currentSearchQuery: '',
    currentMerchantId: '',
    showRewardsEducationDrawer: false,
    coupons: {},
    relations: {
      merchantToCoupons: {}
    }
  },
  reducers: {
    setLoadingMerchant: (state) => {
      return {
        ...state,
        loading: true
      }
    },

    stopLoadingMerchant: (state) => {
      return {
        ...state,
        loading: false
      }
    },

    updateMerchant: (state, action: PayloadAction<Merchant[]>) => {
      const updatedState = commonUpdate(state, action);

      return {
        ...updatedState
      }
    },
    updateCurrentSearchQuery: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        currentSearchQuery: action.payload
      }
    },
    toggleShowEducationDrawer: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        showRewardsEducationDrawer: action.payload
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchMerchantsData.pending, (state) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(searchMerchantsData.fulfilled, (state, action) => {
        const updatedState = commonUpdate(state, action as PayloadAction<Merchant[]>);

        return {
          ...updatedState,
          loading: false,
        }
      })
      .addCase(searchMerchantsData.rejected, (state) => {
        return {
          ...state,
          loading: false,
        }
      })
      .addCase(fetchMerchant.pending, (state) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(fetchMerchant.fulfilled, (state, action) => {
        const updatedState = commonUpdate(state, action as PayloadAction<Merchant[]>);

        return {
          ...updatedState,
          currentMerchantId: action.payload[0].id,
          loading: false,
        }
      })
      .addCase(fetchMerchant.rejected, (state) => {
        return {
          ...state,
          loading: false,
        }
      })
      .addCase(fetchMerchantCoupon.pending, (state) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(fetchMerchantCoupon.fulfilled, (state, action) => {
        const { payload } = action as PayloadAction<MerchantCoupon[]>;
        const coupon = payload[0];

        return {
          ...state,
          loading: false,
          entities: {
            ...state.entities,
          },
          coupons: {
            [coupon.id]: coupon,
          },
          relations: {
            merchantToCoupons: {
              [coupon.merchant.id]: [coupon.id],
            },
          }
        }
      })
      .addCase(fetchMerchantCoupon.rejected, (state) => {
        return {
          ...state,
          loading: false,
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const { setLoadingMerchant, stopLoadingMerchant, updateMerchant } = merchantSlice.actions;

export default merchantSlice.reducer;
