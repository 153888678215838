import { ClassifiedKey } from "../bos_common/src/types/ClassifiedType";
import { TotalSavingPercentageType } from "../services/models";

// Interval to Fetch Data for Merchant in miliseconds
export const MERCHANT_DATA_FETCH_INTERVAL = 20000;

// Interval to Delay Class Check in Page Redirect in miliseconds
export const KIOSK_CLASS_CHECKIN_DELAY_INTERVAL = 5 * 60 * 1000; // 5 minutes

// Interval to Fetch Data of Upcoming Classes in miliseconds
export const KIOSK_UPCOMING_CLASSES_FETCH_INTERVAL = 10 * 60 * 1000; // 10 minutes

// Interval to Show Start Over Dialog on Shopping Cart
export const SHOPPING_CART_START_OVER_INTERVAL = 30000;

// Interval to Refresh Wait Time on Order Status Page
export const WAIT_TIME_ON_ORDER_STATUS_INTERVAL = 120000;

export const SAVING_TRACKER_INTERVAL = 120000

// Only countries for phone field
export const PHONE_ONLY_COUNTRIES = ["us", "pk", "cn", "id", "in"];

// SanMateo Area Zipcodes
export const SAN_MATEO_AREA_ZIPCODES = ['94401', '94402', '94403', '94404', '94497']

export const footerData = {
  urlsList: [
    {
      heading: "Company",
      links: [
        //Need to add later
        // {
        //   name: "About",
        //   url: "1m.app/about",
        // },

        {
          name: "Careers",
          url: "https://angel.co/company/one-market-1",
          target: "_blank",
        },

        {
          name: "Contact",
          url: "mailto:support@1m.app",
        },
      ],
    },

    {
      heading: "Resources",
      links: [
        {
          name: "Partnerships",
          url: "http://1m.co/",
          target: "_blank",
        },

        //Page not added yet
        // {
        //   name: "FAQ",
        //   url: "https://www.1m.app/help/",
        //   target: "_blank"
        // },
      ],
    },

    {
      heading: "Join the Community",
      links: [
        {
          name: "Facebook",
          url: "https://www.facebook.com/1mapp",
          target: "_blank",
        },

        {
          name: "Instagram",
          url: "https://www.instagram.com/1m.app/",
          target: "_blank",
        },

        {
          name: "Twitter",
          url: "https://twitter.com/1m_app_",
          target: "_blank",
        },
      ],
    },
  ],

  bottomLinks: [
    {
      url: "http://www.1m.app/help/terms-of-use",
      name: "TERMS OF SERVICE",
      target: "_blank",
    },
    {
      url: "http://www.1m.app/help/privacy-policy",
      name: "PRIVACY POLICY",
      target: "_blank",
    },
    {
      url: "/",
      name: `© ${new Date().getFullYear()} One Market inc.`,
    },
  ],
};

export const SAN_MATEO_MARKETING_EXPERIMENT_A = {
  localdeals: {
    title: "Local Takeout with Unbeatable Prices",
    subtitles: ["Exclusive deals and rewards for your local favorites"],
    sectionOrder: [
      ClassifiedKey.hotDeal, //deal for you
      ClassifiedKey.bigSavings, // local savings
      ClassifiedKey.hotItem, //local favorites
      ClassifiedKey.exclusive, // only on nemarket
      ClassifiedKey.localGem, //hidden gems
    ],
    savingsTracker: {
      type: TotalSavingPercentageType.SAVING_MERCHANTS,
      title: "We’ve saved local merchants",
      subtitle: "from commission fees"
    }
  },
  buylocalgetrewards: {
    title: "Local Takeout with Unbeatable Prices",
    subtitles: ["Exclusive deals and rewards for your local favorites"],
    sectionOrder: [
      ClassifiedKey.hotDeal, //deal for you
      ClassifiedKey.bigSavings, // local savings
      ClassifiedKey.hotItem, //local favorites
      ClassifiedKey.exclusive, // only on nemarket
      ClassifiedKey.localGem, //hidden gems
    ],
    savingsTracker: {
      type: TotalSavingPercentageType.SAVING_MERCHANTS,
      title: "We’ve saved local merchants",
      subtitle: "from commission fees"
    }
  },
  orderonlinenofees: {
    title: "Local Takeout with Unbeatable Prices",
    subtitles: ["Exclusive deals and rewards for your local favorites"],
    sectionOrder: [
      ClassifiedKey.hotDeal, //deal for you
      ClassifiedKey.bigSavings, // local savings
      ClassifiedKey.hotItem, //local favorites
      ClassifiedKey.exclusive, // only on nemarket
      ClassifiedKey.localGem, //hidden gems
    ],
    savingsTracker: {
      type: TotalSavingPercentageType.SAVING_MERCHANTS,
      title: "We’ve saved local merchants",
      subtitle: "from commission fees"
    }
  },

  supportlocal: {
    title: "Supporting local just got online easier",
    subtitles: [
      "No fees and markups for you",
      "No fees and commissions for the store",
    ],
    sectionOrder: [
      ClassifiedKey.hotItem, //local favorites
      ClassifiedKey.localGem, //hidden gems
      ClassifiedKey.hotDeal, //deal for you
      ClassifiedKey.exclusive, // only on nemarket
      ClassifiedKey.bigSavings, // local savings
    ],
    savingsTracker: {
      type: TotalSavingPercentageType.SAVING_CONSUMERS,
      title: "We’ve saved the community",
      subtitle: "from fees and markups"
    }
  },

  localonline: {
    title: "Direct ordering just got online easier",
    subtitles: [
      "Order ahead at all your local favorites with the same in-store prices.",
    ],
    sectionOrder: [
      ClassifiedKey.exclusive, // only on nemarket
      ClassifiedKey.hotItem, //local favorites
      ClassifiedKey.localGem, //hidden gems
      ClassifiedKey.hotDeal, //deal for you
      ClassifiedKey.bigSavings, // local savings
    ],
    savingsTracker: {
      type: TotalSavingPercentageType.SAVING_MERCHANTS,
      title: "We’ve saved local merchants",
      subtitle: "from commission fees"
    }
  },
  directorderonline: {
    title: "Direct ordering just got online easier",
    subtitles: [
      "Order ahead at all your local favorites with the same in-store prices.",
    ],
    sectionOrder: [
      ClassifiedKey.exclusive, // only on nemarket
      ClassifiedKey.hotItem, //local favorites
      ClassifiedKey.localGem, //hidden gems
      ClassifiedKey.hotDeal, //deal for you
      ClassifiedKey.bigSavings, // local savings
    ],
    savingsTracker: {
      type: TotalSavingPercentageType.SAVING_MERCHANTS,
      title: "We’ve saved local merchants",
      subtitle: "from commission fees"
    }
  },

  default: {
    title: "Order takeout with in-store prices",
    subtitles: ["Discover local favorites, completely fee-free"],
    sectionOrder: [
      ClassifiedKey.hotDeal, //deal for you
      ClassifiedKey.hotItem, //local favorites
      ClassifiedKey.exclusive, // only on nemarket
      ClassifiedKey.bigSavings, // local savings
      ClassifiedKey.localGem, //hidden gems
    ],
    savingsTracker: {
      type: TotalSavingPercentageType.SAVING_MERCHANTS,
      title: "We’ve saved local merchants",
      subtitle: "from commission fees"
    }
  },
};

export const SAN_MATEO_MARKETING_EXPERIMENT_B = {
  localdeals: {
    title: "Local Takeout with In-Store Prices",
    subtitles: [
      "No fees and markups for you",
      "No fees and commissions for the store",
    ],
    sectionOrder: [
      ClassifiedKey.hotDeal, //deal for you
      ClassifiedKey.bigSavings, // local savings
      ClassifiedKey.hotItem, //local favorites
      ClassifiedKey.exclusive, // only on nemarket
      ClassifiedKey.localGem, //hidden gems
    ],
    savingsTracker: {
      type: TotalSavingPercentageType.SAVING_MERCHANTS,
      title: "We’ve saved local merchants",
      subtitle: "from commission fees"
    }
  },
  buylocalgetrewards: {
    title: "Local Takeout with In-Store Prices",
    subtitles: [
      "No fees and markups for you",
      "No fees and commissions for the store",
    ],
    sectionOrder: [
      ClassifiedKey.hotDeal, //deal for you
      ClassifiedKey.bigSavings, // local savings
      ClassifiedKey.hotItem, //local favorites
      ClassifiedKey.exclusive, // only on nemarket
      ClassifiedKey.localGem, //hidden gems
    ],
    savingsTracker: {
      type: TotalSavingPercentageType.SAVING_MERCHANTS,
      title: "We’ve saved local merchants",
      subtitle: "from commission fees"
    }
  },
  orderonlinenofees: {
    title: "Local Takeout with In-Store Prices",
    subtitles: [
      "No fees and markups for you",
      "No fees and commissions for the store",
    ],
    sectionOrder: [
      ClassifiedKey.hotDeal, //deal for you
      ClassifiedKey.bigSavings, // local savings
      ClassifiedKey.hotItem, //local favorites
      ClassifiedKey.exclusive, // only on nemarket
      ClassifiedKey.localGem, //hidden gems
    ],
    savingsTracker: {
      type: TotalSavingPercentageType.SAVING_MERCHANTS,
      title: "We’ve saved local merchants",
      subtitle: "from commission fees"
    }
  },

  supportlocal: {
    title: "Fee-free for our community",
    subtitles: [],
    sectionOrder: [
      ClassifiedKey.hotItem, //local favorites
      ClassifiedKey.localGem, //hidden gems
      ClassifiedKey.hotDeal, //deal for you
      ClassifiedKey.exclusive, // only on nemarket
      ClassifiedKey.bigSavings, // local savings
    ],
    savingsTracker: {
      type: TotalSavingPercentageType.SAVING_CONSUMERS,
      title: "We’ve saved the community",
      subtitle: "from fees and markups"
    }
  },

  localonline: {
    title: "Direct order ahead at all of your local favorites",
    subtitles: ["Over-the-phone prices. All of the online ease."],
    sectionOrder: [
      ClassifiedKey.exclusive, // only on nemarket
      ClassifiedKey.hotItem, //local favorites
      ClassifiedKey.localGem, //hidden gems
      ClassifiedKey.hotDeal, //deal for you
      ClassifiedKey.bigSavings, // local savings
    ],
    savingsTracker: {
      type: TotalSavingPercentageType.SAVING_MERCHANTS,
      title: "We’ve saved local merchants",
      subtitle: "from commission fees"
    }
  },
  directorderonline: {
    title: "Direct order ahead at all of your local favorites",
    subtitles: ["Over-the-phone prices. All of the online ease."],
    sectionOrder: [
      ClassifiedKey.exclusive, // only on nemarket
      ClassifiedKey.hotItem, //local favorites
      ClassifiedKey.localGem, //hidden gems
      ClassifiedKey.hotDeal, //deal for you
      ClassifiedKey.bigSavings, // local savings
    ],
    savingsTracker: {
      type: TotalSavingPercentageType.SAVING_MERCHANTS,
      title: "We’ve saved local merchants",
      subtitle: "from commission fees"
    }
  },

  default: {
    title: "Order takeout with in-store prices",
    subtitles: ["Discover local favorites, completely fee-free"],
    sectionOrder: [
      ClassifiedKey.hotDeal, //deal for you
      ClassifiedKey.hotItem, //local favorites
      ClassifiedKey.exclusive, // only on nemarket
      ClassifiedKey.bigSavings, // local savings
      ClassifiedKey.localGem, //hidden gems
    ],
    savingsTracker: {
      type: TotalSavingPercentageType.SAVING_MERCHANTS,
      title: "We’ve saved local merchants",
      subtitle: "from commission fees"
    }
  },
};

export const MERCHANT_LOGO_PLACEHOLDER_DATA = {
  american: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_scwu4a24i-1648466906129?type=image&exp=1648467806129&sig=eae72255d08a5e8f3a76fb46e6554f4446ec9fb8c728dd326cd169023ca8ab85",
  asian: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_1vo895zmn-1648466970124?type=image&exp=1648467870124&sig=70ec792d57d53be3988b10728c7d1b00a4fda71d3fbb9efea60cfd7b39ec3a9b",
  asian_fusion: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_7pbpd2qhy-1648467006440?type=image&exp=1648467906440&sig=20a70c3163d62df5c49dd17a4bffa863afa8074a266539cb3109607d6019bde2",
  bakery: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_2zqd4175t-1648467081767?type=image&exp=1648467981767&sig=bb0c203ebd601631ae16d1d90e251ec801ca01071fd32206960f7bd22166eba7",
  bar: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_motho4csj-1648467115190?type=image&exp=1648468015190&sig=7306f52b5efc142d4027c16a35b371d45f82c84887d83ad6f3f6d40c47d136f8",
  breakfast: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_j9ao23vo5-1648467147005?type=image&exp=1648468047005&sig=c98cc2acacec55369279a8a18c52506749762d6240109cbd46c7efbb039b05e6",
  burger: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_3pr1qy5rb-1648467180762?type=image&exp=1648468080762&sig=1488235d62491f16ab641f6cd084d2594cc203acb553f8721e4b3615d4e9ef9c",
  cafe: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_nkye6m40i-1648467210765?type=image&exp=1648468110765&sig=7bee19e4c27fc0137989b724d3b0c537a39f6eced5b752c15467acccb39fa618",
  cajun: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_tm49gnewm-1648467243625?type=image&exp=1648468143625&sig=3bb8493f19c9487ceccf32a50e41d61f18d395e480bf8264256d74f7015b2d43",
  chinese: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_djppblh4l-1648467279502?type=image&exp=1648468179502&sig=22b2d5cde4fec887b7f746336414c461f69da1cf52d221c0223b9f89535c2bba",
  coffee: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_vqcnju8i0-1648467372533?type=image&exp=1648468272533&sig=30e1e1668cb8a293d169fa976a9ff0b422d1fdc733fbe18d5901cbe6902ece83",
  desserts: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_7n3b7oi8k-1648467309402?type=image&exp=1648468209402&sig=276231fdf92a3d4ac7cff79f0213fb0631a47c5c9ccf860d708dd8d8fd271eef",
  filipino: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_8xyneiocw-1648467402901?type=image&exp=1648468302901&sig=eb07ef07f419104454b95e157a7bcaf8fda1ac50c505c0dd311ff7c94a32864b",
  german: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_1s75t0fth-1648467431201?type=image&exp=1648468331201&sig=111ddd0ee4698d3edd2c6695a34a5149a52e05004fde7f56d1729d64ac987ad9",
  greek: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_71sx6xfo4-1648467473124?type=image&exp=1648468373124&sig=055b81d49eea382b03f4c661c23954aac2f4a7fd982890a29e28f7c3fedf1c06",
  hot_pot: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_wveb69fu0-1648467501192?type=image&exp=1648468401192&sig=c9647824bb260c773576ad359cbe2a8b0758eef0453563ee9cb99c6554ed79cf",
  indian: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_i25xfooqd-1648467528137?type=image&exp=1648468428137&sig=4f63a8fc13af3396838af965d9bea51cf9932364fa07a9da4dbb7c27963901ef",
  italian: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_rtxjvxmk0-1648467556000?type=image&exp=1648468456000&sig=4c2df49ee54165c4a8e7d45db573ef6eef21be64f6c7bd2c991337804786633f",
  japanese: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_zii3yvkrt-1648467586299?type=image&exp=1648468486299&sig=82cc9e9efc219e98e692b115df7e83749a2848ebba8a17b4b3e1f91fc5c4b446",
  juice: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_xcf3nim3k-1648467612161?type=image&exp=1648468512161&sig=1b933e28231c484efe666621218d03946321cf7ab25d12d547b2197c6947d915",
  mediterranean: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_rf847x5p2-1648467638486?type=image&exp=1648468538486&sig=6054d77eba0bd23b508c51ad656a30d6090675ff9c766db1f03ec159dcd6ce3e",
  mexican: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_xialybvpc-1648467661954?type=image&exp=1648468561954&sig=b279ceb51ac2aa055f9f10ab6fe9670fcec491d33f83639d791ce79ed8cbd5fd",
  middle_eastern: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_xfer8b16b-1648467694268?type=image&exp=1648468594268&sig=30240da3b112e4441fdba5ef47239a12e87cf9eafef004fe9bfc03bed7bbe767",
  noodles: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_dr2fj471h-1648467722154?type=image&exp=1648468622154&sig=c01f48608b9e376365582266d9bad49433765319f9ffa79e7a88de5756b9dfe6",
  pasta: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_vde5bwyaz-1648467749179?type=image&exp=1648468649179&sig=d411139096c385bfbb31b4d2a5c8e706f7dd66c3635881ea9ab1cc23e54b6c25",
  pizza: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_42ztlex0f-1648467781411?type=image&exp=1648468681411&sig=ee140e82259cbd70a3ebf1e6db8ecfd045d18ec0628e13515549182fdefb89ba",
  poke: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_vknxx7q3y-1648467806116?type=image&exp=1648468706116&sig=8290c3e1f5deba38b374a2a01ae2df76bb7ec86e1782fcb35103cb270f982c25",
  ramen: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_i1oxz0tfj-1648467830218?type=image&exp=1648468730218&sig=6f231ffa6e26a3543cf41e44488b4a7db783958b88bd322da3a216909bb5b931",
  salad: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_irgfww8gp-1648467856897?type=image&exp=1648468756897&sig=944d6e30234e526e5b17f94b86c4e7764acfa21016eb543f4ac19d5802b9cbd4",
  sandwich: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_om6avorxb-1648467878988?type=image&exp=1648468778988&sig=565091e9536e162c008fc4101a8b414b77b166b733b65da129f4ffd7d8f4bbcb",
  seafood: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_p46ssqbhx-1648467904141?type=image&exp=1648468804141&sig=b9cfa835a24fa62d61bef2d48d76553c2c8c8455ec28587ebd088c6dcdf1c686",
  sushi: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_tbne179fu-1648467932834?type=image&exp=1648468832834&sig=755b42ebdf87e642ab7710305a23ab87a1e2e4c3ed374dd16a61dad45c3b9441",
  tacos: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_nstyexgnw-1648467956617?type=image&exp=1648468856617&sig=2f244b57010b79811f48d9f970413f91d1524bc7458bc0e426ecb281ebbb90fb",
  thai: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_65ipnyyhu-1648467980457?type=image&exp=1648468880457&sig=c9125c441d563a8511d89e635966ff73f291fe53ff9ee120a7581090690a952b",
  vietnamese: "https://bos-merchant-images.s3-us-west-1.amazonaws.com/image-p_7f0285d7g-1648468007571?type=image&exp=1648468907571&sig=291095e4c280da3ca8e423e256737712f134a53302bbd99bb918dc07f9442d33",
};