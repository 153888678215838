
import React from 'react'
import { SvgIcon } from "@material-ui/core";

function BeverageIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M11.752 6.391c-.087-.923-.465-1.752-1.088-2.367a3.621 3.621 0 00-1.112-.75l.14-1.34.877.241a.41.41 0 00.218-.791l-1.345-.37a.41.41 0 00-.517.354L8.753 3.03A4.025 4.025 0 008 2.96c-1.024 0-1.97.378-2.664 1.064-.623.615-1.001 1.444-1.087 2.367a.627.627 0 00-.622.627v.848c0 .346.281.627.627.627h.011l.724 6.058a.51.51 0 00.506.449h5.01a.51.51 0 00.505-.449l.725-6.058h.011a.627.627 0 00.627-.627v-.848a.627.627 0 00-.621-.627zM4.447 7.672v-.46H11.552v.46H4.448zm6.479-1.28H9.229l.233-2.252c.804.438 1.348 1.246 1.465 2.251zM8 3.78c.232 0 .455.024.668.069L8.404 6.39h-3.33C5.25 4.856 6.426 3.781 8 3.781zm2.229 10.4H5.77l-.68-5.687h5.818l-.68 5.687z" />

    </SvgIcon>
  );
}

export default BeverageIcon