import { BaseIncrement } from "./BaseIncrement";
import { Merchandise } from "./MerchandiseType";
import { Merchant } from "./MerchantType";
import { Order } from "./OrderTypes";
import { User } from "./UserTypes";

export enum ReviewType {
  MERCHANDISE = 'merchandise',
  MERCHANT = 'merchant',
}

export interface Rating {
  taste: number;
  portion: number;
  value: number;
  overall: number;
}

export interface Review extends BaseIncrement {
  order: Order;
  orderId: string;
  email: string;
  merchant: Merchant;
  merchandise: Merchandise;
  user: User;
  reviewType: ReviewType;
  rating: Rating;
  comment: string;
}