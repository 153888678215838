import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { MerchantPromotion } from "../../../bos_common/src/types/MerchantPromotionType";
import { getMerchantPromotions } from "../../../services/merchantPromotionsApiService";
import { getAuthHeaders } from "../../../utils";
import { RootState } from "../../store";
import { getToken } from "../auth/authSelector";

export const merchantPromotionFetching = createAction('merchantPromotion/setLoading');

export const merchantPromotionFetchSucceed = createAction<MerchantPromotion[]>('merchantPromotion/updateMerchantPromotion');

export const merchantPromotionFetchFailed = createAction('merchantPromotion/stopLoading');

export const fetchMerchantPromotion = createAsyncThunk(
  'merchantPromotion/fetchMerchantPromotion',
  async (merchantId: string, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const token = getToken(state);
    const response = await getMerchantPromotions(merchantId, { headers: getAuthHeaders(token) });
    return response.data;
  }
);
