import React from "react"
import { FlashOn, ErrorOutline } from "@material-ui/icons"
import CustomizedChip from "./CustomizedChip"

export const RenderLowStockWarning = () =>
  <CustomizedChip
    icon={<FlashOn fontSize="small"/>}
    color="secondary"
    label="LOW STOCK"
  />

export const RenderSoldOut = () =>
  <CustomizedChip
    variant="filled"
    color="primary"
    disabled={true}
    icon={<ErrorOutline fontSize="small"/>}
    label="SOLD OUT"
  />
