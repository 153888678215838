import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { StoryComment } from "bos_common/src/types/MerchantStoryType";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { displayDate } from "bos_common/src/utils";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    lineHeight: 1.2,

    "& .MuiAvatar-root": {
      width: '48px',
      height: '48px'
    },
    "& .display-name": {
      fontWeight: 600,
    }
  }
}));

export interface CommentInfoProps {
  comment: StoryComment;
}

export const CommentInfo = (props: Readonly<CommentInfoProps>): JSX.Element => {
  const { comment } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Avatar sx={{mr: 1}} alt="image" src={`${comment.user?.picture ? comment.user?.picture : '/user-avatar.png'}`} />
      <div>
        <Typography variant="subtitle1" className="display-name" gutterBottom={false}>
          {comment.user.displayName}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {displayDate(comment.createdAt)}
        </Typography>
      </div>
    </div>
  );
}

export default CommentInfo;
