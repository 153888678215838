import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { StoryComment } from "bos_common/src/types/MerchantStoryType";

export interface CommentMenuProps {
  comment: StoryComment;
  menuOpened: boolean;
  anchorEl?: Element;
  onDelete?: (comment: StoryComment) => void;
  onClose?: () => void;
}

export const CommentMenu = (props: Readonly<CommentMenuProps>): JSX.Element => {
  const { comment, menuOpened, anchorEl, onDelete, onClose } = props;

  const handleClose = () => {
    if (!onClose) {
      return;
    }

    onClose();
  }

  const handleClickDelete = (comment: StoryComment) => {
    if (!onDelete) {
      return;
    }

    onDelete(comment);
  }

  return (
    <Menu
      id="fade-menu"
      MenuListProps={{
        'aria-labelledby': 'fade-button',
      }}
      anchorEl={anchorEl}
      open={menuOpened}
      onClose={handleClose}>
      <MenuItem onClick={() => handleClickDelete(comment)}>Delete</MenuItem>
    </Menu>
  );
}

export default CommentMenu;
