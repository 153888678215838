import React, { useContext } from "react";

import { Merchant } from "../services/models";
import { enableRatings, isEmptyOrNil, isKioskDevice } from "../utils";

export function useEnableRatings(merchant?: Merchant) {

    if(!enableRatings(merchant) || isKioskDevice() || isEmptyOrNil(merchant?.rating)) return false;

    // to show/hide merchant rating
    return merchant?.rating && merchant?.rating.overallRating > 0 && merchant?.rating?.reviewCount >= 10
}