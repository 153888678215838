import { MerchantStory, StoryComment } from "bos_common/src/types/MerchantStoryType";
import { Merchant } from "bos_common/src/types/MerchantType";
import { RootState } from "../../store";

const getFirstStory = (state: RootState, merchantUsername: string): MerchantStory | undefined => {
  const { merchant, merchantStory } = state;
  const { entities } = merchantStory;

  const currentMerchant = Object.values(merchant.entities.byId).find((item) => item.username === merchantUsername);

  return Object.values(entities.byId).find((item) => item.merchant.id === currentMerchant?.id);
}

export const getMerchantStoryLoadingStatus = (state: RootState): boolean => state.merchantStory.loading;

export const getMerchantStoryUpdatingStatus = (state: RootState): boolean => Boolean(state.merchantStory.updating);

export const getMerchantStoryLikingStatus = (state: RootState): boolean => Boolean(state.merchantStory.liking);

export const getMerchantStories = (state: RootState) => (merchantUsername: string): MerchantStory[] => {
  const { entities } = state.merchantStory;
  // TODO: add byMerchant
  return Object.values(entities.byId).filter((item) => item.merchant.username === merchantUsername);
}

export const getMerchantStoriesByMerchantUsername = (state: RootState) => (merchantUsername: string): MerchantStory[] => {
  const { merchant, merchantStory } = state;
  const { entities } = merchantStory;

  const currentMerchant = Object.values(merchant.entities.byId).find((item) => item.username === merchantUsername);
  return Object.values(entities.byId).filter((item) => item.merchant.id === currentMerchant?.id);
}

export const getMerchantStory = (state: RootState) => (merchantUsername: string, id?: number): MerchantStory | undefined => {
  const { merchant, merchantStory } = state;
  const { entities } = merchantStory;

  if (!id) {
    return getFirstStory(state, merchantUsername);
  }

  return entities.byId[id];
}

export const getMerchantStoryComments = (state: RootState) => (merchantUsername: string, merchantStoryId?: number): StoryComment[] | undefined => {
  const { merchantStory } = state;
  const { entities } = merchantStory;

  return merchantStoryId
    ? entities.byId[merchantStoryId]?.comments?.filter((item) => !item.replyToCommentId)
    : getFirstStory(state, merchantUsername)?.comments?.filter((item) => !item.replyToCommentId); // Only get post comment, not the reply
}

export const getCommentsReply = (state: RootState) => (baseComment: StoryComment): StoryComment[] => {
  const { merchantStory } = state;
  const { entities } = merchantStory;

  return entities
    .byId[baseComment.merchantStoryId]?.comments?.filter((item) => item.replyToCommentId === baseComment.id) ?? [];
}

export const getLastFetchedComment = (state: RootState) => (merchantStoryId?: number): StoryComment | undefined => {
  if (!merchantStoryId) {
    return undefined;
  }

  const { entities } = state.merchantStory;
  const merchantStory = entities.byId[merchantStoryId];

  if ((!merchantStory.comments || merchantStory.comments.length === 0)) {
    return undefined;
  }
  
  return merchantStory.comments[merchantStory.comments.length - 1];
}
