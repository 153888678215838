import React, { useContext, useMemo } from 'react';
import { Theme, createStyles, makeStyles, } from '@material-ui/core/styles';
import { Card, CardMedia, Typography, Button, Box } from '@material-ui/core';
import { pluck, includes } from "ramda";
import { Else, If, Then } from 'react-if';

// src
import MerchandiseModifiers from '../../bos_common/src/components/Merchandise/MerchandiseModifiers';
import MerchandiseNote from '../../bos_common/src/components/Merchandise/MerchandiseNote';
import { AppContext, CartItemType } from '../../context/AppContext';
import QuantityInput from '../MerchantMenu/QuantityInput';
import { withFallbackPath } from '../../utils';
import { ShoppingCartContext } from '../../context/ShoppingCart/ShoppingCartContext';
import { Merchant, MerchantCoupon } from '../../services/models';
import MerchandisePrice from '../MerchantMenu/MerchandisePrice';
import eventTrackingService from '../../services/Tracking';
import { EVENT_ACTIONS, EVENT_CATEGORIES } from '../../services/Tracking/events';
import { getEventLabel } from '../../services/Tracking/EventsTracking';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1),
      '& .OutOfOrderInfo': {
        position: 'absolute',
        left: 0,
        top: '50%',
        marginTop: `-${theme.spacing(2)}`,
        width: '100%',
        textAlign: "center",
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[500],
        zIndex: 10,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      }
    },
    row1: {
      display: 'flex',
      '& .details': {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flex: "1 1 auto",
        justifyContent: "space-between",
      },
      '& .MuiButtonBase-root': {
        display: 'flex',
        justifyContent: 'center',
      },

      '& .cover': {
        width: 100,
        height: 100,
        marginRight: theme.spacing(1),
        borderRadius: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
          width: 150,
          height: 150,
        },
      }
    },
    row2: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      marginTop: theme.spacing(1),
      '& .quantityDisabled': {
        padding: theme.spacing(.5),
      },
    },

  }),
);

interface CartItemCardProps {
  item: CartItemType,
  coupon?: MerchantCoupon,
  readOnly?: boolean
  onChangeQuantity?: (quantity: number, change: number) => void,
}

export default function CartItemCard(props: CartItemCardProps): React.ReactElement {
  const classes = useStyles();
  const { item, coupon, readOnly = false, onChangeQuantity } = props;

  const currentInventory = item?.inventory?.dailyCurrentStock
  const { merchantConfig, addItemToCart, removeItemFromCart } = useContext(AppContext)
  const { soldoutItems } = useContext(ShoppingCartContext)

  const photo = item.photos && item.photos.length > 0 && item.photos[0] != null
    ? item.photos[0]
    : null;

  const CardMediaEnhanced: any = useMemo(() => withFallbackPath(CardMedia, photo), [photo]);

  const isItemOutOfStock = useMemo(() => {
    return includes(item.id, pluck('id', soldoutItems))
  }, [soldoutItems, item])

  const _onChangeQuantity = (newQuantity: number, change: number) => {
    if (change > 0) {
      const newItem = { ...item };
      newItem.quantity = change;
      addItemToCart(newItem, merchantConfig?.merchant);
    } else if (change < 0) {
      for (let i = change; i < 0; i++) {
        removeItemFromCart({ merchandiseId: item.id, modifiersSignature: item.modifiersSignature });
      }
    }

    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.SHOPPING_CART,
      action: change > 0 ? EVENT_ACTIONS.CLICK_ITEM_ADDED : EVENT_ACTIONS.CLICK_ITEM_REMOVED,
      data: {
        merchant: merchantConfig?.merchant,
      },
      label: item.name,
    });
  }

  return (
    <Card className={classes.root} elevation={3}>
      <If condition={!readOnly && isItemOutOfStock}>
        <Typography component="div" variant="subtitle1" className="OutOfOrderInfo">
          This item is currently out of stock
        </Typography>
      </If>
      <div className={classes.row1}>
        {photo && (
          <CardMediaEnhanced
            component="img"
            className="cover"
            src={`${photo}_small`}
            title={item.name}
            alt='cart-item-img'
          />
        )}
        <div className="details">
          <div>
            <Typography component="div" variant="subtitle1" style={{ lineHeight: 1.2, marginBottom: '4px' }}>
              {item.name}
            </Typography>
            <MerchandiseModifiers modifiers={item.modifiers} modifiersDisplayOrder={item.modifiersDisplayOrder} />
            {/* when there is a note, we will show it */}
            {item.note && <MerchandiseNote note={item.note} />}
          </div>
          <div className={classes.row2}>
            <If condition={readOnly}>
              <Then>
                <Button variant="outlined" disabled className="quantityDisabled">
                  {item.quantity}
                </Button>
              </Then>
              <Else>
                <QuantityInput
                  quantity={item.quantity}
                  minQuantity={0}
                  maxQuantity={currentInventory}
                  onQuantityChange={onChangeQuantity || _onChangeQuantity} />
              </Else>
            </If>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <MerchandisePrice merchandise={item} coupon={coupon} isCart />
            </Box>
          </div>
        </div>
      </div>
    </Card>
  );
}