import React from 'react'
import { Box, Chip, IconButton, Paper, styled } from "@material-ui/core"
import { rgba } from "polished"

export const CardHighlightChip = styled(Chip)(({ theme }) => ({
  border: 0,

  '&.MuiChip-filled': {
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.07)',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 12,
    height: 'auto',
    padding: theme.spacing(.5),

    '& .MuiChip-label': {
      whiteSpace: 'break-spaces',
      textTransform: 'none',

      '& .ellipsized-label': {
        display: 'grid',
        gridTemplateColumns: '15px 1fr',
        gridColumnGap: '5px',

        alignItems: 'center',
        justifyContent: 'center'
      },
    }
  }
}))

export const StyledGreenChip = styled(CardHighlightChip)(({ theme }) => ({
  '&.MuiChip-filled': {
    backgroundColor: '#22AC38',
  }
}))

export const StyledGreyChip = styled(CardHighlightChip)(({ theme }) => ({
  '&.MuiChip-filled': {
    backgroundColor: rgba(0, 0, 0, 0.07),
    borderRadius: theme.spacing(0.5),
    boxShadow: 'none',
    '& .MuiChip-label': {
      color: theme.palette.secondary.light,
      padding: '2px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '70px',
    }
  }
}))

export const StyledExpenseChip = styled(StyledGreyChip)(({ theme }) => ({
  '&.MuiChip-filled': {
    backgroundColor: '#F8B62D',

    '& .MuiChip-label': {
      whiteSpace: "nowrap",
      color: 'white',
    }
  },

}))

export const CarouselNavigationButton = styled(IconButton)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    border: '1px solid'
  }
}))

export const CarouselWrapper = styled(Box)(({ theme }) => ({
  '& .alice-carousel__prev-btn, & .alice-carousel__next-btn': {
    position: 'absolute',
    top: -60,
    width: 50,
    zIndex: 10,
  },
  '& .alice-carousel__prev-btn': {
    right: 55,
  },
  '& .alice-carousel__next-btn': {
    right: 0,
  },
}))

export const CarouselItemWrapper = styled(Box)(({ theme }) => ({
  '& .MuiPaper-root': { m: 0 },
  width: 230,
  height: 230,
}))

export const EllipsizedText = styled('div')(({ theme }) => ({
  textOverflow: 'ellipsis',
  width: 'auto',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}))

export const TimerSelectChip = styled(({ selected, label, ...other }) => (
  <div {...other} className={`${other.className} ${selected ? 'selected' : ''}`} >{label}</div>
))(({ theme }) => ({
  width: 78,
  height: 35,
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.secondary.light}`,
  fontSize: 14,
  lineHeight: '35px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  backgroundColor: 'rgba(156, 165, 168, 0.1)',
  color: theme.palette.secondary.light,
  cursor: "pointer",
  '&.selected': {
    background: rgba(227, 77, 40, 0.15),
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  }
}))
