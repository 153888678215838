import { createAction } from "@reduxjs/toolkit";
import { VoteRequestBody } from "../../../services/votesApiService";
import UserMerchantVote from "../../../bos_common/src/types/UserMerchantVote";

export const storesToVoteFetching = createAction('vote/setLoadingVote');

export const storesToVoteFetchSucceed = createAction<string[]>('vote/updateStoreToVoteIds');

export const votesFetchSucceed = createAction<UserMerchantVote[]>('vote/updateVote');

export const storesToVoteFetchFailed = createAction('vote/stopLoadingVote');

export const fetchStoresToVote = createAction('VOTE_FETCH_REQUESTED');

export const votedStoresFetching = createAction('vote/setLoadingVote');

export const votedStoresFetchSucceed = createAction<string[]>('vote/updateVotedStoreIds');

export const votedStoresFetchFailed = createAction('vote/stopLoadingVote');

export const fetchVotedStores = createAction('VOTED_STORES_FETCH_REQUESTED');

export const voteCountFetching = createAction('vote/setLoadingVote');

export const voteCountFetchSucceed = createAction<{[key: string]: number}>('vote/updateVoteCount');

export const voteCountFetchFailed = createAction('vote/stopLoadingVote');

export const fetchVoteCount = createAction<string[]>('VOTE_COUNT_FETCH_REQUESTED');

export const voteCreating = createAction('vote/setUpdatingVote');

export const voteCreateSucceed = createAction<UserMerchantVote>('vote/voteCreated');

export const voteCreateFailed = createAction('vote/stopUpdatingVote');

export const createVote = createAction<VoteRequestBody>('VOTE_CREATE_REQUESTED');

export const voteRemainingFetching = createAction('vote/setLoadingVote');

export const voteRemainingFetchSucceed = createAction<number>('vote/updateVoteRemaining');

export const voteRemainingFetchFailed = createAction('vote/stopLoadingVote');

export const fetchVoteRemaining = createAction('VOTE_REMAINING_FETCH_REQUESTED');
