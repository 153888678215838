import React from 'react'
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core"
import classNames from "classnames"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      borderRadius: theme.spacing(0.5),

      '&.isSelected': {
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.07)'
      }
    }
  })
)

interface BoxWithClickProps {
  isSelected?: boolean,
  handleClick: () => void,
  children: React.ReactChild
}

const BoxWithClick = ({ isSelected, handleClick, children }: BoxWithClickProps) => {
  const classes = useStyles()
  const boxClasses = classNames({
    [classes.root]: true,
    isSelected
  })

  return (
    <Box className={boxClasses} onClick={handleClick}>
      {children}
    </Box>
  )
}

export default BoxWithClick