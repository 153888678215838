import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles, useTheme, Typography, Divider, Stack, Fab, Alert, Box } from '@material-ui/core';
import { PermIdentity as PermIdentityIcon } from "@material-ui/icons"
import { useLocation } from "react-router-dom";
import { rgba } from "polished";
import { If } from 'react-if';
import { format } from 'date-fns';

import { FabContainer } from 'bos_common/src/components/FabContainers';
import useAxios from 'bos_common/src/services/reservationBackendAxios';
import { getAPIErrorMessage, isEmptyOrNil, useWindowStorage } from 'bos_common/src';
import { PartySetting } from 'bos_common/src/types/ReservationTypes';
import SimpleLoader from 'bos_common/src/components/SimpleLoader';
import { getRoundedMinutesOffset } from 'bos_common/src/services/hoursUtils';

import { Merchant } from '../../services/models';
import { LOCALSTORAGE_MARKETPLACE_APP_KEYS } from '../../services/localStorage';
import { getTimeLabelFromOffset } from '../../utils';
import { TimerSelectChip } from '../Classifieds/styledComponents';
import MerchantHeader from '../MerchantInfo/MerchantHeader';
import PartySettingDrawer from './PartySettingDrawer';
import ConfirmReservationDrawer from './ConfirmReservationDrawer';

interface ReservationDrawerProps {
  merchant: Merchant
}

const RESERVATION_DATE_FORMAT = 'yyyy-MM-dd';

const useStyle = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    background: theme.palette.background.paper,
    borderRadius: '20px 20px 0 0',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',

    '& .icon-btns': {
      position: 'absolute',
      top: '0',
      right: theme.spacing(3),
      transform: 'translateY(-50%)',

      '& .filledIconButton': {

        '&.MuiButtonBase-root': {
          backgroundColor: `${rgba(theme.palette.background.paper, 1)}`,
          transition: "all 200ms linear",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.07)",
          marginRight: theme.spacing(1),

          '&:hover': {
            backgroundColor: `${rgba(theme.palette.background.paper, 1)}`,
          }
        }
      },
    },
  },
  timer: {
    '& .timerSelectBox': {
      width: '100%',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(4),

      '& .timer-icon': {
        width: '100%',
        marginTop: theme.spacing(1),

        '& .timer-list': {
          width: '100%',
          boxSizing: 'border-box',
          overflowX: 'auto',
          padding: `${theme.spacing(1)} 0`
        },

        '& .timer-btn': {
          width: '78px',
          height: '35px',
          borderRadius: theme.spacing(1),
          border: `1px solid ${theme.palette.secondary.light}`,

          '& div': {
            width: '78px',
            height: '35px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 14
          }
        },

        '& .no-sense': {
          height: '35px',
          width: '100%',
          lineHeight: '35px',
          textAlign: 'center',
        }
      }
    }
  },
}))

const ReservationDrawer = ({ merchant }: ReservationDrawerProps) => {
  const classes = useStyle();
  const theme = useTheme();
  const location = useLocation();
  const { id } = merchant;
  const [partySetting, setPartySetting] = useWindowStorage<PartySetting>(
    LOCALSTORAGE_MARKETPLACE_APP_KEYS.RESERVATION_PARAMS,
    {
      merchantId: id,
      peopleNum: 1,
      dinnerTime: getRoundedMinutesOffset(new Date()),
      dinnerDate: format(new Date(), RESERVATION_DATE_FORMAT),
    }, 'sessionStorage');

  const [selectedTimer, setSelectedTimer] = useState<number>(partySetting?.dinnerTime); // 获取数据
  const [showPartySetting, setShowPartySetting] = useState<boolean>(location.hash !== '#reserve'); // 设置party属性的控制器
  const [showConfirm, setShowConfirm] = useState<boolean>(location.hash === '#reserve'); // 设置confirm reservation drawer的控制器
  const [error, setError] = useState<string>("");

  const [{ data: availabilityTimes, loading }, refresh] = useAxios<number[]>({
    url: "/customer/booking/availableTablePeriods",
    params: {
      ...partySetting,
      merchantId: id,
    },
  }, { manual: true});

  const handleProceed = () => {
    if (Array.isArray(availabilityTimes) && availabilityTimes.indexOf(selectedTimer) !== -1) {
      setShowConfirm(true)
    }
  }

  useEffect(() => {
    const nowDate = format(new Date(), RESERVATION_DATE_FORMAT);
    const minutes = getRoundedMinutesOffset(new Date());

    if (partySetting.dinnerDate < nowDate || (partySetting.dinnerDate === nowDate && partySetting.dinnerTime < minutes)) {
      setPartySetting({
        ...partySetting,
        dinnerTime: minutes,
        dinnerDate: nowDate,
      });
    } else {
      setError('');
      refresh()
        .then((response) => {
          if (response.status >= 400) {
            setError("Failed to connect to backend");
          }
        })
        .catch((err) => {
          const msg = getAPIErrorMessage(err);
          setError(msg);
        })
    }
  }, [partySetting])

  return (
    <div className={classes.root}>
      <Box sx={{pl: 2, pr: 2, pt: 2, pb: 1}}>
        <MerchantHeader merchant={merchant} />
      </Box>
      <Divider />

      <Stack spacing={1} direction="column">
        <SimpleLoader loading={loading} />
        <If condition={!isEmptyOrNil(error)}>
          <Alert severity='error' sx={{mt: 2}}>{error}</Alert>
        </If>

        <div style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden', marginTop: '16px' }} className={classes.timer}>
          <div className="timerSelectBox">
            <Typography>Available slots for {partySetting?.dinnerDate}</Typography>
            <div className="timer-icon">
              {
                Array.isArray(availabilityTimes) && !isEmptyOrNil(availabilityTimes)
                ? (
                  <Stack spacing={1} direction="row" className="timer-list">
                  {
                    availabilityTimes.map((timer, index) => (
                      <div key={`timer_${timer}`}>
                        <TimerSelectChip
                          selected={timer === selectedTimer}
                          label={getTimeLabelFromOffset(Number(timer))}
                          onClick={() => { setSelectedTimer(timer) }}></TimerSelectChip>
                      </div>
                    ))
                  }
                </Stack>
              )
              : (<Typography variant="subtitle1" color="text.secondary" className="no-sense" component="div">No available slots found</Typography>)
              }
            </div>
          </div>

          <FabContainer
            alignment='right'
            fabStyle={{ padding: '24px 16px 16px'}}
            fadeTop
          >
            <div className="fabContent" style={{ display: 'flex', alignItems: 'center' }}>
              <Fab
                variant="extended"
                sx={{color: theme.palette.primary.main, backgroundColor: 'white', padding: "0 12px 0 12px !important" }}
                onClick={() => { setShowPartySetting(true); }}
              >
                <PermIdentityIcon />
                {partySetting.peopleNum} &#8226;
                {format(new Date().setHours(0, partySetting.dinnerTime, 0), 'p')},
                {format(new Date(partySetting.dinnerDate), 'PP')}
              </Fab>
              <Fab
                variant="extended"
                color="primary"
                sx={{color: 'white', padding: "0 12px 0 12px !important" }}
                disabled={(Array.isArray(availabilityTimes) && availabilityTimes.indexOf(selectedTimer) === -1) || error !== ''}
                onClick={() => { handleProceed() }}
              >
                Continue
              </Fab>
            </div>
          </FabContainer>
        </div>
      </Stack>

      <PartySettingDrawer
        merchant={merchant}
        open={showPartySetting}
        setOpen={setShowPartySetting}
        data={partySetting}
        setData={setPartySetting}
      />
      <ConfirmReservationDrawer
        merchant={merchant}
        open={showConfirm}
        setOpen={setShowConfirm}
        partySetting={{
          ...partySetting,
          dinnerTime: selectedTimer
        }}
        setPartySetting={setPartySetting}
      />
    </div>
  );
}

export default ReservationDrawer