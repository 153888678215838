import React from 'react'
import { SvgIcon } from "@material-ui/core";

function CoffeeCupIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M13.183 8.4h-.968l.051-1.073a.445.445 0 00-.444-.466h-9.79a.445.445 0 00-.445.466l.197 4.146a2.625 2.625 0 001.558 2.277H1.445a.445.445 0 000 .89h11.026a.445.445 0 000-.89h-1.96a2.627 2.627 0 001.47-1.715h1.202A1.82 1.82 0 0015 10.217 1.82 1.82 0 0013.183 8.4zm-3.74 4.687H4.41c-.93 0-1.693-.728-1.738-1.657l-.175-3.679h8.857l-.175 3.68a1.737 1.737 0 01-1.737 1.656zm3.74-1.942h-1.098l.088-1.856.02.001h.99a.928.928 0 010 1.855zM5.619 6.132a.443.443 0 00.61-.157.445.445 0 00-.157-.61c-.248-.146-.419-.416-.47-.74-.052-.337.036-.673.236-.899a.219.219 0 00.006-.006l.144-.167c.412-.473.838-.962.96-1.673a.445.445 0 10-.877-.15c-.08.465-.392.823-.754 1.238l-.147.17c-.373.422-.54 1.029-.447 1.625.092.588.419 1.087.896 1.369zM7 5.127c.147.378.41.725.738.976a.443.443 0 00.624-.085.445.445 0 00-.084-.623 1.404 1.404 0 01-.448-.59c-.174-.447-.01-.892.55-1.49.396-.422.649-.92.732-1.44a.445.445 0 00-.88-.14c-.055.348-.224.675-.502.973-.292.311-1.18 1.26-.73 2.419z" />
    </SvgIcon>
  );
}

export default CoffeeCupIcon