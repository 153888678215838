import React from 'react'
import { isEmptyOrNil } from '../utils';
import defaultFallbackImg from '../assets/images/placeholder.png';

interface ImageWithFallbackSrcProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string,
  fallbackSrc: string
}

type ImageWithFallbackSrcState = {
  src: string,
  hasError: boolean
}

class ImageWithFallbackSrc extends React.Component<ImageWithFallbackSrcProps, ImageWithFallbackSrcState> {
  constructor(props: ImageWithFallbackSrcProps) {
    super(props);

    this.state = {
      src: props.src,
      hasError: false,
    };
  }

  onError = () => {
    if (!this.state.hasError) {
      const fallbackSrc = !isEmptyOrNil(this.props.fallbackSrc) ? this.props.fallbackSrc : defaultFallbackImg
      this.setState({
        src: fallbackSrc,
        hasError: true,
      });
    }
  }

  render() {
    const { src } = this.state;
    const {
      src: _1,
      fallbackSrc: _2,
      ...props
    } = this.props;

    return (
      <img
        src={src}
        onError={this.onError}
        {...props}
      />
    );
  }
}

export default ImageWithFallbackSrc;