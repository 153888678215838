import React from 'react';
import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';

import SectionHeader from './SectionHeader';
import RewardsTag from './RewardsTag';
import LootboxCounter from './LootboxCounter';
import ProgressIndicator from './ProgressIndicator';
import OutlinedFab from '../../bos_common/src/components/OutlinedFab';
import { AssignmentOutlined } from '@material-ui/icons';

type Mission = {
  title: string,
  subtitle: string,
  progress: number,
  points: string,
  description: string
}
type MissionCardProps = {
  mission: Mission
}

const CardTitle = (props: MissionCardProps) => {
  const { mission } = props

  return (
    <div className='card-header'>
      <SectionHeader
        title={mission.title}
        subtitle={mission.subtitle}
        CountComponent={() => <RewardsTag points={mission.points} />}
      />
    </div>
  )
}

const MissionCard = (props: MissionCardProps) => {
  const { mission } = props

  return (
    <div className="card-container">
      <Card>
        <CardHeader
          title={<CardTitle mission={mission} />}
        />
        <CardContent>
          <Typography variant='body2' component='p' color='textSecondary'>
            {mission.description}
          </Typography>
          <ProgressIndicator progress={mission.progress} />
        </CardContent>
      </Card>
    </div>
  )
}

const LootboxMissions = () => {
  const mission = {
    title: 'Order 5 times',
    subtitle: 'Expires 01/11/21',
    points: '100',
    progress: 50,
    description: 'Get 300 extra points when you order in eligible stores for 5 times. Foods & drink only.'
  }

  return (
    <div className='section-container'>
      <SectionHeader
        title='Missions'
        subtitle='Fill the progress and earn bouns points'
        CountComponent={() => <LootboxCounter count={32} suffix='Active' />}
      />
      <div className='section-content'>
        <MissionCard mission={mission} />
        <MissionCard mission={mission} />
        <MissionCard mission={mission} />
      </div>
      <div className='section-footer'>
        <OutlinedFab variant='extended'>
          <AssignmentOutlined/>&nbsp;See all missions
        </OutlinedFab>
      </div>
    </div >
  )
}

export default LootboxMissions;