import React from 'react';
import { Card, Theme, makeStyles, createStyles, Typography, CardActionArea } from '@material-ui/core';
import { OneMarketPointsAlternateIcon } from '../common/OneMarketIcons';
import { isEmptyOrNil } from '../../utils';
import { If } from 'react-if';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,

      borderRadius: theme.spacing(1),
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,

      minHeight: '100px',
      minWidth: '230px',

      marginRight: theme.spacing(1),
      padding: theme.spacing(2),

      "& .points-container": {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(1),
      },

      "& .rewards-container": {
        backgroundColor: theme.palette.secondary.dark,
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1),
      }
    }
  })
)

type FeaturedItemCardProps = {
  title: string,
  points: string,
  earnedReward?: string
  action?: () => void
}
const FeaturedItemCard = (props: FeaturedItemCardProps) => {
  const classes = useStyles();
  const { title, points, earnedReward, action } = props

  return (
    <Card className={classes.root} elevation={0}>
      <CardActionArea onClick={() => action && action()}>
        <Typography className='bold' variant='subtitle1' component='div'>
          {title}
        </Typography>
        <div className='points-container'>
          {OneMarketPointsAlternateIcon}&nbsp;
          <Typography className='bold' variant='h5' component='span'>
            {points}
          </Typography>&nbsp;
          <Typography variant='subtitle1' component='span'>
            equals to
          </Typography>
        </div>
        <If condition={!isEmptyOrNil(earnedReward)}>
          <span className='rewards-container'>
            <Typography variant='subtitle1' component='span'>
              {earnedReward}
            </Typography>
          </span>
        </If>
      </CardActionArea>
    </Card>
  )
}

export default FeaturedItemCard;