import { Reservation } from "bos_common/src/types/ReservationTypes";
import { RootState } from "../../store";

export const getReservation = (state: RootState) => (id: string): Reservation => {
  const { entities } = state.reservation;
  return entities.byId[id];
}

export const getReservationLoadingState = (state: RootState): boolean => {
  return state.reservation.loading;
}
