

import React from 'react'
import { SvgIcon } from "@material-ui/core";

function SushiIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M4.25 13.156h7.5c1.826 0 3.313-1.472 3.313-3.281V8.388c.545-.193.937-.714.937-1.325 0-2.356-2.87-4.22-5.188-4.22H5.189C2.885 2.844 0 4.696 0 7.064c0 .61.392 1.132.938 1.325v1.487c0 1.81 1.486 3.281 3.312 3.281zm9.875-3.281c0 1.292-1.065 2.344-2.375 2.344h-7.5c-1.31 0-2.375-1.052-2.375-2.344V8.462c1.404-.04 2.192-.264 2.956-.48.82-.232 1.593-.45 3.169-.45s2.35.218 3.17.45c.763.216 1.55.44 2.955.48v1.413zm-9.432-6.06L3.374 5.575a.469.469 0 10.75.563L5.891 3.78h1.64l-.864 1.152a.469.469 0 10.75.563L8.703 3.78h1.64l-.92 1.229a.469.469 0 00.75.562l1.298-1.731c.438.076.89.222 1.321.426l-.97 1.293a.469.469 0 10.75.562l1.035-1.38c.77.549 1.456 1.383 1.456 2.32a.47.47 0 01-.47.47c-1.575 0-2.349-.22-3.168-.452-.846-.239-1.72-.486-3.425-.486-1.706 0-2.58.247-3.425.486-.82.232-1.593.451-3.169.451a.47.47 0 01-.468-.468c0-1.64 2.033-3.016 3.755-3.247z" />
      <path d="M10.813 10.344a.469.469 0 100-.938.469.469 0 000 .938zM5.188 10.344a.469.469 0 100-.938.469.469 0 000 .938zM8 11.281a.469.469 0 100-.937.469.469 0 000 .937z" />
    </SvgIcon>
  );
}

export default SushiIcon