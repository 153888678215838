import React, { useContext } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { UserContext } from '../bos_common/src/context/UserContext';
import { LinearProgress } from '@material-ui/core';

const ProtectedRoute = ({ children, ...rest }: RouteProps): React.ReactElement => {
  const { user, loading, authenticating } = useContext(UserContext)
  const location = useLocation();

  return (
    <>
      {(loading || authenticating) && <LinearProgress />}
      {!authenticating && (
        <Route {...rest}>
          {user ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          )}
        </Route>

      )}
    </>
  )
}

export default ProtectedRoute