import React, { useState } from "react";
import {
  Box,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { rgba } from "polished";
import useAxios from "axios-hooks";
import LazyLoad from 'react-lazyload';
import { Else, If, Then } from "react-if";
import { descend, pathOr, prop, sort } from "ramda";

import StarIcon from "@material-ui/icons/Star";

import { Review } from "../types/ReviewType";
import { getAbbreviatedNumber, getMerchandiseOverallRatings, isEmptyOrNil } from "../utils";
import { Merchandise } from "../types/MerchandiseType";
import BottomDrawer from "./BottomDrawer";
import FilterDropdown from "./FilterDropdown";
import UserReviewCard from "./UserReviewCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "90vh",
      overflowY: "auto",

      "& .filterStarIcon": {
        color: theme.palette.primary.main,
      },

      "& .header": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: `${theme.spacing(3)} ${theme.spacing(1)}`,

        "& .reviewsHeading": {
          fontWeight: 700,
        },
      },

      "& .divider": {
        marginBottom: theme.spacing(2),
      },

      "& .content": {
        padding: `0 ${theme.spacing(2)}`,
        alignItems: "stretch",

        "& .filterContainer": {
          width: 150,
          margin: "0 auto",
          marginBottom: theme.spacing(2)
        },

        "& .reviewCardsContainer": {
          marginBottom: theme.spacing(4),

          "& .lazyload-wrapper": {
            height: "100%"
          }
        },
      },

      "& .ratingItem": {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0.2),
        marginLeft: theme.spacing(0.6),

        "& .MuiSvgIcon-root": {
          fontSize: "1rem"
        },

        "& .ratingNumber": {
          fontWeight: 600,
          fontSize: "0.8rem"
        },

        "&.ratingItemHeader": {
          borderRadius: theme.spacing(0.5),
          padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
          backgroundColor: rgba(theme.palette.primary.main, 0.3),
          color: theme.palette.primary.main,
          fontWeight: 700,

          "& .MuiSvgIcon-root": {
            fontSize: "1.2rem"
          },

          "& .ratingNumber": {
            fontSize: theme.spacing(1.75),
            lineHeight: 1
          },
        }
      },
    },
    bottomDrawer: {
      "z-index": "3000 !important",
    },
  })
);

const ReviewDrawerHeader = ({ numOfReviews, heading }: { numOfReviews?: number, heading: string }) => (
  <Box className="header">
    <Typography className="reviewsHeading" variant={"h6"}>
      {heading}
    </Typography>
    <If condition={!isEmptyOrNil(numOfReviews)}>
      <Paper elevation={1} className="ratingItem ratingItemHeader">
        <Typography variant="subtitle2" className="ratingNumber">
          {getAbbreviatedNumber(numOfReviews!)}
        </Typography>
      </Paper>
    </If>
  </Box>
);

interface MerchandiseReviewsDrawerProps {
  merchandise: Merchandise | null;
  closeDrawer: () => void
}

const MerchandiseReviewsDrawer = (
  props: MerchandiseReviewsDrawerProps
) => {
  const [filter, setFilter] = useState<string>("default");
  const classes = useStyles();

  const { merchandise, closeDrawer } = props;

  const [{ data: reviews }] = useAxios(
    {
      url: `/merchants/merchandises/${merchandise?.id}/reviews`,
      method: "get",
    }
  );

  const overallRatings = getMerchandiseOverallRatings(merchandise);

  if (isEmptyOrNil(reviews) || !overallRatings) {
    return null;
  }

  const toggleDrawer = () => {
    closeDrawer()
  }

  const filterMenuItems = [
    { value: "default", label: "Newest" },
    { value: "1", label: "1", preIcon: <StarIcon className="filterStarIcon" /> },
    { value: "2", label: "2", preIcon: <StarIcon className="filterStarIcon" /> },
    { value: "3", label: "3", preIcon: <StarIcon className="filterStarIcon" /> },
    { value: "4", label: "4", preIcon: <StarIcon className="filterStarIcon" /> },
    { value: "5", label: "5", preIcon: <StarIcon className="filterStarIcon" /> }
  ];

  let filteredReviews = sort(descend(prop("createdAt")), reviews);

  if (filter !== "default") {
    filteredReviews = filteredReviews.filter(
      (review: Review) => {
        const reviewRating = pathOr(0, ["rating", "overall"], review);
        const filterNum = Number(filter);
        const greaterRatingCheck = filterNum === 1 ? reviewRating.toFixed(2) >= (filterNum - 1).toFixed(2) : reviewRating.toFixed(2) > (filterNum - 1).toFixed(2);
        return greaterRatingCheck && reviewRating.toFixed(2) <= filterNum.toFixed(2)
      }
    );
  }

  const numOfFilteredReviews = filteredReviews.length;

  return (
    <BottomDrawer
      open
      setOpen={toggleDrawer}
      className={classes.bottomDrawer}
      keepMounted={false}
      fullWidth
    >
      <Box className={classes.root}>
        <ReviewDrawerHeader
          heading={merchandise?.name ?? ""}
        />
        <Divider className="divider" />
        <Box className="content">
          <Box className="filterContainer">
            <FilterDropdown
              options={filterMenuItems}
              currentFilter={filter}
              setFilter={setFilter}
            />
          </Box>
          <If condition={numOfFilteredReviews === 0}>
            <Then>
              <Typography variant="body1">No Reviews Found</Typography>
            </Then>
            <Else>
              <Grid key={filter} container className="reviewCardsContainer" spacing={2}>
                {filteredReviews.map((review: Review) => (
                  <Grid item xs={12} lg={6} key={review.id}>
                    {/* Placeholder to be added later */}
                    <LazyLoad overflow height={250} offset={250} >
                      <UserReviewCard review={review} />
                    </LazyLoad>
                  </Grid>
                ))}
              </Grid>
            </Else>
          </If>
        </Box>
      </Box>
    </BottomDrawer>
  );
}

export default MerchandiseReviewsDrawer