import React, { useEffect, useContext } from 'react';
import { useState } from 'react';
import { Typography, CardContent, makeStyles, Theme, createStyles } from '@material-ui/core';
import { If, Then } from 'react-if';
import ReactMarkdown from "react-markdown";

import BottomDrawer from '../bos_common/src/components/BottomDrawer';
import { Merchant } from '../services/models';
import { getOfflineMerchantMessage, isKioskDevice, isMerchantOnline } from '../utils';

import OfflineIllustration from '../assets/images/warning-error.svg';
import useAxios from 'axios-hooks';
import { AnnouncementType, MerchantAnnouncement } from '../bos_common/src/types/MerchantAnnouncementTypes';
import SimpleLoader from '../bos_common/src/components/SimpleLoader';
import { MerchantStoreHoursForToday } from './MerchantInfo/MerchantStoreHours';
import { AppContext } from '../context/AppContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTypography-root': {
        lineHeight: 1.2,
      }
    },
    offlineMerchantImageContainer: {
      textAlign: 'center'
    },
    offlineMerchantImage: {
      height: '20vh',
      objectFit: "cover",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
  }),
);

type RenderOfflineMerchantDrawer = { merchant: Merchant }
const RenderOfflineMerchantDrawer = ({ merchant }: RenderOfflineMerchantDrawer): React.ReactElement | null => {
  const isKioskModeEnabled = isKioskDevice()

  const classes = useStyles()
  const { orderType } = useContext(AppContext);
  const isMerchantOrderingAvailable = isMerchantOnline(merchant, orderType)
  const [isDrawerOpen, toggleDrawer] = useState(false);

  const sessionStorageKey = `hide_offline_banner_${merchant.username}`

  const [{ data: announcement, loading }, executeAnnouncementGet] = useAxios<MerchantAnnouncement>(
    {
      url: `/merchants/${merchant.id}/announcements`,
      method: 'get',
      params: { type: AnnouncementType.OFFLINE }
    },
    { manual: true }
  );

  useEffect(() => {
    const showOfflineBanner = window.sessionStorage.getItem(sessionStorageKey) !== 'true'
    if (!isMerchantOrderingAvailable && showOfflineBanner) {
      toggleDrawer(true)
      if (!isKioskModeEnabled) {
        executeAnnouncementGet()
      }
    } else {
      toggleDrawer(false)
    }
  }, [isMerchantOrderingAvailable])

  const RenderTitle = () => (
    <Typography variant='h6' component='div' color='textPrimary'>
      {getOfflineMerchantMessage(orderType, merchant)}
    </Typography>
  )

  const handleClose = () => {
    window.sessionStorage.setItem(sessionStorageKey, "true")
  }

  return (
    <BottomDrawer
      open={isDrawerOpen}
      setOpen={toggleDrawer}
      onClose={handleClose}
      title={RenderTitle()}
      fullWidth
      >
      <CardContent className={classes.root}>
        <SimpleLoader loading={loading} />
        <If condition={!isKioskModeEnabled}>
          <Then>
            <Typography variant='subtitle1' component='div' color='textSecondary'>
              <MerchantStoreHoursForToday merchant={merchant} />
            </Typography>
            <If condition={Boolean(announcement?.content)}>
              <Then>
                <Typography variant='subtitle1' component='div' color='textSecondary'>
                  <ReactMarkdown>
                    {announcement?.content || ''}
                  </ReactMarkdown>
                </Typography>
              </Then>
            </If>
          </Then>
        </If>
        <div className={classes.offlineMerchantImageContainer}>
          <img className={classes.offlineMerchantImage} src={OfflineIllustration} alt="offline-merchant" />
        </div>
      </CardContent>
    </BottomDrawer>
  )
}

export default RenderOfflineMerchantDrawer;