import React, { useEffect, useMemo, useState } from "react";
import { Stack, Divider, IconButton, Theme, Box } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { useParams } from "react-router-dom";
import { pathOr } from "ramda";
import { If, Then } from "react-if";

import SimpleLoader from '../bos_common/src/components/SimpleLoader';
import { MerchantStory, StoryComment } from "bos_common/src/types/MerchantStoryType";
import { getMarketPlaceURL } from "bos_common/src/services/urls";

import StoryHeroSection from "../components/MerchantStories/StoryHeroSection";
import StoryContentCard from "../components/MerchantStories/StoryContentCard";
import AppFooter from "../components/common/AppFooter";
import MenuItemCard from '../components/MerchantMenu/MenuItemCard';
import DocumentMetaTags from "../components/common/DocumentMetaTags";
import CommentList from "../components/Story/CommentList";
import StoryCTA from "../components/MerchantStories/StoryCTA";
import { SignupEntrance } from "../components/User/SignupDrawer";
import { Merchandise } from "../services/models";
import { isEmptyOrNil } from "../utils";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { fetchCheckStoryLiked, fetchMerchantStories, fetchMerchantStoryComments, likeMerchantStory } from "../redux/slice/merchantStory/merchantStoryAction";
import { getMerchantStory, getMerchantStoryComments, getMerchantStoryLikingStatus, getMerchantStoryLoadingStatus } from "../redux/slice/merchantStory/merchantStorySelector";
import { fetchMerchant } from "../redux/slice/merchant/action";
import { getUser } from "../redux/slice/auth/authSelector";
import { showSignUpDrawer } from "../redux/slice/auth/authActions";

import LikeIcon from "../assets/icons/LikeIcon";
import CommentIcon from "../assets/icons/CommentIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      width: '100%',
      maxWidth: "1280px",
      margin: "auto",

      "& .storyCardsWrapper": {
        width: "100%",
        zIndex: 1,
        marginTop: theme.spacing(-5),

        [theme.breakpoints.down("sm")]: {
          marginTop: theme.spacing(-20),
        },

        "& img[alt*='story-image']": {
          width: "100%",
          margin: "0 auto",
          display: "flex",

          [theme.breakpoints.up("sm")]: {
            maxWidth: "50vw",
          }
        },

        "& .merchandiseTitle": {
          color: theme.palette.primary.main,
          margin: theme.spacing(4, 3, 1),
        },

        "& .merchandiseCardsWrapper": {
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gridGap: theme.spacing(1),
          margin: theme.spacing(3),

          [theme.breakpoints.down("md")]: {
            gridTemplateColumns: "1fr 1fr",
          },

          [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr",
          },
        },
      },
    },
  })
);

interface IMerchantPageParams {
  merchantUsername: string;
  merchandiseId?: string;
  storyId?: string;
}

let timeoutId: NodeJS.Timeout | undefined;
let commentPage = 1;

const MerchantStories = (): JSX.Element => {
  const classes = useStyles();
  const { merchantUsername, storyId } = useParams<IMerchantPageParams>();
  const reduxDispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const loading = useAppSelector(getMerchantStoryLoadingStatus);
  const currentStory: MerchantStory | undefined = useAppSelector(getMerchantStory)(merchantUsername, Number(storyId));
  const comments = useAppSelector(getMerchantStoryComments)(merchantUsername, currentStory?.id);
  const liking = useAppSelector(getMerchantStoryLikingStatus);
  const [liked, setLiked] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    window.scrollTo(0, 0); // makes sure we're at top of story on load
    reduxDispatch(fetchMerchant({ username: merchantUsername }));
    reduxDispatch(fetchMerchantStories({ username: merchantUsername }));
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    if (currentStory && user && liked === undefined) {
      reduxDispatch(fetchCheckStoryLiked(currentStory))
        .then((res) => {
          setLiked(Boolean(res.payload));
        });
    }
  }, [currentStory, user]);

  useEffect(() => {
    if (currentStory && !loading && !comments) {
      reduxDispatch(fetchMerchantStoryComments({
        story: {
          id: currentStory.id
        },
        page: commentPage
      }));
      ++commentPage;
    }
  }, [currentStory]);

  const handleClickLike = () => {
    if (!user) {
      reduxDispatch(showSignUpDrawer({
        drawerShown: true,
        signupEntrance: SignupEntrance.DEFAULT,
      }));
    } else {
      reduxDispatch(likeMerchantStory({ id: currentStory?.id }))
        .then(() => setLiked(!liked));
    }
  }

  const handleScroll = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = undefined;
      return;
    }

    const scrollTop = document.scrollingElement?.scrollTop ?? 0;
    const scrollHeight = document.scrollingElement?.scrollHeight ?? 0;
    const clientHeight = document.scrollingElement?.clientHeight ?? 0;

    if (scrollTop < (scrollHeight - 600 - clientHeight)) {
      return;
    }

    timeoutId = setTimeout(async () => {
      if (!loading) {
        try {
          const page = commentPage;
          ++commentPage;
          const res = await reduxDispatch(fetchMerchantStoryComments({ story: { id: Number(storyId) }, page }));

          if (!res.payload || (res.payload as unknown as StoryComment[]).length === 0) {
            document.removeEventListener('scroll', handleScroll);
            return;
          }

        } catch (error) {
          console.error({ error });
        }
      }
    }, 30);
  }

  const heroPhoto = !isEmptyOrNil(currentStory?.heroPhoto)
    ? currentStory?.heroPhoto
    : pathOr("", ['merchant', 'heroPhoto'], currentStory);

  const meta = useMemo(() => ({
    title: currentStory?.title ?? '',
    description: !isEmptyOrNil(currentStory?.subtitle) ? `${currentStory?.subtitle.slice(0, 20)}...` : "",
    canonical: `${getMarketPlaceURL()}/${merchantUsername}/story/${storyId}`,
  }), [currentStory])

  return (
    <DocumentMetaTags meta={meta}>
      <div className={classes.root}>
        {loading && <SimpleLoader loading={loading} />}
        <If condition={!loading && isEmptyOrNil(currentStory)}>
          <div className="storyCardsWrapper">
            {"Cannot find story"}
          </div>
        </If>
        {currentStory &&
          (<>
            <StoryHeroSection
              title={currentStory?.title ?? ''}
              heroImage={heroPhoto}
              merchant={currentStory?.merchant}
            />

            <div className="storyCardsWrapper">
              <If condition={!isEmptyOrNil(currentStory?.content)}>
                <StoryContentCard description={currentStory?.content} />
              </If>

              <If condition={!isEmptyOrNil(currentStory?.aboutOwner)}>
                <StoryContentCard
                  type="secondary"
                  title={currentStory?.owner?.name}
                  subTitle={currentStory?.owner?.designation}
                  image={currentStory?.owner?.photo}
                  description={currentStory?.aboutOwner}
                  showImage
                />
              </If>

              <If condition={!isEmptyOrNil(currentStory?.merchandises)}>
                <Then>
                  <h4 className="merchandiseTitle">Must Try!</h4>

                  <div className="merchandiseCardsWrapper">
                    {currentStory?.merchant && currentStory?.merchandises?.map((merchandise: Merchandise) => {
                      return (
                        <MenuItemCard
                          key={merchandise.id}
                          merchandise={merchandise}
                          merchant={currentStory?.merchant}
                          modifiers={undefined}
                        />
                      );
                    })}
                  </div>
                </Then>
              </If>
            </div>
            <Box sx={{ my: 2 }}>
              <StoryCTA story={currentStory} />
            </Box>
            <Divider />
            <Stack sx={{ ml: 2, mr: 2 }} direction="row" display={"flex"} alignItems="center">
              <IconButton
                color={liked ? "primary" : "inherit"}
                onClick={handleClickLike}
                disabled={liking}
                sx={{ fontSize: '16px' }}>
                <LikeIcon sx={{ mr: 0.5, mt: -0.5 }} />
                {currentStory?.interactions.likes ?? 0}
              </IconButton>
              <IconButton color="inherit" sx={{ fontSize: '16px', }}>
                <CommentIcon sx={{ mr: 0.5 }} />
                {currentStory?.interactions.comments ?? 0}
              </IconButton>
            </Stack>
            <If condition={!isEmptyOrNil(currentStory)}>
              <CommentList merchantUsername={merchantUsername} storyId={currentStory?.id} />
            </If>
          </>)
        }
        <AppFooter />
      </div>
    </DocumentMetaTags>
  );
};

export default MerchantStories;
