import React, { useState, useContext, useEffect, useMemo } from "react";
import { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/styles";
import { useHistory, useLocation } from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';
import { pathOr } from "ramda";
import { CardContent, CardHeader, Drawer, useMediaQuery, Stack, Chip, Divider, Grid, Typography, Fab } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import assert from "assert";

import { FabContainer } from "bos_common/src/components/FabContainers";
import { isPromotionValid } from "bos_common/src/services/PromotionUtils";
import { getItemsEligibleForCoupon, isCouponValid } from "bos_common/src/services/CouponUtils";
import renderPrice from "bos_common/src/components/Price";
import { toFixed2 } from "bos_common/src/utils";
import { ColoredPaper } from "bos_common/src/components/Papers";

import { CouponValueType, Merchandise, Merchant, MerchantCoupon } from "../services/models";
import MerchandiseList from "../components/MerchantMenu/MerchandiseList";
import { AppContext } from "../context/AppContext";
import { VisitedMerchantContext } from "../context/VisitedMerchantContext/VisitedMerchantContext";
import MerchandiseMedia from "../components/MerchantMenu/MerchandiseMedia";
import { isAddingItemToOpenCheck, isMerchandiseAvailable } from "../utils";
import BackButton from "../components/common/BackButton";
import PageHeader from "../components/common/PageHeader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .lazyload-wrapper': {
        height: '100%',
      },
      '& .content-grid': {
        [theme.breakpoints.up('md')]: {
          justifyContent: 'space-evenly',
        },
        '& .merchandise-grid': {
          [theme.breakpoints.up('md')]: {
            maxWidth: '100%',
            flexGrow: 1,
            flexBasis: '100%',
          }
        },
      },
    },
  })
);

/**
 * This method makes a lot of assumptions, not meant to be shared
 * @param subtotal This me
 * @param coupon
 * @returns
 */
const getComboBasicDiscount = (subtotal: number, coupon: MerchantCoupon): number => {
  switch (coupon.valueType) {
    case CouponValueType.COMBO_PERCENTAGE:
      return toFixed2(subtotal * coupon.value);
    case CouponValueType.COMBO_ABSOLUTE:
      return Math.min(coupon.value || 0, subtotal);
    default:
      return 0;
  }
}

type ComboDealsDrawerPageProps = {
  merchant: Merchant,
  comboCouponId: string | undefined,
}

export const ComboDealsDrawerPage = (props: ComboDealsDrawerPageProps): React.ReactElement | null => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{ from: { pathname: string } }>();

  const { merchant, comboCouponId } = props;
  const { cartType, setCoupon: setCartCoupon } = useContext(AppContext);
  const { visitedMenu } = useContext(VisitedMerchantContext);

  const [coupon, setCoupon] = useState<MerchantCoupon | undefined>(undefined);
  const [affectedMerchandises, setAffectedMerchandises] = useState<Merchandise[] | undefined>(undefined);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (comboCouponId && visitedMenu) {
      const idNo = Number(comboCouponId);
      let matchedCoupon: MerchantCoupon | undefined  = undefined;
      merchant.promotions?.forEach((item) => {
        const couponLocal = item.coupons?.find((c) => c.id === idNo );
        matchedCoupon = matchedCoupon || couponLocal;
      });

      if (matchedCoupon && isCouponValid(matchedCoupon)) {
        if (coupon?.promotion && !isPromotionValid(coupon.promotion)) return;
        setCoupon(matchedCoupon);
        const { merchandises = [] } = visitedMenu;
        const allEligibleItems = getItemsEligibleForCoupon(merchandises, matchedCoupon);
        if (allEligibleItems && allEligibleItems.length === 2) {
          setAffectedMerchandises(allEligibleItems);
        }
      }
    } else {
      setCoupon(undefined);
      setAffectedMerchandises(undefined);
    }
  }, [visitedMenu, comboCouponId]);

  const combinedMeida = useMemo(() => affectedMerchandises
  ? {
      ...affectedMerchandises[0],
      photos: affectedMerchandises.reduce((agg: string[], item) => [...agg, ...(item.photos || [])], coupon?.photos || []),
      videos: affectedMerchandises.reduce((agg: string[], item) => [...agg, ...(item.videos || [])], []),
    }
  : undefined, [affectedMerchandises, coupon]);

  const showDrawer = Boolean(affectedMerchandises && coupon);
  if (!showDrawer || !affectedMerchandises || !coupon) {
    return null;
  }

  const total = toFixed2(affectedMerchandises.reduce((sum, item) => sum + Number(item.price), 0));
  const discount = toFixed2(getComboBasicDiscount(Number(total), coupon));
  const available = isMerchandiseAvailable(affectedMerchandises[0]) && isMerchandiseAvailable(affectedMerchandises[1]);

  /**
    * On click header back event handler.
    */
  const handleBack = (): void => {
    const merchantUserName = merchant.username;
    const fromParam = pathOr(undefined, ['state', 'from'], location);

    if (!fromParam) {
      history.replace({
        ...location,
        pathname: `/${merchantUserName}`,
      });
    } else {
      history.goBack();
    }
  }

  const handleClickAdd = () => {
    assert(affectedMerchandises.length === 2);

    setCartCoupon(coupon);
    const ids = affectedMerchandises.map((item) => item.id);
    const from = location.pathname;
    const params = new URLSearchParams();
    params.append('combo', 'true');
    params.append('ids', ids.join(','));
    const to = `/${merchant?.username}/item/${ids[0]}`;
    history.push({
      pathname: to,
      state: { from },
      search: params.toString(),
    });
  }

  return (
    <Drawer
      anchor="bottom"
      open={showDrawer}
      onClose={handleBack}
      ModalProps={{ keepMounted: true }}
      className={classes.root}>
      <div className="container">
        <PageHeader
          title={isAddingItemToOpenCheck(cartType) ? "Add Items to Order" : ""}
          hideOnScroll={false}
          leftChild={<BackButton onBack={handleBack} />}
        />
        <Grid container className="content-grid" elevation={0} component={ColoredPaper}>
          <Grid item xs={12} md={5}>
            {combinedMeida && <MerchandiseMedia merchandise={combinedMeida} />}
          </Grid>
          <Grid item xs={12} md={5}>
            <CardHeader
              title={
                <div className="merchandise-title">
                  <Typography variant={isMobile ? "h6" : "h5"} component="div">
                    {coupon.title}
                  </Typography>
                </div>
              }
              subheader={
                <Typography variant="subtitle1" gutterBottom color="textSecondary">
                  <ReactMarkdown>
                    {coupon.description || ''}
                  </ReactMarkdown>
                </Typography>
              }
            />
            <CardContent>
              <Divider sx={{ mb: '1rem' }} />
              <Stack direction="row" spacing={1}>
                <Chip label="This combo includes" />
              </Stack>
              <Grid container spacing={2} sx={{ mb: '1rem', mt: '1rem', height: '100%' }}>
                <MerchandiseList
                  merchandises={affectedMerchandises}
                  modifiers={visitedMenu?.modifiers}
                  merchant={merchant}
                  readOnly={true}
                />
              </Grid>
            </CardContent>
          </Grid>
        </Grid>

        <FabContainer alignment='right' fabStyle={{ padding: "24px 16px 16px" }} fadeTop={isMobile}>
          <Fab color="primary" variant="extended" onClick={handleClickAdd} disabled={!available}>
            <AddIcon sx={{ mr: 0.5 }} />
            <Typography variant="subtitle1">
              {renderPrice(total - discount)}
            </Typography>
            <Typography variant="caption" sx={{ ml: '.5rem' }} style={{ textDecoration: 'line-through' }}>
              {renderPrice(total)}
            </Typography>
          </Fab>
        </FabContainer>
      </div>
    </Drawer>
  );
};

export default ComboDealsDrawerPage;
