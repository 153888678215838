import React, { useState } from "react";
import { createStyles, Fab, IconButton, InputBase, makeStyles, Theme } from "@material-ui/core";
import { Add, Remove, Delete } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '105px',
      '& .MuiFab-root': {
        minHeight: '32px',
        minWidth: '32px',
        height: '32px',
        width: '32px',
      },
      '& .MuiInputBase-root': {
        margin: 0,
      },
      '& .MuiInputBase-input': {
        textAlign: 'center',
        "-moz-appearance": "textfield",
        '&::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
      },
    },
  }),
);

interface QuantityInputProps {
  quantity: number,
  onQuantityChange: (quantity: number, change: number) => void,
  minQuantity?: number | null,
  maxQuantity?: number | null,
}

export default function QuantityInput({
  quantity,
  minQuantity = 0,
  maxQuantity,
  onQuantityChange
}: QuantityInputProps): React.ReactElement {
  const classes = useStyles()
  const [count, setCount] = useState(quantity)

  React.useEffect(() => {
    setCount(quantity);
  }, [quantity]);

  const onClick = (increment: number) => {
    const newCount = Math.max(0, count + increment)
    setCount(newCount)
    if (newCount !== count) {
      onQuantityChange(newCount, newCount - count)
    }
  }

  const onChange = (value: number) => {
    const oldCount = count
    const newCount = Math.max(value, 0)
    setCount(newCount)
    if (newCount != oldCount) {
      onQuantityChange(newCount, newCount - oldCount)
    }
  }

  const minusDisabled = (minQuantity != null && count <= minQuantity)
  const plusDisabled = (maxQuantity != null && count >= maxQuantity)

  return (
    <div className={classes.root}>
      <Fab size="small" color="secondary" aria-label="reduce" disabled={minusDisabled} onClick={() => onClick(-1)} >
        {(!minQuantity && count == 1) ? (<Delete fontSize="small" />) : (<Remove fontSize="small" />)}
      </Fab>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        value={count}
        type="number"
        onFocus={(e) => e.target.select()}
        onChange={(e) => onChange(+e.target.value)}
        inputProps={{ 'aria-label': 'Input your quantity' }}
      />
      <Fab size="small" color="secondary" aria-label="add" disabled={plusDisabled} onClick={() => onClick(1)} >
        <Add fontSize="small" />
      </Fab>
    </div>
  );
}