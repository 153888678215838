import React from 'react';
import { Chip, createStyles, makeStyles, Theme } from '@material-ui/core';
import { OneMarketPointsAlternateIcon } from '../common/OneMarketIcons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,

      borderRadius: theme.spacing(1),

      "& .rewards-label": {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1rem'
      }
    }
  })
)

type RewardsTagProps = {
  points: string | React.ReactNode
}
const RewardsTag = (props: RewardsTagProps) => {
  const classes = useStyles();
  const { points } = props;

  const RewardsLabel = () => (
    <div className='rewards-label'>
      {OneMarketPointsAlternateIcon}&nbsp;&#43;{points}
    </div>
  )
  return (
    <Chip
      className={classes.root}
      label={<RewardsLabel />}
    />
  )
}

export default RewardsTag;