import React from "react";
import { Theme, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { footerData } from "../../config/constants";
import OneMarketVerticalLogo from "../../assets/icons/OneMarketVerticalLogo";
import { Link } from "react-router-dom";
import SignupForm from "./SignupForm";
import { If } from "react-if";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.background.paper,
    padding: "50px 15px 40px",
    margin: 0,

    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(2),
    },

    "& a": {
      color: theme.palette.info.light,
      textDecoration: "none",
    },

    "& .footerWrapper": {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      maxWidth: "1270px",
      margin: "auto",

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: "20px",
        alignItems: "center",
      },

      '& .logo': {
        width: 96,
        height: 'auto'
      }
    },

    "& .linksWrapper": {
      display: "flex",
      gridRowGap: "50px",

      [theme.breakpoints.down("sm")]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        paddingTop: theme.spacing(3),
        borderTop: `1px solid ${theme.palette.divider}`,
        gridRowGap: "20px",
      },
    },

    "& .footerBottomLinks": {
      textAlign: "center",
      marginTop: theme.spacing(4),

      "& .bottomLink": {
        fontSize: '14px',
        padding: "0 10px",
        borderRight: `1px solid ${theme.palette.divider}`,
        color: theme.palette.info.light,
        letterSpacing: "1.2px",

        "&:last-child": {
          borderRight: 0,
        },
      },
    },
  },

  urlsList: {
    marginRight: theme.spacing(3),

    "& .heading": {
      fontSize: "14px",
      color: theme.palette.secondary.light,
      margin: 0,
      marginBottom: "13px",
    },

    "& .link": {
      margin: 0,
      marginBottom: "10px",

      "& a": {
        transition: "all 200ms linear",

        "&:hover": {
          color: "#b3b3b3",
        },
      },
    },
  },
}));

type LinkProps = {
  name: string;
  url: string;
  target?: string;
};

type UrlsListProps = {
  heading: string;
  links: LinkProps[];
};

const UrlsList = (props: UrlsListProps) => {
  const { heading, links } = props;

  const classes = useStyles();

  return (
    <div className={classes.urlsList}>
      <h5 className="heading">{heading}</h5>

      {links.map((data, index: number) => {
        const { name, url, target="" } = data;

        return (
          <p className="link" key={`link-${index}`}>
            <a href={url} target={target}>
              {name}
            </a>
          </p>
        );
      })}
    </div>
  );
};

const AppFooter = () => {
  const { urlsList, bottomLinks } = footerData;

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <div className="footerWrapper">
        <Link to="/">
          <OneMarketVerticalLogo className="logo" width="141" height="138" />
        </Link>

        <div className="linksWrapper">
          {urlsList.map((data, index) => {
            return <UrlsList key={`list-${index}`} {...data} />;
          })}
        </div>
        <If condition={!isMobile}>
          <div />
        </If>
      </div>

      <div className="footerBottomLinks">
        {bottomLinks.map((data, index) => {
          const { name, url, target } = data;

          return (
            <a href={url} key={`link-${index}`} className="bottomLink" target={target}>
              {name}
            </a>
          );
        })}
      </div>
    </footer>
  );
};

export default AppFooter;
