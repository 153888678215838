import {
  createSlice,
  SliceCaseReducers
} from '@reduxjs/toolkit'
import MarketPlaceSliceState from '../../../types/MarketPlaceSlice';
import { fetchUserPromotionEntries } from './marketPlaceAction';

type MarketPlaceSliceCaseReducer = SliceCaseReducers<MarketPlaceSliceState>;

export const marketPlaceSlice = createSlice<MarketPlaceSliceState, MarketPlaceSliceCaseReducer, string>({
  name: 'marketPlace',
  initialState: {
    loading: false,
    entities: {
      userPromotionEntriesList: undefined,
    }
  },
  reducers: {},
  // reducers for the thunk actions
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserPromotionEntries.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          entities: {
            ...state.entities,
            userPromotionEntriesList: action.payload,
          }
        }
      })

  },
});

export default marketPlaceSlice.reducer;
