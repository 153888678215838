import React from "react";
import { If } from "react-if";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { pathOr } from "ramda";
import { Merchant } from "../../services/models";
import CardWrapper from "./CardWrapper";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getMerchantStories, getMerchantStoryLoadingStatus } from "../../redux/slice/merchantStory/merchantStorySelector";
import { fetchMerchantStories } from "../../redux/slice/merchantStory/merchantStoryAction";
import { MerchantStory } from "bos_common/src/types/MerchantStoryType";
import eventTrackingService from "../../services/Tracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../../services/Tracking/events";
import StoryCTA from "../MerchantStories/StoryCTA";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    "& .item-grid": {
      padding: 1,
      cursor: 'pointer',

      "& .feed-cta-container": {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(-3),
      }
    },
  }
}));

export interface StoriesFeedProps {
  merchant: Merchant;
}

export const StoriesFeed = (props: Readonly<StoriesFeedProps>): JSX.Element => {
  const { merchant } = props;
  const classes = useStyles();
  const reactHistory = useHistory();
  const reduxDispatch = useAppDispatch();
  const merchantStories = useAppSelector(getMerchantStories)(merchant.username);
  const merchantStoriesLoading = useAppSelector(getMerchantStoryLoadingStatus);

  React.useEffect(() => {
    reduxDispatch(fetchMerchantStories(merchant));
  }, []);

  const handleStoryClick = (item: MerchantStory) => {
    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.MERCHANT_STORY,
      action: EVENT_ACTIONS.CLICK_FEED_POST,
      label: `${item.title} - ${item.merchant.officialName}`
    });

    reactHistory.push(`/${merchant?.username}/story/${item.id}`)
  }

  const renderStories = (): JSX.Element[] => {
    return merchantStories
      .filter(story => story.status === 'published')
      .map((item) => (
        <Grid className="item-grid" item xs={12} sm={6} md={4} lg={3} key={`story_${item.id}`}>
          <Card>
            <Box onClick={() => handleStoryClick(item)}>
              {pathOr(null, ['photos', 0], item) &&
                <CardMedia
                  component="img"
                  alt={item.title}
                  height="140"
                  image={pathOr(null, ['photos', 0], item)}
                />}
              <CardContent>
                <Typography gutterBottom variant="subtitle1" component="div">
                  {item.title}
                </Typography>
                <Typography gutterBottom variant="subtitle2" component="div">
                  {item.subtitle}
                </Typography>
                {/* <Box sx={{ display: 'flex', marginTop: 1, alignItems: "center" }}>
                <Avatar alt="image" src={item.owner?.photo ?? `/user-avatar.png`} sx={{ maxWidth: '36px', maxHeight: '36px' }} />
                <div>
                  <Typography component="div" variant="body2" sx={{ margin: '6px 4px', lineHeight:1.2 }}>{item.owner?.name}</Typography>
                  <Typography
                    component="div"
                    color="textSecondary"
                    variant="body2"
                    sx={{ margin: '6px 4px', lineHeight:1.2 }}>
                    {formatDate(item.createdAt)}
                  </Typography>
                </div>
              </Box> */}
            </CardContent>
          </Box>
          <Box className="feed-cta-container" my={2}>
            <StoryCTA story={item} />
          </Box>
        </Card>
      </Grid>
    ));
  }

  return (
    <If condition={merchantStories && merchantStories?.length > 0 && !merchantStoriesLoading}>
      <CardWrapper
        heading="Feed"
        itemsCount={merchantStories.filter(story => story.status === 'published').length}
      >
        <Grid container className={classes.root} spacing={1}>
          {renderStories()}
        </Grid>
      </CardWrapper>
    </If>
  )
}

export default StoriesFeed;
