import React, { useContext } from "react";
import { createStyles, Fab, makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { Else, If, Then } from "react-if";
import { InsertInvitation, ShoppingBag } from "@material-ui/icons";

import { FabContainer } from "../../bos_common/src/components/FabContainers";
import { Merchandise, Merchant } from "../../services/models";
import { enabledReservations, isFollowMerchantEnabled, isMerchantOnline } from "../../utils";
import FollowMerchantCTA from "../FollowMerchant/FollowMerchantCTA";
import MerchantInfoCTA from "../MerchantInfo/MerchantInfoCTA";
import { AppContext } from "../../context/AppContext";
import eventTrackingService from "../../services/Tracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../../services/Tracking/events";
import { useAppSelector } from "../../redux/hooks";
import { getUser } from "../../redux/slice/auth/authSelector";
import { getEventLabel } from "../../services/Tracking/EventsTracking";
import { toExternalReservation } from "../MerchantMenu/StoreLocation/utils";
import { isEmptyOrNil } from "bos_common/src";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "flex-end",
    },
  })
);

type StoreFrontFooterProps = {
  merchant?: Merchant;
  merchandises?: Merchandise[];
};

const StoreFrontFooter = (props: StoreFrontFooterProps) => {
  const { merchant, merchandises } = props;

  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const { featureFlags } = useContext(AppContext);
  const user = useAppSelector(getUser);

  const isReservationEnabled = enabledReservations(featureFlags, merchant);
  const merchantOnline = merchant && isMerchantOnline(merchant);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const history = useHistory();

  const menuPageLink = {
    pathname: `/${merchant?.username}`,
    state: { from: window.location.pathname },
    search: location.search,
  };

  const handleViewMenu = () => {
    if (!merchant) return;

    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.MERCHANT_STORE_FRONT,
      action: EVENT_ACTIONS.CLICK_PICKUP,
      data: {
        merchant: merchant,
        user,
      },
      label: getEventLabel(merchant),
    })

    history.push({
      pathname: `/${merchant?.username}`,
      state: { from: window.location.pathname },
      search: location.search,
    });
  }

  const handleReserve = () => {
    if (!merchant) return;

    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.MERCHANT_STORE_FRONT,
      action: EVENT_ACTIONS.CLICK_RESERVE,
      data: {
        merchant: merchant,
        user,
      },
      label: getEventLabel(merchant),
    })

    const externalLink = toExternalReservation[merchant.id];
    if (externalLink) {
      window.open(externalLink, '_blank');
    } else {
      history.push({
        pathname: `/${merchant?.username}/reserve`,
        state: { from: window.location.pathname },
        search: location.search,
      });
    }
  };

  return (
    <FabContainer
      alignment="right"
      fabStyle={{ padding: "24px 16px 16px" }}
      fadeTop
    >
      <div className={classes.root}>
        {/* <If condition={!isMobile}>
          <FollowMerchantCTA
            merchant={merchant || ({} as Merchant)}
          />
        </If> */}

        <If condition={(!merchantOnline || isEmptyOrNil(merchandises)) && isReservationEnabled}>
          <Then>
            {/* reservation as the primary action */}
            <If condition={!isEmptyOrNil(merchandises)}>
              <Fab
                variant="extended"
                sx={{ color: theme.palette.primary.main, backgroundColor: '#fff' }}
                onClick={handleViewMenu}>
                <ShoppingBag />View Menu
              </Fab>
            </If>
            <Fab
              variant="extended"
              color="primary"
              onClick={handleReserve}>
              <InsertInvitation />Reserve
            </Fab>
          </Then>
          <Else>
            {/* Order/view menu as the primary action */}
            <If condition={isReservationEnabled}>
              <Then>
                <Fab
                  variant="extended"
                  sx={{ color: theme.palette.primary.main, backgroundColor: '#fff' }}
                  onClick={handleReserve}>
                  <InsertInvitation />Reserve
                </Fab>
              </Then>
            </If>
            <If condition={!isEmptyOrNil(merchandises)}>
              <Fab
                variant="extended"
                color="primary"
                onClick={handleViewMenu}>
                <ShoppingBag />{merchantOnline ? `Order Pickup` : `View Menu`}
              </Fab>
            </If>
          </Else>
        </If>
      </div>
    </FabContainer>
  );
};

export default StoreFrontFooter;
