import React from 'react'
import { SvgIcon } from "@material-ui/core";

function TacoIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M14.95 7.84c-.528-2.77-2.32-4.631-4.459-4.631-3.178 0-5.668 3.683-5.668 8.385 0 1.246-.769 1.814-1.483 1.814-.719 0-1.444-.553-1.444-1.786 0-3.397 1.04-5.919 2.927-7.102a.448.448 0 00-.476-.76C2.188 5.115 1 7.906 1 11.623c0 1.76 1.177 2.682 2.34 2.682 1.182 0 2.38-.931 2.38-2.71 0-4.2 2.095-7.49 4.771-7.49 1.696 0 3.133 1.57 3.578 3.904.102.532.03.954-.647 1.285-.614.3-7.157 3.39-7.223 3.421a.448.448 0 10.382.81c.27-.127 6.615-3.123 7.235-3.426.95-.464 1.33-1.224 1.133-2.257z" />
      <path d="M4.163 5.55c-.279.461-.129 1.016-.009 1.462.041.152.103.382.097.44-.043.128-.278.23-.506.33-.355.153-.84.364-.947.894-.109.548.243.995.526 1.354.126.16.336.426.323.522-.04.285-.39.495-.652.476a.448.448 0 10-.06.894c.032.002.064.004.096.004.73 0 1.407-.553 1.504-1.25.065-.475-.239-.86-.507-1.2-.169-.215-.38-.482-.35-.624.013-.07.27-.182.424-.248.35-.152.828-.36.998-.865.1-.293.012-.618-.08-.961-.068-.251-.17-.631-.09-.766.04-.064.164-.101.235-.117.85-.192 1.356-.743 1.387-1.51.012-.297.028-.703.178-.833.09-.078.286-.105.557-.078.431.045 1.083.114 1.546-.284.144-.124.247-.255.339-.37.162-.206.195-.227.262-.228.023-.002.088-.001.214.223a.449.449 0 00.78-.441c-.096-.17-.396-.685-1.005-.678-.51.007-.767.331-.955.568-.072.092-.14.179-.22.246-.17.147-.574.104-.875.073-.536-.057-.938.04-1.23.292-.443.383-.468 1.013-.487 1.473-.005.143-.02.522-.688.673-.374.084-.644.262-.805.529z" />
    </SvgIcon>
  );
}

export default TacoIcon