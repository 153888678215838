import React, { useContext, useEffect } from 'react';
import {
  Box,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Else, If } from 'react-if';
import { path } from 'ramda';

import { ColoredPaper } from '../bos_common/src/components/Papers';
import { Merchant } from '../bos_common/src/types/MerchantType';
import PageHeader from '../components/common/PageHeader';
import { VisitedMerchantContext } from "../context/VisitedMerchantContext/VisitedMerchantContext";
import { isEmptyOrNil } from '../utils';
import { headerCustomStyle } from '../styles/Common.style';
import FloatingSectionsHeader from '../components/common/FloatingSectionsHeader';
import { AccessTimeFilled } from '@material-ui/icons';
import CountBox from '../components/common/CountBox';
import StoresListDetailed from '../components/Stores/StoresListDetailed';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "20px 20px 0px 0px !important",
      zIndex: 1,

      '& .listCard': {
        margin: theme.spacing(1),
      },
    },
    navWrapper: {
      ...headerCustomStyle(theme, theme.palette.primary.main),
    },
  })
);

const VisitHistoryPage = (): React.ReactElement => {
  const title = 'Visit History'
  const classes = useStyles()
  const { visitedMerchantList } = useContext(VisitedMerchantContext);
  const [visitedMerchantsIn24Hrs, setVisitedMerchantsIn24Hrs] = React.useState<Merchant[]>([]);
  const [visitedMerchants, setVisitedMerchants] = React.useState<Merchant[]>([]);
  const visitedKeys = Object.keys(visitedMerchantList ?? {});

  useEffect(() => {
    const nowTime = (new Date()).getTime();
    const dayMs = 24 * 60 * 60 * 1000;

    if (!isEmptyOrNil(visitedKeys)) {
      const merchantList: Merchant[] = (visitedKeys
        .map((key: string) => visitedMerchantList && visitedMerchantList[key]?.merchant)
        .filter((item: Merchant) => {
          return item && visitedKeys.includes(item.id)
        }) || [])
        .sort((merchantA, merchantB)=>{
          const getLastVisited = (id: string)=> path([id , 'lastVisitedAt'], visitedMerchantList);

          const merchantALastVisited = getLastVisited(merchantA.id)?.getTime();
          const merchantBLastVisited = getLastVisited(merchantB.id)?.getTime();

          if(isEmptyOrNil(merchantALastVisited) || isEmptyOrNil(merchantBLastVisited)){
            return 1;
          }

          return merchantBLastVisited - merchantALastVisited;
        });

      setVisitedMerchantsIn24Hrs(
        merchantList
          .filter((item: Merchant) => (visitedMerchantList && nowTime - visitedMerchantList[item.id]?.lastVisitedAt.getTime() <= dayMs))
      );

      setVisitedMerchants(
        merchantList
          .filter((item: Merchant) => (visitedMerchantList && nowTime - visitedMerchantList[item.id]?.lastVisitedAt.getTime() > dayMs))
      );
    }
  }, [visitedMerchantList]);

  const sectionsList = [
    {
      key: "section-within-24",
      title: "Within 24 hours",
      list: visitedMerchantsIn24Hrs
    }, {
      key: "earlier",
      title: "Earlier",
      list: visitedMerchants
    }
  ]

  return (
    <div className="container">
      <div className={classes.navWrapper}>
        <PageHeader title={title} leftChild={<IconButton><AccessTimeFilled fontSize="large" /></IconButton>} />
      </div>

      <ColoredPaper className={classes.root} >
        <If condition={isEmptyOrNil(visitedKeys)}>
          <Box>
            <Typography>You have not visited any merchant.</Typography>
          </Box>
        </If>
        <Else>
          <FloatingSectionsHeader sections={sectionsList} />
          {
            sectionsList.map((item) => (
              <Box id={item.key} key={item.key} sx={{ padding: 1 }}>
                <Typography variant="subtitle1" style={{ textAlign: 'center', gap: "10px" }} mb="1rem">
                  {item.title} <CountBox count={item.list.length} />
                </Typography>
                <StoresListDetailed merchants={item.list} />
              </Box>
            ))
          }
        </Else>
      </ColoredPaper>
    </div>
  );
};

export default VisitHistoryPage;
