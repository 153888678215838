
import React from 'react';
import OneMarketPointsSrc from '../../assets/icons/1m-points.svg'
import OneMarketPointsAlternateSrc from '../../assets/icons/1m-points-alternate.png'
import TreasureSrc from '../../assets/icons/treasure-icon.svg'
import LootboxSrc from '../../assets/icons/lootbox-icon.png'
import LootboxWithShineSrc from '../../assets/icons/lootbox-icon-with-shine.png'
import { ReactComponent as LootBoxIcon } from '../../assets/icons/loot-box.svg';

export const OneMarketPointsAlternateIcon = <img src={OneMarketPointsAlternateSrc} alt="1m-icon" width={20} height={20} style={{ padding: 1 }} />;
export const OneMarketPointsAlternateIconSrc = OneMarketPointsAlternateSrc;

export const LootboxIconSrc = LootboxSrc

export const TreasureIcon = <img src={TreasureSrc} alt="treasure-icon" width={20} height={20} style={{ padding: 1 }} />;
export const TreasureIconSrc = TreasureSrc

export const LootboxWithShineIcon = <img src={LootboxWithShineSrc} alt="lootbox-icon" style={{ padding: 1 }} />;
export const LootboxWithShineIconSrc = LootboxWithShineSrc
export const OneMarketPointsIcon = <img src={OneMarketPointsSrc} alt="1m-icon" width={20} height={20} style={{ padding: 1 }} />;
export const OneMarketPointsIconSrc = OneMarketPointsSrc;
export {
  LootBoxIcon,
};
