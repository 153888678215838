import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { If, Then } from 'react-if';
import {
  CardContent, makeStyles, Typography, createStyles, Theme, Card,
  CardActionArea, Box, Chip, Divider, styled
} from '@material-ui/core';

// src
import { Merchant } from '../../services/models';
import { getMerchantCategoryList, isEmptyOrNil, isMerchantOnline } from '../../utils';
import { AppContext } from '../../context/AppContext';
import { nextOpenHour } from '../../services/hoursUtils';
import CustomizedChip from './CustomizedChip';
import { StyledGreyChip } from '../Classifieds/styledComponents';
import MerchantCategoriesList from './MerchantCategoriesList';
import renderMerchantRewards from "./MerchantRewards";
import AppLogo from "../../assets/icons/logo.svg"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      marginBottom: ({ gutterBottom }: any) => gutterBottom ? theme.spacing(2) : 0,
      display: 'flex',
      flexDirection: 'column',

      '& .cardTitle': {
        fontSize: "16px",
        fontWeight: 700,
        color: theme.palette.info.light,
      },

      '& .containerWithLink': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
        flex: 1,
        padding: theme.spacing(2),

        '& .localReviews': {
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1.1),
          fontWeight: 600,
          fontSize: "12px",
          marginTop: theme.spacing(1),
        },
      },

      '& .merchantLogoContainer': {
        width: 60,
        height: 60,
        position: "relative",

        [theme.breakpoints.down('sm')]: {
          width: 50,
          height: 50,
        },

        '& .logo': {
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius: 20,
        },

        '& .blank-image-placeholder': {
          width: '100%',
          height: '100%',
          backgroundColor: theme.palette.text.secondary,
          color: 'white',
          fontSize: 30,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },

        '& .rating': {
          color: theme.palette.info.light,
          fontSize: "12px",
          fontWeight: "bold",
          background: theme.palette.background.paper,
          padding: `${theme.spacing(0.2)} ${theme.spacing(0.3)}`,
          display: "flex",
          gap: "4px",
          borderRadius: "4px",
          position: "absolute",
          bottom: "-7px",
          left: "50%",
          transform: "translateX(-50%)",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.07)",
        },
      },

      '& .merchantInfo': {
        display: 'flex',
        alignItems: 'center',
        flexWrap: "wrap",
        justifyContent: 'start',
        gap: "5px",
        marginBottom: theme.spacing(1.2),
        fontSize: "12px",
      },
    }
  })
)

const StyledChip = styled(Chip)(({ theme }) => ({
  '&.MuiChip-filled': {
    textTransform: "uppercase",
    color: theme.palette.background.paper,
    fontWeight: 'bold',
    fontSize: 11,
    borderRadius: theme.spacing(0.5)
  }
}))

const RenderCardLabels = ({ merchant }: { merchant: Merchant }) => {
  const { orderType } = useContext(AppContext)
  const isMerchantOrderingAvailable = useMemo(() => isMerchantOnline(merchant, orderType), [merchant, orderType]);
  const nextOpenHourLabel = useMemo(() => !isMerchantOrderingAvailable ? nextOpenHour(merchant) : null, [merchant, isMerchantOrderingAvailable]);

  return (
    <div>
      <If condition={isMerchantOrderingAvailable}>
        <StyledChip label="Open" size="small" variant="filled" color="primary" />
      </If>
      <If condition={!isMerchantOrderingAvailable && !isEmptyOrNil(merchant.hours) && nextOpenHourLabel} >
        <StyledGreyChip variant="filled" size="small" label={`Opens at ${nextOpenHourLabel}`} />
      </If>
    </div>
  );
}

const RenderDot = () => <span style={{ marginLeft: 4, marginRight: 4, fontSize: "12px" }}>{`\u2022`}</span>

const getSubtitles = (merchant: Merchant) => {
  const subtitles = [];
  const points = renderMerchantRewards(merchant);
  if (points) {
    subtitles.push(points)
  }
  return subtitles;
}

type MerchantItemCardProps = {
  merchant: Merchant,
  promotionText?: string,
  gutterBottom?: boolean
}

const MerchantItemCard = ({ merchant, promotionText, gutterBottom = false }: MerchantItemCardProps): JSX.Element => {
  const classes = useStyles({ gutterBottom });

  const categoriesList: string[] = useMemo(() => getMerchantCategoryList(merchant), [merchant]);
  const subtitles = getSubtitles(merchant);

  return (
    <Card elevation={3} className={classes.root}>
      <CardActionArea
        className="containerWithLink"
        component={Link}
        to={{
          pathname: `/${merchant.username}`,
          state: { from: window.location.pathname },
        }}
      >
        <CardContent sx={{ padding: '0 !important', display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center' }}>
          <Typography className="cardTitle" component="div" variant="h6">
            {merchant.officialName}
          </Typography>
          {subtitles.length > 0 && (
            <div className="merchantInfo">
              {subtitles.length > 0
                ? subtitles
                  .map<React.ReactNode>((t, index) => <React.Fragment key={`${index}-${Math.random() * 100}`}>{t}</React.Fragment>)
                  .reduce((prev, curr, currentIdx) => [prev, <RenderDot key={currentIdx} />, curr])
                : null
              }
            </div>
          )}
          <If condition={promotionText}>
            <Box sx={{ my: 1, marginRight: 1 }}>
              <CustomizedChip size="small" label={promotionText} variant="filled" color="secondary" />
            </Box>
          </If>
          {/* {merchant.thirdPartyReviewCount >= 10 && (
              <div className="localReviews">
                <Star sx={{ width: "15px", height: "15px", fill: "#F7CB2E" }} />
                {merchant.thirdPartyReviewCount} locals
                reviews
              </div>
            )} */}
          <RenderCardLabels merchant={merchant} />
        </CardContent>
        <div className="merchantLogoContainer">
          <img className="logo" src={merchant.logo ? merchant.logo : AppLogo} />
          {/* {merchant.thirdPartyRating && (
            <div className="rating">
              <Star sx={{ width: "15px", height: "15px", fill: "#F7CB2E" }} />{" "}
              {merchant.thirdPartyRating}
            </div>
          )} */}
        </div>
      </CardActionArea>
      <Divider />
      <If condition={categoriesList.length > 0}>
        <Then>
          <Box sx={{ px: 2 }}>
            <MerchantCategoriesList categoriesList={categoriesList} />
          </Box>
        </Then>
      </If>
    </Card >
  )
}

export default MerchantItemCard