import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import classNames from 'classnames'

import { isEmptyOrNil } from 'bos_common/src'

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: '100vh',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    display: 'flex',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },

    '& .left-side': {
      [theme.breakpoints.up('md')]: {
        width: ({ sidePanelWidth }: { sidePanelWidth: number | string }) => sidePanelWidth,
      },

      display: 'flex',
      height: 'inherit',
      justifyContent: 'space-between',
      flexDirection: 'column',

      [theme.breakpoints.down('md')]: {
        height: 'auto',
        '& .illustration': {
          display: 'none'
        }
      },
    },

    '& .right-side': {
      boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.07)',
      background: `rgba(0, 0, 0, 0.1)`,
      flex: '1 1 auto',
      overflow: 'auto',
    }
  }
}))

interface SideLayoutWithIllustrationProps {
  illustrationPath: string,
  leftSideContent: React.ReactNode,
  children: React.ReactChild | React.ReactChild[],
  className?: string,
  sidePanelWidth?: number | string
}

const SideLayoutWithIllustration = ({ illustrationPath, leftSideContent, children, className, sidePanelWidth = 400 }: SideLayoutWithIllustrationProps): JSX.Element => {
  const classes = useStyles({ sidePanelWidth });

  return (
    <div className={classNames(classes.root, { [className || ""]: !isEmptyOrNil(className) })}>
      <div className='left-side'>
        {leftSideContent}
        <img className="illustration" src={illustrationPath} />
      </div>
      <div className="right-side">
        {children}
      </div>
    </div>
  )
}

export default SideLayoutWithIllustration