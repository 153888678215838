import React from 'react';
import { Stack, useTheme } from '@material-ui/core';

// src
import { Merchant } from "../../services/models";
import { isEmptyOrNil } from "../../utils";
import { useAppDispatch } from '../../redux/hooks';
import { setShowRewardsEducationDrawer } from '../../redux/slice/merchant/action';
import GemIcon from '../../assets/icons/GemIcon';


const renderMerchantRewards = (merchant: Merchant): JSX.Element | null => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const openRewardsEductionDrawer = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setShowRewardsEducationDrawer(true))
  }

  const isPointsAvailable =
    !isEmptyOrNil(merchant.orderingConfig?.pointsPerDollar) &&
    merchant.orderingConfig?.pointsPerDollar &&
    merchant.orderingConfig?.pointsPerDollar > 0;

  return isPointsAvailable
    ? (
      <Stack
        onClick={openRewardsEductionDrawer}
        className="merchantRewards"
        style={{
          color: theme.palette.secondary.main,
          fontWeight: 700,
          display: "flex",
          gap: theme.spacing(0.5),
          cursor: 'pointer'
        }}
        component="span"
        alignItems="center"
        flexDirection="row"
        >
        <GemIcon sx={{ width: theme.spacing(2), height: theme.spacing(2)}} color="secondary" />
        {merchant.orderingConfig?.pointsPerDollar}%
      </Stack>
    )
    : null;
}

export default renderMerchantRewards;