import React, { useMemo, useState } from "react";
import { createStyles, makeStyles, Theme, Stack } from "@material-ui/core";
import AliceCarousel from "react-alice-carousel";
import useAxios from "axios-hooks";
import { path, pathOr } from "ramda";

import { Merchant } from "bos_common/src/types/MerchantType";
import BottomDrawer from "bos_common/src/components/BottomDrawer";
import { Merchandise } from "bos_common/src/types/MerchandiseType";
import { Review } from "bos_common/src/types/ReviewType";
import UserReviewCard from "bos_common/src/components/UserReviewCard";

import YelpLogo from "../../assets/icons/yelp-logo.svg";
import GoogleLogo from "../../assets/icons/google_icon.svg";
import { getRatingsFromReview, isEmptyOrNil, ReviewOneMarketStats } from "../../utils";

import CardWrapper from "./CardWrapper";
import ReviewsCard from "./ReviewsCard";
import ReviewsSummaryCard from "./ReviewsSummaryCard";
import ReviewsSummaryHeader from "./ReviewsSummaryHeader";
import ReviewsSummaryItems from "./ReviewsSummaryItems";
import eventTrackingService from "../../services/Tracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../../services/Tracking/events";
import { getEventLabel } from "../../services/Tracking/EventsTracking";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .alice-carousel__stage': {
        padding: `10px 0`
      },

      "& .alice-carousel__stage-item": {
        paddingRight: theme.spacing(1),
      },
      "& .review-card-wrapper": {
        borderRadius: theme.spacing(1),
        border: '1px solid rgba(0, 0, 0, 0.05)',
      },
    },
    wrapper: {
      marginLeft: '6px', // weird number because we use a star svg that has some space on the left. 0.75 unit of spacing
      fontWeight: 600,
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center'
    },
  })
);

type ReviewsListProps = {
  merchandises: Merchandise[];
  merchant?: Merchant;
};

const ReviewsList = (props: ReviewsListProps) => {
  const { merchant } = props;

  const classes = useStyles();

  const [reviewDrawOpen, setReviewDrawOpen] = useState(false);

  const [{ data, loading }] = useAxios(
    { url: `/merchants/${merchant?.id}/external-reviews`, method: "get" },
  );

  const [{ data: internalReviewData }] = useAxios(
    {
      url: `/merchants/${merchant?.id}/reviews`, method: "get"
    }
  );

  const sortComparatorByRating = (a: any, b: any) => {
    return b.props.rating - a.props.rating;
  };

  const mapReviewSourceToReviewCard = (items: any, reviewLogo: string) => {
    return items.map((data: any, index: number) => {
      const { author, createdAt, profilePhoto, rating, text, url } = data;

      return (
        <ReviewsCard
          key={`review-${createdAt}-${index}`}
          name={author}
          profileImage={profilePhoto || ""}
          rating={rating}
          createdAt={createdAt}
          reviewText={text}
          reviewLogo={reviewLogo}
          reviewLink={url}
        />
      );
    });
  };

  const mapOneMarketToReviewCard = (items: Review[]) => {
    return items.map((review: Review, index: number) => {
      const merchandiseId = path(['merchandise', 'id'], review)
      const redirectUrl = !isEmptyOrNil(merchant) && !isEmptyOrNil(merchandiseId) ? `/${merchant?.username}/item/${review.merchandise.id}` : ''
      return (
        <div key={`${review.id}-${index}`}>
          <UserReviewCard review={review} showMerchandiseDetails merchant={merchant} redirectUrl={redirectUrl} />
        </div>
      );
    });
  };

  const yelpItems = useMemo(() => {
    if (isEmptyOrNil(data)) return [];
    return mapReviewSourceToReviewCard(
      pathOr([], ['yelp', 'reviews'], data),
      YelpLogo
    ).sort(sortComparatorByRating);
  }, [data]);

  const googleItems = useMemo(() => {
    if (isEmptyOrNil(data)) return [];
    return mapReviewSourceToReviewCard(
      pathOr([], ['google', 'reviews'], data),
      GoogleLogo
    ).sort(sortComparatorByRating);
  }, [data]);

  const oneMarketItems = useMemo(() => {
    if (isEmptyOrNil(internalReviewData)) return [];
    return mapOneMarketToReviewCard(
      pathOr([], ['reviews'], internalReviewData)
    ).sort(sortComparatorByRating);
  }, [internalReviewData])

  const oneMarketStats: ReviewOneMarketStats = useMemo(() => {
    if (isEmptyOrNil(internalReviewData)) return {
      overall: 0,
      taste: 0,
      portion: 0,
      value: 0
    };
    return getRatingsFromReview(internalReviewData);
  }, [internalReviewData]);


  const items = useMemo(() => {
    return [...googleItems, ...yelpItems, ...oneMarketItems];
  }, [googleItems, yelpItems, oneMarketItems]);

  const carouselItems = useMemo(() => {
    return [...items.slice(0, 9)];
  }, [items]);

  const oneMarketTotal = pathOr(0, ['totalReviewCount'], internalReviewData);
  const yelpReviewTotal = pathOr(0, ['yelp', 'total'], data);
  const googleReviewTotal = pathOr(0, ['google', 'total'], data);
  const itemReviewCount = yelpReviewTotal + googleReviewTotal + oneMarketTotal;

  const yelpRating = pathOr(0, ['yelp', 'rating'], data);
  const googleRating = pathOr(0, ['google', 'rating'], data);

  const seeAllGoogleLink = pathOr(0, ['google', 'reviews'], data).length > 0 ? data.google.reviews[0].url : "";
  const seeAllYelpLink = pathOr(0, ['yelp', 'reviews'], data).length > 0 ? data.yelp.reviews[0].url : "";

  const handleSeeMoreClick = () => {
    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.MERCHANT_STORE_FRONT,
      action: EVENT_ACTIONS.CLICK_SEEMORE_REVIEWS,
      label: getEventLabel(merchant ?? {} as Merchant),
      data: {
        merchant: merchant,
      }
    });
    setReviewDrawOpen(true);
  }

  if (loading || itemReviewCount === 0) return null

  return (
    <CardWrapper
      heading="Reviews"
      itemsCount={itemReviewCount}
      handleSeeMore={
        itemReviewCount > 0 ?
          handleSeeMoreClick :
          undefined
      }
    >
      <div className={classes.root}>
        <ReviewsSummaryCard
          reviewsTotal={itemReviewCount}
          hasOneMarketReviews={oneMarketItems.length > 0}
          hasGoogleReviews={googleItems.length > 0}
          hasYelpReviews={yelpItems.length > 0}
          oneMarketRating={oneMarketStats.overall}
          oneMarketTotal={oneMarketTotal}
          googleRating={googleRating}
          googleTotal={googleReviewTotal}
          yelpRating={yelpRating}
          yelpTotal={yelpReviewTotal}
        />
        <AliceCarousel
          items={carouselItems}
          disableButtonsControls
          disableDotsControls={true}
          responsive={{ 0: { items: 1 }, 800: { items: 2 }, 1180: {items: 3} }}
          mouseTracking
          autoWidth
          autoHeight
          infinite={false}
        />
      </div>
      <BottomDrawer
        open={reviewDrawOpen}
        setOpen={() => setReviewDrawOpen(true)}
        onClose={() => setReviewDrawOpen(false)}
        maxHeightPx={700}
        title={
          <ReviewsSummaryHeader
            reviewsTotal={itemReviewCount}
          />
        }
      >
        <Stack direction={"column"} gap={1} sx={{ m: 1 }}>
          <ReviewsSummaryItems
            googleItems={googleItems}
            googleRating={googleRating}
            googleTotal={googleReviewTotal}
            googleLink={seeAllGoogleLink}
            yelpItems={yelpItems}
            yelpRating={yelpRating}
            yelpTotal={yelpReviewTotal}
            yelpLink={seeAllYelpLink}
            oneMarketItems={oneMarketItems}
            oneMarketRating={oneMarketStats.overall}
            oneMarketTotal={oneMarketTotal}
            taste={oneMarketStats.taste}
            portion={oneMarketStats.portion}
            value={oneMarketStats.value}
            merchant={merchant || {} as Merchant}
          />
        </Stack>
      </BottomDrawer>
    </CardWrapper>
  );
};

export default ReviewsList;
