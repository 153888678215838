import * as React from "react";
import { Alert, AppBar, createStyles, Grow, makeStyles, Theme, Toolbar, Typography } from "@material-ui/core";
import { If } from 'react-if';

// src
import { MenuDrawer } from "../common/MenuDrawer";
import { Merchant, OrderType } from "../../services/models";
import PickupOptions from "../PickupDetails/PickupOptions";
import { isKioskDevice } from "../../utils";
import MerchantAddress from "../MerchantInfo/MerchantAddress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .toolbar': {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
      },
      '& .title-container': {
        display: "flex",
        flexDirection: 'row',
        justifyContent: "space-between",
        alignItems: 'center',
        width: '100%',
        '& .title': {
          display: "block",
          textAlign: "center",
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: theme.palette.primary.main,
          fontWeight: "bold"
        },
        '& .leftTitle': {
          display: "block",
          textAlign: "left",
          paddingLeft: theme.spacing(2),
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        '& .right': {
          visibility: 'visible',
          '&.hide': {
            visibility: 'hidden'
          }
        },
      },
      '& .pickup-info-container': {
        width: '100%',
        minHeight: '40px',
      },
    },
  }),
);

type ShoppingHeaderProps = {
  title: string | React.ReactNode,
  orderType: OrderType,
  leftChild?: React.ReactNode,
  merchant?: Merchant,
  errorMessage?: string,
}
const ShoppingCartHeader = ({ title, orderType, leftChild, merchant, errorMessage }: ShoppingHeaderProps): React.ReactElement => {
  const classes = useStyles()
  const isKioskModeEnabled = isKioskDevice()

  return (
    <AppBar color={'inherit'} elevation={3} position={"sticky"} className={classes.root}>
      <Toolbar className='toolbar' disableGutters={true}>
        <div className="title-container">
          <If condition={Boolean(leftChild)}>
            <div className="left">
              {leftChild}
            </div>
          </If>
          <Typography
            variant={isKioskModeEnabled ? "h5" : "h6"}
            component="div" sx={{ flexGrow: 1 }}
            className={leftChild ? "title" : "leftTitle"}
          >
            {title}
          </Typography>
          <If condition={!isKioskModeEnabled}>
            <div className="right">
              <MenuDrawer />
            </div>
          </If>
        </div>
        {
          merchant && (orderType === OrderType.PICKUP) &&
          (
            <Grow in={!!merchant}>
              <div className="pickup-info-container">
                <PickupOptions merchant={merchant} />
              </div>
            </Grow>
          )
        }
      </Toolbar>
      <If condition={!!errorMessage}>
        <Alert severity="warning" className="error-alert">{errorMessage}</Alert>
      </If>
    </AppBar>
  );
}

export default ShoppingCartHeader;