import React, { useState, useEffect, useContext } from 'react';
import localforage from "localforage";

import { MerchandiseApiResponseType, Merchant, OrderType } from '../../services/models';
import { AppContext } from '../AppContext';
import { isMerchantOnline } from '../../utils';
import { VisitedMerchantContext, VisitedMerchantDictionary } from './VisitedMerchantContext';

const VisitedMerchantContextProvider = (props: {
  children: React.ReactNode,
}): React.ReactElement => {
  const [visitedMerchant, setVisitedMerchant] = useState<Merchant>();
  const [visitedMenu, setVisitedMenu] = useState<MerchandiseApiResponseType>();
  const [visitedMerchantList, setVisitedMerchantList] = useState<VisitedMerchantDictionary>({});
  const [isMerchantOrderingAvailable, setMerchantOrdering] = useState<boolean>(true);
  const { orderType, tableQrCodeObject, setOrderType } = useContext(AppContext);

  useEffect(() => {
    const doAsync = async () => {
      try {
        const visitedMerchants: VisitedMerchantDictionary | null = await localforage.getItem<VisitedMerchantDictionary>('visitedMerchantList');
        if (!visitedMerchants) {
          return null;
        }

        await setVisitedMerchantList(visitedMerchants || {});
      } catch (ex) {
        console.error(ex);
      }
    }

    localforage.ready().then(() => {
      doAsync();
    });
  }, []);

  useEffect(() => {
    setMerchantOrdering(isMerchantOnline(visitedMerchant, orderType))

    // Change OrderType to Pickup when switching stores from the DineIn Mode
    if (visitedMerchant && orderType === OrderType.DINEIN && tableQrCodeObject) {
      const isValidTableId = visitedMerchant?.qrCodes?.find(i => i.id === tableQrCodeObject?.id)
      if (!isValidTableId) {
        setOrderType(OrderType.PICKUP)
      }
    }
  }, [visitedMerchant, orderType])

  useEffect(() => {
    const doAsync = async () => {
      try {
        if (!visitedMerchant) {
          return;
        }

        const visitedMerchants: VisitedMerchantDictionary | null = await localforage.getItem<VisitedMerchantDictionary>('visitedMerchantList');
        const updatedVisitedMerchants: VisitedMerchantDictionary = {
          ...visitedMerchants,
          [visitedMerchant?.id]: {
            lastVisitedAt: new Date(),
            merchant: visitedMerchant,
          },
        }

        await localforage.setItem<VisitedMerchantDictionary>('visitedMerchantList', updatedVisitedMerchants);
        await setVisitedMerchantList(updatedVisitedMerchants);
      }
      catch (ex) {
        console.error(ex);
      }
    };

    doAsync();
  }, [visitedMerchant]);

  return (
    <VisitedMerchantContext.Provider
      value={{
        visitedMerchant,
        setVisitedMerchant,
        visitedMenu,
        setVisitedMenu,
        isMerchantOrderingAvailable,
        visitedMerchantList,
      }}
    >
      {props.children}
    </VisitedMerchantContext.Provider>
  )
}

export default VisitedMerchantContextProvider;
