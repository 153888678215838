import React from 'react'
import { SvgIcon } from "@material-ui/core";

function SteakIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      {/* <path d="M4.93336 0.333313C2.33336 0.333313 0.266689 2.59998 0.333356 5.19998C0.400022 6.93331 1.40002 8.39998 2.80002 9.13331C3.73336 9.59998 4.33336 10.5333 4.33336 11.5333V11.6666C4.33336 12.8 5.20002 13.6666 6.33336 13.6666C9.00002 13.6666 9.66669 10.3333 9.66669 4.99998C9.66669 4.99998 9.66669 0.333313 4.93336 0.333313ZM6.33336 12.3333C5.93336 12.3333 5.66669 12.0666 5.66669 11.6666V11.5333C5.66669 10.0666 4.80002 8.66665 3.46669 7.93331C2.40002 7.39998 1.73336 6.33331 1.66669 5.13331C1.66669 4.26665 2.00002 3.33331 2.60002 2.66665C3.20002 1.99998 4.06669 1.66665 4.86669 1.66665C8.13336 1.66665 8.33336 4.46665 8.33336 4.99998C8.33336 11.6 7.20002 12.3333 6.33336 12.3333ZM7.53336 4.06665L2.53336 5.86665C2.40002 5.66665 2.33336 5.39998 2.33336 5.06665C2.33336 4.59998 2.46669 4.19998 2.66669 3.73331L6.13336 2.46665C6.93336 2.86665 7.33336 3.46665 7.53336 4.06665ZM5.60002 9.06665L7.46669 8.39998C7.40002 9.39998 7.20002 10.1333 7.06669 10.6L6.20002 10.9333C6.20002 10.2666 6.00002 9.66665 5.60002 9.06665ZM7.66669 5.13331C7.66669 5.93331 7.66669 6.66665 7.60002 7.26665L4.93336 8.26665C4.60002 7.93331 4.20002 7.59998 3.73336 7.39998C3.46669 7.26665 3.20002 7.06665 3.00002 6.86665L7.66669 5.13331Z" /> */}
      <g clipPath="url(#clip0_8881_45195)">
        <path d="M14.142 5.41a.473.473 0 00-.473-.473H2.332a.473.473 0 00-.474.474 6.148 6.148 0 003.427 5.508l-.865 4.519a.473.473 0 10.93.178l.406-2.122h4.488l.406 2.122a.473.473 0 10.93-.178l-.866-4.52a6.149 6.149 0 003.428-5.507zm-8.205 7.137l.243-1.27a6.121 6.121 0 001.82.275c.633 0 1.244-.096 1.82-.275l.243 1.27H5.937zM8 10.606a5.202 5.202 0 01-5.174-4.722h10.348A5.202 5.202 0 018 10.606zM6.183 2.444c.111-.114.38-.427.474-.868.078-.363.058-.908-.48-1.44a.473.473 0 10-.665.674c.253.25.247.443.22.567a.917.917 0 01-.227.407c-.111.114-.38.427-.474.867-.078.364-.058.909.48 1.44a.472.472 0 00.67-.005.473.473 0 00-.005-.669c-.253-.25-.247-.443-.22-.567a.918.918 0 01.227-.406zM8.34 2.444c.11-.114.379-.427.473-.868.078-.363.058-.908-.48-1.44a.473.473 0 10-.665.674c.253.25.247.443.22.567a.917.917 0 01-.227.407c-.111.114-.38.427-.474.867-.078.364-.058.909.48 1.44a.472.472 0 00.67-.005.473.473 0 00-.005-.669c-.253-.25-.247-.443-.22-.567a.918.918 0 01.227-.406zM10.495 2.444c.111-.114.38-.427.474-.868.079-.363.059-.908-.48-1.44a.473.473 0 10-.665.674c.253.25.247.443.22.567a.917.917 0 01-.227.407c-.111.114-.38.427-.474.867-.078.364-.058.909.48 1.44a.472.472 0 00.67-.005.473.473 0 00-.005-.669c-.253-.25-.247-.443-.22-.567a.917.917 0 01.227-.406z" />
      </g>
      <defs>
        <clipPath id="clip0_8881_45195">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default SteakIcon