import { AxiosRequestConfig } from "axios";
import axios from "../bos_common/src/services/backendAxios";
import { MerchantCoupon } from "./models";

export const getMerchantCoupon = (merchantId: string, code: string, request?: AxiosRequestConfig): Promise<AxiosRequestConfig<MerchantCoupon>> => {
  return axios.get(`/merchants/${merchantId}/coupons?code=${code}`, {
    headers: request?.headers || {},
  });
}

export default {
  getMerchantCoupon,
}
