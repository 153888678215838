import React, { useEffect, useState } from "react"
import { Alert, Button, createStyles, makeStyles, PropTypes, Snackbar, Theme } from "@material-ui/core";
import { pathOr } from "ramda";
import { FileCopyOutlined } from "@material-ui/icons";

import BitlyService from "bos_common/src/services/BitlyService";
import config from "../../config/config";
import { isEmptyOrNil } from "../../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&.MuiButton-root": {
        border: `1px solid`,
        borderRadius: theme.spacing(1.5),
        textTransform: "unset",
        display: "flex",
        justifyContent: "space-between",
      }
    },
  })
);

interface LinkWithCopyButtonProps {
  url: string,
  size?: "small" | "medium",
  fullWidth?: boolean,
  shorten?: boolean,
}

const bitlyAccessToken = config.appConfig.bitlyAccessCode;
const bitlyService = new BitlyService(bitlyAccessToken)

const LinkWithCopyButton = (props: LinkWithCopyButtonProps): JSX.Element | null => {
  const { url, size = "medium", fullWidth = false, shorten = false } = props;
  const [link, setLink] = useState<string>("");
  const [openAlert, setOpenAlert] = useState(false)

  const classes = useStyles();

  const getShortLink = async () => {
    const response = await bitlyService.shortenUrl(url)
    return pathOr('', ['data', 'link'], response)
  }

  useEffect(() => {
    const init = async () => {
      if (shorten) {
        const shortenedUrl = await getShortLink()
        setLink(shortenedUrl)
      } else {
        setLink(url);
      }
    }
    init()
  }, [url])

  const handleButtonClick = () => {
    navigator.clipboard.writeText(link)
      .then(() => {
        setOpenAlert(true);
      }).catch(() => {
        alert("Error while copying")
      })
  }

  const handleClose = () => {
    setOpenAlert(false);
  }

  if (isEmptyOrNil(link)) return null

  return (
    <>
      <Button
        sx={{ px: 2 }}
        size={size} 
        className={classes.root} 
        fullWidth={fullWidth} 
        onClick={handleButtonClick} 
        endIcon={<FileCopyOutlined sx={{ padding: "8px", background: "rgba(0, 0, 0, 0.07)", borderRadius: 1.5 }} />}
      >
        {link}
      </Button>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAlert}
        autoHideDuration={3000}
        onClose={handleClose}
        sx={{ zIndex: 9999 }}
        ClickAwayListenerProps={{
          onClickAway: handleClose
        }}>
        <Alert severity="success">
          Link copied to your clipboard
        </Alert>
      </Snackbar>
    </>
  )
}

export default LinkWithCopyButton;