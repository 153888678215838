import React from 'react';
import { useHistory } from 'react-router';
import { makeStyles, createStyles, Theme, } from '@material-ui/core';
import { HowToVote } from "@material-ui/icons";
import SectionHeader from './SectionHeader';
import OutlinedFab from '../../bos_common/src/components/OutlinedFab';
import { LootBoxVoteCard } from './LootboxVoteCard';
import { Merchant } from "../../bos_common/src/types/MerchantType";
import { If } from 'react-if';
import { isNullOrUndefined } from 'util';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#364245',

      "& .section-header": {
        marginTop: '22px',
      },

      "& .section-title ": {
        fontSize: '1.125rem',
        fontWeight: 600,
      },

      "& .MuiTypography-subtitle2": {
        fontSize: '0.75rem',
        fontWeight: 400,
        color: theme.palette.secondary.light,
        marginBottom: '14px',
      },

      "& .lootbox-counter": {
        fontSize: '0.625rem',
        fontWeight: 700,
        padding: '2px 6px',
        color: '#F7932E',
        background: 'rgba(247, 147, 46, 0.15)',
        borderRadius: '3px',
      }
    },
    fs14: {
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '1rem',
    },

    fs12: {
      fontSize: '0.75rem',
      fontWeight: 400,
      color: theme.palette.secondary.light,
      lineHeight: '1rem',
    },

    fs10: {
      fontSize: '0.625rem',
      fontWeight: 'bold',
      lineHeight: '1rem',
    },
  })
)

interface LootboxVotesProps {
  title: string;
  subtitle?:string;
  totalVotes?:number;
  votable?:boolean;
  merchants: Merchant[];
  countTable: {[key: string]: number};
  onClickSeeVotes?: () => void;
  onVote?: (merchant: Merchant) => void;
}

export const LootboxVotes = (props: Readonly<LootboxVotesProps>): JSX.Element => {
  const history = useHistory();
  const classes = useStyles();
  const { merchants, countTable, onClickSeeVotes } = props;

  const handleClickHowToVote = () => {
    if (onClickSeeVotes) {
      onClickSeeVotes();
    }
    else {
      history.push('/lootbox/votes');
    }
  }

  const handleVote = (merchant: Merchant) => {
    if (props.onVote) {
      props.onVote(merchant);
    }
  }

  const renderMerchants = (): JSX.Element[] => {
    return merchants.map((item) => {
      return <LootBoxVoteCard 
          key={item.id} 
          merchant={item} 
          votable={props.votable} 
          voteCount={countTable[item.id] || 0}
          onVote={handleVote} 
        />
    });
  }

  return (
    <div className={`${classes.root} section-container`}>
      <SectionHeader
        title={props.title}
        subtitle={props.subtitle}
      />
      <div className='section-content'>
        {renderMerchants()}
      </div>
      <div className='section-footer'>
        <If condition={onClickSeeVotes !== null && onClickSeeVotes !== undefined}>
          <OutlinedFab variant='extended' onClick={handleClickHowToVote}>
            <HowToVote/>&nbsp;See your votes and others stores to vote
          </OutlinedFab>
        </If>
      </div>
    </div>
  )
}

export default LootboxVotes;
