

import React from 'react'
import { SvgIcon } from "@material-ui/core";

function EcoFriendlyIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <g clipPath="url(#clip0_7792_41744)">
        <path d="M14.684 6.778l-.058-.319-.32-.058c-.97-.188-2.675-.112-3.629.357C12.25 3.6 9.137.747 8.266.185L7.999 0l-.267.185c-.907.593-3.979 3.428-2.407 6.59-.954-.49-2.654-.562-3.632-.374l-.32.058-.057.32c-.418 2.33.155 6.836 5.407 6.775.318 0 .594-.017.808-.037V16h.937v-2.483c5.645.514 6.717-3.798 6.216-6.739zM7.999 1.162c2.388 1.963 3.129 4.556.469 7.073V4.908H7.53v3.327C4.864 5.712 5.62 3.118 8 1.162zM6.723 12.616c-3.973.049-4.78-2.853-4.528-5.336 3.778-.353 5.417 1.644 5.333 4.67L5.175 9.599l-.663.663 2.355 2.354-.144.001zm5.968-1.151c-.488.534-1.752 1.21-3.558 1.15l2.354-2.354-.663-.663-2.354 2.354c-.004-.217 0-.461.02-.72.278-3.096 2.167-4.178 5.314-3.952.08.815.146 2.926-1.113 4.185z" />
      </g>
      <defs>
        <clipPath id="clip0_7792_41744">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default EcoFriendlyIcon