import React from "react";
import { Box, CardContent, createStyles, Drawer, makeStyles, Theme, Typography } from "@material-ui/core";
import { If } from "react-if";

import PromotionIllustration from "../assets/images/congratulations-rewards.svg";
import { isEmptyOrNil } from "../utils";
import BottomDrawer from "bos_common/src/components/BottomDrawer";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '& .drawerImage': {
      maxWidth: "180px",
      width: "100%",
      marginBottom: theme.spacing(2),
      margin: "auto",
    },

    '& .drawerHeading': {
      margin: 0,
      marginBottom: theme.spacing(1),
      color: "#364245",
      fontSize: 20,
      fontWeight: 600,
    },

    '& .drawerDescription': {
      color: theme.palette.grey[600],
      fontSize: 14,
      lineHeight: 1.3
    },
  }
}))

type CustomDrawerProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  title: string;
  description?: string;
  promotionImage?: string,
  children?: React.ReactElement|null;
  className?: string;
  fullWidth?: boolean;
};

const CustomDrawer = (props: CustomDrawerProps) => {
  const { isOpen, fullWidth=false, className="", setIsOpen, title, description, children, promotionImage = PromotionIllustration } = props;
  if (!isOpen) return null;

  const classes = useStyles();

  const drawerClasses = classNames([classes.root], {[className]: !isEmptyOrNil(className)})

  return (
    <BottomDrawer
      fullWidth={fullWidth}
      className={drawerClasses}
      open={isOpen}
      setOpen={setIsOpen}>
      <CardContent sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center'}}>
        <img className="drawerImage" src={promotionImage} />
        <Box sx={{px:1}}>
          <Typography variant="h6" gutterBottom>{title}</Typography>
          <If condition={!isEmptyOrNil(description)}>
            <p className="drawerDescription">{description}</p>
          </If>
        </Box>
        {children && children}
      </CardContent>
    </BottomDrawer>
  );
};

export default CustomDrawer;
