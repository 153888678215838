import React from 'react';
import { LinearProgress, styled, linearProgressClasses } from '@material-ui/core';
import { LootboxWithShineIcon } from '../common/OneMarketIcons';

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.light,
  },
}));


type ProgressIndicatorProps = {
  progress: number
}
const ProgressIndicator = (props: ProgressIndicatorProps) => {
  return (
    <div className='progress-indicator'>
      <CustomLinearProgress
        value={props.progress}
        variant="determinate"
      />
      <div className="treasure-icon">
        {LootboxWithShineIcon}
      </div>
    </div>
  )
}

export default ProgressIndicator;