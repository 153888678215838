import React, { useEffect, useState } from 'react'
import { Else, If, Then } from 'react-if';
import {
  createStyles, Fab, IconButton, makeStyles, Stack, Theme, Typography
} from '@material-ui/core';
import useAxios from 'axios-hooks';

import { useFetchApiOnInterval } from 'bos_common/src';
import SimpleLoader from 'bos_common/src/components/SimpleLoader';
import CalendarEvent from 'bos_common/src/types/crm/CalendarEventType';
import { Merchant } from 'bos_common/src/types/MerchantType';

import SideLayoutWithIllustration from '../../../components/common/SideLayoutWithIllustration';
import { isEmptyOrNil, getCalendarEventStartEndDates } from '../../../utils';
import CheckInIllustration from '../../../assets/images/kiosk-class-check-in.svg'
import { KIOSK_UPCOMING_CLASSES_FETCH_INTERVAL } from '../../../config/constants';
import KioskClassCheckInDialog from '../../../components/KioskClassCheckInDialog';
import { Refresh } from '@material-ui/icons';
import { addMinutes, differenceInMinutes } from 'date-fns';
import MerchantAvatar from '../../../components/MerchantAvatar';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginTop: `-${theme.spacing(0.5)}`,

    '& .info-section': {
      padding: theme.spacing(4),
      '& .refresh-btn': {
        color: theme.palette.primary.main,
        backgroundColor: 'white'
      }
    },

    '& .events-section': {
      '& .events-list': {
        margin: theme.spacing(4),
        borderRadius: theme.spacing(3),
        backgroundColor: '#fff',
        color: 'black',

        '& .event-item': {
          padding: theme.spacing(3),
          borderBottom: `1px solid ${theme.palette.divider}`,

          '&:last-child': {
            borderBottom: 'none'
          }
        }
      }
    }
  }
}))

type UpcomingEventsProps = {
  upcomingEvents?: CalendarEvent[];
  setSelectedEvent: (_: CalendarEvent) => void;
}

const UpcomingEvents = (props: UpcomingEventsProps) => {
  const { upcomingEvents, setSelectedEvent } = props;

  if (isEmptyOrNil(upcomingEvents)) return null;

  return (
    <div>
      {upcomingEvents!.map((event) => {
        const start = new Date(event.date);
        const end = addMinutes(start, event.duration);
        // Only render events that ended in the last 20 minutes or after
        if (differenceInMinutes(end, new Date()) > -20) {
          const { startDate, endDate } = getCalendarEventStartEndDates(event);
          return (
            <Stack className='event-item' key={event.id} direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
              <div>
                <Typography variant='h4' fontWeight='bold' color="primary">{event.title}</Typography>
                <Typography variant="h6">{event.staff?.user?.displayName ?? ""}</Typography>
                <Typography variant="h6">{startDate} - {endDate}</Typography>
              </div>
              <Fab color='primary' variant='extended' onClick={() => setSelectedEvent(event)}>Check-in</Fab>
            </Stack>
          )
        }
      })}
    </div>
  )
}

interface ServicesMerchantKioskPageProps {
  merchant: Merchant
}

const ServicesMerchantKioskPage = ({ merchant }: ServicesMerchantKioskPageProps): JSX.Element => {
  const classes = useStyles();

  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent>()

  const [{ data: upcomingEvents, loading }, fetcherFunction] = useAxios<CalendarEvent[]>({
    url: `/merchants/${merchant.id}/calendar/upcoming-events`,
  }, { manual: true });

  // made this function cause new Date() was not working when assigned directly to the useAxios params
  const fetchUpcomingEvents = async () => {
    return fetcherFunction({
      params: { minTimestamp: new Date(), durationInMin: 12 * 60 }
    })
  }

  useEffect(() => {
    if (merchant)
      fetchUpcomingEvents()
  }, [merchant])

  useFetchApiOnInterval(fetchUpcomingEvents, KIOSK_UPCOMING_CLASSES_FETCH_INTERVAL)

  const onDialogClose = () => {
    setSelectedEvent(undefined);
  }

  return (
    <SideLayoutWithIllustration
      illustrationPath={CheckInIllustration}
      leftSideContent={
        <div className='info-section'>
          {merchant &&
            <Stack display="flex" direction="row" justifyContent={"space-between"} alignItems="center" sx={{ mb: 4 }}>
              <MerchantAvatar merchant={merchant} />
              <Fab
                variant="circular"
                className="refresh-btn"
                onClick={() => fetchUpcomingEvents()}>
                <Refresh fontSize="large" />
              </Fab>
            </Stack>
          }
          <Typography variant="h2" fontWeight="bold">Checkin for classes here</Typography>
        </div>
      }
      className={classes.root}
    >
      <div className='events-section'>
        <SimpleLoader loading={loading} />
        <div className="events-list">
          <If condition={!loading && isEmptyOrNil(upcomingEvents)}>
            <Then>
              <Typography variant="h6" sx={{ p: 3 }}>No upcoming classes found</Typography>
            </Then>
            <Else>
              <UpcomingEvents
                upcomingEvents={upcomingEvents}
                setSelectedEvent={setSelectedEvent}
              />
            </Else>
          </If>
        </div>
      </div>

      <KioskClassCheckInDialog
        merchant={merchant}
        isOpen={!isEmptyOrNil(selectedEvent)}
        calendarEvent={selectedEvent}
        handleClose={onDialogClose}
      />
    </SideLayoutWithIllustration>
  )
}

export default ServicesMerchantKioskPage;