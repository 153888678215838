import { EVENT_ACTIONS } from "./events";
import {  captureEventForGA, captureOrderCheckoutEventForGA } from "./EventsTracking";
import { captureEventForFP, captureOrderCheckoutEventForFP, initializeFbPixel } from "./FbPixelTracking";
import { CaptureEventParams, CaptureOrderCheckoutEvent } from "./types";

class EventsTrackingService {
  initializeTracking() {
    initializeFbPixel();
  }

  captureEvent(params: CaptureEventParams) {
    captureEventForFP(params)
    captureEventForGA(params)
  }

  captureCheckoutEvent(params: CaptureOrderCheckoutEvent) {
    captureOrderCheckoutEventForGA(params)
    captureOrderCheckoutEventForFP(params)
  }
}

const eventTrackingService = new EventsTrackingService()
export default eventTrackingService;