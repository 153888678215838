import { createStyles, makeStyles, Theme, ToggleButton, ToggleButtonGroup, Typography } from '@material-ui/core';
import { Star } from '@material-ui/icons';
import React from 'react';
import YelpLogo from "../../assets/icons/yelp-logo.svg";
import GoogleLogo from "../../assets/icons/google_icon.svg";
import OneMarketLogo from "../../assets/icons/onemarket-logo.svg";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: theme.spacing(2, 0),
            display: 'flex',
            justifyContent: 'center',
            "& .review-summary-button-wrapper": {
                display: 'flex',
                alignItems: 'center',
                "& .logo": {
                    maxWidth: theme.spacing(5),
                }
            }
            
        }
    })
);

type ReviewsSummaryButtonGroupsProps = {
    selectedReviewSource: string,
    handleReviewSourceChange: any,
    hasOneMarketReviews: boolean,
    hasYelpReviews: boolean,
    hasGoogleReviews: boolean,
    oneMarketRating: number, 
    oneMarketTotal: number,
    googleRating: number, 
    googleTotal: number,
    yelpRating: number, 
    yelpTotal: number,
    isDisabled?: boolean
};

type ReviewsSummaryButtonProps = {
    reviewsIcon: string,
    reviewsRating: number,
    reviewsTotal: number
};

const ReviewsSummaryButton = ({reviewsIcon, reviewsRating, reviewsTotal}: ReviewsSummaryButtonProps) => (
    <Typography className="review-summary-button-wrapper" component="div">
            <img className="logo" src={reviewsIcon} />
            <Star sx={{ width: 16, height: 16 }} color="primary"/>
            <Typography fontWeight="600" fontSize="12px" color="#364245" component="span">{reviewsRating.toFixed(1)}&nbsp;</Typography>
            <Typography fontWeight="600" fontSize="10px" color="#9CA5A8" component="span">({reviewsTotal})</Typography>
    </Typography>
);

const ReviewsSummaryButtonGroups = (props: ReviewsSummaryButtonGroupsProps) => {

    const { selectedReviewSource, handleReviewSourceChange,
            hasOneMarketReviews, hasYelpReviews, hasGoogleReviews,
            oneMarketRating, oneMarketTotal,
            googleRating, googleTotal,
            yelpRating, yelpTotal, 
            isDisabled
        } = props;

    const classes = useStyles();

    const disabledProps = isDisabled ? {
        disabled: true,
        disableFocusRipple: true,
        disableRipple: true
    } : {};

    return (
        <ToggleButtonGroup
                color="standard"
                value={selectedReviewSource}
                exclusive
                onChange={handleReviewSourceChange}
                aria-label="1m, Yelp, Google Reviews"
                className={classes.root}
            >
                {hasOneMarketReviews && (
                    <ToggleButton
                        className="review-source"
                        value="1m"
                        aria-label="1m"
                        key="1m"
                        {...disabledProps}
                    >
                        <ReviewsSummaryButton reviewsIcon={OneMarketLogo} reviewsRating={oneMarketRating} reviewsTotal={oneMarketTotal} />                        
                    </ToggleButton>
                )}
                {hasYelpReviews && (
                    <ToggleButton
                        className="review-source"
                        value="Yelp"
                        aria-label="yelp"
                        key="yelp"
                        {...disabledProps}
                    >
                        <ReviewsSummaryButton reviewsIcon={YelpLogo} reviewsRating={yelpRating} reviewsTotal={yelpTotal} />
                    </ToggleButton>
                )}
                {hasGoogleReviews && (
                    <ToggleButton
                        className="review-source"
                        value="Google"
                        aria-label="google"
                        key="google"
                        {...disabledProps}
                    >
                        <ReviewsSummaryButton reviewsIcon={GoogleLogo} reviewsRating={googleRating} reviewsTotal={googleTotal} />
                    </ToggleButton>
                )}
            </ToggleButtonGroup>
    );
};

export default ReviewsSummaryButtonGroups;