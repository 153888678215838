import { AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "../bos_common/src/services/backendAxios";

export const getUserPaymentMethods = (request?: AxiosRequestConfig): Promise<AxiosResponse> => {
  return axios({
    url: "/user/stripe/payment-methods",
    method: 'GET',
    headers: (request && request.headers) ? request.headers : {},
  });
}

export const updateUserPaymentMethod = (id: string, request?: AxiosRequestConfig): Promise<AxiosResponse> => {
  return axios({
    url: `/user/stripe/payment-methods/${id}`,
    method: 'PATCH',
    headers: (request && request.headers) ? request.headers : {},
  });
}

export const createSetupIntent = (request?: AxiosRequestConfig): Promise<AxiosResponse> => {
  return axios({
    url: `/user/stripe/setup-intent`,
    method: 'POST',
    headers: (request && request.headers) ? request.headers : {},
  });
}

export const deletePaymentMethod = (paymentMethodId: string, request: AxiosRequestConfig): Promise<unknown> => {
  return axios({
    url: `/user/stripe/payment-methods/${paymentMethodId}`,
    method: 'DELETE',
    headers: (request && request.headers) ? request.headers : {},
  });
}

// export const attachPaymentMethod = (paymentMethodId: string, request: AxiosRequestConfig): Promise<AxiosResponse> => {
//   return axios({
//     url: `/user/stripe/attach-payment-method/${paymentMethodId}`,
//     method: 'PUT',
//     headers: request.headers,
//   });
// }

export default {
  updateUserPaymentMethod,
  getUserPaymentMethods,
  createSetupIntent,
  deletePaymentMethod,
  // attachPaymentMethod,
}
