import { createStyles, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { Star } from '@material-ui/icons';
import React from 'react';

type ReviewsOneMarketStatsProps = {
    taste: number,
    portion: number,
    value: number
};

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        reviewsOneMarketStatsRoot: {
            display: 'flex',
            justifyContent: "center",

            "& .review-summary-header-metrics-1m-flex": {
                display: 'flex',
                alignItems: 'center'
            },

            "& > div": {
                padding: theme.spacing(0, 2),
                borderRight: '1px solid rgba(0, 0, 0, 0.15)'
            },

            "& > div:last-child": {
                borderRight: 'none'
            }
        }
    })
);

const ReviewsOneMarketStats = (props: ReviewsOneMarketStatsProps) => {

    const{ taste, portion, value } = props;

    const classes = useStyles();
    const theme = useTheme();

    const renderStatBox = (name: string, num: number) => {
        return (
            <Typography component="div">
                    <Typography color={theme.palette.secondary.light} fontWeight="600" fontSize="12px">{name}</Typography>
                    <div className="review-summary-header-metrics-1m-flex">
                        <Star sx={{ width: 16, height: 16 }} color="primary"/>
                        <Typography fontWeight="600" fontSize="14px">{num.toFixed(1)}</Typography>
                    </div>
            </Typography>
        );
    };

    return (
        <Typography className={classes.reviewsOneMarketStatsRoot} component="div">
                    {renderStatBox('Taste', taste)}
                    {renderStatBox('Portion', portion)}
                    {renderStatBox('Value', value)}                
        </Typography>
    );
};

export default ReviewsOneMarketStats;