import React, { useContext, useEffect, useState, useMemo } from "react";
import {
  createStyles,
  makeStyles,
  TextField,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  InputAdornment,
  Card,
  Divider,
} from "@material-ui/core";
import debounce from "debounce";

// src
import renderPrice from "../../bos_common/src/components/Price";
import { AppContext, TipData } from "../../context/AppContext";
import {
  getMerchantTipRatesConfig,
  getSubtotalPrice,
} from "../../services/cartUtils";
import { rgba } from "polished";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),

      '& .tipsHeading': {
        padding: theme.spacing(1),
        margin: 0,
      },

      '& .MuiToggleButtonGroup-root': {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: 375,
        padding: theme.spacing(2, 1),
      },
      "& .gratuityItem": {
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        borderRadius: "8px !important",
        borderColor: theme.palette.info.light,
        background: rgba(theme.palette.info.light, 0.15),
        borderLeft: `1px solid ${theme.palette.info.light} !important`,
        color: theme.palette.info.light,
        fontSize: 14,
        fontWeight: 600,

        "&.Mui-selected": {
          borderColor: theme.palette.primary.main,
          background: rgba(theme.palette.primary.main, 0.15),
          borderLeft: `1px solid ${theme.palette.primary.main} !important`,

          "& .MuiToggleButton-label": {
            color: theme.palette.primary.main
          }
        },

        "& .customTip": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent"
          }
        },
      },
      "& .customButton": {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        "& .MuiInputBase-root": {
          paddingLeft: theme.spacing(1),
          fontSize: "14px",
          fontWeight: "bold",
        },
        "& .MuiInputAdornment-root": {
          marginRight: 0,
        },
      },
      "& .tipAmount": {
        fontSize: 12,
      },
      "& .MuiToggleButton-label": {
        display: "inline-block",
        lineHeight: "20px",
      },
      "& .MuiInputBase-input": {
        textAlign: "center",
        minWidth: 55,
        maxWidth: 80,

        "-moz-appearance": "textfield",
        "&::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        "&::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
      },
    },
  })
);

type TipOptionDisplay = {
  tipPercent: number;
  percentDisplay: string;
  priceDisplay: string;
};

export default function TipOptions(): React.ReactElement {
  const classes = useStyles();
  const { tip, setTip, cart, merchantConfig } = useContext(AppContext);
  const subTotal = getSubtotalPrice(cart);

  const merchantTipOptions: TipData[] =
    getMerchantTipRatesConfig(merchantConfig);

  const tipOptionDisplay: TipOptionDisplay[] = [];
  merchantTipOptions.forEach((tipOption: TipData) => {
    if (tipOption.tipRate) {
      tipOptionDisplay.push({
        tipPercent: tipOption.tipRate,
        priceDisplay: renderPrice(subTotal * tipOption.tipRate),
        percentDisplay: `${(tipOption.tipRate * 100).toFixed(0)}%`,
      } as TipOptionDisplay);
    }
  });

  useEffect(() => {
    if (tip.tipRate === undefined && tip.customTip === undefined) {
      setTip(merchantTipOptions[0]);
    }
  }, []);

  const currentOption =
    tip.tipRate || tip.customTip
      ? merchantTipOptions.find((option) => option.tipRate === tip.tipRate)
        ? tip.tipRate
        : "custom"
      : merchantTipOptions[0].tipRate;

  const [option, setOption] = useState(currentOption);
  const [customTip, setCustomTip] = useState<string>(
    tip.customTip ? String(tip.customTip) : ""
  );
  const [isCustomTipSelected, setCustomTipSelected] = useState(false);
  const debouncedHandler = useMemo(() => debounce(setTip, 500), []);

  const onTipRateChange = (
    event: React.MouseEvent<HTMLElement>,
    newOption: string | number | null
  ) => {
    if (newOption !== null) {
      setOption(newOption);

      if (merchantTipOptions.find((option) => newOption === option.tipRate)) {
        const newTip: TipData = {
          tipRate: Number(newOption),
        };
        setTip(newTip);
        setCustomTip("");
      }
    }
  };

  const onCustomTipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCustomTip = Number(event.target.value);
    const newVal = newCustomTip < 0 ? "0" : event.target.value;
    setCustomTip(newVal);

    const newTip: TipData = { customTip: Number(newVal) };
    debouncedHandler(newTip);
  };

  return (
    <Card className={classes.root} id="shopping-cart-tips">
      <Typography className="tipsHeading" gutterBottom variant="subtitle1" color="textSecondary" component="div">
        Tips
      </Typography>

      <Divider />

      <ToggleButtonGroup
        color="primary"
        value={option}
        exclusive
        onChange={onTipRateChange}
        aria-label="Gratuity options"
      >
        {tipOptionDisplay.map((tipOption: TipOptionDisplay) => (
          <ToggleButton
            className="gratuityItem"
            value={tipOption.tipPercent}
            aria-label={tipOption.percentDisplay}
            key={tipOption.percentDisplay}
            onClick={()=>setCustomTipSelected(false)}
          >
            {tipOption.priceDisplay}
            <br />
            <span className="tipAmount">{tipOption.percentDisplay}</span>
          </ToggleButton>
        ))}

        <ToggleButton
          value="custom"
          aria-label="customize"
          className="customButton gratuityItem"
          selected={isCustomTipSelected}
          onClick={()=>setCustomTipSelected(true)}
        >
          <TextField
            onClick={()=>setCustomTipSelected(true)}
            className="customTip"
            color="primary"
            label=""
            type="number"
            size="small"
            value={customTip}
            inputProps={{ min: 0 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              size: "small",
            }}
            placeholder="Custom"
            onChange={onCustomTipChange}
          />
        </ToggleButton>
      </ToggleButtonGroup>
    </Card>
  );
}
