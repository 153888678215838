import React from "react";
import { Box, Skeleton } from "@material-ui/core";

const MerchantCardPlaceholder = () => (
    <Box>
      <Skeleton
        variant="rectangular"
        width={"100%"}
        animation="wave"
        height={160}
        style={{
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
      />
      <Skeleton width={"100%"} animation="wave" height={30} />
      <Skeleton width={"60%"} animation="wave" height={25} />
    </Box>
  );

  export default MerchantCardPlaceholder;