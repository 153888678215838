import { Merchandise } from "./MerchandiseType";
import { Merchant } from "./MerchantType";

export type ClassifiedDetailsType = {
  key: string,
  name: string,
  oneliner: string,
  displayOrder: number
}

export enum ClassifiedType {
  merchant = "merchant",
  merchandise = "merchandise"
}

export enum ClassifiedKey {
  hotDeal = "hot_deal",
  hotItem = "hot_item",
  bigSavings = "big_savings",
  exclusive = "exclusive",
  localGem = "local_gem"
}

export type ClassifiedEntry = {
  type: ClassifiedType,
  valueType: 'percentage' | 'absolute',
  value: number,
  details: ClassifiedDetailsType,
  merchandise: Merchandise | null,
  merchant: Merchant | null,
  oneliner: string | null
}

export type ClassifiedsApiResponseType = {
  [key: string]: ClassifiedEntry[]
}