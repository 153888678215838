import React, { useMemo } from 'react';
import { Box, Fab, Stack } from '@material-ui/core';
import { isEmptyOrNil } from 'bos_common/src';
import { MerchantStory } from 'bos_common/src/types/MerchantStoryType';
import { useHistory } from 'react-router-dom';
import eventTrackingService from '../../services/Tracking';
import { EVENT_CATEGORIES, EVENT_ACTIONS } from '../../services/Tracking/events';

interface StoryCTAProps {
    story: MerchantStory;
    style?: React.CSSProperties;
    size?: "small" | "medium" | "large";
    alignLeft?: boolean;
}

const StoryCTA = (props: StoryCTAProps) => {
    const { story, style, size="medium", alignLeft=false } = props;
    const history = useHistory();

    const renderCTA = useMemo(() => {
        const navigateToMenu = () => history.push({ pathname: `/${story.merchant.username}`, state: { from: window.location.pathname } })

        const navigateToExternalLink = () => {
            eventTrackingService.captureEvent({
                category: EVENT_CATEGORIES.MERCHANT_STORY,
                action: EVENT_ACTIONS.CLICK_FEED_EXTERNAL_LINK,
                label: `${story?.action?.typeInfo?.actionButtonText} - ${story?.action?.typeInfo?.externalLink}` ?? 'External Link'
            });

            const url = story?.action?.typeInfo?.externalLink;
            window.open(url, '_blank')
        }

        const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, ctaCB: ()=>void) => {
          e.preventDefault();
          e.stopPropagation();
          ctaCB();
        }

        if (isEmptyOrNil(story.action)) {
            return (
                <Stack direction={"row"} spacing={2}>
                    <Fab sx={style} size={size} onClick={(e) => onClick(e, navigateToMenu)} variant="extended" color="primary">
                        Order Now
                    </Fab>
                </Stack>
            );
        } else if (story?.action?.type === 'external-link') {
            return (
                <Stack direction={"row"} spacing={2}>
                    <Fab size={size} onClick={(e) => onClick(e, navigateToExternalLink)} variant="extended" color="primary">
                        {story?.action?.typeInfo?.actionButtonText}
                    </Fab>
                </Stack>
            );
        }
        return null;
    }, [story]);

    return (
      <Stack direction="row" justifyContent={alignLeft ? "flex-start" : "center"} mx={alignLeft ? "unset" : "auto"}>
        {renderCTA}
      </Stack>
    );
}
export default StoryCTA;