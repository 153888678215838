import React from "react";
import { SvgIcon } from "@material-ui/core";

function ChatIcon(props: any) {
  return (
    <SvgIcon
      sx={{ width: "41px", height: "41px" }}
      viewBox="0 0 41 41"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_17209_73243)">
        <path d="M0.355122 26.098C0.0232483 25.7673 -0.0774544 25.2706 0.0941856 24.8346L1.90918 20.289C0.666371 18.2726 0.011764 15.9563 0.011764 13.5507C0.011764 6.44287 5.79557 0.660156 12.9023 0.660156C20.0091 0.660156 25.7929 6.44287 25.7929 13.5507C25.7929 14.5944 25.6785 15.5809 25.4542 16.4827C25.3466 16.9095 25.0101 17.2402 24.5821 17.3399C20.6935 18.2439 17.5967 21.3407 16.6926 25.2305C16.5919 25.6597 16.2601 25.9961 15.8344 26.1026C14.9303 26.3269 13.9438 26.4413 12.9024 26.4413C10.4969 26.4413 8.18064 25.7867 6.1619 24.5439L1.61863 26.3578C1.17402 26.5334 0.681683 26.4246 0.355122 26.098ZM24.3167 16.1989H24.3396H24.3167Z" fill="#FF8066" />
        <path d="M15.8344 26.1026C16.2601 25.9961 16.5919 25.6597 16.6926 25.2305C17.5967 21.3407 20.6935 18.2439 24.5821 17.3399C25.0102 17.2403 25.3466 16.9095 25.4542 16.4827C25.6785 15.5809 25.7929 14.5944 25.7929 13.5507C25.7929 6.44287 20.0091 0.660156 12.9023 0.660156V26.4413C13.9438 26.4413 14.9303 26.3269 15.8344 26.1026Z" fill="#FF4D4D" />
        <path d="M38.4048 40.5765L33.8616 38.7626C31.8428 40.0054 29.5266 40.6601 27.121 40.6601C20.0143 40.6601 14.2305 34.8773 14.2305 27.7695C14.2305 26.7258 14.3449 25.7393 14.5692 24.8375C15.6724 20.1031 19.4558 16.3186 24.2074 15.2142C25.0932 14.9934 26.0796 14.8789 27.121 14.8789C34.2278 14.8789 40.0116 20.6616 40.0116 27.7695C40.0116 30.175 39.357 32.4913 38.1142 34.5077L39.9292 39.0533C40.1008 39.4894 40.0001 39.986 39.6683 40.3168C39.3423 40.6426 38.8503 40.7526 38.4048 40.5765Z" fill="#FFE14D" />
        <path d="M12.9034 20.1127C12.6288 20.1127 12.3564 20.0177 12.1367 19.8266C11.6126 19.3723 11.1113 18.9512 10.6421 18.5552C7.90008 16.2401 5.91797 14.5681 5.91797 12.0195C5.91797 9.59227 7.71008 7.69141 9.99664 7.69141C11.2852 7.69141 12.2374 8.27961 12.9034 9.02008C13.5695 8.27961 14.5216 7.69141 15.8102 7.69141C18.0968 7.69141 19.8889 9.59227 19.8889 12.0195C19.8889 14.7609 17.4499 16.6273 15.1488 18.569C14.6841 18.9604 14.1898 19.378 13.6702 19.8267C13.4505 20.0177 13.1758 20.1127 12.9034 20.1127Z" fill="#F0F7FF" />
        <path d="M13.6691 19.8267C14.1886 19.3781 14.683 18.9604 15.1477 18.569C17.4488 16.6273 19.8878 14.7608 19.8878 12.0195C19.8878 9.59227 18.0957 7.69141 15.8091 7.69141C14.5205 7.69141 13.5684 8.27961 12.9023 9.02008V20.1128C13.1746 20.1128 13.4493 20.0178 13.6691 19.8267Z" fill="#DFE7F4" />
        <path d="M33.8616 38.7626L38.4049 40.5765C38.8503 40.7525 39.3424 40.6426 39.6683 40.3168C40.0002 39.9861 40.1009 39.4894 39.9292 39.0533L38.1143 34.5077C39.3571 32.4913 40.0117 30.175 40.0117 27.7695C40.0117 20.6616 34.2279 14.8789 27.1211 14.8789V40.6601C29.5266 40.6601 31.8429 40.0054 33.8616 38.7626Z" fill="#FABE2C" />
        <path d="M31.8086 28.9414H30.6367V26.5977H31.8086C32.4563 26.5977 32.9805 26.0735 32.9805 25.4258C32.9805 24.778 32.4563 24.2539 31.8086 24.2539H30.6367V23.082C30.6367 22.4343 30.1126 21.9102 29.4648 21.9102C28.8171 21.9102 28.293 22.4343 28.293 23.082V24.2539H25.9492V23.082C25.9492 22.4343 25.4251 21.9102 24.7773 21.9102C24.1296 21.9102 23.6055 22.4343 23.6055 23.082V24.2539H22.4336C21.7859 24.2539 21.2617 24.778 21.2617 25.4258C21.2617 26.0735 21.7859 26.5977 22.4336 26.5977H23.6055V28.9414H22.4336C21.7859 28.9414 21.2617 29.4655 21.2617 30.1133C21.2617 30.761 21.7859 31.2852 22.4336 31.2852H23.6055V32.457C23.6055 33.1048 24.1296 33.6289 24.7773 33.6289C25.4251 33.6289 25.9492 33.1048 25.9492 32.457V31.2852H28.293V32.457C28.293 33.1048 28.8171 33.6289 29.4648 33.6289C30.1126 33.6289 30.6367 33.1048 30.6367 32.457V31.2852H31.8086C32.4563 31.2852 32.9805 30.761 32.9805 30.1133C32.9805 29.4655 32.4563 28.9414 31.8086 28.9414ZM25.9492 28.9414V26.5977H28.293V28.9414H25.9492Z" fill="#4D628C" />
        <path d="M29.4648 33.6289C30.1126 33.6289 30.6367 33.1048 30.6367 32.457V31.2852H31.8086C32.4563 31.2852 32.9805 30.761 32.9805 30.1133C32.9805 29.4655 32.4563 28.9414 31.8086 28.9414H30.6367V26.5977H31.8086C32.4563 26.5977 32.9805 26.0735 32.9805 25.4258C32.9805 24.778 32.4563 24.2539 31.8086 24.2539H30.6367V23.082C30.6367 22.4343 30.1126 21.9102 29.4648 21.9102C28.8171 21.9102 28.293 22.4343 28.293 23.082V24.2539H27.1211V26.5977H28.293V28.9414H27.1211V31.2852H28.293V32.457C28.293 33.1048 28.8171 33.6289 29.4648 33.6289Z" fill="#334973" />
      </g>
      <defs>
        <clipPath id="clip0_17209_73243">
          <rect width="40" height="40" fill="white" transform="translate(0.0117188 0.660156)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default ChatIcon;
