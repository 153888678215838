import React, { useContext, useMemo, useState } from "react"
import {
  CardContent, MenuItem, Button, makeStyles, Theme, createStyles, Alert, Typography,
  TextField, InputAdornment
} from "@material-ui/core"
import { format } from "date-fns"
import { all, pathOr } from "ramda";
import { If } from "react-if";
import { DateRange, Schedule } from "@material-ui/icons";

import BottomDrawer from "../../bos_common/src/components/BottomDrawer"
import { getRoundedMinutesOffset } from "bos_common/src/services/hoursUtils";
import { Merchant, SpecialHours } from "../../services/models";
import { isStoreOpen } from "../../utils";
import { VisitedMerchantContext } from "../../context/VisitedMerchantContext/VisitedMerchantContext";
import { findSpecialDays, getStoreTimeOptions, isTimeItemDisabled } from "../../services/hoursUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(1),

      '& .scheduleForm': {
        display: 'flex',
        flexDirection: "column",
        width: '100%',
        maxWidth: 450,
        minWidth: 60,
        margin: '0 auto',

        '& .MuiFormControl-root': {
          marginBottom: theme.spacing(2),
        }
      },
      '& .scheduleButton': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
      }
    },
  }),
);

const DATE_FORMAT = 'EEE, MMM d'

type ScheduleOrderDrawerProps = {
  defaultValue?: Date,
  merchant: Merchant | undefined,
  open: boolean,
  setOpen: (open: boolean) => void,
  onSchedule: (d: Date) => void,
  onClose: () => void,
}

type TimeOptionType = {
  label: string,
  minuteOffset: number
}
type DateOptionType = {
  label: string,
  date: Date,
  isValid: true,
  timeOptions: TimeOptionType[]
}

const ScheduleOrderDrawer = (props: ScheduleOrderDrawerProps): React.ReactElement => {
  const classes = useStyles()

  const { defaultValue, merchant } = props;
  const now = new Date()
  const todayTS = new Date(now).setHours(0, 0, 0, 0);

  const defaultDate = new Date(defaultValue || now);
  const [pickupDateTS, setDate] = useState<number>(new Date(defaultDate).setHours(0, 0, 0, 0));
  const [pickupMinuteOffset, setMinuteOffset] = useState<number>(getRoundedMinutesOffset(defaultDate))
  const [errorMsg, setErrorMsg] = useState<string>("")
  const { isMerchantOrderingAvailable } = useContext(VisitedMerchantContext)

  const merchantSpecialHours = pathOr([], ['hours', 'customizations'], merchant)

  const isOpenOnDay = (d: Date) => {
    const specialDays = findSpecialDays(d, merchantSpecialHours)
    if (!specialDays) return true

    const isOpen = (specialDay: SpecialHours) => !(specialDay.toMinOffset === 0 || specialDay.fromMinOffset === 0)
    return all(isOpen, specialDays)
  }

  // Only support the same day order ahead right now
  const getPickupDateOptions = () => {
    const dateOptions: DateOptionType[] = []
    const d = new Date(todayTS);
    const numDaysToOrderAhead = merchant?.orderingConfig?.numDaysToOrderAhead || 0;

    const getDateLabel = (index: number, label: string) => {
      if (index > 1) return label
      return index === 0 ? 'Today' : 'Tomorrow'
    }

    for (let i = 0; i < 1 + numDaysToOrderAhead; i++) {
      const pDate = new Date(d)
      const timeOptions = getStoreTimeOptions(pDate.valueOf(), merchant)
      if (timeOptions.length > 0)
        dateOptions.push({
          label: getDateLabel(i, format(d, DATE_FORMAT)),
          date: pDate,
          isValid: isOpenOnDay(d),
          timeOptions
        })
      d.setDate(d.getDate() + 1)
    }
    setDate(pathOr(defaultDate, [0, 'date'], dateOptions).valueOf())
    return dateOptions
  }

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMinuteOffset(Number(event.target.value));
    setErrorMsg("")
  };

  const handleDateChange = (event: React.ChangeEvent<{ value: string }>) => {
    setDate(Number(event.target.value));
    setErrorMsg("")
  };

  const onClickSchedule = () => {
    if (isTimeItemDisabled(pickupMinuteOffset, pickupDateTS, merchant)) {
      setErrorMsg("Please choose a valid pick up time")
    } else {
      const pickupFinalDateTime = new Date(pickupDateTS + pickupMinuteOffset * 60 * 1000)
      props.onSchedule(pickupFinalDateTime)
      props.setOpen(false)
      setErrorMsg("")
    }
  }

  const pickupDateOptions = useMemo(() => getPickupDateOptions(), []);
  const pickupTimeOptions = useMemo(() => {
    const selectedDateTimeOptions = pickupDateOptions.find(i => i.date.valueOf() === pickupDateTS)?.timeOptions ?? []
    setMinuteOffset(pathOr(getRoundedMinutesOffset(defaultDate), [0, 'minuteOffset'], selectedDateTimeOptions))
    return selectedDateTimeOptions
  }, [pickupDateTS, pickupDateOptions]);

  return (
    <BottomDrawer
      {...props}
      title={<Typography variant="h6">Schedule pickup time</Typography>}
      onClose={props.onClose}
    >
      <div className={classes.root}>
        <CardContent>
          <form className='scheduleForm'>
            <TextField
              id="pick-date"
              select
              label="Date"
              value={`${pickupDateTS}`}
              onChange={handleDateChange}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start"><DateRange color="primary" /></InputAdornment>,
              }}
            >
              {pickupDateOptions.map((val: DateOptionType) => {
                return (
                  <MenuItem key={val.label} value={val.date.getTime()} disabled={!(val.isValid)}>
                    {val.label}
                  </MenuItem>
                )
              })}
            </TextField>
            <TextField
              id="pick-time"
              select
              label="Time"
              value={pickupMinuteOffset}
              onChange={handleTimeChange}
              variant="outlined"
              fullWidth
              error={isTimeItemDisabled(pickupMinuteOffset, pickupDateTS, merchant)}
              InputProps={{
                startAdornment: <InputAdornment position="start"><Schedule color="primary" /></InputAdornment>,
              }}
              SelectProps={{
                displayEmpty: true
              }}
            >
              {pickupTimeOptions.map((val: TimeOptionType) => {
                return <MenuItem key={val.minuteOffset} value={val.minuteOffset}>
                  {val.label}
                </MenuItem>
              })}
            </TextField>
            <If condition={isMerchantOrderingAvailable && !isStoreOpen(merchant, new Date())}>
              <Typography variant="subtitle2" color="GrayText" textAlign="center">
                {merchant?.officialName} is closed now. To place an order, select a time for pick-up in the future.
              </Typography>
            </If>
            <Button
              color="primary"
              variant="contained"
              onClick={onClickSchedule}
              fullWidth
              className='scheduleButton'>
              Confirm
            </Button>
            {errorMsg && <Alert severity="warning">{errorMsg}</Alert>}
          </form>
        </CardContent>
      </div>
    </BottomDrawer>
  )
}

export default ScheduleOrderDrawer