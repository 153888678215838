import React, { useEffect, useState } from 'react'

import BackButton from '../components/common/BackButton'
import { TreasureIcon } from '../components/common/OneMarketIcons'
import PageHeader from '../components/common/PageHeader'
import useStyles from '../components/Lootbox/styles'
import LootboxVotes from '../components/Lootbox/LootboxVotes'
import { AllVotesDrawer } from '../components/Lootbox/LootboxVoteDrawer'
import { LootboxVotedDialog } from '../components/Lootbox/LootboxVotedDialog'
import LootboxThemeContextProvider from '../context/LootboxThemeContextProvider'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import { createVote, fetchStoresToVote, fetchVotedStores, fetchVoteRemaining } from '../redux/slice/vote/voteAction'
import { getUser } from '../redux/slice/auth/authSelector'
import { getStoresToVote, getStoreVoteCount, getVotedStores, getVotesRemaining } from '../redux/slice/vote/voteSelector'
import { Merchant } from "../bos_common/src/types/MerchantType";

const RenderMainComponent = () => {
  const [drawerShown, setDrawerShown] = useState<boolean>(false);
  const [voteDone, setVoteDone] = useState<boolean>(false);
  const [votedStore, setVotedStore] = useState<Merchant>();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const storesToVote = useAppSelector(getStoresToVote);
  const votedStores = useAppSelector(getVotedStores);
  const voteStoreCount = useAppSelector(getStoreVoteCount);
  const votesRemaining = useAppSelector(getVotesRemaining);

  useEffect(() => {
    if (user) {
      dispatch(fetchStoresToVote());
      dispatch(fetchVotedStores());
      dispatch(fetchVoteRemaining());
    }
  }, [user]);

  const handleVote = (merchant: Merchant): void => {
    if (!user) {
      return;
    }

    setVotedStore(merchant);
    dispatch(
      createVote({
        merchant: merchant.id,
      })
    );
    setVoteDone(true);
  }

  const handleClickSeeVotes = (): void => {
    setDrawerShown(!drawerShown);
  }

  const handleCloseVoted = (): void => {
    setVoteDone(false);
  }

  return (
    <div className={classes.root}>
      <div className='content-container'>
        <LootboxVotes 
          votable={false} 
          title="Voted" 
          merchants={votedStores} 
          subtitle="You will earn 150 points upon each launch" 
          totalVotes={2} 
          countTable={voteStoreCount} 
        />
        <LootboxVotes 
          votable={true} 
          title="Stores to Vote Nearby" 
          merchants={storesToVote} 
          subtitle="You get 10 votes every month" 
          totalVotes={10} 
          onClickSeeVotes={handleClickSeeVotes} 
          onVote={handleVote} 
          countTable={voteStoreCount} 
        />
      </div>
      <AllVotesDrawer open={drawerShown} onClose={() => setDrawerShown(false)} onOpen={() => setDrawerShown(true)} anchor="bottom" />
      {votedStore && 
        <LootboxVotedDialog 
          open={voteDone} 
          onClose={handleCloseVoted} 
          votesRemaining={votesRemaining} 
          store={votedStore} 
          storeVoteCount={voteStoreCount[votedStore.id]}
        />}
    </div>
  )
}

const LootboxTitle = () => {
  return (
    <>
      {TreasureIcon}&nbsp;Votes
    </>
  )
}

const LootboxPage = (): JSX.Element => {
  return (
    <LootboxThemeContextProvider>
      <div className="container">
        <PageHeader
          title={<LootboxTitle />}
          leftChild={<BackButton />}
        />
          <RenderMainComponent />
      </div>
    </LootboxThemeContextProvider>
  )
}

export default LootboxPage;