import React, { useContext } from 'react';
import { useHistory, useLocation, useRouteMatch, Link } from 'react-router-dom';
import { Alert, Typography, Paper, TextField, FormLabel, CardContent, Button, makeStyles, createStyles } from '@material-ui/core';
import { If } from 'react-if';
import * as EmailValidator from 'email-validator';

import ReviewEarnRewards from '../assets/images/congratulations-rewards.svg';

import { FullscreenPaper } from '../bos_common/src';
import { Rating, Review, ReviewType } from '../bos_common/src/types/ReviewType';
import SimpleLoader from '../bos_common/src/components/SimpleLoader';

import { getToken } from '../redux/slice/auth/authSelector';
import { fetchOrderDetail } from '../redux/slice/order/orderAction';
import { getOrder } from '../redux/slice/order/orderSelector';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { createReview, hideEarnRewardsDrawerUI, showEarnRewardsDrawerUI } from '../redux/slice/review/reviewAction';
import { getReviewLoadingStatus, getReviewsByOrder, getReviewUpdatingStatus, isEarnRewardsDrawerShown } from '../redux/slice/review/reviewSelector';

import PageHeader from '../components/common/PageHeader';
import BackButton from '../components/common/BackButton';
import ReviewFAB from '../components/Review/ReviewFAB';
import RatingField from '../components/Review/RatingField';
import { isEmptyOrNil, lineItemWithoutCarryoutBag, pluralString } from '../utils';
import { getMerchant } from '../redux/slice/merchant/merchantSelector';
import BottomDrawer from '../bos_common/src/components/BottomDrawer';
import { UserContext } from 'bos_common/src/context/UserContext';

const useStyles = makeStyles(() =>
  createStyles({
    drawerContent: {
      maxWidth: `400px`,
      textAlign: 'center',
      margin: '0 auto',
    }
  })
)

export const ReviewMerchandisePage = (): JSX.Element => {
  const classes = useStyles()
  const history = useHistory();
  const location = useLocation<{ from: { pathname: string } }>();
  const match = useRouteMatch();
  const orderId = match.params['orderId'];
  const merchandiseId = match.params['merchandiseId'];

  const reduxDispatch = useAppDispatch();
  const token = useAppSelector(getToken);
  const loading = useAppSelector(getReviewLoadingStatus);
  const updating = useAppSelector(getReviewUpdatingStatus);
  const order = useAppSelector(getOrder)(orderId);
  const reviews = useAppSelector(getReviewsByOrder)(order?.id);
  const merchant = useAppSelector(getMerchant)(order?.merchantId);
  const earnRewardsDrawerShown: boolean = useAppSelector(isEarnRewardsDrawerShown);
  const { user, refreshUser } = useContext(UserContext);

  const lastItemToReview = order && order.lineItems.filter((item) => lineItemWithoutCarryoutBag(item, merchant)).length - 1 === reviews.length;

  const [rating, setRating] = React.useState<Rating>({
    taste: 0,
    portion: 0,
    value: 0,
    overall: 0,
  });
  const [comment, setComment] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const lineItem = React.useMemo(() => {
    return order?.lineItems.find((item) => item.id === merchandiseId);
  }, [order]);

  React.useEffect(() => {
    if (token) {
      reduxDispatch(fetchOrderDetail(orderId));
    }
  }, [token]);

  const handleChangeRating = (ratingField: string, value: number) => {
    setRating({
      ...rating,
      [ratingField]: value,
    });
  }

  const handleBack = () => {
    location.state?.from
      ? history.goBack()
      : history.push(`/order/${orderId}/reviews`);
  }

  const handleReviewNext = () => {
    try {
      validate();
      const newReview: Partial<Review> = {
        order: orderId,
        orderId,
        email,
        merchandise: merchandiseId,
        merchant: order.merchantId as any, // For TypeORM compatibility, should find better way for this.
        rating,
        comment,
        reviewType: ReviewType.MERCHANDISE,
      };

      reduxDispatch(createReview(newReview))
        .then(() => {
          if (lastItemToReview) {
            reduxDispatch(showEarnRewardsDrawerUI());
          } else {
            refreshUser();
            history.push({
              pathname: `/order/${orderId}/reviews`,
              state: location.state,
            });
          }
        });
    } catch (exception) {
      const { message } = (exception as Error);
      setErrorMessage(message);
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  }

  const handleClickConfirm = () => {
    reduxDispatch(hideEarnRewardsDrawerUI());
    location.state?.from
      ? history.push(location.state?.from)
      : history.push(`/order/${orderId}`);
  }

  const validate = () => {
    const commentWordsCount = comment.trim().split(' ').length;

    if (rating.overall === 0 || rating.portion === 0 || rating.taste === 0 || rating.value === 0) {
      throw new Error("Please provide a rating for all categories");
    }

    if (commentWordsCount < 10) {
      throw new Error("Please write at least 10 words in your comment");
    }

    if (commentWordsCount > 500) {
      throw new Error("Please restrict your comment to 500 words");
    }

    if (isEmptyOrNil(user?.email)) {
      if (isEmptyOrNil(email)) {
        throw new Error("Please enter your email");
      }
      if (!EmailValidator.validate(email)) {
        throw new Error("Please enter a valid email");
      }
    }
  }

  const remainingWordCount = React.useMemo(() => {
    if (isEmptyOrNil(comment)) {
      return 10;
    } else {
      const diff = 10 - comment.trim().split(' ').length;
      return diff <= 0 ? 0 : diff
    }
  }, [comment]);

  return (
    <div className="container">
      <PageHeader
        title="Review"
        leftChild={<BackButton onBack={handleBack} />}
      />
      <SimpleLoader loading={loading || updating} />
      <If condition={errorMessage}>
        <CardContent>
          <Alert severity="error" style={{ position: 'fixed', top: '4rem' }}>{errorMessage}</Alert>
        </CardContent>
      </If>

      <FullscreenPaper>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            How did you like {lineItem?.name}?
          </Typography>

          <Paper elevation={2} style={{ marginBottom: '1rem' }}>
            <RatingField label='Taste' fieldName='taste' value={rating.taste} onChangeRating={handleChangeRating} />
            <RatingField label='Portion size' fieldName='portion' value={rating.portion} onChangeRating={handleChangeRating} />
            <RatingField label='Value for money' fieldName='value' value={rating.value} onChangeRating={handleChangeRating} />
            <RatingField label='Overall Experience' fieldName='overall' value={rating.overall} onChangeRating={handleChangeRating} />
          </Paper>

          <Paper elevation={2} style={{ marginBottom: '1rem' }}>
            <FormLabel component="legend" style={{ padding: '1rem' }}>
              <Typography variant="subtitle1">Comment</Typography>
            </FormLabel>
            <TextField
              id="outlined-basic"
              variant="outlined"
              multiline
              rows={3}
              fullWidth
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder='Express your opinion in at least 10 words'
              helperText={remainingWordCount > 0 ? `${pluralString(remainingWordCount, 'word')} required` : ''}
            />
          </Paper>

          <If condition={isEmptyOrNil(user?.email)}>
            <Paper elevation={2} style={{ marginBottom: '1rem' }}>
              <TextField
                required
                fullWidth
                placeholder="Email"
                name="email"
                label="Email"
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Paper>
          </If>

          <ReviewFAB onNext={handleReviewNext} onBack={handleBack} lastItem={lastItemToReview} />
        </CardContent>

      </FullscreenPaper>

      <BottomDrawer
        open={earnRewardsDrawerShown}
        setOpen={() => reduxDispatch(showEarnRewardsDrawerUI())}
        onClose={handleClickConfirm}
        title={''}
      >
        <CardContent className={classes.drawerContent}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <img src={ReviewEarnRewards} alt="Earn rewards" style={{ height: '250px', width: '200px', marginBottom: '1rem' }} />
            <Typography variant="body1" textAlign={"center"} lineHeight={1.5} gutterBottom>
              Congratulations! You&apos;ve made your review. {(reviews.length + 1) * 50} points have been added to <Link to='/1mpoints'>your wallet</Link>.
            </Typography>
            <Button variant="contained" color="primary" fullWidth style={{ marginTop: '1rem' }} onClick={handleClickConfirm}>Done</Button>
          </div>
        </CardContent>
      </BottomDrawer>
    </div>
  );
}

export default ReviewMerchandisePage;
