import React, { useEffect } from "react";
import { loadStripe, PaymentMethod } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import { FullscreenPaper } from "../bos_common/src";

import { fetchUserPaymentMethods, removePaymentMethod } from "../redux/slice/stripe/stripeAction";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getToken } from "../redux/slice/auth/authSelector";
import { getStripeLoadingStatus, getStripeUpdatingStatus, getUserPaymentMethods } from "../redux/slice/stripe/stripeSelector";

import PageHeader from "../components/common/PageHeader";
import CountBox from "../components/common/CountBox";
import SimpleLoader from "../bos_common/src/components/SimpleLoader";
import { headerCustomStyle } from '../styles/Common.style';
import { CreditCardList } from "../components/Payment/CreditCardList";
import AddPaymentFormDrawer from "../components/Payment/AddPaymentFormDrawer";
import { stripePublicKey } from "../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "20px 20px 0px 0px !important",
      zIndex: 1,

      "& .paper-item": {
        display: 'flex',
        padding: '1rem',
        margin: '1rem',
        cursor: "pointer"
      },
    },
    navWrapper: {
      ...headerCustomStyle(theme, theme.palette.primary.main),
    },
  })
);

export const UserPaymentMethods = (): JSX.Element => {
  const title = 'Payment';
  const pStripe = React.useMemo(() => loadStripe(stripePublicKey()), []);
  const classes = useStyles();
  const loading = useAppSelector(getStripeLoadingStatus);
  const updating = useAppSelector(getStripeUpdatingStatus);
  const token = useAppSelector(getToken);
  const userPaymentMethods = useAppSelector(getUserPaymentMethods);
  const reduxDispatch = useAppDispatch();
  const [formShown, setFormShown] = React.useState<boolean>(false);

  useEffect(() => {
    if (token && token.trim()) {
      reduxDispatch(fetchUserPaymentMethods());
    }
  }, [token]);

  const handleClickAddPayment = () => {
    setFormShown(true);
  }

  const handlePaymentMethodCreated = () => {
    setFormShown(false);
  }

  const handleDeleteItem = (paymentMethod: PaymentMethod) => {
    reduxDispatch(removePaymentMethod(paymentMethod));
  }

  const handleClose = () => {
    setFormShown(false);
  }

  return (
    <div className="container">
      <SimpleLoader loading={loading || updating} />
      <div className={classes.navWrapper}>
        <PageHeader title={title} leftChild={<AccountBalanceWallet fontSize="large" style={{padding: 12}} />} />
      </div>
      <FullscreenPaper className={classes.root}>
        <Typography variant="subtitle1" style={{ textAlign: 'center', gap: "10px" }} mt="1rem">
          Saved Cards <CountBox count={Number(userPaymentMethods?.length)} />
        </Typography>
        <CreditCardList paymentMethods={userPaymentMethods} onDeleteItem={handleDeleteItem} />
        <Box>
          <Paper elevation={3} className="paper-item" onClick={handleClickAddPayment}>
            <CreditCardIcon sx={{ height: '54px', width: '54px' }} fontSize="large" />
            <Box sx={{ marginLeft: '1rem' }}>
              <Box style={{ display: 'flex' }}>
                <Typography variant="subtitle1" style={{ marginTop: '.75rem' }}>Add Payment Method</Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
        <CardContent>
          <Typography variant="caption" style={{ margin: '1rem', color: '#C4C4C4' }}>
            Your card information is kept strictly encrypted on One Market servers. Secure payments are processed by Stripe as third-party service provider.
          </Typography>
        </CardContent>
      </FullscreenPaper>
      <Elements stripe={pStripe}>
        <AddPaymentFormDrawer
          open={formShown}
          setOpen={setFormShown}
          onPaymentMethodCreated={handlePaymentMethodCreated}
          hidePhoneNumber />
      </Elements>
    </div>
  );
}

export default UserPaymentMethods;
