import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { WritableDraft } from '@reduxjs/toolkit/node_modules/immer/dist/internal';
import { Order } from '../../../bos_common/src/types/OrderTypes';

import { OrderSliceState } from '../../../types/OrderSlice';
import { fetchOrderDetail, updateOrderDetail } from './orderAction';

type OrderSliceCaseReducer = SliceCaseReducers<OrderSliceState>;

const commonUpdate = (state: WritableDraft<OrderSliceState>, action: PayloadAction<Order[]>): OrderSliceState => {
  let tempRef: Order | undefined;
  const { payload } = action;
  const byId = { ...state.entities.byId }
  const ids = [...state.entities.ids];

  payload.forEach((item) => {
    tempRef = payload.find((subItem) => subItem.id === item.id);
    if (tempRef) {
      byId[item.id] = tempRef;
    }

    if (ids.indexOf(item.id) === -1) {
      ids.push(item.id);
    }

  });

  return {
    ...state,
    loading: false,
    updating: false,
    entities: {
      byId, 
      ids,
    }
  }
}

export const orderSlice = createSlice<OrderSliceState, OrderSliceCaseReducer, string>({
  name: 'order',
  initialState: {
    loading: false,
    updating: false,
    entities: {
      ids: [], 
      byId: {},
    },
  },
  reducers: {
    setLoading: (state) => {
      return {
        ...state,
        loading: true
      }
    },

    stopLoading: (state) => {
      return {
        ...state,
        loading: false
      }
    },

    updateOrder: (state, action: PayloadAction<Order[]>) => {
      const updatedState = commonUpdate(state, action);

      return {
        ...updatedState
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderDetail.pending, (state) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(fetchOrderDetail.fulfilled, (state, action) => {
        const updatedState = commonUpdate(state, action as PayloadAction<Order[]>);

        return {
          ...updatedState,
        }
      })
      .addCase(fetchOrderDetail.rejected, (state) => {
        return {
          ...state,
          loading: false,
        }
      })
      .addCase(updateOrderDetail.pending, (state) => {
        return {
          ...state,
          updating: true
        }
      })
      .addCase(updateOrderDetail.fulfilled, (state, action) => {
        const updatedState = commonUpdate(state, action as PayloadAction<Order[]>);

        return {
          ...updatedState,
        }
      })
      .addCase(updateOrderDetail.rejected, (state) => {
        return {
          ...state,
          updating: false,
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, stopLoading, updateOrder } = orderSlice.actions;

export default orderSlice.reducer;
