import React, { useContext, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'

// src
import { AppContext } from '../../context/AppContext';
import { enableStartOver, isEmptyOrNil } from '../../utils';
import ConfirmationAlert from "../../bos_common/src/components/ConfirmationAlert";

type StartOverDialogProps = {
  onConfirm: () => void,
  onClose?: () => void,
  timeout?: number,
  debounce?: number
}

const StartOverDialog = (props: StartOverDialogProps) => {
  const {
    onClose,
    onConfirm,
    timeout = 60000, // 60 sec
    debounce = 500
  } = props
  const { clearCart, cart, featureFlags } = useContext(AppContext)
  const [isOpen, toggleIsOpen] = useState<boolean>(false)

  // if feature flag enabled then show start over dialog
  if (!enableStartOver(featureFlags)) return null;

  useIdleTimer({
    timeout,
    debounce,
    onIdle: () => !isEmptyOrNil(cart) && toggleIsOpen(true),
  })

  if (isEmptyOrNil(cart)) return null

  const handleConfirm = () => {
    onConfirm();
    toggleIsOpen(false);

    clearCart();
  }

  const handleCancel = () => {
    onClose && onClose();
    toggleIsOpen(false);
  }

  return (
    <ConfirmationAlert
      title="Did you forget something?"
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onCancel={handleCancel}>
      We noticed you haven’t confirmed you order yet. Would you like to start over?
    </ConfirmationAlert>
  )
}

export default StartOverDialog