import React from 'react'
import { SvgIcon } from "@material-ui/core";

function OutlineHotpotIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 16, height: 16 }} viewBox="0 0 16 16" {...props} >
      <path d="M15.978 6.588a5.183 5.183 0 00-5.155-4.706H8a.471.471 0 010-.94h3.765V0H8c-.778 0-1.412.633-1.412 1.412 0 .778.634 1.412 1.412 1.412h.47a2.827 2.827 0 002.824 2.823c.614 0 1.137.394 1.33.941H0v2.353a5.182 5.182 0 005.176 5.177h.86a1.414 1.414 0 01-1.33.94h-.941V16h8.47v-.941h-.94a1.414 1.414 0 01-1.332-.941h.86A5.182 5.182 0 0016 8.94V6.588h-.022zM9.412 2.824c1.35.007 1.492-.018 1.882.026v1.856a1.884 1.884 0 01-1.882-1.882zm2.823 2.079V3.066c.664.235 1.251.63 1.716 1.14l-1.094 1.095a2.357 2.357 0 00-.622-.398zm1.19 1.16l1.086-1.085c.275.485.457 1.03.522 1.61H13.6a2.326 2.326 0 00-.175-.524zM9.413 15.06H6.587c.206-.274.354-.594.424-.941H8.99c.07.347.218.667.424.94zm1.41-1.882H5.176A4.241 4.241 0 01.967 9.412h14.066a4.241 4.241 0 01-4.21 3.764zM15.06 8.47H.94v-.942H15.06v.942z" />
      <path d="M2.845 4.685c.164.003 1.771.225 2.378-.382.483-.483.438-1.634.413-1.975l-.03-.404c-.256-.014-1.788-.209-2.38.382-.482.483-.437 1.633-.411 1.974l.03.405zm1.047-1.713c.089-.08.434-.137.814-.148-.01.378-.068.722-.148.813-.09.08-.434.137-.813.148.01-.38.068-.725.147-.813zM7.059 4.235H8v.942h-.941v-.942zM.941 1.412h.941v.94h-.94v-.94zM.941 4.706h.941v.941h-.94v-.941z" />
    </SvgIcon>
  );
}

export default OutlineHotpotIcon