import React from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core'

export const LootboxTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#de8428',
      light: '#f7932e'
    },
    secondary: {
      dark: '#e08f3c',
      main: '#faa958',
      light: '#feecdb'
    }
  }
})

export const LootboxThemeContextProvider = (props: { children: React.ReactChild }): JSX.Element => {
  return (
    <ThemeProvider theme={LootboxTheme}>
      {props.children}
    </ThemeProvider>);
}

export default LootboxThemeContextProvider;
