import { all, call, spawn } from "@redux-saga/core/effects";
import authSaga from "./slice/auth/authSaga";
import voteSaga from "./slice/vote/voteSaga";

export default function* rootSaga(): Generator {
  const sagas = [
    authSaga,
    voteSaga,
  ];

  yield all(sagas.map(saga =>
    spawn(function* () {
      while (true) {
        try {
          yield call(saga)
          break
        } catch (e) {
          console.log(e)
        }
      }
    }))
  );
}
