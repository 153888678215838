import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import { Card, CardContent, LinearProgress, createStyles, Divider, makeStyles, Theme, Typography, Fab, Grid, Alert, darken } from "@material-ui/core";
import { Home } from "@material-ui/icons";
import { If, Then, Else } from "react-if";

// src
import { isOrderActive, displayOrderStatus } from "../bos_common/src/services/orderUtils"
import { UserContext } from "../bos_common/src/context/UserContext";
import { ColoredPaper } from "../bos_common/src/components/Papers";
import { FabContainer } from "../bos_common/src/components/FabContainers";
import { getUTCDate } from "../bos_common/src";

import { AppContext, CartType } from "../context/AppContext";
import { Merchant, MerchantQRCode, Order, OrderStatus, OrderType } from "../services/models";

import PageHeader from "../components/common/PageHeader";
import Order1MPoints from "../components/Order/Order1MPoints";
import PickupDetails from "../components/PickupDetails/PickupDetails";
import CloseButton from "../components/common/CloseButton";
import SignupDrawer, { SignupEntrance } from "../components/User/SignupDrawer";
import RenderWaitTime from "../components/common/WaitTime"

import {
  displayTime, isEmptyOrNil,
  lineItemWithoutCarryoutBag, getAuthHeaders, getReferralGiftPromotion
} from "../utils";

import OrderLocalImpact from "../components/Order/OrderLocalImpact";
import OrderReviewCTA from "../components/Order/OrderReviewCTA";
import { DownloadAppCTA } from "../components/Order/DownloadAppCTA";
import ReviewDrawer from "../components/Review/ReviewDrawer";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { fetchMerchant } from "../redux/slice/merchant/action";
import { getMerchant } from "../redux/slice/merchant/merchantSelector";
import { fetchReviewsForOrder } from "../redux/slice/review/reviewAction";
import { fetchOrderDetail, updateOrderDetail } from "../redux/slice/order/orderAction";
import { getOrder, getOrderLoadingState } from "../redux/slice/order/orderSelector";
import UserAffiliateBanner from "../components/Order/UserAffiliateBanner";
import { checkAffiliateRewardsAtMerchant } from "../services/userApiService";
import ReferralDrawer from "../components/Promotions/ReferralDrawer";
import UserReferralBanner from "../components/Order/UserReferralBanner";
import { isUserAffiliate } from "../services/userUtils";
import eventTrackingService from "../services/Tracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../services/Tracking/events";
import { getEventLabel } from "../services/Tracking/EventsTracking";
import PlatformRaffleCheckInCard from "../components/PlatformRaffles/PlatformRaffleCheckInCard";

interface IOrderStatusPageParams {
  orderId: string,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      '& .MuiCardHeader-action': {
        marginRight: 0,
      },
      '& .fab': {
        '& .MuiFab-root': {
          '& .MuiSvgIcon-root': {
            paddingRight: 0,
            fill: "white",
          },
        },
      },
      '& .order-status': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .status': {
          textAlign: 'right',
          maxWidth: '70%',
        }
      },

      '& .homeButton': {
        background: theme.palette.primary.main,

        '&:hover': {
          background: darken(theme.palette.primary.main, 0.2)
        }
      }
    },
  }),
);

const RenderOrderStatus = ({ order }: { order: Order | undefined }): React.ReactElement => {
  if (!order) {
    return (
      <Typography variant="h6" component="div" className="status">
        Loading...
      </Typography>
    )
  }

  switch (order.status) {
    case OrderStatus.FULFILLED:
      return (
        <Typography color="primary" variant="subtitle1" component="div">
          <If condition={Boolean(order.readyTime)}>
            <Then><div>{`Ready at ${order.readyTime && displayTime(order.readyTime, false)}`}</div></Then>
            <Else><div>{"Ready for pickup"}</div></Else>
          </If>
        </Typography>
      )
    case OrderStatus.PAID:
    case OrderStatus.PREPARING:
    case OrderStatus.REFUNDED:
    default:
      return (
        <>
          <Typography color="primary" variant="subtitle1" component="div">
            {displayOrderStatus(order)}
          </Typography>
          <If condition={order.status !== OrderStatus.REFUNDED}>
            <If condition={getUTCDate(order.enqueueTime) < (new Date())}>
              <Then>
                <RenderWaitTime order={order} />
              </Then>
              <Else>
                <div>{`Pick up time: ${displayTime(order.toPickupTime, true)}`}</div>
              </Else>
            </If>
          </If>
        </>
      )
  }
}

interface GetPickUpDetailsProps { order: Order | undefined, merchant: Merchant | undefined, isKioskMode?: boolean }
const GetPickUpDetails = ({ order, merchant, isKioskMode = false }: GetPickUpDetailsProps) => {
  return (
    <Card elevation={isKioskMode ? 0 : 3}>
      <CardContent className="order-status">
        <div className="info">
          <Typography variant="subtitle1" color="textSecondary" component="div">
            Order for
          </Typography>
          <Typography variant="subtitle1">
            {order?.userDisplayName}
          </Typography>
        </div>
        <div className="status">
          <If condition={merchant?.claimed || (order?.status === OrderStatus.FULFILLED)}>
            <Then>
              <RenderOrderStatus order={order} />
            </Then>
            <Else>
              <Typography color="primary" variant="subtitle1" component="div" lineHeight={1.2}>
                Your order is being processed. Updates will be sent via SMS
              </Typography>
            </Else>
          </If>
        </div>
      </CardContent>
      <Divider />
      <CardContent>
        {order && merchant && <PickupDetails order={order} merchant={merchant} />}
      </CardContent>
    </Card>
  )
}

type RenderSignupDrawerProps = {
  showDrawer: boolean,
  signupEntrance?: SignupEntrance,
  setShowDrawer: (_: boolean) => void,
  exitPage: () => void,
  order?: Order,
  merchant?: Merchant,
  redirectAfterSignUp?: string,
}
const RenderSignupDrawer = (props: RenderSignupDrawerProps) => {
  const { showDrawer, signupEntrance, setShowDrawer, exitPage, order, merchant, redirectAfterSignUp } = props;

  if (!showDrawer) {
    return null
  }

  switch (signupEntrance) {
    case SignupEntrance.EARNED_POINTS:
    case SignupEntrance.NEW_ORDER:
      return (
        <SignupDrawer
          open={showDrawer}
          setOpen={setShowDrawer}
          entrance={signupEntrance}
          order={order}
          merchant={merchant}
        />
      )
    case SignupEntrance.SAVE_ORDER:
      return (
        <SignupDrawer
          open={showDrawer}
          setOpen={setShowDrawer}
          entrance={signupEntrance}
          onCTA={exitPage}
        />
      )
    case SignupEntrance.REVIEWS:
      return (
        <SignupDrawer
          open={showDrawer}
          setOpen={setShowDrawer}
          entrance={signupEntrance}
          onCTA={exitPage}
          redirectAfterSignup={redirectAfterSignUp}
        />
      )
    case SignupEntrance.AFFILIATE_USER:
      return (
        <SignupDrawer
          open={showDrawer}
          setOpen={setShowDrawer}
          entrance={signupEntrance}
          merchant={merchant}
          forceShow
        />
      )
    case SignupEntrance.REFERRAL_GIFT_DRAWER:
      return (
        <ReferralDrawer
          open={showDrawer}
          setOpen={setShowDrawer}
          merchant={merchant}
        />
      )
    default:
      return null
  }
}

const OrderStatusPage = (): React.ReactElement => {
  const history = useHistory()
  const classes = useStyles()
  const { orderId } = useParams<IOrderStatusPageParams>()
  const { user, authenticating, token } = useContext(UserContext)
  const { activeOrder, setActiveOrder } = useContext(AppContext)

  const location = useLocation<{ from?: { pathname: string }, new: boolean }>()
  const [showDrawer, setShowDrawer] = useState<boolean>(false)
  const [signupEntrance, setSignupEntrance] = useState<SignupEntrance>()
  const [reviewDrawerOpen, setReviewDrawerOpen] = React.useState<boolean>(false);

  const from = location.state?.from;
  const newOrder = location.state?.new;
  const pathname = location.pathname
  const redirectAfterSignUp = signupEntrance === SignupEntrance.REVIEWS ? `order/${orderId}/reviews` : undefined;

  const { featureFlags, setCartType } = useContext(AppContext);
  const reduxDispatch = useAppDispatch();
  const order = useAppSelector(getOrder)(orderId);
  const loading = useAppSelector(getOrderLoadingState);
  const merchant = useAppSelector(getMerchant)(order?.merchantId || '');
  const tableQrCodeObject = merchant?.qrCodes?.find((i: MerchantQRCode) => `${i.id}` === `${order?.tableId}`)

  // initial data load
  useEffect(() => {
    setCartType(CartType.REGULAR)
    reduxDispatch(fetchOrderDetail(orderId));
    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.ORDER_STATUS,
      action: EVENT_ACTIONS.VISITED_ORDER_STATUS,
      label: getEventLabel(merchant ?? {} as Merchant)
    });
  }, [orderId])

  // if order status has updated from notifications, fetch again
  useEffect(() => {
    if (order && order.id === activeOrder?.id && order.status !== activeOrder.status) {
      reduxDispatch(fetchOrderDetail(orderId));
    }
  }, [activeOrder?.id, activeOrder?.status])

  // set Active order so that we can subscribe to those events in notification center
  useEffect(() => {
    if (order && isOrderActive(order) && order.id !== activeOrder?.id) {
      setActiveOrder(order)
    }
  }, [order?.id])

  // upsell for sign up for associate order with user
  useEffect(() => {
    if (!authenticating && order && isOrderActive(order)) {

      // For new orders only, after order is created
      if (newOrder) {
        // show affiliated user drawer when user is signed in otherwise show new order drawer
        if (order.userId) {
          checkAffiliateRewardsAtMerchant(
            order.merchantId,
            user,
            { headers: getAuthHeaders(token) }
          ).then((isAffiliateUpsell) => {
            if (isAffiliateUpsell) {
              setShowDrawer(true);
              setSignupEntrance(SignupEntrance.AFFILIATE_USER)
            } else if (!isUserAffiliate(user) && !isEmptyOrNil(getReferralGiftPromotion(merchant))) {
              setShowDrawer(true);
              setSignupEntrance(SignupEntrance.REFERRAL_GIFT_DRAWER)
            } else {
              setShowDrawer(true);
              setSignupEntrance(SignupEntrance.NEW_ORDER)
            }
          })
        } else if (order.oneMarketPoints && order.oneMarketPoints > 0) {
          setShowDrawer(true);
          setSignupEntrance(SignupEntrance.EARNED_POINTS);
        }
      }

      // Order is not associated to anyone
      if (!order.userId) {
        if (user) {
          // associate this order with user
          reduxDispatch(updateOrderDetail({ id: order.id, userId: user.id }));
        }
      }
    }
  }, [authenticating, user, order?.id])

  // show review drawer when user visits the /review url
  useEffect(() => {
    if (pathname.includes('/reviews') && !authenticating) {
      handleClickReview();
    }
  }, [pathname, authenticating, user])

  React.useEffect(() => {
    if (order) {
      reduxDispatch(fetchMerchant({ id: order.merchantId }));
      reduxDispatch(fetchReviewsForOrder(order));
    }
  }, [order?.merchantId]);

  const getOrderType = (orderInfo: Order | undefined): string => {
    if (orderInfo) {
      switch (orderInfo.type) {
        case OrderType.PICKUP:
          return 'Pick Up'
        case OrderType.DINEIN:
          return `Dine In ${tableQrCodeObject ? `(Table: ${tableQrCodeObject?.identifier})` : ''}`
      }
    }

    return "Your Order"
  }

  const needToSignup = (): boolean => {
    return (!user && activeOrder && !activeOrder.userId) as boolean;
  }


  const onClose = () => {
    if (needToSignup()) {
      setSignupEntrance(SignupEntrance.SAVE_ORDER)
      setShowDrawer(true)
    } else {
      exitPage()
    }
  }

  const handleClickReview = () => {
    if (isEmptyOrNil(user)) {
      setSignupEntrance(SignupEntrance.REVIEWS);
      setShowDrawer(true);
    } else {
      setReviewDrawerOpen(true);
    }
  }

  const exitPage = () => {
    if (from?.pathname === location.pathname || !from) {
      const search = order?.type === OrderType.DINEIN
        ? `?tableid=${tableQrCodeObject?.id}`
        : '';
      history.push(`/${merchant?.username}/${search}`);
    } else {
      history.goBack();
    }
  }

  const handleReviewComplete = () => {
    reduxDispatch(fetchOrderDetail(orderId));
  }

  const RenderFabCTA = () => {
    // Dont' render Home button when users come from order status page
    if (from) return null

    return (
      <FabContainer alignment='right' fabStyle={{ padding: "24px 16px 16px" }}>
        <Fab color="primary" className="homeButton" onClick={onClose} >
          <Home />
        </Fab>
      </FabContainer>
    )
  }

  // const setRedirectFromReview = () => {
  //   setSignupEntrance(SignupEntrance.REVIEWS);
  //   setShowDrawer(true);
  // }

  const title = getOrderType(order);
  const needReview = featureFlags?.enableReviews
    && order
    && (order.reviews?.length || 0) < order.lineItems.filter(item => lineItemWithoutCarryoutBag(item, merchant)).length;

  return (
    <div className="container">
      <PageHeader title={title} leftChild={<CloseButton onClose={onClose} />} />
      <If condition={loading}>
        <Then><LinearProgress /></Then>
      </If>
      <If condition={!loading && !order}>
        <ColoredPaper>
          <CardContent>
            <Alert severity="warning">{"Cannot find your order"}</Alert>
          </CardContent>
        </ColoredPaper>
      </If>
      <If condition={!!order}>
        <ColoredPaper className={classes.root}>
          <If condition={Boolean(order && merchant)}>
            <Grid container justifyContent="space-evenly" alignItems="flex-start" spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <GetPickUpDetails order={order} merchant={merchant} />
                <Divider />
              </Grid>
              <Grid container item xs={12} sm={12} md={6} lg={6} spacing={2} justifyContent={'flex-start'} display="flex">
                <Grid item xs={12}>
                  <PlatformRaffleCheckInCard order={order} />
                </Grid>
                <If condition={!isEmptyOrNil(user?.affiliateCode)}>
                  <Then>
                    <Grid item xs={12}>
                      <UserAffiliateBanner merchant={merchant} user={user} />
                    </Grid>
                  </Then>

                  <Else>
                    <Grid item xs={12}>
                      <UserReferralBanner merchant={merchant} user={user} />
                    </Grid>
                  </Else>
                </If>
                <Grid item xs={12}>
                  <Order1MPoints order={order} />
                </Grid>
                <If condition={needReview}>
                  <Grid item xs={12}>
                    <Card elevation={3}>
                      {order && <OrderReviewCTA order={order} merchant={merchant} onClickLink={handleClickReview} />}
                    </Card>
                  </Grid>
                </If>
                <Grid item xs={12}>
                  <DownloadAppCTA />
                </Grid>
                <If condition={!!order}>
                  <Grid item xs={12}>
                    <OrderLocalImpact merchant={merchant} order={order} />
                  </Grid>
                </If>
              </Grid>
            </Grid>
          </If>
          <RenderFabCTA />
          <ReviewDrawer open={reviewDrawerOpen} setOpen={setReviewDrawerOpen} onComplete={handleReviewComplete} />
          <RenderSignupDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            exitPage={exitPage}
            signupEntrance={signupEntrance}
            order={order}
            merchant={merchant}
            redirectAfterSignUp={redirectAfterSignUp}
          />
        </ColoredPaper>
      </If>
    </div>
  );
}

export default OrderStatusPage;