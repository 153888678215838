import React from 'react';
import { Else, If, Then } from 'react-if';
import BottomDrawer from '../../bos_common/src/components/BottomDrawer';
import { LineItem } from '../../bos_common/src/types/OrderTypes';
import { ReviewSelectItem } from './ReviewSelectItem';
import { ReviewMerchandise } from './ReviewMerchandise';

export interface ReviewDrawerProps {
  open: boolean;
  setOpen: (_: boolean) => void;
  onComplete: () => void;
}

export const ReviewDrawer = (props: Readonly<ReviewDrawerProps>): JSX.Element | null => {
  const [selectedItemId, setSelectedItemId] = React.useState<string>('');
  const { open, setOpen, onComplete } = props;

  const handleNext = (lineItem?: Partial<LineItem>) => {
    setSelectedItemId(lineItem?.id || '');
  }

  const handleDone = () => {
    setSelectedItemId('');
    setOpen(false);
    onComplete();
  }

  const handleBack = () => {
    setSelectedItemId('');
    setOpen(true);
  }

  if (!open) {
    return null;
  }

  return (
    <BottomDrawer open={open} setOpen={setOpen} maxHeightPx={800}>
      <If condition={Boolean(!selectedItemId)}>
        <Then>
          <ReviewSelectItem currentlyOpen={open} onNext={handleNext} onBack={handleDone}/>
        </Then>
        <Else>
          <ReviewMerchandise
            merchandiseId={selectedItemId}
            onNext={handleNext}
            onBack={handleBack}
            onDone={handleDone} />
        </Else>
      </If>
    </BottomDrawer>);
}

export default ReviewDrawer;
