import { Fab, IconButton, useTheme } from "@material-ui/core";
import { ChevronRight, Info, InfoOutlined } from "@material-ui/icons";
import React, { useContext, useState } from "react";
import { Case, If, Switch } from "react-if";

import IconWithContentCard from "../StoreFront/IconWithContentCard";
import { Merchant } from "../../services/models";
import { isEmptyOrNil } from "../../utils";
import MerchantInfoDrawer from "./MerchantInfoDrawer";
import eventTrackingService from "../../services/Tracking";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../../services/Tracking/events";
import { useAppSelector } from "../../redux/hooks";
import { getUser } from "../../redux/slice/auth/authSelector";
import { getEventLabel } from "../../services/Tracking/EventsTracking";

type MerchantInfoCTAProps = {
  buttonType?: "rounded" | "normal" | "icon" | "arrow";
  merchant: Merchant;
};

const MerchantInfoCTA = (props: MerchantInfoCTAProps) => {
  const { buttonType = "icon", merchant } = props;

  const theme = useTheme();

  const user = useAppSelector(getUser);

  const [showInfoDrawer, setShowInfoDrawer] = useState(false);

  const handleInfoDrawerOpen = () => {
    setShowInfoDrawer(true);

    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.MERCHANT_STORE_FRONT,
      action: EVENT_ACTIONS.CLICK_INFO,
      label: getEventLabel(merchant),
      data: {
        merchant: merchant,
        user,
      }
    });
  };

  return (
    <div>
      <Switch>
        <Case condition={buttonType === "icon"}>
          <IconButton onClick={handleInfoDrawerOpen}>
            <ChevronRight />
          </IconButton>
        </Case>

        <Case condition={buttonType === "arrow"}>
          <ChevronRight onClick={handleInfoDrawerOpen} sx={{cursor: 'pointer'}}/>
        </Case>

        <Case condition={buttonType === "normal"}>
          <IconButton onClick={handleInfoDrawerOpen} sx={{backgroundColor: 'white'}}>
            <InfoOutlined fontSize="small" />
          </IconButton>
        </Case>

        <Case condition={buttonType === "rounded"}>
          <Fab
            variant="extended"
            sx={{ color: theme.palette.primary.main, backgroundColor: "white" }}
            onClick={handleInfoDrawerOpen}
          >
            <InfoOutlined />
            Info
          </Fab>
        </Case>
      </Switch>

      <If condition={!isEmptyOrNil(merchant)}>
        <MerchantInfoDrawer
          merchant={merchant}
          open={showInfoDrawer}
          setOpen={setShowInfoDrawer}
        />
      </If>
    </div>
  );
};

export default MerchantInfoCTA;
