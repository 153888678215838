import React, { useCallback, useContext, useState } from 'react';
import { Box, Button, createStyles, makeStyles, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { If, Then, Else } from 'react-if';

import BottomDrawer from 'bos_common/src/components/BottomDrawer';
import { isEmptyOrNil } from 'bos_common/src';
import SimpleLoader from 'bos_common/src/components/SimpleLoader';
import { UserContext } from 'bos_common/src/context/UserContext';

import AuthorizeUser from '../common/AuthorizeUser';
import DrawerHeader from './DrawerHeader';
import usePlatformRaffleHook, { PlatformRaffleProps } from './PlatformRaffleHook';
import RafflePostCheckin from './RafflePostCheckin';
import { isKioskDevice } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .drawer-content': {
        padding: theme.spacing(1, 3, 4),

        [theme.breakpoints.up('sm')]: {
          width: '50vw',
          maxWidth: '450px',
          margin: '0 auto'
        }
      }
    }
  })
);

interface PlatformRaffleDrawerProps extends PlatformRaffleProps {
  open: boolean,
  setOpen: (_: boolean) => void
}


export const PlatformRaffleDrawer = ({
  isRaffleCheckedIn = false,
  checkInDetails,
  scannedMerchant,
  raffleQRScanned,
  loading = false,
  order,
  open = false,
  setOpen
}: PlatformRaffleDrawerProps): JSX.Element => {
  const classes = useStyles()

  const location = useLocation<{ from: { pathname: string } }>();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { user, token } = useContext(UserContext);
  const titleVariant = isMobile ? 'h6' : 'h5'

  const RenderCheckInForm = useCallback(() => {
    const searchParams = new URLSearchParams()
    if (!order) {
      searchParams.append('raffleQRScanned', '1')
    }
    searchParams.append('checkedIn', '1')
    const redirectTo = `${location.pathname}?${searchParams.toString()}`

    return (
      <AuthorizeUser
        showActionBtn
        actionBtnText='Check in'
        useErrorAlert={false}
        fullwidth
        redirectTo={redirectTo}
      />
    )
  }, [token, user])

  return (
    <BottomDrawer
      open={open}
      setOpen={setOpen}
      fullWidth
    >
      <Box className={classes.root}>
        <DrawerHeader
          isRaffleCheckedIn={isRaffleCheckedIn}
          merchant={scannedMerchant}
          checkInDetails={checkInDetails}
        />
        <SimpleLoader loading={loading} />
        <Box className='drawer-content' sx={{ py: 1, px: 2 }}>
          <If condition={!isRaffleCheckedIn && !isEmptyOrNil(scannedMerchant)}>
            <Typography variant={titleVariant} sx={{ textAlign: 'center', py: 1, mb: 1 }}>
              Check in at {scannedMerchant!.officialName} to get raffle tickets,&nbsp;
              <a href="https://go.1m.app/sanmateofest2022" rel="noreferrer" target='_blank'>learn more.</a>
            </Typography>
          </If>
          <If condition={isRaffleCheckedIn}>
            <Then>
              <RafflePostCheckin merchant={scannedMerchant!} />
            </Then>
            <Else>
              <RenderCheckInForm />
            </Else>
          </If>
        </Box>
      </Box>
    </BottomDrawer>
  );
}

// Separated Drawer Code to reuse in OrderStatus Card
const PlatformRaffleDrawerWrapper = (): JSX.Element | null => {
  const [open, setOpen] = useState<boolean>(false);
  const raffleObject = usePlatformRaffleHook({ cb: (isOpen: boolean) => setOpen(isOpen) })

  if (isKioskDevice() || isEmptyOrNil(raffleObject.scannedMerchant)) return null;

  return (
    <PlatformRaffleDrawer {...raffleObject} open={open} setOpen={setOpen} />
  )
}

export default PlatformRaffleDrawerWrapper