import React, { useContext, useEffect, useState } from 'react';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { Done, Edit } from '@material-ui/icons';
import { If, Then, Else } from 'react-if';

import { UserContext } from '../../bos_common/src/context/UserContext';
import axios from '../../bos_common/src/services/backendAxios';
import { User } from '../../services/models';
import useStyles from './styles'

interface ProfileState {
  displayName: string;
  email: string;
  address: string;
  affiliateCode?: string | null;
}

const UserProfileSettings = (): React.ReactElement => {
  const classes = useStyles()
  const { user, setLoginState, token, setLoading } = useContext(UserContext);
  const [activeRow, setActiveRow] = useState<string | null>()

  const getUserData = (localUser: User | undefined) => {
    return {
      displayName: localUser?.displayName || '',
      email: localUser?.email || '',
      address: localUser?.address || '',
      affiliateCode: localUser?.affiliateCode,
    }
  }

  const [profileValues, setProfileValues] = useState<ProfileState>(getUserData(user));

  useEffect(() => {
    setProfileValues(getUserData(user))
  }, [user])

  const inputRefs = {
    displayName: React.useRef<HTMLInputElement>(),
    email: React.useRef<HTMLInputElement>(),
    address: React.useRef<HTMLInputElement>(),
  }

  const inputLabels = {
    displayName: 'Name',
    email: 'Email',
    address: 'Zipcode',
    affiliateCode: 'Affiliate code',
  }

  const handleChange = (prop: keyof ProfileState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setProfileValues({ ...profileValues, [prop]: event.target.value });
  };

  const handleEdit = (prop: keyof ProfileState) => (event: any) => {
    if (activeRow && activeRow !== prop) {
      const val = user ? user[activeRow as keyof User] : ''
      setProfileValues({ ...profileValues, [activeRow]: val });
    }
    setActiveRow(prop)
    inputRefs[prop]?.current?.focus()
  }

  const handleDone = (prop: keyof ProfileState) => (event: any) => {
    event.preventDefault()
    setActiveRow(null)
    const currentVal = profileValues[prop]?.trim();
    setProfileValues({ ...profileValues, [prop]: currentVal});
    inputRefs[prop]?.current?.blur()
    const oldVal = user ? user[prop as keyof User] : ''

    if (currentVal !== oldVal) {
      setLoading(true)
      axios.put<User>(
        '/users',
        { ...profileValues, ['id']: user?.id },
        { headers: { "Authorization": `JWT ${token}` } }
      ).then((response) => {
        setLoading(false)
        if (response.status === 200) {
          setLoginState(response.data, token)
        }
      }).catch((err) => {
        console.log(err)
        setLoading(false)
      })
    }
  }

  const RenderAdornment = ({ prop }: { prop: keyof ProfileState }) => {
    return (
      <InputAdornment position="end">
        <If condition={activeRow === prop}>
          <Then>
            <IconButton
              aria-label={`save user ${inputLabels[prop]}`}
              onClick={handleDone(prop)}
              edge="end">
              <Done />
            </IconButton>

          </Then>
          <Else>
            <IconButton
              aria-label={`edit user ${inputLabels[prop]}`}
              onClick={handleEdit(prop)}
              edge="end">
              <Edit />
            </IconButton>
          </Else>
        </If>
      </InputAdornment>
    )
  }

  const generateRow = (prop: keyof ProfileState, inputType = 'text'): React.ReactElement => {
    const inputId = `input_user_${prop}`
    return (
      <div className='inputRow'>
        <FormControl variant="outlined" key={prop} fullWidth>
          <InputLabel htmlFor={inputId}>{inputLabels[prop]}</InputLabel>
          <OutlinedInput
            id={inputId}
            inputRef={inputRefs[prop]}
            value={profileValues[prop]}
            onChange={handleChange(prop)}
            onFocus={handleEdit(prop)}
            onBlur={handleDone(prop)}
            endAdornment={<RenderAdornment prop={prop} />}
            label={inputLabels[prop]}
            type={inputType}
          />
        </FormControl>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      {generateRow('displayName')}
      {generateRow('email')}
      {generateRow('address', 'number')}
      <If condition={profileValues.affiliateCode != null}>
        {generateRow('affiliateCode')}
      </If>
    </div>
  )
}

export default UserProfileSettings;
