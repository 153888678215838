import React from "react";
import { AppBar, createStyles, makeStyles, Slide, Theme, Toolbar, Typography, useScrollTrigger } from "@material-ui/core";
import { MenuDrawer } from "./MenuDrawer";
import { isKioskDevice, isWebViewEmbed } from "../../utils";
import DocumentMetaTags from "./DocumentMetaTags";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: ({ headerTheme }: any) => ({
      '& .toolbar': {
        display: "flex",
        justifyContent: "space-between",
        padding: 0,
        ...(headerTheme === 'dark' && {
          backgroundColor: theme.palette.primary.main,
          color: 'white'
        }),
        '& .left': {
          display: "flex",
          flexShrink: 1,
          justifyContent: "flex-start",
          alignItems: 'center'
        },
        '& .title': {
          display: "block",
          textAlign: "center",
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: headerTheme === "dark" ? theme.palette.common.white : theme.palette.primary.main,
          fontWeight: "bold",
        },
        '& .leftTitle': {
          display: "block",
          textAlign: "left",
          paddingLeft: theme.spacing(2),
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        '& .right': {
          display: "flex",
          flexShrink: 0,
          justifyContent: "flex-end",
        },
        '& .orderOptionButton': {
          marginLeft: theme.spacing(1),
          height: 40
        },
      }
    })
  }),
);

const PageHeader = (props: {
  title: string | React.ReactNode,
  leftChild?: React.ReactNode,
  rightChild?: React.ReactNode,
  theme?: 'dark' | 'light',
  hideOnScroll?: boolean
}): React.ReactElement | null => {
  const {
    title,
    leftChild,
    theme = 'light',
    hideOnScroll = true
  } = props
  const classes = useStyles({ headerTheme: theme })

  if (isWebViewEmbed()) return null

  const rightChild = props.rightChild
    ? props.rightChild
    : (isKioskDevice() ? null : <MenuDrawer />)

  const Content = <AppBar color={'inherit'} elevation={0}>
    <Toolbar className="toolbar">
      {leftChild && (
        <div className="left">
          {leftChild}
        </div>
      )}
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className={leftChild ? "title" : "leftTitle"}>
        {title}
      </Typography>
      <div className="right">
        {rightChild}
      </div>
    </Toolbar>
  </AppBar>

  return (
    <DocumentMetaTags>
      <div className={classes.root}>
        {hideOnScroll ?
          <HideOnScroll {...props}>
            {Content}
          </HideOnScroll>
          : Content
        }

        <Toolbar />
      </div>
    </DocumentMetaTags>
  );
}

export default PageHeader;