import React from "react"
import { Avatar, createStyles, makeStyles, Stack, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { Else, If, Then } from "react-if";
import { ChevronRight, Instagram, Verified } from "@material-ui/icons";
import { rgba } from "polished";

import { isEmptyOrNil } from "bos_common/src";
import PlaceholderImg from "bos_common/src/assets/images/placeholder.png"
import { ContentFeed } from "../ContentFeed/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      height: "100%",
      gridTemplateRows: "1fr auto",
      gridGap: theme.spacing(1),

      "& .thumbnailContainer": {
        position: "relative",

        "& .feedThumbnail": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: theme.spacing(1),
        },

        "& .socialMediaBanner": {
          position: "absolute",
          width: "fill-available",
          bottom: 0,
          padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
          color: theme.palette.common.white,
          background: rgba(0, 0, 0, 0.5),
          borderBottomLeftRadius: theme.spacing(1),
          borderBottomRightRadius: theme.spacing(1),
        },
      },

      "& .feedUserInfo": {
        display: "flex",
        alignItems: "center",
        gridGap: theme.spacing(1),
        padding: theme.spacing(1),
        minHeight: theme.spacing(3),

        "& .feedUserName": {
          fontSize: 12,
          fontWeight: 600,
        }
      }
    },
  })
);

type OneMarketFeedCardProps = {
  feed: ContentFeed;
}

const OneMarketFeedCard = (props: OneMarketFeedCardProps): JSX.Element => {
  const { feed } = props;

  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleCardClick = () => {
    if (isEmptyOrNil(feed?.redirectUrl)) return;

    const w = window.open(feed.redirectUrl!, "_blank");
    if (w) {
      w.focus();
    }
  }
  const feedContentUrl = feed.contentUrl.split(',')[0] // pick the first url from contentUrl key

  const showRedirectBanner = !isEmptyOrNil(feed.redirectUrl);

  const cursorPointer = showRedirectBanner ? { 'cursor': 'pointer' } : {}

  return (
    <div className={classes.root} onClick={handleCardClick} style={cursorPointer}>
      <div className='thumbnailContainer'>
        <If condition={feed.mediaType === 'video'}>
          <Then>
            <video className="feedThumbnail" controls poster={PlaceholderImg}>
              <source src={feedContentUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Then>
          <Else>
            <img className="feedThumbnail" src={feedContentUrl} />
            <If condition={showRedirectBanner}>
              <Stack direction="row" justifyContent="space-between" className="socialMediaBanner" alignItems={'center'}>
                <Instagram /> <Typography variant="body1" fontWeight='bold'>{isMobile ? 'Instagram' : 'View on Instagram'}</Typography> <ChevronRight />
              </Stack>
            </If>
          </Else>
        </If>
      </div>

      <If condition={!isEmptyOrNil(feed.user)}>
        <Then>
          <div className="feedUserInfo">
            <Avatar src={feed?.user?.picture} alt="Profile-pic" sx={{ width: 20, height: 20 }} />
            <span className="feedUserName">{feed?.user?.displayName}</span>
            <Verified sx={{ width: 18, height: 18, color: "#0068FF" }} />
          </div>
        </Then>
      </If>
    </div>
  )
}

export default OneMarketFeedCard;