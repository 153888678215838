import React from "react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { If, Then } from "react-if";
import Stack from "@material-ui/core/Stack";
import Fab from "@material-ui/core/Fab";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { User } from "bos_common/src/types/UserTypes";
import { StoryComment } from "bos_common/src/types/MerchantStoryType";
import { useAppSelector } from "../../redux/hooks";
import { getCommentsReply, getMerchantStoryLoadingStatus, getMerchantStoryUpdatingStatus } from "../../redux/slice/merchantStory/merchantStorySelector";
import CommentInfo from "./CommentInfo";
import CommentMain from "./CommentMain";
import CommentMenu from "./CommentMenu";
import CommentReplyItem from "./CommentReplyItem";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 16px 8px',

    "& .form-container-box": {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(2),
      position: 'relative',
      marginTop: '4px'
    },

    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        borderRadius: '32px',
      }
    },

    "& .submit-comment-btn": {
      position: 'absolute',
      right: '6px',
      top: '4px',
    }
  }
}));

export interface CommentItemProps {
  comment: StoryComment;
  user: User | null;
  onReply?: (newComment: Partial<StoryComment>) => void;
  onDelete?: (comment: StoryComment) => void;
}

export const CommentItem = (props: Readonly<CommentItemProps>): JSX.Element => {
  const { comment, user, onReply, onDelete } = props;
  const classes = useStyles();
  const [replying, setReplying] = React.useState<boolean>(false);
  const [replyContent, setReplyContent] = React.useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>();
  const replies = useAppSelector(getCommentsReply)(comment);
  const loading = useAppSelector(getMerchantStoryLoadingStatus);
  const updating = useAppSelector(getMerchantStoryUpdatingStatus);
  const [requiredError, setRequiredError] = React.useState<boolean>(false);

  const menuOpened = Boolean(anchorEl);

  const handleClickMore = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  }

  const handleCloseMenu = () => {
    setAnchorEl(undefined);
  }

  const handleAboutToComment = () => {
    setReplying(!replying);
  }

  const handleChangeReplyContent = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReplyContent(e.target.value);
  }

  const handleKeyPressReplyContent = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      saveReply();
    }
  }

  const handleDelete = (comment: StoryComment) => {
    if (!comment || !onDelete) {
      return;
    }

    onDelete(comment);
    setAnchorEl(undefined);
  }

  const handleClickSendReply = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    saveReply();
  }

  const handleAboutToReply = () => {
    setReplying(!replying);
  }

  const saveReply = async () => {
    try {
      const content = replyContent.trim();
  
      if (!onReply) {
        return;
      }
  
      if (!content) {
        setReplyContent('');
        setRequiredError(true);
        return;
      }
  
      await onReply({
        merchantStoryId: Number(comment.merchantStoryId),
        content,
        replyToCommentId: comment.replyToCommentId ?? comment.id,
      });
  
      setRequiredError(false);
      setReplyContent('');
      setReplying(false);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={classes.root} key={`comment_${comment.id}`}>
      <If condition={user?.id === comment.user?.id}>
        <div>
          <IconButton sx={{ float: 'right', clear: 'none', marginBottom: -5 }} onClick={(e) => handleClickMore(e)}>
            <MoreHorizIcon />
          </IconButton>
          <CommentMenu
            comment={comment}
            onDelete={() => handleDelete(comment)}
            anchorEl={anchorEl}
            onClose={handleCloseMenu}
            menuOpened={menuOpened}
          />
        </div>
      </If>
      <CommentInfo comment={comment} />
      <CommentMain comment={comment} replyCount={replies.length ?? 0} onAboutToComment={handleAboutToComment} />
      <Stack sx={{ marginLeft: 2 }}>
        {replies.map((item) => (
          <CommentReplyItem
            key={`reply_comment_${item.id}`}
            user={user}
            comment={item}
            onAboutToComment={handleAboutToReply}
            onDelete={onDelete}
          />
          ))
        }
      </Stack>
      <If condition={Boolean(user && replying)}>
        <div className="form-container-box"
          // NOTE: commented with intention, for later use
            // position: 'fixed',
            // right: 0,
            // left: 0,
            // bottom: 0,
            // backgroundColor: '#FFF',
            // zIndex: 9,
          >
          <TextField
            id={`comment_${comment.id}`}
            className="comment-content-text"
            placeholder="Comment..."
            required
            value={replyContent}
            error={requiredError}
            onChange={handleChangeReplyContent}
            onKeyPress={handleKeyPressReplyContent}
          />
          <Fab color="primary" variant="extended" className="submit-comment-btn" disabled={loading || updating} onClick={handleClickSendReply}>
            <ArrowForwardIcon />
          </Fab>
        </div>
      </If>
    </div>
  )
}
