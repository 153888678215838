import React from 'react'
import { SvgIcon } from "@material-ui/core";

function PercentageIcon(props: any) {
  return (
    <SvgIcon width="22" height="16" viewBox="0 0 22 16" fill="#364245" {...props} >
      <path d="M20.45 0.649902H1.55C1.27152 0.649902 1.00445 0.760527 0.807538 0.95744C0.610625 1.15435 0.5 1.42142 0.5 1.6999V5.8999H1.43765C2.48345 5.8999 3.45365 6.61495 3.62165 7.6471C3.67283 7.94849 3.65762 8.25742 3.57709 8.55233C3.49656 8.84723 3.35265 9.12101 3.15539 9.35456C2.95813 9.58811 2.71229 9.7758 2.43501 9.90453C2.15773 10.0333 1.8557 10.0999 1.55 10.0999H0.5V14.2999C0.5 14.5784 0.610625 14.8455 0.807538 15.0424C1.00445 15.2393 1.27152 15.3499 1.55 15.3499H20.45C20.7285 15.3499 20.9955 15.2393 21.1925 15.0424C21.3894 14.8455 21.5 14.5784 21.5 14.2999V10.0999H20.45C20.1443 10.0999 19.8423 10.0333 19.565 9.90453C19.2877 9.7758 19.0419 9.58811 18.8446 9.35456C18.6474 9.12101 18.5034 8.84723 18.4229 8.55233C18.3424 8.25742 18.3272 7.94849 18.3783 7.6471C18.5464 6.61495 19.5166 5.8999 20.5623 5.8999H21.5V1.6999C21.5 1.42142 21.3894 1.15435 21.1925 0.95744C20.9955 0.760527 20.7285 0.649902 20.45 0.649902ZM7.85 4.8499C8.12848 4.8499 8.39555 4.96053 8.59246 5.15744C8.78937 5.35435 8.9 5.62142 8.9 5.8999C8.9 6.17838 8.78937 6.44545 8.59246 6.64236C8.39555 6.83928 8.12848 6.9499 7.85 6.9499C7.57152 6.9499 7.30445 6.83928 7.10754 6.64236C6.91062 6.44545 6.8 6.17838 6.8 5.8999C6.8 5.62142 6.91062 5.35435 7.10754 5.15744C7.30445 4.96053 7.57152 4.8499 7.85 4.8499ZM7.01 11.5699L13.31 3.1699L14.99 4.4299L8.69 12.8299L7.01 11.5699ZM14.15 11.1499C13.8715 11.1499 13.6045 11.0393 13.4075 10.8424C13.2106 10.6455 13.1 10.3784 13.1 10.0999C13.1 9.82142 13.2106 9.55435 13.4075 9.35744C13.6045 9.16053 13.8715 9.0499 14.15 9.0499C14.4285 9.0499 14.6955 9.16053 14.8925 9.35744C15.0894 9.55435 15.2 9.82142 15.2 10.0999C15.2 10.3784 15.0894 10.6455 14.8925 10.8424C14.6955 11.0393 14.4285 11.1499 14.15 11.1499Z" />
    </SvgIcon>
  );
}

export default PercentageIcon

