import React from "react";

import { MarketplaceContext } from "./MarketplaceContext";
import { LOCALSTORAGE_MARKETPLACE_APP_KEYS } from "../../services/localStorage";
import { useWindowStorage } from "../../bos_common/src";

export interface SearchStateType {
  sortBy: string;
  price: number | undefined;
  name: string;
  selectedCategory: string;
}

const MarketplaceContextProvider = (props: {
  children: React.ReactNode,
}): React.ReactElement => {
  const [searchState, setSearchState] = useWindowStorage<SearchStateType>(
    LOCALSTORAGE_MARKETPLACE_APP_KEYS.MARKETPLACE_DISCOVERY_SEARCH_STATE,
    {
      sortBy: "",
      price: -1,
      name: "",
      selectedCategory: "",
    },
    "sessionStorage"
  );

  const handleSearchStateChange = (key: string, value: string | number) => {
    setSearchState((s: any) => ({
      ...s,
      [key]: value,
    }));
  }

  const resetSearchState = () => {
    setSearchState({
      sortBy: "",
      price: -1,
      name: "",
      selectedCategory: "",
    });
  }

  const hasFilters = Boolean(searchState.name || searchState.price !== -1 || searchState.selectedCategory || searchState.sortBy);

  return (
    <MarketplaceContext.Provider
      value={{
        searchState,
        setSearchState,
        handleSearchStateChange,
        resetSearchState,
        hasFilters
      }}>
      {props.children}
    </MarketplaceContext.Provider >
  );
};

export default MarketplaceContextProvider;
