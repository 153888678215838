import { map, pipe, toPairs } from 'ramda';
import React, { useContext, useMemo } from 'react';
import { Helmet } from "react-helmet";

import { getMarketPlaceMerchantURL, getMarketPlaceURL } from '../../bos_common/src/services/urls';
import { VisitedMerchantContext } from '../../context/VisitedMerchantContext/VisitedMerchantContext';

type DocumentMetaTagsProps = {
  children: React.ReactElement
  meta?: { [key: string]: string }
}
const DocumentMetaTags = ({ children, meta }: DocumentMetaTagsProps) => {
  const { visitedMerchant } = useContext(VisitedMerchantContext);

  const metaTags = useMemo(() => ({
    title: `${visitedMerchant ? `${visitedMerchant.officialName}${visitedMerchant.category ? ` - ${visitedMerchant.category}` : ''}` : 'One Market'}`,
    description: "Buy local, get rewards on 1m.app",
    canonical: visitedMerchant ? getMarketPlaceMerchantURL(visitedMerchant) : getMarketPlaceURL(),
    ...meta
  }), [visitedMerchant, meta])

  const generateMetaTagsHTML = (p: [string, string]) => {
    const [name, content] = p

    switch (name) {
      case 'title':
        return (<title>{content}</title>)
      default:
        return (<meta name={name} content={content} />)
    }

  }

  const metaTagsHTML = pipe(
    toPairs,
    map(generateMetaTagsHTML)
  )(metaTags)

  return (
    <>
      <Helmet>
        {metaTagsHTML}
      </Helmet>
      {children}
    </>
  )
}

export default DocumentMetaTags;