import React from "react";
import { Card, CardContent, createStyles, Fab, makeStyles, Theme } from "@material-ui/core";
import { Home } from "@material-ui/icons";
import { useHistory } from "react-router";

import PageHeader from "../components/common/PageHeader";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    homeButton: {
      '&.MuiButtonBase-root': {
        [theme.breakpoints.down('md')]: {
          left: '50%',
          transform: "translateX(-50%)"
        }
      }
    }
  })
);

type NoneExistPageProps = {
  title?: string | React.ReactNode
}

const NoneExistPage = ({ title = 'Page not found' }: NoneExistPageProps): React.ReactElement => {
  const history = useHistory();

  const classes = useStyles();

  return (
    <div className="container">
      <PageHeader title={title} />
      <Card variant="outlined">
        <CardContent>
          <p>Sorry that we could not find the page even after we turned our warehouse upside down.</p>
          <Fab className={classes.homeButton} variant="extended" color="primary" onClick={() => history.push('/')}>
            <Home sx={{mr: 1}} /> Back to home
          </Fab>
        </CardContent>
      </Card>
    </div>
  );
}

export default NoneExistPage;