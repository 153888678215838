import React from "react";
import { AppContext, CartItemType, CartType } from "../../context/AppContext";
import CartItemCard from "./CartItemCard";

type CartItemsProps = {
  cartItems?: CartItemType[]
}

export default function CartItems(props: CartItemsProps): React.ReactElement {
  const { cartItems } = props
  const { cart, cartType, coupon } = React.useContext(AppContext)
  const cartList = cartItems || cart
  const readOnly = cartType === CartType.FROM_OPEN_CHECK;

  // It only renders products that are visible to customers, this allow us to hide the bags from the cart
  return (
    <div className='cart-items-container'>
      {cartList.map((item: CartItemType) => {
        return (
          <CartItemCard
            item={item}
            key={item.id + item.modifiersSignature}
            readOnly={readOnly}
            coupon={coupon}
          />
        )
      })}
    </div>
  )
}