import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from "react";
import useAxios from "axios-hooks";
import { debounce } from "debounce";
import { pickAll } from "ramda";

import axios from "../bos_common/src/services/backendAxios";
import ConfirmationAlert from "../bos_common/src/components/ConfirmationAlert";
import { UserContext } from "../bos_common/src/context/UserContext";

import { FeatureFlags, MerchandiseApiResponseType, Order, OrderType, MerchantCoupon, Merchant, MerchantQRCode, OrderPickupType } from "../services/models";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from "../services/Tracking/events";
import eventTrackingService from "../services/Tracking";

import { TipData, defaultTipData, AppContext, CartItemType, RemoveItemFromCartParams, CartType, MerchantCartConfig, SwitchMerchantAlert } from "./AppContext";
import {
  mercReducer, ADD_MERCHANDISE, REMOVE_MERCHANDISE, CLEAR_CART, UPDATE_TIP_SELECTION,
  CANCEL_REDEMPTION, REDEEM_MERCHANDISE, UPDATE_MERCHANDISE, UPDATE_COUPON, UPDATE_PICKUPTIME, UPDATE_ORDER_TYPE, UPDATE_CART_TYPE,
} from "./Reducers"
import { fetchAuth } from "../redux/slice/auth/authActions";
import { useAppDispatch } from "../redux/hooks";
import { fetchUserPromotionEntries } from "../redux/slice/marketPlace/marketPlaceAction";
import { getStoredAppContextState } from "../services/localStorage";

// Remember to update clearCart in Reducers.tsx
const initialState = {
  cart: [],
  cartType: CartType.REGULAR,
  merchantConfig: undefined,
  coupon: undefined,
  tip: defaultTipData,
  pickupTime: undefined,
  orderType: OrderType.PICKUP,
  tableQrCodeObject: undefined,
  openCheckOrder: undefined,
}
// TODO: Move all context to redux except for theme, so that we don't have multiple and nested context for our app
const AppContextProvider = (props: {
  children: React.ReactNode,
}): React.ReactElement => {
  const [cartState, dispatch] = useReducer(mercReducer, getStoredAppContextState(initialState));
  const { user, token } = useContext(UserContext)
  const { tableQrCodeObject = undefined, cartType = undefined, orderType, openCheckOrder, cart: cartItems = [] } = cartState;
  const reduxDispatch = useAppDispatch();

  const [activeOrder, setActiveOrder] = useState<Order>();
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>();
  const [orderPickupType, setOrderPickupType] = useState<OrderPickupType | null>(OrderPickupType.TO_GO);

  const [switchMerchantAlert, setSwitchMerchantAlert] = useState<SwitchMerchantAlert>({
    previousMerchantName: '',
    currentMerchantName: '',
    isOpen: false
  });

  const itemToBeAddedRef = useRef<{ item: CartItemType, merchant: Merchant | undefined } | null>(null);

  const [{ loading: isFetchingMerchandises }, fetchData] = useAxios<MerchandiseApiResponseType>(
    { url: '/merchandises' }, { manual: true }
  )

  const merchantId = cartState.merchantConfig?.merchant?.id || (cartItems.length > 0 ? cartItems[0].merchantId : undefined);

  const fetchMerchandises = (mid: string): boolean => {
    if (!mid) {
      return false;
    }

    fetchData({ params: { merchantId: mid } })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: UPDATE_MERCHANDISE,
            mercData: response.data,
          });
        }
      })
    return isFetchingMerchandises;
  }

  const debounceHandler = useMemo(() => debounce(fetchMerchandises, 5000, true), []);
  const reFetchMerchandisesForCart = useCallback(() => merchantId && debounceHandler(merchantId), [merchantId]);


  useEffect(() => {
    // Get Feature Flags data
    axios.get<FeatureFlags>('/featureflags')
      .then(res => {
        if (res.status === 200) {
          setFeatureFlags(res.data)
        }
      })

    eventTrackingService.captureEvent({
      category: EVENT_CATEGORIES.GLOBAL,
      action: EVENT_ACTIONS.VISITED_1M_APP,
      label: window.location.pathname,
      data: { user }
    })

    // clear debounced requests
    return () => {
      debounceHandler.clear();
    }
  }, [])

  useEffect(() => {
    if (reFetchMerchandisesForCart)
      reFetchMerchandisesForCart()
  }, [merchantId])

  useEffect(() => {
    if (user && token) {
      // Sync auth between AppContextProvider and Redux.
      reduxDispatch(fetchAuth({ user, token }));
    }
  }, [user, token]);

  useEffect(() => {
    if (token && token.trim()) {
      reduxDispatch(fetchUserPromotionEntries(token));
    }
  }, [token]);

  const addItemToCart = (
    item: CartItemType,
    merchant: Merchant | undefined) => {
    if (cartState.merchantConfig &&
      cartState.merchantConfig?.merchant?.id !== merchant?.id) {

      itemToBeAddedRef.current = { item, merchant }
      setSwitchMerchantAlert({
        previousMerchantName: cartState.merchantConfig?.merchant?.officialName,
        currentMerchantName: merchant?.officialName,
        isOpen: true
      })
      return;
    }

    if (!merchant) {
      return;
    }

    setTimeout(() => {
      dispatch({ type: ADD_MERCHANDISE, item, merchant });
    }, 200)
  }

  const removeItemFromCart = (params: RemoveItemFromCartParams) => {
    setTimeout(() => {
      dispatch({ type: REMOVE_MERCHANDISE, ...params });
    }, 200)
  }

  const confirmMerchantSwitchAction = () => {
    const { item, merchant } = itemToBeAddedRef.current || {}

    clearCart();
    closeMerchantSwitchAlert();

    if (!merchant) return
    setTimeout(() => {
      dispatch({ type: ADD_MERCHANDISE, item, merchant });
    }, 200)
  }

  const closeMerchantSwitchAlert = () => {
    itemToBeAddedRef.current = null
    setSwitchMerchantAlert({ isOpen: false });
  }

  const redeemItems = (items: CartItemType[]) => {
    setTimeout(() => {
      dispatch({ type: REDEEM_MERCHANDISE, items });
    }, 200)
  }

  const cancelRedemption = () => {
    dispatch({ type: CANCEL_REDEMPTION });
  }

  const clearCart = () => {
    setOrderPickupType(OrderPickupType.TO_GO)
    dispatch({ type: CLEAR_CART });
  }

  const setTip = (tip: TipData) => {
    if (tip !== cartState.tip) {
      setTimeout(() => {
        dispatch({ type: UPDATE_TIP_SELECTION, tip })
      }, 100)
    }
  }

  const setCoupon = (coupon: MerchantCoupon | undefined) => {
    setTimeout(() => {
      dispatch({ type: UPDATE_COUPON, coupon })
    }, 100)
  }

  const setPickupTime = (pickupTime: Date | undefined) => {
    setTimeout(() => {
      dispatch({ type: UPDATE_PICKUPTIME, pickupTime })
    }, 100)
  }

  const setOrderType = (_orderType: OrderType, tableQrCodeObject?: MerchantQRCode) => {
    if (orderType !== _orderType) {
      clearCart()
    }
    setTimeout(() => {
      dispatch({ type: UPDATE_ORDER_TYPE, orderType: _orderType, tableQrCodeObject })
    }, 100)
  }

  const setCartType = (cartType: CartType, merchantConfig?: MerchantCartConfig, openCheckOrder?: Order) => {
    setTimeout(() => {
      dispatch({ type: UPDATE_CART_TYPE, cartType, merchantConfig, openCheckOrder })
    }, 100)
  }

  return (
    <AppContext.Provider
      value={{
        cart: cartItems as CartItemType[],
        merchantConfig: cartState.merchantConfig,
        tip: cartState.tip,
        setTip,
        orderType: orderType || OrderType.PICKUP as OrderType,
        tableQrCodeObject: tableQrCodeObject as MerchantQRCode | undefined,
        setOrderType,
        pickupTime: cartState.pickupTime,
        setPickupTime,
        addItemToCart,
        removeItemFromCart,
        redeemItems,
        cancelRedemption,
        coupon: cartState.coupon,
        setCoupon,
        clearCart,
        activeOrder,
        setActiveOrder,
        openCheckOrder,
        cartType,
        setCartType,
        featureFlags,
        reFetchMerchandisesForCart,
        setSwitchMerchantAlert,
        orderPickupType,
        setOrderPickupType,
      }}>
      {props.children}
      <ConfirmationAlert
        title="Switch Stores"
        isOpen={switchMerchantAlert.isOpen}
        onConfirm={confirmMerchantSwitchAction}
        onCancel={closeMerchantSwitchAlert}
      >
        {`Your cart contains items from ${switchMerchantAlert.previousMerchantName}. Creating a new order to add items from ${switchMerchantAlert.currentMerchantName} will clear your cart. Do you wish to continue?`}
      </ConfirmationAlert>
    </AppContext.Provider >
  );
};

export default AppContextProvider;
