import React from 'react';
import { Button, Box, createStyles, makeStyles, Stack, Theme, Typography } from "@material-ui/core"
import { Else, If, Then } from 'react-if';
import { useHistory } from 'react-router-dom';
import { rgba } from "polished";

import { Merchant } from 'bos_common/src/types/MerchantType';
import { CheckInApiResponse } from 'bos_common/src/types/PlatformRaffleType';
import { isEmptyOrNil, pluralString } from 'bos_common/src';

import personLeft from './assets/person-left.svg';
import personRight from './assets/person-right.svg';
import winPrizes from './assets/win-prizes.svg';
import discoverMore from './assets/discover-more.svg';
import ShoppingIcon from '../../assets/icons/ShoppingIcon';
import RaffleBackground from './assets/drawerBackground.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .header-bg': {
        background: `url(${RaffleBackground})`,
        backgroundColor: theme.palette.primary.main,
        backgroundSize: "cover",
        backgroundPosition: 'center',
        backgroundRepeat: "no-repeat",
        position: 'relative',
        padding: theme.spacing(5, 0),

        "& .persons-illustration": {
          position: 'absolute',
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",

          "& .person": {
            maxWidth: '50vw'
          }
        },

        '& .header-illustration': {
          display: "grid",
          gridGap: "10px",
          padding: theme.spacing(0, 3),
          width: '100%',
          zIndex: 1,

          // styles to match drawer-content layout
          [theme.breakpoints.up('sm')]: {
            boxSizing: 'content-box',
            maxWidth: '450px',
            padding: theme.spacing(0, 5),
            width: '50vw',
          },

          '& img': {
            width: '320px',
            maxWidth: '100%',
          },
        },
      },
      '& .see-all-button': {
        background: theme.palette.common.white,
        '&:hover': {
          background: rgba(theme.palette.common.white, .85),
        },
      },
    },

    ticketsCountChip: {
      height: theme.spacing(5.25),
      gap: theme.spacing(1),
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(1),
      borderRadius: `${theme.spacing(7)} !important`, //to override default important of style

      "& .ticketCountText": {
        color: theme.palette.common.black,
        fontWeight: 600,

        "& span": {
          color: theme.palette.primary.main,
        }
      }
    }
  })
);

interface DrawerHeaderProps {
  isRaffleCheckedIn: boolean
  merchant?: Merchant
  checkInDetails?: CheckInApiResponse
}

const DrawerHeader = (props: DrawerHeaderProps) => {
  const { isRaffleCheckedIn, merchant, checkInDetails } = props;
  const classes = useStyles();
  const history = useHistory();

  const headerTextSvg = !isRaffleCheckedIn
    ? winPrizes
    : discoverMore

  const RenderTicketCount = () => {
    if (!isRaffleCheckedIn || isEmptyOrNil(checkInDetails)) return null;

    const label = (
      <Typography variant='body2' textAlign={"center"} className="ticketCountText">
        <If condition={!checkInDetails?.isMerchantAlreadyScanned && !checkInDetails?.isOrderAlreadyScanned}>
          <Then>
            Tickets <span>+{checkInDetails!.earned}</span>: you now have <span>{pluralString(checkInDetails!.totalTicketCount, 'ticket')}</span>!
          </Then>
          <Else>
            You have already checked in at <span>{merchant!.officialName}</span>.<br /> You have <span>{pluralString(checkInDetails!.totalTicketCount, 'ticket')}</span>!
          </Else>
        </If>
      </Typography>
    )

    return (
      <Stack className={classes.ticketsCountChip} direction='row' alignItems='center' justifyContent='center'>
        <ShoppingIcon sx={{ width: 22, height: 22 }} />
        {label}
      </Stack>
    )
  }

  return (
    <Box className={classes.root}>
      <Stack className='header-bg' direction={'row'} justifyContent='center'>
        <div className='persons-illustration'>
          <img className='person' src={personLeft} />
          <img className='person' src={personRight} />
        </div>

        <Box className='header-illustration'>
          <RenderTicketCount />
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', my: 1 }}>
            <img src={headerTextSvg} />
          </Box>
          <If condition={isRaffleCheckedIn}>
            <Button
              color='inherit'
              variant="text"
              className="see-all-button"
              fullWidth
              sx={{ border: '1px solid' }}
              onClick={() => history.push("/sanmateofest")}>
              See all participating stores
            </Button>
          </If>
        </Box>
      </Stack>
    </Box >
  )
}

export default DrawerHeader;
